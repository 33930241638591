import gql from 'graphql-tag'

export const deviceTimeScheduleConfig = gql`
  query deviceTimeScheduleConfig($deviceId: ID!, $lang: AcceptedLanguageEnum) {
    device(deviceId: $deviceId) {
      id
      timeScheduleConfig(lang: $lang)
    }
  }
`
