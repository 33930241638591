<template>
  <div>
    <div v-if="$apollo.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <template v-else>
      <div>
        <v-radio-group v-model="newUsesDefault" :label="$t('global.useDefaultConfiguration')">
          <v-radio :label="$t('global.defaultConfiguration')" :value="true"></v-radio>
          <v-radio :label="$t('global.customConfiguration')" :value="false"></v-radio>
        </v-radio-group>
        <v-btn
          @click="usesDefault ? saveUseDefault() : (confirmRestoreDefaultsModalOpen = true)"
          color="primary"
          class="mb-7"
          :disabled="usesDefault === newUsesDefault"
          depressed
          >{{ $t('global.save') }}</v-btn
        >
      </div>
      <NotificationDelaysTable
        :notificationDelays="defaultNotificationClasses"
        :updateFunction="usesDefault ? null : companyNotificationClassUpdateDelays"
        :defaultDelays="usesDefault ? null : defaultNotificationClassesDefaultDelays"
        :style="{ maxWidth: '1000px' }"
      />
      <div class="d-flex align-center">
        <h2 class="mr-5">{{ $t('global.customNotificationClasses') }}</h2>
        <v-btn @click="createModalOpen = true" color="primary" height="27" depressed>{{
          $t('global.add')
        }}</v-btn>
      </div>
      <NotificationDelaysTable
        :notificationDelays="customNotificationClasses"
        :updateFunction="companyNotificationClassUpdateDelays"
        :deleteFunction="deleteCustomNotificationClass"
        withCustomName
        :style="{ maxWidth: '1000px' }"
      />
    </template>
    <ConfirmationDialog
      v-model="confirmRestoreDefaultsModalOpen"
      :heading="$t('global.restoreDefaultNotificationDelaysConfirmationModal.heading')"
      :text="$t('global.restoreDefaultNotificationDelaysConfirmationModal.text')"
      :action="saveUseDefault"
      confirmBtnColor="error"
    />

    <v-dialog v-model="createModalOpen" width="unset">
      <v-card>
        <div class="pa-6">
          <h2 class="mb-7">{{ $t('global.createCustomNotificationClass') }}</h2>
          <v-form @submit.prevent="createCustomNotificationClass">
            <v-text-field
              v-model="createForm.customName"
              :error-messages="getErrors('createForm.customName')"
              :label="$t('global.name')"
              required
              @input="$v.createForm.customName.$touch()"
              @blur="$v.createForm.customName.$touch()"
              filled
              style="width: 350px"
            ></v-text-field>
            <v-select
              v-model="createForm.notificationClass"
              :error-messages="getErrors('createForm.notificationClass')"
              :items="selectableBaseClasses"
              @input="$v.createForm.notificationClass.$touch()"
              @blur="$v.createForm.notificationClass.$touch()"
              filled
              style="width: 350px"
            >
              <template v-slot:selection="{ item }">
                <NotificationClass :notificationClass="item" />
              </template>
              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <NotificationClass :notificationClass="item" />
                </v-list-item>
              </template>
            </v-select>
            <DurationInput
              v-if="createForm.notificationClass"
              v-model="createForm.firstDelay"
              :label="$t('global.notificationDispatcher.firstNotification')"
              @input="$v.createForm.firstDelay.$touch()"
              @hasError="firstDelayHasError = $event"
              :errorMessages="firstDelayErrors"
            />
            <DurationInput
              v-if="
                createForm.notificationClass &&
                delaysLengthForNotificationClass &&
                delaysLengthForNotificationClass[createForm.notificationClass] >= 2
              "
              v-model="createForm.secondDelay"
              :label="$t('global.notificationDispatcher.secondNotification')"
              @input="$v.createForm.secondDelay.$touch()"
              @hasError="secondDelayHasError = $event"
              :errorMessages="secondDelayErrors"
              class="mt-7"
            />
            <div class="mt-8 text-right">
              <v-btn @click="createModalOpen = false" depressed>{{ $t('global.cancel') }}</v-btn>
              <v-btn type="submit" color="primary" class="ml-3" depressed>{{
                $t('global.confirm')
              }}</v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import NotificationDelaysTable from '@/components/NotificationDispatcher/NotificationDelaysTable'
import NotificationClass from '@/components/NotificationDispatcher/NotificationClass'
import DurationInput from '@/components/Form/Input/DurationInput'
import { companyCustomNotificationClasses } from '@/graphql/query/companyCustomNotificationClasses'
import { customNotificationClassUpdate } from '@/graphql/mutations/customNotificationClassUpdate'
import { companyNotificationClassDelaysUseDefault } from '@/graphql/mutations/companyNotificationClassDelaysUseDefault'
import { customNotificationClassCreate } from '@/graphql/mutations/customNotificationClassCreate'
import { customNotificationClassDelete } from '@/graphql/mutations/customNotificationClassDelete'
import { notificationClassesEnumValues } from '@/graphql/query/notificationClassesEnumValues'
import { mapGetters } from 'vuex'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { validationMixin } from 'vuelidate'
import vuelidateErrorsExtractor from '@/mixins/vuelidateErrorsExtractor'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CompanyNotificationDelayConfiguration',
  mixins: [validationMixin, vuelidateErrorsExtractor],
  components: { NotificationDelaysTable, NotificationClass, DurationInput, ConfirmationDialog },
  data() {
    return {
      usesDefault: undefined,
      newUsesDefault: undefined,
      confirmRestoreDefaultsModalOpen: false,
      createModalOpen: false,
      createForm: {
        customName: null,
        notificationClass: null,
        firstDelay: null,
        secondDelay: null,
      },
      firstDelayHasError: false,
      secondDelayHasError: false,
    }
  },
  validations() {
    return {
      createForm: {
        customName: { required },
        notificationClass: { required },
        firstDelay: { required, internalError: () => !this.firstDelayHasError },
        ...(this.delaysLengthForNotificationClass?.[this.createForm.notificationClass] >= 2 && {
          secondDelay: { required, internalError: () => !this.secondDelayHasError },
        }),
      },
    }
  },
  apollo: {
    companyNotificationClasses: {
      query: companyCustomNotificationClasses,
      variables() {
        return {
          companyId: this.currentCompanyId,
        }
      },
      update(data) {
        this.usesDefault = this.newUsesDefault = data.company.usesDefaultNotificationClassDelays

        return data.company.customNotificationClasses
      },
    },
    baseNotificationClasses: {
      query: notificationClassesEnumValues,
      update(data) {
        return data.__type.enumValues.map((value) => value.name)
      },
    },
  },
  methods: {
    companyNotificationClassUpdateDelays(args) {
      return this.$apollo.mutate({
        mutation: customNotificationClassUpdate,
        variables: {
          companyId: this.currentCompanyId,
          notificationClassId: args.id,
          delays: args.delays,
          customName: args.customName,
        },
        refetchQueries: ['companyCustomNotificationClasses'],
      })
    },
    async saveUseDefault() {
      const wasSetToDefault = this.newUsesDefault
      try {
        await this.$apollo.mutate({
          mutation: companyNotificationClassDelaysUseDefault,
          variables: {
            companyId: this.currentCompanyId,
            useDefault: this.newUsesDefault,
          },
          refetchQueries: ['companyCustomNotificationClasses'],
        })

        this.confirmRestoreDefaultsModalOpen = false
        wasSetToDefault
          ? this.$toast.success(this.$t('global.companySwitchToDefaultSettings.success'))
          : this.$toast.success(this.$t('global.companySwitchToCustomSettings.success'))
      } catch (error) {
        console.error(error)
        wasSetToDefault
          ? this.$toast.error(this.$t('global.companySwitchToDefaultSettings.failure'))
          : this.$toast.error(this.$t('global.companySwitchToCustomSettings.failure'))
      }
    },
    async createCustomNotificationClass() {
      this.$v.createForm.$touch()
      if (!this.$v.createForm.$invalid) {
        try {
          const newDelays = [this.createForm.firstDelay]
          if (this.delaysLengthForNotificationClass?.[this.createForm.notificationClass] >= 2) {
            newDelays.push(this.createForm.secondDelay)
          }

          await this.$apollo.mutate({
            mutation: customNotificationClassCreate,
            variables: {
              companyId: this.currentCompanyId,
              baseNotificationClass: this.createForm.notificationClass,
              delays: newDelays,
              customName: this.createForm.customName,
            },
            refetchQueries: ['companyCustomNotificationClasses'],
          })

          this.createModalOpen = false
          this.$toast.success(this.$t('global.createCustomNotificationClassAction.success'))
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.createCustomNotificationClassAction.failure'))
        }
      }
    },
    async deleteCustomNotificationClass(notificationClassId) {
      try {
        await this.$apollo.mutate({
          mutation: customNotificationClassDelete,
          variables: {
            notificationClassId,
          },
          refetchQueries: ['companyCustomNotificationClasses'],
        })

        this.$toast.success(this.$t('global.deleteCustomNotificationClassAction.success'))
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.deleteCustomNotificationClassAction.failure'))
        throw error // will be caught in child component
      }
    },
    resetCreateForm() {
      this.createForm.customName = null
      this.createForm.notificationClass = null
      this.createForm.firstDelay = null
      this.createForm.secondDelay = null
      this.$v.createForm.$reset()
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    defaultNotificationClasses() {
      return this.companyNotificationClasses
        .filter((cnc) => !cnc.customName)
        .map((cnc) => ({
          id: cnc.id,
          notificationClass: cnc.baseNotificationClass.notificationClass,
          delays: cnc.delays,
        }))
    },
    customNotificationClasses() {
      return this.companyNotificationClasses
        .filter((cnc) => cnc.customName)
        .map((cnc) => ({
          id: cnc.id,
          customName: cnc.customName,
          notificationClass: cnc.baseNotificationClass.notificationClass,
          delays: cnc.delays,
          isUsed: cnc.isUsed,
        }))
    },
    defaultNotificationClassesDefaultDelays() {
      return this.companyNotificationClasses
        .filter((cnc) => !cnc.customName)
        .map((cnc) => ({
          notificationClass: cnc.baseNotificationClass.notificationClass,
          delays: cnc.baseNotificationClass.delays,
        }))
    },
    delaysLengthForNotificationClass() {
      if (!this.baseNotificationClasses || !this.companyNotificationClasses) {
        return null
      }
      const map = {}
      this.baseNotificationClasses.forEach((notificationClass) => {
        map[notificationClass] = this.companyNotificationClasses.find(
          (cnc) => cnc.baseNotificationClass.notificationClass === notificationClass,
        ).delays.length
      })
      return map
    },
    selectableBaseClasses() {
      return this.baseNotificationClasses?.filter((nc) => nc !== 'NO_NOTIFICATION')
    },
    firstDelayErrors() {
      const errors = []
      if (!this.$v.createForm.firstDelay?.$dirty) {
        return errors
      }
      !this.$v.createForm.firstDelay.required &&
        errors.push(this.$t('global.formValidation.required'))
      return errors
    },
    secondDelayErrors() {
      const errors = []
      if (!this.$v.createForm.secondDelay?.$dirty) {
        return errors
      }
      !this.$v.createForm.secondDelay.required &&
        errors.push(this.$t('global.formValidation.required'))
      return errors
    },
  },
  watch: {
    createModalOpen() {
      if (!this.createModalOpen) {
        this.resetCreateForm()
      }
    },
  },
}
</script>
<style lang="less" scoped></style>
