import { Auth } from 'aws-amplify'
import { removeItem } from '../middleware/storage'

export default {
  methods: {
    async logout() {
      if (this.$store.getters.hasUnsavedChanges) {
        const answer = window.confirm(this.$t('global.unsavedChanges'))
        if (!answer) {
          return
        }
      }

      try {
        this.$store.dispatch('user/logout')
        this.$apollo.getClient().clearStore()
        this.$apollo.getClient().resetStore()
        removeItem('redirect')
        await Auth.signOut()
        this.$router.push({ name: 'LoginPage' })
      } catch (error) {
        console.error('error signing out: ', error)
      }
    },
  },
}
