import gql from 'graphql-tag'
import { allowedLanguages } from '@/translations'

export const eventCoreCauseAdmin = gql`
  query eventCoreCauseAdmin($id: ID!, $lang: String) {
    eventCoreCauseAdmin(coreCauseId: $id) {
      id
      name
      ${allowedLanguages
        .map((lang) => `${lang}Description: description(lang: "${lang}")`)
        .join('\n')}
      correctiveActions {
        id
        name
        description(lang: $lang)
      }
      eventCodes {
        id
        name
        description(lang: $lang)
      }
    }
  }
`
