<template>
  <div class="energy-chart-wrapper">
    <div v-if="$apollo.loading" class="loader-wrapper d-flex justify-center align-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <Bar
      v-else-if="energyChartData"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :plugins="plugins"
    />
    <v-alert v-else outlined type="info" class="mt-3">
      {{ $t('global.energyChartsNoData') }}
    </v-alert>
  </div>
</template>
<script>
import { Bar } from 'vue-chartjs/legacy'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js'
import { energyChartData } from '@/graphql/query/energyChartData'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'EnergyChart',
  components: { Bar },
  props: {
    deviceId: String,
    from: Date,
    to: Date,
  },
  data() {
    return {
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        parsing: {
          xAxisKey: 'equipmentId',
          yAxisKey: 'hourCounter',
        },
        scales: {
          y1: {
            position: 'left',
            title: {
              display: true,
              text: `${this.$t('global.energy')} (kWh)`,
            },
            grid: {
              drawOnChartArea: false,
            },
          },
          y2: {
            position: 'right',
            title: {
              display: true,
              text: `${this.$t('global.hourCounter')} (h)`,
            },
            grid: {
              drawOnChartArea: false,
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => {
                return `${context.dataset.label}: ${context.parsed.y} ${
                  context.dataset.yAxisID === 'y1' ? 'kWh' : 'h'
                }`
              },
            },
          },
        },
      },
      height: 300,
      plugins: [ChartDataLabels],
    }
  },
  apollo: {
    energyChartData: {
      query: energyChartData,
      variables() {
        return {
          deviceId: this.deviceId,
          from: this.from,
          to: this.to,
          lang: this.$i18n.locale,
        }
      },
      update(data) {
        const formattedData = data.device.energyChartData?.map((d) => ({
          ...d,
          hourCounter: Math.round((d.timeCounter / 3600) * 10) / 10,
        }))
        this.chartData = {
          datasets: [
            {
              label: this.$t('global.energy'),
              data: formattedData,
              backgroundColor: '#141b5d',
              yAxisID: 'y1',
              parsing: {
                yAxisKey: 'energyCounter',
              },
              datalabels: {
                color: '#fff',
                formatter: (value) => (value.energyCounter ? `${value.energyCounter} kWh` : ''),
              },
            },
            {
              label: this.$t('global.hourCounter'),
              data: formattedData,
              backgroundColor: '#a4e2ff',
              yAxisID: 'y2',
              parsing: {
                yAxisKey: 'hourCounter',
              },
              datalabels: {
                color: '#141b5d',
                formatter: (value) => (value.hourCounter ? `${value.hourCounter} h` : ''),
              },
            },
          ],
        }
        return data.device.energyChartData
      },
    },
  },
}
</script>
<style lang="less" scoped>
.loader-wrapper {
  min-height: 400px;
}
</style>
