import gql from 'graphql-tag'

const deviceControl = gql`
  mutation($data: ControlDeviceInput!) {
    deviceControl(input: $data) {
      success
    }
  }
`

const deviceStartOverrideControl = gql`
  mutation($data: StartControlDeviceInput!) {
    deviceStartOverrideControl(input: $data) {
      clientMutationId
    }
  }
`

const deviceEndOverrideControl = gql`
  mutation($data: EndControlDeviceInput!) {
    deviceEndOverrideControl(input: $data) {
      clientMutationId
    }
  }
`

const devicePropagateSettings = gql`
  mutation($data: PropagateDeviceSettingsInput!) {
    devicePropagateSettings(input: $data) {
      success
    }
  }
`

const deviceControlUpdateForbiddenByUser = gql`
  mutation DeviceControlupdateForbiddenByUser(
    $deviceId: ID!
    $controlName: NonEmptyString!
    $forbiddenByUser: Boolean!
  ) {
    deviceControlUpdateForbiddenByUser(
      input: { deviceId: $deviceId, controlName: $controlName, forbiddenByUser: $forbiddenByUser }
    ) {
      success
    }
  }
`

export {
  deviceControl,
  deviceStartOverrideControl,
  deviceEndOverrideControl,
  devicePropagateSettings,
  deviceControlUpdateForbiddenByUser,
}
