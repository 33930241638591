import gql from 'graphql-tag'

export const serviceHintsAdminOverview = gql`
  query serviceHintsAdminOverview($lang: String) {
    eventDetectionPointsAdmin {
      id
      name
      description(lang: $lang)
      children: eventCodes {
        id
        name
        severity
        disabled
        description(lang: $lang)
        children: coreCauses {
          id
          name
          description(lang: $lang)
          children: correctiveActions {
            id
            name
            description(lang: $lang)
          }
        }
      }
    }
  }
`
