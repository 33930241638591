import gql from 'graphql-tag'

export const detectionPointUpdate = gql`
  mutation detectionPointUpdate(
    $detectionPointId: ID!
    $name: NonEmptyString!
    $descriptionTranslations: DescriptionTranslationsInput!
  ) {
    detectionPointUpdate(
      input: {
        detectionPointId: $detectionPointId
        name: $name
        descriptionTranslations: $descriptionTranslations
      }
    ) {
      success
    }
  }
`
