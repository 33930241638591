/**
 * Returns function that can be used to check if user has access to some features.
 * @param {Object} permissions Permissions used to check against
 * @param {Object} eligibilities Eligibilities used to check against
 * @param {Boolean} directAccess Does user have direct access to device?
 * @returns {(requiredPermissions: string[] | string | null, requiredEligibilities: string[] | string | null) => Boolean}
 * Function to check possibility of access.
 * If directAccess was true then the function checks eligibilities, otherwise it checks permissions and eligibilities. Use '[]' if no permissions/eligibilities are required.
 * Use 'null' if you want to reject access completely. Null eligibilities reject access only for directAccess.
 */
export function createPermissionChecker(permissions, eligibilities, directAccess) {
  return (requiredPermissions, requiredEligibilities) => {
    const requiredPermissionArr = Array.isArray(requiredPermissions)
      ? requiredPermissions
      : [requiredPermissions]
    const requiredEligibilityArr = Array.isArray(requiredEligibilities)
      ? requiredEligibilities
      : [requiredEligibilities]
    return directAccess
      ? requiredEligibilityArr.every((e) => eligibilities?.[e])
      : requiredPermissionArr.every((p) => permissions?.[p]) &&
          (!requiredEligibilities || requiredEligibilityArr.every((e) => eligibilities?.[e]))
  }
}
