import gql from 'graphql-tag'

export const detectionPointCreate = gql`
  mutation detectionPointCreate(
    $name: NonEmptyString!
    $descriptionTranslations: DescriptionTranslationsInput!
  ) {
    detectionPointCreate(
      input: { name: $name, descriptionTranslations: $descriptionTranslations }
    ) {
      success
    }
  }
`
