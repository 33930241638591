<template>
  <ul class="assigned-to-list-wrapper">
    <li v-for="item of data" :key="item[idKey]">
      <router-link :to="{ name: detailPageName, params: { id: item[idKey] } }">
        <span class="description">{{ item[descriptionKey] }}</span>
        <span class="name">{{ item[valueKey] }}</span>
      </router-link>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'AssignedToList',
  props: {
    data: Array,
    detailPageName: String,
    idKey: {
      type: String,
      default: 'id',
    },
    valueKey: {
      type: String,
      default: 'name',
    },
    descriptionKey: {
      type: String,
      default: 'description',
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';
ul {
  padding: 0 !important;

  li {
    list-style-type: none;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px;
    background-color: tint(@color-brand-highlight, 50%);
    margin: 0;
    margin-bottom: 5px;
    word-break: break-word;

    .description {
      display: block;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 3px;
    }

    .name {
      display: block;
      font-size: 12px;
      opacity: 0.75;
    }
  }
}
</style>
