import gql from 'graphql-tag'
import { CompanyUserWithPermissions } from '@/graphql/fragments/companyUserWithPermissions'

export const companyAddUser = gql`
  mutation companyAddUser(
    $companyId: ID!
    $userData: UserCreateInput!
    $permissions: CompanyPermissionsInput!
    $lang: String
    $withPermissions: Boolean! = true
  ) {
    companyAddUser(
      input: { companyId: $companyId, userData: $userData, permissions: $permissions, lang: $lang }
    ) {
      user {
        ...CompanyUserWithPermissions
      }
    }
  }
  ${CompanyUserWithPermissions}
`
