import gql from 'graphql-tag'

export const createCompany = gql`
  mutation createCompany(
    $name: NonEmptyString!
    $createCompanyToken: String!
    $defaultLanguage: AcceptedLanguageEnum
  ) {
    companyCreate(
      input: { name: $name, token: $createCompanyToken, defaultLanguage: $defaultLanguage }
    ) {
      company {
        id
        name
      }
    }
  }
`
