<template>
  <div class="wrapper">
    <DeviceLink v-for="device of usersDevices" :key="device.id" :device="device" :type="type" />
  </div>
</template>
<script>
import { userDevices } from '@/graphql/query/device'
import { mapGetters } from 'vuex'
import DeviceLink from '@/components/DeviceListPanel/DeviceLink'
import { permissions } from '@/config/permissions'

export default {
  name: 'DeviceListPanel',
  components: { DeviceLink },
  props: {
    type: String,
  },
  apollo: {
    userDevices: {
      query: userDevices,
      errorPolicy: 'ignore',
      variables() {
        return {
          type: this.type.toUpperCase(),
          companyId: this.currentCompanyId,
          withCompanyName:
            this.currentCompanyPermissions[permissions.OTHER_COMPANIES_MANAGEMENT] ||
            !this.currentCompanyId,
          withCustomName: !this.currentCompanyId,
          lang: this.$i18n.locale,
        }
      },
      skip() {
        // skip if devices are already in store
        return Boolean(this.usersDevices)
      },
      update(data) {
        return data.me.devices
      },
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyPermissions', 'currentCompanyId', 'usersDevices']),
  },
  watch: {
    userDevices() {
      this.$store.commit('user/setUsersDevices', this.userDevices)
    },
  },
}
</script>
<style lang="less" scoped>
.wrapper {
  width: 200px;
}
</style>
