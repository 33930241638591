<template>
  <div class="form-main--grid">
    <div>
      <select-input
        :label="$t('global.addressItems.country')"
        variable-name="address_code"
        v-model="currentLocation.code"
        :choices="countryCodes"
        :errors="errors"
        name="country_code"
      ></select-input>
      <classic-input
        v-show="currentLocation.code"
        v-model="currentLocation.address"
        ref="addressInput"
        :label="$t('global.addressItems.route')"
        :errors="errors"
        variable-name="address_route"
        name="address"
        key="auto-address"
        required
        :hint="$t('global.addressHint')"
      ></classic-input>
      <h4 style="font-weight: 500; font-size: 14px" class="mt-3">
        {{ $t('global.addressSpecification') }}:
      </h4>
      <classic-input
        v-model="currentLocation.city"
        :label="$t('global.addressItems.city')"
        :errors="errors"
        variable-name="address_city"
        name="city"
        required
      ></classic-input>
      <classic-input
        v-model="currentLocation.postalCode"
        :label="$t('global.addressItems.postalCode')"
        variable-name="address_postalCode"
      ></classic-input>
      <number-input
        v-model="currentLocation.lat"
        :label="$t('global.addressItems.lat')"
        variable-name="address_lat"
      ></number-input>
      <number-input
        v-model="currentLocation.lng"
        :label="$t('global.addressItems.lng')"
        variable-name="address_lng"
      ></number-input>
    </div>
  </div>
</template>

<script>
import SelectInput from '@/components/Form/Input/SelectInput'
import ClassicInput from '@/components/Form/Input/ClassicInput'
import NumberInput from '@/components/Form/Input/NumberInput'
import Vue from 'vue'
import { countryCodes } from '@/config/countryCodes'

export default {
  name: 'AddressEdit',
  components: { ClassicInput, SelectInput, NumberInput },
  props: {
    value: Object,
    errors: Array,
    isValidByDefault: Boolean,
    isManualModeOpen: Boolean,
  },
  data() {
    return {
      addressInputValue: '',
      currentLocation: {
        address: null,
        postalCode: null,
        code: null,
        lat: null,
        lng: null,
        placeId: null,
        city: null,
      },
      googleAutocomplete: null,
      doNotResetPlaceId: false,
    }
  },
  watch: {
    currentLocation: {
      deep: true,
      handler() {
        if (this.doNotResetPlaceId) {
          this.doNotResetPlaceId = false
        } else {
          this.currentLocation.placeId = null
        }
        this.$emit('input', {
          address: this.currentLocation.address,
          city: this.currentLocation.city,
          postalCode: this.currentLocation.postalCode,
          code: this.currentLocation.code,
          lat: this.currentLocation.lat,
          lng: this.currentLocation.lng,
          placeId: this.currentLocation.placeId,
        })
      },
    },
    'currentLocation.code': {
      handler(newValue) {
        this.googleAutocomplete &&
          window.google.maps.event.clearInstanceListeners(this.googleAutocomplete)
        if (newValue) {
          this.initAutocomplete()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.doNotResetPlaceId = true
    Vue.set(this, 'currentLocation', this.value)
  },
  computed: {
    countryCodes() {
      return countryCodes
        .map((code) => ({ label: this.$t(`global.countryCodes.${code}`), value: code }))
        .sort((a, b) => a.label.localeCompare(b.label))
    },
  },
  methods: {
    initAutocomplete() {
      this.googleAutocomplete = new window.google.maps.places.Autocomplete(
        this.$refs.addressInput.$refs.input,
        {
          componentRestrictions: {
            country: [this.currentLocation.code],
          },
          fields: ['address_components', 'formatted_address', 'geometry', 'place_id'],
          types: ['address'],
        },
      )
      this.googleAutocomplete.addListener('place_changed', () => {
        this.doNotResetPlaceId = true
        const placeInfo = this.googleAutocomplete.getPlace()
        this.currentLocation.address = placeInfo.formatted_address
        this.currentLocation.placeId = placeInfo.place_id
        this.currentLocation.lat = placeInfo.geometry?.location?.lat()
        this.currentLocation.lng = placeInfo.geometry?.location?.lng()
        const postalCodeComponent = placeInfo.address_components.find(
          (addressComponent) => addressComponent.types[0] === 'postal_code',
        )
        this.currentLocation.postalCode = postalCodeComponent
          ? postalCodeComponent.short_name
          : null

        let localityComponent = placeInfo.address_components.find((addressComponent) =>
          addressComponent.types.includes('locality'),
        )
        if (!localityComponent) {
          localityComponent = placeInfo.address_components.find((addressComponent) =>
            addressComponent.types.includes('sublocality'),
          )
        }
        this.currentLocation.city = localityComponent ? localityComponent.short_name : null
      })
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

.brand-link {
  color: @color-primary;
  margin-left: 186px;

  @media (max-width: 767px) {
    margin-left: 50%;
  }

  @media (max-width: 479px) {
    margin-left: 0;
  }
}
</style>
