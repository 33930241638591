<template>
  <div ref="wrapper" class="calendar-input-wrapper">
    <div class="timeline">
      <div v-for="tick of ticks" :key="tick" class="tick">
        {{ tick }}
      </div>
    </div>
    <div class="lanes-wrapper">
      <div
        class="time-indicator"
        :style="{
          top: percentageOfDay + '%',
        }"
      >
        <span></span>
      </div>
      <div v-for="config of timeScheduleConfig.schedules" :key="config.name" class="lane-wrapper">
        <div class="lane-header">
          <h2>{{ config.label }}</h2>
        </div>
        <div v-for="n in 48" :key="n" class="grid-line"></div>

        <template v-for="(button, index) in addButtonsPositions[config.name]">
          <TimeScheduleCalendarAddButton
            v-if="
              !resizedOrDraggedIntervalKey &&
              (addButtonIsDraggedIndex == null || addButtonIsDraggedIndex === index)
            "
            :key="`${config.name}-${index}`"
            :timeSchedule="timeSchedule[config.name]"
            :granularity="timeScheduleConfig.granularity"
            :states="config.states"
            :begin="button.begin"
            :end="button.end"
            :getWrapperRef="() => $refs.wrapper"
            @dragging="
              $event ? (addButtonIsDraggedIndex = index) : (addButtonIsDraggedIndex = null)
            "
            @newInterval="addNewInterval(config.name, $event.state, $event.begin, $event.end)"
          />
        </template>

        <div
          v-for="(interval, index) of timeSchedule[config.name]"
          :key="`${config.name}-interval-${interval.id}`"
          @contextmenu.prevent="editable ? openContextMenu(config.name, index, $event) : undefined"
          @mousedown="editable ? dragMousedown(config.name, index, $event) : undefined"
          class="interval"
          :class="
            ((resizedOrDraggedIntervalKey === `${config.name}-${index}`
              ? resizedOrDraggedIntervalEnd - resizedOrDraggedIntervalBegin
              : interval.end - interval.begin) /
              86400) *
              2064 -
              4 >
            38
              ? 'interval--normal'
              : 'interval--narrow'
          "
          :style="{
            backgroundColor: configMap.get(config.name).stateMap.get(interval.state)[
              interval.wip ? 'newBgColor' : 'color'
            ],
            top:
              ((resizedOrDraggedIntervalKey === `${config.name}-${index}`
                ? resizedOrDraggedIntervalBegin
                : interval.begin) /
                86400) *
                2064 +
              43 +
              2 +
              'px', // (interval.begin / numberOfSecondsInDay) * (48 * 43 = heightOfTimeline) + heightOfHeader + padding
            bottom:
              2064 -
              ((resizedOrDraggedIntervalKey === `${config.name}-${index}`
                ? resizedOrDraggedIntervalEnd
                : interval.end) /
                86400) *
                2064 +
              2 +
              'px', // (48 * 43 = heightOfTimeline) - (interval.end / numberOfSecondsInDay) * (48 * 43 = heightOfTimeline) + padding
            border:
              interval.wip &&
              `1.5px dashed ${
                configMap.get(config.name).stateMap.get(interval.state).newBorderColor
              }`,
            cursor: editable ? (resizedOrDraggedIntervalKey ? 'grabbing' : 'grab') : 'default',
          }"
        >
          <div
            v-if="editable && !resizedOrDraggedIntervalKey"
            class="interval-drag-handle-top"
            @click.stop="() => null"
            @mousedown.stop="resizeMousedown(config.name, index, 'begin', $event)"
          >
            <div
              class="interval-drag-handle-top--circle"
              :style="{
                backgroundColor: configMap.get(config.name).stateMap.get(interval.state)[
                  interval.wip ? 'newBgColor' : 'color'
                ],
              }"
            ></div>
          </div>
          <div class="interval-texts">
            <span class="interval-begin-end"
              >{{
                formatSecondsToTime(
                  resizedOrDraggedIntervalKey === `${config.name}-${index}`
                    ? resizedOrDraggedIntervalBegin
                    : interval.begin,
                )
              }}
              -
              {{
                formatSecondsToTime(
                  resizedOrDraggedIntervalKey === `${config.name}-${index}`
                    ? resizedOrDraggedIntervalEnd
                    : interval.end,
                )
              }}</span
            >
            <span class="interval-description"
              >{{ config.label }} -
              {{ configMap.get(config.name).stateMap.get(interval.state).label }}</span
            >
          </div>
          <div
            v-if="editable && !resizedOrDraggedIntervalKey"
            class="interval-drag-handle-bottom"
            @click.stop="() => null"
            @mousedown.stop="resizeMousedown(config.name, index, 'end', $event)"
          >
            <div
              class="interval-drag-handle-bottom--circle"
              :style="{
                backgroundColor: configMap.get(config.name).stateMap.get(interval.state)[
                  interval.wip ? 'newBgColor' : 'color'
                ],
              }"
            ></div>
          </div>
        </div>

        <div
          v-for="interval of forbiddenIntervals[config.name]"
          :key="`${config.name}-forbidden-interval-${interval.id}`"
          class="forbidden-interval"
          :style="{
            top: (interval.begin / 86400) * 2064 + 43 + 2 + 'px',
            bottom: 2064 - (interval.end / 86400) * 2064 + 2 + 'px',
          }"
        ></div>
      </div>

      <v-menu
        v-if="contextMenu.isOpen"
        v-model="contextMenu.isOpen"
        :position-x="contextMenu.x"
        :position-y="contextMenu.y"
        absolute
        offset-y
      >
        <v-list>
          <v-list-item
            v-for="state in configMap
              .get(contextMenu.scheduleName)
              .states.filter(
                (state) =>
                  state.name !==
                  timeSchedule[contextMenu.scheduleName][contextMenu.intervalIndex].state,
              )"
            :key="state.name"
            @click="changeStateFromContextMenu(state.name)"
            link
          >
            <div class="square" :style="{ backgroundColor: state.color }"></div>
            <span>{{ state.label }}</span>
          </v-list-item>
          <v-list-item @click="deleteIntervalFromContextMenu()" link>
            <v-icon class="mr-2">mdi-delete</v-icon>
            {{ $t('global.delete') }}
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>
import { formatSecondsToTime } from '@/utils/timeSchedule'
import startOfDay from 'date-fns/startOfDay'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import produce from 'immer'
import TimeScheduleCalendarAddButton from '@/components/TimeSchedules/TimeScheduleCalendarAddButton'
import mouseMoveScrollNearEdge from '@/mixins/calendar/mouseMoveScrollNearEdge'

export default {
  name: 'TimeScheduleCalendarInput',
  components: { TimeScheduleCalendarAddButton },
  mixins: [mouseMoveScrollNearEdge],
  model: {
    prop: 'timeSchedule',
    event: 'change',
  },
  props: {
    timeScheduleConfig: Object,
    timeSchedule: Object,
    editable: Boolean,
  },
  data() {
    return {
      percentageOfDay: 0,
      updateCurrentTimeInterval: null,
      gettingAnimationFrame: false,

      resizedOrDraggedIntervalKey: null,
      resizedIntervalTop: null,
      resizedOrDraggedIntervalBegin: null,
      resizedOrDraggedIntervalEnd: null,

      contextMenu: {
        isOpen: false,
        x: null,
        y: null,
        scheduleName: null,
        intervalIndex: null,
      },
      addButtonIsDraggedIndex: null,
    }
  },
  created() {
    const now = new Date()
    this.percentageOfDay = (differenceInSeconds(now, startOfDay(now)) / 86400) * 100

    this.updateCurrentTimeInterval = setInterval(() => {
      const now = new Date()
      this.percentageOfDay = (differenceInSeconds(now, startOfDay(now)) / 86400) * 100
    }, 60000)
  },
  beforeDestroy() {
    clearInterval(this.updateCurrentTimeInterval)
  },
  methods: {
    addNewInterval(timeScheduleName, state, begin, end) {
      const newTimeSchedule = produce(this.timeSchedule, (draft) => {
        draft[timeScheduleName].push({
          state,
          begin,
          end,
        })
        draft[timeScheduleName].sort((a, b) => a.begin - b.begin)
      })

      this.$emit('change', newTimeSchedule)
    },
    changeStateFromContextMenu(newState) {
      const newTimeSchedule = produce(this.timeSchedule, (draft) => {
        draft[this.contextMenu.scheduleName][this.contextMenu.intervalIndex].state = newState
      })
      this.$emit('change', newTimeSchedule)
    },
    deleteIntervalFromContextMenu() {
      const newTimeSchedule = produce(this.timeSchedule, (draft) => {
        draft[this.contextMenu.scheduleName].splice(this.contextMenu.intervalIndex, 1)
      })
      this.contextMenu.isOpen = false

      this.$emit('change', newTimeSchedule)
    },
    openContextMenu(timeScheduleName, intervalIndex, e) {
      this.contextMenu = {
        isOpen: true,
        x: e.clientX,
        y: e.clientY,
        scheduleName: timeScheduleName,
        intervalIndex,
      }
    },
    resizeMousedown(timeScheduleName, intervalIndex, beginOrEnd, e) {
      e.preventDefault()
      // clear selection
      if (window.getSelection) {
        window.getSelection().removeAllRanges()
      } else if (document.selection) {
        document.selection.empty()
      }

      this.resizedOrDraggedIntervalKey = `${timeScheduleName}-${intervalIndex}`
      this.resizedOrDraggedIntervalBegin = this.timeSchedule[timeScheduleName][intervalIndex].begin
      this.resizedOrDraggedIntervalEnd = this.timeSchedule[timeScheduleName][intervalIndex].end

      const handleMouseMove = (moveEvent) => {
        if (!this.gettingAnimationFrame) {
          requestAnimationFrame(() => {
            this.gettingAnimationFrame = false

            const boundingRect = this.$refs.wrapper.getBoundingClientRect()
            const realTop = moveEvent.clientY - boundingRect.y + this.$refs.wrapper.scrollTop // real position of the mouse in px from the top of the scrollable div

            // mouse position corresponds to some time in seconds on our timeline
            // same equation as is used in template to calculate top from seconds but reversed
            // rounded to granularity steps
            const mousePositionInSeconds =
              Math.round((((realTop - 45) / 2064) * 86400) / this.timeScheduleConfig.granularity) *
              this.timeScheduleConfig.granularity

            // here we only normalize seconds to not overlap other intervals or go below 0 or above 86400
            if (beginOrEnd === 'begin') {
              this.resizedOrDraggedIntervalBegin = Math.min(
                Math.max(
                  mousePositionInSeconds,
                  intervalIndex > 0
                    ? this.timeSchedule[timeScheduleName][intervalIndex - 1].end
                    : 0,
                ),
                this.timeSchedule[timeScheduleName][intervalIndex].end -
                  this.timeScheduleConfig.granularity,
              )
            } else {
              this.resizedOrDraggedIntervalEnd = Math.min(
                Math.max(
                  mousePositionInSeconds,
                  this.timeSchedule[timeScheduleName][intervalIndex].begin +
                    this.timeScheduleConfig.granularity,
                ),
                intervalIndex < this.timeSchedule[timeScheduleName].length - 1
                  ? this.timeSchedule[timeScheduleName][intervalIndex + 1].begin
                  : 86400,
              )
            }

            this.mouseMoveScrollNearEdge(moveEvent, boundingRect, () => this.$refs.wrapper)
          })
        }
        this.gettingAnimationFrame = true
      }
      const handleMouseUp = () => {
        const newTimeSchedule = produce(this.timeSchedule, (draft) => {
          draft[timeScheduleName][intervalIndex].begin = this.resizedOrDraggedIntervalBegin
          draft[timeScheduleName][intervalIndex].end = this.resizedOrDraggedIntervalEnd
        })

        this.$emit('change', newTimeSchedule)
        this.resizedOrDraggedIntervalKey = null
        this.resizedOrDraggedIntervalBegin = null
        this.resizedOrDraggedIntervalEnd = null
        window.removeEventListener('mouseup', handleMouseUp)
        window.removeEventListener('mousemove', handleMouseMove)
        if (this.scrollInterval) {
          clearInterval(this.scrollInterval)
          this.scrollInterval = null
        }
      }
      window.addEventListener('mouseup', handleMouseUp)
      window.addEventListener('mousemove', handleMouseMove)
    },
    dragMousedown(timeScheduleName, intervalIndex, e) {
      e.preventDefault()
      // clear selection
      if (window.getSelection) {
        window.getSelection().removeAllRanges()
      } else if (document.selection) {
        document.selection.empty()
      }

      this.resizedOrDraggedIntervalKey = `${timeScheduleName}-${intervalIndex}`
      this.resizedOrDraggedIntervalBegin = this.timeSchedule[timeScheduleName][intervalIndex].begin
      this.resizedOrDraggedIntervalEnd = this.timeSchedule[timeScheduleName][intervalIndex].end

      const boundingRect = this.$refs.wrapper.getBoundingClientRect()
      const realTop = e.clientY - boundingRect.y + this.$refs.wrapper.scrollTop // real position of the mouse in px from the top of the scrollable div

      // mouse position corresponds to some time in seconds on our timeline
      // same equation as is used in template to calculate top from seconds but reversed
      const mousePositionInSeconds = ((realTop - 45) / 2064) * 86400

      const beginOffset = mousePositionInSeconds - this.resizedOrDraggedIntervalBegin // distance of mouse from top of interval

      const handleMouseMove = (moveEvent) => {
        if (!this.gettingAnimationFrame) {
          requestAnimationFrame(() => {
            this.gettingAnimationFrame = false

            const boundingRect = this.$refs.wrapper.getBoundingClientRect()
            const realTop = moveEvent.clientY - boundingRect.y + this.$refs.wrapper.scrollTop // real position of the mouse in px from the top of the scrollable div

            // mouse position corresponds to some time in seconds on our timeline
            // same equation as is used in template to calculate top from seconds but reversed
            // rounded to granularity steps
            const newBeginInSeconds =
              Math.round(
                (((realTop - 45) / 2064) * 86400 - beginOffset) /
                  this.timeScheduleConfig.granularity,
              ) * this.timeScheduleConfig.granularity

            const intervalLength =
              this.resizedOrDraggedIntervalEnd - this.resizedOrDraggedIntervalBegin
            const newEndInSeconds = newBeginInSeconds + intervalLength

            // drag up
            if (newBeginInSeconds < this.resizedOrDraggedIntervalBegin) {
              loop1: for (let i = 0; i < this.timeSchedule[timeScheduleName].length; i++) {
                if (i === intervalIndex) {
                  if (i === this.timeSchedule[timeScheduleName].length - 1) {
                    this.resizedOrDraggedIntervalBegin =
                      newBeginInSeconds < 0 ? 0 : newBeginInSeconds
                    this.resizedOrDraggedIntervalEnd =
                      newBeginInSeconds < 0 ? intervalLength : newEndInSeconds
                    break
                  }
                  continue
                }
                if (
                  // would intervals overlap?
                  (newBeginInSeconds >= this.timeSchedule[timeScheduleName][i].begin &&
                    newBeginInSeconds < this.timeSchedule[timeScheduleName][i].end) ||
                  (newEndInSeconds > this.timeSchedule[timeScheduleName][i].begin &&
                    newEndInSeconds <= this.timeSchedule[timeScheduleName][i].end) ||
                  (newBeginInSeconds < this.timeSchedule[timeScheduleName][i].begin &&
                    newEndInSeconds > this.timeSchedule[timeScheduleName][i].end)
                ) {
                  // if intervals overlap we find nearest place where interval fits
                  for (let j = i; j < this.timeSchedule[timeScheduleName].length; j++) {
                    if (
                      (this.timeSchedule[timeScheduleName][j + 1 === intervalIndex ? j + 2 : j + 1]
                        ?.begin ?? 86400) -
                        this.timeSchedule[timeScheduleName][j].end >=
                      intervalLength
                    ) {
                      this.resizedOrDraggedIntervalBegin =
                        this.timeSchedule[timeScheduleName][j].end
                      this.resizedOrDraggedIntervalEnd =
                        this.timeSchedule[timeScheduleName][j].end + intervalLength
                      break loop1
                    }
                  }
                } else if (
                  newBeginInSeconds < this.timeSchedule[timeScheduleName][i].begin ||
                  i === this.timeSchedule[timeScheduleName].length - 1
                ) {
                  this.resizedOrDraggedIntervalBegin = newBeginInSeconds < 0 ? 0 : newBeginInSeconds
                  this.resizedOrDraggedIntervalEnd =
                    newBeginInSeconds < 0 ? intervalLength : newEndInSeconds
                  break
                }
              }
            } else {
              // drag down
              loop1: for (let i = this.timeSchedule[timeScheduleName].length - 1; i >= 0; i--) {
                if (i === intervalIndex) {
                  if (i === 0) {
                    this.resizedOrDraggedIntervalBegin =
                      newEndInSeconds > 86400 ? 86400 - intervalLength : newBeginInSeconds
                    this.resizedOrDraggedIntervalEnd =
                      newEndInSeconds > 86400 ? 86400 : newEndInSeconds
                    break
                  }
                  continue
                }
                if (
                  // would intervals overlap?
                  (newBeginInSeconds >= this.timeSchedule[timeScheduleName][i].begin &&
                    newBeginInSeconds < this.timeSchedule[timeScheduleName][i].end) ||
                  (newEndInSeconds > this.timeSchedule[timeScheduleName][i].begin &&
                    newEndInSeconds <= this.timeSchedule[timeScheduleName][i].end) ||
                  (newBeginInSeconds < this.timeSchedule[timeScheduleName][i].begin &&
                    newEndInSeconds > this.timeSchedule[timeScheduleName][i].end)
                ) {
                  // if intervals overlap we find nearest place where interval fits
                  for (let j = i; j >= 0; j--) {
                    if (
                      this.timeSchedule[timeScheduleName][j].begin -
                        (this.timeSchedule[timeScheduleName][
                          j - 1 === intervalIndex ? j - 2 : j - 1
                        ]?.end ?? 0) >=
                      intervalLength
                    ) {
                      this.resizedOrDraggedIntervalBegin =
                        this.timeSchedule[timeScheduleName][j].begin - intervalLength
                      this.resizedOrDraggedIntervalEnd =
                        this.timeSchedule[timeScheduleName][j].begin
                      break loop1
                    }
                  }
                } else if (
                  newBeginInSeconds > this.timeSchedule[timeScheduleName][i].end ||
                  i === 0
                ) {
                  this.resizedOrDraggedIntervalBegin =
                    newEndInSeconds > 86400 ? 86400 - intervalLength : newBeginInSeconds
                  this.resizedOrDraggedIntervalEnd =
                    newEndInSeconds > 86400 ? 86400 : newEndInSeconds
                  break
                }
              }
            }

            this.mouseMoveScrollNearEdge(moveEvent, boundingRect, () => this.$refs.wrapper)
          })
        }
        this.gettingAnimationFrame = true
      }
      const handleMouseUp = () => {
        const newTimeSchedule = produce(this.timeSchedule, (draft) => {
          draft[timeScheduleName][intervalIndex].begin = this.resizedOrDraggedIntervalBegin
          draft[timeScheduleName][intervalIndex].end = this.resizedOrDraggedIntervalEnd

          draft[timeScheduleName].sort((a, b) => a.begin - b.begin)
        })

        this.$emit('change', newTimeSchedule)
        this.resizedOrDraggedIntervalKey = null
        this.resizedOrDraggedIntervalBegin = null
        this.resizedOrDraggedIntervalEnd = null
        window.removeEventListener('mouseup', handleMouseUp)
        window.removeEventListener('mousemove', handleMouseMove)
        if (this.scrollInterval) {
          clearInterval(this.scrollInterval)
          this.scrollInterval = null
        }
      }
      window.addEventListener('mouseup', handleMouseUp)
      window.addEventListener('mousemove', handleMouseMove)
    },
    scrollTo(seconds) {
      // meant to be called from parent
      // (secondsToScrollTo / numberOfSecondsInDay) * (48 * 43 = heightOfTimeline) - height / 2
      this.$nextTick(() => {
        this.$refs.wrapper.scrollTo({
          top: (seconds / 86400) * 2064 - this.$refs.wrapper.getBoundingClientRect().height / 2,
          behavior: 'smooth',
        })
      })
    },
    formatSecondsToTime,
  },
  computed: {
    ticks() {
      const ticks = []
      for (let i = 0; i <= 24; i++) {
        ticks.push(`${i.toString().length === 1 ? `0${i}` : i}:00`)
      }
      return ticks
    },
    addButtonsPositions() {
      const addButtonsPositions = {}
      this.timeScheduleConfig.schedules.forEach((schedule) => {
        addButtonsPositions[schedule.name] = []
        if (!this.editable || this.timeSchedule[schedule.name].find((interval) => interval.wip)) {
          return // don't display any buttons if this is not edit mode or some interval is in progress of creation
        }

        for (let intervalIndex = 0, beginTime = 0; beginTime < 86400; ) {
          let nextInterval = this.timeSchedule[schedule.name][intervalIndex] || {
            begin: 86400,
            end: 86400,
          }
          if (beginTime < nextInterval.begin) {
            let endTime
            if (beginTime + 3600 > nextInterval.begin) {
              endTime = nextInterval.begin
            } else {
              endTime = (Math.floor(beginTime / 3600) + 1) * 3600
            }
            addButtonsPositions[schedule.name].push({
              begin: beginTime,
              end: endTime,
            })

            if (endTime === nextInterval.begin) {
              beginTime = nextInterval.end
              intervalIndex++
            } else {
              beginTime = endTime
            }
          } else {
            beginTime = nextInterval.end
            intervalIndex++
          }
        }
      })
      return addButtonsPositions
    },
    forbiddenIntervals() {
      const result = {}
      this.timeScheduleConfig.schedules.forEach((timeSchedule) => {
        if (timeSchedule.scheduleForbiddenIf) {
          result[timeSchedule.name] = []
          this.timeSchedule[timeSchedule.scheduleForbiddenIf.key]?.forEach((interval) => {
            if (timeSchedule.scheduleForbiddenIf.c === 'equal') {
              if (!interval.wip && interval.state === timeSchedule.scheduleForbiddenIf.value) {
                result[timeSchedule.name].push({
                  begin: interval.begin,
                  end: interval.end,
                  id: interval.id,
                })
              }
            }
          })
        }
      })
      return result
    },
    configMap() {
      const configMap = new Map()
      this.timeScheduleConfig.schedules.forEach((timeSchedule) => {
        const stateMap = new Map()
        timeSchedule.states.forEach((state) => {
          stateMap.set(state.name, state)
        })
        configMap.set(timeSchedule.name, { ...timeSchedule, stateMap })
      })
      return configMap
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/components/TimeSchedules/timeSchedule.less';

@half-hour-height: 43px;

.square {
  margin-left: 5px;
  margin-right: 14px;
}

.calendar-input-wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content auto;
  flex-grow: 1;
  overflow-y: auto;
}

.timeline {
  .tick {
    background-color: #eee;
    color: #616161;
    font-size: 12px;
    font-weight: 400;
    padding-right: 8px;
    padding-left: 12px;
    height: @half-hour-height * 2;
    line-height: @half-hour-height * 2;

    &:last-child {
      height: @half-hour-height;
      line-height: inherit;
      display: flex;
      align-items: flex-end;
    }
  }
}

.lanes-wrapper {
  position: relative;
  display: grid;
  grid-auto-columns: minmax(0px, 220px);
  grid-auto-flow: column;
}

.time-indicator {
  position: absolute;
  width: 100%;
  height: 1px;
  background: #231f20;
  z-index: 3;
  pointer-events: none;

  span {
    width: 9px;
    height: 9px;
    background: #231f20;
    display: block;
    position: absolute;
    border-radius: 50%;
    top: -4.5px;
    left: -4.5px;
  }
}

.lane-wrapper {
  position: relative;

  .lane-header {
    height: @half-hour-height;
    display: flex;
    align-items: center;
    padding-left: 16px;
    border-left: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    position: sticky;
    top: 0;
    background-color: white;

    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    clip-path: polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%);
    backdrop-filter: blur(45px);

    z-index: 5;

    h2 {
      font-size: 13px;
      font-weight: 500;
    }
  }

  .grid-line {
    height: @half-hour-height;
    border-left: 1px solid #e0e0e0;

    &:nth-child(even) {
      border-bottom: 1px dashed #e0e0e0;
    }
    &:nth-child(odd) {
      border-bottom: 1px solid #e0e0e0;
    }
  }
}

.interval {
  position: absolute;
  border-radius: 4px;
  width: calc(100% - 4px);
  left: 2px;
  cursor: pointer;

  &--normal {
    padding: 8px;
  }

  &--narrow {
    padding: 0px 8px;
    display: flex;
    align-items: center;

    .interval-texts {
      display: flex;
      align-items: center;
    }

    .interval-begin-end {
      margin-right: 10px;
    }
  }

  .interval-texts {
    position: sticky;
    top: calc(@half-hour-height + 5px);
    z-index: 1;
  }

  .interval-begin-end {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: block;
  }

  .interval-description {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: block;
  }

  .interval-drag-handle-top,
  .interval-drag-handle-bottom {
    position: absolute;
    width: 100%;
    height: 3px;
    cursor: row-resize;
    background: transparent;

    &--circle {
      position: absolute;
      width: 12px;
      height: 12px;
      border: 1px solid #ffffff;
      border-radius: 100%;
      display: none;
      z-index: 2;
    }
  }
  .interval-drag-handle-top {
    left: 0;
    top: -1px;

    &--circle {
      left: calc(50% - 6px);
      top: -6px;
    }
  }
  .interval-drag-handle-bottom {
    right: 0;
    bottom: -1px;

    &--circle {
      left: calc(50% - 6px);
      bottom: -6px;
    }
  }

  &:hover {
    .interval-drag-handle-top--circle,
    .interval-drag-handle-bottom--circle {
      display: block;
    }
  }
}

.forbidden-interval {
  position: absolute;
  border-radius: 4px;
  width: calc(100% - 4px);
  left: 2px;
  background: repeating-linear-gradient(
    -45deg,
    rgba(219, 219, 219, 0.3),
    rgba(219, 219, 219, 0.3) 5px,
    rgba(219, 219, 219, 0.8) 5px,
    rgba(219, 219, 219, 0.8) 25px
  );
  pointer-events: none;
}
</style>
