<template>
  <div class="search-wrapper">
    <div class="header--input--wrapper search">
      <svg class="header--input--icon header--input--icon--1" width="13" height="13">
        <use xlink:href="#icon-search"></use>
      </svg>
      <svg class="header--input--icon header--input--icon--2" width="20" height="20">
        <use xlink:href="#icon-filter-list"></use>
      </svg>
      <input
        @click="searchActive = !searchActive"
        class="header--input"
        type="text"
        name="_search"
        id="_search"
        v-model="searchText"
        :placeholder="$t('device.searchDevice')"
        autocomplete="off"
      />
      <div
        v-if="searchActive && searchDevices"
        class="search--results"
        v-click-outside="closeSearch"
      >
        <div class="device--wrap" @click="closeSearch">
          <DeviceLink
            class="device--item"
            v-for="device in searchDevices"
            :key="device.controller"
            :device="device"
            :type="device.type"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceLink from '@/components/DeviceListPanel/DeviceLink'
import { searchDevices } from '../../../graphql/query/device'

export default {
  name: 'DeviceSearch',
  components: { DeviceLink },

  data: function () {
    return {
      searchText: '',
      searchActive: false,
    }
  },
  methods: {
    closeSearch: function () {
      this.searchActive = false
    },
  },
  watch: {
    searchText: function (newValue) {
      if (newValue === '') {
        this.searchActive = false
      }
      if (newValue !== '') {
        this.searchActive = true
      }
    },
  },
  apollo: {
    searchDevices: {
      query: searchDevices,
      variables: function () {
        return {
          searchText: this.searchText,
          companyId: this.$store.getters['user/currentCompanyId'],
        }
      },
      skip: function () {
        return this.searchText === ''
      },
    },
  },
}
</script>

<style scoped lang="less">
.header--input {
  border-radius: 0.188rem;
  background: #f5f5f5;
  color: #161c4b;
  font-size: 16px;
  font-weight: 400;
  min-height: 36px;
  border: none;
  width: 100%;
  padding: 7px 40px 7px 43px;

  &::placeholder {
    opacity: 0.4;
  }

  &--wrapper {
    position: relative;
  }

  &--icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    color: #161c4b;

    &--1 {
      left: 15px;
    }

    &--2 {
      right: 10px;
    }
  }
}

.search {
  position: relative;

  &--results {
    position: absolute;
    z-index: 200;
    top: 100%;
    left: 0;
    right: 0;
    max-height: calc(100vh - 56px - 10px);
    overflow: auto;
    background: #ffffff;
    box-shadow: 0 0.063rem 0.188rem rgba(0, 0, 0, 0.14);
  }

  @media (max-width: 479px) {
    &--results {
      top: 100%;
      max-height: calc(100vh - 85px);
    }
  }
}
</style>
