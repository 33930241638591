import gql from 'graphql-tag'
import { CompanyUserWithPermissions } from '@/graphql/fragments/companyUserWithPermissions'

export const companyUsers = gql`
  query companyUsers($companyId: ID!, $withPermissions: Boolean! = true) {
    company(companyId: $companyId) {
      id
      users {
        ...CompanyUserWithPermissions
      }
    }
  }
  ${CompanyUserWithPermissions}
`
