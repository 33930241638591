<template>
  <div>
    <div v-if="label" class="label" :class="{ errorLabel: hasError }">
      {{ label }}
    </div>
    <div class="d-flex" :class="{ hasLabel: Boolean(label) }">
      <v-text-field
        v-model="internalValue.days"
        :error-messages="daysErrors"
        :error="hasError"
        :label="$t('global.days')"
        @input="$v.internalValue.days.$touch()"
        @blur="$v.internalValue.days.$touch()"
        filled
        hide-details
        style="width: 100px"
        type="number"
        min="0"
      ></v-text-field>
      <v-text-field
        v-model="internalValue.hours"
        :error-messages="hoursErrors"
        :error="hasError"
        :label="$t('global.hours')"
        @input="$v.internalValue.hours.$touch()"
        @blur="$v.internalValue.hours.$touch()"
        filled
        hide-details
        style="width: 100px"
        type="number"
        min="0"
        max="23"
      ></v-text-field>
      <v-text-field
        v-model="internalValue.minutes"
        :error-messages="minutesErrors"
        :error="hasError"
        :label="$t('global.minutes')"
        @input="$v.internalValue.minutes.$touch()"
        @blur="$v.internalValue.minutes.$touch()"
        filled
        hide-details
        style="width: 100px"
        type="number"
        min="0"
        max="59"
      ></v-text-field>
    </div>
    <div class="errorMessage">{{ (errorMessages && errorMessages[0]) || internalErrors[0] }}</div>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { minValue, maxValue, integer } from 'vuelidate/lib/validators'

export default {
  name: 'DurationInput',
  mixins: [validationMixin],
  props: {
    value: Number,
    label: String,
    errorMessages: Array,
  },
  data() {
    return {
      internalValue: {
        days: null,
        hours: null,
        minutes: null,
      },
    }
  },
  validations: {
    internalValue: {
      days: { integer, minValue: minValue(0) },
      hours: { integer, minValue: minValue(0), maxValue: maxValue(23) },
      minutes: { integer, minValue: minValue(0), maxValue: maxValue(59) },
    },
  },
  methods: {
    emitNewValue() {
      if (this.internalErrors.length === 0) {
        const isNull = (val) => val == null || val === ''
        if (
          isNull(this.internalValue.days) &&
          isNull(this.internalValue.hours) &&
          isNull(this.internalValue.minutes)
        ) {
          this.$emit('input', null)
        } else {
          this.$emit(
            'input',
            (this.internalValue.days ? Number(this.internalValue.days) : 0) * 1440 +
              (this.internalValue.hours ? Number(this.internalValue.hours) : 0) * 60 +
              (this.internalValue.minutes ? Number(this.internalValue.minutes) : 0),
          )
        }
      }
    },
  },
  computed: {
    hasError() {
      return Boolean((this.errorMessages && this.errorMessages[0]) || this.internalErrors[0])
    },
    daysErrors() {
      const errors = []
      if (!this.$v.internalValue.days.$dirty) {
        return errors
      }
      !this.$v.internalValue.days.integer && errors.push(this.$t('global.formValidation.integer'))
      !this.$v.internalValue.days.minValue &&
        errors.push(this.$t('global.formValidation.min', { min: 0 }))
      return errors
    },
    hoursErrors() {
      const errors = []
      if (!this.$v.internalValue.hours.$dirty) {
        return errors
      }
      !this.$v.internalValue.hours.integer && errors.push(this.$t('global.formValidation.integer'))
      !this.$v.internalValue.hours.minValue &&
        errors.push(this.$t('global.formValidation.min', { min: 0 }))
      !this.$v.internalValue.hours.maxValue &&
        errors.push(this.$t('global.formValidation.max', { max: 23 }))
      return errors
    },
    minutesErrors() {
      const errors = []
      if (!this.$v.internalValue.minutes.$dirty) {
        return errors
      }
      !this.$v.internalValue.minutes.integer &&
        errors.push(this.$t('global.formValidation.integer'))
      !this.$v.internalValue.minutes.minValue &&
        errors.push(this.$t('global.formValidation.min', { min: 0 }))
      !this.$v.internalValue.minutes.maxValue &&
        errors.push(this.$t('global.formValidation.max', { max: 59 }))
      return errors
    },
    internalErrors() {
      return [
        ...this.daysErrors.map((e) => `${this.$t('global.days')}: ${e}`),
        ...this.hoursErrors.map((e) => `${this.$t('global.hours')}: ${e}`),
        ...this.minutesErrors.map((e) => `${this.$t('global.minutes')}: ${e}`),
      ]
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue != null) {
          const days = Math.floor(newValue / 1440)
          this.internalValue.days = days

          const hours = Math.floor((newValue % 1440) / 60)
          this.internalValue.hours = hours

          const minutes = Math.floor(newValue % 60)
          this.internalValue.minutes = minutes
        } else {
          this.internalValue.days = null
          this.internalValue.hours = null
          this.internalValue.minutes = null
        }
      },
    },
    internalValue: {
      deep: true,
      handler() {
        this.emitNewValue()
      },
    },
    internalErrors() {
      this.$emit('hasError', Boolean(this.internalErrors.length))
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables';

.label {
  background-color: #f0f0f0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 5px 5px 2px 13px;
  color: rgba(0, 0, 0, 0.6);

  &.errorLabel {
    color: @color-error;
  }
}

.errorMessage {
  color: @color-error;
  font-size: 12px;
  padding: 4px 12px;
}

.hasLabel /deep/.v-text-field--filled {
  border-radius: 0;
}

/deep/.v-text-field--filled {
  &:nth-child(2) {
    border-radius: 0;
  }

  &:first-child {
    border-top-right-radius: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
  }
}
</style>
