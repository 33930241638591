import gql from 'graphql-tag'

export const serviceOrderAssignableUsers = gql`
  query serviceOrderAssignableUsers($deviceId: ID!) {
    device(deviceId: $deviceId) {
      id
      serviceOrderAssignableUsers {
        id
        email
        firstName
        lastName
      }
    }
  }
`
