<template>
  <div class="service-sequence-wizard-wrapper" :style="{ flexBasis: modalHeight }">
    <!-- WIP header to show in app not sure if it'll be needed -->
    <!-- <header class="d-flex align-center" v-if="soloPage">
      <IXFieldLogo class="logo-full" width="134" height="30" />
      <h1>{{ $t('global.serviceSequenceWizardHeader') }}</h1>
    </header> -->
    <div
      v-if="
        $apollo.queries.device.loading ||
        ($apollo.queries.serviceSequenceConfig.loading && !serviceSequenceConfig)
      "
      class="sequence-loading-wrapper"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else-if="!device || !isAllowedAccess">
      <v-alert prominent type="error">
        <v-row align="center">
          <v-col class="grow">
            {{
              device
                ? $t('device.insufficientPermissionsToViewServiceSequence')
                : $t('device.deviceNotFound')
            }}
          </v-col>
          <v-col class="shrink">
            <v-btn @click="close()">{{ $t('global.close') }}</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>
    <template v-else>
      <div v-if="serviceSequenceState === undefined" class="sequence-loading-wrapper">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-container v-else class="layout-container">
        <ServiceSequenceLayoutRow
          :config="layoutConfig"
          :componentConfigs="serviceSequenceConfig.components"
          v-resize="onResize"
        />
        <v-btn @click="tryClose" v-if="soloPage && !screenConfig.notCloseable" text class="mt-2">{{
          $t('global.cancelSequence')
        }}</v-btn>
      </v-container>
      <ServiceSequenceConfirmationDialog
        v-if="serviceSequenceConfig.globalAbort.confirmation"
        v-model="confirmAbortModalOpen"
        :config="serviceSequenceConfig.globalAbort.confirmation"
        :command="serviceSequenceConfig.globalAbort.command"
      />
    </template>
  </div>
</template>
<script>
import { serviceSequenceState } from '@/graphql/query/serviceSequenceState'
import { serviceSequenceConfig } from '@/graphql/query/serviceSequenceConfig'
import { getDevicePermissionsAndEligibilities } from '@/graphql/query/device'
import { deviceSendCommand } from '@/graphql/mutations/deviceSendCommand'
import { permissions } from '@/config/permissions'
import { allowedLanguages } from '@/translations'
import { mapGetters } from 'vuex'
import ServiceSequenceLayoutRow from '@/components/ServiceSequence/layouts/ServiceSequenceLayoutRow'
import ServiceSequenceConfirmationDialog from '@/components/ServiceSequence/ServiceSequenceConfirmationDialog'

export default {
  name: 'ServiceSequenceWizard',
  components: {
    ServiceSequenceLayoutRow,
    ServiceSequenceConfirmationDialog,
  },
  props: {
    deviceId: String,
    serviceSequenceName: String,
    soloPage: Boolean,
    closeFromOutside: Boolean,
    width: [String, Number],
  },
  data() {
    return {
      confirmAbortModalOpen: false,
      shouldCloseWhenReturnToInitial: false,
      stateChangeTimeout: null,
      stateToChangeFrom: undefined,
      openConfirmationModal: null,
      windowWidth: null,
      isTechnician: null,
      artificialStateIndex: 0, // used to keep track of service sequence states with equal shadow state(s)
      providedData: {
        serviceSequenceLiveData: null,
        handleCommand: this.handleCommand,
        loadingAction: null,
        isDeviceOffline: false,
      },
    }
  },
  provide() {
    return {
      reactive: this.providedData,
    }
  },
  created() {
    if (this.$route.query.lang && allowedLanguages.includes(this.$route.query.lang)) {
      this.$store.commit('changeLocale', this.$route.query.lang)
    }
    if (this.$route.query.isTechnician) {
      this.isTechnician = this.$route.query.isTechnician
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth
  },
  apollo: {
    device: {
      query: getDevicePermissionsAndEligibilities,
      variables() {
        return {
          deviceId: this.deviceId,
        }
      },
    },
    serviceSequenceConfig: {
      query: serviceSequenceConfig,
      variables() {
        return {
          deviceId: this.deviceId,
          serviceSequenceName: this.serviceSequenceName,
          lang: this.$i18n.locale,
        }
      },
      update(data) {
        return data.device?.liveDeviceData.serviceSequenceConfig
      },
    },
    serviceSequenceState: {
      query: serviceSequenceState,
      variables() {
        return {
          deviceId: this.deviceId,
          serviceSequenceName: this.serviceSequenceName,
        }
      },
      update(data) {
        // update is offline status
        this.providedData.isDeviceOffline = data.device.connectionStatus === 'OFFLINE'
        this.providedData.serviceSequenceLiveData = {
          ...data.device?.liveDeviceData.serviceSequenceLiveData,
        }

        return data.device?.liveDeviceData.serviceSequenceState
      },
      pollInterval() {
        return this.isAllowedAccess ? 1000 : 0
      },
    },
  },
  methods: {
    async handleCommand(command, inputValue = null) {
      if (command.startsWith('$')) {
        // handle special commands
        switch (command) {
          case '$NEXT':
            this.artificialStateIndex++
            break
          default:
            console.error(`Unknown service sequence special command ${command}`)
        }
      } else {
        await this.sendCommand(command, inputValue)
      }
    },
    async sendCommand(command, inputValue = null) {
      try {
        this.stateToChangeFrom = this.serviceSequenceState
        await this.$apollo.mutate({
          mutation: deviceSendCommand,
          variables: {
            deviceId: this.deviceId,
            serviceSequenceName: this.serviceSequenceName,
            setValue: command,
            setInputValue: inputValue,
          },
        })

        this.providedData.loadingAction = command
        this.stateChangeTimeout = setTimeout(() => {
          this.stateToChangeFrom = undefined
          this.stateChangeTimeout = null
          this.providedData.loadingAction = null

          this.$toast.error(this.$t('global.notResponding'))
        }, 10000)
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.serviceSequenceSendCommandFailure'))
      }
    },
    updateWidth() {
      if (this.serviceSequenceConfig) {
        if (this.screenConfig) {
          if (this.screenConfig.modalWidth != null) {
            this.$emit('update:width', this.screenConfig.modalWidth)
          } else if (this.serviceSequenceConfig.modalWidth != null) {
            this.$emit('update:width', this.serviceSequenceConfig.modalWidth)
          }
        }
      }
    },
    close() {
      if (window.ReactNativeWebView) {
        // close web view in app
        window.ReactNativeWebView.postMessage('close')
      }
      this.$emit('close')
    },
    tryClose() {
      if (!this.screenConfig || this.screenConfig.closeableWithoutAbort) {
        this.close()
      } else {
        if (this.serviceSequenceConfig.globalAbort.confirmation) {
          this.confirmAbortModalOpen = true
        } else {
          this.handleCommand(this.serviceSequenceConfig.globalAbort.command)
        }
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    isAllowedAccess() {
      if (!this.device || !this.serviceSequenceConfig) {
        return null
      }

      if (this.device.userPermissions) {
        if (!this.device.userPermissions[permissions.SERVICE_SEQUENCES]) {
          return false
        }
      }
      return this.device.eligibilities[this.serviceSequenceConfig.requiredEligibility]
    },
    screenConfig() {
      if (!this.serviceSequenceConfig || this.serviceSequenceState === undefined) {
        return null
      }
      const relevantStates = this.serviceSequenceConfig.statesConfig.filter((config) =>
        config.states.includes(this.serviceSequenceState),
      )
      if (relevantStates.length === 0) {
        console.error(
          `No screen config found for state ${this.serviceSequenceState} in service sequence ${this.serviceSequenceName}`,
        )
        return null
      }
      if (!relevantStates[this.artificialStateIndex]) {
        console.error(
          `Could not perform $NEXT command in state ${this.serviceSequenceState} to index ${this.artificialStateIndex} in service sequence ${this.serviceSequenceName}`,
        )
        return relevantStates.at(-1)
      }
      return relevantStates[this.artificialStateIndex]
    },
    layoutConfig() {
      if (!this.screenConfig) {
        return null
      }
      if (this.windowWidth < (this.serviceSequenceConfig.mobileLayoutBreakpoint ?? 600)) {
        return this.screenConfig.mobileLayout
      } else {
        return this.screenConfig.desktopLayout
      }
    },
    modalHeight() {
      if (this.screenConfig) {
        if (this.screenConfig.modalHeight != null) {
          return this.screenConfig.modalHeight
        } else if (this.serviceSequenceConfig.modalHeight != null) {
          return this.serviceSequenceConfig.modalHeight
        }
      }
      return 'auto'
    },
  },
  watch: {
    serviceSequenceConfig() {
      this.updateWidth()
    },
    serviceSequenceState() {
      this.updateWidth()

      this.artificialStateIndex = 0 // reset artificial state index

      if (
        this.stateToChangeFrom !== undefined &&
        this.stateToChangeFrom !== this.serviceSequenceState
      ) {
        clearTimeout(this.stateChangeTimeout)
        this.stateToChangeFrom = undefined
        this.stateChangeTimeout = null
        this.providedData.loadingAction = null
        this.openConfirmationModal = null
      }

      if (!this.screenConfig?.initial) {
        // we assume that if sequence got to the state other than initial and then it returns to the initial state, it should be closed
        this.shouldCloseWhenReturnToInitial = true
      }

      if (this.shouldCloseWhenReturnToInitial && this.screenConfig?.initial) {
        this.close()
      }

      this.confirmAbortModalOpen = false
    },
    screenConfig() {
      if (this.screenConfig?.notCloseable) {
        this.$emit('disableClose', false)
      } else {
        this.$emit('disableClose', true)
      }
    },
    closeFromOutside() {
      if (this.closeFromOutside) {
        this.tryClose()
        this.$emit('update:closeFromOutside', false) // enable outside to close again
      }
    },
  },
}
</script>
<style lang="less" scoped>
.service-sequence-wizard-wrapper {
  background: white;
  padding: 20px 28px;
  border-radius: 16px;
  overflow: auto;

  @media screen and (max-width: 768px) {
    padding: 5px 5px;
  }

  .sequence-loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .layout-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    /deep/.col {
      display: flex;
      flex-direction: column;
    }
  }

  .confirmation-modal-actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}
</style>
