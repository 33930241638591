import gql from 'graphql-tag'

export const eligibilityTemplates = gql`
  query eligibilityTemplates($companyId: ID!, $thingType: NonEmptyString, $lang: NonEmptyString) {
    company(companyId: $companyId) {
      id
      thingTypes {
        name
        businessName
      }
      eligibilityTemplates(thingType: $thingType, lang: $lang) {
        id
        name
        eligibilities
        thingTypes {
          name
          businessName
        }
      }
    }
  }
`
