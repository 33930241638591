import gql from 'graphql-tag'

export const coreCauseUnassignCorrectiveAction = gql`
  mutation coreCauseUnassignCorrectiveAction($coreCauseId: ID!, $correctiveActionId: ID!) {
    coreCauseUnassignCorrectiveAction(
      input: { coreCauseId: $coreCauseId, correctiveActionId: $correctiveActionId }
    ) {
      success
    }
  }
`
