<template>
  <div class="d-flex">
    <div class="device-list-panel-wrapper">
      <DeviceListPanel v-if="device" :type="device.type" />
    </div>
    <DeviceDetail
      :device="device"
      :devicePermissions="devicePermissions"
      :isLoading="$apollo.loading"
      @refetchDevice="refetchDevice()"
    />
  </div>
</template>

<script>
import DeviceDetail from '@/components/DeviceDetail/DeviceDetail'
import DeviceListPanel from '@/components/DeviceListPanel/DeviceListPanel'
import { getDevice, deviceLiveData, getDevicePermissions } from '@/graphql/query/device'
import { permissions } from '@/config/permissions'
import { mapGetters } from 'vuex'
import { EventBus } from '@/helper/EventBus'

export default {
  name: 'DevicePage',
  components: { DeviceListPanel, DeviceDetail },
  apollo: {
    devicePermissions: {
      query: getDevicePermissions,
      variables() {
        return {
          id: this.$route.params.deviceId,
        }
      },
      update(data) {
        return data.device.userPermissions
      },
      skip() {
        return !this.currentCompanyId
      },
    },
    device: {
      query: getDevice,
      variables() {
        return {
          id: this.$route.params.deviceId,
          lang: this.$i18n.locale,
          withFullCustomerData:
            !this.currentCompanyId ||
            Boolean(this.devicePermissions[permissions.FULL_CUSTOMER_DATA]),
          withRestrictedCustomerData:
            !this.currentCompanyId ||
            Boolean(
              this.devicePermissions[permissions.RESTRICTED_CUSTOMER_DATA] ||
                this.devicePermissions[permissions.FULL_CUSTOMER_DATA],
            ),
          withGrafanaLink:
            !this.currentCompanyId || this.devicePermissions[permissions.OPERATIONAL_HISTORY],
          directAccessView: !this.currentCompanyId,
        }
      },
      skip() {
        return !this.devicePermissions && this.currentCompanyId
      },
    },
    deviceOnlyLiveData: {
      query: deviceLiveData,
      variables() {
        return {
          id: this.$route.params.deviceId,
          lang: this.$i18n.locale,
        }
      },
      skip() {
        return !this.device
      },
      update(data) {
        return data.device
      },
      pollInterval: 5000,
    },
  },
  methods: {
    refetchDevice() {
      this.$apollo.queries.device.refetch()
      EventBus.$emit('refetchDevice')
    },
    beforeRouteLeaveAndUpdate(next) {
      const goNext = () => {
        if (this.device?.isControlsOverrideEnabled) {
          this.endOverrideDeviceControl()
        }
        next()
      }

      if (this.isUploadingImages || this.hasUnsavedChanges) {
        const answer = window.confirm(this.$t('global.unsavedChanges'))
        if (answer) {
          goNext()
        } else {
          next(false)
        }
      } else {
        goNext()
      }
    },
    zoomOutMobile() {
      const viewport = document.querySelector('meta[name="viewport"]')

      if (viewport) {
        viewport.content = 'initial-scale=1'
        viewport.content = 'width=device-width'
      }
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    ...mapGetters(['isUploadingImages', 'hasUnsavedChanges']),
  },
  beforeRouteUpdate(from, to, next) {
    this.beforeRouteLeaveAndUpdate(next)
  },
  beforeRouteLeave(from, to, next) {
    this.beforeRouteLeaveAndUpdate(next)
  },
  beforeMount() {
    this.zoomOutMobile()
  },
}
</script>

<style scoped lang="less">
.device-list-panel-wrapper {
  border-right: 1px solid #eeeeee;
  overflow: auto;
  flex-shrink: 0;
}

@media (max-width: 933px) {
  .device-list-panel-wrapper {
    display: none;
  }
}
</style>
