import gql from 'graphql-tag'

export const ChildCompaniesPermissions = gql`
  fragment ChildCompaniesPermissions on Permission {
    id
    configurationChanges
    configurationUpload
    deviceControl
    expertMode
    operationalHistory
    restrictedCustomerData
    fullCustomerData
    userManagement
    serviceSequences
  }
`
