<template>
  <div>
    <v-btn @click="createModalOpen = true" class="mb-3" color="primary" depressed>{{
      $t('global.createServiceOrder')
    }}</v-btn>

    <v-data-table
      :headers="headers"
      :items="serviceOrders"
      :loading="$apollo.loading"
      :search="search"
      :item-class="() => 'row-wrapper'"
      @click:row="(item) => openEditModal(item)"
      :no-data-text="$t('users.noMatchingRecordsFound')"
      :footer-props="{
        'items-per-page-text': $t('global.dataTable.itemsPerPageText'),
        'items-per-page-all-text': $t('global.dataTable.allItemsText'),
      }"
      disable-pagination
      hide-default-footer
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-text-field v-model="search" :label="$t('users.search')" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.assignee="{ item }">
        <template v-if="item.assignee">
          <div v-if="item.assignee.firstName.length">
            <span>{{ item.assignee.firstName }} {{ item.assignee.lastName }}</span>
            <span class="secondary-text">{{ item.assignee.email }}</span>
          </div>
          <span v-else>{{ item.assignee.email }}</span>
        </template>
      </template>
      <template v-slot:item.author="{ item }">
        <template v-if="item.author">
          <div v-if="item.author.firstName.length">
            <span>{{ item.author.firstName }} {{ item.author.lastName }}</span>
            <span class="secondary-text">{{ item.author.email }}</span>
          </div>
          <span v-else>{{ item.author.email }}</span>
        </template>
      </template>
    </v-data-table>

    <v-dialog
      v-model="createModalOpen"
      @click:outside="confirmCancelOrCancel()"
      width="unset"
      max-width="500"
      :persistent="hasUnsavedChanges"
    >
      <v-card>
        <div class="pa-6">
          <h2 class="mb-7">{{ $t('global.createServiceOrder') }}</h2>
          <v-form @submit.prevent="createServiceOrder" class="form">
            <v-textarea
              v-model="createForm.text"
              :label="$t('global.serviceOrderText')"
              filled
              rows="3"
            ></v-textarea>
            <v-select
              v-model="createForm.status"
              :items="serviceOrderStatuses"
              :error-messages="getErrors('createForm.status')"
              filled
              :label="$t('global.status')"
            ></v-select>
            <v-select
              v-model="createForm.assigneeId"
              :items="serviceOrderAssignableUsers"
              item-value="id"
              :item-text="(user) => getUserName(user)"
              filled
              :label="$t('global.assignee')"
            ></v-select>
            <div class="d-flex justify-end mt-8">
              <v-btn @click="confirmCancelOrCancel()" depressed>{{ $t('global.cancel') }}</v-btn>
              <v-btn type="submit" color="primary" class="ml-3" :loading="isCreating" depressed>{{
                $t('global.save')
              }}</v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="serviceOrderToEdit"
      v-model="editModalOpen"
      @click:outside="confirmCancelOrCancel()"
      width="unset"
      :persistent="hasUnsavedChanges"
    >
      <v-card>
        <div class="pa-6">
          <h2 class="mb-7">{{ $t('global.serviceOrder') }}: {{ serviceOrderToEdit.number }}</h2>
          <v-textarea
            v-model="createForm.text"
            :label="$t('global.serviceOrderText')"
            filled
            rows="3"
          ></v-textarea>
          <v-select
            v-model="createForm.status"
            :items="serviceOrderStatuses"
            :error-messages="getErrors('createForm.status')"
            filled
            :label="$t('global.status')"
          ></v-select>
          <v-select
            v-model="createForm.assigneeId"
            :items="serviceOrderAssignableUsers"
            item-value="id"
            :item-text="(user) => getUserName(user)"
            filled
            clearable
            :label="$t('global.assignee')"
          ></v-select>
          <div>
            <dl class="service-order-data">
              <dt>{{ $t('global.serviceOrderNumber') }}</dt>
              <dd>{{ serviceOrderToEdit.number }}</dd>
              <dt>{{ $t('global.createdBy') }}</dt>
              <dd>
                <template v-if="serviceOrderToEdit.author">
                  {{
                    `${serviceOrderToEdit.author.firstName} ${serviceOrderToEdit.author.lastName} (${serviceOrderToEdit.author.email})`
                  }}
                </template>
              </dd>
              <dt>{{ $t('global.createdAt') }}</dt>
              <dd>{{ serviceOrderToEdit.createdAt }}</dd>
              <dt>{{ $t('global.deviceName') }}</dt>
              <dd>{{ serviceOrderToEdit.thingName }}</dd>
              <dt>{{ $t('device.controller') }}</dt>
              <dd>{{ serviceOrderToEdit.controller }}</dd>
              <dt>{{ $t('global.address') }}</dt>
              <dd>{{ serviceOrderToEdit.contactAddress }}</dd>
              <dt>{{ $t('global.contactName') }}</dt>
              <dd>{{ serviceOrderToEdit.contactPerson }}</dd>
            </dl>
          </div>
          <v-expansion-panels class="mt-3">
            <v-expansion-panel>
              <v-expansion-panel-header>{{ $t('global.thingData') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="tab of serviceOrderToEdit.dataSnapshot.items" :key="tab.label">
                  <h3 class="mb-1 mt-2">{{ tab.label }}</h3>
                  <dl>
                    <template v-for="item of tab.items">
                      <dt :key="`${item.name}-term`">{{ item.label }}</dt>
                      <dd :key="item.name">{{ getValue(item) }}</dd>
                    </template>
                  </dl>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="d-flex flex-wrap mt-8">
            <v-btn
              @click="confirmDeleteModalOpen = true"
              depressed
              color="error"
              class="mr-auto"
              :class="{ 'mb-1': showBlockButtons }"
              :block="showBlockButtons"
              >{{ $t('global.delete') }}</v-btn
            >
            <v-btn
              @click="confirmCancelOrCancel()"
              depressed
              :class="{ 'mb-1': showBlockButtons, 'mr-3': !showBlockButtons }"
              :block="showBlockButtons"
              >{{ $t('global.cancel') }}</v-btn
            >
            <v-btn
              @click="updateServiceOrder()"
              color="primary"
              depressed
              :block="showBlockButtons"
              >{{ $t('global.save') }}</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
    <ConfirmationDialog
      v-if="serviceOrderToEdit"
      v-model="confirmDeleteModalOpen"
      :heading="$t('global.serviceOrderDeleteConfirmation.heading')"
      :text="
        $t('global.serviceOrderDeleteConfirmation.text', {
          serviceOrderNumber: serviceOrderToEdit.number,
        })
      "
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
      :action="deleteServiceOrder"
    />
    <ConfirmationDialog
      v-model="confirmCancelEditDialogOpen"
      :heading="$t('device.timeSchedule.editModal.cancelConfirmation.heading')"
      :text="$t('device.timeSchedule.editModal.cancelConfirmation.text')"
      :cancelText="$t('global.continueEditing')"
      :confirmText="$t('global.discardChanges')"
      confirmBtnColor="error"
      :action="cancel"
    />
  </div>
</template>
<script>
import { serviceOrders } from '@/graphql/query/serviceOrders'
import { serviceOrderStatusEnumValues } from '@/graphql/query/serviceOrderStatusEnumValues'
import { serviceOrderAssignableUsers } from '@/graphql/query/serviceOrderAssignableUsers'
import { serviceOrderCreate } from '@/graphql/mutations/serviceOrderCreate'
import { serviceOrderUpdate } from '@/graphql/mutations/serviceOrderUpdate'
import { serviceOrderDelete } from '@/graphql/mutations/serviceOrderDelete'
import format from 'date-fns/format'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import vuelidateErrorsExtractor from '@/mixins/vuelidateErrorsExtractor'
import valueFormatter from '@/mixins/valueFormatter'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { mapGetters } from 'vuex'

export default {
  name: 'ServiceOrdersTab',
  mixins: [validationMixin, vuelidateErrorsExtractor, valueFormatter],
  components: { ConfirmationDialog },
  props: {
    device: Object,
    deviceDetailWrapper: HTMLDivElement,
  },
  data() {
    return {
      search: null,
      headers: [
        { text: this.$t('global.serviceOrderNumber'), value: 'number' },
        { text: this.$t('global.status'), value: 'statusTranslation' },
        { text: this.$t('global.assignee'), value: 'assignee' },
        { text: this.$t('global.createdBy'), value: 'author' },
        { text: this.$t('global.createdAt'), value: 'createdAt' },
      ],
      createModalOpen: false,
      createForm: {
        text: null,
        status: 'OPEN',
        assigneeId: null,
      },
      serviceOrderToEdit: null,
      editModalOpen: false,
      confirmDeleteModalOpen: false,
      confirmCancelEditDialogOpen: false,
      isCreating: false,
    }
  },
  validations: {
    createForm: {
      status: { required },
    },
  },
  apollo: {
    serviceOrders: {
      query: serviceOrders,
      variables() {
        return {
          deviceId: this.device.id,
          lang: this.$i18n.locale,
        }
      },
      update(data) {
        return data.device.serviceOrders.edges.map((edge) => ({
          ...edge.node,
          statusTranslation: this.$t(`global.serviceOrderStatuses.${edge.node.status}`),
          createdAt: format(new Date(edge.node.createdAt), 'd.M.Y, H:mm'),
        }))
      },
    },
    serviceOrderStatuses: {
      query: serviceOrderStatusEnumValues,
      update(data) {
        return data.__type.enumValues.map((value) => ({
          value: value.name,
          text: this.$t(`global.serviceOrderStatuses.${value.name}`),
        }))
      },
    },
    serviceOrderAssignableUsers: {
      query: serviceOrderAssignableUsers,
      variables() {
        return {
          deviceId: this.device.id,
        }
      },
      update(data) {
        return data.device.serviceOrderAssignableUsers
      },
    },
  },
  methods: {
    async createServiceOrder() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          this.isCreating = true
          await this.$apollo.mutate({
            mutation: serviceOrderCreate,
            variables: {
              deviceId: this.device.id,
              text: this.createForm.text,
              status: this.createForm.status,
              assigneeId: this.createForm.assigneeId,
            },
            refetchQueries: ['serviceOrders'],
          })
          this.$toast.success(this.$t('global.createServiceOrderAction.success'))
          this.createModalOpen = false
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.createServiceOrderAction.failure'))
        } finally {
          this.isCreating = false
        }
      }
    },
    async updateServiceOrder() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: serviceOrderUpdate,
            variables: {
              serviceOrderId: this.serviceOrderToEdit.id,
              text: this.createForm.text,
              status: this.createForm.status,
              assigneeId: this.createForm.assigneeId,
            },
            refetchQueries: ['serviceOrders'],
          })
          this.$toast.success(this.$t('global.updateServiceOrderAction.success'))
          this.editModalOpen = false
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.updateServiceOrderAction.failure'))
        }
      }
    },
    async deleteServiceOrder() {
      try {
        await this.$apollo.mutate({
          mutation: serviceOrderDelete,
          variables: {
            serviceOrderId: this.serviceOrderToEdit.id,
          },
          refetchQueries: ['serviceOrders'],
        })
        this.$toast.success(this.$t('global.deleteServiceOrderAction.success'))
        this.confirmDeleteModalOpen = false
        this.editModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.deleteServiceOrderAction.failure'))
      }
    },
    getUserName(user) {
      return user.firstName && user.firstName.trim() !== ''
        ? `${user.firstName} ${user.lastName}`
        : user.email
    },
    resetForm() {
      this.createForm.text = null
      this.createForm.status = 'OPEN'
      this.createForm.assigneeId = null
    },
    openEditModal(serviceOrder) {
      this.serviceOrderToEdit = serviceOrder
      this.createForm.text = serviceOrder.text
      this.createForm.status = serviceOrder.status
      this.createForm.assigneeId = serviceOrder.assignee?.id
      this.editModalOpen = true
    },
    confirmCancelOrCancel() {
      this.hasUnsavedChanges ? (this.confirmCancelEditDialogOpen = true) : this.cancel()
    },
    cancel() {
      this.confirmDeleteModalOpen = false
      this.editModalOpen = false
      this.createModalOpen = false
      this.confirmCancelEditDialogOpen = false
      this.resetForm()
    },
    getValue(item) {
      switch (item.type) {
        case 'NUMBER':
          return this.formatPrintValue(item.value, item.unit, item.options)
        case 'BOOLEAN':
          return this.formatPrintValue(item.value, 'BOOLEAN')
        case 'ENUM':
          return item.formattedValue
        case 'STRING':
          return item.value
        default:
          return '-'
      }
    },
    onBeforeUnload() {
      if (this.hasUnsavedChanges) {
        // modern browsers will ignore this message and will show default
        return this.$t('global.unsavedChanges')
      }
    },
  },
  computed: {
    ...mapGetters(['hasUnsavedChanges']),
    hasDataChanged() {
      if (this.serviceOrderToEdit) {
        return (
          this.createForm.text !== this.serviceOrderToEdit.text ||
          this.createForm.assigneeId !== this.serviceOrderToEdit.assignee?.id ||
          this.createForm.status !== this.serviceOrderToEdit.status
        )
      }
      return (
        this.createForm.text !== null ||
        this.createForm.assigneeId !== null ||
        this.createForm.status !== 'OPEN'
      )
    },
    showBlockButtons() {
      return this.$vuetify.breakpoint.name === 'xs'
    },
  },
  watch: {
    createModalOpen() {
      if (!this.createModalOpen) {
        this.resetForm()
      }
    },
    editModalOpen() {
      if (!this.editModalOpen) {
        this.resetForm()
      }
    },
    hasDataChanged() {
      this.$store.commit('setHasUnsavedChanges', this.hasDataChanged)
    },
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload
  },
  destroyed() {
    window.onbeforeunload = undefined
    this.$store.commit('setHasUnsavedChanges', false)
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.secondary-text {
  display: block;
  font-size: 10px;
  opacity: 0.5;
}

.form {
  max-width: 330px;
}

dl {
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;

  dt {
    font-weight: 500;

    &::after {
      content: ':';
    }
  }
}

/deep/.row-wrapper {
  cursor: pointer;
}
</style>
