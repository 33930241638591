export const childCompaniesPermissions = {
  CONFIGURATION_CHANGES: 'configurationChanges',
  CONFIGURATION_UPLOAD: 'configurationUpload',
  DEVICE_CONTROL: 'deviceControl',
  EXPERT_MODE: 'expertMode',
  OPERATIONAL_HISTORY: 'operationalHistory',
  RESTRICTED_CUSTOMER_DATA: 'restrictedCustomerData',
  FULL_CUSTOMER_DATA: 'fullCustomerData',
  USER_MANAGEMENT: 'userManagement',
  SERVICE_SEQUENCES: 'serviceSequences',
}

export const companyPermissions = {
  COMPANY_ADMINISTRATION: 'companyAdministration',
  COMPANY_USER_MANAGEMENT: 'companyUserManagement',
  TEMPLATE_MANAGEMENT: 'templateManagement',
  NEW_PRODUCT_CATALOGUE: 'newProductManagement',
  BASE_PRODUCT_CATALOGUE: 'baseProductManagement',
  ...childCompaniesPermissions,
  OTHER_COMPANIES_MANAGEMENT: 'otherCompaniesManagement',
}

export const permissions = {
  SUPER_ADMIN: 'superAdmin',
  COMPANY_CREATE_INVITE: 'companyCreateInvite',
  ...companyPermissions,
}
