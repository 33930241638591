import gql from 'graphql-tag'

export const deviceNotificationSettings = gql`
  query deviceNotificationSettings($deviceId: ID!) {
    device(deviceId: $deviceId) {
      id
      emailAddressesForNotifications
      phoneNumbersForNotifications
      pushNotificationsEnabled
    }
  }
`
