<template>
  <div v-bind="wrapperProps">
    <ul v-bind="listProps" class="list">
      <li
        v-for="(item, index) of items"
        :key="index"
        v-bind="listItemProps"
        v-sanitized-html="replaceServiceSequenceVariables(item, reactive.serviceSequenceLiveData)"
      ></li>
    </ul>
  </div>
</template>
<script>
import { replaceServiceSequenceVariables } from '@/utils/replaceServiceSequenceVariables'

export default {
  name: 'ServiceSequenceList',
  props: {
    items: Array,
    wrapperProps: Object,
    listProps: Object,
    listItemProps: Object,
  },
  inject: ['reactive'],
  methods: {
    replaceServiceSequenceVariables,
  },
}
</script>
<style lang="less" scoped>
.list {
  list-style-type: none;
  padding-left: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  li {
    padding: 16px;
    border-radius: 12px;
    border: 0.5px solid rgba(0, 0, 0, 0.2);

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
</style>
