<template>
  <div class="pa-6">
    <h1 class="mb-5">{{ $t('global.templateManagement') }}</h1>
    <div
      v-for="(deviceType, index) of availableDeviceTypes.map((dt) => dt.toUpperCase())"
      :key="deviceType"
      class="device-type-wrapper"
    >
      <h3 v-if="timeScheduleConfigs && timeScheduleConfigs[index]" class="mb-3">
        {{ $t('global.deviceType') }}: {{ $t(`global.deviceTypes.${deviceType}`) }}
      </h3>
      <WeeklyTimeScheduleTemplates
        v-if="timeScheduleConfigs && timeScheduleConfigs[index]"
        :deviceType="deviceType"
        :timeScheduleConfig="timeScheduleConfigs[index]"
      />
      <DailyTimeScheduleTemplates
        v-if="timeScheduleConfigs && timeScheduleConfigs[index]"
        :deviceType="deviceType"
        :timeScheduleConfig="timeScheduleConfigs[index]"
      />
    </div>
  </div>
</template>
<script>
import WeeklyTimeScheduleTemplates from '@/components/Templates/WeeklyTimeScheduleTemplates'
import DailyTimeScheduleTemplates from '@/components/Templates/DailyTimeScheduleTemplates'
import { mapGetters } from 'vuex'
import { timeScheduleConfig } from '@/graphql/query/timeScheduleConfig'

export default {
  name: 'TemplatesPage',
  components: { WeeklyTimeScheduleTemplates, DailyTimeScheduleTemplates },
  data() {
    return {
      timeScheduleConfigs: null,
    }
  },
  async created() {
    const timeScheduleConfigResponses = await Promise.all(
      this.availableDeviceTypes.map((deviceType) =>
        this.$apollo.query({
          query: timeScheduleConfig,
          fetchPolicy: 'cache-first',
          variables: {
            deviceType: deviceType.toUpperCase(),
            lang: this.$i18n.locale,
          },
        }),
      ),
    )

    this.timeScheduleConfigs = timeScheduleConfigResponses.map(
      (response) => response.data.deviceTypeInfo.timeScheduleConfig,
    )
  },
  computed: {
    ...mapGetters('user', ['availableDeviceTypes']),
  },
}
</script>
<style lang="less" scoped>
h1 {
  font-size: 24px;
}
</style>
