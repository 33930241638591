<template>
  <div>
    <div v-if="$apollo.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <template v-else>
      <div>
        <v-radio-group v-model="newUsesDefault" :label="$t('global.useDefaultConfiguration')">
          <v-radio :label="$t('global.defaultConfiguration')" :value="true"></v-radio>
          <v-radio :label="$t('global.customConfiguration')" :value="false"></v-radio>
        </v-radio-group>
        <v-btn
          @click="usesDefault ? saveUseDefault() : (confirmRestoreDefaultsModalOpen = true)"
          color="primary"
          class="mb-7"
          :disabled="usesDefault === newUsesDefault"
          depressed
          >{{ $t('global.save') }}</v-btn
        >
      </div>
      <DataTableCustom
        :headers="headers"
        :items="eventCodes"
        :search.sync="search"
        :footer-props="{
          'items-per-page-text': $t('global.dataTable.itemsPerPageText'),
          'items-per-page-all-text': $t('global.dataTable.allItemsText'),
        }"
        class="pt-2 mb-12"
        hide-default-footer
        disable-pagination
        :style="{ maxWidth: '1000px' }"
        :getFilterableStrings="getFilterableStrings"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            :label="$t('users.search')"
            :style="{ maxWidth: '300px' }"
          ></v-text-field>
        </template>
        <template v-slot:item.description="{ item }">
          <div class="d-flex">
            <ReportBadge
              :text="item.description"
              :severity="item.severity"
              :clickable="false"
              :deviceId="null"
            />
          </div>
        </template>
        <template v-slot:item.notificationClass="{ item }">
          <NotificationClassWithDelays
            :notificationClass="
              usesDefault ? item.defaultNotificationClass : item.customNotificationClass
            "
            class="py-1"
          />
        </template>
        <template v-if="!usesDefault" v-slot:item.defaultNotificationClass="{ item }">
          <NotificationClass
            v-if="
              item.defaultNotificationClass.notificationClass !==
              item.customNotificationClass.baseNotificationClass.notificationClass
            "
            :notificationClass="item.defaultNotificationClass.notificationClass"
            small
          />
        </template>
        <template v-if="!usesDefault" v-slot:item.edit="{ item }">
          <v-btn icon @click="openEditModal(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </DataTableCustom>

      <v-dialog v-if="!usesDefault" v-model="editModalOpen" width="unset">
        <div v-if="editedItem" class="edit-modal-wrapper">
          <div class="d-flex align-center mb-7">
            <h2 class="mr-4">{{ $t('global.edit') }}:</h2>
            <ReportBadge
              :text="editedItem.description"
              :severity="editedItem.severity"
              :clickable="false"
              :deviceId="null"
            />
          </div>
          <v-form @submit.prevent="updateNotificationClass">
            <v-select
              v-model="newNotificationClassId"
              :items="customNotificationClasses"
              item-value="id"
              filled
            >
              <template v-slot:selection="{ item }">
                <NotificationClassWithDelays :notificationClass="item" class="py-1" />
              </template>
              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <NotificationClassWithDelays :notificationClass="item" class="py-1" />
                </v-list-item>
              </template>
            </v-select>
            <div class="mt-8 text-right">
              <v-btn @click="cancel()" depressed>{{ $t('global.cancel') }}</v-btn>
              <v-btn type="submit" color="primary" class="ml-3" depressed>{{
                $t('global.save')
              }}</v-btn>
            </div>
          </v-form>
        </div>
      </v-dialog>
    </template>

    <ConfirmationDialog
      v-model="confirmRestoreDefaultsModalOpen"
      :heading="$t('global.restoreDefaultNotificationClassesConfirmationModal.heading')"
      :text="$t('global.restoreDefaultNotificationClassesConfirmationModal.text')"
      :confirmText="$t('global.confirm')"
      confirmBtnColor="error"
      :action="saveUseDefault"
    />
  </div>
</template>
<script>
import NotificationClass from '@/components/NotificationDispatcher/NotificationClass'
import NotificationClassWithDelays from '@/components/NotificationDispatcher/NotificationClassWithDelays'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import ReportBadge from '@/components/UI/ReportBadge'
import { eventCodes } from '@/graphql/query/eventCodes'
import { mapGetters } from 'vuex'
import { companyCustomNotificationClasses } from '@/graphql/query/companyCustomNotificationClasses'
import { companyEventCodeUseDefaultNotificationClass } from '@/graphql/mutations/companyEventCodeUseDefaultNotificationClass'
import { companyEventCodeUpdateNotificationClass } from '@/graphql/mutations/companyEventCodeUpdateNotificationClass'
import { formatMinutes } from '@/utils/formatMinutes'

export default {
  name: 'CompanyNotificationFlow',
  components: { ReportBadge, NotificationClass, NotificationClassWithDelays, ConfirmationDialog },
  data() {
    return {
      newUsesDefault: undefined,
      newNotificationClassId: null,
      editedItem: null,
      search: '',
      editModalOpen: false,
      confirmRestoreDefaultsModalOpen: false,
    }
  },
  apollo: {
    eventCodes: {
      query: eventCodes,
      variables() {
        return {
          companyId: this.currentCompanyId,
          lang: this.$i18n.locale,
        }
      },
    },
    customNotificationClasses: {
      query: companyCustomNotificationClasses,
      variables() {
        return {
          companyId: this.currentCompanyId,
        }
      },
      update(data) {
        return data.company.customNotificationClasses
      },
      skip() {
        return this.usesDefault
      },
    },
  },
  methods: {
    async saveUseDefault() {
      const wasSetToDefault = this.newUsesDefault
      try {
        await this.$apollo.mutate({
          mutation: companyEventCodeUseDefaultNotificationClass,
          variables: {
            companyId: this.currentCompanyId,
            useDefault: this.newUsesDefault,
          },
          refetchQueries: ['eventCodes'],
        })

        this.confirmRestoreDefaultsModalOpen = false
        wasSetToDefault
          ? this.$toast.success(this.$t('global.companySwitchToDefaultSettings.success'))
          : this.$toast.success(this.$t('global.companySwitchToCustomSettings.success'))
      } catch (error) {
        console.error(error)
        wasSetToDefault
          ? this.$toast.error(this.$t('global.companySwitchToDefaultSettings.failure'))
          : this.$toast.error(this.$t('global.companySwitchToCustomSettings.failure'))
      }
    },
    async updateNotificationClass() {
      try {
        await this.$apollo.mutate({
          mutation: companyEventCodeUpdateNotificationClass,
          variables: {
            companyId: this.currentCompanyId,
            eventCodeId: this.editedItem.id,
            notificationClassId: this.newNotificationClassId,
          },
          refetchQueries: ['eventCodes'],
        })

        this.editModalOpen = false
        this.$toast.success(this.$t('global.updateNotificationClassForCompanyAndEventCode.success'))
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.updateNotificationClassForCompanyAndEventCode.failure'))
      }
    },
    openEditModal(rowData) {
      this.editedItem = rowData
      this.newNotificationClassId = rowData.customNotificationClass?.id
      this.editModalOpen = true
    },
    cancel() {
      this.editedItem = null
      this.newNotificationClassId = null
      this.editModalOpen = false
    },
    getFilterableStrings(item) {
      return [
        item.description,
        this.$t(
          `global.notificationClasses.${
            item.customNotificationClass?.baseNotificationClass.notificationClass ??
            item.defaultNotificationClass.notificationClass
          }`,
        ),
      ]
    },
    formatMinutes,
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    usesDefault() {
      return this.eventCodes?.some((eventCode) => eventCode.customNotificationClass == null)
    },
    eventCodesWithoutNull() {
      return this.eventCodes?.map((ec) => ({
        ...ec,
        notificationClass: ec.notificationClass || 'NO_NOTIFICATION',
      }))
    },
    headers() {
      const headers = [
        { text: this.$t('global.notificationDispatcher.event'), value: 'description' },
        {
          text: this.$t('global.notificationDispatcher.notificationClass'),
          value: 'notificationClass',
        },
      ]

      if (!this.usesDefault) {
        headers.push({
          text: this.$t('global.defaultValue'),
          value: 'defaultNotificationClass',
        })
        headers.push({ value: 'edit', align: 'right' })
      }
      return headers
    },
  },
  watch: {
    editModalOpen() {
      if (!this.editModalOpen) {
        this.cancel()
      }
    },
    eventCodes() {
      this.newUsesDefault = this.eventCodes?.some(
        (eventCode) => eventCode.customNotificationClass == null,
      )
    },
  },
}
</script>
<style lang="less" scoped>
.edit-modal-wrapper {
  background: white;
  padding: 20px;
}

.light-text {
  font-size: 12px;
  opacity: 0.75;
}
</style>
