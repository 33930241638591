import store from '../../store'
import { apolloClient } from '@/graphql/client'
import { userGetAndCreateIfNotExists } from '@/graphql/mutations/userGetAndCreateIfNotExists'
import { Auth } from 'aws-amplify'
import { saveItem, getItem, removeItem } from '../storage'

export const ifAuthenticated = async (to, from, next) => {
  try {
    if (to.query?.error_description) {
      if (to.query.error_description.startsWith('Already found an entry for username')) {
        const provider = getItem('socialProvider')
        if (provider) {
          removeItem('socialProvider')
          Auth.federatedSignIn({ provider })
        } else {
          next()
        }
      } else {
        next()
      }
      return
    }
    removeItem('socialProvider')

    const response = await apolloClient.mutate({
      mutation: userGetAndCreateIfNotExists,
      variables: {},
      errorPolicy: 'all',
    })
    const user = response?.data?.userGetAndCreateIfNotExists?.user

    if (user?.deactivated) {
      throw 'FE_USER_DEACTIVATED'
    }
    if (response.errors) {
      throw response.errors[0].message
    }

    const companyId = store.getters['user/currentCompanyId']
    if (companyId === undefined) {
      store.commit(
        'user/setCurrentUserCompanyId',
        user?.companies.length ? user.companies[0].id : null,
      )
    }
    store.commit('user/setCurrentUser', { user })

    const redirect = getItem('redirect')
    if (redirect) {
      removeItem('redirect')
      if (redirect.startsWith('/login')) {
        next()
      } else {
        next(redirect)
      }
    } else {
      next()
    }
  } catch (err) {
    store.commit('user/setLoginError', err)
    store.dispatch('user/logout')

    try {
      await Auth.signOut()
    } finally {
      saveItem('redirect', to.fullPath)
      next({ name: 'LoginPage' })
    }
  }
}
