<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <div class="pa-6">
        <h2>{{ $t('global.createCompanyModal.headline') }}</h2>
        <p>{{ $t('global.createCompanyModal.description') }}</p>
        <v-form @submit.prevent="submit">
          <v-text-field
            v-model="name"
            :error-messages="nameErrors"
            :label="$t('global.createCompanyModal.companyName')"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
            filled
            style="width: 330px"
          ></v-text-field>
          <v-select
            :items="localeOptions"
            v-model="defaultLanguage"
            :label="$t('global.companyDefaultLanguage')"
            filled
            style="width: 330px"
          ></v-select>
          <div class="mt-8 text-right">
            <v-btn @click="dialog = false" depressed>{{ $t('global.close') }}</v-btn>
            <v-btn type="submit" color="primary" class="ml-3" depressed>{{
              $t('global.submit')
            }}</v-btn>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { createCompany } from '@/graphql/mutations/company'
import { me } from '@/graphql/query/me'
import { allowedLanguages } from '@/translations'

export default {
  name: 'CreateCompanyModal',
  mixins: [validationMixin],
  validations: {
    name: { required },
  },
  props: {
    show: Boolean,
  },
  data: function () {
    return {
      name: '',
      defaultLanguage: this.$i18n.locale,
    }
  },
  computed: {
    dialog: {
      get() {
        return this.show
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
    localeOptions() {
      return allowedLanguages.map((locale) => ({
        text: this.$t(`global.lang.${locale}`),
        value: locale,
      }))
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) {
        return errors
      }
      !this.$v.name.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
  },
  methods: {
    async submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const response = await this.$apollo.mutate({
            mutation: createCompany,
            variables: {
              name: this.name,
              createCompanyToken: this.$route.query.createCompanyToken,
              defaultLanguage: this.defaultLanguage,
            },
            refetchQueries: [{ query: me }],
          })

          if (response?.data?.companyCreate?.company?.id) {
            this.$store.commit('user/addCompany', response.data.companyCreate.company)
            this.$store.commit(
              'user/setCurrentUserCompanyId',
              response.data.companyCreate.company.id,
            )
          }

          this.$toast.success(this.$t('global.createCompanyModal.successMessage'))

          this.dialog = false
          history.pushState({}, null, '/app')
        } catch (error) {
          console.error(error)
          const parsedError = error.message?.replace('GraphQL error:', '').trim()
          this.$toast.error(
            parsedError === 'COMPANY_CREATE_NAME_TAKEN'
              ? this.$t('global.createCompanyModal.companyNameTakenError')
              : this.$t('global.createCompanyModal.errorMessage'),
          )
        }
      }
    },
  },
}
</script>
