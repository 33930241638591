// import Big from 'big.js'

export function replaceDeviceParametersLabels(text, parameters, objWithNamesAndLabels) {

  const variableRegex = /{{\s*('[^']*'|\w*)\s*(?:\|\s*[a-zA-Z]*:\s*[^|}]*\s*)*\s*}}/gm
  // const pipeRegex = /\|\s*(?<pipe>[a-zA-Z]*):\s*(?<value>[^|}\s]*)\s*/g

  // If the regex doesn't find anything, return text
  if (!variableRegex.test(text)) {
    return text
  }

  const labels = {};
  // Down to the deepest level of objWithNamesAndLabels sub-objects, find the ones that have name and label and add them to the labels object
  const findLabels = (obj) => {
    if (!obj)
      return;
    // If obj is an array, go through each element
    if (Array.isArray(obj)) {
      obj.forEach((element) => {
        findLabels(element)
      })
      return
    }
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'object') {
        findLabels(value)
      } else if (key === 'name' && obj.label) {
        labels[obj.name] = obj.label
      }
      else if (key === 'items') {
        // items: [{name: ..., label: ..., type: ..., unit: ..., options: ...}, {...}]
        findLabels(value)
      }
      else if (key === 'choices') {
        // choices: [{value: 'notUsed', label: 'Nepoužito'}, {...}]
      } else if (key === 'value' && obj.label) {
        // {value: 'notUsed', label: 'Nepoužito'}
        labels[obj.value] = obj.label
      }
    }
  }
  findLabels(objWithNamesAndLabels)

  // Now replace all variables with the device parameters
  return text.replaceAll(variableRegex, (match, varName) => {
    // If begins and ends with ', is literal string
    if (parameters?.[varName] != null) {
      const parameterValue = parameters[varName];
      const parameterLabel = labels[parameterValue] || "???";
      return parameterLabel
    }
    return '???'
  })
}
