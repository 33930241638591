<template>
  <div>
    <NotificationClass
      :notificationClass="
        notificationClass.baseNotificationClass
          ? notificationClass.baseNotificationClass.notificationClass
          : notificationClass.notificationClass
      "
      :customName="notificationClass.customName"
    />
    <p v-if="notificationClass.delays.length" class="delays">
      {{ $t('global.notificationDispatcher.firstNotification') }}:
      {{ formatMinutes(notificationClass.delays[0]) }}
    </p>
    <p v-if="notificationClass.delays.length >= 2" class="delays">
      {{ $t('global.notificationDispatcher.secondNotification') }}:
      {{ formatMinutes(notificationClass.delays[1]) }}
    </p>
  </div>
</template>
<script>
import NotificationClass from '@/components/NotificationDispatcher/NotificationClass'
import { formatMinutes } from '@/utils/formatMinutes'

export default {
  name: 'NotificationClassWithDelays',
  components: { NotificationClass },
  props: {
    notificationClass: Object,
  },
  methods: {
    formatMinutes,
  },
}
</script>
<style lang="less" scoped>
.delays {
  font-size: 12px;
  opacity: 0.75;
  margin-bottom: 0;
}
</style>
