<template>
  <div class="login-page--content--inner">
    <h1 class="login-page--headline">{{ $t('login.resetPasswordTitle') }}</h1>
    <p>{{ $t('login.resetPasswordText') }}</p>
    <div class="login-page--form">
      <v-form @submit.prevent="resetPassword">
        <v-text-field
          v-model="resetPasswordCode"
          filled
          :error-messages="getErrors('resetPasswordCode')"
          :label="$t('login.resetPasswordCode')"
          required
          @input="$v.resetPasswordCode.$touch()"
          @blur="$v.resetPasswordCode.$touch()"
          autocomplete="code"
        ></v-text-field>
        <v-text-field
          v-model="password"
          filled
          :error-messages="getErrors('password')"
          :label="$t('login.password')"
          required
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          autocomplete="new-password"
        ></v-text-field>
        <v-text-field
          v-model="passwordAgain"
          filled
          :error-messages="getErrors('passwordAgain')"
          :label="$t('login.passwordAgain')"
          required
          @input="$v.passwordAgain.$touch()"
          @blur="$v.passwordAgain.$touch()"
          :append-icon="showPasswordAgain ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPasswordAgain ? 'text' : 'password'"
          @click:append="showPasswordAgain = !showPasswordAgain"
          autocomplete="new-password"
        ></v-text-field>
        <div class="login-page--error">
          <div v-if="error">{{ error }}</div>
        </div>
        <div class="btn--wrapper">
          <v-btn :loading="isLoading" type="submit" color="primary" large>{{
            $t('login.resetPassword')
          }}</v-btn>
          <router-link :to="{ name: 'LoginPage' }" class="to-login-btn ml-10">{{
            $t('login.backToLogin')
          }}</router-link>
        </div>
      </v-form>
    </div>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'
import vuelidateErrorsExtractor from '@/mixins/vuelidateErrorsExtractor'
import { Auth } from 'aws-amplify'

export default {
  name: 'ResetPassword',
  mixins: [validationMixin, vuelidateErrorsExtractor],
  data() {
    return {
      resetPasswordCode: '',
      password: '',
      passwordAgain: '',
      showPassword: false,
      showPasswordAgain: false,
      isLoading: false,
      error: null,
    }
  },
  validations: {
    resetPasswordCode: { required },
    password: { required },
    passwordAgain: { required, sameAsPassword: sameAs('password') },
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query.email) {
      next({ name: 'LoginPage' })
    } else {
      next()
    }
  },
  methods: {
    async resetPassword() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isLoading = true
        this.error = null
        try {
          await Auth.forgotPasswordSubmit(
            this.$route.query.email,
            this.resetPasswordCode,
            this.password,
          )

          this.$toast.success(this.$t('login.resetPasswordSuccess'), { timeout: 10000 })
          this.$router.push({ name: 'LoginPage' })
        } catch (error) {
          console.error(error)
          if (error.code === 'ExpiredCodeException' || error.code === 'CodeMismatchException') {
            this.error = this.$t('login.resetPasswordInvalidCode')
          } else {
            this.error = this.$t('global.errors.somethingWentWrong')
          }
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
