<template>
  <div class="login-page--content--inner">
    <h1 class="login-page--headline">Please set your password</h1>

    <div class="login-page--switch">
      <!--            <router-link :to="{name: 'LoginPage' }" class="login-page&#45;&#45;switch&#45;&#45;button">Login</router-link>-->
      <!--            <router-link :to="{name: 'RegisterPage' }" class="login-page&#45;&#45;switch&#45;&#45;button">Register</router-link>-->
    </div>

    <div class="login-page--form">
      <div class="input--wrapper">
        <input
          class="input"
          id="password"
          type="password"
          v-model="changePasswordForm.newPassword"
        />
        <label class="input--label" for="password">{{ $t('login.password') }}</label>
      </div>
      <div class="input--wrapper">
        <input
          class="input"
          id="password_again"
          type="password"
          v-model="changePasswordForm.newPasswordAgain"
        />
        <label class="input--label" for="password_again">{{ $t('login.passwordAgain') }}</label>
      </div>

      <div class="login-page--error">
        <div v-if="error">{{ $t(error) }}</div>
      </div>

      <div class="btn--wrapper">
        <button class="btn btn--big" type="submit" @click="changePassword">
          {{ $t('login.changePassword') }}
        </button>
        <router-link :to="{ name: 'LoginPage' }" class="btn--link">{{
          $t('login.backToLogin')
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import passwordValidator from '@/utils/passwordValidator'

export default {
  name: 'ChallengeNewPassword',
  props: {
    cognitoUser: Object,
    sessionUserAttributes: Object,
  },
  data: function () {
    return {
      error: null,
      changePasswordForm: {
        newPassword: '',
        newPasswordAgain: '',
      },
    }
  },
  created() {
    if (this.cognitoUser === null) {
      this.$router.push({ name: 'LoginPage' })
    }
  },
  methods: {
    changePassword: function () {
      this.error = null
      if (this.changePasswordForm.newPassword !== this.changePasswordForm.newPasswordAgain) {
        this.error = 'global.password.notSame'
        return
      }

      const passwordValidResult = passwordValidator(this.changePasswordForm.newPassword)
      if (false === passwordValidResult) {
        this.error = 'global.password.notValid'
        return
      }

      this.cognitoUser.completeNewPasswordChallenge(
        this.changePasswordForm.newPassword,
        this.sessionUserAttributes,
        {
          onSuccess: function () {
            this.$router.push({ name: 'DashboardPage' })
          },
          onFailure: function (err) {
            // TODO log this error, this should not happen, due internal password policy
            this.error = err.message
          },
        },
      )
    },
  },
}
</script>
