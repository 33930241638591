import gql from 'graphql-tag'
import { CompanyRelatedPermissions } from './companyRelatedPermissions'

export const CompanyUserWithPermissions = gql`
  fragment CompanyUserWithPermissions on User {
    id
    firstName
    lastName
    email
    phoneNumber
    enabled
    companyPermissions(companyId: $companyId) @include(if: $withPermissions) {
      ...CompanyRelatedPermissions
    }
    isAdminInSomeCompany
  }
  ${CompanyRelatedPermissions}
`
