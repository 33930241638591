import gql from 'graphql-tag'

export const deviceWifiCredentials = gql`
  query deviceWifiCredentials($deviceId: ID!) {
    device(deviceId: $deviceId) {
      id
      wifiCredentials {
        ssid
        password
      }
    }
  }
`
