import get from 'lodash/get'

const validatorToMessageMap = {
  required: () => ['global.formValidation.required'],
  isPhoneNumber: () => ['global.formValidation.notPhoneNumber'],
  email: () => ['global.formValidation.notEmail'],
  maxLength: () => ['global.formValidation.tooLong'],
  integer: () => ['global.formValidation.integer'],
  minValue: (validationsObject) => [
    'global.formValidation.min',
    { min: get(validationsObject, 'minValue.min') },
  ],
  maxValue: (validationsObject) => [
    'global.formValidation.max',
    { max: get(validationsObject, 'maxValue.max') },
  ],
  sameAsPassword: () => ['global.formValidation.notSameAsPassword'],
}

export default {
  methods: {
    getErrors(fieldPath) {
      const validationsObject = get(this.$v, fieldPath)
      if (!validationsObject) {
        throw new Error(`Validations not defined for path: '${fieldPath}'`)
      }

      if (!validationsObject.$dirty) {
        return []
      }

      const activeErrorKeys = Object.keys(validationsObject).filter(
        (key) => !key.startsWith('$') && !validationsObject[key],
      )

      return activeErrorKeys.map((key) => this.$t(...validatorToMessageMap[key](validationsObject)))
    },
  },
}
