<template>
  <div class="form-main--row">
    <div class="form-main--column">
      <label class="form-main--label" :for="variableName">{{ translatedLabel }}</label>
    </div>
    <div class="form-main--column">
      <div class="form-input--wrapper time-picker-wrapper" :class="{ error: inputErrors.length }">
        <select v-model="selectedHour" @change="updateTime" class="time-select hour-select">
          <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}</option>
        </select>
        <span class="time-separator">:</span>
        <select v-model="selectedMinute" @change="updateTime" class="time-select minute-select">
          <option v-for="minute in minutes" :key="minute" :value="minute">{{ minute }}</option>
        </select>
        <span class="time-separator">:</span>
        <select v-model="selectedSecond" @change="updateTime" class="time-select second-select">
          <option v-for="second in seconds" :key="second" :value="second">{{ second }}</option>
        </select>
        <div class="time-format-label">HH : MM : SS</div>
      </div>
      <p v-for="error in inputErrors" :key="`time_input_${name}_error_${error.error}`">
        {{ $t('device.validations.' + error.error) }}
      </p>
    </div>
  </div>
</template>

<script>
import BasicInput from '@/components/Form/Input/BasicInput'

export default {
  name: 'TimePickerInput',
  extends: BasicInput,
  data() {
    return {
      selectedHour: '00',
      selectedMinute: '00',
      selectedSecond: '00',
      hours: Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0')),
      minutes: Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0')),
      seconds: Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0')),
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        const hours = Math.floor(newValue / 3600)
        const minutes = Math.floor((newValue % 3600) / 60)
        const seconds = newValue % 60
        this.selectedHour = hours.toString().padStart(2, '0')
        this.selectedMinute = minutes.toString().padStart(2, '0')
        this.selectedSecond = seconds.toString().padStart(2, '0')
      },
    },
  },
  methods: {
    updateTime() {
      const totalSeconds =
        parseInt(this.selectedHour) * 3600 +
        parseInt(this.selectedMinute) * 60 +
        parseInt(this.selectedSecond)
      this.$emit('input', totalSeconds)
    },
  },
}
</script>

<style scoped>
.time-picker-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  padding-left: 6px;
}

.time-format-label {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #a3a3a3;
  pointer-events: none;
  font-size: 0.875rem;
}

.time-select {
  border: none;
  background: transparent;
  padding: 6px 8px;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.time-separator {
  color: #000;
  opacity: 0.3;
}

.time-select {
  position: relative;
}

.time-select::-ms-expand {
  display: none; /* Hide the default arrow in IE */
}

/* Custom arrow */
.time-select::after {
  content: '▼';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 12px;
  background: #fff;
  cursor: pointer;
  pointer-events: none;
  transition: all 300ms ease;
}

/* Error state */
.error .time-picker-wrapper {
  border-color: red;
}

/* On focus and hover state of the select elements */
.time-select:focus,
.time-picker-wrapper:hover {
  outline: none;
  border-color: #1d2024;
}
</style>
