import gql from 'graphql-tag'

export const generalEligibilitiesUpdateDefaultValues = gql`
  mutation generalEligibilitiesUpdateDefaultValues(
    $updateData: [EligibilitiesGeneralUpdateDefaultValuesInput!]!
  ) {
    generalEligibilitiesUpdateDefaultValues(input: { updateData: $updateData }) {
      success
    }
  }
`
