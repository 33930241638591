import gql from 'graphql-tag'

export const serviceSequenceNextRun = gql`
  query serviceSequenceNextRun($serviceSequenceName: NonEmptyString!, $deviceId: ID!) {
    device(deviceId: $deviceId) {
      id
      liveDeviceData {
        serviceSequenceNextRun: getServiceSequenceNextRun(serviceSequenceName: $serviceSequenceName)
      }
    }
  }
`
