var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wrapper",staticClass:"calendar-input-wrapper"},[_c('div',{staticClass:"timeline"},_vm._l((_vm.ticks),function(tick){return _c('div',{key:tick,staticClass:"tick"},[_vm._v(" "+_vm._s(tick)+" ")])}),0),_c('div',{staticClass:"lanes-wrapper"},[_c('div',{staticClass:"time-indicator",style:({
        top: _vm.percentageOfDay + '%',
      })},[_c('span')]),_vm._l((_vm.timeScheduleConfig.schedules),function(config){return _c('div',{key:config.name,staticClass:"lane-wrapper"},[_c('div',{staticClass:"lane-header"},[_c('h2',[_vm._v(_vm._s(config.label))])]),_vm._l((48),function(n){return _c('div',{key:n,staticClass:"grid-line"})}),_vm._l((_vm.addButtonsPositions[config.name]),function(button,index){return [(
            !_vm.resizedOrDraggedIntervalKey &&
            (_vm.addButtonIsDraggedIndex == null || _vm.addButtonIsDraggedIndex === index)
          )?_c('TimeScheduleCalendarAddButton',{key:`${config.name}-${index}`,attrs:{"timeSchedule":_vm.timeSchedule[config.name],"granularity":_vm.timeScheduleConfig.granularity,"states":config.states,"begin":button.begin,"end":button.end,"getWrapperRef":() => _vm.$refs.wrapper},on:{"dragging":function($event){$event ? (_vm.addButtonIsDraggedIndex = index) : (_vm.addButtonIsDraggedIndex = null)},"newInterval":function($event){return _vm.addNewInterval(config.name, $event.state, $event.begin, $event.end)}}}):_vm._e()]}),_vm._l((_vm.timeSchedule[config.name]),function(interval,index){return _c('div',{key:`${config.name}-interval-${interval.id}`,staticClass:"interval",class:((_vm.resizedOrDraggedIntervalKey === `${config.name}-${index}`
            ? _vm.resizedOrDraggedIntervalEnd - _vm.resizedOrDraggedIntervalBegin
            : interval.end - interval.begin) /
            86400) *
            2064 -
            4 >
          38
            ? 'interval--normal'
            : 'interval--narrow',style:({
          backgroundColor: _vm.configMap.get(config.name).stateMap.get(interval.state)[
            interval.wip ? 'newBgColor' : 'color'
          ],
          top:
            ((_vm.resizedOrDraggedIntervalKey === `${config.name}-${index}`
              ? _vm.resizedOrDraggedIntervalBegin
              : interval.begin) /
              86400) *
              2064 +
            43 +
            2 +
            'px', // (interval.begin / numberOfSecondsInDay) * (48 * 43 = heightOfTimeline) + heightOfHeader + padding
          bottom:
            2064 -
            ((_vm.resizedOrDraggedIntervalKey === `${config.name}-${index}`
              ? _vm.resizedOrDraggedIntervalEnd
              : interval.end) /
              86400) *
              2064 +
            2 +
            'px', // (48 * 43 = heightOfTimeline) - (interval.end / numberOfSecondsInDay) * (48 * 43 = heightOfTimeline) + padding
          border:
            interval.wip &&
            `1.5px dashed ${
              _vm.configMap.get(config.name).stateMap.get(interval.state).newBorderColor
            }`,
          cursor: _vm.editable ? (_vm.resizedOrDraggedIntervalKey ? 'grabbing' : 'grab') : 'default',
        }),on:{"contextmenu":function($event){$event.preventDefault();_vm.editable ? _vm.openContextMenu(config.name, index, $event) : undefined},"mousedown":function($event){_vm.editable ? _vm.dragMousedown(config.name, index, $event) : undefined}}},[(_vm.editable && !_vm.resizedOrDraggedIntervalKey)?_c('div',{staticClass:"interval-drag-handle-top",on:{"click":function($event){$event.stopPropagation();return (() => null).apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();return _vm.resizeMousedown(config.name, index, 'begin', $event)}}},[_c('div',{staticClass:"interval-drag-handle-top--circle",style:({
              backgroundColor: _vm.configMap.get(config.name).stateMap.get(interval.state)[
                interval.wip ? 'newBgColor' : 'color'
              ],
            })})]):_vm._e(),_c('div',{staticClass:"interval-texts"},[_c('span',{staticClass:"interval-begin-end"},[_vm._v(_vm._s(_vm.formatSecondsToTime( _vm.resizedOrDraggedIntervalKey === `${config.name}-${index}` ? _vm.resizedOrDraggedIntervalBegin : interval.begin, ))+" - "+_vm._s(_vm.formatSecondsToTime( _vm.resizedOrDraggedIntervalKey === `${config.name}-${index}` ? _vm.resizedOrDraggedIntervalEnd : interval.end, )))]),_c('span',{staticClass:"interval-description"},[_vm._v(_vm._s(config.label)+" - "+_vm._s(_vm.configMap.get(config.name).stateMap.get(interval.state).label))])]),(_vm.editable && !_vm.resizedOrDraggedIntervalKey)?_c('div',{staticClass:"interval-drag-handle-bottom",on:{"click":function($event){$event.stopPropagation();return (() => null).apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();return _vm.resizeMousedown(config.name, index, 'end', $event)}}},[_c('div',{staticClass:"interval-drag-handle-bottom--circle",style:({
              backgroundColor: _vm.configMap.get(config.name).stateMap.get(interval.state)[
                interval.wip ? 'newBgColor' : 'color'
              ],
            })})]):_vm._e()])}),_vm._l((_vm.forbiddenIntervals[config.name]),function(interval){return _c('div',{key:`${config.name}-forbidden-interval-${interval.id}`,staticClass:"forbidden-interval",style:({
          top: (interval.begin / 86400) * 2064 + 43 + 2 + 'px',
          bottom: 2064 - (interval.end / 86400) * 2064 + 2 + 'px',
        })})})],2)}),(_vm.contextMenu.isOpen)?_c('v-menu',{attrs:{"position-x":_vm.contextMenu.x,"position-y":_vm.contextMenu.y,"absolute":"","offset-y":""},model:{value:(_vm.contextMenu.isOpen),callback:function ($$v) {_vm.$set(_vm.contextMenu, "isOpen", $$v)},expression:"contextMenu.isOpen"}},[_c('v-list',[_vm._l((_vm.configMap
            .get(_vm.contextMenu.scheduleName)
            .states.filter(
              (state) =>
                state.name !==
                _vm.timeSchedule[_vm.contextMenu.scheduleName][_vm.contextMenu.intervalIndex].state,
            )),function(state){return _c('v-list-item',{key:state.name,attrs:{"link":""},on:{"click":function($event){return _vm.changeStateFromContextMenu(state.name)}}},[_c('div',{staticClass:"square",style:({ backgroundColor: state.color })}),_c('span',[_vm._v(_vm._s(state.label))])])}),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.deleteIntervalFromContextMenu()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")],1)],2)],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }