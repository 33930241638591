<template>
  <v-dialog :value="isOpen" @input="$emit('modalToggle', $event)" max-width="1000" max-height>
    <div class="wrapper">
      <div class="mb-2 d-flex justify-space-between align-baseline flex-wrap">
        <div class="d-flex align-center">
          <h1 class="mr-2">{{ activeImage && activeImage.name }}</h1>
          <v-btn v-if="canEdit" icon small @click="nameEditModalOpen = true"
            ><v-icon small>mdi-pencil</v-icon></v-btn
          >
        </div>
        <p class="info-p">
          {{ getAuthorName(activeImage) }}, {{ getFormattedDateTaken(activeImage) }}
        </p>
      </div>
      <div class="preview-wrapper">
        <v-btn
          icon
          v-if="images.length > 1"
          class="change-img-btn"
          @click="previous()"
          :ripple="false"
        >
          <v-icon size="80" class="shadow-icon">mdi-chevron-left</v-icon>
          <v-icon size="80">mdi-chevron-left</v-icon>
        </v-btn>
        <div class="flex-grow-1 d-flex justify-center">
          <img class="preview-img" :src="activeImage.url" :alt="activeImage.name" />
        </div>
        <v-btn icon v-if="images.length > 1" class="change-img-btn" @click="next()" :ripple="false">
          <v-icon size="80" class="shadow-icon">mdi-chevron-right</v-icon>
          <v-icon size="80">mdi-chevron-right</v-icon>
        </v-btn>
      </div>
      <div class="d-flex mt-5">
        <v-btn v-if="canEdit" color="error" @click="confirmDeleteModalOpen = true">{{
          $t('global.delete')
        }}</v-btn>
        <div class="flex-grow-1 text-right">
          <v-btn color="primary" @click="$emit('modalToggle', false)">{{
            $t('global.close')
          }}</v-btn>
        </div>
      </div>
    </div>

    <v-dialog v-if="canEdit" v-model="nameEditModalOpen" width="unset">
      <v-card>
        <div class="pa-6">
          <h2>{{ $t('device.gallery.imageNameEditHeading') }}</h2>
          <p>{{ $t('device.gallery.imageNameEditText') }}</p>
          <v-form @submit.prevent="updateImageName">
            <v-text-field
              v-model="imageNameEdit"
              :error-messages="imageNameErrors"
              :label="$t('device.gallery.imageNameEditLabel')"
              required
              @input="$v.imageNameEdit.$touch()"
              @blur="$v.imageNameEdit.$touch()"
              filled
              counter="35"
              style="width: 350px"
            ></v-text-field>
            <div class="mt-8 text-right">
              <v-btn @click="nameEditModalOpen = false" depressed>{{ $t('global.cancel') }}</v-btn>
              <v-btn type="submit" color="primary" class="ml-3" depressed>{{
                $t('global.save')
              }}</v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <ConfirmationDialog
      v-if="canEdit"
      v-model="confirmDeleteModalOpen"
      :heading="$t('device.gallery.deleteConfirmationHeading')"
      :text="$t('device.gallery.deleteConfirmationText')"
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
      :action="deleteImage"
    />
  </v-dialog>
</template>
<script>
import format from 'date-fns/format'
import { dateLocales } from '@/config/dateFnsLocales'
import { deviceImageDelete } from '@/graphql/mutations/deviceImageDelete'
import { deviceImageRename } from '@/graphql/mutations/deviceImageRename'
import { deviceImages } from '@/graphql/query/device'
import produce from 'immer'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import ConfirmationDialog from '@/components/ConfirmationDialog'

export default {
  name: 'ImagePreviewModal',
  components: { ConfirmationDialog },
  mixins: [validationMixin],
  model: {
    prop: 'isOpen',
    event: 'modalToggle',
  },
  props: {
    isOpen: Boolean,
    images: Array,
    openIndex: Number,
    deviceId: String,
    canEdit: Boolean,
  },
  data() {
    return {
      actualIndex: this.openIndex,
      confirmDeleteModalOpen: false,
      nameEditModalOpen: false,
      imageNameEdit: this.images[this.openIndex]?.name,
    }
  },
  validations: {
    imageNameEdit: { required, maxLength: maxLength(35) },
  },
  methods: {
    getAuthorName(image) {
      if (!image.addedByUser) {
        return this.$t('global.anonymous')
      }
      return image.addedByUser.firstName && image.addedByUser.firstName.trim() !== ''
        ? `${image.addedByUser.firstName} ${image.addedByUser.lastName}`
        : image.addedByUser.email
    },
    getFormattedDateTaken(image) {
      return format(new Date(image.takenAt || image.uploadedAt), 'd. LLLL y', {
        locale: dateLocales[this.$i18n.locale],
      })
    },
    previous() {
      this.actualIndex = this.actualIndex > 0 ? this.actualIndex - 1 : this.images.length - 1
    },
    next() {
      this.actualIndex = this.actualIndex < this.images.length - 1 ? this.actualIndex + 1 : 0
    },
    async updateImageName() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: deviceImageRename,
            variables: {
              imageId: this.activeImage.id,
              newName: this.imageNameEdit,
            },
            update: (store, response) => {
              const query = {
                query: deviceImages,
                variables: {
                  deviceId: this.deviceId,
                },
              }
              const data = store.readQuery(query)
              const newData = produce(data, (draft) => {
                const updatedIndex = draft.device.images.findIndex(
                  (image) => image.id === response.data.deviceImageRename.image.id,
                )
                draft.device.images[updatedIndex] = {
                  ...draft.device.images[updatedIndex],
                  name: response.data.deviceImageRename.image.name,
                }
              })
              store.writeQuery({
                ...query,
                data: newData,
              })
            },
          })
          this.$toast.success(this.$t('device.gallery.renameImageSuccess'))
          this.nameEditModalOpen = false
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('device.gallery.renameImageFailed'))
        }
      }
    },
    async deleteImage() {
      try {
        await this.$apollo.mutate({
          mutation: deviceImageDelete,
          variables: {
            imageId: this.activeImage.id,
          },
          update: (store, response) => {
            if (this.images.length === 1) {
              this.$emit('modalToggle', false)
            } else if (this.actualIndex === this.images.length - 1) {
              this.actualIndex--
            }
            const query = {
              query: deviceImages,
              variables: {
                deviceId: this.deviceId,
              },
            }
            const data = store.readQuery(query)
            const newData = produce(data, (draft) => {
              draft.device.images = draft.device.images.filter(
                (image) => image.id !== response.data.deviceImageDelete.deletedImageId,
              )
            })
            store.writeQuery({
              ...query,
              data: newData,
            })
          },
        })
        this.$toast.success(this.$t('device.gallery.deleteImageSuccess'))
        this.confirmDeleteModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('device.gallery.deleteImageFailed'))
      }
    },
  },
  computed: {
    activeImage() {
      return this.images[this.actualIndex]
    },
    imageNameErrors() {
      const errors = []
      if (!this.$v.imageNameEdit.$dirty) {
        return errors
      }
      !this.$v.imageNameEdit.required && errors.push(this.$t('global.formValidation.required'))
      !this.$v.imageNameEdit.maxLength && errors.push(this.$t('global.formValidation.tooLong'))
      return errors
    },
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.actualIndex = this.openIndex
      }
    },
    activeImage() {
      this.imageNameEdit = this.activeImage?.name
    },
  },
}
</script>
<style lang="less" scoped>
.wrapper {
  background-color: white;
  padding: 15px;

  h1 {
    font-size: 18px;
    font-weight: 500;
    word-break: break-word;
  }

  .info-p {
    font-size: 14px;
    color: #adadad;
    margin: 0;
  }

  .preview-wrapper {
    position: relative;

    .change-img-btn {
      background: transparent;
      position: absolute;
      height: 100%;
      width: 15%;
      top: 0;
      z-index: 1;
      color: white;

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }

      &:hover::before {
        opacity: 0;
      }

      .shadow-icon {
        position: absolute;
        color: black;
        filter: blur(6px);
      }
    }

    .preview-img {
      max-height: 70vh;
    }
  }
}
</style>
