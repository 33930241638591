import gql from 'graphql-tag'

export const contactEmailVariables = gql`
  query contactEmailVariables($lang: String!) {
    contactEmailVariables {
      name
      mockValue(lang: $lang)
      description(lang: $lang)
    }
  }
`
