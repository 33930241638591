import gql from 'graphql-tag'

export const thingTypeFamilies = gql`
  query thingTypeFamilies(
    $companyId: ID
    $adminView: Boolean = false
    $withEligibilities: Boolean! = false
  ) {
    thingTypeFamilies(companyId: $companyId, adminView: $adminView) {
      id
      name
      eligibilities @include(if: $withEligibilities) {
        name
        defaultValue
        defaultAvailability
        isPremium
        serviceSequence
        provisionType
      }
    }
  }
`
