<template>
  <div>
    <template
      v-if="permissionChecker(permissions.OPERATIONAL_HISTORY, eligibilities.ENERGY_CHARTS)"
    >
      <h2 class="mb-2">{{ $t('global.energyReports') }}</h2>
      <div id="date-selector" class="d-flex align-center gap">
        <v-btn @click="previousDateInterval()" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
        <v-menu
          v-model="dateEditMode"
          attach="#date-selector"
          :close-on-content-click="false"
          content-class="menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-flex align-center gap">
              <v-icon>mdi-calendar</v-icon>
              <span>{{ formattedDateRange }}</span>
            </div>
          </template>
          <div class="date-menu-wrapper pa-6">
            <div class="d-flex align-center gap">
              <v-btn @click="previousDateInterval()" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
              <v-icon>mdi-calendar</v-icon>
              <input v-model="formattedDates[0]" type="text" class="date-input" />
              <span>-</span>
              <input v-model="formattedDates[1]" type="text" class="date-input" />
              <v-btn @click="nextDateInterval()" icon><v-icon>mdi-chevron-right</v-icon></v-btn>
            </div>
            <div class="date-menu-inner-wrapper mt-3">
              <div class="mr-3">
                <v-list>
                  <v-list-item v-for="dateRange of dateRangeChoices" :key="dateRange.name">
                    <v-btn
                      @click="setRangeAndClose(dateRange.getRange(), dateRange.stepInterval)"
                      depressed
                      plain
                      >{{ $t(`global.dateRangeChoice.${dateRange.name}`) }}</v-btn
                    >
                  </v-list-item>
                </v-list>
              </div>
              <div>
                <v-date-picker
                  v-model="dates"
                  range
                  @change="setRangeAndClose($event)"
                  show-adjacent-months
                  scrollable
                  :locale="this.$i18n.locale"
                ></v-date-picker>
              </div>
            </div>
          </div>
        </v-menu>
        <v-btn @click="nextDateInterval()" icon><v-icon>mdi-chevron-right</v-icon></v-btn>
      </div>
      <div>
        <EnergyChart :deviceId="device.id" :from="realDates[0]" :to="realDates[1]" />
      </div>
      <div v-if="device.grafanaLink" class="mt-3">
        <h2 class="mb-2">{{ $t('device.diagnosticCharts') }}</h2>
        <v-btn color="primary" :href="device.grafanaLink" target="_blank">{{
          $t('global.enter')
        }}</v-btn>
      </div>
    </template>
    <template v-else>
      <div v-if="device.grafanaLink" class="wrapper">
        <span>{{ $t('device.diagnosticCharts') }}</span>
        <div class="content">
          <p>{{ $t('device.chartsUnderConstruction') }}</p>
          <v-btn color="primary" :href="device.grafanaLink" target="_blank">{{
            $t('global.enter')
          }}</v-btn>
        </div>
      </div>
      <div v-else class="unauthorized">
        <v-icon x-large>mdi-emoticon-sad-outline</v-icon>
        <p class="mt-2">{{ $t('device.chartsUnavailable') }}</p>
      </div>
    </template>
  </div>
</template>
<script>
import { permissions } from '@/config/permissions'
import { eligibilities } from '@/config/eligibilities'
import EnergyChart from '@/components/DeviceDetail/EnergyChart'
import isEqual from 'lodash/isEqual'

import format from 'date-fns/format'
import formatISO from 'date-fns/formatISO'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import subDays from 'date-fns/subDays'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import subMonths from 'date-fns/subMonths'
import startOfYear from 'date-fns/startOfYear'
import endOfYear from 'date-fns/endOfYear'
import subYears from 'date-fns/subYears'
import differenceInDays from 'date-fns/differenceInDays'
import add from 'date-fns/add'
import sub from 'date-fns/sub'

export default {
  name: 'GraphsTab',
  components: { EnergyChart },
  props: {
    device: Object,
    permissionChecker: Function,
  },
  data() {
    const dateRangeChoices = [
      {
        name: 'today',
        getRange: () => {
          const now = new Date()
          return [startOfDay(now), endOfDay(now)]
        },
        stepInterval: { days: 1 },
      },
      {
        name: 'yesterday',
        getRange: () => {
          const yesterday = subDays(new Date(), 1)
          return [startOfDay(yesterday), endOfDay(yesterday)]
        },
        stepInterval: { days: 1 },
      },
      {
        name: 'thisWeek',
        getRange: () => {
          const now = new Date()
          return [startOfWeek(now, { weekStartsOn: 1 }), endOfWeek(now, { weekStartsOn: 1 })]
        },
        stepInterval: { weeks: 1 },
      },
      {
        name: 'lastWeek',
        getRange: () => {
          const lastWeek = subDays(new Date(), 7)
          return [
            startOfWeek(lastWeek, { weekStartsOn: 1 }),
            endOfWeek(lastWeek, { weekStartsOn: 1 }),
          ]
        },
        stepInterval: { weeks: 1 },
      },
      {
        name: 'thisMonth',
        getRange: () => {
          const now = new Date()
          return [startOfMonth(now), endOfMonth(now)]
        },
        stepInterval: { months: 1 },
      },
      {
        name: 'lastMonth',
        getRange: () => {
          const lastMonth = subMonths(new Date(), 1)
          return [startOfMonth(lastMonth), endOfMonth(lastMonth)]
        },
        stepInterval: { months: 1 },
      },
      {
        name: 'last30days',
        getRange: () => {
          const now = new Date()
          return [startOfDay(subDays(now, 29)), endOfDay(now)]
        },
        stepInterval: { days: 30 },
      },
      {
        name: 'last90days',
        getRange: () => {
          const now = new Date()
          return [startOfDay(subDays(now, 89)), endOfDay(now)]
        },
        stepInterval: { days: 90 },
      },
      {
        name: 'thisYear',
        getRange: () => {
          const now = new Date()
          return [startOfYear(now), endOfYear(now)]
        },
        stepInterval: { years: 1 },
      },
      {
        name: 'lastYear',
        getRange: () => {
          const lastYear = subYears(new Date(), 1)
          return [startOfYear(lastYear), endOfYear(lastYear)]
        },
        stepInterval: { years: 1 },
      },
    ]
    return {
      dateEditMode: false,
      dateRangeChoices,
      dates: dateRangeChoices
        .find((e) => e.name === 'thisWeek')
        .getRange()
        .map((date) => formatISO(date, { representation: 'date' })),
      stepInterval: dateRangeChoices.find((e) => e.name === 'thisWeek').stepInterval,
      formattedDates: [],
    }
  },
  methods: {
    setRangeAndClose(newRange, stepInterval) {
      this.dates =
        newRange[0] instanceof Date
          ? newRange.map((date) => formatISO(date, { representation: 'date' }))
          : newRange

      if (new Date(this.dates[0]) > new Date(this.dates[1])) {
        this.dates.reverse()
      }

      if (stepInterval) {
        this.stepInterval = stepInterval
      } else {
        this.stepInterval = {
          days: differenceInDays(parseISO(this.dates[1]), parseISO(this.dates[0])),
        }
      }

      this.dateEditMode = false
    },
    nextDateInterval() {
      this.dates = this.dates.map((date, index) => {
        let addedDate = add(parseISO(date), this.stepInterval)
        if (this.stepInterval?.months === 1 && index === 1) {
          addedDate = endOfMonth(addedDate)
        }
        return formatISO(addedDate, { representation: 'date' })
      })
      this.dateEditMode = false
    },
    previousDateInterval() {
      this.dates = this.dates.map((date, index) => {
        let subtractedDate = sub(parseISO(date), this.stepInterval)
        if (this.stepInterval?.months === 1 && index === 1) {
          subtractedDate = endOfMonth(subtractedDate)
        }
        return formatISO(subtractedDate, { representation: 'date' })
      })
      this.dateEditMode = false
    },
  },
  computed: {
    formattedDateRange() {
      return `${this.formattedDates[0]} - ${this.formattedDates[1]}`
    },
    realDates() {
      return [startOfDay(parseISO(this.dates[0])), endOfDay(parseISO(this.dates[1]))]
    },
    permissions() {
      return permissions
    },
    eligibilities() {
      return eligibilities
    },
  },
  watch: {
    dates: {
      immediate: true,
      handler() {
        const newFormattedDates = this.dates.map((date) => format(new Date(date), 'd.M.yyyy'))
        if (!isEqual(newFormattedDates, this.formattedDates)) {
          this.formattedDates = newFormattedDates
        }
      },
    },
    formattedDates: {
      deep: true,
      handler() {
        const newDates = this.formattedDates.map((date) =>
          formatISO(parse(date, 'd.M.yyyy', new Date()), {
            representation: 'date',
          }),
        )
        if (!isEqual(newDates, this.dates)) {
          this.dates = newDates
        }
      },
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.wrapper {
  padding: 16px;
  background-color: @color-tile-background;

  & > span {
    display: block;
    opacity: 0.5;
    font-size: 12px;
  }

  .content {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > p {
      font-weight: bold;
      text-align: center;
    }
  }
}

.unauthorized {
  padding: 16px;
  background-color: @color-tile-background;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#date-selector {
  position: relative;
}

.menu {
  top: 0 !important;
  left: 0 !important;
  max-width: unset;

  .date-menu-wrapper {
    background-color: white;
    .date-menu-inner-wrapper {
      display: flex;
    }
  }
}

.date-input {
  width: 80px;

  &:focus-visible {
    outline: none;
  }
}

.gap {
  gap: 5px;
}

/deep/.v-list-item {
  min-height: unset;
  padding: 0;
}

h2 {
  font-size: 18px;
  font-weight: 500;
}
</style>
