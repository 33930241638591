

export default {
    controllerId: 'Thing ID (XXX-XXX-XXX)',
    controller: 'Thing',
    status: {
        ONLINE: 'Online',
        OFFLINE: 'Offline'
    },
    operatingMode: {
        IN_CONSTRUCTION: 'Under Construction',
        NORMAL: 'Normal Operation',
        MAINTENANCE: 'Maintenance',
        WINTERIZED: 'Fully Winterized'
    },
    lastSynchronization: 'Last seen',
    propagate: 'Restart Thing.',
    saveAndPropagate: 'Save and Send',
    saveAsDeviceTemplate: 'Save as a Thing Template',
    saveAsDeviceTemplateDescription: 'Enter template name.',
    validations: {
        is_empty: 'Please fill in this field.',
        device_already_registered: 'This Thing ID already exists in the database.',
        invalid_serial: 'This Thing ID does not exist.'
    },
    searchDevice: 'Search for Thing',
    addDevice: 'Add Thing',
    addDeviceInProgress: 'In Progress',
    target: 'Target',
    deviceNotFound: 'Thing not found.',
    unavailable: 'Unavailable',
    forbiddenByUser: 'Forbidden by user',
    enterExpertMode: 'Enter Expert Mode',
    forbidden: 'Forbidden',
    dosing: 'Dosing',
    notDosing: 'Ready',
    addressNotFound: 'Address not found.',
    diagnosticCharts: 'Diagnostic Charts',
    chartsUnderConstruction: 'This section is under development. Proceed.',
    chartsUnavailable: 'Diagnostic charts are not available for this type of thing.',
    controlUpdateFail: 'Failed to set value.',
    sendSettingsFailed: 'Failed sending settings to Thing.',
    saveDeviceTemplateSuccess: 'Template successfully saved.',
    saveDeviceTemplateError: 'Failed to save template.',
    confirmOpenExpertModeModal: {
        headline: 'Are you sure you want to switch to Expert Mode?',
        text: 'Improper use of Expert Mode may cause damage.'
    },
    confirmCloseExpertModeModal: {
        headline: 'Are you sure you want to leave Expert Mode?',
        text: 'System returns to Normal Mode.'
    },
    expertModeModal: {
        headline: 'Expert Mode activated.',
        exit: 'Exit Expert mode',
        openFailed: 'Failed to activate Expert Mode.',
        closeFailed: 'Failed to deactivate Expert Mode.'
    },
    deviceWentOfflineModal: {
        headline: 'The Thing has gone offline.',
        text: 'Wait for the Thing to go back online or exit Expert Mode.',
        buttonText: 'Exit Expert Mode'
    },
    deviceList: 'Thing List',
    listView: 'List View',
    mapView: 'Map View',
    deviceListHeaders: {
        name: 'Name',
        company: 'Company',
        connectionType: 'Connection Type',
        connection: 'Connection Status',
        currentOperation: 'Current operation',
        operatingMode: 'Operating Mode',
        reports: 'Notifications',
        userNote: 'User Message',
        inOperationSince: 'In Operation Since'
    },
    noDevices: 'No available Things',
    noRecords: 'No records',
    timeSchedule: {
        changeSchedule: 'Change Weekly Schedule',
        selectSchedule: 'Select a Weekly Schedule',
        showWeeklyTimeSchedules: 'Show Weekly Schedules',
        showDailyTimeSchedules: 'Show Daily Schedules',
        recommended: 'Recommended Running Time',
        activeTimeSchedule: 'Active Schedule',
        noActiveTimeSchedule: 'No active time schedule.',
        activeTimeScheduleNoMatch: 'No active time schedule. Please activate one of your schedules.',
        pleaseActivate: 'Please activate one of your schedules.',
        setActiveTimeScheduleFailed: 'Failed to activate time schedule.',
        createTimeScheduleFailed: 'Failed to create time schedule.',
        updateTimeScheduleFailed: 'Failed to update time schedule.',
        deleteTimeScheduleFailed: 'Failed to delete time schedule.',
        setActiveTimeScheduleSuccess: 'Time schedule successfully activated.',
        createTimeScheduleSuccess: 'Time schedule successfully created.',
        updateTimeScheduleSuccess: 'Time schedule successfully updated.',
        deleteTimeScheduleSuccess: 'Time schedule successfully deleted.',
        editModal: {
            cancelConfirmation: {
                heading: 'Quit Editing',
                text: 'Are you sure you want to quit? All changes will be lost.'
            }
        },
        confirmActivateModal: {
            heading: 'Activate Time Schedule',
            text: 'Are you sure you want to activate this time schedule?'
        }
    },
    gallery: {
        noPhotodocumentation: 'No images available.',
        addPhotos: 'Add images',
        takePhoto: 'Take a photo',
        imageUploadSuccess: 'Images successfully uploaded.',
        imageUploadFailed: 'Failed to upload images.',
        imageUploadPartiallyFailed: 'Failed to upload {failedCount} of {totalCount} images.',
        imageUploadUnsupportedFormat: 'Unsupported file format. Please use jpg, png, heif or heic.',
        deleteConfirmationHeading: 'Delete Image',
        deleteConfirmationText: 'Are you sure you want to delete this image?',
        deleteImageSuccess: 'Image successfully deleted.',
        deleteImageFailed: 'Failed to delete image.',
        imageNameEditHeading: 'Image Name',
        imageNameEditText: 'Enter a new image name.',
        imageNameEditLabel: 'Image Name',
        renameImageSuccess: 'Image name successfully updated.',
        renameImageFailed: 'Failed to update image name.'
    },
    deviceDeleteSuccess: 'Thing successfully removed.',
    deviceDeleteFailure: 'Failed to delete Thing.',
    deviceDeleteConfirmation: {
        heading: 'Delete Thing',
        text: 'Are you sure you want to delete this Thing ({controller})?'
    },
    updateDevice: 'Change Thing Properties',
    deviceUpdateSuccess: 'Thing properties successfully changed.',
    deviceUpdateFailure: 'Failed to change Thing properties.',
    deviceUpdateControllerTaken: 'This Thing ID is already taken by another Thing.',
    serviceSequenceInProgress: 'Service sequence in progress.',
    nextRun: 'Next Run',
    launch: 'Launch',
    insufficientPermissionsToViewServiceSequence: 'Insufficient permissions to view service sequence.'
};