import gql from 'graphql-tag'

export const serviceHistoryEntryTypeEnumValues = gql`
  query serviceHistoryEntryTypeEnumValues {
    __type(name: "ServiceHistoryEntryTypeEnum") {
      enumValues {
        name
      }
    }
  }
`
