<template>
  <v-text-field
    :value="value"
    @input="input($event)"
    :error-messages="errors"
    required
    dense
    filled
    @blur="$v.value.$touch()"
    type="number"
    hide-details="auto"
    v-bind="options"
    style="width: 100px"
  ></v-text-field>
</template>
<script>
import { required, integer, minValue } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  name: 'IntegerEligibilityInput',
  mixins: [validationMixin],
  props: {
    value: [Number, String],
    options: Object,
  },
  validations: {
    value: { required, integer, minValue: minValue(0) },
  },
  methods: {
    input(newValue) {
      this.$emit('input', newValue)
      this.$v.value.$touch()
    },
  },
  computed: {
    errors() {
      const errors = []
      if (!this.$v.value.$dirty) {
        return errors
      }
      !this.$v.value.required && errors.push(this.$t('global.formValidation.required'))
      !this.$v.value.integer && errors.push(this.$t('global.formValidation.integer'))
      !this.$v.value.minValue && errors.push(this.$t('global.formValidation.min', { min: 0 }))
      return errors
    },
  },
  watch: {
    errors() {
      this.$emit('hasErrors', Boolean(this.errors.length))
    },
  },
}
</script>
