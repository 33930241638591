import { isTokenValid } from '@/graphql/query/isTokenValid'
import { apolloClient } from '@/graphql/client'
import store from '@/store'
import queryString from 'query-string'
import { getItem } from '@/middleware/storage'

/**
 * Allow rigistration only with invitation. Invitation link contains token and email.
 */
export const registrationGuard = async (to, from, next) => {
  let response
  if (getItem('redirect')) {
    const redirectQuery = queryString.parse(queryString.extract(getItem('redirect')))
    if (redirectQuery.email && (redirectQuery.createCompanyToken || redirectQuery.token)) {
      response = await apolloClient.query({
        query: isTokenValid,
        variables: {
          token: redirectQuery.createCompanyToken || redirectQuery.token,
          email: redirectQuery.email,
        },
      })
    }
  } else if (to.query.email && (to.query.token || to.query.createCompanyToken)) {
    response = await apolloClient.query({
      query: isTokenValid,
      variables: {
        token: to.query.token || to.query.createCompanyToken,
        email: to.query.email,
      },
    })
  } else {
    next({ name: 'LoginPage' })
    return
  }

  if (response.data.isTokenValid) {
    next()
  } else {
    store.commit('user/setLoginError', 'FE_INVALID_INVITATION')
    next({ name: 'LoginPage' })
  }
}
