import gql from 'graphql-tag'

export const companyNotificationClassDelaysUseDefault = gql`
  mutation companyNotificationClassDelaysUseDefault($companyId: ID!, $useDefault: Boolean!) {
    companyNotificationClassDelaysUseDefault(
      input: { companyId: $companyId, useDefault: $useDefault }
    ) {
      success
    }
  }
`
