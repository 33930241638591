import gql from 'graphql-tag'
import { DeviceRelatedPermissions } from '../fragments/deviceRelatedPermissions'
import { ControlFields } from '../fragments/controlFields'
import { ImageData } from '../fragments/imageData'

export const getDevice = gql`
  query GetDevice(
    $id: ID!
    $lang: String!
    $withFullCustomerData: Boolean!
    $withRestrictedCustomerData: Boolean!
    $withGrafanaLink: Boolean!
    $directAccessView: Boolean! = false
  ) {
    device(deviceId: $id) {
      id
      name
      type
      controller
      operatingMode
      inOperationSince
      controlsEnabled
      connectionStatus
      connectionStatusChangedTime
      grafanaLink @include(if: $withGrafanaLink)
      needPropagateDeviceData
      isConfigurationJobInProgress
      dataPropagationFailed
      isControlsOverrideEnabled
      controlOverrideStart
      thingType {
        name
        businessName
        thingTypeFamily {
          name
        }
      }
      address {
        id
        address @include(if: $withFullCustomerData)
        code @include(if: $withRestrictedCustomerData)
        city @include(if: $withRestrictedCustomerData)
        lat @include(if: $withFullCustomerData)
        lng @include(if: $withFullCustomerData)
        approximateLat @include(if: $withRestrictedCustomerData)
        approximateLng @include(if: $withRestrictedCustomerData)
        placeId @include(if: $withFullCustomerData)
        postalCode @include(if: $withFullCustomerData)
      }
      contactInfo @include(if: $withFullCustomerData) {
        name
        phone
        email
        note
      }
      company {
        id
        name
        usesNewEligibilitySystem
      }
      newProduct {
        id
      }
      liveDeviceData {
        runningServiceSequence {
          name
          requiredEligibility
        }
        operatingValues(lang: $lang) {
          ...ControlFields
          validFor
        }
        controls(lang: $lang) {
          ...ControlFields
          forbiddenByUser
          forbiddenByTechnology
        }
        serviceSequences(lang: $lang) {
          ...ControlFields
          forbiddenByUser
          forbiddenByTechnology
        }
        # Disable expert mode for now
        # expertMode(lang: $lang) {
        #   ...ControlFields
        # }
        tabs(lang: $lang) {
          label
          type
          items {
            ... on Tab {
              label
              type
              items {
                ... on TabItem {
                  name
                  label
                  type
                  value
                  unit
                  options
                  formattedValue
                  canSetInTab
                }
              }
            }
            ... on TabItem {
              name
              label
              type
              value
              unit
              options
              formattedValue
              canSetInTab
            }
          }
        }
      }
      eventDetectionPoints {
        eventCodes {
          severity
          description(lang: $lang)
        }
      }
      userAccess @include(if: $directAccessView) {
        id
        customDeviceName
        type
      }
      eligibilities
    }
  }
  ${ControlFields}
`

export const deviceLiveData = gql`
  query deviceLiveData($id: ID!, $lang: String!) {
    device(deviceId: $id) {
      id
      needPropagateDeviceData
      isConfigurationJobInProgress
      dataPropagationFailed
      connectionStatus
      connectionStatusChangedTime
      liveDeviceData {
        runningServiceSequence {
          name
          requiredEligibility
        }
        operatingValues(lang: $lang) {
          value
          desiredValue
          statusLabel
          statusIcon
        }
        controls(lang: $lang) {
          value
          desiredValue
          statusLabel
          statusIcon
        }
        serviceSequences(lang: $lang) {
          value
          desiredValue
          statusLabel
          statusIcon
        }
        # Disable expert mode for now
        # expertMode(lang: $lang) {
        #   value
        #   desiredValue
        # }
      }
      eventDetectionPoints {
        eventCodes {
          severity
          description(lang: $lang)
        }
      }
    }
  }
`

export const searchDevices = gql`
  query searchDevices($searchText: String!, $companyId: ID) {
    searchDevices(searchText: $searchText, companyId: $companyId) {
      id
      name
      type
      connectionStatus
      company {
        id
        name
      }
      eventDetectionPoints {
        eventCodes {
          severity
        }
      }
    }
  }
`

export const userDevices = gql`
  query GetUserDevices(
    $type: DeviceTypeEnum!
    $companyId: ID
    $searchText: String
    $pageNumber: Int! = 1
    $connectionStatus: Boolean
    $withEvents: Boolean
    $withCompanyName: Boolean! = false
    $withCustomName: Boolean! = false
    $withAddress: Boolean! = false
    $lang: String!
  ) {
    me {
      id
      devices(
        type: $type
        companyId: $companyId
        searchText: $searchText
        pageNumber: $pageNumber
        connectionStatus: $connectionStatus
        withEvents: $withEvents
      ) {
        id
        name
        connectionType: paramByName(name: "connectionType", lang: $lang) {
          formattedValue
        }
        customName @include(if: $withCustomName)
        controller
        operatingMode
        connectionStatus
        connectionStatusChangedTime
        company @include(if: $withCompanyName) {
          id
          name
        }
        eventDetectionPoints {
          eventCodes {
            severity
            description(lang: $lang)
          }
        }
        address @include(if: $withAddress) {
          lat
          lng
          approximateLat
          approximateLng
        }
      }
    }
  }
`

export const devicesCount = gql`
  query DevicesCount($type: DeviceTypeEnum!, $companyId: ID!) {
    allDevicesCount: devicesCount(type: $type, companyId: $companyId)
    onlineDevicesCount: devicesCount(type: $type, companyId: $companyId, connectionStatus: true  )
    offlineDevicesCount: devicesCount(type: $type, companyId: $companyId, connectionStatus: false)
    withEventsDevicesCount: devicesCount(type: $type, companyId: $companyId, withEvents: true)
  }
`

export const filteredDevicesCount = gql`
  query FilteredDevicesCount($type: DeviceTypeEnum!, $companyId: ID!, $searchText: String) {
    filteredDevicesCount: devicesCount(type: $type, companyId: $companyId, searchText: $searchText)
    filteredOnlineDevicesCount: devicesCount(
      type: $type
      companyId: $companyId
      searchText: $searchText
      connectionStatus: true
    )
    filteredOfflineDevicesCount: devicesCount(
      type: $type
      companyId: $companyId
      searchText: $searchText
      connectionStatus: false
    )
    filteredWithEventsDevicesCount: devicesCount(
      type: $type
      companyId: $companyId
      searchText: $searchText
      withEvents: true
    )
  }
`

export const getDevicePermissions = gql`
  query GetDevicePermissions($id: ID!) {
    device(deviceId: $id) {
      id
      userPermissions {
        ...DeviceRelatedPermissions
      }
    }
  }
  ${DeviceRelatedPermissions}
`

export const getDevicePermissionsAndEligibilities = gql`
  query GetDevicePermissions($deviceId: ID!) {
    device(deviceId: $deviceId) {
      id
      eligibilities
      userPermissions {
        ...DeviceRelatedPermissions
      }
    }
  }
  ${DeviceRelatedPermissions}
`

export const deviceSystemLogs = gql`
  query deviceSystemLogs($deviceId: ID!) {
    device(deviceId: $deviceId) {
      id
      systemLogs {
        message
        created
        count
      }
    }
  }
`

export const deviceImages = gql`
  query DeviceImages($deviceId: ID!) {
    device(deviceId: $deviceId) {
      id
      images {
        ...ImageData
      }
    }
  }
  ${ImageData}
`

export const deviceUserAccesses = gql`
  query deviceUserAccesses($deviceId: ID!) {
    device(deviceId: $deviceId) {
      id
      userAccesses {
        id
        type
        accessStart
        hasValidDeviceAdminInvitation
        user {
          id
          email
        }
      }
      wifiCredentialsAvailable
    }
  }
`

export const getDeviceThingType = gql`
  query GetDevice($deviceId: ID!) {
    device(deviceId: $deviceId) {
      id
      name
      thingType {
        name
      }
    }
  }
`
