<template>
  <v-main>
    <v-container class="text-center pt-10">
      <h2>
        <!-- hack: fix when this issue will be resolved: https://github.com/vuejs/vue-router/issues/977 -->
        {{
          $route.path.includes('//')
            ? $t('global.insufficientPermissions')
            : $t('global.pageNotFound')
        }}
      </h2>
      <v-btn @click="$router.back()" color="primary">{{ $t('global.back') }}</v-btn>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'ErrorsPage',
}
</script>

<style scoped lang="less"></style>
