export const notificationClasses = {
  NO_NOTIFICATION: {
    classNumber: 0,
  },
  END_USER_ONLY: {
    classNumber: 1,
  },
  END_USER_THEN_PROFESSIONAL_USER: {
    classNumber: 2,
  },
  END_USER_AND_PROFESSIONAL_USER: {
    classNumber: 3,
  },
  PROFESSIONAL_USER_THEN_END_USER: {
    classNumber: 4,
  },
  PROFESSIONAL_USER_ONLY: {
    classNumber: 5,
  },
}
