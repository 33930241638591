<template>
  <v-select v-bind="$attrs" v-on="$listeners">
    <template v-slot:selection="{ item }">
      <div class="d-flex align-center">
        <div class="big-square" :style="{ backgroundColor: item.color }"></div>
        <span class="selection-text">{{ item.label }}</span>
      </div>
    </template>
    <template v-slot:item="{ active, item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <div class="d-flex align-center">
          <div class="big-square" :style="{ backgroundColor: item.color }"></div>
          <span>{{ item.label }}</span>
        </div>
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
export default {
  name: 'TimeScheduleStateSelect',
}
</script>
<style lang="less" scoped>
@import '~@/components/TimeSchedules/timeSchedule.less';

.big-square {
  margin-right: 10px;
}
.selection-text {
  width: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
