<template>
  <div>
    <div class="base" :style="{ height: `${height}px` }">
      <div v-for="(interval, index) of defaultNotForbiddenIntervals" :key="`default-${index}`" class="default-interval"
        :style="{
          background: getIntervalBackground(interval),
          left: `${(interval.begin / 86400) * 100}%`,
          right: `${100 - (interval.end / 86400) * 100}%`,
        }"></div>
      <div v-for="(interval, index) of timeScheduleWithDefaultIntervals" :key="index" class="interval"
        :class="{ default: interval.state === 'default' }" :style="{
          backgroundColor:
            interval.state === 'default' ||
            !configMap.get(timeScheduleName).stateMap.get(interval.state)
              ? 'transparent'
              : configMap.get(timeScheduleName).stateMap.get(interval.state).color,
          left: `${(interval.begin / 86400) * 100}%`,
          right: `${100 - (interval.end / 86400) * 100}%`,
        }">
        <div class="interval-hover-helper">
          <div class="interval-texts">
            <span class="interval-begin-end">{{ formatSecondsToTime(interval.begin) }}
              -
              {{ formatSecondsToTime(interval.end) }}</span>
            <span class="interval-description">
              {{
                interval.state === 'default' ||
                  !configMap.get(timeScheduleName).stateMap.get(interval.state)
                  ? configMap.get(timeScheduleName).defaultStateLabel
                  : configMap.get(timeScheduleName).stateMap.get(interval.state).label
              }}</span>
          </div>
        </div>
      </div>
      <div v-for="(interval, index) of forbiddenIntervals" :key="`forbidden-${index}`" class="forbidden-interval"
        :style="{
          left: `${(interval.begin / 86400) * 100}%`,
          right: `${100 - (interval.end / 86400) * 100}%`,
        }"></div>
    </div>
  </div>
</template>
<script>
import { formatSecondsToTime } from '@/utils/timeSchedule'

export default {
  name: 'PlanMinimap',
  props: {
    timeSchedule: Object,
    timeScheduleConfig: Object,
    timeScheduleName: String,
    height: {
      type: Number,
      default: 38,
    },
  },
  methods: {
    formatSecondsToTime,
    checkCondition() {
      const stateMap = this.configMap.get(this.timeScheduleName).stateMap
      // Iterate over each interval in the schedule
      for (const interval of this.timeSchedule[this.timeScheduleName]) {
        // If the interval's state is not 'default' and also not found in the stateMap, return true
        if (interval.state !== 'default' && !stateMap.has(interval.state)) {
          return true
        }
      }
      // If all intervals have a valid state, return false
      return false
    },
    getDefaultIntervalBackground(onColor) {
      return `repeating-linear-gradient(90deg,rgba(255, 255, 255, 1) 0px,rgba(255, 255, 255, 1) 4px,${onColor} 4px,${onColor} 6px,rgba(255, 255, 255, 1) 6px,rgba(255, 255, 255, 1) 9px)`;
    },
    getIntervalBackground(interval) {
      if (interval.state === 'default') {
        const ret = this.getDefaultIntervalBackground(this.configMap.get(this.timeScheduleName)?.defaultStateStripesColor ?? 'green');
        return ret;
      }

      const stateConfig = this.configMap.get(this.timeScheduleName)?.stateMap.get(interval.state);

      if (!stateConfig) {
        return 'white';
      }

      return stateConfig.color;
    },
  },
  mounted() {
    if (this.checkCondition()) {
      this.$emit('planErrorConditionMet')
    }
  },
  computed: {
    timeScheduleWithDefaultIntervals() {
      const helperSchedule = [
        { end: 0 },
        ...this.timeSchedule[this.timeScheduleName],
        { begin: 86400 },
      ]
      const schedule = []
      helperSchedule.forEach((interval, index) => {
        if (index === helperSchedule.length - 1) {
          return
        }
        if (index !== 0) {
          schedule.push(interval)
        }
        if (interval.end < helperSchedule[index + 1].begin) {
          schedule.push({
            begin: interval.end,
            end: helperSchedule[index + 1].begin,
            state: 'default',
          })
        }
      })
      return schedule
    },
    defaultNotForbiddenIntervals() {
      const defaultIntervals = [];
      // Default intervals have the state == 'default'
      this.timeScheduleWithDefaultIntervals.forEach((interval) => {
        if (interval.state === 'default') {
          defaultIntervals.push(interval)
        }
      })
      const forbiddenIntervals = this.forbiddenIntervals;
      const result = [];
      defaultIntervals.forEach((interval) => {
        // Cut the part which is in forbidden intervals
        const forbiddenIntervalsInsideThisInterval = forbiddenIntervals.filter((forbiddenInterval) => forbiddenInterval.begin >= interval.begin && forbiddenInterval.end <= interval.end);
        if (forbiddenIntervalsInsideThisInterval.length > 0) {
          let begin = interval.begin;
          forbiddenIntervalsInsideThisInterval.forEach((forbiddenInterval) => {
            if (forbiddenInterval.begin > begin) {
              result.push({
                begin: begin,
                end: forbiddenInterval.begin,
                state: 'default',
              })
            }
            begin = forbiddenInterval.end;
          })
          result.push({
            begin: begin,
            end: interval.end,
            state: 'default',
          })
        } else {
          result.push(interval)
        }
      })
      return result;
    },
    forbiddenIntervals() {
      const result = []
      const forbiddenIf = this.configMap.get(this.timeScheduleName).scheduleForbiddenIf
      if (forbiddenIf) {
        this.timeSchedule[forbiddenIf.key]?.forEach((interval) => {
          if (forbiddenIf.c === 'equal') {
            if (interval.state === forbiddenIf.value) {
              result.push({
                begin: interval.begin,
                end: interval.end,
              })
            }
          }
        })
      }
      return result
    },
    configMap() {
      const configMap = new Map()
      this.timeScheduleConfig.schedules.forEach((timeSchedule) => {
        const stateMap = new Map()
        timeSchedule.states.forEach((state) => {
          stateMap.set(state.name, state)
        })
        configMap.set(timeSchedule.name, { ...timeSchedule, stateMap })
      })
      return configMap
    },
  },
}
</script>
<style lang="less" scoped>
.base {
  position: relative;
}

.interval,
.default-interval,
.forbidden-interval {
  height: 100%;
  position: absolute;
  border-radius: 4px;
}

.forbidden-interval {
  pointer-events: none;
}

.default {
  border: 1px solid #e7e7e7;
}

.interval,
.interval-hover-helper {
  container-type: inline-size;
}

.interval-hover-helper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.forbidden-interval {
  background: repeating-linear-gradient(-45deg,
      rgba(219, 219, 219, 0.3),
      rgba(219, 219, 219, 0.3) 5px,
      rgba(219, 219, 219, 0.8) 5px,
      rgba(219, 219, 219, 0.8) 25px);
}

@container (max-width: 65px) {
  .interval-hover-helper:hover .interval-texts {
    display: block;
  }

  .interval-hover-helper {
    padding: 0 !important;
  }

  .interval-texts {
    display: none;
    position: absolute;
    top: -33px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(45px);
    color: white;
    padding: 7px 10px;
    white-space: nowrap;
    z-index: 10;
  }
}

.interval-texts {
  font-size: 10px;
}

.interval-begin-end {
  font-size: 10px;
  font-weight: 500;
  margin-right: 6px;
}
</style>
