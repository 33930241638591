import gql from 'graphql-tag'

export const generalEligibilitiesOnlyNames = gql`
  query generalEligibilitiesOnlyNames($lang: String) {
    eligibilitiesConfig {
      general {
        name
        label(lang: $lang)
        description(lang: $lang)
      }
    }
  }
`

export const generalEligibilities = gql`
  query generalEligibilities($lang: String) {
    eligibilitiesConfig {
      general {
        name
        type
        defaultValue
        defaultAvailability
        label(lang: $lang)
        description(lang: $lang)
        provisionType
        isPremium
      }
    }
  }
`
