import gql from 'graphql-tag'

export const DeviceRelatedPermissions = gql`
  fragment DeviceRelatedPermissions on Permissions {
    configurationChanges
    configurationUpload
    deviceControl
    expertMode
    operationalHistory
    restrictedCustomerData
    fullCustomerData
    userManagement
    serviceSequences
  }
`
