import Big from 'big.js'

const formatFunctions = {
  TEMP_CELSIUS: (value, options) => formatHelper(value, '°C', options),
  VOLTAGE: (value, options) => formatHelper(value, 'V', options),
  METER: (value, options) => formatHelper(value, 'm', options),
  SQUARE_METER: (value, options) => formatHelper(value, 'm²', options),
  CUBIC_METER: (value, options) => formatHelper(value, 'm³', options),
  LITER: (value, options) => formatHelper(value, 'l', options),
  SECOND: (value, options) => formatHelper(value, 's', options),
  HH_FORMAT: (value, options) => {
    const totalSeconds = parseInt(value, 10)
    if (isNaN(totalSeconds)) {
      return formatHelper(value, 's', options)
    }

    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60

    const paddedHours = hours.toString().padStart(2, '0')
    const paddedMinutes = minutes.toString().padStart(2, '0')
    const paddedSeconds = seconds.toString().padStart(2, '0')

    const timeFormat = `${paddedHours}:${paddedMinutes}:${paddedSeconds}`

    return {
      value: timeFormat,
      unit: '',
      printValue: timeFormat,
    }
  },
  POWER: (value, options) => formatHelper(value, 'W', options),
  AMPERE: (value, options) => formatHelper(value, 'A', options),
  PERCENT: (value, options) => formatHelper(value, '%', options),
  PPM: (value, options) => formatHelper(value, 'ppm', options),
  V_PPM: (value, options) => formatHelper(value, 'V/ppm', options),
  G_H: (value, options) => formatHelper(value, 'g/h', options),
  KILOGRAM: (value, options) => {
    let parsedValue = parseFloat(value)
    if (!isNaN(parsedValue)) {
      parsedValue *= 1000
    }
    return formatHelper(parsedValue, 'g', options)
  },

  BOOLEAN: (value, targetUnitOptions) => {
    if (value != null) {
      if (value === 'true') {
        return {
          value: targetUnitOptions.vue.$t(`global.yes`),
          unit: '',
          printValue: targetUnitOptions.vue.$t(`global.yes`),
        }
      }
      if (value === 'false') {
        return {
          value: targetUnitOptions.vue.$t(`global.no`),
          unit: '',
          printValue: targetUnitOptions.vue.$t(`global.no`),
        }
      }
    }
    return {
      value: '-',
      unit: '',
      printValue: '-',
    }
  },
}

const metricPrefixMap = {
  M: 1000000,
  k: 1000,
  h: 100,
  da: 10,
  '': 1,
  d: 0.1,
  c: 0.01,
  m: 0.001,
}

function getValueAndMetricPrefix(value, baseUnit, options, isFixedPrefix, defaultMetricPrefix) {
  let metricPrefix = defaultMetricPrefix
  let printValue = new Big(value)
  if (options?.metricPrefix) {
    const metricPrefixes = options.metricPrefix
      .split(',')
      .map((prefix) => (prefix === '1' ? '' : prefix))

    if (metricPrefixes.length > 1) {
      const currentPrefixes = metricPrefixes
        .map((prefix) => ({
          prefix,
          value: metricPrefixMap[prefix],
        }))
        .sort((a, b) => b.value - a.value) // descending

      for (let i = 0; i < currentPrefixes.length; i++) {
        if (printValue.gte(currentPrefixes[i].value) || i === currentPrefixes.length - 1) {
          metricPrefix = currentPrefixes[i].prefix
          printValue = printValue.div(currentPrefixes[i].value)
          break
        }
      }
    } else {
      metricPrefix = metricPrefixes[0]
      printValue = printValue.div(metricPrefixMap[metricPrefix])
    }
  }

  if (options?.digits != null) {
    printValue = printValue.toFixed(options.digits)
  } else {
    printValue = printValue.toString()
  }

  if (parseFloat(printValue) === 0 && !isFixedPrefix) {
    metricPrefix = baseUnit === 'g' ? 'k' : ''
  }

  return {
    printValue,
    metricPrefix,
  }
}

export function formatHelper(value, baseUnit, options) {
  const parsedValue = parseFloat(value)
  let metricPrefix = options?.metricPrefix?.indexOf(',') === -1 ? options.metricPrefix : ''
  const isFixedPrefix = metricPrefix !== ''
  if (isNaN(parsedValue)) {
    if (baseUnit === 'g' && metricPrefix === '') {
      metricPrefix = 'k'
    }
    return {
      value: '-',
      unit: `${metricPrefix}${baseUnit}`,
      printValue: `- ${metricPrefix}${baseUnit}`,
    }
  }

  let ltOrGt = null
  let minOrMaxVisibleValue = null
  if (options?.minVisible != null && parsedValue < options.minVisible) {
    ltOrGt = '<'
    minOrMaxVisibleValue = options.minVisible
  } else if (options?.maxVisible != null && parsedValue > options.maxVisible) {
    ltOrGt = '>'
    minOrMaxVisibleValue = options.maxVisible
  }

  const { printValue, metricPrefix: newMetricPrefix } = getValueAndMetricPrefix(
    parsedValue,
    baseUnit,
    options,
    isFixedPrefix,
    metricPrefix,
  )

  if (minOrMaxVisibleValue != null) {
    const { printValue: printMaxOrMinValue, metricPrefix: metricPrefixMaxOrMin } =
      getValueAndMetricPrefix(minOrMaxVisibleValue, baseUnit, options, isFixedPrefix, metricPrefix)

    return {
      value: printValue,
      unit: `${newMetricPrefix}${baseUnit}`,
      printValue: `${ltOrGt} ${printMaxOrMinValue} ${metricPrefixMaxOrMin}${baseUnit}`,
    }
  }

  return {
    value: printValue,
    unit: `${newMetricPrefix}${baseUnit}`,
    printValue: `${printValue} ${newMetricPrefix}${baseUnit}`,
  }
}

// CAUTION: in case currentUnit is SQUARE_METER or CUBIC_METER, do not use volatile metricPrefix - will produce incorrect results
export function formatPrintValue(value, currentUnit, targetUnitOptions) {
  if (formatFunctions[currentUnit]) {
    return formatFunctions[currentUnit](value, { ...targetUnitOptions, vue: this }).printValue
  } else {
    return formatHelper(value, '', { ...targetUnitOptions, vue: this }).printValue
  }
}

export function formatValueDigits(value, targetUnitOptions) {
  if (targetUnitOptions?.digits) {
    return value != null ? parseFloat(value).toFixed(targetUnitOptions.digits) : '-'
  }

  return value ?? '-'
}

export function formatValue(value, currentUnit, targetUnitOptions) {
  if (formatFunctions[currentUnit]) {
    return formatFunctions[currentUnit](value, { ...targetUnitOptions, vue: this }).value
  }

  return value ?? '-'
}

export function formatUnit(value, currentUnit, targetUnitOptions) {
  if (formatFunctions[currentUnit]) {
    return formatFunctions[currentUnit](value, { ...targetUnitOptions, vue: this }).unit
  }

  return ''
}

export function toBaseUnits(value, currentUnit, options) {
  let metricPrefix = options?.metricPrefix?.indexOf(',') === -1 ? options.metricPrefix : ''
  const isFixedPrefix = metricPrefix !== ''

  if (isFixedPrefix) {
    if (currentUnit === 'KILOGRAM') {
      return Big(value).times(metricPrefixMap[metricPrefix]).div(1000).toNumber()
    }
    return Big(value).times(metricPrefixMap[metricPrefix]).toNumber()
  }

  return value
}

export default {
  methods: {
    formatPrintValue,
    formatValueDigits,
    formatValue,
    formatUnit,
    toBaseUnits,
  },
}
