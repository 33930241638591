import gql from 'graphql-tag'
import { AllPermissions } from '../fragments/allPermissions'

export const userGetAndCreateIfNotExists = gql`
  mutation {
    userGetAndCreateIfNotExists(input: {}) {
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        deactivated
        deviceTypes
        companies {
          id
          name
          userPermissions {
            ...AllPermissions
          }
          deviceTypesAvailableToUser
          generalEligibilities
        }
      }
    }
  }
  ${AllPermissions}
`
