import gql from 'graphql-tag'

export const companyRemoveServiceCompany = gql`
  mutation companyRemoveServiceCompany($childCompanyId: ID!, $parentCompanyId: ID!) {
    companyRemoveServiceCompany(
      input: { childCompanyId: $childCompanyId, parentCompanyId: $parentCompanyId }
    ) {
      success
    }
  }
`
