import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar-ng'

import PoolIcon from '@/assets/images/icon-pool.svg'
import BulbIcon from '@/assets/icons/icon-bulb.svg'
import CoolingIcon from '@/assets/icons/icon-cooling.svg'
import FilterIcon from '@/assets/icons/icon-filter.svg'
import AirFlowIcon from '@/assets/icons/icon-air-flow.svg'
import ChemicalsIcon from '@/assets/icons/icon-chemicals.svg'
import UploadIcon from '@/assets/icons/icon-upload.svg'
import SettingsIcon from '@/assets/icons/icon-settings.svg'
import ChlorineIcon from '@/assets/icons/icon-chlorine.svg'
import HeatingIcon from '@/assets/icons/icon-heating.svg'
import QRIcon from '@/assets/icons/icon-qr.svg'
import DropPlusIcon from '@/assets/icons/icon-drop-plus.svg'
import PlusIcon from '@/assets/icons/icon-plus.svg'
import PaperIcon from '@/assets/icons/icon-paper.svg'
import BuildingIcon from '@/assets/icons/icon-building.svg'
import FountainIcon from '@/assets/icons/icon-fountain.svg'
import MassageIcon from '@/assets/icons/icon-massage.svg'
import OrpIcon from '@/assets/icons/icon-orp.svg'
import PhIcon from '@/assets/icons/icon-ph.svg'
import PhAgentIcon from '@/assets/icons/icon-ph-agent.svg'
import TankLevelIcon from '@/assets/icons/icon-tank-level.svg'
import WeatherIcon from '@/assets/icons/icon-weather.svg'
import EnclosureOpen from '@/assets/icons/icon-enclosure-open.svg'
import EnclosureClose from '@/assets/icons/icon-enclosure-close.svg'
import SewagePumpIcon from '@/assets/icons/icon-sewage-pump.svg'
import MainPumpTriggerIcon from '@/assets/icons/icon-main-pump-trigger.svg'
import ProtectionIcon from '@/assets/icons/icon-protection.svg'
import ProtectionRestartIcon from '@/assets/icons/icon-protection-restart.svg'
import FilterBackwashIcon from '@/assets/icons/icon-filter-backwash.svg'
import CzFlagIcon from '@/assets/flags/cz.svg'
import GbFlagIcon from '@/assets/flags/gb.svg'
import DeFlagIcon from '@/assets/flags/de.svg'
import PlFlagIcon from '@/assets/flags/pl.svg'
import ChargePointIcon from '@/assets/icons/icon-charge-point.svg'
import BoostIcon from '@/assets/icons/icon-boost.svg'
import ChargingBatteryIcon from '@/assets/icons/icon-charging-battery.svg'
import PhasesIcon from '@/assets/icons/icon-phases.svg'
import BottomLevelElectrode from '@/assets/icons/bottom-level-electrode.svg'
import TopLevelElectrode from '@/assets/icons/top-level-electrode.svg'
import ProtectionEmergencyStop from '@/assets/icons/protection-emergency-stop.svg'
import SterilizeIcon from '@/assets/icons/icon-sterilize.svg'
import TwoDropsIcon from '@/assets/icons/icon-two-drops.svg'
import ChemicalProbeCalibrationIcon from '@/assets/icons/icon-chemical-probe-calibration.svg'
import AuxiliaryCirculationPumpIcon from '@/assets/icons/icon-auxiliary-circulation-pump.svg'
import BubbleBathIcon from '@/assets/icons/icon-bubble-bath.svg'
import AttratctionIcon from '@/assets/icons/icon-attraction.svg'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
})
Vue.use(VuetifyToast, {
  x: 'right',
  y: 'bottom',
  color: 'info',
  icon: 'mdi-information',
  timeout: 5000,
  dismissable: true,
  closeIcon: 'mdi-close',
  property: '$toast',
  shorts: {
    error: {
      color: 'error',
      icon: 'mdi-alert-circle',
    },
    success: {
      color: 'success',
      icon: 'mdi-check',
    },
  },
})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        anchor: '#4e4768',
        primary: '#1f97ff',
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1200,
    },
    scrollBarWidth: 24,
  },
  icons: {
    values: {
      pool: { component: PoolIcon },
      bulb: { component: BulbIcon },
      filter: { component: FilterIcon },
      'air-flow': { component: AirFlowIcon },
      chemicals: { component: ChemicalsIcon },
      upload: { component: UploadIcon },
      settings: { component: SettingsIcon },
      chlorine: { component: ChlorineIcon },
      heating: { component: HeatingIcon },
      qr: { component: QRIcon },
      'drop-plus': { component: DropPlusIcon },
      plus: { component: PlusIcon },
      paper: { component: PaperIcon },
      building: { component: BuildingIcon },
      fountain: { component: FountainIcon },
      massage: { component: MassageIcon },
      orp: { component: OrpIcon },
      ph: { component: PhIcon },
      'ph-agent': { component: PhAgentIcon },
      'tank-level': { component: TankLevelIcon },
      weather: { component: WeatherIcon },
      'enclosure-open': { component: EnclosureOpen },
      'enclosure-close': { component: EnclosureClose },
      'sewage-pump': { component: SewagePumpIcon },
      'main-pump-trigger': { component: MainPumpTriggerIcon },
      protection: { component: ProtectionIcon },
      'protection-restart': { component: ProtectionRestartIcon },
      'filter-backwash': { component: FilterBackwashIcon },
      cs: { component: CzFlagIcon },
      en: { component: GbFlagIcon },
      de: { component: DeFlagIcon },
      pl: { component: PlFlagIcon },
      'charge-point': { component: ChargePointIcon },
      boost: { component: BoostIcon },
      'charging-battery': { component: ChargingBatteryIcon },
      phases: { component: PhasesIcon },
      'bottom-level-electrode': { component: BottomLevelElectrode },
      'top-level-electrode': { component: TopLevelElectrode },
      'protection-emergency-stop': { component: ProtectionEmergencyStop },
      sterilize: { component: SterilizeIcon },
      'two-drops': { component: TwoDropsIcon },
      'chemical-probe-calibration': { component: ChemicalProbeCalibrationIcon },
      cooling: { component: CoolingIcon },
      attraction: { component: AttratctionIcon },
      'bubble-bath': { component: BubbleBathIcon },
      'auxiliary-circulation-pump': { component: AuxiliaryCirculationPumpIcon },
    },
  },
})
