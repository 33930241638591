<template>
  <div>
    <div v-for="item of accessibleTabs" :key="item.label" class="mb-5">
      <h2 class="mb-3">{{ item.label }}</h2>
      <component
        :is="selectTabType(item.type)"
        :tabConfig="item"
        :device="device"
        :permissionChecker="permissionChecker"
      ></component>
    </div>
  </div>
</template>
<script>
import DisplayTab from '@/components/DeviceDetail/Tabs/DisplayTab'
import InstallationSiteTab from '@/components/DeviceDetail/Tabs/InstallationSiteTab'
import GraphsTab from '@/components/DeviceDetail/Tabs/GraphsTab'
import HistoryTab from '@/components/DeviceDetail/Tabs/HistoryTab'
import TimeScheduleTab from '@/components/DeviceDetail/Tabs/TimeScheduleTab'
import GalleryTab from '@/components/DeviceDetail/Tabs/GalleryTab'
import UserAccessesTab from '@/components/DeviceDetail/Tabs/UserAccessesTab'
import ServiceHistoryTab from '@/components/DeviceDetail/Tabs/ServiceHistoryTab'
import OperationCounterTab from '@/components/DeviceDetail/Tabs/OperationCounterTab'
import ServiceOrdersTab from '@/components/DeviceDetail/Tabs/ServiceOrdersTab'
import NotificationSettingsTab from '@/components/DeviceDetail/Tabs/NotificationSettingsTab'
import ChargingHistoryTab from '@/components/DeviceDetail/Tabs/ChargingHistoryTab'
import { tabAccessibilityChecker } from '@/utils/tabAccessibilityChecker'

export default {
  name: 'ComposedTab',
  components: {
    DisplayTab,
    InstallationSiteTab,
    GraphsTab,
    HistoryTab,
    TimeScheduleTab,
    GalleryTab,
    UserAccessesTab,
    ServiceHistoryTab,
    OperationCounterTab,
    ServiceOrdersTab,
    NotificationSettingsTab,
    ChargingHistoryTab,
  },
  props: {
    tabConfig: Object,
    device: Object,
    permissionChecker: Function,
  },
  methods: {
    selectTabType(type) {
      const tabTypeMap = {
        DISPLAY: DisplayTab,
        INSTALLATION_SITE: InstallationSiteTab,
        GRAPHS: GraphsTab,
        HISTORY: HistoryTab,
        TIMEPLAN: TimeScheduleTab,
        GALLERY: GalleryTab,
        USER_ACCESSES: UserAccessesTab,
        SERVICE_HISTORY: ServiceHistoryTab,
        OPERATION_COUNTERS: OperationCounterTab,
        SERVICE_ORDERS: ServiceOrdersTab,
        NOTIFICATION_SETTINGS: NotificationSettingsTab,
        CHARGING_HISTORY: ChargingHistoryTab,
      }
      return tabTypeMap[type] || DisplayTab
    },
  },
  computed: {
    accessibleTabs() {
      return this.tabConfig.items.filter((tab) =>
        tabAccessibilityChecker(
          tab.type,
          this.permissionChecker,
          this.device.userAccess?.type,
          !this.currentCompanyId,
        ),
      )
    },
  },
}
</script>
<style lang="less" scoped>
h2 {
  font-weight: 500;
  font-size: 18px;
}
</style>
