<template>
  <div class="form-main--row">
    <div class="form-main--column">
      <label class="form-main--label" :class="{ required }" :for="variableName">
        {{ translatedLabel }}
      </label>
    </div>
    <div class="form-main--column">
      <span v-if="hint" class="form-input--hint">{{ hint }}</span>
      <div class="form-input--wrapper" :class="{ error: inputErrors.length }">
        <input
          ref="input"
          @input="updateValue($event.target.value)"
          :value="value"
          class="form-input form-input--post"
          :id="variableName"
          :type="type"
          @change="$emit('change', $event.target.value)"
          @blur="$emit('blur')"
        />
      </div>
      <p v-for="error in inputErrors" :key="`input_${name}_error_${error.error}`">
        {{ $t('device.validations.' + error.error) }}
      </p>
    </div>
  </div>
</template>

<script>
import BasicInput from '@/components/Form/Input/BasicInput'

export default {
  name: 'ClassicInput',
  extends: BasicInput,
  props: {
    type: {
      type: String,
      default: 'text',
    },
  },
}
</script>
