<template>
  <div>
    <div v-if="$apollo.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else class="wrapper">
      <h3 class="my-2">{{ $t('global.companyDetail.emailVariables') }}</h3>
      <p class="variable-description mb-1">
        {{ $t('global.companyDetail.emailVariablesDescription') }}
      </p>
      <div class="mb-5">
        <dl class="variables-legend">
          <div
            v-for="contactEmailVariable of contactEmailVariables"
            :key="contactEmailVariable.name"
            class="d-flex"
          >
            <dt><span v-pre>{{ </span>{{ contactEmailVariable.name }}<span v-pre> }}</span></dt>
            <dd>{{ contactEmailVariable.description }}</dd>
          </div>
        </dl>
      </div>

      <v-text-field
        v-model="contactEmailForm.contactEmailSubject"
        :error-messages="getErrors('contactEmailForm.contactEmailSubject')"
        :label="$t('global.companyDetail.emailSubject')"
        @input="$v.contactEmailForm.contactEmailSubject.$touch()"
        @blur="$v.contactEmailForm.contactEmailSubject.$touch()"
        filled
        counter="255"
      ></v-text-field>
      <v-textarea
        v-model="contactEmailForm.contactEmailBody"
        :error-messages="getErrors('contactEmailForm.contactEmailBody')"
        :label="$t('global.companyDetail.emailBody')"
        @input="$v.contactEmailForm.contactEmailBody.$touch()"
        @blur="$v.contactEmailForm.contactEmailBody.$touch()"
        filled
        auto-grow
        counter="1000"
      ></v-textarea>
      <h3 class="mb-3">{{ $t('global.companyDetail.emailPreview') }}</h3>
      <div class="email-preview">
        <p class="subject">{{ emailSubjectPreview }}</p>
        <p class="body mb-0">{{ emailBodyPreview }}</p>
      </div>

      <div class="mt-7 d-flex flex-wrap">
        <v-btn
          @click="companyUpdateContactEmailData()"
          :block="showBlockButtons"
          class="mr-2"
          :class="{ 'mb-1': showBlockButtons }"
          :disabled="!hasContactEmailFormChanged"
          color="primary"
          depressed
          >{{ $t('global.save') }}</v-btn
        >
        <v-btn
          @click="confirmRestoreDefaultsModalOpen = true"
          :block="showBlockButtons"
          class="mr-2"
          depressed
          >{{ $t('global.companyDetail.restoreDefaults') }}</v-btn
        >
        <v-btn
          v-if="hasContactEmailFormChanged"
          @click="resetContactEmailForm()"
          :block="showBlockButtons"
          depressed
          >{{ $t('global.cancel') }}</v-btn
        >
      </div>

      <ConfirmationDialog
        v-model="confirmRestoreDefaultsModalOpen"
        :heading="$t('global.companyDetail.restoreDefaultsConfirmationModal.heading')"
        :text="$t('global.companyDetail.restoreDefaultsConfirmationModal.text')"
        :confirmText="$t('global.companyDetail.restoreDefaults')"
        confirmBtnColor="error"
        :action="restoreDefaults"
      />
    </div>
  </div>
</template>
<script>
import { companyForContact } from '@/graphql/query/companyForContact'
import { contactEmailVariables } from '@/graphql/query/contactEmailVariables'
import { companyUpdateContactEmailTemplate } from '@/graphql/mutations/companyUpdateContactEmailTemplate'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import vuelidateErrorsExtractor from '@/mixins/vuelidateErrorsExtractor'
import { required, maxLength } from 'vuelidate/lib/validators'
import { eligibilities } from '@/config/eligibilities'
import ConfirmationDialog from '@/components/ConfirmationDialog'

export default {
  name: 'CompanyContactEmailTemplatePage',
  mixins: [validationMixin, vuelidateErrorsExtractor],
  components: { ConfirmationDialog },
  data() {
    return {
      contactEmailForm: {
        contactEmailSubject: null,
        contactEmailBody: null,
      },
      confirmRestoreDefaultsModalOpen: false,
    }
  },
  validations: {
    contactEmailForm: {
      contactEmailSubject: { required, maxLength: maxLength(255) },
      contactEmailBody: { required, maxLength: maxLength(1000) },
    },
  },
  apollo: {
    company: {
      query: companyForContact,
      variables() {
        return {
          companyId: this.currentCompanyId,
        }
      },
    },
    contactEmailVariables: {
      query: contactEmailVariables,
      variables() {
        return {
          lang: this.$i18n.locale,
        }
      },
    },
  },
  methods: {
    async companyUpdateContactEmailData() {
      if (!this.$v.contactEmailForm.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: companyUpdateContactEmailTemplate,
            variables: {
              input: {
                companyId: this.currentCompanyId,
                contactEmailSubject: this.contactEmailForm.contactEmailSubject,
                contactEmailBody: this.contactEmailForm.contactEmailBody,
              },
            },
            refetchQueries: ['companyForContact'],
          })

          this.$toast.success(this.$t('global.updateCompanyContactEmailTemplate.success'))
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.updateCompanyContactEmailTemplate.failure'))
        }
      }
    },
    async restoreDefaults() {
      // restore default email template
      try {
        await this.$apollo.mutate({
          mutation: companyUpdateContactEmailTemplate,
          variables: {
            input: {
              companyId: this.currentCompanyId,
              contactEmailSubject: null,
              contactEmailBody: null,
            },
          },
          refetchQueries: ['companyForContact'],
        })

        this.resetContactEmailForm()
        this.confirmRestoreDefaultsModalOpen = false
        this.$toast.success(this.$t('global.updateCompanyContactEmailTemplate.success'))
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.updateCompanyContactEmailTemplate.failure'))
      }
    },
    resetContactEmailForm() {
      this.contactEmailForm.contactEmailSubject = this.company.contactEmailSubject
      this.contactEmailForm.contactEmailBody = this.company.contactEmailBody
    },
    onBeforeUnload() {
      if (this.hasUnsavedChanges) {
        // modern browsers will ignore this message and will show default
        return this.$t('global.unsavedChanges')
      }
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    ...mapGetters(['hasUnsavedChanges']),
    emailSubjectPreview() {
      return this.contactEmailVariables?.reduce((res, variable) => {
        return res.replace(
          new RegExp(`\\{\\{\\s*${variable.name}\\s*\\}\\}`, 'gm'),
          variable.mockValue,
        )
      }, this.contactEmailForm.contactEmailSubject)
    },
    emailBodyPreview() {
      return this.contactEmailVariables?.reduce((res, variable) => {
        return res.replace(
          new RegExp(`\\{\\{\\s*${variable.name}\\s*\\}\\}`, 'gm'),
          variable.mockValue,
        )
      }, this.contactEmailForm.contactEmailBody)
    },
    hasContactEmailFormChanged() {
      if (!this.company) {
        return false
      }
      return (
        this.contactEmailForm.contactEmailSubject !== this.company.contactEmailSubject ||
        this.contactEmailForm.contactEmailBody !== this.company.contactEmailBody
      )
    },
    showBlockButtons: function () {
      return this.$vuetify.breakpoint.name === 'xs'
    },
    eligibilities() {
      return eligibilities
    },
  },
  watch: {
    company() {
      this.resetContactEmailForm()
    },
    hasContactEmailFormChanged() {
      this.$store.commit('setHasUnsavedChanges', this.hasContactEmailFormChanged)
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('global.unsavedChanges'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload
  },
  destroyed() {
    window.onbeforeunload = undefined
    this.$store.commit('setHasUnsavedChanges', false)
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

h3 {
  font-size: 16px;
}

.wrapper {
  max-width: 700px;
}

.email-preview {
  border: 2px solid @color-brand-highlight;
  border-radius: 10px;
  padding: 10px;
  white-space: pre-wrap;
  font-size: 14px;

  .subject {
    font-weight: bold;
  }
}

.variable-description {
  font-size: 14px;
}

.variables-legend {
  font-size: 14px;

  dt {
    font-weight: 700;
    margin-right: 5px;
    flex-shrink: 0;

    &::after {
      content: ':';
    }
  }
}

/deep/.v-select__selections {
  padding-top: 26px !important;
}
</style>
