<template>
  <div class="pa-6">
    <h1 class="mb-5">{{ $t('global.adminConsole') }}: {{ $t('global.adminConsoleMenu.users') }}</h1>
    <v-data-table
      :headers="headers"
      :items="usersData"
      :options.sync="options"
      :server-items-length="users ? users.totalCount : 0"
      :loading="$apollo.loading"
      :disable-sort="true"
      :no-data-text="$t('users.noMatchingRecordsFound')"
      :footer-props="{
        'items-per-page-text': $t('global.dataTable.itemsPerPageText'),
        'items-per-page-all-text': $t('global.dataTable.allItemsText'),
      }"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-text-field v-model="search" :label="$t('users.search')" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.deleteCol="{ item }">
        <v-btn icon color="error" @click="openDeleteUserConfirmationDialog(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <ConfirmationDialog
      v-if="userToDelete"
      v-model="confirmDeleteModalOpen"
      :heading="$t('users.userAdminDeleteConfirmation.heading')"
      :text="$t('users.userAdminDeleteConfirmation.text', { email: userToDelete.email })"
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
      :action="deleteUser"
    />
  </div>
</template>
<script>
import { users as usersQuery } from '@/graphql/query/users'
import { userDelete } from '@/graphql/mutations/userDelete'
import debounce from 'lodash/debounce'
import ConfirmationDialog from '@/components/ConfirmationDialog'

export default {
  name: 'AdminUsers',
  components: { ConfirmationDialog },
  data() {
    return {
      usersQueryInput: {
        search: null,
        first: 10,
        after: null,
        last: null,
        before: null,
      },
      options: {},
      search: null,
      headers: [
        { text: this.$t('global.email'), value: 'email' },
        { text: this.$t('global.firstName'), value: 'firstName' },
        { text: this.$t('global.lastName'), value: 'lastName' },
        { text: this.$t('global.phoneNumber'), value: 'phoneNumber' },
        { text: this.$t('global.enabled'), value: 'enabled' },
        { text: this.$t('global.deactivated'), value: 'deactivated' },
        { text: '', value: 'deleteCol', align: 'right' },
      ],
      userToDelete: null,
      confirmDeleteModalOpen: false,
    }
  },
  apollo: {
    users: {
      query: usersQuery,
      variables() {
        return this.usersQueryInput
      },
    },
  },
  methods: {
    async deleteUser() {
      try {
        await this.$apollo.mutate({
          mutation: userDelete,
          variables: {
            userId: this.userToDelete.id,
          },
          refetchQueries: ['users'],
        })
        this.$toast.success(this.$t('users.userAdminDeleteSuccess'))
        this.confirmDeleteModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('users.userAdminDeleteFailure'))
      }
    },
    openDeleteUserConfirmationDialog(user) {
      this.userToDelete = user
      this.confirmDeleteModalOpen = true
    },
    debounceSearch: debounce(function (newSearch) {
      this.usersQueryInput = {
        search: newSearch !== '' ? newSearch : null,
        first: this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : null,
        after: null,
        last: null,
        before: null,
      }
    }, 250),
  },
  computed: {
    usersData() {
      return this.users ? this.users.edges.map((edge) => edge.node) : []
    },
  },
  watch: {
    options: {
      handler({ page, itemsPerPage }, { page: oldPage, itemsPerPage: oldItemsPerPage }) {
        if (!oldPage && !oldItemsPerPage) {
          return
        }
        if (oldItemsPerPage !== itemsPerPage) {
          // change page size
          this.usersQueryInput = {
            ...this.usersQueryInput,
            first: itemsPerPage !== -1 ? itemsPerPage : null,
            after: null,
            last: null,
            before: null,
          }
        } else if (page === oldPage + 1) {
          // next page
          this.usersQueryInput = {
            ...this.usersQueryInput,
            first: itemsPerPage,
            after: this.users.pageInfo.endCursor,
            last: null,
            before: null,
          }
        } else if (page === oldPage - 1) {
          // previous page
          this.usersQueryInput = {
            ...this.usersQueryInput,
            first: null,
            after: null,
            last: itemsPerPage,
            before: this.users.pageInfo.startCursor,
          }
        }
      },
      deep: true,
    },
    search() {
      this.debounceSearch(this.search)
    },
  },
}
</script>
<style lang="less" scoped>
h1 {
  font-size: 24px;
}
</style>
