<template>
  <v-row v-bind="config.props">
    <ServiceSequenceLayoutColumn
      v-for="(colConfig, index) of config.children"
      :key="index"
      :config="colConfig"
      :componentConfigs="componentConfigs"
    />
  </v-row>
</template>
<script>
import ServiceSequenceLayoutColumn from '@/components/ServiceSequence/layouts/ServiceSequenceLayoutColumn'

export default {
  name: 'ServiceSequenceLayoutRow',
  components: {
    ServiceSequenceLayoutColumn,
  },
  props: {
    config: Object,
    componentConfigs: Array,
  },
}
</script>
