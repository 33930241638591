<template>
  <v-main>
    <div class="pa-6">
      <h2>{{ $t('global.companyInvite.inviteCompany') }}</h2>
      <p>{{ $t('global.companyInvite.description') }}</p>
      <v-form class="mb-10">
        <v-text-field
          v-model="email"
          filled
          :error-messages="emailErrors"
          :label="$t('global.email')"
          required
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
          style="width: 330px"
          type="email"
        ></v-text-field>
        <v-select
          :items="localeOptions"
          v-model="lang"
          :label="$t('global.invitationLanguage')"
          filled
          style="width: 330px"
        ></v-select>
        <v-btn @click="inviteCompany" color="primary" class="mt-2" depressed>{{
          $t('global.companyInvite.submit')
        }}</v-btn>
      </v-form>
      <h2 class="mb-2">{{ $t('global.companyInvite.sentInvitations') }}</h2>
      <DataTableCustom :headers="headers" :items="createCompanyInvitations" class="elevation-2">
        <template v-slot:item.state="{ item }">
          <span class="status-badge" :class="`${item.state}-badge`">{{
            $t(`global.invitationState.${item.state}`)
          }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip
            top
            v-if="item.state !== 'accepted'"
            :disabled="!Boolean(cooldownMap[item.id])"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="d-inline-flex">
                <v-btn
                  height="27"
                  depressed
                  @click="inviteCompanyOrResendInvitation(item.email, item.language, false)"
                  :disabled="Boolean(cooldownMap[item.id])"
                >
                  {{ $t('global.companyInvite.resendInvitation') }}
                </v-btn>
              </div>
            </template>
            <span>{{
              $t('global.resendCooldownTooltip', { remainingTime: cooldownMap[item.id] })
            }}</span>
          </v-tooltip>
          <v-btn
            v-if="item.state !== 'accepted'"
            icon
            color="error"
            class="ml-3"
            @click="openDeleteInvitationConfirmationDialog(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </DataTableCustom>
    </div>

    <ConfirmationDialog
      v-if="invitationToDelete"
      v-model="confirmDeleteModalOpen"
      :heading="$t('global.createCompanyInvitationDeleteConfirmation.heading')"
      :text="
        $t('global.createCompanyInvitationDeleteConfirmation.text', {
          email: invitationToDelete.email,
        })
      "
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
      :action="deleteInvitation"
    />
  </v-main>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import produce from 'immer'
import { createCompanyInvitationCreate } from '@/graphql/mutations/createCompanyInvitationCreate'
import { createCompanyInvitationDelete } from '@/graphql/mutations/createCompanyInvitationDelete'
import { createCompanyInvitations } from '@/graphql/query/createCompanyInvitations'
import { allowedLanguages } from '@/translations'
import ConfirmationDialog from '@/components/ConfirmationDialog'

export default {
  name: 'CompanyInvitePage',
  components: { ConfirmationDialog },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  data() {
    return {
      email: '',
      lang: this.$i18n.locale,
      invitationToDelete: null,
      confirmDeleteModalOpen: false,
      resendCooldown: 60, // seconds
      cooldownMap: {},
      countdownInterval: null,
    }
  },
  apollo: {
    createCompanyInvitations: {
      query: createCompanyInvitations,
      variables() {
        return {
          companyId: this.currentCompanyId,
        }
      },
      update(data) {
        return data.company.createCompanyInvitations.map((invitation) => ({
          ...invitation,
          state: this.getInvitationState(invitation),
        }))
      },
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    headers() {
      return [
        { text: this.$t('global.email'), value: 'email' },
        { text: this.$t('global.companyName'), value: 'createdCompany.name' },
        { text: this.$t('global.state'), value: 'state' },
        { text: '', value: 'actions', align: 'right' },
      ]
    },
    localeOptions() {
      return allowedLanguages.map((locale) => ({
        text: this.$t(`global.lang.${locale}`),
        value: locale,
      }))
    },
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) {
        return errors
      }
      !this.$v.email.email && errors.push(this.$t('global.formValidation.notEmail'))
      !this.$v.email.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
  },
  methods: {
    async inviteCompanyOrResendInvitation(email, lang, resetEmail) {
      try {
        await this.$apollo.mutate({
          mutation: createCompanyInvitationCreate,
          variables: {
            email: email,
            companyId: this.currentCompanyId,
            lang,
          },
          // update cache
          update: (store, response) => {
            const query = {
              query: createCompanyInvitations,
              variables: {
                companyId: this.currentCompanyId,
              },
            }
            const data = store.readQuery(query)
            const newCreateCompanyInvitation =
              response.data.createCompanyInvitationCreate.createCompanyInvitation
            const newData = produce(data, (draft) => {
              const originalInvitationIndex = draft.company.createCompanyInvitations.findIndex(
                (invitation) => invitation.id === newCreateCompanyInvitation.id,
              )
              if (originalInvitationIndex === -1) {
                // new invitation
                draft.company.createCompanyInvitations.push(newCreateCompanyInvitation)
              } else {
                // resend invitation
                draft.company.createCompanyInvitations.splice(
                  originalInvitationIndex,
                  1,
                  newCreateCompanyInvitation,
                )
              }
            })
            store.writeQuery({
              ...query,
              data: newData,
            })

            this.$set(this.cooldownMap, newCreateCompanyInvitation.id, this.resendCooldown)
          },
        })

        if (resetEmail) {
          this.email = ''
          this.$v.$reset()
        }
        this.$toast.success(this.$t('global.companyInvite.invitationSuccess'))
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.companyInvite.invitationError'))
      }
    },
    async inviteCompany() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        await this.inviteCompanyOrResendInvitation(this.email, this.lang, true)
      }
    },
    async deleteInvitation() {
      try {
        await this.$apollo.mutate({
          mutation: createCompanyInvitationDelete,
          variables: {
            invitationId: this.invitationToDelete.id,
          },
          // update cache
          update: (store, response) => {
            const query = {
              query: createCompanyInvitations,
              variables: {
                companyId: this.currentCompanyId,
              },
            }
            const data = store.readQuery(query)
            const newData = produce(data, (draft) => {
              const deletedIndex = draft.company.createCompanyInvitations.findIndex(
                (invitation) =>
                  invitation.id === response.data.createCompanyInvitationDelete.deletedInvitationId,
              )
              draft.company.createCompanyInvitations.splice(deletedIndex, 1)
            })
            store.writeQuery({
              ...query,
              data: newData,
            })
          },
        })

        this.$toast.success(this.$t('global.createCompanyInvitationDelete.success'))
        this.confirmDeleteModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.createCompanyInvitationDelete.failure'))
      }
    },
    getInvitationState(invitation) {
      if (invitation.createdCompany) {
        return 'accepted'
      }
      if (invitation.expired) {
        return 'expired'
      }
      return 'pending'
    },
    openDeleteInvitationConfirmationDialog(invitation) {
      this.invitationToDelete = invitation
      this.confirmDeleteModalOpen = true
    },
  },
  watch: {
    cooldownMap() {
      if (Object.keys(this.cooldownMap).length) {
        if (!this.countdownInterval) {
          this.countdownInterval = setInterval(() => {
            for (const key in this.cooldownMap) {
              if (this.cooldownMap[key] > 0) {
                this.cooldownMap[key]--
              } else {
                delete this.cooldownMap[key]
                this.cooldownMap = { ...this.cooldownMap } // trigger change detection
              }
            }
          }, 1000)
        }
      } else {
        clearInterval(this.countdownInterval)
        this.countdownInterval = null
      }
    },
  },
}
</script>
<style lang="less" scoped>
.status-badge {
  font-weight: 500;
  font-size: 11px;
  padding: 8px 11px;
  border-radius: 1px;
  line-height: 11px;

  &.expired-badge {
    background-color: #fc5252;
    color: white;
  }
  &.pending-badge {
    background-color: #fc7610;
    color: white;
  }
  &.accepted-badge {
    background-color: fade(#22b77c, 10%);
    color: #22b77c;
  }
}
</style>
