import gql from 'graphql-tag'

export const eventCoreCausesAdmin = gql`
  query eventCoreCausesAdmin($lang: String) {
    eventCoreCausesAdmin {
      id
      name
      description(lang: $lang)
    }
  }
`
