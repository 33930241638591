

export default {
    controllerId: 'ID urządzenia (XXX-XXX-XXX)',
    controller: 'Urządzenie',
    status: {
        ONLINE: 'Online',
        OFFLINE: 'Offline'
    },
    operatingMode: {
        IN_CONSTRUCTION: 'W budowie',
        NORMAL: 'Normalne działanie',
        MAINTENANCE: 'Konserwacja',
        WINTERIZED: 'W pełni zimowany'
    },
    lastSynchronization: 'Ostatnio online',
    propagate: 'Wykonaj ponowne uruchomienie.',
    saveAndPropagate: 'Zapisz i wyślij',
    saveAsDeviceTemplate: 'Zachowaj jako szablon',
    saveAsDeviceTemplateDescription: 'Wpisz nazwę szablonu.',
    validations: {
        is_empty: 'Proszę wypełnić to pole.',
        device_already_registered: 'Ten identyfikator urządzenia już istnieje w bazie danych.',
        invalid_serial: 'Ten identyfikator urządzenia nie istnieje.'
    },
    searchDevice: 'Wyszukaj urządzenie',
    addDevice: 'Dodaj urządzenie',
    addDeviceInProgress: 'W toku',
    target: 'Cel',
    deviceNotFound: 'Nie znaleziono urządzenia.',
    unavailable: 'Niedostępny',
    forbiddenByUser: 'Zablokowane przez użytkownika',
    enterExpertMode: 'Wejdź w tryb eksperta',
    forbidden: 'Niedozwolone',
    dosing: 'Dozowanie',
    notDosing: 'Gotowe',
    addressNotFound: 'Adres nie został znaleziony.',
    diagnosticCharts: 'Wykresy diagnostyczne',
    chartsUnderConstruction: 'Sekcja jest w przygotowaniu. Kontynuuj.',
    chartsUnavailable: 'Wykresy diagnostyczne nie są dostępne dla tego typu urządzenia.',
    controlUpdateFail: 'Nie można ustawić wartości.',
    sendSettingsFailed: 'Nie udało się wysłać ustawień na urządzenie.',
    saveDeviceTemplateSuccess: 'Szablon został zapisany.',
    saveDeviceTemplateError: 'Nie udało się zapisać szablonu.',
    confirmOpenExpertModeModal: {
        headline: 'Czy na pewno chcesz przełączyć się do trybu eksperta?',
        text: 'Niewłaściwe użycie trybu eksperckiego może spowodować uszkodzenie urządzenia.'
    },
    confirmCloseExpertModeModal: {
        headline: 'Czy na pewno chcesz opuścić tryb eksperta?',
        text: 'Powrót systemu do trybu normalnego.'
    },
    expertModeModal: {
        headline: 'Tryb eksperta został aktywowany.',
        exit: 'Wyjdź z trybu eksperta',
        openFailed: 'Nie udało się włączyć trybu eksperta.',
        closeFailed: 'Nie udało się wyłączyć trybu eksperta.'
    },
    deviceWentOfflineModal: {
        headline: 'Urządzenie przeszło w tryb offline.',
        text: 'Poczekaj, aż urządzenie ponownie się połączy lub wyjdź z trybu eksperckiego.',
        buttonText: 'Wyjdź z trybu eksperta'
    },
    deviceList: 'Lista urządzeń',
    listView: 'Widok listy',
    mapView: 'Widok Mapy',
    deviceListHeaders: {
        name: 'Nazwa',
        company: 'Firma',
        connectionType: 'Rodzaj połączenia',
        connection: 'Status połączenia',
        currentOperation: 'Bieżąca operacja',
        operatingMode: 'Tryb pracy',
        reports: 'Powiadomienia',
        userNote: 'Wiadomość użytkownika',
        inOperationSince: 'Działa od'
    },
    noDevices: 'Brak dostępnych urządzeń',
    noRecords: 'Brak rekordów',
    timeSchedule: {
        changeSchedule: 'Wybierz inny tygodniowy harmonogram',
        selectSchedule: 'Wybierz harmonogram tygodniowy',
        showWeeklyTimeSchedules: 'Pokaż harmonogramy tygodniowe',
        showDailyTimeSchedules: 'Pokaż codzienne harmonogramy',
        recommended: 'Zalecany czas działania',
        activeTimeSchedule: 'Aktywny harmonogram',
        noActiveTimeSchedule: 'Brak aktywnego harmonogramu.',
        activeTimeScheduleNoMatch: 'Brak aktywnego harmonogramu czasu. Aktywuj jeden z harmonogramów.',
        pleaseActivate: 'Aktywuj jeden ze swoich harmonogramów.',
        setActiveTimeScheduleFailed: 'Nie udało się aktywować harmonogramu.',
        createTimeScheduleFailed: 'Nie udało się utworzyć harmonogramu.',
        updateTimeScheduleFailed: 'Nie udało się zaktualizować harmonogramu.',
        deleteTimeScheduleFailed: 'Nie udało się usunąć harmonogramu.',
        setActiveTimeScheduleSuccess: 'Harmonogram został aktywowany.',
        createTimeScheduleSuccess: 'Harmonogram został utworzony.',
        updateTimeScheduleSuccess: 'Harmonogram został pomyślnie zaktualizowany.',
        deleteTimeScheduleSuccess: 'Harmonogram został usunięty.',
        editModal: {
            cancelConfirmation: {
                heading: 'Wyjdź z edycji',
                text: 'Czy na pewno chcesz wyjść? Wszystkie zmiany zostaną utracone.'
            }
        },
        confirmActivateModal: {
            heading: 'Aktywuj harmonogram',
            text: 'Czy na pewno chcesz aktywować ten harmonogram czasu?'
        }
    },
    gallery: {
        noPhotodocumentation: 'Brak dostępnych zdjęć.',
        addPhotos: 'Dodaj zdjęcie',
        takePhoto: 'Zrób zdjęcie',
        imageUploadSuccess: 'Obrazy przesłane pomyślnie.',
        imageUploadFailed: 'Nie udało się przesłać obrazów.',
        imageUploadPartiallyFailed: 'Nie udało się załadować zdjęć {failedCount} z {totalCount}.',
        imageUploadUnsupportedFormat: 'Nieobsługiwany format pliku. Użyj jpg, png, heif lub heic.',
        deleteConfirmationHeading: 'Usuń zdjęcie',
        deleteConfirmationText: 'Czy na pewno chcesz usunąć to zdjęcie?',
        deleteImageSuccess: 'Obraz pomyślnie usunięty.',
        deleteImageFailed: 'Nie udało się usunąć obrazu.',
        imageNameEditHeading: 'Nazwa zdjęcia',
        imageNameEditText: 'Wpisz nową nazwę obrazu.',
        imageNameEditLabel: 'Nazwa zdjęcia',
        renameImageSuccess: 'Nazwa obrazu została zaktualizowana.',
        renameImageFailed: 'Nie udało się zaktualizować nazwy obrazu.'
    },
    deviceDeleteSuccess: 'Urządzenie zostało pomyślnie usunięte.',
    deviceDeleteFailure: 'Nie udało się usunąć urządzenia.',
    deviceDeleteConfirmation: {
        heading: 'Usuń urządzenie',
        text: 'Czy na pewno chcesz usunąć to urządzenie ({controller})?'
    },
    updateDevice: 'Zmień ustawienia urządzenia',
    deviceUpdateSuccess: 'Właściwości urządzenia zostały zmienione.',
    deviceUpdateFailure: 'Nie udało się zmienić właściwości urządzenia.',
    deviceUpdateControllerTaken: 'Ten identyfikator jest już zajęty przez inne urządzenie.',
    serviceSequenceInProgress: 'Sekwencja serwisowa jest w toku.',
    nextRun: 'Kolejne uruchomienie',
    launch: 'Uruchom',
    insufficientPermissionsToViewServiceSequence: 'Niewystarczające uprawnienia do wyświetlania sekwencji serwisowej.'
};