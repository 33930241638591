<template>
  <div ref="wrapper" class="timeline-wrapper">
    <div v-for="(tick, index) of ticks" :key="index" class="tick">
      <span>{{ tick }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MinimapTimeline',
  data() {
    return {
      width: 0,
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.wrapper) {
        this.width = this.$refs.wrapper.clientWidth
      } else {
        this.width = 0
      }
    })
  },
  computed: {
    ticks() {
      let greatestDivisor
      for (let i = Math.floor(this.width / 60); i > 0; i--) {
        if (24 % i === 0) {
          greatestDivisor = i
          break
        }
      }

      const ticks = []
      const interval = 24 / greatestDivisor
      for (let i = 0; i < 24; i += interval) {
        ticks.push(`${i.toString().length === 1 ? `0${i}` : i}:00`)
      }
      ticks.push('00:00')
      return ticks
    },
  },
}
</script>
<style lang="less" scoped>
.timeline-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;

  .tick {
    color: #454d57;
    font-weight: 400;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 0px;

    &:first-child {
      align-items: flex-start;
    }

    &:last-child {
      align-items: flex-end;
    }
  }
}
</style>
