<template>
  <div
    class="installation-site-tab-wrapper"
    :class="{ full: permissionChecker(permissions.FULL_CUSTOMER_DATA, []) }"
  >
    <div
      v-if="permissionChecker(permissions.FULL_CUSTOMER_DATA, []) && device.contactInfo"
      class="contact-info"
    >
      <Value :label="$t('global.contactName')" :value="device.contactInfo.name" />
      <Value :label="$t('global.contactEmail')" :value="device.contactInfo.email" />
      <Value :label="$t('global.contactPhone')" :value="device.contactInfo.phone" />
      <Value :label="$t('global.note')" :value="device.contactInfo.note" />
    </div>

    <div class="map-wrapper">
      <span class="mb-2">{{ $t('global.map') }}</span>
      <div v-show="location" class="map" ref="map"></div>
      <div v-show="!location" class="address-not-found">
        <v-icon x-large>mdi-map-marker-remove-variant</v-icon>
        <span>{{ $t('device.addressNotFound') }}</span>
      </div>
    </div>
    <div v-if="permissionChecker(permissions.FULL_CUSTOMER_DATA, [])" class="address-info">
      <Value :label="$t('global.address')" :value="device.address.address" />
      <Value
        :label="$t('global.addressItems.country')"
        :value="$t(`global.countryCodes.${device.address.code}`)"
      />
      <Value :label="$t('global.addressItems.city')" :value="device.address.city" />
      <Value :label="$t('global.addressItems.postalCode')" :value="device.address.postalCode" />
      <Value
        :label="$t('global.location')"
        :value="
          device.address.lat != null && device.address.lng != null
            ? `${device.address.lat.toFixed(4)} ${device.address.lng.toFixed(4)}`
            : null
        "
      />
    </div>
    <div v-else class="address-info">
      <Value
        :label="$t('global.addressItems.code')"
        :value="$t(`global.countryCodes.${device.address.code}`)"
      />
      <Value :label="$t('global.addressItems.city')" :value="device.address.city" />
    </div>
  </div>
</template>
<script>
import Value from '@/components/UI/Value'
import { permissions } from '@/config/permissions'

export default {
  name: 'InstallationSiteTab',
  components: { Value },
  props: {
    tabConfig: Object,
    device: Object,
    permissionChecker: Function,
  },
  data() {
    return {
      geocoder: null,
      marker: null,
      circle: null,
      map: null,
    }
  },
  watch: {
    location: {
      immediate: true,
      handler() {
        if (this.location) {
          this.$nextTick(() => {
            if (!this.map) {
              this.map = new window.google.maps.Map(this.$refs.map, {
                zoom: 10,
                center: this.location,
                gestureHandling: 'cooperative',
              })
            } else {
              this.map.setCenter(this.location)
            }

            if (!this.marker) {
              this.marker = new window.google.maps.Marker({
                position: this.location,
                map: this.map,
              })
            } else {
              this.marker.setPosition(this.location)
            }

            if (
              !this.permissionChecker(permissions.FULL_CUSTOMER_DATA, []) &&
              this.permissionChecker(permissions.RESTRICTED_CUSTOMER_DATA, [])
            ) {
              if (!this.circle) {
                this.circle = new window.google.maps.Circle({
                  map: this.map,
                  radius: 450,
                  fillColor: '#fff',
                  fillOpacity: 0.6,
                  strokeColor: '#313131',
                  strokeOpacity: 0.4,
                  strokeWeight: 0.8,
                })
                this.circle.bindTo('center', this.marker, 'position')
              } else if (this.circle.getMap() == null) {
                this.circle.setMap(this.map)
              }
            } else if (this.circle) {
              this.circle.setMap(null)
            }

            this.map.getStreetView().setVisible(false)
          })
        }
      },
    },
  },
  computed: {
    location() {
      if (this.device.address.lat && this.device.address.lng) {
        return {
          lat: this.device.address.lat,
          lng: this.device.address.lng,
        }
      } else if (this.device.address.approximateLat && this.device.address.approximateLng) {
        return {
          lat: this.device.address.approximateLat,
          lng: this.device.address.approximateLng,
        }
      }
      return null
    },
    permissions() {
      return permissions
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.installation-site-tab-wrapper {
  display: grid;
  gap: 5px;
  grid-template-columns: auto fit-content(250px);

  &.full {
    grid-template-columns: fit-content(250px) auto fit-content(250px);
  }

  .contact-info,
  .address-info {
    display: grid;
    grid-auto-rows: min-content;
    gap: 5px;
    max-width: 300px;
  }

  .map-wrapper {
    display: flex;
    flex-direction: column;

    padding: 16px;
    background-color: @color-tile-background;

    & > span {
      display: block;
      opacity: 0.5;
      font-size: 12px;
    }

    .map {
      min-height: 300px;
      flex-grow: 1;
    }

    .address-not-found {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 1250px) {
  .installation-site-tab-wrapper {
    grid-template-columns: unset !important;
    grid-auto-rows: min-content;
    gap: 15px;

    .contact-info,
    .address-info {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-auto-rows: unset;
      max-width: unset;
    }

    .map-wrapper {
      grid-row-start: 3;
    }
  }
}
</style>
