<template>
  <div
    class="wrapper"
    :class="{
      unavailable: isUnavailable,
    }"
  >
    <div class="label-edit-container">
      <p class="name" :class="{ 'mt-2': !(control.settable && canEdit && !isUnavailable) }">
        {{ control.label }}
      </p>
      <v-dialog
        v-if="!isUnavailable && control.settable && canEdit"
        v-model="isEditDialogOpen"
        width="unset"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="edit-btn" v-bind="attrs" v-on="on">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="headline">{{ control.label }}</v-card-title>
          <v-card-text>
            <NumberControlInput
              ref="input"
              :control="control"
              :label="$t('device.target')"
              v-model="inputValue"
              :isValid="isValid"
              @isValidChange="isValid = $event"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed @click="isEditDialogOpen = false">
              {{ $t('global.cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              :disabled="isSetButtonDisabled"
              depressed
              @click="setValue(), (isEditDialogOpen = false)"
            >
              {{ $t('global.set') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div style="overflow: hidden">
      <div class="value-row d-flex align-center">
        <p class="value">{{ isOffline && isInvalid ? '---' : value }}</p>
        <p v-if="control.showDesired && !(isOffline && isInvalid)" class="desired-value">
          {{ $t('device.target') }} {{ desiredValue }}
        </p>
      </div>
      <div v-if="control.type === 'STATUS_INDICATOR_WITH_NUMBER'" class="d-flex align-center">
        <v-icon v-if="control.statusIcon" size="20" class="status-icon mr-1">{{
          `$${control.statusIcon}`
        }}</v-icon>
        <p class="status-text">{{ control.statusLabel }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import NumberControlInput from '@/components/DeviceControlPanel/Control/NumberControlInput'
import valueFormatter from '@/mixins/valueFormatter'
import addSeconds from 'date-fns/addSeconds'
import { deviceControl } from '@/graphql/mutations/deviceControl'

export default {
  name: 'NumberControl',
  components: { NumberControlInput },
  mixins: [valueFormatter],
  props: {
    control: Object,
    deviceId: String,
    isOffline: Boolean,
    connectionStatusChangedTime: String,
    canEdit: Boolean,
  },
  data() {
    return {
      isEditDialogOpen: false,
      inputValue: null,
      isValid: true,
    }
  },
  computed: {
    value() {
      return this.formatPrintValue(
        this.control.value,
        this.control.options.unit,
        this.control.options,
      )
    },
    desiredValue() {
      return this.formatPrintValue(
        this.control.desiredValue,
        this.control.options.unit,
        this.control.options,
      )
    },
    isInvalid() {
      if (this.connectionStatusChangedTime == null) {
        return true
      }
      if (this.control.validFor == null) {
        return false
      }
      return (
        new Date() > addSeconds(new Date(this.connectionStatusChangedTime), this.control.validFor)
      )
    },
    isUnavailable() {
      return this.isOffline || this.control.forbiddenByTechnology
    },
    needsSave() {
      return parseFloat(this.inputValue) !== parseFloat(this.control.desiredValue)
    },
    isSetButtonDisabled() {
      return !this.needsSave || !this.isValid
    },
  },
  methods: {
    async setValue() {
      try {
        await this.$apollo.mutate({
          mutation: deviceControl,
          variables: {
            data: {
              deviceId: this.deviceId,
              name: this.control.name,
              value: this.inputValue.toString(),
            },
          },
        })

        this.$emit('controlChanged')
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('device.controlUpdateFail'))
      }
    },
  },
  watch: {
    isEditDialogOpen() {
      if (this.isEditDialogOpen) {
        this.$nextTick(() => {
          this.$refs.input.enableInputMode()
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.wrapper {
  position: relative;
  background-color: fade(@color-brand-highlight, 50%);
  border-radius: 3px;
  padding: 5px 5px 13px 18px;

  p {
    margin-bottom: 0;
  }
  .label-edit-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .name {
    font-size: 12px;
    color: @color-brand-2;
    opacity: 0.5;
  }

  .value {
    font-size: 30px;
    font-weight: bold;
    color: @color-brand-2;
  }

  .desired-value {
    font-size: 12px;
    font-weight: bold;
    color: @color-brand;
    margin-right: 8px;
  }

  .edit-btn {
    i {
      color: @color-brand !important;
    }
  }

  &.unavailable {
    background-color: fade(#ebebeb, 50%);

    .name,
    .value {
      color: gray;
    }

    .desired-value {
      color: #adadad;
    }
  }
  .value-row {
    justify-content: space-between;
  }
  .status-icon {
    color: @color-brand-2;
  }
  .status-text {
    font-size: 12px;
    color: @color-brand-2;
  }
}
</style>
