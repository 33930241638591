import gql from 'graphql-tag'

export const serviceCompanyInvitation = gql`
  query serviceCompanyInvitation($deviceId: ID!) {
    device(deviceId: $deviceId) {
      id
      serviceCompanyInvitation {
        id
        email
        company {
          id
          name
        }
        createdAt
        expirationPeriod
      }
    }
  }
`

export const serviceCompanyInvitationByToken = gql`
  query serviceCompanyInvitation($token: NonEmptyString!) {
    serviceCompanyInvitation(token: $token) {
      id
      email
      company {
        id
        name
      }
      emailFrom
      createdAt
      expirationPeriod
      deviceThingType
      deviceName
      deviceController
    }
  }
`
