<template>
  <div class="name-item">
    <router-link
      v-if="withLink"
      :to="{ name: 'DevicePage', params: { deviceId: device.id } }"
      class="name"
    >
      {{ getDeviceName(device, currentCompanyId) }}
    </router-link>
    <span v-else class="name">{{ getDeviceName(device, currentCompanyId) }}</span>
    <div class="controller" @click.stop="copyController(device.controller)">
      <span>{{ device.controller }}</span>
      <v-icon x-small class="ml-1">mdi-content-copy</v-icon>
    </div>
  </div>
</template>
<script>
import { getDeviceName } from '@/utils/getDeviceName'
import { mapGetters } from 'vuex'

export default {
  name: 'DeviceNameTableItem',
  props: {
    device: Object,
    withLink: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    copyController(controller) {
      navigator.clipboard.writeText(controller).then(
        () => {
          this.$toast.success(`"${controller}" ${this.$t('global.copiedToClipboard')}`)
        },
        (err) => {
          console.error(err)
          this.$toast.error(this.$t('global.copyToClipboardFailed'))
        },
      )
    },
    getDeviceName,
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
  },
}
</script>
<style lang="less" scoped>
.name-item {
  .name {
    display: block;
    font-size: 14px;
  }

  .controller {
    font-size: 10px;
    opacity: 0.25;
    transition: 0.1s;
    width: fit-content;
    cursor: copy;

    i {
      opacity: 0;
    }

    &:hover {
      opacity: 0.5;

      i {
        opacity: 0.5;
      }
    }
  }
}
</style>
