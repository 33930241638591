<template>
  <div class="pa-6">
    <h1>{{ $t('global.companyManagementMenu.notifications') }}</h1>
    <v-tabs show-arrows class="mt-10">
      <v-tab :to="{ name: 'CompanyContactEmailsPage' }">
        {{ $t('global.companyDetail.targetAddresses') }}
      </v-tab>
      <v-tab
        v-if="
          currentCompanyGeneralEligibilities[eligibilities.ORDER_SERVICE_FROM_APP] ||
          currentCompanyGeneralEligibilities[eligibilities.ORDER_SERVICE_FROM_APP_FROM_EVENT]
        "
        :to="{ name: 'CompanyContactEmailTemplatePage' }"
      >
        {{ $t('global.contactEmailTemplate') }}
      </v-tab>
      <v-tab
        v-if="currentCompanyGeneralEligibilities[eligibilities.NOTIFICATION_DISPATCHER]"
        :to="{ name: 'CompanyNotificationFlow' }"
      >
        {{ $t('global.notificationDispatcher.notificationFlow') }}
      </v-tab>
      <v-tab
        v-if="currentCompanyGeneralEligibilities[eligibilities.NOTIFICATION_DISPATCHER]"
        :to="{ name: 'CompanyNotificationDelays' }"
      >
        {{ $t('global.notificationDispatcher.delayConfiguration') }}
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { eligibilities } from '@/config/eligibilities'

export default {
  name: 'CompanyNotificationsPage',
  computed: {
    ...mapGetters('user', ['currentCompanyGeneralEligibilities']),
    eligibilities() {
      return eligibilities
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

h1 {
  font-size: 24px;
}

/deep/.v-slide-group {
  background-color: tint(@color-brand-highlight, 50%) !important;
  margin-bottom: 30px;

  .v-tab {
    color: @color-brand-2 !important;
    opacity: 0.5;

    &--active {
      opacity: 1;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .v-tabs-slider-wrapper {
    color: @color-brand-2 !important;
  }
}
</style>
