import gql from 'graphql-tag'

export const notificationClassDefaultDelays = gql`
  query notificationClassDefaultDelays {
    notificationClassDefaultDelays {
      id
      notificationClass
      delays
    }
  }
`
