import login from './login'
import device from './device'
import global from './global'
import users from './users'

export default {
  login,
  device,
  global,
  users,
}
