import gql from 'graphql-tag'
import { ChildCompaniesPermissions } from '@/graphql/fragments/childCompaniesPermissions'

export const serviceCompanyDetail = gql`
  query serviceCompanyDetail($companyId: ID!, $parentCompanyId: ID!) {
    company(companyId: $companyId) {
      id
      name
      contactEmail(parentCompanyId: $parentCompanyId)
      enabled: enabledInParentCompany(parentCompanyId: $parentCompanyId)
      permissions: permissionsInParentCompany(parentCompanyId: $parentCompanyId) {
        ...ChildCompaniesPermissions
      }
    }
  }
  ${ChildCompaniesPermissions}
`
