export const deviceTypes = {
  pool: {
    label: 'global.menu.pools',
    icon: '$pool',
  },
  sewage_pump: {
    label: 'global.menu.sewagePumps',
    icon: '$sewage-pump',
  },
  charge_point: {
    label: 'global.menu.chargePoint',
    icon: '$charge-point',
  },
}
