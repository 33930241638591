let localStorageSupported = true
try {
  localStorage.setItem('test', 'test')
  localStorage.removeItem('test')
} catch (e) {
  localStorageSupported = false
}

export function saveItem(key, data) {
  if (localStorageSupported) {
    localStorage.setItem(key, data)
  }
}

export function getItem(key) {
  if (localStorageSupported) {
    return localStorage.getItem(key)
  }
  return null
}

export function removeItem(key) {
  if (localStorageSupported) {
    return localStorage.removeItem(key)
  }
}
