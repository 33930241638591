import SelectInput from '@/components/Form/Input/SelectInput'
import ClassicInput from '@/components/Form/Input/ClassicInput'
import NumberInput from '@/components/Form/Input/NumberInput'
import CheckboxInput from '@/components/Form/Input/CheckboxInput'
import TimePickerInput from '@/components/Form/Input/TimePickerInput'

export default {
  components: { SelectInput },
  methods: {
    componentFormItemType(type, unit = null) {
      switch (type) {
        case 'SelectType':
          return SelectInput
        case 'NumberType':
          return unit === 'SECOND' ? TimePickerInput : NumberInput
        case 'CheckboxType':
          return CheckboxInput
        default:
          return ClassicInput
      }
    },
  },
}
