import gql from 'graphql-tag'

export const createCompanyInvitationCreate = gql`
  mutation createCompanyInvitationCreate($email: EmailAddress!, $companyId: ID!, $lang: String) {
    createCompanyInvitationCreate(input: { email: $email, companyId: $companyId, lang: $lang }) {
      createCompanyInvitation {
        id
        email
        expired
        createdCompany {
          id
          name
        }
      }
    }
  }
`
