<template>
  <v-dialog :value="isOpen" @input="$emit('modalToggle', $event)" max-width="700" max-height>
    <div class="modal-wrapper">
      <h1 class="mb-4">{{ $t('global.changeServiceCompanyModal.title') }}</h1>
      <div v-if="activeInvitation">
        <v-alert outlined type="info">
          <p>{{ $t('global.changeServiceCompanyModal.activeInvitationDescription') }}</p>
          <p class="mb-0">{{ $t('global.changeServiceCompanyModal.activeInvitation') }}</p>
          <ul class="mb-6">
            <li>
              {{ $t('global.email') }}: <b>{{ activeInvitation.email }}</b>
            </li>
            <li>
              {{ $t('global.companyName') }}: <b>{{ activeInvitation.company.name }}</b>
            </li>
          </ul>
          <p>
            {{ $t('global.changeServiceCompanyModal.invitationWillExpire') }}
            {{ invitationExpiresIn }}.
          </p>
        </v-alert>
        <div class="d-flex justify-end">
          <v-btn depressed class="mr-2" @click="resendInvitation">{{
            $t('global.changeServiceCompanyModal.resendInvitation')
          }}</v-btn>
          <v-btn color="error" depressed @click="deleteInvitation">{{
            $t('global.changeServiceCompanyModal.deleteInvitation')
          }}</v-btn>
        </div>
      </div>
      <div v-else>
        <p>{{ $t('global.changeServiceCompanyModal.description') }}</p>
        <v-form @submit.prevent="inviteServiceCompany">
          <v-text-field
            v-model="invitationEmail"
            filled
            :error-messages="invitationEmailErrors"
            :label="$t('global.email')"
            required
            @input="$v.invitationEmail.$touch()"
            @blur="$v.invitationEmail.$touch()"
            style="width: 330px"
            type="email"
          ></v-text-field>
          <div class="text-right">
            <v-btn type="submit" color="primary" class="mt-2" depressed>{{
              $t('global.changeServiceCompanyModal.submit')
            }}</v-btn>
          </div>
        </v-form>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { serviceCompanyInvitation } from '@/graphql/query/serviceCompanyInvitation'
import { deviceInviteServiceCompany } from '@/graphql/mutations/deviceInviteServiceCompany'
import { serviceCompanyInvitationDelete } from '@/graphql/mutations/serviceCompanyInvitationDelete'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import addDays from 'date-fns/addDays'
import { dateLocales } from '@/config/dateFnsLocales'

export default {
  name: 'ChangeServiceCompanyModal',
  mixins: [validationMixin],
  model: {
    prop: 'isOpen',
    event: 'modalToggle',
  },
  props: {
    isOpen: Boolean,
    deviceId: String,
  },
  data() {
    return {
      invitationEmail: null,
    }
  },
  validations: {
    invitationEmail: { required, email },
  },
  apollo: {
    activeInvitation: {
      query: serviceCompanyInvitation,
      variables() {
        return {
          deviceId: this.deviceId,
        }
      },
      update(data) {
        return data.device.serviceCompanyInvitation
      },
    },
  },
  methods: {
    async sendInvitation(email, successMessage) {
      try {
        await this.$apollo.mutate({
          mutation: deviceInviteServiceCompany,
          variables: {
            email: email,
            deviceId: this.deviceId,
          },
          refetchQueries: ['serviceCompanyInvitation'],
        })

        this.$toast.success(successMessage)
        this.$emit('modalToggle', false)
      } catch (error) {
        console.error(error)
        const parsedError = error.message?.replace('GraphQL error:', '').trim()
        const errorMessages = {
          DEVICE_INVITE_SERVICE_COMPANY_NOT_ADMIN_EMAIL: this.$t(
            'global.changeServiceCompanyModal.inviteServiceCompanyAction.notAdminEmail',
          ),
          DEVICE_INVITE_SERVICE_COMPANY_ALREADY_SERVICE_COMPANY: this.$t(
            'global.changeServiceCompanyModal.inviteServiceCompanyAction.alreadyServiceCompany',
          ),
        }
        this.$toast.error(
          errorMessages[parsedError] ||
            this.$t('global.changeServiceCompanyModal.inviteServiceCompanyAction.failure'),
        )
      }
    },
    async inviteServiceCompany() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        await this.sendInvitation(
          this.invitationEmail,
          this.$t('global.changeServiceCompanyModal.inviteServiceCompanyAction.success'),
        )
      }
    },
    async resendInvitation() {
      await this.sendInvitation(
        this.activeInvitation.email,
        this.$t('global.changeServiceCompanyModal.resendInvitationSuccess'),
      )
    },
    async deleteInvitation() {
      try {
        await this.$apollo.mutate({
          mutation: serviceCompanyInvitationDelete,
          variables: {
            invitationId: this.activeInvitation.id,
          },
          refetchQueries: ['serviceCompanyInvitation'],
        })

        this.$toast.success(
          this.$t('global.changeServiceCompanyModal.deleteServiceCompanyInvitationAction.success'),
        )
      } catch (error) {
        console.error(error)
        this.$toast.error(
          this.$t('global.changeServiceCompanyModal.deleteServiceCompanyInvitationAction.failure'),
        )
      }
    },
  },
  computed: {
    invitationExpiresIn() {
      return this.activeInvitation
        ? formatDistanceToNow(
            addDays(
              new Date(this.activeInvitation.createdAt),
              this.activeInvitation.expirationPeriod,
            ),
            {
              addSuffix: true,
              locale: dateLocales[this.$i18n.locale],
            },
          )
        : null
    },
    invitationEmailErrors() {
      const errors = []
      if (!this.$v.invitationEmail.$dirty) {
        return errors
      }
      !this.$v.invitationEmail.required && errors.push(this.$t('global.formValidation.required'))
      !this.$v.invitationEmail.email && errors.push(this.$t('global.formValidation.notEmail'))
      return errors
    },
  },
}
</script>
<style lang="less" scoped>
.modal-wrapper {
  background-color: white;
  padding: 15px;

  h1 {
    font-size: 24px;
  }

  ul {
    color: black;
  }
}
</style>
