import gql from 'graphql-tag'

export const eventCodesAdmin = gql`
  query eventCodesAdmin($lang: String) {
    eventCodesAdmin {
      id
      name
      severity
      description(lang: $lang)
      disabled
      detectionPoint {
        id
        name
      }
      detectionMechanism
      terminationPoint
      terminationMechanism
      actionUponDetection
      defaultNotificationClass {
        id
        notificationClass
      }
      coreCauses {
        id
        name
      }
    }
  }
`
