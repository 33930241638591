export default {
  pageTitle: 'Vítejte v iXsolve',
  login: 'Přihlášení',
  register: 'Registrace',
  logIn: 'Přihlásit',
  password: 'Heslo',
  passwordAgain: 'Zopakovat heslo',
  forgotPassword: 'Zapomenuté heslo?',
  registerNewAccount: 'Registrovat nový účet',
  signIn: 'Vytvořit účet',
  haveAccount: 'Mám účet',
  error: { wrongUsernameOrPassword: 'Nesprávný email nebo heslo.' },
  backToLogin: 'Zpět na přihlášení',
  resetPassword: 'Obnovit heslo',
  forgotPasswordTitle: 'Obnovit heslo',
  confirmAccountTitle: 'Prosíme potvrďte svůj účet.',
  confirmationCode: 'Potvrzovací kód',
  sendConfirmation: 'Potvrdit účet',
  requestNewCode: 'Poslat nový kód',
  confirmAccountInEmail:
    'Vytvoření účtu proběhlo v pořádku, prosím zkontrolujte email a potvrďte svůj účet.',
  changePassword: 'Změnit heslo',
  resetPasswordTitle: 'Obnovit heslo',
  resetPasswordText:
    'Na váš e-mail byl zaslán ověřovací kód. Zkopírujte prosím ověřovací kód do níže uvedeného pole a nastavte nové heslo.',
  resetPasswordCode: 'Ověřovací kód z e-mailu',
  resetPasswordSuccess: 'Heslo úspěšně aktualizováno.',
  resetPasswordInvalidCode: 'Neplatný ověřovací kód.',
}
