<template>
  <div v-bind="wrapperProps">
    <p
      v-bind="textProps"
      v-sanitized-html="replaceServiceSequenceVariables(text, reactive.serviceSequenceLiveData)"
    ></p>
  </div>
</template>
<script>
import { replaceServiceSequenceVariables } from '@/utils/replaceServiceSequenceVariables'

export default {
  name: 'ServiceSequenceText',
  props: {
    text: String,
    textProps: Object,
    wrapperProps: Object,
  },
  inject: ['reactive'],
  methods: {
    replaceServiceSequenceVariables,
  },
}
</script>
