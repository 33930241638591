import gql from 'graphql-tag'

export const weeklyTimeScheduleCreate = gql`
  mutation weeklyTimeScheduleCreate($input: WeeklyTimeScheduleCreateInput!) {
    weeklyTimeScheduleCreate(input: $input) {
      weeklyTimeSchedule {
        id
        name
        weeklySchedule {
          days
          dailySchedule {
            id
          }
        }
        isGlobal
        isUsed
      }
    }
  }
`

export const weeklyTimeScheduleUpdate = gql`
  mutation weeklyTimeScheduleUpdate($input: WeeklyTimeScheduleUpdateInput!) {
    weeklyTimeScheduleUpdate(input: $input) {
      success
    }
  }
`

export const weeklyTimeScheduleDelete = gql`
  mutation weeklyTimeScheduleDelete($weeklyScheduleId: ID!) {
    weeklyTimeScheduleDelete(input: { weeklyScheduleId: $weeklyScheduleId }) {
      deletedId
    }
  }
`
