<template>
  <div v-bind="wrapperProps">
    <v-btn
      @click="confirmation ? (confirmationDialogOpen = true) : reactive.handleCommand(command)"
      :loading="reactive.loadingAction === command"
      v-bind="buttonProps"
      depressed
      >{{ replaceServiceSequenceVariables(buttonText, reactive.serviceSequenceLiveData) }}
    </v-btn>
    <ServiceSequenceConfirmationDialog
      v-if="confirmation"
      v-model="confirmationDialogOpen"
      :config="confirmation"
      :command="command"
    />
  </div>
</template>
<script>
import ServiceSequenceConfirmationDialog from '@/components/ServiceSequence/ServiceSequenceConfirmationDialog'
import { replaceServiceSequenceVariables } from '@/utils/replaceServiceSequenceVariables'

export default {
  name: 'ServiceSequenceButton',
  components: {
    ServiceSequenceConfirmationDialog,
  },
  props: {
    command: String,
    buttonText: String,
    buttonProps: Object,
    wrapperProps: Object,
    confirmation: Object,
  },
  data() {
    return {
      confirmationDialogOpen: false,
    }
  },
  inject: ['reactive'],
  methods: {
    replaceServiceSequenceVariables,
  },
}
</script>
