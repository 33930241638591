import gql from 'graphql-tag'

export const correctiveActionUpdate = gql`
  mutation correctiveActionUpdate(
    $correctiveActionId: ID!
    $name: NonEmptyString!
    $descriptionTranslations: DescriptionTranslationsInput!
  ) {
    correctiveActionUpdate(
      input: {
        correctiveActionId: $correctiveActionId
        name: $name
        descriptionTranslations: $descriptionTranslations
      }
    ) {
      success
    }
  }
`
