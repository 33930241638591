import gql from 'graphql-tag'

export const customNotificationClassUpdate = gql`
  mutation customNotificationClassUpdate(
    $companyId: ID!
    $notificationClassId: ID!
    $delays: [Int!]!
    $customName: NonEmptyString
  ) {
    customNotificationClassUpdate(
      input: {
        companyId: $companyId
        notificationClassId: $notificationClassId
        delays: $delays
        customName: $customName
      }
    ) {
      success
    }
  }
`
