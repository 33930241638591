export default {
  pageTitle: 'Willkommen bei iXsolve',
  login: 'Login',
  register: 'Registrieren',
  logIn: 'Log-in',
  password: 'Passwort',
  passwordAgain: 'Password wiederholen',
  forgotPassword: 'Passwort vergessen?',
  registerNewAccount: 'Neues Konto registrieren',
  signIn: 'Konto erstellen',
  haveAccount: 'Ich habe ein Konto',
  error: { wrongUsernameOrPassword: 'Falsche Email oder falsches Passwort.' },
  backToLogin: 'Zurück zum Login',
  resetPassword: 'Passwort zurücksetzen',
  forgotPasswordTitle: 'Passwort Zurücksetzen',
  confirmAccountTitle: 'Bitte bestätigen Sie Ihr Konto.',
  confirmationCode: 'Bestätigungscode',
  sendConfirmation: 'Konto bestätigen',
  requestNewCode: 'Neuen Code anfordern',
  confirmAccountInEmail:
    'Konto wurde erfolgreich erstellt. Bitte überprüfen Sie Ihre E-Mails und bestätigen Sie Ihr Konto.',
  changePassword: 'Passwort ändern',
  resetPasswordTitle: 'Passwort Zurücksetzen',
  resetPasswordText:
    'Ein Verifizierungscode wurde an Ihre E-Mail gesendet. Bitte kopieren Sie den Bestätigungscode in das untenstehende Feld und setzen Sie ein neues Passwort.',
  resetPasswordCode: 'Verifizierungscode aus der E-Mail',
  resetPasswordSuccess: 'Passwort erfolgreich aktualisiert.',
  resetPasswordInvalidCode: 'Ungültiger Verifizierungscode.',
}
