import gql from 'graphql-tag'
import { CompanyUserWithPermissions } from '@/graphql/fragments/companyUserWithPermissions'

export const companyUser = gql`
  query GetCompanyUser($userId: ID!, $companyId: ID!, $withPermissions: Boolean! = true) {
    user: companyUser(userId: $userId, companyId: $companyId) {
      ...CompanyUserWithPermissions
    }
  }
  ${CompanyUserWithPermissions}
`
