<template>
  <v-main style="overflow: auto">
    <div v-if="$apollo.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <template v-else>
      <div v-if="company" class="page-wrapper">
        <div class="content-wrapper pa-6">
          <h2>{{ $t('users.serviceCompanyDetail') }}</h2>
          <p v-if="this.company && !this.company.enabled" class="not-enabled">
            {{ $t('users.notEnabledCompany') }}
          </p>
          <v-form class="mt-6">
            <div class="form-wrapper mb-2">
              <v-text-field
                v-model="company.name"
                :label="$t('global.companyName')"
                filled
                disabled
              ></v-text-field>
              <v-text-field
                v-model="company.contactEmail"
                :label="$t('global.email')"
                filled
                disabled
              ></v-text-field>
            </div>
            <UserPermissionsInput v-model="permissions"></UserPermissionsInput>
          </v-form>
          <div class="d-flex flex-wrap mt-4">
            <v-btn
              color="primary"
              class="mr-5 mb-2"
              @click="updatePermissions()"
              :block="showBlockButtons"
              >{{ $t('global.save') }}</v-btn
            >
            <v-btn
              @click="isConfirmDeleteDialogOpen = true"
              color="error"
              class="mr-5 mb-2"
              :block="showBlockButtons"
              >{{ $t('global.delete') }}</v-btn
            >
            <v-btn @click="cancel()" :block="showBlockButtons">{{ $t('global.cancel') }}</v-btn>
          </div>
        </div>
      </div>
      <div v-else class="pa-8">
        <v-alert prominent type="error">
          <v-row align="center">
            <v-col class="grow">
              {{ $t('users.userNotFound') }}
            </v-col>
            <v-col class="shrink">
              <v-btn @click="$router.back()">{{ $t('global.back') }}</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </div>

      <ConfirmationDialog
        v-model="isConfirmDeleteDialogOpen"
        :heading="$t('users.serviceCompanyDeleteConfirmation.heading')"
        :text="$t('users.serviceCompanyDeleteConfirmation.text')"
        :confirmText="$t('global.delete')"
        confirmBtnColor="error"
        :action="deleteCompanyFromCompany"
      />
    </template>
  </v-main>
</template>

<script>
import { serviceCompanyDetail } from '@/graphql/query/serviceCompanyDetail'
import UserPermissionsInput from '@/components/User/UserPermissionsInput'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { companyPermissionsUpdate } from '@/graphql/mutations/companyPermissionsUpdate'
import { companyDeleteFromCompany } from '@/graphql/mutations/companyDeleteFromCompany'
import { mapGetters } from 'vuex'
import { childCompaniesPermissions } from '@/config/permissions'
import pick from 'lodash/pick'

export default {
  name: 'ServiceCompanyDetailPage',
  components: { UserPermissionsInput, ConfirmationDialog },
  data: () => {
    return {
      permissions: null,
      isConfirmDeleteDialogOpen: false,
    }
  },
  apollo: {
    company: {
      query: serviceCompanyDetail,
      variables() {
        return {
          companyId: this.$route.params.id,
          parentCompanyId: this.currentCompanyId,
        }
      },
    },
  },
  watch: {
    company(newCompany) {
      this.permissions = pick(newCompany.permissions, Object.values(childCompaniesPermissions))
    },
  },
  methods: {
    async updatePermissions() {
      try {
        await this.$apollo.mutate({
          mutation: companyPermissionsUpdate,
          variables: {
            companyId: this.currentCompanyId,
            permissionsList: [{ ...this.permissions, id: this.company.permissions.id }],
          },
        })

        this.$toast.success(this.$t('global.dataUpdateSuccess'))

        this.$store.commit('setHasUnsavedChanges', false)
        this.$router.back()
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.dataUpdateFailure'))
      }
    },
    async deleteCompanyFromCompany() {
      try {
        await this.$apollo.mutate({
          mutation: companyDeleteFromCompany,
          variables: {
            childCompanyId: this.$route.params.id,
            parentCompanyId: this.currentCompanyId,
          },
        })

        this.isConfirmDeleteDialogOpen = false
        this.$toast.success(this.$t('users.serviceCompanyDeleteSuccess'))

        this.$store.commit('setHasUnsavedChanges', false)
        this.$router.back()
      } catch (error) {
        this.$toast.error(this.$t('users.serviceCompanyDeleteFailure'))
      }
    },
    onBeforeUnload() {
      if (this.hasUnsavedChanges) {
        // modern browsers will ignore this message and will show default
        return this.$t('global.unsavedChanges')
      }
    },
    cancel() {
      this.$store.commit('setHasUnsavedChanges', false)
      this.$router.back()
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId', 'currentUserId', 'currentCompanyPermissions']),
    ...mapGetters(['hasUnsavedChanges']),
    showBlockButtons: function () {
      return this.$vuetify.breakpoint.name === 'xs'
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('global.unsavedChanges'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload
  },
  destroyed() {
    window.onbeforeunload = undefined
    this.$store.commit('setHasUnsavedChanges', false)
  },
}
</script>

<style scoped lang="less">
main {
  flex: 1 1 auto;
}

.page-wrapper {
  min-height: 100%;
  max-width: 768px;
  border-right: 1px solid #eeeeee;

  .content-wrapper {
    border-bottom: 1px solid #eeeeee;

    .form-wrapper {
      max-width: 330px;
    }
  }
}

.headline {
  word-break: break-word;
}

.not-enabled {
  color: darkorange;
}
</style>
