<template>
  <div>
    <v-data-table
      v-bind="$attrs"
      v-on="$listeners"
      :options.sync="options"
      :items="items"
      :search="search"
      :loading="loading"
      :custom-filter="customFilter || caseAndDiacriticsInsensitiveFilter"
    >
      <!-- Pass on all named slots -->
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
      <!-- Pass on all scoped slots -->
      <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>
    </v-data-table>
  </div>
</template>
<script>
import Big from 'big.js'
// known BUG: https://github.com/vuetifyjs/vuetify/issues/14414

export default {
  name: 'DataTableCustom',
  props: {
    queryPrefix: String,
    items: null, // any
    search: String,
    loading: Boolean,
    itemsPerPage: Number,
    customFilter: Function,
    getFilterableStrings: Function, // function that takes table item and returns all of the strings that should be filterable via search
  },
  data() {
    return {
      options: null,
      defaults: {
        // query parameters are used only if these props are different from default
        page: 1,
        pageSize: this.itemsPerPage ?? -1,
      },
      keepPage: false,
      ignoreFirstOptions: true,
    }
  },
  mounted() {
    if (this.$route.query?.[`${this.prefix}q`]) {
      if (this.$route.query?.[`${this.prefix}page`]) {
        this.keepPage = true // do not reset page on search change when arriving on page
      }
      this.$emit('update:search', this.$route.query?.[`${this.prefix}q`])
    }
  },
  methods: {
    encodeSort(sortBy, sortDesc) {
      return sortBy.reduce((acc, cur, index) => {
        acc += cur
        if (sortDesc[index]) {
          acc += '|D'
        }
        if (index < sortBy.length - 1) {
          acc += '+'
        }

        return acc
      }, '')
    },
    decodeSort(query) {
      const splitted = query.split('+')
      return splitted.reduce(
        (acc, cur) => {
          const split = cur.split('|')
          if (split.length > 1 && split[split.length - 1] === 'D') {
            acc.sortBy.push(split.slice(0, -1).join('|'))
            acc.sortDesc.push(true)
          } else {
            acc.sortBy.push(split[0])
            acc.sortDesc.push(false)
          }

          return acc
        },
        { sortBy: [], sortDesc: [] },
      )
    },
    caseAndDiacriticsInsensitiveFilter(value, search, item) {
      if (this.getFilterableStrings) {
        return this.getFilterableStrings(item).some((str) => this.filterUtility(str, search))
      }
      return this.filterUtility(value, search)
    },
    filterUtility(value, search) {
      if (!value) {
        return false
      }
      if (!search || search.trim() === '') {
        return true
      }
      const toLowerCaseWithoutDiacritic = (str) =>
        str.toString().toLowerCase().normalize('NFKD').replace(/[^\w]/g, '')

      return toLowerCaseWithoutDiacritic(value).indexOf(toLowerCaseWithoutDiacritic(search)) !== -1
    },
  },
  computed: {
    prefix() {
      return this.queryPrefix ? `${this.queryPrefix}-` : ''
    },
  },
  watch: {
    search() {
      if (this.items) {
        const newQuery = { ...this.$route.query }

        // reset page on search
        if (!this.keepPage) {
          delete newQuery[`${this.prefix}page`]
          this.options = {
            ...this.options,
            page: this.defaults.page,
          }
        }

        if (this.search && this.search.trim() !== '') {
          newQuery[`${this.prefix}q`] = this.search
        } else {
          delete newQuery[`${this.prefix}q`]
        }
        if (this.$route.query?.[`${this.prefix}q`] !== newQuery[`${this.prefix}q`]) {
          this.$router.push({
            name: this.$route.name,
            params: this.$route.params,
            query: newQuery,
          })
        }
      }
    },
    options(newOptions) {
      if (!this.ignoreFirstOptions) {
        const newQuery = { ...this.$route.query }
        if (newOptions?.page !== this.defaults.page) {
          newQuery[`${this.prefix}page`] = newOptions.page
        } else {
          delete newQuery[`${this.prefix}page`]
        }
        if (newOptions?.itemsPerPage !== this.defaults.pageSize) {
          newQuery[`${this.prefix}pageSize`] = newOptions.itemsPerPage
        } else {
          delete newQuery[`${this.prefix}pageSize`]
        }
        if (newOptions?.sortBy?.length) {
          newQuery[`${this.prefix}sort`] = this.encodeSort(newOptions.sortBy, newOptions.sortDesc)
        } else {
          delete newQuery[`${this.prefix}sort`]
        }

        const pageInQuery =
          this.$route.query?.[`${this.prefix}page`] == null
            ? undefined
            : Number(this.$route.query?.[`${this.prefix}page`])
        const pageSizeInQuery =
          this.$route.query?.[`${this.prefix}pageSize`] == null
            ? undefined
            : Number(this.$route.query?.[`${this.prefix}pageSize`])
        if (
          pageInQuery !== newQuery[`${this.prefix}page`] ||
          pageSizeInQuery !== newQuery[`${this.prefix}pageSize`] ||
          this.$route.query?.[`${this.prefix}sort`] !== newQuery[`${this.prefix}sort`]
        ) {
          this.$router.push({
            name: this.$route.name,
            params: this.$route.params,
            query: newQuery,
          })
        }
      } else {
        this.ignoreFirstOptions = false
      }
    },
    items: {
      immediate: true,
      handler() {
        if (this.items?.length) {
          let pageSize = this.defaults.pageSize
          if (
            !this.$attrs?.['disable-pagination'] &&
            this.$route.query?.[`${this.prefix}pageSize`]
          ) {
            const parsedPageSize = Number(this.$route.query[`${this.prefix}pageSize`])
            if (parsedPageSize === -1 || parsedPageSize > 0) {
              pageSize = parsedPageSize
            }
          }

          let page = this.defaults.page
          if (!this.$attrs?.['disable-pagination'] && this.$route.query?.[`${this.prefix}page`]) {
            const parsedPage = Number(this.$route.query[`${this.prefix}page`])
            const itemsLength =
              this.$attrs?.['server-items-length'] != null
                ? this.$attrs?.['server-items-length']
                : this.items.length
            if (
              parsedPage <= Number(new Big(itemsLength).div(pageSize).round(0, 3)) && // round up
              parsedPage > 0
            ) {
              page = parsedPage
            }
          }

          let sortBy = []
          let sortDesc = []
          if (!this.$attrs?.['disable-sort'] && this.$route.query?.[`${this.prefix}sort`]) {
            const { sortBy: parsedSortBy, sortDesc: parsedSortDesc } = this.decodeSort(
              this.$route.query?.[`${this.prefix}sort`],
            )
            if (
              parsedSortBy.length === 1 ||
              (this.$attrs?.['multi-sort'] && parsedSortBy.length > 1)
            ) {
              sortBy = parsedSortBy
              sortDesc = parsedSortDesc
            }
          }

          this.options = {
            ...this.options,
            page,
            itemsPerPage: pageSize,
            sortBy,
            sortDesc,
          }
        }
      },
    },
  },
}
</script>
