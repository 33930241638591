import gql from 'graphql-tag'

export const parentCompanies = gql`
  query parentCompanies($companyId: ID!) {
    company(companyId: $companyId) {
      id
      parentCompanies {
        id
      }
    }
  }
`
