<template>
  <div>
    <DataTableCustom
      :headers="headers"
      :items="userDevices"
      item-key="id"
      :item-class="getRowClass"
      class="elevation-2 pt-2 device-list-table"
      :no-results-text="$t('users.noMatchingRecordsFound')"
      :no-data-text="$t('device.noDevices')"
      @click:row="rowClick"
      hideDefaultFooter
      disablePagination
      :loading="isLoading"
      :custom-sort="tableSort"
    >
      <template v-slot:loading>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </template>
      <template v-slot:item.name="{ item }">
        <DeviceNameTableItem :device="item" />
      </template>
      <template v-slot:item.connection="{ item }">
        <div class="d-flex align-center">
          <span
            class="status-badge"
            :class="item.connectionStatus === 'ONLINE' ? 'online-badge' : 'offline-badge'"
            >{{ getConnectionColumnValue(item) }}</span
          >
          <span
            v-if="item.connectionStatus === 'OFFLINE' && item.connectionStatusChangedTime"
            class="ml-3"
            >{{ formatDistanceToNow(item.connectionStatusChangedTime) }}</span
          >
        </div>
      </template>
      <template v-slot:item.operatingMode="{ item }">
        {{ getOperatingModeValue(item) }}
      </template>
      <template v-slot:item.reports="{ item }">
        <div v-if="item.eventDetectionPoints" class="reports">
          <ReportBadge
            v-for="eventCode of getSortedErrors(item.eventDetectionPoints)"
            :key="`${eventCode.description}|${eventCode.severity}`"
            :text="eventCode.description"
            :severity="eventCode.severity"
            :clickable="false"
            :deviceId="item.id"
          />
        </div>
      </template>
    </DataTableCustom>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { dateLocales } from '@/config/dateFnsLocales'
import { permissions } from '@/config/permissions'
import get from 'lodash/get'
import ReportBadge from '@/components/UI/ReportBadge'
import DeviceNameTableItem from '@/components/DeviceNameTableItem'

export default {
  name: 'DeviceListPageListView',
  components: { ReportBadge, DeviceNameTableItem },
  props: {
    userDevices: Array,
    isLoading: Boolean,
  },
  methods: {
    formatDistanceToNow(date) {
      return formatDistanceToNow(new Date(date), {
        locale: dateLocales[this.$i18n.locale],
      })
    },
    rowClick(item) {
      this.$router.push({
        name: 'DevicePage',
        params: { deviceId: item.id },
      })
    },
    getRowClass() {
      return 'row-wrapper'
    },
    getConnectionColumnValue(item) {
      return item.connectionStatus === 'ONLINE' ? 'ONLINE' : 'OFFLINE'
    },
    getOperatingModeValue(item) {
      return this.$t(`device.operatingMode.${item.operatingMode}`)
    },
    getErrorsStringRepresentation(item) {
      return item.eventDetectionPoints
        ? item.eventDetectionPoints
            .flatMap((eop) => eop.eventCodes.map((ec) => ec.description), '')
            .join('')
        : ''
    },
    tableSort(items, sortBy, sortDesc) {
      if (this.userDevices) {
        this.$store.commit(
          'user/setUsersDevices',
          this.sortFunction(JSON.parse(JSON.stringify(this.userDevices)), sortBy, sortDesc),
        )
      }
      return this.sortFunction(items, sortBy, sortDesc)
    },
    sortFunction(items, sortBy, sortDesc) {
      if (!sortBy || !sortBy.length || !sortDesc || !sortDesc.length) {
        return items
      }
      items.sort((a, b) => {
        if (sortBy[0] === 'connection') {
          if (!sortDesc[0]) {
            return this.getConnectionColumnValue(a).localeCompare(this.getConnectionColumnValue(b))
          } else {
            return this.getConnectionColumnValue(b).localeCompare(this.getConnectionColumnValue(a))
          }
        } else if (sortBy[0] === 'operatingMode') {
          if (!sortDesc[0]) {
            return this.getOperatingModeValue(a).localeCompare(this.getOperatingModeValue(b))
          } else {
            return this.getOperatingModeValue(b).localeCompare(this.getOperatingModeValue(a))
          }
        } else if (sortBy[0] === 'reports') {
          if (!sortDesc[0]) {
            return this.getErrorsStringRepresentation(a).localeCompare(
              this.getErrorsStringRepresentation(b),
            )
          } else {
            return this.getErrorsStringRepresentation(b).localeCompare(
              this.getErrorsStringRepresentation(a),
            )
          }
        } else {
          // default
          const valueA = get(a, sortBy[0]) ?? ''
          const valueB = get(b, sortBy[0]) ?? ''
          if (!sortDesc[0]) {
            return valueA.localeCompare(valueB)
          } else {
            return valueB.localeCompare(valueA)
          }
        }
      })
      return items
    },
    getSortedErrors(eventDetectionPoints) {
      return eventDetectionPoints
        .flatMap((eop) => eop.eventCodes)
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyPermissions', 'currentCompanyId']),
    headers() {
      return [
        { text: this.$t('device.deviceListHeaders.name'), value: 'name', width: '15% ' },
        ...(this.currentCompanyPermissions[permissions.OTHER_COMPANIES_MANAGEMENT] ||
        !this.currentCompanyId
          ? [
              {
                text: this.$t('device.deviceListHeaders.company'),
                value: 'company.name',
                width: '10%',
              },
            ]
          : []),
        {
          text: this.$t('device.deviceListHeaders.connectionType'),
          value: 'connectionType.formattedValue',
          width: '10%',
        },
        { text: this.$t('device.deviceListHeaders.connection'), value: 'connection', width: '10% ' },
        {
          text: this.$t('device.deviceListHeaders.operatingMode'),
          value: 'operatingMode',
          width: '10%',
        },
        { text: this.$t('device.deviceListHeaders.reports'), value: 'reports', width: '45%' },
      ]
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.status-badge {
  font-weight: 500;
  font-size: 11px;
  padding: 8px 11px;
  border-radius: 1px;
  line-height: 11px;

  &.offline-badge {
    background-color: #fc5252;
    color: white;
  }
  &.online-badge {
    background-color: fade(#22b77c, 10%);
    color: #22b77c;
  }
}

.reports {
  display: flex;
  flex-wrap: wrap;
  margin: 3px 0px;

  div {
    margin: 2.5px 0px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

/deep/.row-wrapper {
  cursor: pointer;
}

.device-list-table /deep/ table {
  table-layout: fixed;
}
.device-list-table /deep/ table colgroup {
  display: block;
}
</style>
