<template>
  <v-main style="overflow: auto">
    <div v-if="loadingTemplates" class="no-template">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-if="!hasTemplate && !loadingTemplates" class="no-template">
      <div class="functions-row">
        <div>
          <a @click="goToBaseProductList" class="back-to-dashboard pt-10" color="primary"
            ><v-icon left class="back-arrow" color="primary" size="18">mdi-chevron-left</v-icon
            >{{ this.$t('global.back') }}</a
          >
        </div>
        <div>
          <a @click="goToTemplateCreation" class="back-to-dashboard pt-10" color="primary"
            >{{ this.$t('global.creatBaseProduct')
            }}<v-icon left class="back-arrow" color="primary" size="18"
              >mdi-chevron-right</v-icon
            ></a
          >
        </div>
      </div>
      <h2>{{ this.$t('global.noTemplateYet') }}</h2>
    </div>
    <div v-if="hasTemplate && !loadingTemplates">
      <div class="pa-6">
        <a @click="$router.back()" class="back-to-dashboard"
          ><v-icon left class="back-arrow" color="primary" size="18">mdi-chevron-left</v-icon
          >{{
            this.fromProductSettings
              ? this.$t('global.backToProductsList')
              : this.$t('global.backToDashboard')
          }}</a
        >
        <h2>{{ this.$t('global.productSettings') }}</h2>
        <div class="top-row">
          <h3 class="mt-6 mb-6">{{ deviceName }}</h3>
          <div>
            <v-btn class="mr-2" @click="$router.back()">{{ this.$t('global.cancel') }}</v-btn>
            <v-btn color="primary" @click="saveNewProduct">{{ this.$t('global.save') }}</v-btn>
          </div>
        </div>
        <v-form>
          <!-- Select field for eligibility templates -->
          <v-select
            class="select-template"
            :label="$t('global.selectBaseProduct')"
            v-model="selectedTemplate"
            :items="templateOptions"
            item-text="name"
            item-value="id"
            @change="loadEligibilityForm"
          ></v-select>

          <div class="feature-type-nav">
            <v-tabs v-model="currentTab" show-arrows class="mt-2">
              <v-tab @click="setFeatureFilter('ALL')">
                {{ this.$t('global.eligibilityFilter.all') }}
              </v-tab>
              <v-tab @click="setFeatureFilter('ONE_OFF')">
                {{ this.$t('global.eligibilityFilter.oneOff') }}
              </v-tab>
              <v-tab @click="setFeatureFilter('SUBSCRIPTION')">
                {{ this.$t('global.eligibilityFilter.subscription') }}
              </v-tab>
              <v-tab @click="setFeatureFilter('SERVICE_SEQUENCES')">
                {{ this.$t('global.eligibilityFilter.serviceSequences') }}
              </v-tab>
              <v-tab @click="setFeatureFilter('PREMIUM')">
                {{ this.$t('global.eligibilityFilter.premium') }}
              </v-tab>
            </v-tabs>
          </div>

          <!-- Eligibility Form -->
          <div v-if="eligibilityForm.length > 0" class="eligibility-form">
            <!-- Headers -->
            <div class="eligibility-header">
              <div class="name-header">{{ this.$t('global.feature') }}</div>
              <div class="availability-header">{{ this.$t('global.availability') }}</div>
            </div>
            <!-- Eligibility items -->
            <EligibilityDeviceControl
              v-for="eligibility in filteredEligibilities"
              :key="eligibility.name"
              :eligibility="eligibility"
              v-model="form.eligibilities[eligibility.name]"
            />
          </div>
        </v-form>
      </div>
    </div>
  </v-main>
</template>

<script>
import EligibilityDeviceControl from '@/components/ProductCatalogue/EligibilitiesInputs/EligibilityDeviceControl'
import { eligibilityTemplates } from '@/graphql/query/eligibilityTemplates'
import { newProductCreate } from '@/graphql/mutations/newProductCreate'
import { permissions } from '@/config/permissions'
import { mapGetters } from 'vuex'

export default {
  name: 'AddDeviceProductPage',
  components: {
    EligibilityDeviceControl,
  },
  props: {
    controllerId: {
      type: String,
    },
    deviceName: {
      type: String,
    },
    thingType: {
      type: String,
    },
    fromProductSettings: Boolean,
    fromChangeBaseProduct: Boolean,
  },
  data() {
    return {
      form: {
        name: this.deviceName,
        eligibilities: {},
      },
      selectedTemplate: null,
      eligibilityForm: [],
      templateOptions: [],
      eligiblitiesTemplates: {},
      featureFilter: 'ALL',
      currentTab: 0,
      loadingTemplates: true,
      hasUnsavedChanges: false,
    }
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId', 'currentCompanyPermissions']),
    productName() {
      return `${this.deviceName} - ${this.controllerId}`
    },
    permissions() {
      return permissions
    },
    filteredEligibilities() {
      if (!Array.isArray(this.eligibilityForm)) {
        console.error('eligibilityForm is not an array')
        return []
      }

      const filtered = this.eligibilityForm.filter((eligibility) => {
        switch (this.featureFilter) {
          case 'ONE_OFF':
            return eligibility.provisionType === 'ONE_OFF'
          case 'SUBSCRIPTION':
            return eligibility.provisionType === 'SUBSCRIPTION'
          case 'SERVICE_SEQUENCES':
            return eligibility.serviceSequence
          case 'PREMIUM':
            return eligibility.isPremium
          default:
            return true
        }
      })

      filtered.sort((a, b) => {
        if (a.defaultAvailability === 'optional' && b.defaultAvailability !== 'optional') {
          return -1
        } else if (a.defaultAvailability !== 'optional' && b.defaultAvailability === 'optional') {
          return 1
        } else {
          return 0
        }
      })

      return filtered
    },
    hasTemplate() {
      // Return false if templates are still loading or no templates are available
      return !this.loadingTemplates && this.templateOptions.length > 0
    },
  },
  apollo: {
    // Fetch eligibility templates using the query
    eligibilityTemplates: {
      query: eligibilityTemplates,
      variables() {
        return {
          companyId: this.currentCompanyId,
          thingType: this.thingType,
          lang: this.$i18n.locale,
        }
      },
      loadingKey: 'loadingTemplates',
      result({ data }) {
        // Check if templates are available and update loading state
        if (data && data.company && data.company.eligibilityTemplates) {
          this.eligiblitiesTemplates = data.company.eligibilityTemplates
          this.templateOptions = data.company.eligibilityTemplates.map((template) => ({
            id: template.id,
            name: template.name,
          }))
          this.loadingTemplates = false
        } else {
          this.loadingTemplates = false
        }
      },
    },
  },
  methods: {
    resetFormWithTemplate(selectedTemplate) {
      this.form.eligibilities = {}
      Object.entries(selectedTemplate.eligibilities).forEach(([key, value]) => {
        if (value.defaultAvailability !== 'not_available') {
          this.form.eligibilities[key] = {
            defaultValue: value.defaultValue,
            defaultAvailability: value.defaultAvailability,
          }
        }
      })
      this.selectedTemplate = selectedTemplate.id
    },
    goToTemplateCreation() {
      this.$router.push({ name: 'AddBaseProductPage' })
    },
    goToBaseProductList() {
      if (this.currentCompanyPermissions[permissions.BASE_PRODUCT_CATALOGUE]) {
        this.$router.push({ name: 'NewProductCataloguePage' })
      } else {
        this.$router.back()
      }
    },
    async saveNewProduct() {
      try {
        const eligibilitiesWithOption = {}
        Object.entries(this.form.eligibilities).forEach(([key, value]) => {
          eligibilitiesWithOption[key] = {
            defaultValue: value.defaultValue,
            defaultAvailability: value.defaultAvailability,
          }
        })

        const input = {
          name: this.deviceName,
          companyId: this.currentCompanyId,
          deviceId: this.controllerId,
          templateId: this.selectedTemplate,
          eligibilities: eligibilitiesWithOption,
        }
        const response = await this.$apollo.mutate({
          mutation: newProductCreate,
          variables: { input },
        })

        if (response.data.newProductCreate.success) {
          this.hasUnsavedChanges = false
          this.$toast.success(this.$t('global.newProductCreate.success'))
          if (this.fromChangeBaseProduct) {
            this.$router.push({ name: 'NewProductCataloguePage' })
          } else {
            this.$router.back()
          }
        } else {
          console.error('Failed to create the product')
          this.$toast.error(this.$t('global.newProductCreate.failure'))
        }
      } catch (error) {
        console.error('Error saving new product:', error)
        this.$toast.error(this.$t('global.newProductCreate.failure'))
      }
    },
    loadEligibilityForm(selectedId) {
      this.eligibilityForm = []
      const selectedTemplate = this.eligiblitiesTemplates.find(
        (template) => template.id === selectedId,
      )
      this.selectedTemplate = selectedTemplate

      if (selectedTemplate && selectedTemplate.eligibilities) {
        this.resetFormWithTemplate(selectedTemplate)
        const allowedEligibilities = {}
        Object.entries(selectedTemplate.eligibilities).forEach(([key, value]) => {
          if (value.defaultAvailability !== 'not_available') {
            allowedEligibilities[key] = value
          }
        })
        this.eligibilityForm = Object.entries(allowedEligibilities).map(([key, value]) => ({
          name: key,
          ...value,
        }))
      } else {
        console.error('Selected template not found or has no eligibilities')
        this.eligibilityForm = []
      }
    },
    setFeatureFilter(filterType) {
      this.featureFilter = filterType
    },
    handleEligibilityChange(updatedEligibility) {
      this.form.eligibilities[updatedEligibility.name] = updatedEligibility.defaultValue
    },
  },
  watch: {
    'form.eligibilities': {
      handler() {
        this.hasUnsavedChanges = true
      },
      deep: true,
    },
    selectedTemplate(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.hasUnsavedChanges = true
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('global.unsavedChanges'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

/deep/.v-slide-group {
  background-color: tint(@color-brand-highlight, 50%) !important;
  margin-bottom: 20px;

  .v-tab {
    color: @color-brand-2 !important;
    opacity: 0.5;

    &--active {
      opacity: 1;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .v-tabs-slider-wrapper {
    color: @color-brand-2 !important;
  }
}

.no-template {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-right: 2rem;
}

/* Style the select dropdown */
.select-template {
  margin-bottom: 1rem;
  max-width: 20rem;
}

.top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 2rem;
}

/* Styling for the eligibility form section */
.eligibility-form {
  border-top: 1px solid #e0e0e0;
}

/* Styling for the headers above the radio buttons */
.eligibility-header {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  margin-bottom: 8px;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid #ececec;
}

.back-to-dashboard {
  cursor: pointer;
  color: @color-primary;
  text-decoration: none;
  margin-bottom: 1em;
  display: inline-block;
  display: flex;
  justify-items: center;
  font-size: 0.95rem;
}

.functions-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 80%;
  padding-bottom: 2rem;
}

.back-to-dashboard:hover {
  opacity: 0.8;
}

.back-arrow {
  max-width: 1rem;
  max-height: auto;
  color: @color-primary;
}

.name-header {
  flex: 1;
}

.availability-header {
  margin-right: 7rem;
}

.no-header {
  width: 10%;
}
</style>
