var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('figure',{ref:"scrollContainer",staticClass:"scrollable pb-2",style:(_vm.tableHeight ? { height: _vm.tableHeight + 'px' } : null)},[_c('table',{staticClass:"rotated-header-table"},[_c('thead',[_c('tr',[_c('th',[_c('div',{class:{ 'shadow-right': _vm.showShadow }})]),_vm._l((_vm.settablePermissions),function(permissionName,key,index){return _c('th',{key:permissionName,style:({ zIndex: 100 - index })},[_c('div',{ref:permissionName,refInFor:true,staticClass:"rotated-header-container"},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px","z-index":"102"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({ref:permissionName,refInFor:true,staticClass:"rotated-header-content",on:{"mouseenter":function($event){return _vm.highlightColumn(permissionName)},"mouseleave":function($event){return _vm.unhighlightColumn(permissionName)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t(`users.permissions.${permissionName}`))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(`users.permissionsDescription.${permissionName}`)))])])],1)])})],2)]),_c('tbody',_vm._l((_vm.permissionsEdit),function(permissionRow){return _c('tr',{key:permissionRow.id,staticClass:"body-row"},[_vm._l((permissionRow),function(value,key){return [(key !== 'id' && key !== 'userId' && key !== 'isAdminInSomeCompany')?_c('td',{key:key,ref:key,refInFor:true,staticClass:"text-center",class:{ 'name-td': key === 'name' },on:{"mouseenter":function($event){return _vm.highlightColumn(key)},"mouseleave":function($event){return _vm.unhighlightColumn(key)}}},[(key !== 'name')?_c('v-simple-checkbox',{attrs:{"color":"primary","ripple":false,"disabled":_vm.isPermissionDisabled(
                    key,
                    permissionRow.userId,
                    _vm.permissions.find((p) => p.id === permissionRow.id)[
                      _vm.companyPermissions.COMPANY_ADMINISTRATION
                    ],
                    permissionRow.isAdminInSomeCompany,
                  )},model:{value:(permissionRow[key]),callback:function ($$v) {_vm.$set(permissionRow, key, $$v)},expression:"permissionRow[key]"}}):_c('div',{staticClass:"name-wrapper",class:{ 'shadow-right': _vm.showShadow }},[_c('button',{staticClass:"edit-btn",on:{"click":function($event){return _vm.$router.push({
                      name: _vm.linkToName,
                      params: { id: permissionRow.userId },
                    })}}},[_c('div',{staticClass:"button-text"},[_vm._v(_vm._s(value))]),_c('v-icon',{attrs:{"right":"","dense":""}},[_vm._v("mdi-pencil")])],1)])],1):_vm._e()]})],2)}),0)])]),_c('div',{staticClass:"flex-shrink-0 mt-8"},[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"primary"},on:{"click":function($event){return _vm.updatePermissions()}}},[_vm._v(_vm._s(_vm.$t('global.save')))]),_c('v-btn',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t('global.cancel')))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }