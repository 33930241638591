import gql from 'graphql-tag'

export const company = gql`
  query company($companyId: ID!) {
    company(companyId: $companyId) {
      id
      name
      defaultLanguage
      timezone
      usesNewProductCatalogue
    }
  }
`
