import gql from 'graphql-tag'
import { ChildCompaniesPermissions } from '@/graphql/fragments/childCompaniesPermissions'

export const serviceCompanies = gql`
  query serviceCompanies($companyId: ID!) {
    company(companyId: $companyId) {
      companiesWithAccess {
        id
        name
        contactEmail(parentCompanyId: $companyId)
        enabled: enabledInParentCompany(parentCompanyId: $companyId)
        permissions: permissionsInParentCompany(parentCompanyId: $companyId) {
          ...ChildCompaniesPermissions
        }
      }
    }
  }
  ${ChildCompaniesPermissions}
`
