import gql from 'graphql-tag'

export const deviceImageRename = gql`
  mutation deviceImageRename($imageId: ID!, $newName: NonEmptyString!) {
    deviceImageRename(input: { imageId: $imageId, newName: $newName }) {
      success
      image {
        id
        name
      }
    }
  }
`
