<template>
  <div
    class="wrapper"
    :class="{
      'turned-on': !isUpdating && currentValue,
      'turned-off': !isUpdating && !currentValue,
      updating: isUpdating,
    }"
  >
    <div @click="currentValue = !currentValue" v-if="!isUpdating">
      <div class="d-flex flex-column align-center">
        <v-icon size="40">{{ control.icon ? `$${control.icon}` : 'mdi-cogs' }}</v-icon>
        <span class="label mt-4 mb-6">{{ control.label }}</span>
      </div>
      <div class="switch-wrapper d-flex justify-space-between align-center">
        <span>{{ currentValue ? $t('global.on') : $t('global.off') }}</span>
        <v-switch
          v-if="control.settable"
          v-model="currentValue"
          readonly
          inset
          hide-details
        ></v-switch>
      </div>
    </div>
    <div v-if="isUpdating" class="d-flex justify-center align-center" style="height: 100%">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { deviceControl } from '@/graphql/mutations/deviceControl'

export default {
  name: 'ToggleControlTile',
  props: {
    control: Object,
    deviceId: String,
  },
  data() {
    return {
      currentValue: this.control.value === 'true',
      isUpdating: false,
    }
  },
  computed: {
    isUnavailable() {
      return this.isOffline || this.control.forbiddenByTechnology
    },
  },
  methods: {
    async valueChanged(newValue) {
      try {
        await this.$apollo.mutate({
          mutation: deviceControl,
          variables: {
            data: {
              deviceId: this.deviceId,
              name: this.control.name,
              value: newValue ? 'ON' : 'OFF',
            },
          },
        })
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('device.controlUpdateFail'))
      }
    },
  },
  watch: {
    'control.value'() {
      this.currentValue = this.control.value === 'true'
      this.isUpdating = false
    },
    currentValue() {
      const value = this.control.value === 'true'
      if (this.currentValue !== value) {
        this.isUpdating = true
        this.valueChanged(this.currentValue)
      } else {
        this.isUpdating = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.wrapper {
  border-radius: 12.5px;
  padding: 10px;
  transition: 0.3s;
  cursor: pointer;

  .v-icon {
    display: block;
    color: @color-brand-2;
  }

  .label {
    color: @color-brand-2;
    font-size: 15px;
    font-weight: 600;
  }

  .switch-wrapper span {
    font-size: 15px;
    font-weight: bold;
    color: @color-brand;
  }

  &.turned-on {
    background-color: #5ec7f2;

    .switch-wrapper span {
      color: @color-brand-2;
    }
  }

  &.turned-off {
    background-color: fade(@color-brand-highlight, 50%);

    .switch-wrapper span {
      color: @color-brand;
    }
  }

  &.updating {
    background-color: fade(@color-brand-highlight, 50%);
    cursor: wait;
  }
}

/deep/.v-progress-circular {
  color: @color-brand-2;
}

/deep/.v-input {
  margin: 0;
  padding-top: 0 !important;

  .v-input--switch__track {
    color: @color-brand !important;
    opacity: 1;
  }

  .v-input--selection-controls__input {
    margin: 0;
  }
}

/deep/.primary--text .v-input__slot {
  .v-input--switch__track {
    color: @color-brand-2 !important;
    opacity: 1;
  }

  .v-input--switch__thumb {
    color: white !important;
  }
}
</style>
