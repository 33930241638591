import gql from 'graphql-tag'

export const eligiblityTemplateById = gql`
  query eligibilityTemplate($templateId: ID!, $lang: NonEmptyString) {
    eligibilityTemplate(templateId: $templateId, lang: $lang) {
      name
      companyId
      eligibilities
      thingTypes {
        name
        businessName
      }
    }
  }
`
