<template>
  <div class="login-page--content--inner">
    <h1 class="login-page--headline">{{ $t('login.registerNewAccount') }}</h1>

    <div class="login-page--form">
      <SocialLogin></SocialLogin>
      <v-form @submit.prevent="createAccount">
        <v-text-field
          v-model="userFormRegister.email"
          filled
          :error-messages="emailErrors"
          :label="$t('global.email')"
          required
          @input="$v.userFormRegister.email.$touch()"
          @blur="$v.userFormRegister.email.$touch()"
          :readonly="Boolean(fixedEmail)"
          type="email"
        ></v-text-field>
        <v-text-field
          v-model="userFormRegister.password"
          filled
          :error-messages="passwordErrors"
          :label="$t('login.password')"
          required
          @input="$v.userFormRegister.password.$touch()"
          @blur="$v.userFormRegister.password.$touch()"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-text-field
          v-model="userFormRegister.passwordAgain"
          filled
          :error-messages="passwordAgainErrors"
          :label="$t('login.passwordAgain')"
          required
          @input="$v.userFormRegister.passwordAgain.$touch()"
          @blur="$v.userFormRegister.passwordAgain.$touch()"
          :append-icon="showPasswordAgain ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPasswordAgain ? 'text' : 'password'"
          @click:append="showPasswordAgain = !showPasswordAgain"
        ></v-text-field>
        <div class="login-page--error">
          <div v-if="error">{{ $t(error) }}</div>
        </div>
        <div class="btn--wrapper">
          <v-btn :loading="isLoading" type="submit" color="primary" large>{{
            $t('login.signIn')
          }}</v-btn>
          <router-link :to="{ name: 'LoginPage' }" class="to-login-btn ml-10">{{
            $t('login.haveAccount')
          }}</router-link>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import SocialLogin from '@/components/Login/SocialLogin'
import { Auth } from 'aws-amplify'
import { AuthenticationDetails } from 'amazon-cognito-identity-js'
import { getItem } from '@/middleware/storage'
import queryString from 'query-string'
import { validationMixin } from 'vuelidate'
import { required, email, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'Register',
  components: { SocialLogin },
  mixins: [validationMixin],
  validations: {
    userFormRegister: {
      email: { required, email },
      password: { required },
      passwordAgain: { required, sameAsPassword: sameAs('password') },
    },
  },
  data: function () {
    return {
      userFormRegister: {
        email: '',
        password: '',
        passwordAgain: '',
      },
      error: null,
      showPassword: false,
      showPasswordAgain: false,
      isLoading: false,
    }
  },
  computed: {
    fixedEmail() {
      return this.$route.query.email || this.redirectQueryParams?.email || null
    },
    redirectQueryParams() {
      return getItem('redirect')
        ? queryString.parse(queryString.extract(getItem('redirect')))
        : null
    },
    emailErrors() {
      const errors = []
      if (!this.$v.userFormRegister.email.$dirty) {
        return errors
      }
      !this.$v.userFormRegister.email.required &&
        errors.push(this.$t('global.formValidation.required'))
      !this.$v.userFormRegister.email.email &&
        errors.push(this.$t('global.formValidation.notEmail'))
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.userFormRegister.password.$dirty) {
        return errors
      }
      !this.$v.userFormRegister.password.required &&
        errors.push(this.$t('global.formValidation.required'))
      return errors
    },
    passwordAgainErrors() {
      const errors = []
      if (!this.$v.userFormRegister.passwordAgain.$dirty) {
        return errors
      }
      !this.$v.userFormRegister.passwordAgain.required &&
        errors.push(this.$t('global.formValidation.required'))
      !this.$v.userFormRegister.passwordAgain.sameAsPassword &&
        errors.push(this.$t('global.formValidation.notSameAsPassword'))
      return errors
    },
  },
  watch: {
    fixedEmail: {
      immediate: true,
      handler(newValue) {
        this.userFormRegister.email = newValue
      },
    },
  },
  methods: {
    createAccount: async function () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          this.isLoading = true
          const result = await Auth.signUp({
            username: this.userFormRegister.email,
            password: this.userFormRegister.password,
            attributes: {
              email: this.userFormRegister.email,
            },
            clientMetadata: {
              invitationToken:
                this.$route.query.token || this.redirectQueryParams?.createCompanyToken,
              env: process.env.VUE_APP_ENV,
              lang: this.$i18n.locale,
            },
          })
          if (result.userConfirmed) {
            const authenticationDetails = new AuthenticationDetails({
              Username: this.userFormRegister.email,
              Password: this.userFormRegister.password,
            })
            result.user.authenticateUser(authenticationDetails, {
              onSuccess: () => {
                this.$router.push({ name: 'DashboardPage' })
              },
              onFailure: (err) => {
                console.error(err)
                this.error = err.message
              },
            })
          } else {
            this.$router.push({
              name: 'ConfirmUserAccountPage',
              params: {
                cognitoUser: result.user,
              },
            })
          }
        } catch (err) {
          console.error(err)
          this.error = err.message
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/variables.less';

.to-login-btn {
  color: @color-primary;
}
</style>
