<template>
  <div class="pa-6">
    <h1 class="mb-10">
      {{ $t('global.adminConsole') }}: {{ $t('global.adminConsoleMenu.serviceHints') }}
    </h1>
    <v-tabs show-arrows>
      <v-tab :to="{ name: 'AdminEventCodes' }">
        {{ $t('global.events') }}
      </v-tab>
      <v-tab :to="{ name: 'AdminCoreCauses' }">
        {{ $t('global.coreCauses') }}
      </v-tab>
      <v-tab :to="{ name: 'AdminCorrectiveActions' }">
        {{ $t('global.correctiveActions') }}
      </v-tab>
      <v-tab :to="{ name: 'AdminDetectionPoints' }">
        {{ $t('global.detectionPoints') }}
      </v-tab>
      <v-tab :to="{ name: 'AdminServiceHintsOverview' }">
        {{ $t('global.overview') }}
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'AdminServiceHints',
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

h1 {
  font-size: 24px;
}

/deep/.v-slide-group {
  background-color: tint(@color-brand-highlight, 50%) !important;
  margin-bottom: 30px;

  .v-tab {
    color: @color-brand-2 !important;
    opacity: 0.5;

    &--active {
      opacity: 1;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .v-tabs-slider-wrapper {
    color: @color-brand-2 !important;
  }
}
</style>
