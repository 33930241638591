<template>
  <div>
    <div v-if="$apollo.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else-if="correctiveAction">
      <div class="mb-2 d-flex align-center justify-space-between">
        <h3 class="mr-2">{{ $t('global.eventCorrectiveActionDetail.title') }}</h3>
        <v-btn
          @click="confirmDeleteModalOpen = true"
          class="ml-2"
          color="error"
          style="height: 27px"
          depressed
        >
          {{ $t('global.delete') }}
        </v-btn>
      </div>
      <div class="values-wrapper mb-4">
        <div class="value">
          <v-btn small icon class="edit-btn" @click="editModalOpen = 'name'">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <span class="value--headline">{{ this.$t('global.correctiveAction') }}</span>
          <span class="value--text">{{ correctiveAction.name }}</span>
        </div>
      </div>
      <h4>{{ $t('global.descriptions') }}</h4>
      <div class="values-wrapper mb-4">
        <div class="value" v-for="lang of allowedLanguages" :key="lang">
          <v-btn
            small
            icon
            class="edit-btn"
            @click="editModalOpen = `descriptionTranslations.${lang}`"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <span
            ><v-icon size="18">{{ `$${lang}` }}</v-icon></span
          >
          <span class="value--text">{{ correctiveAction[`${lang}Description`] || '---' }}</span>
        </div>
      </div>
      <div class="mb-4 d-flex align-center flex-wrap">
        <h3 class="mr-5">{{ $t('global.eventCorrectiveActionDetail.assignedToCoreCauses') }}</h3>
        <v-btn height="27" depressed :to="{ name: 'AdminCoreCauses' }">{{
          $t('global.manage')
        }}</v-btn>
      </div>
      <div class="d-flex">
        <AssignedToList
          :data="correctiveAction.coreCauses"
          :detailPageName="'AdminCoreCauseDetail'"
        />
      </div>
    </div>
    <div v-else class="pa-8">
      <v-alert prominent type="error">
        <v-row align="center">
          <v-col class="grow">
            {{ $t('global.eventCorrectiveActionDetail.correctiveActionNotFound') }}
          </v-col>
          <v-col class="shrink">
            <v-btn @click="$router.back()">{{ $t('global.back') }}</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <template v-if="correctiveAction">
      <EditValueModal
        v-slot="{ validations, value, onInput, errors }"
        :isOpen="editModalOpen === 'name'"
        @modalToggle="editModalOpen = null"
        :heading="`${$t('global.edit')}: ${$t('global.name')}`"
        :validations="requiredValidation"
        :originalValue="correctiveAction.name"
        :submit="updateCorrectiveAction"
      >
        <v-text-field
          :value="value"
          @input="onInput"
          :error-messages="errors"
          :label="$t('global.name')"
          @blur="validations.value.$touch()"
          filled
          style="width: 330px"
        ></v-text-field>
      </EditValueModal>
      <EditValueModal
        v-for="lang of allowedLanguages"
        :key="lang"
        v-slot="{ validations, value, onInput, errors }"
        :isOpen="editModalOpen === `descriptionTranslations.${lang}`"
        @modalToggle="editModalOpen = null"
        :heading="`${$t('global.edit')}: ${$t('global.adminEventCode.description')}`"
        :validations="maxLengthValidation"
        :originalValue="correctiveAction[`${lang}Description`]"
        :submit="updateCorrectiveAction"
      >
        <v-textarea
          :value="value"
          :error-messages="errors"
          @input="onInput"
          @blur="validations.value.$touch()"
          filled
          counter="600"
          rows="3"
          style="width: 330px"
        >
          <template v-slot:label>
            {{ $t('global.adminEventCode.description') }}
            <v-icon class="ml-1" style="vertical-align: middle">{{ `$${lang}` }}</v-icon>
          </template>
        </v-textarea>
      </EditValueModal>

      <ConfirmationDialog
        v-if="correctiveAction"
        v-model="confirmDeleteModalOpen"
        :heading="$t('global.adminCorrectiveActions.deleteConfirmationModal.heading')"
        :text="
          $t('global.adminCorrectiveActions.deleteConfirmationModal.text', {
            correctiveAction: correctiveAction.name,
          })
        "
        :confirmText="$t('global.delete')"
        confirmBtnColor="error"
        :action="deleteCorrectiveAction"
      />
    </template>
  </div>
</template>
<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import { eventCorrectiveActionAdmin } from '@/graphql/query/eventCorrectiveActionAdmin'
import AssignedToList from '@/components/Admin/ServiceHints/AssignedToList'
import { correctiveActionDelete } from '@/graphql/mutations/correctiveActionDelete'
import { correctiveActionUpdate } from '@/graphql/mutations/correctiveActionUpdate'
import EditValueModal from '@/components/Admin/ServiceHints/EditValueModal'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { allowedLanguages } from '@/translations'
import set from 'lodash/set'

export default {
  name: 'AdminCorrectiveActionDetail',
  components: { AssignedToList, EditValueModal, ConfirmationDialog },
  props: {
    id: String,
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editModalOpen: null,
      confirmDeleteModalOpen: false,
      requiredValidation: {
        value: { required },
      },
      maxLengthValidation: {
        value: { maxLength: maxLength(600) },
      },
    }
  },
  apollo: {
    correctiveAction: {
      query: eventCorrectiveActionAdmin,
      variables() {
        return {
          id: this.id,
          lang: this.$i18n.locale,
        }
      },
      update(data) {
        return data.eventCorrectiveActionAdmin
      },
    },
  },
  methods: {
    async updateCorrectiveAction(value) {
      const key = this.editModalOpen
      try {
        const newValue = value?.trim() === '' ? null : value

        const data = {
          correctiveActionId: this.id,
          name: this.correctiveAction.name,
          descriptionTranslations: Object.fromEntries(
            allowedLanguages.map((lang) => [lang, this.correctiveAction[`${lang}Description`]]),
          ),
        }

        // update key
        set(data, key, newValue)

        await this.$apollo.mutate({
          mutation: correctiveActionUpdate,
          variables: data,
          refetchQueries: ['eventCorrectiveActionAdmin'],
        })

        this.$toast.success(
          this.$t('global.adminCorrectiveActions.updateCorrectiveActionAction.success'),
        )
        this.editModalOpen = null
      } catch (error) {
        console.error(error)
        const parsedError = error.message?.replace('GraphQL error:', '').trim()
        this.$toast.error(
          parsedError === 'CORRECTIVE_ACTION_UPDATE_NAME_TAKEN'
            ? this.$t('global.nameTakenError')
            : this.$t('global.adminCorrectiveActions.updateCorrectiveActionAction.failure'),
        )
      }
    },
    async deleteCorrectiveAction() {
      try {
        await this.$apollo.mutate({
          mutation: correctiveActionDelete,
          variables: {
            correctiveActionId: this.id,
          },
          refetchQueries: this.inModal ? ['serviceHintsAdminOverview'] : [],
        })

        this.$toast.success(
          this.$t('global.adminCorrectiveActions.deleteCorrectiveActionAction.success'),
        )
        if (this.inModal) {
          this.$emit('onDelete')
        } else {
          this.$router.push({ name: 'AdminCorrectiveActions' })
        }
      } catch (error) {
        console.error(error)
        this.$toast.error(
          this.$t('global.adminCorrectiveActions.deleteCorrectiveActionAction.failure'),
        )
      }
    },
  },
  computed: {
    allowedLanguages() {
      return allowedLanguages
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.values-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 5px;
}

.value {
  padding: 16px;
  background-color: @color-tile-background;
  position: relative;

  &--headline {
    display: block;
    opacity: 0.5;
    font-size: 12px;
    margin-right: 20px;
  }

  &--text {
    display: block;
    font-size: 14px;
    font-weight: bold;
    word-break: break-word;
  }

  .edit-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
</style>
