import gql from 'graphql-tag'

export const eventDetectionPoints = gql`
  query eventDetectionPoints(
    $deviceId: ID!
    $lang: String!
    $withCoreCauses: Boolean!
    $withCorrectiveActions: Boolean!
  ) {
    device(deviceId: $deviceId) {
      id
      eventDetectionPoints {
        name(lang: $lang)
        coreCauses(lang: $lang) @include(if: $withCoreCauses)
        correctiveActions(lang: $lang) @include(if: $withCorrectiveActions)
        eventCodes {
          severity
          description(lang: $lang)
          timestamp
        }
      }
    }
  }
`
