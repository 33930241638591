<template>
  <router-link
    :to="{
      name: 'DevicePage',
      params: { type: type, deviceId: device.id },
    }"
    class="link"
  >
    <div class="wrapper pa-3">
      <div v-if="hasError" class="badge badge--error">
        <v-icon x-small color="white">mdi-alert</v-icon>
      </div>
      <div v-else-if="hasWarning" class="badge badge--warning">
        <v-icon x-small color="white">mdi-wrench</v-icon>
      </div>
      <div class="content">
        <h2 class="name">
          {{ getDeviceName(device, currentCompanyId) }}
        </h2>
        <div
          v-if="device.company && device.company.id !== $store.getters['user/currentCompanyId']"
          class="company d-flex align-center"
        >
          <v-icon small class="mr-1">$building</v-icon>{{ device.company.name }}
        </div>
        <div v-if="device.connectionStatus === 'OFFLINE'" class="offline-text">Offline</div>
      </div>
    </div>
  </router-link>
</template>
<script>
import { mapGetters } from 'vuex'
import { getDeviceName } from '@/utils/getDeviceName'
import { hasErrorOfSeverity } from '@/utils/hasErrorOfSeverity'

export default {
  name: 'DeviceLink',
  props: {
    device: Object,
    type: String,
  },
  methods: {
    getDeviceName,
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    hasError() {
      return hasErrorOfSeverity(this.device, 'ERROR')
    },
    hasWarning() {
      return hasErrorOfSeverity(this.device, 'WARNING')
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.link {
  &:hover {
    text-decoration: none;
  }

  &.router-link-active > .wrapper {
    background-color: fade(@color-brand-highlight, 50%);
  }

  .wrapper {
    border-bottom: 1px solid #eeeeee;
    height: 84px;
    display: flex;
    align-content: stretch;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .name {
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .company {
      opacity: 0.5;
      font-size: 11px;
      font-weight: 500;
    }

    .offline-text {
      color: @color-danger;
      font-size: 11px;
      font-weight: 500;
    }

    .badge {
      width: 22px;
      margin-right: 12px;
      border-radius: 3px;
      flex-shrink: 0;
      display: flex;
      align-content: center;
      justify-content: center;

      &--error {
        background-color: @color-danger;
      }
      &--warning {
        background-color: @color-warning;
      }
    }
  }
}
</style>
