import gql from 'graphql-tag'

export const translationsUpdateBase = gql`
  mutation translationsUpdateBase {
    translationsUpdateBase(input: {}) {
      success
      updatedFiles
      failedFiles
    }
  }
`
