var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},_vm._l((_vm.tabConfig.items),function(item){return _c('Value',{key:item.name,attrs:{"label":item.label,"value":_vm.getValue(item),"setValueProps":item.canSetInTab &&
      _vm.permissionChecker(_vm.permissions.CONFIGURATION_CHANGES, _vm.eligibilities.DEVICE_CONFIGURATION)
        ? {
            name: item.name,
            deviceId: _vm.device.id,
            unit: item.unit,
            rawValue: item.value,
            options: item.options,
          }
        : null}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }