<template>
  <div class="item">
    <v-btn v-if="setValueProps" small icon class="edit-btn" @click="isEditDialogOpen = true">
      <v-icon small>mdi-pencil</v-icon>
    </v-btn>
    <span class="item--headline">{{ label }}</span>
    <span class="item--text">{{ value != null && value !== '' ? value : '---' }}</span>

    <v-dialog v-if="setValueProps" v-model="isEditDialogOpen" max-width="290">
      <v-card>
        <div class="pa-6">
          <h2 class="mb-3">{{ label }}</h2>
          <v-form @submit.prevent="setValue">
            <v-text-field
              v-model="valueEdit"
              :error-messages="valueEditErrors"
              required
              @input="$v.realValue.$touch()"
              @blur="$v.realValue.$touch()"
              filled
              :suffix="
                setValueProps.unit
                  ? formatUnit(realValue, setValueProps.unit, setValueProps.options)
                  : ''
              "
              type="number"
              :step="setValueProps.options.step"
            ></v-text-field>
            <div class="mt-5 text-right">
              <v-btn @click="isEditDialogOpen = false" depressed>{{ $t('global.cancel') }}</v-btn>
              <v-btn
                type="submit"
                color="primary"
                class="ml-3"
                depressed
                :disabled="$v.$invalid || setValueProps.rawValue === realValue"
                >{{ $t('global.set') }}</v-btn
              >
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
import valueFormatter from '@/mixins/valueFormatter'
import { deviceUpdateParameter } from '@/graphql/mutations/deviceUpdateParameter'
import { EventBus } from '@/helper/EventBus'

export default {
  name: 'Value',
  mixins: [validationMixin, valueFormatter],
  props: {
    label: String,
    value: null,
    setValueProps: {
      name: String,
      deviceId: String,
      unit: String,
      rawValue: String,
      options: Object,
    },
  },
  data() {
    return {
      isEditDialogOpen: false,
      valueEdit: this.setValueProps
        ? this.formatValue(
            this.setValueProps.rawValue,
            this.setValueProps.unit,
            this.setValueProps.options,
          )
        : null,
      realValue: this.setValueProps?.rawValue,
    }
  },
  validations() {
    return {
      realValue: {
        required,
        ...(this.setValueProps?.options?.min != null && {
          minValue: minValue(this.setValueProps.options.min),
        }),
        ...(this.setValueProps?.options?.max != null && {
          maxValue: maxValue(this.setValueProps.options.max),
        }),
      },
    }
  },
  methods: {
    async setValue() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: deviceUpdateParameter,
            variables: {
              deviceId: this.setValueProps.deviceId,
              paramName: this.setValueProps.name,
              newValue: String(this.realValue),
            },
            refetchQueries: ['GetDevice'],
          })
          EventBus.$emit('paramUpdate', {
            paramName: this.setValueProps.name,
            value: this.realValue,
          })

          this.isEditDialogOpen = false
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('device.controlUpdateFail'))
        }
      }
    },
  },
  computed: {
    valueEditErrors() {
      const errors = []
      if (!this.$v.realValue.$dirty) {
        return errors
      }
      !this.$v.realValue.required && errors.push(this.$t('global.formValidation.required'))
      if (this.setValueProps?.options?.min != null && !this.$v.realValue.minValue) {
        errors.push(
          this.$t('global.formValidation.min', {
            min: this.formatValue(
              this.setValueProps?.options?.min,
              this.setValueProps.unit,
              this.setValueProps.options,
            ),
          }),
        )
      }
      if (this.setValueProps?.options?.max != null && !this.$v.realValue.maxValue) {
        errors.push(
          this.$t('global.formValidation.max', {
            max: this.formatValue(
              this.setValueProps?.options?.max,
              this.setValueProps.unit,
              this.setValueProps.options,
            ),
          }),
        )
      }
      return errors
    },
  },
  watch: {
    valueEdit() {
      this.realValue = this.toBaseUnits(
        this.valueEdit,
        this.setValueProps.unit,
        this.setValueProps.options,
      )
    },
    isEditDialogOpen() {
      if (this.isEditDialogOpen) {
        this.valueEdit = this.formatValue(
          this.setValueProps.rawValue,
          this.setValueProps.unit,
          this.setValueProps.options,
        )
      }
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/variables.less';

.item {
  padding: 16px;
  background-color: @color-tile-background;
  position: relative;

  &--headline {
    display: block;
    opacity: 0.5;
    font-size: 12px;
    margin-right: 20px;
  }

  &--text {
    display: block;
    font-size: 14px;
    font-weight: bold;
    word-break: break-word;
  }

  .edit-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
</style>
