

export default {
    controllerId: 'ID zařízení (XXX-XXX-XXX)',
    controller: 'Zařízení',
    status: {
        ONLINE: 'Online',
        OFFLINE: 'Offline'
    },
    operatingMode: {
        IN_CONSTRUCTION: 'Ve výstavbě',
        NORMAL: 'Normální provoz',
        MAINTENANCE: 'Údržba',
        WINTERIZED: 'Plně zazimován'
    },
    lastSynchronization: 'Naposledy připojen',
    propagate: 'Provést restart.',
    saveAndPropagate: 'Uložit a odeslat',
    saveAsDeviceTemplate: 'Uložit jako šablonu',
    saveAsDeviceTemplateDescription: 'Zadejte jméno šablony.',
    validations: {
        is_empty: 'Prosíme vyplňte toto pole.',
        device_already_registered: 'Toto ID zařízení již v databázi existuje.',
        invalid_serial: 'Toto ID zařízení neexistuje.'
    },
    searchDevice: 'Hledat zařízení',
    addDevice: 'Přidat zařízení',
    addDeviceInProgress: 'Rozpracovaný',
    target: 'Cíl',
    deviceNotFound: 'Zařízení nenalezeno.',
    unavailable: 'Nedostupné',
    forbiddenByUser: 'Zakázáno uživatelem',
    enterExpertMode: 'Vstup do expertního režimu',
    forbidden: 'Zakázáno',
    dosing: 'Probíhá dávkování',
    notDosing: 'Připraveno',
    addressNotFound: 'Adresa nenalezena.',
    diagnosticCharts: 'Diagnostické grafy',
    chartsUnderConstruction: 'Sekce je ve vývoji. Pokračovat.',
    chartsUnavailable: 'Diagnostické grafy pro tento typ zařízení nejsou k dispozici.',
    controlUpdateFail: 'Nastavení hodnoty se nezdařilo.',
    sendSettingsFailed: 'Odeslání nastavení do zařízení se nezdařilo.',
    saveDeviceTemplateSuccess: 'Šablona byla úspěšně uložena.',
    saveDeviceTemplateError: 'Při ukládání šablony došlo k chybě.',
    confirmOpenExpertModeModal: {
        headline: 'Opravdu chcete přejít do expertního režimu?',
        text: 'Neodborné používání expertního režimu může způsobit poškození zařízení.'
    },
    confirmCloseExpertModeModal: {
        headline: 'Opravdu chcete opustit expertní režim?',
        text: 'Řízení systému se vrátí do normálního režimu.'
    },
    expertModeModal: {
        headline: 'Aktivován expertní režim.',
        exit: 'Opustit expertní režim',
        openFailed: 'Aktivace expertního režimu se nezdařila.',
        closeFailed: 'Expertní režim se nepodařilo deaktivovat.'
    },
    deviceWentOfflineModal: {
        headline: 'Zařízení přešlo do stavu offline.',
        text: 'Čekejte na opětovné připojení zařízení nebo opusťte expertní režim.',
        buttonText: 'Opustit expertní režim'
    },
    deviceList: 'Seznam zařízení',
    listView: 'Seznam',
    mapView: 'Mapa',
    deviceListHeaders: {
        name: 'Název',
        company: 'Firma',
        connectionType: 'Typ připojení',
        connection: 'Stav připojení',
        currentOperation: 'Aktuální operace',
        operatingMode: 'Provozní režim',
        reports: 'Upozornění',
        userNote: 'Uživatelská poznámka',
        inOperationSince: 'V provozu od'
    },
    noDevices: 'Žádná dostupná zařízení',
    noRecords: 'Žádné záznamy',
    timeSchedule: {
        changeSchedule: 'Změnit týdenní rozvrh',
        selectSchedule: 'Vybrat týdenní rozvrh',
        showWeeklyTimeSchedules: 'Zobrazit týdenní rozvrhy',
        showDailyTimeSchedules: 'Zobrazit denní rozvrhy',
        recommended: 'Doporučená doba běhu',
        activeTimeSchedule: 'Aktivní rozvrh',
        noActiveTimeSchedule: 'Není aktivní žádný časový rozvrh.',
        activeTimeScheduleNoMatch: 'Nerozpoznán aktivní časový rozvrh. Aktivujte prosím jeden z vašich rozvrhů.',
        pleaseActivate: 'Prosím aktivujte jeden z vašich rozvrhů.',
        setActiveTimeScheduleFailed: 'Aktivace rozvrhu se nezdařila.',
        createTimeScheduleFailed: 'Vytvoření rozvrhu se nezdařilo.',
        updateTimeScheduleFailed: 'Aktualizace rozvrhu se nezdařila.',
        deleteTimeScheduleFailed: 'Smazání rozvrhu se nezdařilo.',
        setActiveTimeScheduleSuccess: 'Rozvrh úspěšně změněn.',
        createTimeScheduleSuccess: 'Rozvrh úspěšně vytvořen.',
        updateTimeScheduleSuccess: 'Rozvrh úspěšně aktualizován.',
        deleteTimeScheduleSuccess: 'Rozvrh úspěšně smazán.',
        editModal: {
            cancelConfirmation: {
                heading: 'Zrušit úpravy',
                text: 'Opravdu chcete zrušit všechny změny?'
            }
        },
        confirmActivateModal: {
            heading: 'Aktivovat rozvrh',
            text: 'Opravdu chcete aktivovat tento rozvrh?'
        }
    },
    gallery: {
        noPhotodocumentation: 'Doposud žádné obrázky.',
        addPhotos: 'Přidat obrázky',
        takePhoto: 'Vyfotit',
        imageUploadSuccess: 'Obrázky úspěšně nahrány.',
        imageUploadFailed: 'Nahrávání obrázků se nezdařilo.',
        imageUploadPartiallyFailed: 'Nepodařilo se nahrát {failedCount} z {totalCount} obrázků.',
        imageUploadUnsupportedFormat: 'Nepodporovaný formát souboru. Použijte prosím jpg, png, heif nebo heic.',
        deleteConfirmationHeading: 'Odstranit obrázek',
        deleteConfirmationText: 'Opravdu chcete odstranit tento obrázek?',
        deleteImageSuccess: 'Obrázek úspěšně smazán.',
        deleteImageFailed: 'Odstranění obrázku se nezdařilo.',
        imageNameEditHeading: 'Název obrázku',
        imageNameEditText: 'Zadejte nový název obrázku.',
        imageNameEditLabel: 'Název obrázku',
        renameImageSuccess: 'Název obrázku úspěšně aktualizován.',
        renameImageFailed: 'Aktualizace názvu obrázku se nezdařila.'
    },
    deviceDeleteSuccess: 'Zařízení úspěšně odstraněno.',
    deviceDeleteFailure: 'Odstranění zařízení se nezdařilo.',
    deviceDeleteConfirmation: {
        heading: 'Odstanit zařízení',
        text: 'Opravdu chcete odstranit toto zařízení ({controller})?'
    },
    updateDevice: 'Úprava zařízení',
    deviceUpdateSuccess: 'Zařízení úspěšně aktualizováno.',
    deviceUpdateFailure: 'Úprava zařízení se nezdařila.',
    deviceUpdateControllerTaken: 'Úprava zařízení se nezdařila - jiné zařízení již má toto ID.',
    serviceSequenceInProgress: 'Probíhá servisní sekvence.',
    nextRun: 'Další spuštění',
    launch: 'Spustit',
    insufficientPermissionsToViewServiceSequence: 'Nedostatečná oprávnění k prohlížení servisní sekvence.'
};

