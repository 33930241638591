<template>
  <SetPermissionsPage
    :permissions="permissions"
    :pageTitle="$t('users.userPermissions')"
    :settablePermissions="companyPermissions"
    :detailPageName="'CompanyUserDetailPage'"
  />
</template>

<script>
import { companyUsers as companyUsersQuery } from '@/graphql/query/companyUsers'
import { companyPermissions } from '@/config/permissions'
import { mapGetters } from 'vuex'
import SetPermissionsPage from '@/views/SetPermissionsPage'

export default {
  name: 'UserPermissionsPage',
  components: { SetPermissionsPage },
  data: () => {
    return {
      permissions: [],
    }
  },
  apollo: {
    companyUsers: {
      query: companyUsersQuery,
      variables() {
        return {
          companyId: this.currentCompanyId,
        }
      },
      skip() {
        return !this.currentCompanyId
      },
      update(data) {
        this.permissions = data.company.users.map((user) => ({
          id: user.companyPermissions.id,
          name: `${user.firstName} ${user.lastName}`.trim().length
            ? `${user.firstName} ${user.lastName}`
            : user.email,
          userId: user.id,
          isAdminInSomeCompany: user.isAdminInSomeCompany,
          ...Object.fromEntries(
            Object.values(companyPermissions).map((permissionName) => [
              permissionName,
              user.companyPermissions[permissionName],
            ]),
          ),
        }))
        return data.company.users
      },
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    companyPermissions() {
      return companyPermissions
    },
  },
}
</script>
