<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menuOpen"
      :close-on-content-click="false"
      :nudge-left="50"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="time"
          placeholder="hh:mm"
          readonly
          filled
          hide-details
          dense
          class="input-field"
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="menuOpen"
        v-model="time"
        format="24hr"
        :allowed-hours="allowedHours"
        :allowed-minutes="allowedMinutes"
        full-width
        @click:minute="menuOpen = false"
        @click:hour="selectedHours = $event"
      ></v-time-picker>
    </v-menu>
  </div>
</template>
<script>
import { formatSecondsToTime } from '@/utils/timeSchedule'
export default {
  name: 'TimeScheduleTimeInput',
  props: {
    value: Number,
    schedule: Array,
    intervalId: Number,
    beginOrEnd: String,
    otherNewTime: Number,
    granularity: Number,
  },
  data() {
    return {
      time: formatSecondsToTime(this.value),
      menuOpen: false,
      allowedTimes: null,
      selectedHours: null,
    }
  },
  methods: {
    parseTime(input) {
      const [hours, minutes] = input.split(':')
      return hours * 60 * 60 + minutes * 60
    },
    computeAllowedTimes() {
      const allowedTimes = new Map()

      if (this.intervalId != null || this.otherNewTime != null) {
        let otherTime = this.otherNewTime
        if (otherTime == null) {
          const intervalIndex = this.schedule.findIndex(
            (interval) => interval.id === this.intervalId,
          )
          otherTime = this.schedule[intervalIndex][this.beginOrEnd === 'begin' ? 'end' : 'begin']
        }

        let timeToBeBiggerThen = 0
        let timeToBeLowerThen = 86400
        let timeToBeBiggerThenIsExclusive = false
        let timeToBeLowerThenIsExclusive = false
        if (this.beginOrEnd === 'begin') {
          timeToBeLowerThen = otherTime
          timeToBeLowerThenIsExclusive = true
          const previousInterval = this.schedule.findLast((interval) => interval.end < otherTime)
          if (previousInterval) {
            timeToBeBiggerThen = previousInterval.end
          }
        } else {
          timeToBeBiggerThen = otherTime
          timeToBeBiggerThenIsExclusive = true
          const nextInterval = this.schedule.find((interval) => interval.begin > otherTime)
          if (nextInterval) {
            timeToBeLowerThen = nextInterval.begin
          }
        }

        for (let h = 0; h <= 23; h++) {
          const start = h * 3600
          const end = (h + 1) * 3600 - this.granularity
          if (
            (timeToBeBiggerThenIsExclusive
              ? end > timeToBeBiggerThen
              : end >= timeToBeBiggerThen) &&
            (timeToBeLowerThenIsExclusive ? start < timeToBeLowerThen : start <= timeToBeLowerThen)
          ) {
            allowedTimes.set(h, [])
            for (let m = 0; m <= 60 - this.granularity / 60; m += this.granularity / 60) {
              const timeWithMinutes = h * 3600 + m * 60
              if (
                (timeToBeBiggerThenIsExclusive
                  ? timeWithMinutes > timeToBeBiggerThen
                  : timeWithMinutes >= timeToBeBiggerThen) &&
                (timeToBeLowerThenIsExclusive
                  ? timeWithMinutes < timeToBeLowerThen
                  : timeWithMinutes <= timeToBeLowerThen)
              ) {
                allowedTimes.get(h).push(m)
              }
            }
          }
        }
        // test for 24:00
        if (timeToBeLowerThen === 86400) {
          const minutes = allowedTimes.get(0)
          minutes ? minutes.push(0) : allowedTimes.set(0, [0])
        }

        this.allowedTimes = allowedTimes
      } else {
        // allowed times for first entry of new interval
        if (this.beginOrEnd === 'begin') {
          for (let s = 0, intervalIndex = 0; s < 86400; ) {
            if (intervalIndex < this.schedule.length && s >= this.schedule[intervalIndex].begin) {
              s = this.schedule[intervalIndex].end
              intervalIndex++
              continue
            } else {
              const currentHour = allowedTimes.get(Math.floor(s / 3600))
              if (currentHour) {
                currentHour.push((s % 3600) / 60)
              } else {
                allowedTimes.set(Math.floor(s / 3600), [(s % 3600) / 60])
              }
              s += this.granularity
            }
          }
        } else {
          for (let s = 86400, intervalIndex = this.schedule.length - 1; s > 0; ) {
            if (intervalIndex >= 0 && s <= this.schedule[intervalIndex].end) {
              s = this.schedule[intervalIndex].begin
              intervalIndex--
              continue
            } else {
              const currentHour = allowedTimes.get(Math.floor(s / 3600))
              if (currentHour) {
                currentHour.push((s % 3600) / 60)
              } else {
                allowedTimes.set(Math.floor(s / 3600), [(s % 3600) / 60])
              }
              s -= this.granularity
            }
          }
          // if 24:00 was added transform to 00:00
          if (allowedTimes.get(24)) {
            const minutes = allowedTimes.get(0)
            minutes ? minutes.push(0) : allowedTimes.set(0, [0])
            allowedTimes.delete(24)
          }
        }

        this.allowedTimes = allowedTimes
      }
    },
  },
  computed: {
    allowedHours() {
      if (this.allowedTimes === undefined) {
        return undefined // allow everything
      }
      if (this.allowedTimes) {
        return [...this.allowedTimes.keys()]
      }
      return []
    },
    allowedMinutes() {
      if (this.allowedTimes === undefined) {
        return undefined // allow everything
      }
      if (!this.allowedTimes) {
        return []
      }
      if (this.time) {
        const [hours] = this.time.split(':')
        return this.allowedTimes.get(Number(hours))
      }
      return this.allowedTimes.get(this.selectedHours)
    },
  },
  watch: {
    value() {
      this.time = formatSecondsToTime(this.value)
    },
    time() {
      // if you already have some set the time and then you select hours,
      // it is possible that previously set minutes are not allowed with this changed hour,
      // so we change minutes to minimal allowed for new hour
      if (this.time && this.allowedTimes) {
        const [hours, minutes] = this.time.split(':')
        if (
          this.allowedTimes.get(Number(hours)) &&
          !this.allowedTimes.get(Number(hours)).includes(Number(minutes))
        ) {
          const firstAllowedMinutes = this.allowedTimes.get(Number(hours))[0]
          this.time = `${hours}:${firstAllowedMinutes < 10 ? '0' : ''}${firstAllowedMinutes}`
        }
      }
    },
    menuOpen() {
      if (this.menuOpen) {
        if (this.value != null) {
          this.$emit('calendarScrollTo', this.value)
        }
        this.computeAllowedTimes()
      } else if (this.time) {
        if (this.time === '00:00' && this.beginOrEnd === 'end') {
          this.$emit('input', 86400)
          this.$emit('calendarScrollTo', 86400)
        } else {
          this.$emit('input', this.parseTime(this.time))
          this.$emit('calendarScrollTo', this.parseTime(this.time))
        }
      }
    },
    schedule() {
      // if this is time input for new interval and other side of interval is not yet set,
      // it is possible to change existing intervals in a way that value in this input becomes invalid,
      // so set it to null in this case
      if (this.intervalId == null && this.otherNewTime == null && this.time) {
        this.computeAllowedTimes()
        const [hours, minutes] = this.time.split(':')
        if (
          !this.allowedHours.includes(Number(hours)) ||
          !this.allowedMinutes.includes(Number(minutes))
        ) {
          this.$emit('input', null)
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.input-field {
  width: 72px;

  @media screen and (max-width: 417px) {
    width: 58px;
  }
}
</style>
