<template>
  <div class="my-account-page-wrapper">
    <h2>{{ $t('global.myAccount') }}</h2>
    <v-form class="mt-6" @submit.prevent="updateUserData()">
      <div class="form-wrapper mb-2">
        <v-text-field
          v-model="$store.state.user.currentUser.email"
          :label="$t('global.email')"
          required
          filled
          disabled
        ></v-text-field>
        <v-text-field
          v-model="userDataForm.firstName"
          :error-messages="getErrors('userDataForm.firstName')"
          :label="$t('global.firstName')"
          required
          @input="$v.userDataForm.firstName.$touch()"
          @blur="$v.userDataForm.firstName.$touch()"
          filled
        ></v-text-field>
        <v-text-field
          v-model="userDataForm.lastName"
          :error-messages="getErrors('userDataForm.lastName')"
          :label="$t('global.lastName')"
          required
          @input="$v.userDataForm.lastName.$touch()"
          @blur="$v.userDataForm.lastName.$touch()"
          filled
        ></v-text-field>
        <v-text-field
          v-model="userDataForm.phoneNumber"
          :error-messages="getErrors('userDataForm.phoneNumber')"
          :label="$t('global.phoneNumber')"
          @input="$v.userDataForm.phoneNumber.$touch()"
          @blur="$v.userDataForm.phoneNumber.$touch()"
          filled
        ></v-text-field>
      </div>
      <v-btn color="primary" class="mr-5 mb-12 mt-1" type="submit">{{ $t('global.save') }}</v-btn>
    </v-form>

    <h3>{{ $t('global.changePassword') }}</h3>
    <v-form class="mt-6" @submit.prevent="changePassword()">
      <div class="form-wrapper mb-2">
        <v-text-field
          v-model="changePasswordForm.currentPassword"
          :error-messages="getErrors('changePasswordForm.currentPassword')"
          :label="$t('global.currentPassword')"
          required
          @input="$v.changePasswordForm.currentPassword.$touch()"
          @blur="$v.changePasswordForm.currentPassword.$touch()"
          filled
          :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showCurrentPassword ? 'text' : 'password'"
          @click:append="showCurrentPassword = !showCurrentPassword"
        ></v-text-field>
        <v-text-field
          v-model="changePasswordForm.newPassword"
          :error-messages="getErrors('changePasswordForm.newPassword')"
          :label="$t('global.newPassword')"
          required
          @input="$v.changePasswordForm.newPassword.$touch()"
          @blur="$v.changePasswordForm.newPassword.$touch()"
          filled
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showNewPassword ? 'text' : 'password'"
          @click:append="showNewPassword = !showNewPassword"
        ></v-text-field>
        <v-text-field
          v-model="changePasswordForm.newPasswordAgain"
          :error-messages="getErrors('changePasswordForm.newPasswordAgain')"
          :label="$t('global.newPasswordAgain')"
          @input="$v.changePasswordForm.newPasswordAgain.$touch()"
          @blur="$v.changePasswordForm.newPasswordAgain.$touch()"
          filled
          :append-icon="showNewPasswordAgain ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showNewPasswordAgain ? 'text' : 'password'"
          @click:append="showNewPasswordAgain = !showNewPasswordAgain"
        ></v-text-field>
      </div>
      <v-btn color="primary" class="mr-5 mb-12 mt-1" type="submit">{{ $t('global.save') }}</v-btn>
    </v-form>

    <h3 class="mb-6">{{ $t('global.chargersApiKey') }}</h3>
    <template v-if="newlyGeneratedKey">
      <v-alert type="warning" outlined border="left" class="mt-3 save-api-key-alert">{{
        $t('global.copyApiKeyWarning')
      }}</v-alert>
      <div class="d-flex align-center mb-4">
        <p class="api-key-field">{{ newlyGeneratedKey }}</p>
        <v-btn @click="copyApiKey()" icon class="ml-4"><v-icon>mdi-content-copy</v-icon></v-btn>
      </div>
    </template>
    <p v-else-if="hasChargerApiKey">{{ $t('global.hasChargerApiKey') }}</p>
    <p v-else>{{ $t('global.noChargerApiKey') }}</p>
    <div class="d-flex mb-8 mt-3">
      <v-btn
        color="primary"
        class="mr-3"
        @click="hasChargerApiKey ? (confirmGenerateApiKeyModalOpen = true) : generateNewApiKey()"
        >{{ $t('global.generateNewApiKey') }}</v-btn
      >
      <v-btn
        v-if="hasChargerApiKey"
        @click="confirmDeleteApiKeyModalOpen = true"
        color="error"
        class="ml-2"
        >{{ $t('global.deleteApiKey') }}</v-btn
      >
    </div>

    <ConfirmationDialog
      v-if="hasChargerApiKey"
      v-model="confirmGenerateApiKeyModalOpen"
      :heading="$t('global.generateNewApiKeyConfirmationModal.heading')"
      :text="$t('global.generateNewApiKeyConfirmationModal.text')"
      :action="generateNewApiKey"
      :confirmText="$t('global.confirmGenerateNew')"
    />
    <ConfirmationDialog
      v-if="hasChargerApiKey"
      v-model="confirmDeleteApiKeyModalOpen"
      :heading="$t('global.deleteApiKeyConfirmationModal.heading')"
      :text="$t('global.deleteApiKeyConfirmationModal.text')"
      :action="deleteApiKey"
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import vuelidateErrorsExtractor from '@/mixins/vuelidateErrorsExtractor'
import { required, sameAs } from 'vuelidate/lib/validators'
import { isPhoneNumber } from '@/utils/phoneNumberValidator'
import { userUpdate } from '@/graphql/mutations/userUpdate'
import { chargerApiKeyCreate } from '@/graphql/mutations/chargerApiKeyCreate'
import { chargerApiKeyDelete } from '@/graphql/mutations/chargerApiKeyDelete'
import { hasChargerApiKey } from '@/graphql/query/hasChargerApiKey'
import { Auth } from 'aws-amplify'
import ConfirmationDialog from '@/components/ConfirmationDialog'

export default {
  name: 'MyAccountPage',
  mixins: [validationMixin, vuelidateErrorsExtractor],
  components: { ConfirmationDialog },
  data() {
    return {
      userDataForm: {
        firstName: this.$store.state.user.currentUser.firstName,
        lastName: this.$store.state.user.currentUser.lastName,
        phoneNumber: this.$store.state.user.currentUser.phoneNumber,
      },
      changePasswordForm: {
        currentPassword: '',
        newPassword: '',
        newPasswordAgain: '',
      },
      showCurrentPassword: false,
      showNewPassword: false,
      showNewPasswordAgain: false,
      confirmGenerateApiKeyModalOpen: false,
      confirmDeleteApiKeyModalOpen: false,
      newlyGeneratedKey: null,
    }
  },
  validations: {
    userDataForm: {
      firstName: { required },
      lastName: { required },
      phoneNumber: { isPhoneNumber },
    },
    changePasswordForm: {
      currentPassword: { required },
      newPassword: { required },
      newPasswordAgain: { required, sameAsPassword: sameAs('newPassword') },
    },
  },
  apollo: {
    hasChargerApiKey: {
      query: hasChargerApiKey,
      update(data) {
        return data.me.hasChargerApiKey
      },
    },
  },
  methods: {
    async updateUserData() {
      this.$v.userDataForm.$touch()
      if (!this.$v.userDataForm.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: userUpdate,
            variables: {
              userId: this.currentUserId,
              userData: {
                firstName: this.userDataForm.firstName,
                lastName: this.userDataForm.lastName,
                phoneNumber:
                  this.userDataForm.phoneNumber === '' ? null : this.userDataForm.phoneNumber,
              },
            },
            refetchQueries: ['me'],
          })

          this.$toast.success(this.$t('global.dataUpdateSuccess'))
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.dataUpdateFailure'))
        }
      }
    },
    async changePassword() {
      this.$v.changePasswordForm.$touch()
      if (!this.$v.changePasswordForm.$invalid) {
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser()
          await Auth.changePassword(
            cognitoUser,
            this.changePasswordForm.currentPassword,
            this.changePasswordForm.newPassword,
          )

          this.$toast.success(this.$t('global.passwordChangeAction.success'))
          this.changePasswordForm.currentPassword = ''
          this.changePasswordForm.newPassword = ''
          this.changePasswordForm.newPasswordAgain = ''
          this.$v.changePasswordForm.$reset()
        } catch (error) {
          console.error(error)
          if (error.code === 'NotAuthorizedException') {
            this.$toast.error(this.$t('global.passwordChangeAction.notAuthorized'))
          } else if (error.message) {
            this.$toast.error(`${this.$t('global.passwordChangeAction.failure')}: ${error.message}`)
          } else {
            this.$toast.error(this.$t('global.passwordChangeAction.failure'))
          }
        }
      }
    },
    async generateNewApiKey() {
      try {
        const response = await this.$apollo.mutate({
          mutation: chargerApiKeyCreate,
          variables: {},
          refetchQueries: ['hasChargerApiKey'],
        })

        this.newlyGeneratedKey = response.data.chargerApiKeyCreate.chargerApiKey

        this.$toast.success(this.$t('global.generateNewApiKeyAction.success'))
        this.confirmGenerateApiKeyModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.generateNewApiKeyAction.failure'))
      }
    },
    async deleteApiKey() {
      try {
        await this.$apollo.mutate({
          mutation: chargerApiKeyDelete,
          variables: {},
          refetchQueries: ['hasChargerApiKey'],
        })

        this.newlyGeneratedKey = null
        this.$toast.success(this.$t('global.deleteApiKeyAction.success'))
        this.confirmDeleteApiKeyModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.deleteApiKeyAction.failure'))
      }
    },
    copyApiKey() {
      navigator.clipboard.writeText(this.newlyGeneratedKey).then(
        () => {
          this.$toast.success(
            `${this.$t('global.chargersApiKey')} ${this.$t('global.copiedToClipboard')}`,
          )
        },
        (err) => {
          console.error(err)
          this.$toast.error(this.$t('global.copyToClipboardFailed'))
        },
      )
    },
    onBeforeUnload() {
      if (this.hasUnsavedChanges) {
        // modern browsers will ignore this message and will show default
        return this.$t('global.unsavedChanges')
      }
    },
  },
  computed: {
    ...mapGetters('user', ['currentUserId']),
    ...mapGetters(['hasUnsavedChanges']),
    hasDataChanged() {
      return (
        this.userDataForm.firstName !== this.$store.state.user.currentUser.firstName ||
        this.userDataForm.lastName !== this.$store.state.user.currentUser.lastName ||
        this.userDataForm.phoneNumber !== this.$store.state.user.currentUser.phoneNumber ||
        this.changePasswordForm.currentPassword !== '' ||
        this.changePasswordForm.newPassword !== '' ||
        this.changePasswordForm.newPasswordAgain !== ''
      )
    },
  },
  watch: {
    hasDataChanged() {
      this.$store.commit('setHasUnsavedChanges', this.hasDataChanged)
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('global.unsavedChanges'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload
  },
  destroyed() {
    window.onbeforeunload = undefined
    this.$store.commit('setHasUnsavedChanges', false)
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.my-account-page-wrapper {
  padding: 15px;
}

.form-wrapper {
  max-width: 330px;
}

.save-api-key-alert {
  max-width: 330px;
}

.api-key-field {
  max-width: 330px;
  word-break: break-all;
  font-size: 14px;
  padding: 10px;
  border-radius: 3px;
  background-color: @color-brand-highlight;
  color: @color-brand-2;
  margin-bottom: 0;
}
</style>
