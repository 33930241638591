<template>
  <div v-bind="wrapperProps">
    <v-text-field
      label="Enter Value"
      v-model="inputValue"
      :rules="inputRules"
      v-bind="textInputProps"
      :disabled="isSending"
    ></v-text-field>
    <v-btn @click="confirmInput" :disabled="!inputValue || isSending" color="primary">
      Confirm
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ServiceSequenceTextInput',
  props: {
    textInputProps: Object,
    wrapperProps: Object,
  },
  inject: ['reactive'],
  data() {
    return {
      inputValue: '',
      inputRules: [(v) => !!v || 'Input is required'],
      isSending: false,
    }
  },
  methods: {
    async confirmInput() {
      this.isSending = true
      try {
        // Send the command along with the inputValue
        await this.reactive.handleCommand(this.textInputProps.command, this.inputValue)
      } catch (error) {
        console.error('Error sending input:', error)
      } finally {
        this.isSending = false
      }
    },
  },
}
</script>
