<template>
  <div
    class="wrapper"
    :class="{
      unavailable: isUnavailable,
      'forbidden-by-user': control.forbiddenByUser && !isUnavailable,
      'turned-on': currentValue,
    }"
  >
    <!-- Disable forbidden by user for now -->
    <!-- <v-dialog v-if="!isUnavailable && canEdit" v-model="isEditDialogOpen" max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon class="edit-btn" v-bind="attrs" v-on="on">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline">{{ control.label }}</v-card-title>
        <v-card-text>
          <v-checkbox
            class="mt-3"
            v-model="currentForbiddenByUserValue"
            :label="$t('device.forbidden')"
            hide-details
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="isEditDialogOpen = false">
            {{ $t('global.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="setForbiddenByUser(), (isEditDialogOpen = false)"
          >
            {{ $t('global.set') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <div class="title-wrapper mt-2">
      <v-icon v-if="control.icon" size="30">{{ `$${control.icon}` }}</v-icon>
      <p>{{ control.label }}</p>
    </div>
    <div v-if="isUnavailable" class="value-wrapper">
      <span>{{ $t('device.unavailable') }}</span>
    </div>
    <div v-else-if="control.forbiddenByUser" class="value-wrapper">
      <span>{{ $t('device.forbiddenByUser') }}</span>
    </div>
    <div v-else class="switch-wrapper value-wrapper">
      <span>{{ currentValue ? $t('global.on') : $t('global.off') }}</span>
      <v-switch
        v-if="control.settable"
        v-model="currentValue"
        :disabled="!canEdit"
        inset
        hide-details
        @change="valueChanged"
        :loading="pendingStateChange"
      ></v-switch>
    </div>
  </div>
</template>

<script>
import {
  deviceControl,
  deviceControlUpdateForbiddenByUser,
} from '@/graphql/mutations/deviceControl'

export default {
  name: 'ToggleControl',
  props: {
    control: Object,
    deviceId: String,
    isOffline: Boolean,
    canEdit: Boolean,
  },
  data() {
    return {
      currentValue: this.control.value === 'true',
      currentForbiddenByUserValue: this.control.forbiddenByUser,
      isEditDialogOpen: false,
      isSetting: false,
      pendingStateChange: false,
    }
  },
  computed: {
    isUnavailable() {
      return this.isOffline || this.control.forbiddenByTechnology
    },
  },
  methods: {
    async valueChanged(newValue) {
      try {
        this.pendingStateChange = true

        await this.$apollo.mutate({
          mutation: deviceControl,
          variables: {
            data: {
              deviceId: this.deviceId,
              name: this.control.name,
              value: newValue ? 'ON' : 'OFF',
            },
          },
        })

        this.$emit('controlChanged')

        // temporary solution, it can happen that deviceControl does not fail, but will immediately switch back to previous value,
        // in this case toggle was in wrong state, this will be resolved when forbiddenByTechnology will be implemented
        setTimeout(() => {
          this.pendingStateChange = false
          this.currentValue = this.control.value === 'true'
        }, 7000)
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('device.controlUpdateFail'))
        this.pendingStateChange = false
      }
    },
    async setForbiddenByUser() {
      if (this.currentForbiddenByUserValue !== this.control.forbiddenByUser) {
        this.isSetting = true
        try {
          await this.$apollo.mutate({
            mutation: deviceControlUpdateForbiddenByUser,
            variables: {
              deviceId: this.deviceId,
              controlName: this.control.name,
              forbiddenByUser: this.currentForbiddenByUserValue,
            },
          })

          this.$emit('controlChanged')
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('device.controlUpdateFail'))
          this.currentForbiddenByUserValue = this.control.forbiddenByUser
        }
        this.isSetting = false
      }
    },
  },
  watch: {
    'control.value'() {
      this.currentValue = this.control.value === 'true'
      this.pendingStateChange = false
    },
    isEditDialogOpen() {
      if (!this.isEditDialogOpen && !this.isSetting) {
        this.currentForbiddenByUserValue = this.control.forbiddenByUser
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.wrapper {
  position: relative;
  background-color: fade(@color-brand-highlight, 50%);
  border-radius: 3px;
  padding: 5px 5px 13px 18px;
  transition: 0.3s;

  .title-wrapper {
    display: flex;
    align-items: center;

    .v-icon {
      margin-right: 16px;
      color: @color-brand-2;
    }

    p {
      font-size: 12px;
      opacity: 0.5;
      color: @color-brand-2;
      margin-bottom: 0;
    }
  }

  .edit-btn {
    float: right;

    i {
      color: @color-brand !important;
    }
  }

  .value-wrapper {
    margin-top: 16px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;

    span {
      font-size: 14px;
    }
  }

  .switch-wrapper {
    span {
      font-weight: bold;
      color: @color-brand;
    }
  }

  &.turned-on {
    background-color: @color-brand-highlight;

    .switch-wrapper span {
      color: @color-brand-2;
    }
  }

  &.unavailable {
    background-color: fade(#ebebeb, 50%);

    .v-icon {
      color: gray;
    }

    p,
    span {
      color: gray;
    }
  }

  &.forbidden-by-user {
    background-color: fade(@color-danger, 20%);

    .v-icon {
      color: @color-danger;
    }

    p,
    span {
      color: @color-danger;
    }
  }
}

/deep/.v-input {
  margin: 0;
  padding-top: 0 !important;

  .v-input--switch__track {
    color: @color-brand !important;
    opacity: 1;
  }
}

/deep/.primary--text .v-input__slot {
  .v-input--switch__track {
    color: @color-brand-2 !important;
    opacity: 1;
  }

  .v-input--switch__thumb {
    color: white !important;
  }
}
</style>
