import gql from 'graphql-tag'

export const deviceTypeInfo = gql`
  query deviceTypeInfo($deviceId: ID!) {
    device(deviceId: $deviceId) {
      id
      typeInfo {
        name
        hasAddress
        hasContactInfo
      }
    }
  }
`
