`
<template>
  <div>
    <div v-if="$apollo.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else class="content-wrapper">
      <div class="settings-grid">
        <div>
          <h2>{{ $t('global.emailNotifications') }}</h2>
          <template v-if="form.emailAddresses.length">
            <div
              v-for="(emailAddress, index) of form.emailAddresses"
              class="d-flex align-center"
              :key="index"
            >
              <v-text-field
                v-model="form.emailAddresses[index]"
                :error-messages="getErrors(`form.emailAddresses.$each.${index}`)"
                @input="$v.form.emailAddresses.$each[index].$touch()"
                @blur="$v.form.emailAddresses.$each[index].$touch()"
                filled
                dense
                type="email"
                style="width: 330px"
              ></v-text-field>
              <v-btn
                @click="form.emailAddresses.splice(index, 1)"
                color="error"
                class="ml-1 mb-5"
                icon
                depressed
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>
          </template>
          <template v-else>
            <p>{{ $t('global.emailNotificationsAddFirst') }}</p>
          </template>
          <v-btn @click="form.emailAddresses.push('')" depressed>{{ $t('global.add') }}</v-btn>
        </div>
        <div v-if="permissionChecker([], eligibilities.SMS_NOTIFICATIONS)">
          <h2>{{ $t('global.smsNotifications') }}</h2>
          <template v-if="form.phoneNumbers.length">
            <div
              v-for="(phoneNumber, index) of form.phoneNumbers"
              class="d-flex align-center"
              :key="index"
            >
              <v-text-field
                v-model="form.phoneNumbers[index]"
                :error-messages="getErrors(`form.phoneNumbers.$each.${index}`)"
                @input="$v.form.phoneNumbers.$each[index].$touch()"
                @blur="$v.form.phoneNumbers.$each[index].$touch()"
                filled
                dense
                type="tel"
                style="width: 330px"
              ></v-text-field>
              <v-btn
                @click="form.phoneNumbers.splice(index, 1)"
                color="error"
                class="ml-1 mb-5"
                icon
                depressed
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>
          </template>
          <template v-else>
            <p>{{ $t('global.smsNotificationsAddFirst') }}</p>
          </template>
          <v-btn @click="form.phoneNumbers.push('')" depressed>{{ $t('global.add') }}</v-btn>
        </div>
        <div>
          <h2>{{ $t('global.pushNotifications') }}</h2>
          <p>{{ $t('global.pushNotificationsText') }}</p>
          <v-switch
            v-model="form.pushNotifications"
            :label="form.pushNotifications ? $t('global.on') : $t('global.off')"
          ></v-switch>
        </div>
      </div>
      <v-btn
        @click="updateNotificationSettings()"
        :disabled="!hasDataChanged"
        color="primary"
        class="mt-5"
        depressed
        >{{ $t('global.save') }}</v-btn
      >
    </div>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import vuelidateErrorsExtractor from '@/mixins/vuelidateErrorsExtractor'
import { required, email } from 'vuelidate/lib/validators'
import { isPhoneNumber } from '@/utils/phoneNumberValidator'
import { deviceNotificationSettings } from '@/graphql/query/deviceNotificationSettings'
import { deviceSetEventNotifications } from '@/graphql/mutations/deviceSetEventNotifications'
import { mapGetters } from 'vuex'
import isEqual from 'lodash/isEqual'
import { eligibilities } from '@/config/eligibilities'

export default {
  name: 'NotificationSettingsTab',
  mixins: [validationMixin, vuelidateErrorsExtractor],
  props: {
    device: Object,
    permissionChecker: Function,
  },
  data() {
    return {
      form: {
        emailAddresses: [],
        phoneNumbers: [],
        pushNotifications: false,
      },
    }
  },
  validations: {
    form: {
      emailAddresses: { $each: { required, email } },
      phoneNumbers: { $each: { required, isPhoneNumber } },
    },
  },
  apollo: {
    deviceNotificationSettings: {
      query: deviceNotificationSettings,
      variables() {
        return {
          deviceId: this.device.id,
        }
      },
      update(data) {
        return data.device
      },
    },
  },
  methods: {
    async updateNotificationSettings() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: deviceSetEventNotifications,
            variables: {
              deviceId: this.device.id,
              pushNotifications: this.form.pushNotifications,
              phoneNumbers: this.permissionChecker([], eligibilities.SMS_NOTIFICATIONS)
                ? this.form.phoneNumbers
                : null,
              emailAddresses: this.form.emailAddresses,
            },
            refetchQueries: ['deviceNotificationSettings'],
          })
          this.$toast.success(this.$t('global.deviceSetEventNotificationsAction.success'))
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.deviceSetEventNotificationsAction.failure'))
        }
      }
    },
    onBeforeUnload() {
      if (this.hasUnsavedChanges) {
        // modern browsers will ignore this message and will show default
        return this.$t('global.unsavedChanges')
      }
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    ...mapGetters(['hasUnsavedChanges']),
    hasDataChanged() {
      if (!this.deviceNotificationSettings) {
        return false
      }
      return !isEqual(this.form, {
        emailAddresses: this.deviceNotificationSettings.emailAddressesForNotifications,
        phoneNumbers: this.deviceNotificationSettings.phoneNumbersForNotifications,
        pushNotifications: this.deviceNotificationSettings.pushNotificationsEnabled,
      })
    },
    eligibilities() {
      return eligibilities
    },
  },
  watch: {
    deviceNotificationSettings() {
      this.form = {
        emailAddresses: [...this.deviceNotificationSettings.emailAddressesForNotifications],
        phoneNumbers: [...this.deviceNotificationSettings.phoneNumbersForNotifications],
        pushNotifications: this.deviceNotificationSettings.pushNotificationsEnabled,
      }
    },
    hasDataChanged() {
      this.$store.commit('setHasUnsavedChanges', this.hasDataChanged)
    },
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload
  },
  destroyed() {
    window.onbeforeunload = undefined
    this.$store.commit('setHasUnsavedChanges', false)
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.content-wrapper {
  .settings-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 25px;
  }
}

h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}
</style>
