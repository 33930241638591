import gql from 'graphql-tag'

export const generalEligibilityCreate = gql`
  mutation generalEligibilityCreate(
    $name: NonEmptyString!
    $type: EligibilityTypeEnum!
    $defaultDefaultValueValue: Boolean!
    $defaultDefaultAvailabilityValue: NonEmptyString!
    $defaultIsPremiumValue: Boolean!
    $defaultProvisionTypeValue: EligibilityProvisionTypeEnum!
  ) {
    generalEligibilityCreate(
      input: {
        name: $name
        type: $type
        defaultDefaultValueValue: $defaultDefaultValueValue
        defaultDefaultAvailabilityValue: $defaultDefaultAvailabilityValue
        defaultIsPremiumValue: $defaultIsPremiumValue
        defaultProvisionTypeValue: $defaultProvisionTypeValue
      }
    ) {
      success
    }
  }
`
