import gql from 'graphql-tag'
import { allowedLanguages } from '@/translations'

export const eventDetectionPointAdmin = gql`
  query eventDetectionPointAdmin($id: ID!, $lang: String) {
    eventDetectionPointAdmin(detectionPointId: $id) {
      id
      name
      ${allowedLanguages
        .map((lang) => `${lang}Description: description(lang: "${lang}")`)
        .join('\n')}
      eventCodes {
        id
        name
        description(lang: $lang)
      }
    }
  }
`
