<template>
  <v-form>
    <v-text-field
      v-model="form.name"
      :error-messages="getErrors('form.name')"
      :label="$t('global.id')"
      @input="$v.form.name.$touch()"
      @blur="$v.form.name.$touch()"
      filled
      style="width: 330px"
    ></v-text-field>
    <v-select
      :items="detectionPoints"
      :error-messages="getErrors('form.eventDetectionPointId')"
      :label="$t('global.adminEventCode.detectionPoint')"
      item-text="name"
      item-value="id"
      v-model="form.eventDetectionPointId"
      @input="$v.form.eventDetectionPointId.$touch()"
      @blur="$v.form.eventDetectionPointId.$touch()"
      filled
      style="width: 330px"
    ></v-select>
    <v-select
      :items="errorSeverities"
      :error-messages="getErrors('form.severity')"
      :label="$t('global.adminEventCode.severity')"
      v-model="form.severity"
      @input="$v.form.severity.$touch()"
      @blur="$v.form.severity.$touch()"
      filled
      style="width: 330px"
    ></v-select>
    <v-textarea
      v-for="lang of allowedLanguages"
      :key="lang"
      v-model="form.descriptionTranslations[lang]"
      :error-messages="getErrors(`form.descriptionTranslations.${lang}`)"
      @input="$v.form.descriptionTranslations[lang].$touch()"
      @blur="$v.form.descriptionTranslations[lang].$touch()"
      filled
      style="width: 600px"
      counter="255"
      rows="2"
    >
      <template v-slot:label>
        {{ $t('global.adminEventCode.description') }}
        <v-icon class="ml-1" style="vertical-align: middle">{{ `$${lang}` }}</v-icon>
      </template>
    </v-textarea>
    <v-select
      :items="notificationClasses"
      :error-messages="getErrors('form.defaultNotificationClass')"
      :label="$t('global.defaultNotificationClass')"
      v-model="form.defaultNotificationClass"
      @input="$v.form.defaultNotificationClass.$touch()"
      @blur="$v.form.defaultNotificationClass.$touch()"
      filled
      style="width: 330px"
    >
      <template v-slot:selection="{ item }">
        <NotificationClass class="mt-2" :notificationClass="item" />
      </template>
      <template v-slot:item="{ active, item, attrs, on }">
        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
          <NotificationClass :notificationClass="item" />
        </v-list-item>
      </template>
    </v-select>
    <v-textarea
      v-model="form.detectionMechanism"
      :label="$t('global.adminEventCode.detectionMechanism')"
      :error-messages="getErrors('form.detectionMechanism')"
      @input="$v.form.detectionMechanism.$touch()"
      @blur="$v.form.detectionMechanism.$touch()"
      filled
      style="width: 330px"
      counter="255"
    ></v-textarea>
    <v-textarea
      v-model="form.terminationPoint"
      :label="$t('global.adminEventCode.terminationPoint')"
      :error-messages="getErrors('form.terminationPoint')"
      @input="$v.form.terminationPoint.$touch()"
      @blur="$v.form.terminationPoint.$touch()"
      filled
      style="width: 330px"
      counter="255"
    ></v-textarea>
    <v-textarea
      v-model="form.terminationMechanism"
      :label="$t('global.adminEventCode.terminationMechanism')"
      :error-messages="getErrors('form.terminationMechanism')"
      @input="$v.form.terminationMechanism.$touch()"
      @blur="$v.form.terminationMechanism.$touch()"
      filled
      style="width: 330px"
      counter="255"
    ></v-textarea>
    <v-textarea
      v-model="form.actionUponDetection"
      :label="$t('global.adminEventCode.actionUponDetection')"
      :error-messages="getErrors('form.actionUponDetection')"
      @input="$v.form.actionUponDetection.$touch()"
      @blur="$v.form.actionUponDetection.$touch()"
      filled
      style="width: 330px"
      counter="255"
    ></v-textarea>
    <v-checkbox
      class="mt-3"
      v-model="form.disabled"
      :label="$t('global.adminEventCode.disabled')"
    ></v-checkbox>
  </v-form>
</template>
<script>
import { validationMixin } from 'vuelidate'
import vuelidateErrorsExtractor from '@/mixins/vuelidateErrorsExtractor'
import { required, maxLength } from 'vuelidate/lib/validators'
import { eventDetectionPointsAdmin } from '@/graphql/query/eventDetectionPointsAdmin'
import { notificationClassesEnumValues } from '@/graphql/query/notificationClassesEnumValues'
import { errorsConfig } from '@/config/errorsConfig'
import { allowedLanguages } from '@/translations'
import NotificationClass from '@/components/NotificationDispatcher/NotificationClass'

export default {
  name: 'EventCodeForm',
  mixins: [validationMixin, vuelidateErrorsExtractor],
  components: { NotificationClass },
  props: {
    value: Object,
  },
  data() {
    return {
      form: {
        name: null,
        eventDetectionPointId: null,
        severity: null,
        disabled: false,
        defaultNotificationClass: null,
        detectionMechanism: null,
        terminationPoint: null,
        terminationMechanism: null,
        actionUponDetection: null,
        descriptionTranslations: Object.fromEntries(allowedLanguages.map((lang) => [lang, null])),
      },
    }
  },
  validations: {
    form: {
      name: { required },
      eventDetectionPointId: { required },
      severity: { required },
      disabled: { required },
      defaultNotificationClass: { required },
      detectionMechanism: { maxLength: maxLength(255) },
      terminationPoint: { maxLength: maxLength(255) },
      terminationMechanism: { maxLength: maxLength(255) },
      actionUponDetection: { maxLength: maxLength(255) },
      descriptionTranslations: Object.fromEntries(
        allowedLanguages.map((lang) => [lang, { maxLength: maxLength(255) }]),
      ),
    },
  },
  apollo: {
    detectionPoints: {
      query: eventDetectionPointsAdmin,
      update(data) {
        return data.eventDetectionPointsAdmin
      },
    },
    notificationClasses: {
      query: notificationClassesEnumValues,
      update(data) {
        return data.__type.enumValues.map((value) => value.name)
      },
    },
  },
  methods: {
    reset() {
      // called from parent
      this.form = {
        name: null,
        eventDetectionPointId: null,
        severity: null,
        disabled: false,
        defaultNotificationClass: null,
        detectionMechanism: null,
        terminationPoint: null,
        terminationMechanism: null,
        actionUponDetection: null,
        descriptionTranslations: Object.fromEntries(allowedLanguages.map((lang) => [lang, null])),
      }

      this.$v.$reset()
    },
  },
  computed: {
    errorSeverities() {
      return Object.keys(errorsConfig)
    },
    allowedLanguages() {
      return allowedLanguages
    },
  },
}
</script>
