import gql from 'graphql-tag'

export const notificationClassesEnumValues = gql`
  query notificationClassesEnumValues {
    __type(name: "NotificationClassEnum") {
      enumValues {
        name
      }
    }
  }
`
