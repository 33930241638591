<template>
  <div class="pa-6">
    <h1 class="mb-5">{{ $t('global.companyManagementMenu.settings') }}</h1>
    <div v-if="$apollo.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else>
      <v-text-field
        v-model="companyForm.name"
        :error-messages="getErrors('companyForm.name')"
        :label="$t('global.name')"
        @input="$v.companyForm.name.$touch()"
        @blur="$v.companyForm.name.$touch()"
        filled
        style="width: 330px"
      ></v-text-field>
      <v-select
        :items="localeOptions"
        v-model="companyForm.defaultLanguage"
        :label="$t('global.companyDefaultLanguage')"
        filled
        style="width: 330px"
      ></v-select>
      <v-select
        :items="Intl.supportedValuesOf('timeZone')"
        v-model="companyForm.timezone"
        :label="$t('global.timezone')"
        filled
        style="width: 330px"
      ></v-select>

      <div class="mt-2 mb-12 d-flex flex-wrap">
        <v-btn
          @click="updateCompanyData()"
          :block="showBlockButtons"
          class="mr-2"
          :class="{ 'mb-1': showBlockButtons }"
          :disabled="!hasCompanyFormChanged"
          color="primary"
          depressed
          >{{ $t('global.save') }}</v-btn
        >
        <v-btn
          v-if="hasCompanyFormChanged"
          @click="resetCompanyForm()"
          :block="showBlockButtons"
          depressed
          >{{ $t('global.cancel') }}</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
import { company } from '@/graphql/query/company'
import { companyUpdate } from '@/graphql/mutations/companyUpdate'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import vuelidateErrorsExtractor from '@/mixins/vuelidateErrorsExtractor'
import { required } from 'vuelidate/lib/validators'
import { allowedLanguages } from '@/translations'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

export default {
  name: 'CompanySettingsPage',
  mixins: [validationMixin, vuelidateErrorsExtractor],
  data() {
    return {
      companyForm: {
        name: null,
        defaultLanguage: null,
        timezone: null,
      },
    }
  },
  validations: {
    companyForm: {
      name: { required },
      defaultLanguage: { required },
      timezone: { required },
    },
  },
  apollo: {
    company: {
      query: company,
      variables() {
        return {
          companyId: this.currentCompanyId,
        }
      },
    },
  },
  methods: {
    async updateCompanyData() {
      if (!this.$v.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: companyUpdate,
            variables: {
              input: {
                companyId: this.currentCompanyId,
                name: this.companyForm.name,
                defaultLanguage: this.companyForm.defaultLanguage,
                timezone: this.companyForm.timezone,
              },
            },
            refetchQueries: ['company'],
          })

          this.$toast.success(this.$t('global.companyDetail.updateCompanyData.success'))
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.companyDetail.updateCompanyData.failure'))
        }
      }
    },
    resetCompanyForm() {
      this.companyForm.name = this.company.name
      this.companyForm.defaultLanguage = this.company.defaultLanguage
      this.companyForm.timezone = this.company.timezone
    },
    onBeforeUnload() {
      if (this.hasUnsavedChanges) {
        // modern browsers will ignore this message and will show default
        return this.$t('global.unsavedChanges')
      }
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    ...mapGetters(['hasUnsavedChanges']),
    hasCompanyFormChanged() {
      if (!this.company) {
        return false
      }
      return !isEqual(this.companyForm, pick(this.company, ['name', 'defaultLanguage', 'timezone']))
    },
    localeOptions() {
      return allowedLanguages.map((locale) => ({
        text: this.$t(`global.lang.${locale}`),
        value: locale,
      }))
    },
    showBlockButtons: function () {
      return this.$vuetify.breakpoint.name === 'xs'
    },
  },
  watch: {
    company() {
      this.resetCompanyForm()
    },
    hasCompanyFormChanged() {
      this.$store.commit('setHasUnsavedChanges', this.hasCompanyFormChanged)
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('global.unsavedChanges'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload
  },
  destroyed() {
    window.onbeforeunload = undefined
    this.$store.commit('setHasUnsavedChanges', false)
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

h1 {
  font-size: 24px;
}
</style>
