<template>
  <div class="page-wrapper" ref="wrapper">
    <h2 class="flex-shrink-0 mb-6">{{ pageTitle }}</h2>
    <PermissionsTable
      :permissions="permissions"
      :settablePermissions="settablePermissions"
      :linkToName="detailPageName"
      :tableHeight="tableHeight"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PermissionsTable from '@/components/PermissionsTable'
import throttle from 'lodash/throttle'

export default {
  name: 'SetPermissionsPage',
  components: { PermissionsTable },
  props: {
    permissions: Array,
    pageTitle: String,
    settablePermissions: Object,
    detailPageName: String,
  },
  data: () => {
    return {
      tableHeight: null,
      otherContentHeight: 168, // CAUTION: will need to be changed if some elements are resized or added/removed from page
    }
  },
  created() {
    window.addEventListener('resize', this.resizeHandler)
  },
  mounted() {
    this.getTableHeight()
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    resizeHandler: throttle(function () {
      this.getTableHeight()
    }, 100),
    getTableHeight() {
      const newHeight = this.$refs.wrapper.offsetHeight - this.otherContentHeight
      this.tableHeight = newHeight < 300 ? null : newHeight
    },
  },
  computed: {
    ...mapGetters(['hasUnsavedChanges']),
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('global.unsavedChanges'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
}
</script>

<style scoped lang="less">
.page-wrapper {
  max-height: 100%;
  padding: 20px;
}
</style>
