import gql from 'graphql-tag'

export const CompanyRelatedPermissions = gql`
  fragment CompanyRelatedPermissions on Permission {
    id
    companyAdministration
    companyUserManagement
    templateManagement
    otherCompaniesManagement
    configurationChanges
    configurationUpload
    deviceControl
    expertMode
    operationalHistory
    restrictedCustomerData
    fullCustomerData
    userManagement
    serviceSequences
    newProductManagement
    baseProductManagement
  }
`
