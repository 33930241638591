import gql from 'graphql-tag'

export const operatingModes = gql`
  query operatingModes($deviceType: DeviceTypeEnum!) {
    operatingModes(deviceType: $deviceType) {
      name
      default
    }
  }
`
