import store from '../../store'

/**
 * Checks whether current user has permissions to access route. Every permission and every eligibility must be true to pass.
 * Works as expected only in routes starting with '/app'.
 * To replace component without change of route, workaround from here was used: https://github.com/vuejs/vue-router/issues/977
 *
 * @param {string[]} requiredPermissions Array of required permissions to activate this route
 * @param {string[]} requiredGeneralEligibilities Array of required eligibilities to activate this route
 * @param {boolean} orCheck some of permissions and some of eligibilities must be true to pass
 */
export function authGuard(requiredPermissions, requiredGeneralEligibilities = [], orCheck = false) {
  return async (to, from, next) => {
    const currUserPermissions = store.getters['user/currentCompanyPermissions']
    const currentCompanyGeneralEligibilities =
      store.getters['user/currentCompanyGeneralEligibilities']

    const andCondition =
      requiredPermissions.every((permissionName) => currUserPermissions[permissionName]) &&
      requiredGeneralEligibilities.every(
        (eligibilityName) => currentCompanyGeneralEligibilities?.[eligibilityName],
      )

    const orCondition =
      (requiredPermissions.length === 0 ||
        requiredPermissions.some((permissionName) => currUserPermissions[permissionName])) &&
      (requiredGeneralEligibilities.length === 0 ||
        requiredGeneralEligibilities.some(
          (eligibilityName) => currentCompanyGeneralEligibilities?.[eligibilityName],
        ))

    if (orCheck ? orCondition : andCondition) {
      next()
    } else {
      next({ name: 'Errors', params: [to.path.replace('/app', '')], replace: true }) // hack: fix when this issue will be resolved: https://github.com/vuejs/vue-router/issues/977
    }
  }
}
