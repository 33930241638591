<template>
  <div class="login-page--content--inner">
    <h1 class="login-page--headline">{{ $t('login.pageTitle') }}</h1>

    <div class="login-page--form">
      <div class="login-page--form">
        <SocialLogin></SocialLogin>
        <v-form @submit.prevent="login">
          <v-text-field
            v-model="userFormLogin.email"
            filled
            :error-messages="emailErrors"
            :label="$t('global.email')"
            required
            @input="$v.userFormLogin.email.$touch()"
            @blur="$v.userFormLogin.email.$touch()"
            :readonly="Boolean(fixedEmail)"
            type="email"
          ></v-text-field>
          <v-text-field
            v-model="userFormLogin.password"
            filled
            :error-messages="passwordErrors"
            :label="$t('login.password')"
            required
            @input="$v.userFormLogin.password.$touch()"
            @blur="$v.userFormLogin.password.$touch()"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <div class="login-page--error">
            <div v-if="errorMessage">{{ errorMessage }}</div>
          </div>
          <div>
            <v-btn :loading="isLoading" type="submit" color="primary" large>{{
              $t('login.logIn')
            }}</v-btn>
            <router-link :to="{ name: 'ForgotPassword' }" class="btn--link">{{
              $t('login.forgotPassword')
            }}</router-link>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import SocialLogin from '@/components/Login/SocialLogin'
import { Auth } from 'aws-amplify'
import { getItem, removeItem } from '@/middleware/storage'
import queryString from 'query-string'
import { isEmailRegistered } from '@/graphql/query/isEmailRegistered'
import VueRouter from 'vue-router'
const { isNavigationFailure, NavigationFailureType } = VueRouter
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  components: { SocialLogin },
  mixins: [validationMixin],
  validations: {
    userFormLogin: {
      email: { required, email },
      password: { required },
    },
  },
  data: function () {
    return {
      userFormLogin: {
        email: '',
        password: '',
      },
      error: this.$store.getters['user/loginError'],
      fixedEmail: false,
      showPassword: false,
      isLoading: false,
    }
  },
  methods: {
    login: async function () {
      this.error = null
      this.$store.commit('user/setLoginError', null)
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          this.isLoading = true
          const data = await Auth.signIn(this.userFormLogin.email, this.userFormLogin.password)
          if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
            if (data.attributes.email_verified) {
              delete data.attributes.phone_number_verified
              delete data.attributes.email_verified
            }

            this.$router.push({
              name: 'ChallengeNewPasswordPage',
              params: {
                data,
                sessionUserAttributes: data.attributes,
              },
            })
          } else {
            this.$router.push({ name: 'DashboardPage' }).catch((failure) => {
              if (!isNavigationFailure(failure, NavigationFailureType.redirected)) {
                console.error(failure)
              }
            })
          }
        } catch (err) {
          console.error('verification failed', err)
          if (err.code === 'UserNotConfirmedException') {
            try {
              await Auth.resendSignUp(this.userFormLogin.email)
            } catch (err) {
              console.error('error resending code: ', err)
              this.error = err.message
            }
          } else if (err.code === 'NotAuthorizedException') {
            this.error = 'FE_INCORRECT_USERNAME_OR_PASSWORD'
          } else {
            this.error = err.message
          }
        } finally {
          this.isLoading = false
        }
      }
    },
  },
  computed: {
    ...mapGetters('user', ['loginError']),
    errorMessage: function () {
      const errorMap = {
        USER_CREATE_EMAIL_ALREADY_EXISTS: this.$t('global.errors.emailAlreadyExists'),
        FE_INVALID_INVITATION: this.$t('global.errors.invalidInvitation'),
        FE_USER_DEACTIVATED: `${this.$t('global.errors.userDeactivated')} support@ixfield.com`,
        FE_INCORRECT_USERNAME_OR_PASSWORD: this.$t('login.error.wrongUsernameOrPassword'),
        USER_CREATE_ACCESS_TOKEN_IS_MISSING: '',
      }
      if (this.error == null) {
        return null
      }

      return errorMap[this.error] != null
        ? errorMap[this.error]
        : this.$t('global.errors.somethingWentWrong')
    },
    emailErrors() {
      const errors = []
      if (!this.$v.userFormLogin.email.$dirty) {
        return errors
      }
      !this.$v.userFormLogin.email.required &&
        errors.push(this.$t('global.formValidation.required'))
      !this.$v.userFormLogin.email.email && errors.push(this.$t('global.formValidation.notEmail'))
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.userFormLogin.password.$dirty) {
        return errors
      }
      !this.$v.userFormLogin.password.required &&
        errors.push(this.$t('global.formValidation.required'))
      return errors
    },
  },
  watch: {
    loginError() {
      if (this.loginError) {
        this.error = this.loginError
      }
    },
  },
  mounted: function () {
    // clear login error so it does not persist after refresh
    this.$store.commit('user/setLoginError', null)

    this.$nextTick(async () => {
      if (getItem('redirect')) {
        const query = queryString.parse(queryString.extract(getItem('redirect')))
        if (query?.email) {
          this.fixedEmail = true
          this.userFormLogin.email = query.email
        }
        if (query?.email && query?.createCompanyToken) {
          try {
            const result = await this.$apollo.query({
              query: isEmailRegistered,
              variables: {
                email: query.email,
                token: query.createCompanyToken,
              },
              fetchPolicy: 'network-only',
            })

            if (!result?.data?.isEmailRegistered) {
              this.$router.push({ name: 'RegisterPage' })
            }
          } catch (error) {
            this.fixedEmail = false
            this.error = 'FE_INVALID_INVITATION'
            removeItem('redirect')
          }
        }
      }
    })
  },
}
</script>
