import gql from 'graphql-tag'

export const deviceInviteUser = gql`
  mutation deviceInviteUser($deviceId: ID!, $email: EmailAddress!, $lang: String) {
    deviceInviteUser(input: { deviceId: $deviceId, email: $email, lang: $lang }) {
      success
      userDeviceAccess {
        id
        accessStart
        accessEnd
        type
        user {
          id
          enabled
        }
      }
    }
  }
`
