<template>
  <div class="pa-6">
    <h1 class="mb-5">
      {{ $t('global.adminConsole') }}: {{ $t('global.adminConsoleMenu.companies') }}
    </h1>
    <v-data-table
      :headers="headers"
      :items="companiesData"
      :options.sync="options"
      :loading="$apollo.loading"
      :server-items-length="companies ? companies.totalCount : 0"
      :disable-sort="true"
      :no-data-text="$t('users.noMatchingRecordsFound')"
      :footer-props="{
        'items-per-page-text': $t('global.dataTable.itemsPerPageText'),
        'items-per-page-all-text': $t('global.dataTable.allItemsText'),
      }"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-text-field v-model="search" :label="$t('users.search')" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.companyAccessColumn="{ item }">
        <span v-if="item.id === currentCompanyId">-</span>
        <v-btn
          v-else-if="parentCompanies && parentCompanies.includes(item.id)"
          height="27"
          color="error"
          @click="openConfirmationDialog(item, 'removeCompany')"
          depressed
          >{{ $t('global.removeAccess') }}</v-btn
        >
        <v-btn height="27" v-else @click="openConfirmationDialog(item, 'addCompany')" depressed>{{
          $t('global.addAccess')
        }}</v-btn>
      </template>
      <template v-slot:item.myAccessColumn="{ item }">
        <v-btn
          v-if="
            currentUserCompanies && currentUserCompanies.find((company) => company.id === item.id)
          "
          height="27"
          color="error"
          @click="openConfirmationDialog(item, 'removeUser')"
          depressed
          >{{ $t('global.removeAccess') }}</v-btn
        >
        <v-btn height="27" v-else @click="openConfirmationDialog(item, 'addUser')" depressed>{{
          $t('global.addAccess')
        }}</v-btn>
      </template>
      <template v-slot:item.xeeloIntegration="{ item }">
        <span v-if="item.xeeloIntegration">{{ $t('global.yes') }}</span>
        <v-btn
          v-else
          height="27"
          @click="openConfirmationDialog(item, 'addXeeloIntegration')"
          depressed
          >{{ $t('global.addXeeloIntegration') }}</v-btn
        >
      </template>
    </v-data-table>

    <ConfirmationDialog
      v-model="confirmationModalOpen"
      v-bind="confirmationConfigMap[confirmationConfigKey]"
    />
  </div>
</template>
<script>
import { companies as companiesQuery } from '@/graphql/query/companies'
import { parentCompanies } from '@/graphql/query/parentCompanies'
import { companyAddServiceCompany } from '@/graphql/mutations/companyAddServiceCompany'
import { companyRemoveServiceCompany } from '@/graphql/mutations/companyRemoveServiceCompany'
import { companyCreateUserAccess } from '@/graphql/mutations/companyCreateUserAccess'
import { companyDeleteUserAccess } from '@/graphql/mutations/companyDeleteUserAccess'
import { companyAddXeeloIntegration } from '@/graphql/mutations/companyAddXeeloIntegration'
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'
import ConfirmationDialog from '@/components/ConfirmationDialog'

export default {
  name: 'AdminCompanies',
  components: { ConfirmationDialog },
  data() {
    return {
      companiesQueryInput: {
        search: null,
        first: 10,
        after: null,
        last: null,
        before: null,
      },
      options: {},
      search: null,
      headers: [
        { text: this.$t('global.companyName'), value: 'name' },
        { text: this.$t('global.companyAccess'), value: 'companyAccessColumn', align: 'center' },
        { text: this.$t('global.myAccess'), value: 'myAccessColumn', align: 'center' },
        { text: this.$t('global.xeeloIntegration'), value: 'xeeloIntegration', align: 'center' },
      ],
      selectedCompany: null,
      confirmationModalOpen: false,
      confirmationConfigKey: null,
    }
  },
  apollo: {
    companies: {
      query: companiesQuery,
      variables() {
        return this.companiesQueryInput
      },
    },
    parentCompanies: {
      query: parentCompanies,
      variables() {
        return {
          companyId: this.currentCompanyId,
        }
      },
      update(data) {
        return data.company.parentCompanies.map((pc) => pc.id)
      },
    },
  },
  methods: {
    openConfirmationDialog(company, config) {
      this.selectedCompany = company
      this.confirmationConfigKey = config
      this.confirmationModalOpen = true
    },
    async addCurrentCompanyAsServiceCompany() {
      try {
        this.$apollo.mutate({
          mutation: companyAddServiceCompany,
          variables: {
            childCompanyId: this.currentCompanyId,
            parentCompanyId: this.selectedCompany.id,
          },
          refetchQueries: ['parentCompanies'],
        })

        this.$toast.success(this.$t('global.addCurrentCompanyAsServiceCompany.success'))
        this.confirmationModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.addCurrentCompanyAsServiceCompany.failure'))
      }
    },
    async removeCurrentCompanyAsServiceCompany() {
      try {
        this.$apollo.mutate({
          mutation: companyRemoveServiceCompany,
          variables: {
            childCompanyId: this.currentCompanyId,
            parentCompanyId: this.selectedCompany.id,
          },
          refetchQueries: ['parentCompanies'],
        })

        this.$toast.success(this.$t('global.removeCurrentCompanyAsServiceCompany.success'))
        this.confirmationModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.removeCurrentCompanyAsServiceCompany.failure'))
      }
    },
    async addCurrentUserToCompany() {
      try {
        this.$apollo.mutate({
          mutation: companyCreateUserAccess,
          variables: {
            companyId: this.selectedCompany.id,
            userId: this.currentUserId,
          },
          refetchQueries: ['me'],
        })

        this.$toast.success(this.$t('global.addMyselfToCompany.success'))
        this.confirmationModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.addMyselfToCompany.failure'))
      }
    },
    async removeCurrentUserFromCompany() {
      try {
        this.$apollo.mutate({
          mutation: companyDeleteUserAccess,
          variables: {
            companyId: this.selectedCompany.id,
            userId: this.currentUserId,
          },
          refetchQueries: ['me'],
        })

        this.$toast.success(this.$t('global.removeMyCompanyAccess.success'))
        this.confirmationModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.removeMyCompanyAccess.failure'))
      }
    },
    async addXeeloIntegration() {
      try {
        await this.$apollo.mutate({
          mutation: companyAddXeeloIntegration,
          variables: {
            companyId: this.selectedCompany.id,
          },
          refetchQueries: ['companies'],
        })

        this.$toast.success(this.$t('global.addXeeloIntegrationAction.success'))
        this.confirmationModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.addXeeloIntegrationAction.failure'))
      }
    },
    debounceSearch: debounce(function (newSearch) {
      this.companiesQueryInput = {
        search: newSearch !== '' ? newSearch : null,
        first: this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : null,
        after: null,
        last: null,
        before: null,
      }
    }, 250),
  },
  computed: {
    ...mapGetters('user', [
      'currentCompanyName',
      'currentCompanyId',
      'currentUserCompanies',
      'currentUserId',
    ]),
    companiesData() {
      return this.companies ? this.companies.edges.map((edge) => edge.node) : []
    },
    confirmationConfigMap() {
      return {
        addCompany: {
          heading: this.$t('global.addAccessConfirmation.heading'),
          text: this.$t('global.addAccessConfirmation.text', {
            childCompany: this.currentCompanyName,
            parentCompany: this.selectedCompany?.name,
          }),
          action: this.addCurrentCompanyAsServiceCompany,
        },
        removeCompany: {
          heading: this.$t('global.removeAccessConfirmation.heading'),
          text: this.$t('global.removeAccessConfirmation.text', {
            childCompany: this.currentCompanyName,
            parentCompany: this.selectedCompany?.name,
          }),
          action: this.removeCurrentCompanyAsServiceCompany,
          confirmBtnColor: 'error',
          confirmText: this.$t('global.remove'),
        },
        addUser: {
          heading: this.$t('global.addMyselfToCompanyConfirmation.heading'),
          text: this.$t('global.addMyselfToCompanyConfirmation.text', {
            companyName: this.selectedCompany?.name,
          }),
          action: this.addCurrentUserToCompany,
        },
        removeUser: {
          heading: this.$t('global.removeMyselfFromCompanyConfirmation.heading'),
          text: this.$t('global.removeMyselfFromCompanyConfirmation.text', {
            companyName: this.selectedCompany?.name,
          }),
          action: this.removeCurrentUserFromCompany,
          confirmBtnColor: 'error',
          confirmText: this.$t('global.remove'),
        },
        addXeeloIntegration: {
          heading: this.$t('global.addXeeloIntegrationConfirmation.heading'),
          text: this.$t('global.addXeeloIntegrationConfirmation.text', {
            companyName: this.selectedCompany?.name,
          }),
          action: this.addXeeloIntegration,
        },
      }
    },
  },
  watch: {
    options: {
      handler({ page, itemsPerPage }, { page: oldPage, itemsPerPage: oldItemsPerPage }) {
        if (!oldPage && !oldItemsPerPage) {
          return
        }
        if (oldItemsPerPage !== itemsPerPage) {
          // change page size
          this.companiesQueryInput = {
            ...this.companiesQueryInput,
            first: itemsPerPage !== -1 ? itemsPerPage : null,
            after: null,
            last: null,
            before: null,
          }
        } else if (page === oldPage + 1) {
          // next page
          this.companiesQueryInput = {
            ...this.companiesQueryInput,
            first: itemsPerPage,
            after: this.companies.pageInfo.endCursor,
            last: null,
            before: null,
          }
        } else if (page === oldPage - 1) {
          // previous page
          this.companiesQueryInput = {
            ...this.companiesQueryInput,
            first: null,
            after: null,
            last: itemsPerPage,
            before: this.companies.pageInfo.startCursor,
          }
        }
      },
      deep: true,
    },
    search() {
      this.debounceSearch(this.search)
    },
  },
}
</script>
<style lang="less" scoped>
h1 {
  font-size: 24px;
}
</style>
