import gql from 'graphql-tag'

export const correctiveActionCreate = gql`
  mutation correctiveActionCreate(
    $name: NonEmptyString!
    $descriptionTranslations: DescriptionTranslationsInput!
  ) {
    correctiveActionCreate(
      input: { name: $name, descriptionTranslations: $descriptionTranslations }
    ) {
      success
    }
  }
`
