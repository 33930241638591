<template>
  <div>
    <div v-if="$apollo.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <template v-else-if="thingTypeFamilies && deviceEligibilities">
      <v-btn color="primary" @click="addEligibilityModalOpen = true">{{
        $t('global.addEligibility')
      }}</v-btn>
      <figure class="scrollable pb-2 pl-1">
        <table class="rotated-header-table">
          <thead>
            <tr>
              <th></th>
              <th
                v-for="(thingType, index) of thingTypeFamilies"
                :key="thingType.name"
                :style="{ zIndex: 100 - index }"
              >
                <div class="rotated-header-container" :ref="thingType.name">
                  <div
                    class="rotated-header-content"
                    :ref="thingType.name"
                    @mouseenter="highlightColumn(thingType.name)"
                    @mouseleave="unhighlightColumn(thingType.name)"
                  >
                    {{ thingType.businessName || thingType.name }}
                  </div>
                </div>
              </th>
              <th :style="{ zIndex: 100 - thingTypeFamilies.length }"></th>
            </tr>
          </thead>
          <tbody class="elevation-3">
            <tr v-for="eligibility of deviceEligibilities" :key="eligibility.name">
              <td>
                <div class="mx-2 my-1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{ eligibility.label }}</span>
                    </template>
                    <span>{{ eligibility.description }}</span>
                  </v-tooltip>
                </div>
              </td>
              <template v-for="thingType of thingTypeFamilies">
                <td
                  class="text-center clickable-td"
                  :key="thingType.name"
                  :ref="thingType.name"
                  @mouseenter="highlightColumn(thingType.name)"
                  @mouseleave="unhighlightColumn(thingType.name)"
                  @click="editEligibility(eligibility.name, eligibility.type, thingType)"
                >
                  <template v-if="thingTypesValuesMap[thingType.name][eligibility.name] != null">
                    <template v-if="eligibility.type === 'BOOLEAN'">
                      <div class="d-flex flex-column">
                        <v-icon>{{
                          thingTypesValuesMap[thingType.name][eligibility.name]
                            .defaultDefaultValueValue
                            ? 'mdi-check'
                            : 'mdi-close'
                        }}</v-icon>
                        <v-icon>{{
                          thingTypesValuesMap[thingType.name][eligibility.name]
                            .defaultDefaultAvailabilityValue === 'available'
                            ? 'mdi-check'
                            : thingTypesValuesMap[thingType.name][eligibility.name]
                                .defaultDefaultAvailabilityValue === 'optional'
                            ? 'mdi-help'
                            : 'mdi-close'
                        }}</v-icon>
                      </div>
                    </template>
                    <template v-if="eligibility.type === 'INTEGER'">
                      <div class="d-flex flex-column">
                        <span>{{
                          thingTypesValuesMap[thingType.name][eligibility.name]
                            .defaultDefaultValueValue
                        }}</span>
                        <span>{{
                          thingTypesValuesMap[thingType.name][eligibility.name]
                            .defaultDefaultAvailabilityValue
                        }}</span>
                      </div>
                    </template>
                    <template v-if="eligibility.type === 'HISTORY'">
                      <div class="d-flex flex-column">
                        <span
                          >{{
                            thingTypesValuesMap[thingType.name][eligibility.name]
                              .defaultDefaultValueValue
                          }}
                          {{ $t('global.days') }}</span
                        >
                        <span>{{
                          thingTypesValuesMap[thingType.name][eligibility.name]
                            .defaultDefaultAvailabilityValue
                        }}</span>
                      </div>
                    </template>
                  </template>
                </td>
              </template>
              <td class="text-center">
                <v-btn
                  icon
                  color="error"
                  @click="openDeleteEligibilityConfirmationDialog(eligibility.name)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </figure>
    </template>
    <v-dialog v-model="editEligibilityModalOpen" width="400">
      <v-card>
        <div class="pa-6">
          <h2 class="mb-4">{{ $t('global.updateEligibility') }}</h2>
          <p class="mb-2">
            <b>{{ $t('global.eligibility') }}:</b> {{ updateData.eligibilityName }}
          </p>
          <p class="mb-2">
            <b>{{ $t('global.thingType') }}:</b> {{ updateData.thingTypeBusinessName }}
          </p>
          <v-form @submit.prevent="updateEligibility">
            <div class="d-flex align-center mb-2">
              <p class="mb-0">
                <b>{{ $t('global.allowed') }}:</b>
              </p>
              <v-simple-checkbox
                v-model="updateData.isAllowed"
                :ripple="false"
                color="primary"
                class="ml-3"
              ></v-simple-checkbox>
            </div>
            <div class="d-flex align-center">
              <p class="mb-0">
                <b>{{ $t('global.defaultAvailability') }}:</b>
              </p>
              <v-select
                v-model="updateData.newDefaultAvailability"
                :items="availabilityOptions"
                item-text="text"
                item-value="value"
                :disabled="!updateData.isAllowed"
                class="ml-3"
              ></v-select>
            </div>
            <div
              v-if="updateData.newDefaultAvailability === 'optional'"
              class="d-flex align-center"
            >
              <p class="mb-0">
                <b>{{ $t('global.defaultValue') }}:</b>
              </p>
              <component
                :is="chooseInputType(updateData.eligibilityType)"
                v-model="updateData.newDefaultValue"
                :options="{ disabled: !updateData.isAllowed }"
                @hasErrors="updateData.error = $event"
                class="ml-3 flex-grow-0"
              ></component>
            </div>

            <!-- Checkbox for isPremium -->
            <div class="d-flex align-center mb-2">
              <p class="mb-0">
                <b>{{ $t('global.premium') }}:</b>
              </p>
              <component
                :is="chooseInputType(updateData.eligibilityType)"
                v-model="updateData.newIsPremium"
                :options="{ disabled: !updateData.isAllowed }"
                @hasErrors="updateData.error = $event"
                class="ml-3 flex-grow-0"
              ></component>
            </div>

            <!-- Checkbox for serviceSequence -->
            <div class="d-flex align-center mb-2">
              <p class="mb-0">
                <b>{{ $t('global.serviceSequence') }}:</b>
              </p>
              <component
                :is="chooseInputType(updateData.eligibilityType)"
                v-model="updateData.newServiceSequence"
                :options="{ disabled: !updateData.isAllowed }"
                @hasErrors="updateData.error = $event"
                class="ml-3 flex-grow-0"
              ></component>
            </div>

            <!-- Select for provisionType -->
            <v-select
              v-model="updateData.newProvisionType"
              :items="provisionTypes"
              :label="$t('global.provisionType')"
              class="mb-2"
            ></v-select>

            <div class="mt-8 text-right">
              <v-btn @click="editEligibilityModalOpen = false" depressed>{{
                $t('global.cancel')
              }}</v-btn>
              <v-btn type="submit" color="primary" class="ml-3" depressed>{{
                $t('global.confirm')
              }}</v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addEligibilityModalOpen" width="unset">
      <v-card>
        <div class="pa-6">
          <h2 class="mb-7">{{ $t('global.addEligibility') }}</h2>
          <v-form @submit.prevent="addEligibility">
            <v-text-field
              v-model="newEligibilityName"
              :error-messages="newEligibilityNameErrors"
              :label="$t('global.name')"
              required
              @input="$v.newEligibilityName.$touch()"
              @blur="$v.newEligibilityName.$touch()"
              filled
            ></v-text-field>
            <v-select
              v-model="newEligibilityType"
              :error-messages="newEligibilityTypeErrors"
              :items="eligibilityTypes"
              :label="$t('global.type')"
              filled
              style="width: 350px"
            ></v-select>
            <!-- isPremium Checkbox -->
            <v-checkbox v-model="newIsPremium" :label="$t('global.premium')"></v-checkbox>

            <!-- serviceSequence Checkbox -->
            <v-checkbox
              v-model="newServiceSequence"
              :label="$t('global.serviceSequence')"
            ></v-checkbox>

            <!-- provisionType Select -->
            <v-select
              v-model="newProvisionType"
              :items="provisionTypes"
              :label="$t('global.provisionType')"
              filled
            ></v-select>
            <div class="mt-8 text-right">
              <v-btn @click="addEligibilityModalOpen = false" depressed>{{
                $t('global.cancel')
              }}</v-btn>
              <v-btn type="submit" color="primary" class="ml-3" depressed>{{
                $t('global.confirm')
              }}</v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <ConfirmationDialog
      v-if="eligibilityToDelete"
      v-model="confirmDeleteModalOpen"
      :heading="$t('global.eligibilityDeleteConfirmation.heading')"
      :text="$t('global.eligibilityDeleteConfirmation.text', { eligibility: eligibilityToDelete })"
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
      :action="deleteEligibility"
    />
  </div>
</template>

<script>
import { thingTypeFamilies } from '@/graphql/query/thingTypeFamilies'
import { deviceEligibilities } from '@/graphql/query/deviceEligibilities'
import { eligibilityTypeEnumValues } from '@/graphql/query/eligibilityTypeEnumValues'
import BooleanEligibilityInput from '@/components/ProductCatalogue/EligibilitiesInputs/BooleanEligibilityInput'
import IntegerEligibilityInput from '@/components/ProductCatalogue/EligibilitiesInputs/IntegerEligibilityInput'
import HistoryEligibilityInput from '@/components/ProductCatalogue/EligibilitiesInputs/HistoryEligibilityInput'
import { updateThingTypeFamilyEligibilities } from '@/graphql/mutations/thingTypeFamilyEligibilitiesUpdate'
import { deviceEligibilityCreate } from '@/graphql/mutations/deviceEligibilityCreate'
import { eligibilityDelete } from '@/graphql/mutations/eligibilityDelete'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import ConfirmationDialog from '@/components/ConfirmationDialog'

export default {
  name: 'AdminThingTypeEligibilities',
  mixins: [validationMixin],
  components: {
    BooleanEligibilityInput,
    IntegerEligibilityInput,
    HistoryEligibilityInput,
    ConfirmationDialog,
  },
  data() {
    return {
      editEligibilityModalOpen: false,
      addEligibilityModalOpen: false,
      updateData: {
        eligibilityName: null,
        eligibilityType: null,
        thingTypeName: null,
        isAllowed: null,
        newDefaultValue: null,
        newDefaultAvailability: 'available',
        newIsPremium: null,
        newServiceSequence: null,
        newProvisionType: '',
        error: false,
      },
      newIsPremium: false,
      newServiceSequence: false,
      newProvisionType: 'ONE_OFF',
      provisionTypes: [
        { text: 'One-Off', value: 'ONE_OFF' },
        { text: 'Subscription', value: 'SUBSCRIPTION' },
      ],
      availabilityOptions: [
        { text: 'Available', value: 'available' },
        { text: 'Not Available', value: 'not_available' },
        { text: 'Optional', value: 'optional' },
      ],
      newEligibilityName: '',
      newEligibilityType: null,
      eligibilityToDelete: null,
      confirmDeleteModalOpen: false,
      thingTypeFamilies: [],
    }
  },
  validations: {
    newEligibilityName: { required },
    newEligibilityType: { required },
  },
  apollo: {
    thingTypeFamilies: {
      query: thingTypeFamilies,
      variables: {
        adminView: true,
        withEligibilities: true,
      },
      update(data) {
        return data.thingTypeFamilies
      },
    },
    deviceEligibilities: {
      query: deviceEligibilities,
      variables() {
        return {
          lang: this.$i18n.locale,
        }
      },
      update(data) {
        return data.eligibilitiesConfig.device
      },
    },
    eligibilityTypes: {
      query: eligibilityTypeEnumValues,
      update(data) {
        return data.__type.enumValues.map((value) => value.name)
      },
    },
  },
  methods: {
    async updateEligibility() {
      if (!this.updateData.error) {
        if (this.updateData.newDefaultAvailability === 'available') {
          this.updateData.newDefaultValue = true
        } else if (this.updateData.newDefaultAvailability === 'not_available') {
          this.updateData.newDefaultValue = false
        }

        try {
          const newEligibilities = JSON.parse(
            JSON.stringify(this.thingTypesValuesMap[this.updateData.thingTypeName]),
          )
          if (!this.updateData.isAllowed) {
            delete newEligibilities[this.updateData.eligibilityName]
          } else {
            newEligibilities[this.updateData.eligibilityName] = {
              defaultDefaultValueValue: ['INTEGER', 'HISTORY'].includes(
                this.updateData.eligibilityType,
              )
                ? Number(this.updateData.newDefaultValue)
                : this.updateData.newDefaultValue,
              defaultDefaultAvailabilityValue: this.updateData.newDefaultAvailability,
              defaultIsPremiumValue: ['INTEGER', 'HISTORY'].includes(
                this.updateData.eligibilityType,
              )
                ? Number(this.updateData.newIsPremium)
                : this.updateData.newIsPremium,
              defaultServiceSequenceValue: ['INTEGER', 'HISTORY'].includes(
                this.updateData.eligibilityType,
              )
                ? Number(this.updateData.newServiceSequence)
                : this.updateData.newServiceSequence,
              defaultProvisionTypeValue: this.updateData.newProvisionType,
            }
          }

          await this.$apollo.mutate({
            mutation: updateThingTypeFamilyEligibilities,
            variables: {
              updateData: [
                {
                  thingTypeFamily: this.updateData.thingTypeName,
                  eligibilities: Object.entries(newEligibilities).map(
                    ([elName, defaultValues]) => ({
                      name: elName,
                      defaultDefaultValueValue: defaultValues.defaultDefaultValueValue,
                      defaultDefaultAvailabilityValue:
                        defaultValues.defaultDefaultAvailabilityValue,
                      defaultIsPremiumValue: defaultValues.defaultIsPremiumValue,
                      defaultServiceSequenceValue: defaultValues.defaultServiceSequenceValue,
                      defaultProvisionTypeValue: defaultValues.defaultProvisionTypeValue,
                    }),
                  ),
                },
              ],
            },
            refetchQueries: ['thingTypeFamilies'],
          })
          this.$toast.success(this.$t('global.thingTypesEligibilitiesUpdate.success'))
          this.editEligibilityModalOpen = false
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.thingTypesEligibilitiesUpdate.failure'))
        }
      }
    },
    async addEligibility() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: deviceEligibilityCreate,
            variables: {
              name: this.newEligibilityName,
              type: this.newEligibilityType,
              isPremium: this.newIsPremium,
              serviceSequence: this.newServiceSequence,
              provisionType: this.newProvisionType,
            },
            refetchQueries: ['deviceEligibilities'],
          })
          this.$toast.success(this.$t('global.deviceEligibilityCreate.success'))
          this.addEligibilityModalOpen = false
          // Reset fields after sending
          this.newEligibilityName = ''
          this.newEligibilityType = null
          this.newIsPremium = false
          this.newServiceSequence = false
          this.newProvisionType = 'ONE_OFF'
          this.$v.$reset()
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.deviceEligibilityCreate.failure'))
        }
      }
    },
    editEligibility(eligibilityName, eligibilityType, thingType) {
      const baseValueForType = {
        BOOLEAN: false,
        INTEGER: 0,
        HISTORY: 0,
      }

      // Fetch the default eligibility settings from deviceEligibilities based on the name
      const defaultEligibility = this.deviceEligibilities.find((e) => e.name === eligibilityName)

      // Set all values, ensuring there's also default values for notAllowed deviceEligibilities from deviceEligibilities object.
      this.updateData = {
        eligibilityName,
        eligibilityType,
        thingTypeName: thingType.name,
        thingTypeBusinessName: thingType.businessName || thingType.name,
        isAllowed: this.thingTypesValuesMap[thingType.name][eligibilityName] != null,
        newDefaultValue:
          this.thingTypesValuesMap[thingType.name][eligibilityName]?.defaultDefaultValueValue !=
          null
            ? this.thingTypesValuesMap[thingType.name][eligibilityName].defaultDefaultValueValue
            : baseValueForType[eligibilityType],
        newDefaultAvailability:
          this.thingTypesValuesMap[thingType.name][eligibilityName]
            ?.defaultDefaultAvailabilityValue != null
            ? this.thingTypesValuesMap[thingType.name][eligibilityName]
                .defaultDefaultAvailabilityValue
            : 'available',
        newIsPremium:
          this.thingTypesValuesMap[thingType.name][eligibilityName]?.defaultIsPremiumValue != null
            ? this.thingTypesValuesMap[thingType.name][eligibilityName].defaultIsPremiumValue
            : defaultEligibility
            ? defaultEligibility.isPremium
            : baseValueForType[eligibilityType],
        newServiceSequence:
          this.thingTypesValuesMap[thingType.name][eligibilityName]?.defaultServiceSequenceValue !=
          null
            ? this.thingTypesValuesMap[thingType.name][eligibilityName].defaultServiceSequenceValue
            : defaultEligibility
            ? defaultEligibility.serviceSequence
            : baseValueForType[eligibilityType],
        newProvisionType:
          this.thingTypesValuesMap[thingType.name][eligibilityName]?.defaultProvisionTypeValue !=
          null
            ? this.thingTypesValuesMap[thingType.name][eligibilityName].defaultProvisionTypeValue
            : defaultEligibility
            ? defaultEligibility.provisionType
            : 'ONE_OFF',
        error: false,
      }

      this.editEligibilityModalOpen = true
    },
    async deleteEligibility() {
      try {
        await this.$apollo.mutate({
          mutation: eligibilityDelete,
          variables: {
            name: this.eligibilityToDelete,
          },
          refetchQueries: ['thingTypeFamilies', 'deviceEligibilities'],
        })
        this.$toast.success(this.$t('global.eligibilityDelete.success'))
        this.confirmDeleteModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.eligibilityDelete.failure'))
      }
    },
    openDeleteEligibilityConfirmationDialog(eligibility) {
      this.eligibilityToDelete = eligibility
      this.confirmDeleteModalOpen = true
    },
    highlightColumn(thingTypeName) {
      this.$refs[thingTypeName].forEach((ref) => (ref.style.backgroundColor = '#D3F1FF'))
    },
    unhighlightColumn(thingTypeName) {
      this.$refs[thingTypeName].forEach((ref) => (ref.style.backgroundColor = '#ffffff'))
    },
    chooseInputType(type) {
      const inputTypeMap = {
        INTEGER: IntegerEligibilityInput,
        BOOLEAN: BooleanEligibilityInput,
        HISTORY: HistoryEligibilityInput,
      }
      return inputTypeMap[type]
    },
  },
  computed: {
    thingTypesValuesMap() {
      if (!this.thingTypeFamilies) {
        return {}
      }
      return this.thingTypeFamilies.reduce((acc, thingType) => {
        acc[thingType.name] = thingType.eligibilities.reduce((result, eligibility) => {
          result[eligibility.name] = {
            defaultDefaultValueValue: eligibility.defaultValue,
            defaultDefaultAvailabilityValue: eligibility.defaultAvailability,
            defaultIsPremiumValue: eligibility.isPremium,
            defaultServiceSequenceValue: eligibility.serviceSequence,
            defaultProvisionTypeValue: eligibility.provisionType,
          }
          return result
        }, {})
        return acc
      }, {})
    },
    newEligibilityNameErrors() {
      const errors = []
      if (!this.$v.newEligibilityName.$dirty) {
        return errors
      }
      !this.$v.newEligibilityName.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
    newEligibilityTypeErrors() {
      const errors = []
      if (!this.$v.newEligibilityType.$dirty) {
        return errors
      }
      !this.$v.newEligibilityType.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
  },
}
</script>

<style lang="less" scoped>
@header-height: 150px;
@header-width: 50px;
@user-link-bg-color: #eeeeee;

.scrollable {
  overflow: auto;
  max-height: 600px;
}

.rotated-header-table {
  border-collapse: collapse;

  th {
    position: sticky;
    top: 0;
    max-width: @header-width;
    min-width: @header-width;
    height: @header-height;
    background-color: white;

    &:first-child::after {
      content: '';
      height: 100%;
      width: 100%;
      background-color: white;
      position: absolute;
      top: 0;
      left: -5px;
    }

    &:last-child::before {
      content: '';
      height: 100%;
      width: 100%;
      background-color: white;
      position: absolute;
      top: 0;
      right: -5px;
    }
  }

  tr {
    border-bottom: 1px solid #d6d6dd;
  }

  tr:last-child {
    border-bottom: none;
  }

  .rotated-header-container {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }

  .rotated-header-content {
    white-space: nowrap;
    width: fit-content;
    height: cos(45deg) * @header-width;
    line-height: cos(45deg) * @header-width;
    padding: 0 10px 0 15px;
    transform-origin: left top;
    transform: rotate(-45deg);
    font-weight: normal;
    font-size: 14px;
    cursor: default;
  }

  .clickable-td {
    cursor: pointer;
  }
}
</style>
