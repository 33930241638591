import gql from 'graphql-tag'

export const deviceImageDelete = gql`
  mutation deviceImageDelete($imageId: ID!) {
    deviceImageDelete(input: { imageId: $imageId }) {
      success
      deletedImageId
    }
  }
`
