<template>
  <v-main style="overflow: auto">
    <div class="page-wrapper">
      <div class="content-wrapper pa-6">
        <h2 class="mb-6">{{ $t('users.addNewUser') }}</h2>
        <p>{{ $t('users.addNewUserDescription') }}</p>
        <v-form>
          <div class="form-wrapper">
            <v-text-field
              v-model="form.email"
              :error-messages="emailErrors"
              :label="$t('global.email')"
              required
              @input="$v.form.email.$touch()"
              @blur="$v.form.email.$touch()"
              filled
              type="email"
            ></v-text-field>
            <v-text-field
              v-model="form.firstName"
              :error-messages="firstNameErrors"
              :label="$t('global.firstName')"
              required
              @input="$v.form.firstName.$touch()"
              @blur="$v.form.firstName.$touch()"
              filled
            ></v-text-field>
            <v-text-field
              v-model="form.lastName"
              :error-messages="lastNameErrors"
              :label="$t('global.lastName')"
              required
              @input="$v.form.lastName.$touch()"
              @blur="$v.form.lastName.$touch()"
              filled
            ></v-text-field>

            <template v-if="!manualPermissionsOpen">
              <p class="mt-2">{{ $t('users.permissionsByUser') }}:</p>
              <v-select
                :items="selectableUsers"
                item-text="name"
                item-value="id"
                v-model="permissionsByUserId"
                filled
                hide-details
              ></v-select>
              <a @click="manualPermissionsOpen = true" class="brand-link mt-4 d-block">{{
                $t('users.setPermissionsManually')
              }}</a>
            </template>
          </div>
          <template v-if="manualPermissionsOpen">
            <p class="mt-2">{{ $t('users.permissionsTitle') }}:</p>
            <UserPermissionsInput v-model="form.companyPermissions"></UserPermissionsInput>
            <a @click="manualPermissionsOpen = false" class="brand-link mt-4 d-block">{{
              $t('users.setPermissionsByUser')
            }}</a>
          </template>
          <div class="form-wrapper mt-8">
            <v-select
              :items="localeOptions"
              v-model="form.lang"
              :label="$t('global.invitationLanguage')"
              filled
              hide-details
            ></v-select>
          </div>
        </v-form>
        <div class="mt-7 d-flex flex-wrap">
          <v-btn
            :block="showBlockButtons"
            color="primary"
            class="mr-5 mb-2"
            @click="inviteNewUser()"
            >{{ $t('users.sendInvitation') }}</v-btn
          >
          <v-btn :block="showBlockButtons" @click="cancel()">{{ $t('global.cancel') }}</v-btn>
        </div>
      </div>
    </div>
  </v-main>
</template>

<script>
import { companyUsers } from '@/graphql/query/companyUsers'
import { mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import UserPermissionsInput from '@/components/User/UserPermissionsInput'
import { companyAddUser } from '@/graphql/mutations/companyAddUser'
import { companyPermissions } from '@/config/permissions'
import { allowedLanguages } from '@/translations'

export default {
  name: 'AddUserToCompanyPage',
  mixins: [validationMixin],
  components: { UserPermissionsInput },
  data() {
    return {
      permissionsByUserId: null,
      manualPermissionsOpen: false,
      form: {
        email: '',
        firstName: '',
        lastName: '',
        companyPermissions: Object.fromEntries(
          Object.values(companyPermissions).map((permissionName) => [permissionName, undefined]),
        ),
        lang: this.$i18n.locale,
      },
    }
  },
  validations: {
    form: {
      email: { required, email },
      firstName: { required },
      lastName: { required },
      companyPermissions: Object.fromEntries(
        Object.values(companyPermissions).map((permissionName) => [permissionName, {}]),
      ),
    },
  },
  apollo: {
    companyUsers: {
      query: companyUsers,
      variables() {
        return {
          companyId: this.currentCompanyId,
        }
      },
      skip() {
        return !this.currentCompanyId
      },
      update(data) {
        this.permissionsByUserId = data.company.users[0]?.id
        return data.company.users
      },
    },
  },
  methods: {
    assignPermissionsByUser(userId) {
      const usersPermissions = this.companyUsers.find((user) => user.id === userId)
        ?.companyPermissions
      if (usersPermissions) {
        for (const key in this.form.companyPermissions) {
          this.form.companyPermissions[key] = usersPermissions[key]
        }
      }
    },
    async inviteNewUser() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const user = await this.$apollo.mutate({
            mutation: companyAddUser,
            variables: {
              companyId: this.currentCompanyId,
              userData: {
                email: this.form.email,
                firstName: this.form.firstName,
                lastName: this.form.lastName,
              },
              permissions: this.form.companyPermissions,
              lang: this.form.lang,
            },
            update: (
              store,
              {
                data: {
                  companyAddUser: { user },
                },
              },
            ) => {
              // update cache
              const query = {
                query: companyUsers,
                variables: {
                  companyId: this.currentCompanyId,
                },
              }
              const data = store.readQuery(query)
              const newData = {
                ...data,
                company: { ...data.company, users: [...data.company.users, user] },
              }
              store.writeQuery({
                ...query,
                data: newData,
              })

              return user
            },
          })

          this.$toast.success(
            user.enabled
              ? this.$t('users.userSuccessfullyAdded')
              : this.$t('users.invitationSuccess'),
          )

          this.$store.commit('setHasUnsavedChanges', false)
          this.$router.back()
        } catch (error) {
          console.error(error)
          const parsedError = error.message?.replace('GraphQL error:', '').trim()
          const errorMessages = {
            COMPANY_ADD_USER_ALREADY_INVITED: this.$t('users.userInviteUserAlreadyInvited'),
            COMPANY_ADD_USER_ALREADY_IN_COMPANY: this.$t('users.userInviteUserAlreadyAdded'),
          }
          this.$toast.error(errorMessages[parsedError] || this.$t('users.invitationFailure'))

          if (errorMessages[parsedError]) {
            this.$store.commit('setHasUnsavedChanges', false)
            this.$router.back()
          }
        }
      }
    },
    onBeforeUnload() {
      if (this.hasUnsavedChanges) {
        // modern browsers will ignore this message and will show default
        return this.$t('global.unsavedChanges')
      }
    },
    cancel() {
      this.$store.commit('setHasUnsavedChanges', false)
      this.$router.back()
    },
  },
  watch: {
    permissionsByUserId(userId) {
      this.assignPermissionsByUser(userId)
    },
    manualPermissionsOpen(isOpen) {
      if (!isOpen) {
        this.assignPermissionsByUser(this.permissionsByUserId)
      }
    },
    'form.companyPermissions'() {
      this.$v.form.companyPermissions.$touch()
    },
    '$v.$anyDirty'(isDirty) {
      this.$store.commit('setHasUnsavedChanges', isDirty)
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId', 'currentCompanyPermissions']),
    ...mapGetters(['hasUnsavedChanges']),
    selectableUsers() {
      return this.companyUsers
        ?.filter(
          (user) =>
            !(
              user.companyPermissions[companyPermissions.COMPANY_ADMINISTRATION] &&
              !this.currentCompanyPermissions[companyPermissions.COMPANY_ADMINISTRATION]
            ),
        ) // only admins can see admins
        .map((user) => ({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`.trim().length
            ? `${user.firstName} ${user.lastName}`
            : user.email,
        }))
    },
    localeOptions() {
      return allowedLanguages.map((locale) => ({
        text: this.$t(`global.lang.${locale}`),
        value: locale,
      }))
    },
    emailErrors() {
      const errors = []
      if (!this.$v.form.email.$dirty) {
        return errors
      }
      !this.$v.form.email.required && errors.push(this.$t('global.formValidation.required'))
      !this.$v.form.email.email && errors.push(this.$t('global.formValidation.notEmail'))
      return errors
    },
    firstNameErrors() {
      const errors = []
      if (!this.$v.form.firstName.$dirty) {
        return errors
      }
      !this.$v.form.firstName.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
    lastNameErrors() {
      const errors = []
      if (!this.$v.form.lastName.$dirty) {
        return errors
      }
      !this.$v.form.lastName.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
    showBlockButtons: function () {
      return this.$vuetify.breakpoint.name === 'xs'
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('global.unsavedChanges'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload
  },
  destroyed() {
    window.onbeforeunload = undefined
    this.$store.commit('setHasUnsavedChanges', false)
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

main {
  flex: 1 1 auto;
}

.page-wrapper {
  min-height: 100%;
  max-width: 768px;
  border-right: 1px solid #eeeeee;

  .content-wrapper {
    border-bottom: 1px solid #eeeeee;

    .form-wrapper {
      max-width: 330px;
    }

    .brand-link {
      color: @color-primary;
    }
  }
}
</style>
