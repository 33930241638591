const acceptedLanguages = ['en', 'cs', 'de']

export const rootState = {
  state: {
    hasUnsavedChanges: false,
    isUploadingImages: false,
    locale: acceptedLanguages.includes(navigator.language.split('-')[0])
      ? navigator.language.split('-')[0]
      : 'en',
  },
  getters: {
    hasUnsavedChanges: (state) => state.hasUnsavedChanges,
    isUploadingImages: (state) => state.isUploadingImages,
  },
  mutations: {
    setHasUnsavedChanges(state, newValue) {
      state.hasUnsavedChanges = newValue
    },
    setIsUploadingImages(state, newValue) {
      state.isUploadingImages = newValue
    },
    // in App.vue is watcher that updates $i18n.locale
    changeLocale(state, newValue) {
      state.locale = newValue
    },
  },
}
