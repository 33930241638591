import gql from 'graphql-tag'

export const eventCodeReorderCoreCauses = gql`
  mutation eventCodeReorderCoreCauses($eventCodeId: ID!, $orderedCoreCauseIds: [ID!]!) {
    eventCodeReorderCoreCauses(
      input: { eventCodeId: $eventCodeId, orderedCoreCauseIds: $orderedCoreCauseIds }
    ) {
      success
    }
  }
`
