<template>
  <div>
    <div class="d-flex justify-space-between">
      <h2>{{ configMap.get(timeScheduleName).label }}</h2>
      <div v-if="timeSchedule[timeScheduleName].length" class="currently-set">
        <div class="d-flex">
          <div
            v-for="state of configMap.get(timeScheduleName).states"
            :key="state.name"
            class="current-value"
          >
            <div class="square" :style="{ backgroundColor: state.color }"></div>
            <span>{{ getCurrentlySetCount(state.name) }} {{ $t('global.hrs') }}</span>
          </div>
        </div>
      </div>
    </div>
    <p v-if="configMap.get(timeScheduleName).recommended" class="recommended-text">
      {{ $t('device.timeSchedule.recommended') }}: min.
      {{ configMap.get(timeScheduleName).recommended.min }} {{ $t('global.hrs') }} max.
      {{ configMap.get(timeScheduleName).recommended.max }} {{ $t('global.hrs') }}
    </p>

    <div class="form-input-rows-wrapper">
      <TimeScheduleStateSelect
        :items="selectableStates"
        item-value="name"
        v-model="newInterval.state"
        @input="newIntervalStateChange()"
        :label="$t('global.mode')"
        filled
        class="state-select input-to-shrink mr-4"
        hide-details
        dense
      />
      <v-icon class="mr-2">mdi-clock-outline</v-icon>
      <TimeScheduleTimeInput
        v-model="newInterval.begin"
        :schedule="timeSchedule[timeScheduleName]"
        :beginOrEnd="'begin'"
        :otherNewTime="newInterval.end"
        :granularity="timeScheduleConfig.granularity"
        @input="newIntervalChange()"
        @calendarScrollTo="$emit('calendarScrollTo', $event)"
        class="input-to-shrink mr-2"
      />
      <TimeScheduleTimeInput
        v-model="newInterval.end"
        :schedule="timeSchedule[timeScheduleName]"
        :beginOrEnd="'end'"
        :otherNewTime="newInterval.begin"
        :granularity="timeScheduleConfig.granularity"
        @input="newIntervalChange()"
        @calendarScrollTo="$emit('calendarScrollTo', $event)"
        class="input-to-shrink mr-2"
      />
      <div>
        <v-btn
          @click="addNewInterval()"
          :disabled="
            newInterval.state == null || newInterval.begin == null || newInterval.end == null
          "
          color="primary"
          depressed
          >{{ $t('global.add') }}</v-btn
        >
      </div>

      <div
        v-for="(interval, index) of timeSchedule[timeScheduleName].filter(
          (interval) => !interval.wip,
        )"
        :key="interval.id"
        class="interval-input mb-4"
      >
        <TimeScheduleStateSelect
          :items="selectableStates"
          item-value="name"
          :value="interval.state"
          @input="intervalStateChange(index, $event)"
          filled
          class="state-select input-to-shrink mr-4"
          hide-details
          dense
        />
        <v-icon class="mr-2">mdi-clock-outline</v-icon>
        <TimeScheduleTimeInput
          :value="interval.begin"
          :schedule="timeSchedule[timeScheduleName]"
          :intervalId="interval.id"
          :beginOrEnd="'begin'"
          :granularity="timeScheduleConfig.granularity"
          @input="intervalChange(index, 'begin', $event)"
          @calendarScrollTo="$emit('calendarScrollTo', $event)"
          class="input-to-shrink mr-2"
        />
        <TimeScheduleTimeInput
          :value="interval.end"
          :schedule="timeSchedule[timeScheduleName]"
          :intervalId="interval.id"
          :beginOrEnd="'end'"
          :granularity="timeScheduleConfig.granularity"
          @input="intervalChange(index, 'end', $event)"
          @calendarScrollTo="$emit('calendarScrollTo', $event)"
          class="input-to-shrink mr-2"
        />
        <v-btn icon class="ml-1" @click="deleteInterval(index)"
          ><v-icon>mdi-delete-outline</v-icon></v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
import produce from 'immer'
import TimeScheduleStateSelect from '@/components/TimeSchedules/TimeScheduleStateSelect'
import TimeScheduleTimeInput from '@/components/TimeSchedules/TimeScheduleTimeInput'
import Big from 'big.js'

export default {
  name: 'TimeScheduleFormInput',
  components: { TimeScheduleTimeInput, TimeScheduleStateSelect },
  model: {
    prop: 'timeSchedule',
    event: 'change',
  },
  props: {
    timeScheduleConfig: Object,
    timeSchedule: Object, // expects sorted timeSchedule with intervals with smaller begin than end
    timeScheduleName: String,
  },
  data() {
    return {
      newInterval: {
        begin: null,
        end: null,
        state: null,
      },
    }
  },
  methods: {
    getCurrentlySetCount(state) {
      const sumSeconds = this.timeSchedule[this.timeScheduleName].reduce(
        (sum, curr) => (curr.state === state && !curr.wip ? sum + (curr.end - curr.begin) : sum),
        0,
      )
      return new Big(sumSeconds / 3600).round(1)
    },
    intervalChange(index, beginOrEnd, newValue) {
      const newTimeSchedule = produce(this.timeSchedule, (draft) => {
        draft[this.timeScheduleName][index][beginOrEnd] = newValue
        draft[this.timeScheduleName].sort((a, b) => a.begin - b.begin)
      })
      this.$emit('change', newTimeSchedule)
    },
    intervalStateChange(index, newState) {
      const newTimeSchedule = produce(this.timeSchedule, (draft) => {
        draft[this.timeScheduleName][index].state = newState
      })
      this.$emit('change', newTimeSchedule)
    },
    newIntervalChange() {
      if (
        this.newInterval.state != null &&
        this.newInterval.begin != null &&
        this.newInterval.end != null
      ) {
        const newTimeSchedule = produce(this.timeSchedule, (draft) => {
          const wipIntervalIndex = draft[this.timeScheduleName].findIndex(
            (interval) => interval.wip,
          )
          if (wipIntervalIndex !== -1) {
            draft[this.timeScheduleName][wipIntervalIndex] = { ...this.newInterval, wip: true }
          } else {
            draft[this.timeScheduleName].push({ ...this.newInterval, wip: true })
          }
          draft[this.timeScheduleName].sort((a, b) => a.begin - b.begin)
        })

        this.$emit('change', newTimeSchedule)
      }
    },
    newIntervalStateChange() {
      if (
        this.newInterval.state != null &&
        this.newInterval.begin != null &&
        this.newInterval.end != null
      ) {
        const newTimeSchedule = produce(this.timeSchedule, (draft) => {
          const wipIntervalIndex = draft[this.timeScheduleName].findIndex(
            (interval) => interval.wip,
          )
          if (wipIntervalIndex !== -1) {
            draft[this.timeScheduleName][wipIntervalIndex] = { ...this.newInterval, wip: true }
          } else {
            draft[this.timeScheduleName].push({ ...this.newInterval, wip: true })
          }
          draft[this.timeScheduleName].sort((a, b) => a.begin - b.begin)
        })
        this.$emit('change', newTimeSchedule)
      }
    },
    addNewInterval() {
      const newTimeSchedule = produce(this.timeSchedule, (draft) => {
        const wipIntervalIndex = this.timeSchedule[this.timeScheduleName].findIndex(
          (interval) => interval.wip,
        )
        draft[this.timeScheduleName][wipIntervalIndex].wip = undefined
      })

      this.$emit('change', newTimeSchedule)
      this.newInterval = {
        begin: null,
        end: null,
        state: null,
      }
    },
    deleteInterval(index) {
      const newTimeSchedule = produce(this.timeSchedule, (draft) => {
        draft[this.timeScheduleName].splice(index, 1)
      })

      this.$emit('change', newTimeSchedule)
    },
    removeWipIntervalIfExists() {
      const wipIntervalIndex = this.timeSchedule[this.timeScheduleName].findIndex(
        (interval) => interval.wip,
      )
      if (wipIntervalIndex !== -1) {
        const newTimeSchedule = produce(this.timeSchedule, (draft) => {
          draft[this.timeScheduleName].splice(wipIntervalIndex, 1)
        })

        this.$emit('change', newTimeSchedule)
      }
    },
  },
  computed: {
    selectableStates() {
      return this.configMap.get(this.timeScheduleName).states
    },
    configMap() {
      const configMap = new Map()
      this.timeScheduleConfig.schedules.forEach((timeSchedule) => {
        configMap.set(timeSchedule.name, timeSchedule)
      })
      return configMap
    },
    colorMap() {
      const colorMap = new Map()
      this.timeScheduleConfig.schedules
        .find((tc) => tc.name === this.timeScheduleName)
        .states.forEach((state) => {
          colorMap.set(state.name, state.color)
        })
      return colorMap
    },
  },
  watch: {
    timeSchedule(newSchedule, oldSchedule) {
      const oldWipInterval = oldSchedule[this.timeScheduleName].find((interval) => interval.wip)
      const wipInterval = newSchedule[this.timeScheduleName].find((interval) => interval.wip)
      if (wipInterval) {
        this.newInterval.begin = wipInterval.begin
        this.newInterval.end = wipInterval.end
        this.newInterval.state = wipInterval.state
      } else if (oldWipInterval) {
        // if we added or deleted wip interval from calendar we need to reset the form
        this.newInterval = {
          begin: null,
          end: null,
          state: null,
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';
@import '~@/components/TimeSchedules/timeSchedule.less';

h2 {
  font-size: 16px;
  font-weight: 400;
}

.state-select {
  width: 97px;
  /deep/.v-select__selections {
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 417px) {
  .input-to-shrink {
    /deep/.v-input__slot {
      padding: 0 5px !important;
    }
  }

  .state-select {
    width: 83px;
  }
}

.recommended-text {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.5;
  margin-bottom: 0;
  margin-top: 8px;
}

.currently-set {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
}

.add-first-text {
  color: #adadad;
}

.current-value {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-right: 14px;
  }

  span {
    font-size: 14px;
    opacity: 0.5;
  }

  .square {
    margin-right: 8px;
  }
}

.interval-input {
  display: contents;
  margin-bottom: 5px;
}

.form-input-rows-wrapper {
  display: grid;
  grid-template-columns: repeat(5, auto);
  align-items: center;
  justify-items: center;
  justify-content: start;
  gap: 16px 0px;
  margin-bottom: 32px;
  margin-top: 16px;
}
</style>
