import gql from 'graphql-tag'

export const deviceUpdateParameter = gql`
  mutation deviceUpdateParameter($deviceId: ID!, $paramName: NonEmptyString!, $newValue: String!) {
    deviceUpdateParameter(
      input: { deviceId: $deviceId, paramName: $paramName, newValue: $newValue }
    ) {
      success
    }
  }
`
