<template>
  <div>
    <DataTableCustom
      :headers="headers"
      :items="notificationDelays"
      :footer-props="{
        'items-per-page-text': $t('global.dataTable.itemsPerPageText'),
        'items-per-page-all-text': $t('global.dataTable.allItemsText'),
      }"
      class="pt-2 mb-12"
      hide-default-footer
      disable-pagination
      :no-data-text="$t('global.noCustomClasses')"
    >
      <template v-slot:item.notificationClass="{ item }">
        <NotificationClass
          :notificationClass="item.notificationClass"
          :customName="item.customName"
          class="py-1"
        />
      </template>
      <template v-slot:item.firstNotification="{ item, index }">
        <span class="d-block">{{
          item.delays[0] != null ? formatMinutes(item.delays[0]) : '---'
        }}</span>
        <span
          class="d-block light-text"
          v-if="defaultDelays && item.delays[0] !== defaultDelays[index].delays[0]"
          >({{ $t('global.defaultValue') }}:
          {{ formatMinutes(defaultDelays[index].delays[0]) }})</span
        >
      </template>
      <template v-slot:item.secondNotification="{ item, index }">
        <span class="d-block">{{
          item.delays[1] != null ? formatMinutes(item.delays[1]) : '---'
        }}</span>
        <span
          class="d-block light-text"
          v-if="defaultDelays && item.delays[1] !== defaultDelays[index].delays[1]"
          >({{ $t('global.defaultValue') }}:
          {{ formatMinutes(defaultDelays[index].delays[1]) }})</span
        >
      </template>
      <template v-if="updateFunction" v-slot:item.edit="{ item }">
        <v-btn v-if="item.delays.length" icon @click="openEditModal(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-tooltip v-if="withCustomName" top :disabled="!item.isUsed">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-inline-block">
              <v-btn
                icon
                @click="openDeleteConfirmationModal(item)"
                :disabled="item.isUsed"
                color="error"
                class="ml-4"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t('global.canNotDeleteUsedCustomNotificationClassTooltip') }}</span>
        </v-tooltip>
      </template>
    </DataTableCustom>

    <v-dialog v-model="editDelayModalOpen" width="unset">
      <div v-if="editedRow" class="edit-delays-modal-wrapper">
        <div class="d-flex align-center mb-7">
          <h2 class="mr-4">{{ $t('global.edit') }}:</h2>
          <NotificationClass
            :notificationClass="editedRow.notificationClass"
            :customName="editedRow.customName"
          />
        </div>
        <v-form @submit.prevent="updateDefaultNotificationClassDelays">
          <v-text-field
            v-if="withCustomName"
            v-model="newCustomName"
            :error-messages="getErrors('newCustomName')"
            :label="$t('global.name')"
            required
            @input="$v.newCustomName.$touch()"
            @blur="$v.newCustomName.$touch()"
            filled
          ></v-text-field>
          <DurationInput
            v-model="newFirstDelay"
            :label="$t('global.notificationDispatcher.firstNotification')"
            @input="$v.newFirstDelay.$touch()"
            @hasError="newFirstDelayHasError = $event"
            :errorMessages="newFirstDelayErrors"
          />
          <DurationInput
            v-if="editedRow.delays.length >= 2"
            v-model="newSecondDelay"
            :label="$t('global.notificationDispatcher.secondNotification')"
            @input="$v.newSecondDelay.$touch()"
            @hasError="newSecondDelayHasError = $event"
            :errorMessages="newSecondDelayErrors"
            class="mt-7"
          />
          <div class="mt-8 text-right">
            <v-btn @click="cancel()" depressed>{{ $t('global.cancel') }}</v-btn>
            <v-btn type="submit" color="primary" class="ml-3" depressed>{{
              $t('global.save')
            }}</v-btn>
          </div>
        </v-form>
      </div>
    </v-dialog>

    <ConfirmationDialog
      v-model="deleteCustomClassConfirmationModalOpen"
      :heading="$t('global.deleteCustomNotificationClassConfirmationModal.heading')"
      :text="
        $t('global.deleteCustomNotificationClassConfirmationModal.text', {
          className: classToDelete && classToDelete.customName,
        })
      "
      :action="deleteCustomClass"
      confirmBtnColor="error"
    />
  </div>
</template>
<script>
import NotificationClass from '@/components/NotificationDispatcher/NotificationClass'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import DurationInput from '@/components/Form/Input/DurationInput'
import { formatMinutes } from '@/utils/formatMinutes'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import vuelidateErrorsExtractor from '@/mixins/vuelidateErrorsExtractor'

export default {
  name: 'NotificationDelaysTable',
  mixins: [validationMixin, vuelidateErrorsExtractor],
  components: { NotificationClass, DurationInput, ConfirmationDialog },
  props: {
    notificationDelays: Array,
    updateFunction: Function,
    defaultDelays: Array,
    withCustomName: Boolean,
    deleteFunction: Function,
  },
  data() {
    return {
      editDelayModalOpen: false,
      editedRow: null,
      newFirstDelay: null,
      newSecondDelay: null,
      newFirstDelayHasError: false,
      newSecondDelayHasError: false,
      newCustomName: null,
      deleteCustomClassConfirmationModalOpen: false,
      classToDelete: null,
    }
  },
  validations() {
    return {
      newFirstDelay: { required, internalError: () => !this.newFirstDelayHasError },
      ...(this.editedRow?.delays.length >= 2 && {
        newSecondDelay: { required, internalError: () => !this.newSecondDelayHasError },
      }),
      ...(this.withCustomName && {
        newCustomName: { required },
      }),
    }
  },
  methods: {
    async updateDefaultNotificationClassDelays() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const newDelays = [this.newFirstDelay]
          if (this.editedRow.delays.length >= 2) {
            newDelays.push(this.newSecondDelay)
          }

          await this.updateFunction({
            id: this.editedRow.id,
            delays: newDelays,
            customName: this.newCustomName,
          })

          this.$toast.success(this.$t('global.updateNotificationClassDelays.success'))
          this.cancel()
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.updateNotificationClassDelays.failure'))
        }
      }
    },
    async deleteCustomClass() {
      try {
        await this.deleteFunction(this.classToDelete.id)
        this.deleteCustomClassConfirmationModalOpen = false
      } catch {
        // error is handled in parent
      }
    },
    openEditModal(rowData) {
      this.editedRow = rowData
      this.newFirstDelay = rowData.delays[0]
      this.newSecondDelay = rowData.delays[1]
      this.newCustomName = rowData.customName
      this.editDelayModalOpen = true
    },
    openDeleteConfirmationModal(item) {
      this.classToDelete = item
      this.deleteCustomClassConfirmationModalOpen = true
    },
    cancel() {
      this.editedRow = null
      this.newFirstDelay = null
      this.newSecondDelay = null
      this.editDelayModalOpen = false
    },
    formatMinutes,
  },
  computed: {
    newFirstDelayErrors() {
      const errors = []
      if (!this.$v.newFirstDelay.$dirty) {
        return errors
      }
      !this.$v.newFirstDelay.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
    newSecondDelayErrors() {
      const errors = []
      if (!this.$v.newSecondDelay?.$dirty) {
        return errors
      }
      !this.$v.newSecondDelay.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
    headers() {
      const headers = [
        {
          text: this.$t('global.notificationDispatcher.notificationClass'),
          value: 'notificationClass',
        },
        {
          text: this.$t('global.notificationDispatcher.firstNotification'),
          value: 'firstNotification',
        },
        {
          text: this.$t('global.notificationDispatcher.secondNotification'),
          value: 'secondNotification',
        },
      ]

      if (this.updateFunction) {
        headers.push({ value: 'edit', align: 'right' })
      }
      return headers
    },
  },
  watch: {
    editDelayModalOpen() {
      if (!this.editDelayModalOpen) {
        this.cancel()
      }
    },
  },
}
</script>
<style lang="less" scoped>
.edit-delays-modal-wrapper {
  background: white;
  padding: 20px;
}

.light-text {
  font-size: 12px;
  opacity: 0.75;
}
</style>
