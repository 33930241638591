<template>
  <div
    class="wrapper"
    :class="{
      unavailable: isUnavailable,
      open: isListOpen,
    }"
  >
    <v-btn
      v-if="!isUnavailable && control.settable && canEdit"
      icon
      class="edit-btn"
      @click="isListOpen = !pendingStateChange"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <div style="overflow: hidden">
      <p class="name mt-2">{{ control.label }}</p>
      <div class="d-flex align-center mt-2">
        <v-progress-circular
          v-if="pendingStateChange"
          class="mr-2"
          :size="20"
          :width="3"
          indeterminate
          color="#5ec7f2"
        ></v-progress-circular>
        <p class="value" :class="{ pending: pendingStateChange }">
          {{ currentValue }}
        </p>
      </div>
    </div>
    <v-slide-y-transition>
      <div v-click-outside="() => (isListOpen = false)" class="options-list" v-if="isListOpen">
        <div class="line"></div>
        <button
          v-for="item of control.options.values.filter((i) => i.value !== control.value)"
          :key="item.name"
          @click="setValue(item.value)"
          class="option-btn"
        >
          {{ item.label }}
        </button>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
import { deviceControl } from '@/graphql/mutations/deviceControl'

export default {
  name: 'EnumControl',
  props: {
    control: Object,
    deviceId: String,
    isOffline: Boolean,
    connectionStatusChangedTime: String,
    canEdit: Boolean,
  },
  data() {
    return {
      currentValue: this.getLabelForValue(this.control.value),
      isListOpen: false,
      pendingStateChange: false,
    }
  },
  computed: {
    value() {
      if (this.control.value == null) {
        return '---'
      }
      const selectedItem = this.control.options.values.find(
        (item) => item.value === this.control.value,
      )
      return selectedItem.label || this.control.value
    },
    isUnavailable() {
      return this.isOffline || this.control.forbiddenByTechnology
    },
  },
  methods: {
    async setValue(newValue) {
      try {
        this.isListOpen = false

        await this.$apollo.mutate({
          mutation: deviceControl,
          variables: {
            data: {
              deviceId: this.deviceId,
              name: this.control.name,
              value: newValue,
            },
          },
        })

        this.currentValue = this.getLabelForValue(newValue)
        this.pendingStateChange = true
        this.$emit('controlChanged')

        // temporary solution, it can happen that deviceControl does not fail, but will immediately switch back to previous value,
        // in this case toggle was in wrong state, this will be resolved when forbiddenByTechnology will be implemented
        setTimeout(() => {
          this.pendingStateChange = false
          this.currentValue = this.getLabelForValue(this.control.value)
        }, 7000)
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('device.controlUpdateFail'))
      }
    },
    getLabelForValue(value) {
      if (value == null) {
        return '---'
      }
      const selectedItem = this.control.options.values.find((item) => item.value === value)
      return selectedItem.label || value
    },
  },
  watch: {
    'control.value'() {
      this.currentValue = this.getLabelForValue(this.control.value)
      this.pendingStateChange = false
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.wrapper {
  position: relative;
  background-color: fade(@color-brand-highlight, 50%);
  border-radius: 3px;
  padding: 5px 5px 13px 18px;

  p {
    margin-bottom: 0;
  }

  .name {
    font-size: 12px;
    color: @color-brand-2;
    opacity: 0.5;
  }

  .value {
    font-size: 24px;
    font-weight: bold;
    color: @color-brand-2;

    &.pending {
      color: tint(@color-brand-2, 50%);
    }
  }

  .edit-btn {
    float: right;

    i {
      color: @color-brand !important;
    }
  }

  &.unavailable {
    background-color: fade(#ebebeb, 50%);

    .name,
    .value {
      color: gray;
    }

    .desired-value {
      color: #adadad;
    }
  }

  &.open {
    border: 1px solid #d4e3ea;
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    z-index: 1;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}

.options-list {
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  background-color: tint(@color-brand-highlight, 50%);
  border: 1px solid #d4e3ea;
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 1;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  clip-path: polygon(-30% 0%, 130% 0%, 130% 130%, -30% 130%);

  .option-btn {
    display: block;
    width: 100%;
    text-align: left;
    color: @color-brand-2;
    padding: 13px 18px;

    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }
  }

  .line {
    height: 1px;
    margin: 0px 18px;
    background: #d7e5f1;
  }
}
</style>
