<template>
  <div class="form-main--row">
    <div class="form-main--column">
      <label class="form-main--label" :for="variableName">{{ translatedLabel }}</label>
    </div>
    <div class="form-main--column">
      <div class="form-input--wrapper" :class="{ error: inputErrors.length }">
        <input
          @input="updateValue($event.target.value)"
          :value="currentValue"
          class="form-input form-input--post"
          type="text"
          :id="variableName"
        />
      </div>
      <p v-for="error in inputErrors" :key="`input_${name}_error_${error.error}`">
        {{ $t('device.validations.' + error.error) }}
      </p>
    </div>
  </div>
</template>

<script>
import valueFormatter from '../../../mixins/valueFormatter'
import { replaceDeviceParametersLabels } from '@/utils/replaceDeviceParametersLabels';

export default {
  name: 'BasicInput',
  mixins: [valueFormatter],
  data: function () {
    return {
      emptyValue: '',
      valueBackup: null,
      haveError: null,
    }
  },
  props: {
    label: String,
    name: String,
    value: { default: '' },
    variableName: String,
    unit: {
      type: String,
      required: false,
    },
    options: Object,
    otherData: Object,
    formItems: Array,
    errors: Array,
    required: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      required: false,
    },
  },
  methods: {
    validate: function () {
      if (this.options && this.options.required) {
        if (!this.value) {
          this.haveError = 'form.validation.required'
        }
      }

      return !this.haveError
    },
    updateValue: function (value) {
      this.$emit('input', value)
      this.haveError = null
    },
  },
  computed: {
    currentValue: function () {
      return this.value
    },
    translatedLabel: function () {
      const ret = this.label !== '' ? this.label : this.$t(`device.deviceAttributes.${this.name}`);
      return replaceDeviceParametersLabels(ret, this.otherData, this.formItems);
    },
    inputErrors: function () {
      if (this.errors) {
        return this.errors.filter((error) => error.name === this.name)
      } else {
        return []
      }
    },
  },
}
</script>

<style lang="less">
.form-main {
  font-family: Roboto, sans-serif;

  &--grid {
    margin: 14px 0 0;
  }

  &--row {
    display: grid;
    grid-template-columns: 186px 1fr;
    align-items: center;
    margin: 6px 0 0;
  }

  &--column {
    &:first-child {
      padding: 0 20px 0 0;
    }
  }

  &--label {
    height: 100%;
    color: #a3a3a3;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;

    [disabled] & {
      color: #d9d9d9;
      pointer-events: none;
    }

    &.required::after {
      content: '*';
      color: red;
      margin-left: 2px;
    }
  }

  @media (max-width: 767px) {
    &--row {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 479px) {
    &--row {
      grid-template-columns: 1fr;
      grid-gap: 8px;
    }
    &--column {
      &:first-child {
        padding: 0;
      }
    }
  }
}

.form-input {
  font-family: Roboto, sans-serif;
  border: 0.063rem solid #e8e8e8;
  background: #ffffff;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 36px;
  padding: 6px 12px;
  outline: none;
  transition: all 300ms ease;

  &:hover,
  &:focus {
    border-color: #1d2024;
  }

  &--wrapper {
    position: relative;
  }

  &--pre {
    padding: 6px 46px 6px 12px;

    &--text {
      user-select: none;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 15px;
      transform: translate(0, -50%);
      opacity: 0.5;
      color: #000;
      font-size: 1.5rem;
      font-weight: 400;
      width: 22px;
    }
  }

  &--special {
    padding: 6px 76px 6px 12px;

    &--text {
      position: absolute;
      top: 50%;
      right: 45px;
      transform: translate(0, -50%);
      opacity: 0.3;
      color: #1d2024;
      font-size: 0.875rem;
      font-weight: 400;
      width: 22px;
    }
  }

  &--post {
    padding: 6px 46px 6px 12px;

    &--text {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translate(0, -50%);
      opacity: 0.3;
      color: #1d2024;
      font-size: 0.875rem;
      font-weight: 400;
      width: 22px;
    }
  }

  &--only-negative {
    padding-left: 25px;
    position: relative;
    right: 0;
  }

  [disabled] & {
    color: rgba(29, 32, 36, 0.4);
    pointer-events: none;

    &::placeholder {
      color: rgba(29, 32, 36, 0.4);
    }
  }

  .error & {
    border-color: red;
  }

  &--hint {
    font-size: small;
    opacity: 0.5;
  }
}

.form-select {
  -webkit-appearance: none;
  cursor: pointer;
  border: 0.063rem solid #e8e8e8;
  background: #ffffff;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 36px;
  padding: 6px 46px 6px 12px;
  outline: none;
  transition: all 300ms ease;

  &:hover,
  &:focus {
    border-color: #1d2024;
  }

  &--wrapper {
    position: relative;
  }

  &--icon {
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translate(0, -50%);
    color: #1f97ff;
  }

  &--color {
    background: #1f97ff;
    border-color: #1f97ff;
    color: #ffffff;

    ~ .form-select {
      &--icon {
        color: #ffffff;
      }
    }
  }

  .error & {
    border-color: red;
  }
}

.form-switch {
  border: 0.063rem solid #e8e8e8;
  width: 42px;
  height: 22px;
  border-radius: 100px;
  cursor: pointer;
  position: relative;
  transition: all 300ms ease;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -2px;
    width: 22px;
    height: 22px;
    box-shadow: 0 0.188rem 0.375rem rgba(0, 0, 0, 0.16);
    background: #ffffff;
    border-radius: 100px;
    transition: all 300ms ease;
  }

  &--wrapper {
    margin: 12px 0;
  }

  &--input:checked {
    + .form-switch {
      background: #1f97ff;
      border-color: #1f97ff;

      &:before {
        transform: translate(100%, 0);
      }
    }
  }
}
</style>
