import gql from 'graphql-tag'

const prepareForm = gql`
  query PrepareForm($controller: NonEmptyString!, $lang: String!, $companyId: ID!) {
    prepareCreateForm(controller: $controller, lang: $lang) {
      deviceType
      defaultValues
      items {
        name
        label
        type
        unit
        options
        ... on CompoundFormItem {
          items {
            name
            label
            type
            unit
            options
          }
        }
      }
      presets(companyId: $companyId) {
        id
        name
        defaultValues
      }
    }
  }
`

const prepareFormForDevice = gql`
  query PrepareForm($deviceId: ID!, $lang: String!, $includeContactInfoAndAddress: Boolean!) {
    prepareUpdateForm(deviceId: $deviceId, lang: $lang) {
      defaultValues
      items {
        name
        label
        type
        unit
        options
        ... on CompoundFormItem {
          items {
            name
            label
            type
            unit
            options
          }
        }
      }
    }
    device(deviceId: $deviceId) {
      id
      name
      contactInfo @include(if: $includeContactInfoAndAddress) {
        name
        phone
        email
        note
      }
      address @include(if: $includeContactInfoAndAddress) {
        address
        code
        city
        lat
        lng
        placeId
        postalCode
      }
      liveDeviceData {
        parameters
      }
    }
  }
`

export { prepareForm, prepareFormForDevice }
