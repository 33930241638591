export default {
  usersList: 'Lista użytkowników',
  companyList: 'Wykaz firm serwisowych',
  userDetail: 'Szczegóły użytkownika',
  serviceCompanyDetail: 'Szczegóły firmy serwisowej',
  addNewUser: 'Dodaj użytkownika',
  addNewCompany: 'Dodaj nową firmę serwisową',
  userPermissions: 'Pozwolenia użytkownika',
  serviceCompaniesPermissions: 'Uprawnienia firmy serwisowej',
  addUser: 'Dodaj użytkownika',
  addCompany: 'Dodaj firmę',
  setPermissions: 'Ustaw uprawnienia',
  search: 'Szukaj',
  permissions: {
    companyAdministration: 'Administracja firmy',
    companyUserManagement: 'Zarządzanie użytkownikami',
    templateManagement: 'Zarządzanie szablonem',
    productCatalogue: 'Klienci i produkty',
    otherCompaniesManagement: 'Zarządzanie innymi firmami',
    configurationUpload: 'Wgraj konfigurację',
    configurationChanges: 'Modyfikuj konfigurację',
    expertMode: 'Tryb Eksperta',
    deviceControl: 'Sterowanie urządzeniem',
    operationalHistory: 'Historia operacji',
    fullCustomerData: 'Pełne dane klienta',
    restrictedCustomerData: 'Ograniczone dane klienta',
    userManagement: 'Zarządzanie dostępem użytkownika',
    serviceSequences: 'Sekwencje serwisowe',
  },
  permissionsDescription: {
    companyAdministration: 'Zarządzanie kontem firmy.',
    companyUserManagement:
      'Dostęp do listy użytkowników, tworzenie i usuwanie kont użytkowników, ustawienia uprawnień dla innych użytkowników (z wyjątkiem kont administratora).',
    templateManagement:
      'Dostęp do szablonów firmowych, tworzenie, aktualizacja i usuwanie szablonów.',
    productCatalogue: 'Dostęp do katalogu produktów.',
    otherCompaniesManagement: 'Wyświetlanie i zarządzanie urządzeniami firm partnerskich.',
    configurationUpload: 'Prześlij konfigurację do urządzenia.',
    configurationChanges: 'Twórz nowe i edytuj urządzenia bez wysyłania ustawień.',
    expertMode: 'Uzyskaj dostęp do trybu eksperta.',
    deviceControl:
      'Sterowanie urządzeniem w panelu sterowania, zmiana trybów pracy z wyjątkiem trybu eksperckiego.',
    operationalHistory:
      'Dostęp do historii urządzeń i wykresów technicznych, tworzenie zapisów i notatek serwisowych.',
    fullCustomerData: 'Wyświetl pełne dane kontaktowe',
    restrictedCustomerData: 'Wyświetl tylko miasto i kraj kontaktu',
    userManagement:
      'Dostęp do listy użytkowników, tworzenie i usuwanie praw dostępu, ustawianie uprawnień dla innych użytkowników, dostęp do ustawień powiadomień',
    serviceSequences: 'Uruchamianie i wyświetlanie postępu sekwencji serwisowych.',
  },
  userDeleteSuccess: 'Użytkownik został pomyślnie usunięty z firmy.',
  userDeleteFailure: 'Usunięcie użytkownika z firmy nie powiodło się.',
  serviceCompanyDeleteSuccess: 'Firma serwisowa została pomyślnie usunięta z firmy.',
  serviceCompanyDeleteFailure: 'Nie udało się usunąć firmy serwisowej z firmy.',
  userSuccessfullyAdded: 'Nowy użytkownik został pomyślnie dodany do firmy.',
  userInviteUserAlreadyInvited: 'Użytkownik został już zaproszony.',
  userInviteUserAlreadyAdded: 'Ten użytkownik jest już w firmie.',
  invitationSuccess: 'Zaproszenie pomyślnie wysłane.',
  invitationFailure: 'Nie udało się wysłać zaproszenia.',
  companyInviteNotAdminEmail: 'Podany adres e-mail nie należy do administratora żadnej firmy.',
  companyInvitationAlreadyInvited: 'Firma została już zaproszona.',
  companyInvitationAlreadyAdded: 'Firma jest już pod firmą.',
  companyInvitationCanNotInviteOwnCompany: 'Nie można zaprosić firmy do samego siebie.',
  userDeleteConfirmation: {
    heading: 'Usuń użytkownika z firmy',
    text: 'Czy na pewno chcesz usunąć tego użytkownika z firmy?',
  },
  serviceCompanyDeleteConfirmation: {
    heading: 'Usuń firmę serwisową',
    text: 'Czy na pewno chcesz usunąć tę firmę serwisową ze swojej firmy?',
  },
  userNotFound: 'Nie znaleziono użytkownika.',
  addNewUserDescription:
    'Wprowadź adres e-mail nowego użytkownika. Zaproszenie z linkiem aktywacyjnym zostanie wysłane na adres e-mail.',
  addNewCompanyDescription:
    'Wprowadź e-mail administratora firmy, którą chcesz dodać. Zaproszenie z linkiem aktywacyjnym zostanie wysłane na adres e-mail.',
  permissionsByUser: 'Użyj uprawnień istniejącego użytkownika',
  permissionsByCompany: 'Użyj uprawnień firmy zarządzającej',
  permissionsTitle: 'Uprawnienia',
  setPermissionsManually: 'Ustaw uprawnienia ręcznie',
  setPermissionsByUser: 'Użyj uprawnień istniejącego użytkownika',
  setPermissionsByCompany: 'Użyj uprawnień istniejącej firmy',
  sendInvitation: 'Wyślij zaproszenie',
  notEnabled: 'Użytkownik nie zaakceptował zaproszenia.',
  notEnabledCompany: 'Firma nie zaakceptowała zaproszenia.',
  noMatchingRecordsFound: 'Nie znaleziono pasującego wyniku.',
  noServiceCompanies: 'Brak firm serwisowych.',
  userAdminDeleteConfirmation: {
    heading: 'Usuń użytkownika',
    text: 'Czy na pewno chcesz usunąć tego użytkownika ({email})?',
  },
  userAdminDeleteSuccess: 'Użytkownik został pomyślnie usunięty.',
  userAdminDeleteFailure: 'Nie udało się usunąć użytkownika.',
}
