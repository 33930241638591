import gql from 'graphql-tag'

export const deviceTimeSchedules = gql`
  query deviceTimeSchedules($deviceId: ID!) {
    device(deviceId: $deviceId) {
      id
      dailyTimeSchedules {
        id
        name
        schedule
        isGlobal
        isUsed
      }
      weeklyTimeSchedules {
        id
        name
        weeklySchedule {
          days
          dailySchedule {
            id
          }
        }
        isGlobal
      }
      activeTimeSchedule {
        id
      }
    }
  }
`
