<template>
  <div class="wrapper">
    <div class="map" ref="map"></div>
    <v-overlay absolute :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-show="false" v-if="tooltipDevices" ref="tooltip">
      <div class="tooltip-content-wrapper">
        <div v-for="device of tooltipDevices" :key="device.id" class="device-info">
          <button
            :onclick="`window.dispatchEvent(new CustomEvent('device-link-click', { detail: '${device.id}'}))`"
          >
            {{ device.name }}
          </button>
          <div
            v-if="device.eventDetectionPoints && device.eventDetectionPoints.length"
            class="mt-3 reports-wrapper"
          >
            <ReportBadge
              v-for="eventCode of getSortedErrors(device.eventDetectionPoints)"
              :key="`${eventCode.description}|${eventCode.severity}`"
              :text="eventCode.description"
              :severity="eventCode.severity"
              :clickable="false"
              :deviceId="device.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MarkerClusterer from '@googlemaps/markerclustererplus'
import ReportBadge from '@/components/UI/ReportBadge'
import { hasErrorOfSeverity } from '@/utils/hasErrorOfSeverity'

export default {
  name: 'DeviceListPageMapView',
  components: { ReportBadge },
  props: {
    userDevices: Array,
    isLoading: Boolean,
  },
  data() {
    return {
      map: null,
      markers: new Map(),
      markerClusterer: null,
      tooltipDevices: null,
      infoWindow: new window.google.maps.InfoWindow(),
      deviceLinkClickListener: null,
      maxZoomService: new window.google.maps.MaxZoomService(),
    }
  },
  mounted() {
    this.deviceLinkClickListener = window.addEventListener('device-link-click', (e) => {
      this.$router.push({
        name: 'DevicePage',
        params: { deviceId: e.detail },
      })
    })

    this.map = new window.google.maps.Map(this.$refs.map, {
      zoom: 5,
      center: { lat: 49.2802, lng: 17.1179 },
      clickableIcons: false,
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          stylers: [{ visibility: 'off' }],
        },
      ],
    })

    this.map.addListener('click', () => {
      this.infoWindow.close()
    })

    this.devices?.forEach((device) => {
      this.createMarker(device)
    })

    // this determines color of cluster
    const calc = function (markers) {
      // default to blue
      let color = 1
      for (const marker of markers) {
        if (marker.getIcon().indexOf('marker-critical.svg') > -1) {
          return { text: markers.length, index: 4 } // index of critical error
        }
        if (marker.getIcon().indexOf('marker-error.svg') > -1) {
          color = 3 // index of error
        }
        if (marker.getIcon().indexOf('marker-warning.svg') > -1) {
          color = 2 // index of warning
        }
      }
      return { text: markers.length, index: color }
    }

    this.markerClusterer = new MarkerClusterer(this.map, [...this.markers.keys()], {
      imagePath: '/cluster',
      imageExtension: 'svg',
      averageCenter: true,
      imageSizes: [32, 32, 32, 32],
      ignoreHidden: true,
    })
    this.markerClusterer.setCalculator(calc)
    window.google.maps.event.addListener(this.markerClusterer, 'click', async (cluster) => {
      const markers = cluster.getMarkers()
      const maxZoom = await this.getMaxZoom(cluster.center_)
      if (this.map.getZoom() >= maxZoom) {
        this.tooltipDevices = markers.map((marker) => this.markers.get(marker))
        this.$nextTick(() => {
          this.infoWindow.setContent(this.$refs.tooltip.innerHTML)
          this.infoWindow.setPosition(cluster.getCenter())
          this.infoWindow.open({
            map: this.map,
            shouldFocus: false,
          })
        })
      }
    })

    this.$nextTick(() => this.devices?.length && this.mapfitBounds())
  },
  beforeDestroy() {
    window.removeEventListener('device-link-click', this.deviceLinkClickListener)
  },
  methods: {
    getIcon(device) {
      if (hasErrorOfSeverity(device, 'CRITICAL')) {
        return '/marker-critical.svg'
      }
      if (hasErrorOfSeverity(device, 'ERROR')) {
        return '/marker-error.svg'
      }
      if (hasErrorOfSeverity(device, 'WARNING')) {
        return '/marker-warning.svg'
      }
      return '/marker-normal.svg'
    },
    createMarker(device) {
      let position
      let approximate = false
      if (device.address.lat && device.address.lng) {
        position = {
          lat: device.address.lat,
          lng: device.address.lng,
        }
      } else if (device.address.approximateLat && device.address.approximateLng) {
        approximate = true
        position = {
          lat: device.address.approximateLat,
          lng: device.address.approximateLng,
        }
      } else {
        return null
      }
      const newMarker = new window.google.maps.Marker({
        position,
        icon: this.getIcon(device),
      })
      this.markers.set(newMarker, device)

      if (approximate) {
        const circle = new window.google.maps.Circle({
          radius: 450,
          fillColor: '#fff',
          fillOpacity: 0.6,
          strokeColor: '#313131',
          strokeOpacity: 0.4,
          strokeWeight: 0.8,
        })

        circle.bindTo('center', newMarker, 'position')
        circle.bindTo('map', newMarker, 'map')
      }

      newMarker.addListener('click', () => {
        this.tooltipDevices = [device]
        this.$nextTick(() => {
          this.infoWindow.setContent(this.$refs.tooltip.innerHTML)
          this.infoWindow.open({
            anchor: newMarker,
            map: this.map,
            shouldFocus: false,
          })
        })
      })

      return newMarker
    },
    getMapBounds() {
      return this.devices.reduce(
        (acc, device) => {
          const lat = device.address.lat ?? device.address.approximateLat
          const lng = device.address.lng ?? device.address.approximateLng
          if (lat && lng) {
            if (lat < acc.south) {
              acc.south = lat
            }
            if (lat > acc.north) {
              acc.north = lat
            }
            if (lng < acc.west) {
              acc.west = lng
            }
            if (lng > acc.east) {
              acc.east = lng
            }
          }
          return acc
        },
        { south: 90, north: -90, west: 180, east: -180 },
      )
    },
    mapfitBounds() {
      this.map.fitBounds(this.getMapBounds())

      // move center of a map after fitBounds to get rid of the cluster bug
      const mapCenter = this.map.getCenter()
      this.map.setCenter({ lat: mapCenter.lat() + 0.0000001, lng: mapCenter.lng() })
    },
    getMaxZoom(latLng) {
      return new Promise((resolve) => {
        this.maxZoomService.getMaxZoomAtLatLng(latLng, (result) => {
          if (result.status !== 'OK') {
            console.error('getMaxZoomAtLatLng failed returning default')
            resolve(18)
          } else {
            resolve(result.zoom)
          }
        })
      })
    },
    getSortedErrors(eventDetectionPoints) {
      return eventDetectionPoints
        .flatMap((eop) => eop.eventCodes)
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    },
  },
  computed: {
    devices() {
      if (!this.userDevices || this.isLoading) {
        return null
      }
      return this.userDevices
    },
  },
  watch: {
    devices() {
      this.markers.clear()
      this.markerClusterer.clearMarkers()
      this.devices.forEach((device) => {
        const newMarker = this.createMarker(device)
        if (newMarker) {
          this.markers.set(newMarker, device)
        }
      })
      this.markerClusterer.addMarkers([...this.markers.keys()])

      this.mapfitBounds()
    },
  },
}
</script>
<style lang="less" scoped>
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .map {
    width: 100%;
    height: 100%;
  }
}

/deep/.tooltip-content-wrapper {
  .device-info {
    padding: 5px;

    &:not(:first-child) {
      border-top: 1px dashed grey;
      padding-top: 10px;
      margin-top: 10px;
    }
  }

  button {
    font-size: 20px;
    font-weight: 500;
    color: #454d57;

    &:hover {
      text-decoration: underline;
    }
  }

  .reports-wrapper > .badge-wrapper:not(:first-child) {
    margin-top: 3px;
  }
}

/deep/div[role='dialog'] button[title='Close'] {
  display: none !important;
}

/deep/.cluster > div {
  color: white !important;
}
</style>
