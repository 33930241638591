import gql from 'graphql-tag'

export const thingTypes = gql`
  query thingTypes($withEligibilities: Boolean! = false) {
    thingTypes {
      name
      businessName
      thingTypeFamilyId
      eligibilities @include(if: $withEligibilities) {
        name
        defaultValue
        defaultAvailability
      }
    }
  }
`
