export default {
  pageTitle: 'Witaj w iXsolve',
  login: 'Zaloguj się',
  register: 'Zarejestruj się',
  logIn: 'Zaloguj się',
  password: 'Hasło',
  passwordAgain: 'Powtórz hasło',
  forgotPassword: 'Nie pamiętasz hasła?',
  registerNewAccount: 'Zarejestruj nowe konto',
  signIn: 'Utwórz konto',
  haveAccount: 'Mam już konto',
  error: { wrongUsernameOrPassword: 'Nieprawidłowy e-mail lub hasło.' },
  backToLogin: 'Powrót do logowania',
  resetPassword: 'Resetuj hasło',
  forgotPasswordTitle: 'Zresetuj hasło',
  confirmAccountTitle: 'Potwierdź swoje konto.',
  confirmationCode: 'Kod potwierdzający',
  sendConfirmation: 'Potwierdź konto',
  requestNewCode: 'Poproś o nowy kod',
  confirmAccountInEmail: 'Konto zostało pomyślnie utworzone, sprawdź swój e-mail i potwierdź.',
  changePassword: 'Zmień hasło',
  resetPasswordTitle: 'Zresetuj hasło',
  resetPasswordText:
    'Kod weryfikacyjny został wysłany na Twój adres e-mail. Skopiuj kod weryfikacyjny do pola poniżej i ustaw nowe hasło.',
  resetPasswordCode: 'Kod weryfikacyjny z e-maila',
  resetPasswordSuccess: 'Hasło zostało pomyślnie zaktualizowane.',
  resetPasswordInvalidCode: 'Nieprawidłowy kod weryfikacyjny.',
}
