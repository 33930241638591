<template>
  <div>
    <v-btn color="primary" depressed class="mb-4" @click="createDetectionPointModalOpen = true">{{
      $t('global.adminDetectionPoints.addDetectionPoint')
    }}</v-btn>
    <DataTableCustom
      :headers="headers"
      :items="detectionPoints"
      :loading="$apollo.loading"
      :search.sync="search"
      :footer-props="{
        'items-per-page-text': $t('global.dataTable.itemsPerPageText'),
        'items-per-page-all-text': $t('global.dataTable.allItemsText'),
      }"
      class="elevation-1 pt-2 mb-12"
      :style="{ maxWidth: '1000px' }"
      :item-class="() => 'row-wrapper'"
      @click:row="
        (item) => {
          $router.push({
            name: 'AdminDetectionPointDetail',
            params: { id: item.id },
          })
        }
      "
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-text-field v-model="search" :label="$t('users.search')" class="mx-4"></v-text-field>
      </template>
    </DataTableCustom>

    <v-dialog v-model="createDetectionPointModalOpen" width="unset">
      <v-card>
        <div class="pa-6">
          <h2 class="mb-6">
            {{ $t('global.adminDetectionPoints.addDetectionPoint') }}
          </h2>
          <v-form @submit.prevent="createDetectionPoint()">
            <v-text-field
              v-model="form.name"
              :error-messages="getErrors('form.name')"
              :label="$t('global.name')"
              @input="$v.form.name.$touch()"
              @blur="$v.form.name.$touch()"
              filled
              style="width: 330px"
            ></v-text-field>
            <v-textarea
              v-for="lang of allowedLanguages"
              :key="lang"
              v-model="form.descriptionTranslations[lang]"
              :error-messages="getErrors(`form.descriptionTranslations.${lang}`)"
              @input="$v.form.descriptionTranslations[lang].$touch()"
              @blur="$v.form.descriptionTranslations[lang].$touch()"
              filled
              style="width: 600px"
              counter="255"
              rows="2"
            >
              <template v-slot:label>
                {{ $t('global.adminEventCode.description') }}
                <v-icon class="ml-1" style="vertical-align: middle">{{ `$${lang}` }}</v-icon>
              </template>
            </v-textarea>
            <div class="mt-8 text-right">
              <v-btn @click="createDetectionPointModalOpen = false" depressed>{{
                $t('global.close')
              }}</v-btn>
              <v-btn type="submit" color="primary" class="ml-3" depressed>{{
                $t('global.submit')
              }}</v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import vuelidateErrorsExtractor from '@/mixins/vuelidateErrorsExtractor'
import { required, maxLength } from 'vuelidate/lib/validators'
import { eventDetectionPointsAdmin } from '@/graphql/query/eventDetectionPointsAdmin'
import { detectionPointCreate } from '@/graphql/mutations/detectionPointCreate'
import { allowedLanguages } from '@/translations'

export default {
  name: 'AdminDetectionPoints',
  mixins: [validationMixin, vuelidateErrorsExtractor],
  data() {
    return {
      headers: [
        { text: this.$t('global.detectionPoint'), value: 'name' },
        { text: this.$t('global.description'), value: 'description' },
      ],
      search: '',
      createDetectionPointModalOpen: false,
      form: {
        name: null,
        descriptionTranslations: Object.fromEntries(allowedLanguages.map((lang) => [lang, null])),
      },
      detectionPointToDelete: null,
      confirmDeleteModalOpen: false,
    }
  },
  validations: {
    form: {
      name: { required },
      descriptionTranslations: Object.fromEntries(
        allowedLanguages.map((lang) => [lang, { maxLength: maxLength(255) }]),
      ),
    },
  },
  apollo: {
    detectionPoints: {
      query: eventDetectionPointsAdmin,
      variables() {
        return {
          lang: this.$i18n.locale,
        }
      },
      update(data) {
        return data.eventDetectionPointsAdmin
      },
    },
  },
  methods: {
    async createDetectionPoint() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const nullInsteadOfEmptyString = (string) => (string?.trim() === '' ? null : string)
          await this.$apollo.mutate({
            mutation: detectionPointCreate,
            variables: {
              name: this.form.name,
              descriptionTranslations: Object.fromEntries(
                allowedLanguages.map((lang) => [
                  lang,
                  nullInsteadOfEmptyString(this.form.descriptionTranslations[lang]),
                ]),
              ),
            },
            refetchQueries: ['eventDetectionPointsAdmin'],
          })

          this.$toast.success(
            this.$t('global.adminDetectionPoints.createDetectionPointAction.success'),
          )
          this.createDetectionPointModalOpen = false
        } catch (error) {
          console.error(error)
          const parsedError = error.message?.replace('GraphQL error:', '').trim()
          this.$toast.error(
            parsedError === 'DETECTION_POINT_CREATE_NAME_TAKEN'
              ? this.$t('global.nameTakenError')
              : this.$t('global.adminDetectionPoints.createDetectionPointAction.failure'),
          )
        }
      }
    },
  },
  computed: {
    allowedLanguages() {
      return allowedLanguages
    },
  },
  watch: {
    createDetectionPointModalOpen() {
      if (!this.createDetectionPointModalOpen) {
        this.form.name = null
        this.form.descriptionTranslations = Object.fromEntries(
          allowedLanguages.map((lang) => [lang, null]),
        )
        this.$v.$reset()
      }
    },
  },
}
</script>
<style lang="less" scoped>
/deep/.row-wrapper {
  cursor: pointer;
}
</style>
