<template>
  <div class="form-main--row">
    <div class="form-main--column">
      <label class="form-main--label" :for="variableName">{{ translatedLabel }}</label>
    </div>
    <div class="form-main--column">
      <div class="form-input--wrapper" :class="{ error: inputErrors.length }">
        <div v-if="attributes.onlyNegative" class="form-input--pre--text">-</div>
        <input
          v-if="unit !== 'V_PPM'"
          @blur="updateValue($event)"
          :value="currentValue"
          v-bind="attributes"
          :id="variableName"
          class="form-input form-input--post"
          :class="{ 'form-input--only-negative': attributes.onlyNegative }"
          type="number"
          :min="parseFloat(formatValue(attributes.min, unit, options))"
          :max="parseFloat(formatValue(attributes.max, unit, options))"
        />
        <div v-if="unit !== 'V_PPM'" class="form-input--post--text">
          {{ formatUnit(value, unit, options) }}
        </div>
        <input
          v-if="unit === 'V_PPM'"
          @blur="updateValue($event)"
          :value="currentValue"
          v-bind="attributes"
          :id="variableName"
          class="form-input form-input--special"
          :class="{ 'form-input--only-negative': attributes.onlyNegative }"
          type="number"
          :min="parseFloat(formatValue(attributes.min, unit, options))"
          :max="parseFloat(formatValue(attributes.max, unit, options))"
        />
        <div v-if="unit === 'V_PPM'" class="form-input--special--text">
          {{ formatUnit(value, unit, options) }}
        </div>
      </div>
      <p v-for="error in inputErrors" :key="`input_${name}_error_${error.error}`">
        {{ $t('device.validations.' + error.error) }}
      </p>
    </div>
  </div>
</template>

<script>
import BasicInput from '@/components/Form/Input/BasicInput'
import valueFormatter from '@/mixins/valueFormatter'

export default {
  name: 'NumberInput',
  extends: BasicInput,
  mixins: [valueFormatter],
  computed: {
    onlyNegative() {
      return this.options && this.options.onlyNegative
    },
    attributes() {
      const props = {
        step: 'any',
      }
      if (this.options) {
        if (this.options.step != null) {
          props.step = this.options.step
        }
        if (this.options.min !== undefined) {
          props.min = this.options.min
        }
        if (this.options.max !== undefined) {
          props.max = this.options.max
        }
        props.onlyNegative = !!this.onlyNegative
        if (props.onlyNegative) {
          props.min = -this.options.max
          props.max = -this.options.min
        }
      }
      return props
    },
    currentValue: function () {
      if (this.onlyNegative) {
        return -this.formatValue(this.value, this.unit, this.options)
      }
      return this.formatValue(this.value, this.unit, this.options)
    },
  },
  methods: {
    updateValue: function (event) {
      if (event.data !== '.') {
        this.$emit(
          'input',
          this.onlyNegative
            ? -Number(this.toBaseUnits(event.target.value, this.unit, this.options))
            : Number(this.toBaseUnits(event.target.value, this.unit, this.options)),
        )
        this.haveError = null
      }
    },
  },
}
</script>
