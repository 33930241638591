import store from '@/store'
import pick from 'lodash/pick'

/**
 * redirect to correct device type or to no devices
 */
export const deviceTypeGuard = async (to, from, next) => {
  const availableDeviceTypes = store.getters['user/availableDeviceTypes']
  if (to.name === 'NoDevicesPage' && !availableDeviceTypes?.length) {
    next()
  }

  // if there is invitation in a query, we need to preserve it, otherwise corresponding modal won't be open
  const newQuery = pick(
    to.query,
    'email',
    'createCompanyToken',
    'serviceCompanyToken',
    'deviceServiceCompanyToken',
  )

  if (!availableDeviceTypes?.length) {
    next({ name: 'NoDevicesPage', query: newQuery })
  } else {
    if (to.name === 'NoDevicesPage') {
      next({ name: 'DeviceListPage', params: { type: availableDeviceTypes[0] }, query: newQuery })
    }
    const targetDeviceType = to.params.type
    if (availableDeviceTypes.includes(targetDeviceType)) {
      next()
    } else {
      next({ name: 'DeviceListPage', params: { type: availableDeviceTypes[0] }, query: newQuery })
    }
  }
}
