<template>
  <div class="wrapper">
    <Value
      v-for="item of tabConfig.items"
      :key="item.name"
      :label="item.label"
      :value="getValue(item)"
      :setValueProps="
        item.canSetInTab &&
        permissionChecker(permissions.CONFIGURATION_CHANGES, eligibilities.DEVICE_CONFIGURATION)
          ? {
              name: item.name,
              deviceId: device.id,
              unit: item.unit,
              rawValue: item.value,
              options: item.options,
            }
          : null
      "
    />
  </div>
</template>
<script>
import valueFormatter from '@/mixins/valueFormatter'
import Value from '@/components/UI/Value'
import { permissions } from '@/config/permissions'
import { eligibilities } from '@/config/eligibilities'

export default {
  name: 'DisplayTab',
  mixins: [valueFormatter],
  components: { Value },
  props: {
    tabConfig: Object,
    device: Object,
    permissionChecker: Function,
  },
  methods: {
    getValue(item) {
      switch (item.type) {
        case 'NUMBER':
          return this.formatPrintValue(
            item.value,
            item.unit === 'SECOND' ? 'HH_FORMAT' : item.unit,
            item.options,
          )
        case 'BOOLEAN':
          return this.formatPrintValue(item.value, 'BOOLEAN')
        case 'ENUM':
          return item.formattedValue
        case 'STRING':
          return item.value
        default:
          return '-'
      }
    },
  },
  computed: {
    permissions() {
      return permissions
    },
    eligibilities() {
      return eligibilities
    },
  },
}
</script>
<style lang="less" scoped>
.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 5px;
}
</style>
