<template>
  <div class="product-row">
    <DataTableCustom
      :headers="headers"
      :items="filteredDevices"
      :search.sync="search"
      :loading="$apollo.loading"
      :no-data-text="$t('users.noMatchingRecordsFound')"
      :no-results-text="$t('users.noMatchingRecordsFound')"
      :footer-props="{
        'items-per-page-text': $t('global.dataTable.itemsPerPageText'),
        'items-per-page-all-text': $t('global.dataTable.allItemsText'),
      }"
      class="elevation-1 pt-2"
      @click:row="rowClick"
      :getFilterableStrings="getFilterableStrings"
    >
      <template v-slot:top>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="search" :label="$t('users.search')"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                :items="baseProductOptions"
                :label="$t('global.baseProduct')"
                v-model="baseProduct"
                item-text="name"
                item-value="name"
                clearable
                return-object
                class="mx-4"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.name="{ item }">
        <DeviceNameTableItem :device="item" />
      </template>

      <template v-slot:item.newProduct.template.name="{ item }">
        <span v-if="item.newProduct?.template" class="product-link"
          >{{ item.newProduct.template.name }}<v-icon small class="pl-2">mdi-pencil</v-icon></span
        >
        <span v-else>&ndash;</span>
      </template>
    </DataTableCustom>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DeviceNameTableItem from '@/components/DeviceNameTableItem'
import DataTableCustom from '@/components/DataTableCustom'
import { companyDevicesWithProduct } from '../../graphql/query/companyDevicesWithProduct'

export default {
  name: 'DeviceListWithNewProduct',
  components: {
    DeviceNameTableItem,
    DataTableCustom,
  },
  data() {
    return {
      search: '',
      baseProduct: null,
      baseProductOptions: [],
      headers: [
        { text: this.$t('global.title'), value: 'name', sortable: false },
        { text: this.$t('global.baseProduct'), value: 'newProduct.template.name' },
      ],
      allDevices: [],
    }
  },
  apollo: {
    devices: {
      query: companyDevicesWithProduct,
      variables() {
        return { companyId: this.currentCompanyId }
      },
      update(data) {
        this.baseProductOptions = [
          ...new Set(
            data.company.devices.edges
              .map((edge) => edge.node.newProduct?.template?.name)
              .filter(Boolean),
          ),
        ].map((name) => ({ name: name }))
        this.allDevices = data.company.devices.edges.map((edge) => edge.node)
        return [] // Handled by computed property
      },
    },
  },
  methods: {
    getFilterableStrings(item) {
      const deviceName = item.name
      const productName = item.newProduct?.template?.name || ''
      return [deviceName, productName]
    },
    rowClick(item) {
      if (item.newProduct?.template) {
        this.redirectToProductPage(item.newProduct.id)
      } else {
        this.redirectToNewProductPage(item)
      }
    },
    redirectToProductPage(productId) {
      this.$router.push({
        name: 'ProductDetailPage_new',
        params: { productId, fromProductSettings: true },
      })
    },
    redirectToNewProductPage(device) {
      this.$router.push({
        name: 'AddDeviceProductPage',
        params: {
          controllerId: device.id,
          deviceName: `${device.name}-${device.controller}`,
          thingType: device.thingType.name,
          fromProductSettings: true,
        },
      })
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    filteredDevices() {
      if (!this.baseProduct) {
        return this.allDevices // Return all devices if no baseProduct is selected
      }
      return this.allDevices.filter(
        (device) => device.newProduct?.template?.name === this.baseProduct.name,
      )
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/variables.less';

.product-link {
  display: flex;
}

.product-row {
  cursor: pointer;
}
</style>
