export const eligibilities = {
  DEVICE_CONFIGURATION: 'deviceConfiguration',
  DEVICE_CONTROL: 'deviceControl',
  SET_TARGET_PH: 'setTargetPh',
  SET_TARGET_ORP: 'setTargetORP',
  SET_TARGET_TEMPERATURE: 'setTargetTemperature',
  EXPERT_MODE: 'expertMode',
  TIME_SCHEDULE_SELECTION: 'timeScheduleSelection',
  TIME_SCHEDULE_EDIT: 'timeScheduleEdit',
  GALLERY_VIEW: 'galleryView',
  GALLERY_EDIT: 'galleryEdit',
  EVENT_CORE_CAUSES: 'eventCoreCauses',
  EVENT_CORRECTIVE_ACTIONS: 'eventCorrectiveActions',
  ORDER_SERVICE_FROM_APP: 'orderServiceFromApp',
  ORDER_SERVICE_FROM_APP_FROM_EVENT: 'orderServiceFromAppFromEvent',
  DEMO_MODE: 'demoMode',
  BESGO_VALVE_BACKWASH: 'besgoValveBackwash',
  DOSING_SERVICE_SEQUENCES: 'dosingServiceSequences',
  NOTIFICATION_DISPATCHER: 'notificationDispatcher',
  SERVICE_HISTORY: 'serviceHistory',
  ENERGY_CHARTS: 'energyCharts',
  OPERATION_COUNTERS: 'operationCounters',
  SERVICE_ORDERS: 'serviceOrders',
  SMS_NOTIFICATIONS: 'smsNotifications',
  CHARGING_HISTORY: 'chargingHistory',
  CHANGE_OPERATING_MODE: 'changeOperatingMode',
}
