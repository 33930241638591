import gql from 'graphql-tag'

export const companyDeleteFromCompany = gql`
  mutation companyDeleteFromCompany($parentCompanyId: ID!, $childCompanyId: ID!) {
    companyDeleteFromCompany(
      input: { parentCompanyId: $parentCompanyId, childCompanyId: $childCompanyId }
    ) {
      success
    }
  }
`
