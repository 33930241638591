<template>
  <v-dialog :value="isOpen" @input="$emit('modalToggle', $event)" max-width="1000" max-height>
    <div class="wrapper">
      <h1 class="mb-4">{{ $t('global.troubleshooting') }}</h1>
      <div
        v-for="(eventDetectionPoint, index) of eventDetectionPoints"
        :key="index"
        class="detection-point"
      >
        <h2 class="mb-3">{{ eventDetectionPoint.name }}</h2>
        <div>
          <div
            v-for="eventCode of getSortedEventCodes(eventDetectionPoint.eventCodes)"
            :key="`${eventCode.description}|${eventCode.severity}`"
            class="d-flex align-center mb-3"
          >
            <span class="datetime mr-4">{{ formatDate(eventCode.timestamp) }}</span>
            <ReportBadge
              :text="eventCode.description"
              :severity="eventCode.severity"
              :clickable="false"
              :deviceId="null"
            />
          </div>
        </div>
        <div
          v-if="
            permissionChecker([], eligibilities.EVENT_CORE_CAUSES) &&
            eventDetectionPoint.coreCauses.length
          "
          class="mb-4 mt-4"
        >
          <h3 class="mb-2">{{ $t('global.possibleCauses') }}</h3>
          <ol>
            <li
              v-for="(coreCause, index) of eventDetectionPoint.coreCauses"
              :key="index"
              class="mb-1"
            >
              {{ coreCause }}
            </li>
          </ol>
        </div>
        <div
          v-if="
            permissionChecker([], eligibilities.EVENT_CORRECTIVE_ACTIONS) &&
            eventDetectionPoint.correctiveActions.length
          "
          class="mt-4"
        >
          <h3 class="mb-2">{{ $t('global.correctiveMeassures') }}</h3>
          <ol>
            <li
              v-for="(correctiveAction, index) of eventDetectionPoint.correctiveActions"
              :key="index"
              class="mb-1"
            >
              {{ correctiveAction }}
            </li>
          </ol>
        </div>
      </div>
      <div class="mt-5 text-right">
        <v-btn @click="$emit('modalToggle', false)" height="27" depressed>{{
          $t('global.close')
        }}</v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { eventDetectionPoints } from '@/graphql/query/eventDetectionPoints'
import { eligibilities } from '@/config/eligibilities'
import format from 'date-fns/format'
import { errorsConfig } from '@/config/errorsConfig'

export default {
  name: 'ServiceHintsModal',
  components: { ReportBadge: () => import('@/components/UI/ReportBadge') },
  model: {
    prop: 'isOpen',
    event: 'modalToggle',
  },
  props: {
    isOpen: Boolean,
    deviceId: String,
    permissionChecker: Function,
  },
  apollo: {
    eventDetectionPoints: {
      query: eventDetectionPoints,
      variables() {
        return {
          deviceId: this.deviceId,
          lang: this.$i18n.locale,
          withCoreCauses: this.permissionChecker([], eligibilities.EVENT_CORE_CAUSES),
          withCorrectiveActions: this.permissionChecker([], eligibilities.EVENT_CORRECTIVE_ACTIONS),
        }
      },
      update(data) {
        return data.device.eventDetectionPoints
      },
    },
  },
  methods: {
    formatDate(timestamp) {
      return format(new Date(timestamp), 'd.M.y H:mm')
    },
    getSortedEventCodes(eventCodes) {
      return JSON.parse(JSON.stringify(eventCodes)).sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
      )
    },
  },
  computed: {
    eligibilities() {
      return eligibilities
    },
    errorsConfig() {
      return errorsConfig
    },
  },
}
</script>
<style lang="less" scoped>
.wrapper {
  background-color: white;
  padding: 20px;

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 14px;
  }

  li {
    font-size: 14px;
    font-weight: 500;
  }

  .detection-point {
    border: 2px solid #f5f5f5;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 15px;

    .datetime {
      color: #aaaaaa;
    }
  }
}
</style>
