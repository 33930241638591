<template>
  <div class="control-panel--control--item">
    <span class="mr-5">{{ label }}</span>
    <div class="control-panel--control--item--wrapper--inner">
      <div class="control-panel--control--input--wrapper" :class="{ error: !isValid }">
        <button
          @click="minusValue"
          :disabled="isMinusDisabled"
          class="control-panel--control--input--button control-panel--control--input--button--down"
        >
          -
        </button>
        <input
          v-if="editMode"
          @blur="disableInputMode"
          @focus="$event.target.select()"
          class="control-panel--control--input"
          type="number"
          step="any"
          v-model="inputValue"
          ref="input"
        />
        <div v-if="!editMode" @click="enableInputMode" class="control-panel--control--input">
          {{ formattedValue }}
        </div>
        <button
          @click="plusValue"
          :disabled="isPlusDisabled"
          class="control-panel--control--input--button control-panel--control--input--button--up"
        >
          +
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import valueFormatter from '@/mixins/valueFormatter'
import Big from 'big.js'

export default {
  name: 'NumberControlInput',
  mixins: [valueFormatter],
  props: {
    control: Object,
    label: String,
    value: [String, Number],
    isValid: Boolean,
  },
  data: function () {
    return {
      editMode: false,
      inputValue: null,
      isMinusDisabled: false,
      isPlusDisabled: false,
    }
  },
  created() {
    this.$emit(
      'input',
      this.formatValueDigits(
        this.control.desiredValue != null ? this.control.desiredValue : this.control.value,
        this.control.options,
      ),
    )
    this.validate()
  },
  watch: {
    value() {
      this.validate()
    },
  },
  computed: {
    unit: function () {
      return this.control.options.unit ?? null
    },
    formattedValue: function () {
      return this.formatPrintValue(this.value, this.control.options.unit, this.control.options)
    },
  },
  methods: {
    roundToNearestStep(value, step, direction) {
      // type transformations
      const stepString = String(step)
      // get number of floating points of the step
      const stepFixedString = stepString.split('.')[1] || ''
      const stepFixedLength = stepFixedString.length
      const stepMultiplier = 10 ** stepFixedLength
      const multipliedStep = step * stepMultiplier
      const remainder = parseFloat(
        new Big(value).times(stepMultiplier).mod(multipliedStep).div(stepMultiplier),
      )

      // if direction is "up" do this
      let subRemainderValue = new Big(value).minus(remainder).plus(step)

      if (direction === 'down') {
        subRemainderValue =
          remainder === 0 ? new Big(value).minus(step) : new Big(value).minus(remainder)
      }

      return parseFloat(subRemainderValue.toFixed(stepFixedLength))
    },
    plusValue: function () {
      const step = this.control.options.step ? parseFloat(this.control.options.step) : 1
      const max = this.control.options.max ? parseFloat(this.control.options.max) : null

      const currentValue = parseFloat(this.value)
      let newValue

      if (typeof max !== 'undefined') {
        if (currentValue + step <= max) {
          newValue = this.roundToNearestStep(currentValue, this.control.options.step, 'up')
        } else {
          newValue = max
        }
      } else {
        newValue = this.roundToNearestStep(currentValue, this.control.options.step, 'up')
      }

      this.$emit('input', newValue)
    },
    minusValue: function () {
      const step = this.control.options.step ? parseFloat(this.control.options.step) : 1
      const min = this.control.options.hasOwnProperty('min') ? this.control.options.min : null

      const currentValue = parseFloat(this.value)
      let newValue

      if (typeof min !== 'undefined') {
        if (currentValue - step >= min) {
          newValue = this.roundToNearestStep(currentValue, this.control.options.step, 'down')
        } else {
          newValue = min
        }
      } else {
        newValue = this.roundToNearestStep(currentValue, this.control.options.step, 'down')
      }

      this.$emit('input', newValue)
    },
    validate: function () {
      const min = this.control.options.min ? this.control.options.min : null
      const max = this.control.options.max ? this.control.options.max : null

      this.isMinusDisabled = false
      this.isPlusDisabled = false

      const value = parseFloat(this.value)

      if (min) {
        this.isMinusDisabled = min >= value
      }
      if (max) {
        this.isPlusDisabled = value >= max
      }

      if (min <= this.value && this.value <= max) {
        if (!this.isValid) {
          this.$emit('isValidChange', true)
        }
      } else {
        if (this.isValid) {
          this.$emit('isValidChange', false)
        }
      }
    },
    disableInputMode: function () {
      this.$emit('input', this.toBaseUnits(this.inputValue, this.unit, this.control.options))
      this.editMode = false
    },
    enableInputMode: function () {
      const volatileMetricPrefix = this.control.options?.metricPrefix?.indexOf(',') !== -1
      this.inputValue = volatileMetricPrefix
        ? this.value
        : this.formatValue(this.value, this.unit, this.control.options)
      this.editMode = true
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/variables.less';

.error {
  border: 1px solid red;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.control-panel {
  &--control {
    &--item {
      display: flex;
      align-items: center;
      &:first-child {
        margin-top: 0;
      }
      &--wrapper {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--inner {
          display: grid;
          grid-template-columns: 135px 36px;
          grid-gap: 10px;
        }
      }
    }

    &--input {
      border: 0.063rem solid #e8e8e8;
      background: #ffffff;
      padding: 9px 36px;
      text-align: center;
      width: 100%;
      outline: none;
      font-size: 0.875rem;
      transition: all 300ms ease;
      overflow: hidden;
      &:not(output):-moz-ui-invalid {
        box-shadow: none;
      }
      &.before-save {
        border-color: #1f97ff;
      }
      &--wrapper {
        position: relative;
        flex: 0 0 135px;
      }
      &--button {
        position: absolute;
        top: 1px;
        bottom: 1px;
        background: none;
        border: none;
        padding: 10px;
        margin: 0;
        font-family: Roboto, sans-serif;
        color: #1f97ff;
        background: #ffffff;
        font-size: 0.875rem;
        font-weight: 400;
        outline: none;
        transition: all 300ms ease;
        &--down {
          left: 1px;
        }
        &--up {
          right: 1px;
        }
        &[disabled] {
          color: rgba(205, 216, 222, 0.48);
        }
      }
    }
  }
}
</style>
