<template>
  <div class="pa-6">
    <h1 class="mb-5">
      {{ $t('global.adminConsole') }}: {{ $t('global.notificationDispatcher.delayConfiguration') }}
    </h1>
    <NotificationDelaysTable
      :notificationDelays="defaultDelaysWithIds"
      :updateFunction="updateDefaultNotificationClassDelays"
    />
  </div>
</template>
<script>
import NotificationDelaysTable from '@/components/NotificationDispatcher/NotificationDelaysTable'
import { notificationClassDefaultDelays } from '@/graphql/query/notificationClassDefaultDelays'
import { notificationClassUpdateDefaultDelays } from '@/graphql/mutations/notificationClassUpdateDefaultDelays'

export default {
  name: 'AdminDelayConfiguration',
  components: { NotificationDelaysTable },
  data() {
    return {}
  },
  apollo: {
    notificationClassDefaultDelays: {
      query: notificationClassDefaultDelays,
    },
  },
  methods: {
    updateDefaultNotificationClassDelays(args) {
      return this.$apollo.mutate({
        mutation: notificationClassUpdateDefaultDelays,
        variables: {
          notificationClass: args.id,
          delays: args.delays,
        },
        refetchQueries: ['notificationClassDefaultDelays'],
      })
    },
  },
  computed: {
    defaultDelaysWithIds() {
      return this.notificationClassDefaultDelays?.map((el) => ({ ...el, id: el.notificationClass }))
    },
  },
}
</script>
<style lang="less" scoped>
h1 {
  font-size: 24px;
}
</style>
