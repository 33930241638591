import csTrans from './cs'
import enTrans from './en'
import deTrans from './de'
import plTrans from './pl'

const translations = {
  en: enTrans,
  de: deTrans,
  cs: csTrans,
  pl: plTrans,
}

export const allowedLanguages = Object.keys(translations)

export default translations
