export const awsConfig = {
  Auth: {
    region: process.env.VUE_APP_COGNITO_REGION_ID,
    userPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.VUE_APP_COGNITO_DOMAIN,
      scope: ['openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.VUE_APP_DOMAIN,
      redirectSignOut: `${process.env.VUE_APP_DOMAIN}login`,
      responseType: 'code',
    },
  },
}
