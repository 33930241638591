<template>
  <div>
    <v-simple-table class="elevation-2" dense>
      <template v-slot:default>
        <tbody>
          <tr v-for="(permissionValue, permissionName) in value" :key="permissionName">
            <td class="py-3">
              <p class="mb-1">{{ $t(`users.permissions.${permissionName}`) }}</p>
              <p class="description">{{ $t(`users.permissionsDescription.${permissionName}`) }}</p>
            </td>
            <td class="text-right">
              <v-simple-checkbox
                :value="permissionValue"
                @input="onChange(permissionName, $event)"
                color="primary"
                :ripple="false"
                :disabled="isPermissionDisabled(permissionName, isAdmin, isAdminInSomeCompany)"
              ></v-simple-checkbox>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { companyPermissions } from '@/config/permissions'

export default {
  name: 'UserPermissionsInput',
  props: {
    value: Object,
    isAdmin: {
      default: false,
      type: Boolean,
    },
    isAdminInSomeCompany: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    onChange(permissionName, newValue) {
      this.$emit('input', {
        ...this.value,
        [permissionName]: newValue,
      })
    },
    isPermissionDisabled(permissionName, isAdmin, isAdminInSomeCompany) {
      // only admins can change admin permission, and only admin can change any permission on admin
      const a =
        (permissionName === companyPermissions.COMPANY_ADMINISTRATION || isAdmin) &&
        !this.currentCompanyPermissions[companyPermissions.COMPANY_ADMINISTRATION]

      // can not change own company user management and company administration permissions
      const b =
        this.$route.params.id === this.currentUserId &&
        (permissionName === companyPermissions.COMPANY_USER_MANAGEMENT ||
          permissionName === companyPermissions.COMPANY_ADMINISTRATION)

      // user can be admin only in one company
      const c =
        permissionName === companyPermissions.COMPANY_ADMINISTRATION &&
        isAdminInSomeCompany &&
        !isAdmin
      return a || b || c
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyPermissions', 'currentUserId']),
  },
}
</script>

<style scoped>
.description {
  font-size: 12px;
  opacity: 0.75;
  margin-bottom: 0;
}
</style>
