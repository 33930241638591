import gql from 'graphql-tag'

export const companyToCompanyInvitation = gql`
  query companyToCompanyInvitation($token: NonEmptyString!) {
    companyToCompanyInvitation(token: $token) {
      childCompany {
        name
      }
      parentCompany {
        name
      }
    }
  }
`
