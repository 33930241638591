<template>
  <div class="accept-device-access-request-wrapper">
    <h1 class="mb-5">{{ $t('global.acceptDeviceAccessRequest.headline') }}</h1>
    <div v-if="!validQuery">
      <v-alert prominent type="error">{{
        $t('global.acceptDeviceAccessRequest.invalidLink')
      }}</v-alert>
    </div>
    <template v-else>
      <div v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-else-if="successMessage">
        <v-alert prominent type="success">{{ successMessage }}</v-alert>
      </div>
      <div v-else-if="errorMessage">
        <v-alert prominent type="error">{{ errorMessage }}</v-alert>
      </div>
    </template>
  </div>
</template>
<script>
import { deviceAccessRequestAccept } from '@/graphql/mutations/deviceAccessRequestAccept'

export default {
  name: 'AcceptDeviceAccessRequestLandingPage',
  data() {
    return {
      loading: null,
      errorMessage: null,
      successMessage: null,
    }
  },
  async mounted() {
    if (this.$route.query.lang) {
      this.$store.commit('changeLocale', this.$route.query.lang)
    }

    if (this.validQuery) {
      this.loading = true
      try {
        const result = await this.$apollo.mutate({
          mutation: deviceAccessRequestAccept,
          variables: {
            token: this.$route.query.token,
            lang: this.$route.query.lang,
          },
        })

        this.successMessage = this.$t('global.acceptDeviceAccessRequest.success', {
          deviceName: result?.data?.deviceAccessRequestAccept?.newUserDeviceAccess.customDeviceName,
          userEmail: result?.data?.deviceAccessRequestAccept?.newUserDeviceAccess.user.email,
        })
      } catch (error) {
        console.error(error)
        const parsedError = error.message?.replace('GraphQL error:', '').trim()
        this.errorMessage =
          parsedError === 'DEVICE_ACCESS_REQUEST_ACCEPT_REQUEST_NOT_FOUND'
            ? this.$t('global.acceptDeviceAccessRequest.requestNotFound')
            : this.$t('global.acceptDeviceAccessRequest.failure')
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    validQuery() {
      return Boolean(this.$route.query.token)
    },
  },
}
</script>
<style lang="less" scoped>
.accept-device-access-request-wrapper {
  padding: 20px;

  h1 {
    font-size: 24px;
  }
}
</style>
