<template>
  <div class="pa-6">
    <h1 class="mb-5">{{ $t('global.adminConsole') }}: {{ $t('global.crowdin.crowdin') }}</h1>
    <div class="d-flex flex-column align-start">
      <v-btn
        class="mb-5"
        color="primary"
        @click=";(action = 'UPLOAD'), (confirmActionModalOpen = true)"
        :disabled="Boolean(actionInProgress)"
        :loading="actionInProgress === 'UPLOAD'"
        >{{ $t('global.crowdin.uploadTranslations') }}</v-btn
      >
      <v-btn
        class="mb-5"
        color="primary"
        @click=";(action = 'UPDATE_BASE'), (confirmActionModalOpen = true)"
        :disabled="Boolean(actionInProgress)"
        :loading="actionInProgress === 'UPDATE_BASE'"
        >{{ $t('global.crowdin.updateBase') }}</v-btn
      >
      <v-btn
        color="primary"
        @click=";(action = 'DOWNLOAD'), (confirmActionModalOpen = true)"
        :disabled="Boolean(actionInProgress)"
        :loading="actionInProgress === 'DOWNLOAD'"
        >{{ $t('global.crowdin.downloadTranslations') }}</v-btn
      >
    </div>
    <v-dialog v-if="action" v-model="confirmActionModalOpen" width="unset">
      <v-card>
        <v-card-title class="headline">{{ $t('global.crowdin.confirmationDialog') }}</v-card-title>
        <v-card-text>
          <div>
            {{ actionMap[action].text }}
          </div>
          <v-checkbox
            v-if="action === 'DOWNLOAD'"
            color="primary"
            v-model="downloadOnlyDatabase"
            :label="$t('global.crowdin.downloadOnlyDatabase')"
          ></v-checkbox>
        </v-card-text>
        <!-- <span>{{ $t('global.crowdin.downloadOnlyDatabase') }}</span> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmActionModalOpen = false">
            {{ $t('global.back') }}
          </v-btn>
          <v-btn color="error" text @click="runCrowdinAction(actionMap[action])">
            {{ actionMap[action].submitBtnText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <textarea ref="outputArea" :value="output" rows="10" disabled />
  </div>
</template>
<script>
import { translationsUpload } from '@/graphql/mutations/translationsUpload'
import { translationsDownload } from '@/graphql/mutations/translationsDownload'
import { translationsUpdateBase } from '@/graphql/mutations/translationsUpdateBase'

const mutations = {
  translationsUpload,
  translationsDownload,
  translationsUpdateBase,
}

export default {
  name: 'AdminCrowdin',
  data() {
    return {
      output: 'OUTPUT:\n',
      confirmActionModalOpen: false,
      actionMap: {
        UPLOAD: {
          text: this.$t('global.crowdin.uploadConfirmationText'),
          mutation: 'translationsUpload',
          submitBtnText: this.$t('global.crowdin.uploadTranslations'),
        },
        DOWNLOAD: {
          text: this.$t('global.crowdin.downloadConfirmationText'),
          mutation: 'translationsDownload',
          submitBtnText: this.$t('global.crowdin.downloadTranslations'),
        },
        UPDATE_BASE: {
          text: this.$t('global.crowdin.updateBaseConfirmationText'),
          mutation: 'translationsUpdateBase',
          submitBtnText: this.$t('global.crowdin.updateBase'),
        },
      },
      action: null,
      actionInProgress: null,
      downloadOnlyDatabase: false,
    }
  },
  methods: {
    async runCrowdinAction(config) {
      try {
        const variables = {}
        if (this.action === 'DOWNLOAD') {
          variables.onlyDatabase = this.downloadOnlyDatabase
        }

        this.actionInProgress = this.action
        this.output += `\nRunning ${config.submitBtnText}\n`
        this.confirmActionModalOpen = false
        this.action = null

        const response = await this.$apollo.mutate({
          mutation: mutations[config.mutation],
          variables,
        })

        if (response.data[config.mutation].success) {
          this.$toast.success(this.$t('global.crowdin.crowdinActionSuccess'))
        } else {
          this.$toast.warning(this.$t('global.crowdin.crowdinActionPartialFailure'))
        }

        this.output += `SUCCESSFUL FILES:\n${response.data[config.mutation].updatedFiles.join(
          '\n',
        )}\n`
        this.output += `FAILED FILES:\n${response.data[config.mutation].failedFiles.join('\n')}\n`
      } catch (error) {
        this.output += `ACTION FAILED:\n${error}\n`
        console.error(error)
        this.$toast.error(this.$t('global.crowdin.crowdinActionFailure'))
      } finally {
        this.actionInProgress = null
      }

      // scroll output down
      this.$nextTick(() => {
        this.$refs.outputArea.scrollTop = this.$refs.outputArea.scrollHeight
      })
    },
  },
}
</script>
<style lang="less" scoped>
textarea {
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 5px;
  font-family: monospace;
  background-color: #f5f5f5;
  margin-top: 20px;
  padding: 5px;
}
</style>
