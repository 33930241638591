<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed v-bind="{ ...attrs, ...btnProps }" v-on="on">{{
        $t('global.addDailySchedule')
      }}</v-btn>
    </template>
    <v-list class="list">
      <v-list-item @click="$emit('addNew')">
        <v-list-item-title class="d-flex align-center"
          ><v-icon left>mdi-plus</v-icon>{{ $t('global.newDailySchedule') }}</v-list-item-title
        >
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
      <v-list-item
        v-for="dailySchedule in dailyTimeSchedules"
        :key="dailySchedule.id"
        @click="select(dailySchedule.id)"
        :disabled="
          Boolean(
            actualTimeSchedule.find((entry) => entry.dailyTimeScheduleId === dailySchedule.id),
          )
        "
      >
        <v-list-item-title
          >{{ dailySchedule.name
          }}<span class="schedule-label ml-2">{{
            dailySchedule.isGlobal ? $t('global.predefinedSchedule') : $t('global.mySchedule')
          }}</span></v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: 'AddDailyScheduleButton',
  props: {
    actualTimeSchedule: Array,
    dailyTimeSchedules: Array,
    btnProps: Object,
  },
  methods: {
    select(id) {
      this.$emit('select', id)
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/components/TimeSchedules/timeSchedule.less';

.list {
  overflow: auto;
  max-height: 90vh;
}
</style>
