<template>
  <div class="d-flex justify-space-around my-6 flex-column flex-md-row">
    <v-btn @click="socialLogin('Facebook')" color="#3b5998" class="white--text mb-2"
      ><v-icon left>mdi-facebook</v-icon>Facebook</v-btn
    >
    <v-btn @click="socialLogin('Google')" color="#4285F4" class="white--text mb-2"
      ><v-icon left>mdi-google</v-icon>Google</v-btn
    >
    <v-btn @click="socialLogin('SignInWithApple')" color="black" class="white--text mb-2"
      ><v-icon left>mdi-apple</v-icon>Apple ID</v-btn
    >
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import { saveItem } from '@/middleware/storage'

export default {
  name: 'SocialLogin',
  methods: {
    socialLogin: function (provider) {
      saveItem('socialProvider', provider)
      this.$store.commit('user/setLoginError', null)
      Auth.federatedSignIn({ provider })
    },
  },
}
</script>
