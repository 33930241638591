import gql from 'graphql-tag'

export const deviceEligibilityCreate = gql`
  mutation deviceEligibilityCreate(
    $name: NonEmptyString!
    $type: EligibilityTypeEnum!
    $serviceSequence: Boolean!
    $provisionType: EligibilityProvisionTypeEnum!
    $isPremium: Boolean!
  ) {
    deviceEligibilityCreate(
      input: {
        name: $name
        type: $type
        serviceSequence: $serviceSequence
        provisionType: $provisionType
        isPremium: $isPremium
      }
    ) {
      success
    }
  }
`
