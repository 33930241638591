import gql from 'graphql-tag'

export const deviceEligibilities = gql`
  query deviceEligibilities($lang: String) {
    eligibilitiesConfig {
      device {
        name
        type
        isPremium
        serviceSequence
        provisionType
        label(lang: $lang)
        description(lang: $lang)
      }
    }
  }
`
