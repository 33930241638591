import gql from 'graphql-tag'

export const notificationClassUpdateDefaultDelays = gql`
  mutation notificationClassUpdateDefaultDelays(
    $notificationClass: NotificationClassEnum!
    $delays: [Int!]!
  ) {
    notificationClassUpdateDefaultDelays(
      input: { notificationClass: $notificationClass, delays: $delays }
    ) {
      success
    }
  }
`
