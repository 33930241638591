<template>
  <div class="login-page--content--inner">
    <h1 class="login-page--headline">{{ $t('login.forgotPasswordTitle') }}</h1>
    <div class="login-page--form">
      <v-form @submit.prevent="forgotPassword">
        <v-text-field
          v-model="email"
          filled
          :error-messages="getErrors('email')"
          :label="$t('global.email')"
          required
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
          type="email"
        ></v-text-field>
        <div class="login-page--error">
          <div v-if="error">{{ error }}</div>
        </div>
        <div class="btn--wrapper">
          <v-btn :loading="isLoading" type="submit" color="primary" large>{{
            $t('login.resetPassword')
          }}</v-btn>
          <router-link :to="{ name: 'LoginPage' }" class="btn--link">{{
            $t('login.backToLogin')
          }}</router-link>
        </div>
      </v-form>
    </div>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import vuelidateErrorsExtractor from '@/mixins/vuelidateErrorsExtractor'
import { required, email } from 'vuelidate/lib/validators'
import { Auth } from 'aws-amplify'

export default {
  name: 'ForgotPassword',
  mixins: [validationMixin, vuelidateErrorsExtractor],
  data: function () {
    return {
      email: '',
      error: null,
      isLoading: false,
    }
  },
  validations: {
    email: { required, email },
  },
  methods: {
    async forgotPassword() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isLoading = true
        this.error = null
        try {
          await Auth.forgotPassword(this.email)

          this.$router.push({ name: 'ResetPassword', query: { email: this.email } })
        } catch (error) {
          console.error(error)
          this.error = this.$t('global.errors.somethingWentWrong')
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
