import { HttpLink, ApolloClient, InMemoryCache } from '@apollo/client/core'
import { ApolloLink } from 'apollo-link'
import VueApollo from 'vue-apollo'
import { Auth } from 'aws-amplify'
import Router from '@/router'
import { saveItem } from '@/middleware/storage'
import VueRouter from 'vue-router'
import { getItem } from '../middleware/storage'
const { isNavigationFailure, NavigationFailureType } = VueRouter

const httpLink = new HttpLink({ uri: process.env.VUE_APP_API_BACKEND })

const authLink = new ApolloLink(async (operation, forward) => {
  if (operation.operationName === 'IntrospectionQuery') {
    return forward(operation)
  }

  if (Router.currentRoute?.query?.accessToken) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${Router.currentRoute?.query?.accessToken}`,
      },
    })
    return forward(operation)
  }

  // get session - will refresh access and id token if they are expired and there is a valid refresh token
  try {
    const session = await Auth.currentSession()
    if (session?.accessToken?.jwtToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${session?.accessToken?.jwtToken}`,
        },
      })
    }

    return forward(operation)
  } catch (error) {
    if (Router.currentRoute.fullPath.startsWith('/app')) {
      apolloClient.clearStore()
      if (!getItem('redirect')) {
        // save redirect only when there is no other redirect, without condition invitational redirects were overwritten by this if invitation was open on other tab
        saveItem('redirect', Router.currentRoute.fullPath)
      }
      Router.push({ name: 'LoginPage' }).catch((failure) => {
        if (!isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          console.error(failure)
        }
      })
    }

    return forward(operation)
  }
})

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Device: {
        fields: {
          liveDeviceData: {
            merge: true,
          },
        },
      },
      Query: {
        fields: {
          company: {
            merge(existing, incoming) {
              return incoming
            },
          },
        },
      },
    },
  }),
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      fetchPolicy: 'cache-and-network',
    },
  },
})

export default apolloProvider
