<template>
  <div ref="wrapper">
    <div v-if="$apollo.loading && !chargingHistoryResult" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else>
      <template v-if="chargingHistory.length">
        <v-btn @click="exportModalOpen = true" class="mb-3" depressed
          >{{ $t('global.export') }}<v-icon right> mdi-export</v-icon></v-btn
        >

        <div v-for="entry of chargingHistory" class="entry" :key="entry.id">
          {{ $t('global.chargingStart') }} {{ formatToDateAndMinutes(entry.timestamp) }},
          {{ $t('global.duration') }} {{ secondsToHoursAndMinutes(entry.duration) }},
          {{ $t('global.totalEnergy') }} {{ toKWH(entry.energy) }} kWh
        </div>
        <div>
          <div v-if="$apollo.queries.chargingHistoryResult.loading" class="d-flex justify-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <v-btn v-if="endCursorToLoad" @click="scrollToTop()" outlined class="float-right"
            ><v-icon class="mr-2">mdi-format-vertical-align-top</v-icon
            >{{ $t('global.backToTop') }}</v-btn
          >
        </div>

        <v-dialog v-model="exportModalOpen" width="500">
          <div class="modal-wrapper">
            <h1 class="mb-6">{{ $t('global.exportChargingHistory') }}</h1>
            <v-menu
              ref="menuFrom"
              v-model="menuFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFromFormatted"
                  :label="$t('global.dateFrom')"
                  :error-messages="dateFromErrors"
                  :hint="$t('global.chargingHistoryDateFromHint')"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="dateFrom = parseDate(dateFromFormatted) || dateFrom"
                  v-on="on"
                  filled
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateFrom" no-title @input="menuFrom = false"></v-date-picker>
            </v-menu>
            <v-menu
              ref="menuTo"
              v-model="menuTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateToFormatted"
                  :label="$t('global.dateTo')"
                  :error-messages="dateToErrors"
                  :hint="$t('global.chargingHistoryDateToHint')"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="dateTo = parseDate(dateToFormatted) || dateTo"
                  v-on="on"
                  filled
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateTo" no-title @input="menuTo = false"></v-date-picker>
            </v-menu>
            <div class="d-flex justify-end mt-2">
              <v-btn @click="exportModalOpen = false" depressed>{{ $t('global.cancel') }}</v-btn>
              <v-btn @click="exportToCSV" class="ml-2" color="primary" depressed>{{
                $t('global.export')
              }}</v-btn>
            </div>
          </div>
        </v-dialog>
      </template>
      <v-alert v-else outlined type="info" class="mt-3">
        {{ $t('global.chargingHistoryNoData') }}
      </v-alert>
    </div>
  </div>
</template>
<script>
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import { chargingHistory, chargingHistoryFromTo } from '@/graphql/query/chargingHistory'
import throttle from 'lodash/throttle'

export default {
  name: 'ChargingHistoryTab',
  props: {
    tabConfig: Object,
    device: Object,
    deviceDetailWrapper: HTMLDivElement,
    active: Boolean,
  },
  data() {
    return {
      pageSize: 10,
      endCursor: null,
      endCursorToLoad: null,
      hasNextPage: false,
      chargingHistory: [],
      exportModalOpen: false,
      dateFrom: null,
      dateTo: new Date().toISOString(),
      dateFromFormatted: null,
      dateToFormatted: this.formatDate(new Date()),
      menuFrom: false,
      menuTo: false,
    }
  },
  mounted() {
    this.deviceDetailWrapper.addEventListener('scroll', this.scrollHandler)
  },
  beforeDestroy() {
    this.deviceDetailWrapper.removeEventListener('scroll', this.scrollHandler)
    this.editor?.destroy()
  },
  apollo: {
    chargingHistoryResult: {
      query: chargingHistory,
      variables() {
        return {
          deviceId: this.device.id,
          first: this.pageSize,
          after: this.endCursorToLoad,
        }
      },
      update(data) {
        const newData = data.device.chargingHistory.edges.map(
          (chargingHistoryEntryEdge) => chargingHistoryEntryEdge.node,
        )
        this.endCursor = data.device.chargingHistory.pageInfo.endCursor
        this.hasNextPage = data.device.chargingHistory.pageInfo.hasNextPage
        this.chargingHistory.push(...newData)
        return newData
      },
    },
  },
  methods: {
    async exportToCSV() {
      try {
        if (this.dateFromErrors.length || this.dateToErrors.length) {
          return
        }

        let from = new Date(this.dateFrom)
        let to = new Date(this.dateTo)
        if (from > to) {
          // swap dates if they are in wrong order
          let helper = from
          from = to
          to = helper
        }
        const data = await this.getDataForExport(
          this.device.id,
          from.toISOString(),
          to.toISOString(),
        )

        // convert to CSV
        let csv = `${this.$t('global.chargingStart')},${this.$t('global.duration')},${this.$t(
          'global.totalEnergy',
        )} (kWh)`
        data.forEach((entry) => {
          csv += `\n${this.formatToDateAndMinutes(
            entry.timestamp,
          )},${this.secondsToHoursAndMinutesNormalized(entry.duration)},${this.toKWH(entry.energy)}`
        })

        // create a blob with the CSV data
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

        // create a link with the blob URL and download attribute
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'charging-history.csv'

        // simulate a click on the link to trigger the download
        link.click()

        // clean up the blob URL
        URL.revokeObjectURL(link.href)
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.chargingHistoryExportFailure'))
      }
    },
    async getDataForExport(deviceId, from, to) {
      const response = await this.$apollo.query({
        query: chargingHistoryFromTo,
        variables: {
          deviceId,
          from,
          to,
        },
      })

      return response.data.device.chargingHistoryFromTo
    },
    scrollHandler: throttle(function (event) {
      // scrolled to the end of the page
      if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 2) {
        // -2 sometimes condition wasn't working
        if (this.hasNextPage && this.chargingHistory.length) {
          this.loadMore()
        }
      }
    }, 100),
    loadMore() {
      this.endCursorToLoad = this.endCursor
    },
    formatToDateAndMinutes(dateString) {
      return format(new Date(dateString), 'dd.MM.y HH:mm')
    },
    scrollToTop() {
      this.$refs.wrapper.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
    reset() {
      this.endCursor = null
      this.endCursorToLoad = null
      this.hasNextPage = false
      this.chargingHistory = []
    },
    secondsToHoursAndMinutes(seconds) {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.round((seconds % 3600) / 60)
      let output = []
      if (hours) {
        output.push(`${hours} h`)
      }
      if (minutes || !hours) {
        output.push(`${minutes} min`)
      }
      return output.join(' ')
    },
    secondsToHoursAndMinutesNormalized(seconds) {
      const hours = Math.floor(seconds / 3600).toString()
      const minutes = Math.round((seconds % 3600) / 60).toString()
      return `${hours.length === 1 ? '0' + hours : hours}:${
        minutes.length === 1 ? '0' + minutes : minutes
      }`
    },
    toKWH(value) {
      return (value / 1000).toFixed(1)
    },
    formatDate(date) {
      return date ? format(new Date(date), 'd.M.yyyy') : ''
    },
    parseDate(date) {
      if (!date) return null
      try {
        return format(parse(date, 'd.M.yyyy', new Date()), 'yyyy-MM-dd')
      } catch (error) {
        return null
      }
    },
  },
  computed: {
    dateFromErrors() {
      if (!this.dateFromFormatted) return []
      try {
        format(parse(this.dateFromFormatted, 'd.M.yyyy', new Date()), 'yyyy-MM-dd')
        return []
      } catch (error) {
        return [this.$t('global.invalidDate')]
      }
    },
    dateToErrors() {
      if (!this.dateToFormatted) return []
      try {
        format(parse(this.dateToFormatted, 'd.M.yyyy', new Date()), 'yyyy-MM-dd')
        return []
      } catch (error) {
        return [this.$t('global.invalidDate')]
      }
    },
  },
  watch: {
    'device.id'() {
      this.reset()
    },
    active() {
      if (this.active) {
        this.reset()
        this.$apollo.queries.chargingHistoryResult.refetch()
      }
    },
    dateFrom() {
      this.dateFromFormatted = this.formatDate(this.dateFrom)
    },
    dateTo() {
      this.dateToFormatted = this.formatDate(this.dateTo)
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.entry {
  max-width: 1000px;
  padding: 15px;
  background-color: @color-tile-background;
  border-radius: 3px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}

.modal-wrapper {
  background-color: white;
  padding: 25px;

  h1 {
    font-size: 24px;
  }
}
</style>
