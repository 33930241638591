<template>
  <div class="user-tile-wrapper">
    <div class="actions-wrapper">
      <v-btn
        v-if="deviceAdminView || !userAccess.accessStart"
        icon
        @click="
          currentUserId === userAccess.user.id
            ? (removeOwnAccessModalOpen = true)
            : (confirmDeleteAccessModalOpen = true)
        "
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn v-else class="mr-1" icon @click="editModalOpen = true">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </div>
    <p class="name mb-2">
      {{
        userAccess.type === userDeviceAccessRoles.DEVICE_ADMIN
          ? $t('global.userAccessesTab.deviceAdmin')
          : $t('global.userAccessesTab.guest')
      }}{{ activeInvitationText }}
    </p>
    <p class="value">{{ userAccess.user.email }}</p>

    <v-dialog v-if="!deviceAdminView" v-model="editModalOpen" width="unset" max-width="500">
      <v-card>
        <div class="pa-6">
          <h2 class="mb-7">{{ userAccess.user.email }}</h2>
          <p>{{ $t('global.userAccessesTab.editModal.text') }}</p>
          <v-form @submit.prevent="changeAccessType">
            <v-select
              v-model="newAccessType"
              :items="roleOptions"
              filled
              :label="$t('global.userAccessesTab.editModal.role')"
            ></v-select>
            <div class="d-flex justify-space-between mt-8">
              <v-btn color="error" @click="confirmDeleteAccessModalOpen = true" depressed>{{
                $t('global.userAccessesTab.editModal.removeUsersAccess')
              }}</v-btn>
              <div>
                <v-btn @click="editModalOpen = false" depressed>{{ $t('global.cancel') }}</v-btn>
                <v-btn
                  :disabled="newAccessType === userAccess.type"
                  type="submit"
                  color="primary"
                  class="ml-3"
                  depressed
                  >{{ $t('global.save') }}</v-btn
                >
              </div>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>

    <ConfirmationDialog
      v-model="confirmDeleteAccessModalOpen"
      :heading="$t('global.userAccessesTab.confirmDeleteAccessModal.headline')"
      :text="
        $t('global.userAccessesTab.confirmDeleteAccessModal.text', {
          deviceName: getDeviceName(device, currentCompanyId),
          email: userAccess.user.email,
        })
      "
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
      :action="deleteUserAccess"
    />

    <v-dialog
      v-if="deviceAdminView && currentUserId === userAccess.user.id"
      v-model="removeOwnAccessModalOpen"
      width="unset"
      max-width="500"
    >
      <v-card>
        <div class="pa-6">
          <h2 class="mb-7">{{ $t('global.userAccessesTab.removeOwnAccessModal.headline') }}</h2>
          <div v-show="!shouldTransferDevice">
            <p>{{ $t('global.userAccessesTab.removeOwnAccessModal.text') }}</p>
            <div class="mt-8 text-right">
              <v-btn @click="removeOwnAccessModalOpen = false" depressed>{{
                $t('global.cancel')
              }}</v-btn>
              <v-btn
                @click="removeWithoutTransferConfirmationOpen = true"
                color="error"
                class="ml-3"
                depressed
                >{{
                  $t('global.userAccessesTab.removeOwnAccessModal.deleteWithoutTransfer')
                }}</v-btn
              >
              <v-btn @click="shouldTransferDevice = true" color="primary" class="ml-3" depressed>{{
                $t('global.userAccessesTab.removeOwnAccessModal.deleteWithTransfer')
              }}</v-btn>
            </div>
          </div>
          <v-form v-show="shouldTransferDevice" @submit.prevent="submitRemoveOwnAccess">
            <v-radio-group
              v-if="guestAccesses && guestAccesses.length"
              v-model="removeOwnAccessInputType"
            >
              <v-radio
                :label="$t('global.userAccessesTab.removeOwnAccessModal.selectExistingUser')"
                :value="'SELECT'"
              ></v-radio>
              <v-radio
                :label="$t('global.userAccessesTab.removeOwnAccessModal.enterEmail')"
                :value="'TEXT'"
              ></v-radio>
            </v-radio-group>
            <v-text-field
              v-show="
                !guestAccesses || !guestAccesses.length || removeOwnAccessInputType === 'TEXT'
              "
              v-model="invitationEmail"
              :error-messages="invitationEmailErrors"
              :label="$t('global.email')"
              required
              @input="$v.invitationEmail.$touch()"
              @blur="$v.invitationEmail.$touch()"
              filled
              type="email"
            ></v-text-field>
            <v-select
              v-show="
                guestAccesses && guestAccesses.length && removeOwnAccessInputType === 'SELECT'
              "
              v-model="invitationEmail"
              :error-messages="invitationEmailErrors"
              :items="guestAccesses"
              :item-text="(access) => access.user.email"
              :item-value="(access) => access.user.email"
              @input="$v.invitationEmail.$touch()"
              @blur="$v.invitationEmail.$touch()"
              filled
              :label="$t('global.email')"
            ></v-select>
            <div class="d-flex justify-space-between mt-8">
              <v-btn @click="shouldTransferDevice = false" depressed>{{ $t('global.back') }}</v-btn>
              <div>
                <v-btn @click="removeOwnAccessModalOpen = false" depressed>{{
                  $t('global.cancel')
                }}</v-btn>
                <v-btn type="submit" color="error" class="ml-3" depressed>{{
                  $t('global.submit')
                }}</v-btn>
              </div>
            </div>
          </v-form>
        </div>

        <ConfirmationDialog
          v-model="confirmRemoveOwnAccessModalOpen"
          :heading="$t('global.userAccessesTab.confirmRemoveOwnAccessModal.headline')"
          :text="
            $t('global.userAccessesTab.confirmRemoveOwnAccessModal.text', {
              deviceName: getDeviceName(device, currentCompanyId),
              invitationEmail,
            })
          "
          :confirmText="$t('global.delete')"
          confirmBtnColor="error"
          :action="transferAndRemoveOwnAccess"
        />
        <ConfirmationDialog
          v-model="removeWithoutTransferConfirmationOpen"
          :heading="$t('global.userAccessesTab.removeWithoutTransferConfirmationModal.headline')"
          :text="
            $t('global.userAccessesTab.removeWithoutTransferConfirmationModal.text', {
              deviceName: getDeviceName(device, currentCompanyId),
            })
          "
          :confirmText="$t('global.delete')"
          confirmBtnColor="error"
          :action="deleteUserAccess"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { userDeviceAccessDelete } from '@/graphql/mutations/userDeviceAccessDelete'
import { userDeviceAccessChangeType } from '@/graphql/mutations/userDeviceAccessChangeType'
import { deviceChangeDeviceAdmin } from '@/graphql/mutations/deviceChangeDeviceAdmin'
import { getDeviceName } from '@/utils/getDeviceName'
import { mapGetters } from 'vuex'
import { userDeviceAccessRoles } from '@/config/userDeviceAccessRoles'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import ConfirmationDialog from '@/components/ConfirmationDialog'

export default {
  name: 'UserTile',
  components: { ConfirmationDialog },
  mixins: [validationMixin],
  props: {
    userAccess: Object,
    device: Object,
    deviceAdminView: Boolean,
    guestAccesses: Array,
  },
  data() {
    return {
      newAccessType: this.userAccess.type,
      editModalOpen: false,
      confirmDeleteAccessModalOpen: false,
      removeOwnAccessModalOpen: false,
      removeOwnAccessInputType: 'SELECT',
      confirmRemoveOwnAccessModalOpen: false,
      removeWithoutTransferConfirmationOpen: false,
      invitationEmail: '',
      shouldTransferDevice: false,
    }
  },
  validations: {
    invitationEmail: { required, email },
  },
  methods: {
    async deleteUserAccess() {
      try {
        await this.$apollo.mutate({
          mutation: userDeviceAccessDelete,
          variables: {
            deviceId: this.device.id,
            userId: this.userAccess.user.id,
          },
          refetchQueries: this.removeOwnAccessModalOpen ? [] : ['deviceUserAccesses'],
        })
        this.$toast.success(this.$t('global.userAccessesTab.deleteUserAccessAction.success'))
        if (this.removeOwnAccessModalOpen) {
          this.$router.push({ name: 'DashboardPage' })
        } else {
          this.confirmDeleteAccessModalOpen = false
        }
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.userAccessesTab.deleteUserAccessAction.failure'))
      }
    },
    async changeDeviceAdmin() {
      try {
        await this.$apollo.mutate({
          mutation: deviceChangeDeviceAdmin,
          variables: {
            deviceId: this.device.id,
            email: this.invitationEmail,
          },
          refetchQueries: ['GetDevice'],
        })
      } catch (error) {
        console.error(error)
      }
    },
    async transferAndRemoveOwnAccess() {
      await this.changeDeviceAdmin()
      await this.deleteUserAccess()
    },
    async changeAccessType() {
      try {
        await this.$apollo.mutate({
          mutation: userDeviceAccessChangeType,
          variables: {
            deviceId: this.device.id,
            userId: this.userAccess.user.id,
            newType: this.newAccessType,
          },
          refetchQueries: ['deviceUserAccesses'],
        })
        this.$toast.success(this.$t('global.userAccessesTab.changeAccessTypeAction.success'))
        this.editModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.userAccessesTab.changeAccessTypeAction.failure'))
      }
    },
    submitRemoveOwnAccess() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.confirmRemoveOwnAccessModalOpen = true
      }
    },
    getDeviceName,
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId', 'currentUserId']),
    activeInvitationText() {
      if (this.userAccess.hasValidDeviceAdminInvitation) {
        return ` (${this.$t('global.userAccessesTab.activeDeviceAdminInvitation')})`
      }
      if (this.userAccess.accessStart == null) {
        return ` (${this.$t('global.userAccessesTab.activeInvitation')})`
      }
      return ''
    },
    userDeviceAccessRoles() {
      return userDeviceAccessRoles
    },
    roleOptions() {
      return [
        {
          text: this.$t('global.userAccessesTab.deviceAdmin'),
          value: userDeviceAccessRoles.DEVICE_ADMIN,
        },
        { text: this.$t('global.userAccessesTab.guest'), value: userDeviceAccessRoles.GUEST },
      ]
    },
    invitationEmailErrors() {
      const errors = []
      if (!this.$v.invitationEmail.$dirty) {
        return errors
      }
      !this.$v.invitationEmail.required && errors.push(this.$t('global.formValidation.required'))
      !this.$v.invitationEmail.email && errors.push(this.$t('global.formValidation.notEmail'))
      return errors
    },
  },
  watch: {
    removeOwnAccessModalOpen() {
      this.shouldTransferDevice = false
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.user-tile-wrapper {
  position: relative;
  background-color: fade(@color-brand-highlight, 50%);
  border-radius: 3px;
  padding: 14px 16px 16px 16px;

  p {
    margin-bottom: 0;
  }

  .actions-wrapper {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .name {
    font-size: 12px;
    color: @color-brand-2;
    opacity: 0.5;
  }

  .value {
    font-size: 14px;
    font-weight: bold;
    color: @color-brand-2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
