<template>
  <div
    class="wrapper"
    :class="{
      unavailable: isUnavailable,
    }"
  >
    <div class="title-wrapper mt-2">
      <v-icon v-if="control.icon" size="30">{{ `$${control.icon}` }}</v-icon>
      <p>{{ control.label }}</p>
    </div>
    <div v-if="isUnavailable" class="value-wrapper">
      <span>{{ $t('device.unavailable') }}</span>
    </div>
    <div v-else class="button-wrapper value-wrapper mr-2">
      <span v-if="nextRunFormatted">{{ $t('device.nextRun') }}<br />{{ nextRunFormatted }}</span>
      <span v-else></span>
      <v-btn
        @click="$emit('openServiceSequenceWizard', control.name)"
        :disabled="!canEdit"
        color="primary"
        dense
        depressed
        height="27"
      >
        {{ $t('device.launch') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { serviceSequenceNextRun } from '@/graphql/query/serviceSequenceNextRun'
import format from 'date-fns/format'

export default {
  name: 'ServiceSequenceTile',
  props: {
    control: Object,
    deviceId: String,
    isOffline: Boolean,
    canEdit: Boolean,
  },
  data() {
    return {
      currentForbiddenByUserValue: this.control.forbiddenByUser,
      isEditDialogOpen: false,
      isSetting: false,
      sequenceWizardOpen: false,
    }
  },
  apollo: {
    serviceSequenceNextRun: {
      query: serviceSequenceNextRun,
      errorPolicy: 'ignore',
      variables() {
        return {
          deviceId: this.deviceId,
          serviceSequenceName: this.control.name,
        }
      },
      update(data) {
        return data.device?.liveDeviceData.serviceSequenceNextRun
      },
    },
  },
  computed: {
    isUnavailable() {
      return this.isOffline || this.control.forbiddenByTechnology
    },
    nextRunFormatted() {
      if (!this.serviceSequenceNextRun) {
        return null
      }
      return format(new Date(this.serviceSequenceNextRun), 'd.M.y H:mm')
    },
  },
  watch: {
    isEditDialogOpen() {
      if (!this.isEditDialogOpen && !this.isSetting) {
        this.currentForbiddenByUserValue = this.control.forbiddenByUser
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.wrapper {
  position: relative;
  background-color: fade(@color-brand-highlight, 50%);
  border-radius: 3px;
  padding: 5px 5px 13px 18px;

  .title-wrapper {
    display: flex;
    align-items: center;

    .v-icon {
      margin-right: 16px;
    }

    p {
      font-size: 12px;
      opacity: 0.5;
      color: @color-brand-2;
      margin-bottom: 0;
    }
  }

  .edit-btn {
    float: right;

    i {
      color: @color-brand !important;
    }
  }

  &.unavailable {
    background-color: fade(#ebebeb, 50%);

    .v-icon {
      color: gray;
    }

    p,
    span {
      color: gray;
    }
  }

  &.forbidden-by-user {
    background-color: fade(@color-danger, 20%);

    .v-icon {
      color: @color-danger;
    }

    p,
    span {
      color: @color-danger;
    }
  }

  .value-wrapper {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    font-weight: bold;

    span {
      font-size: 14px;
    }
  }

  .button-wrapper {
    .v-btn {
      padding: 0 6px;
    }

    span {
      font-weight: bold;
      color: @color-brand;
    }
  }

  &.dosing {
    background-color: @color-brand-highlight;

    .button-wrapper span {
      color: @color-brand-2;
    }
  }
}
</style>
