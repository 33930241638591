<template>
  <div class="pa-6">
    <h1 class="mb-5">{{ $t('global.companyManagementMenu.users') }}</h1>
    <div
      v-if="currentCompanyPermissions[companyPermissions.COMPANY_USER_MANAGEMENT]"
      class="d-flex flex-wrap mb-4"
    >
      <v-btn
        :to="{ name: 'AddUserToCompanyPage' }"
        style="text-decoration: none"
        color="primary"
        class="mr-5 mb-2"
        depressed
        :block="showBlockButtons"
        >{{ $t('users.addUser') }}</v-btn
      >
      <v-btn
        :to="{ name: 'UserPermissionsPage' }"
        style="text-decoration: none"
        color="primary"
        depressed
        :block="showBlockButtons"
        >{{ $t('users.setPermissions') }}</v-btn
      >
    </div>
    <DataTableCustom
      :headers="usersHeaders"
      :items="displayableCompanyUsers"
      item-key="email"
      :item-class="getRowClass"
      class="elevation-2 pt-2"
      :search.sync="userSearch"
      :no-results-text="$t('users.noMatchingRecordsFound')"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-text': $t('global.dataTable.itemsPerPageText'),
        'items-per-page-all-text': $t('global.dataTable.allItemsText'),
      }"
    >
      <template v-slot:top>
        <v-text-field v-model="userSearch" :label="$t('users.search')" class="mx-4"></v-text-field>
      </template>
      <template
        v-if="currentCompanyPermissions[companyPermissions.COMPANY_USER_MANAGEMENT]"
        v-slot:item.edit="{ item }"
      >
        <v-btn
          icon
          v-if="
            isEditModeAvailable(
              item.companyPermissions &&
                item.companyPermissions[companyPermissions.COMPANY_ADMINISTRATION],
            )
          "
          :to="{ name: 'CompanyUserDetailPage', params: { id: item.id } }"
          style="text-decoration: none"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} {{ $t('global.dataTable.of') }}
        {{ items.itemsLength }}
      </template>
    </DataTableCustom>
  </div>
</template>

<script>
import { companyUsers } from '@/graphql/query/companyUsers'
import { mapGetters } from 'vuex'
import { companyPermissions } from '@/config/permissions'

export default {
  name: 'CompanyUsersListPage',
  data: () => {
    return {
      userSearch: '',
    }
  },
  apollo: {
    companyUsers: {
      query: companyUsers,
      variables() {
        return {
          companyId: this.currentCompanyId,
          withPermissions: this.currentCompanyPermissions[
            companyPermissions.COMPANY_USER_MANAGEMENT
          ],
        }
      },
      skip() {
        return !this.currentCompanyId
      },
      update(data) {
        return data.company.users
      },
    },
  },
  methods: {
    isEditModeAvailable(isUserAdmin) {
      return (
        (this.currentCompanyPermissions[companyPermissions.COMPANY_USER_MANAGEMENT] &&
          !isUserAdmin) ||
        this.currentCompanyPermissions[companyPermissions.COMPANY_ADMINISTRATION]
      )
    },
    getRowClass(item) {
      return item.enabled ? '' : 'disabled-row'
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId', 'currentCompanyPermissions']),
    usersHeaders() {
      return [
        { text: this.$t('global.firstName'), value: 'firstName' },
        { text: this.$t('global.lastName'), value: 'lastName' },
        { text: this.$t('global.email'), value: 'email' },
        { text: this.$t('global.phoneNumber'), value: 'phoneNumber' },
        ...(this.currentCompanyPermissions[companyPermissions.COMPANY_USER_MANAGEMENT]
          ? [{ text: '', value: 'edit', align: 'right' }]
          : []),
      ]
    },
    companyPermissions() {
      return companyPermissions
    },
    showBlockButtons: function () {
      return this.$vuetify.breakpoint.name === 'xs'
    },
    displayableCompanyUsers() {
      return this.companyUsers?.map((user) => ({
        ...user,
        firstName: user.firstName?.trim().length ? user.firstName : '-',
        lastName: user.lastName?.trim().length ? user.lastName : '-',
        phoneNumber: user.phoneNumber?.trim().length ? user.phoneNumber : '-',
      }))
    },
  },
}
</script>

<style scoped lang="less">
h1 {
  font-size: 24px;
}

/deep/.disabled-row {
  color: darkorange;
}
</style>
