<template>
  <div id="landing-page-wrapper">
    <header>
      <router-link class="d-flex align-center" :to="{ name: 'DashboardPage' }">
        <IXSolveLogo width="134" height="30" />
      </router-link>
      <LocaleSelect id="locale-select" />
    </header>
    <main>
      <router-view></router-view>
    </main>
    <footer>
      <span>Powered by</span>
      <a href="https://www.eledio.com/" id="brand-logo" target="_blank">
        <BrandLogo />
      </a>
    </footer>
  </div>
</template>
<script>
import IXSolveLogo from '@/assets/images/logo-ixsolve.svg'
import BrandLogo from '@/assets/images/logo-eledio.svg'
import LocaleSelect from '@/components/LocaleSelect'

export default {
  name: 'LandingPageTemplate',
  components: { IXSolveLogo, BrandLogo, LocaleSelect },
}
</script>
<style lang="less" scoped>
#landing-page-wrapper {
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #eeeeee;
    padding: 10px 16px;
    min-height: 56px;
  }

  main {
    flex-grow: 1;
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: solid 1px #eeeeee;
    padding: 10px 16px;
    min-height: 56px;

    color: rgba(30, 30, 31, 0.4);
    font-size: 0.875rem;
    font-weight: 400;

    #brand-logo {
      width: 85px;
      margin-left: 10px;
    }
  }

  #locale-select {
    flex-basis: 0;
    flex-grow: 0;
  }
}
</style>
