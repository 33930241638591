<template>
  <transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper" @click.self="close">
        <div class="modal-container">
          <div class="modal-content">
            <div class="modal-content--sidebar">
              <h2 class="modal-content--sidebar--headline">{{ $t('global.settings') }}</h2>

              <div v-if="formItems.length" class="modal-content--sidebar--nav">
                <button @click="scrollTo('_baseInfo')" class="modal-content--sidebar--button">
                  {{ $t('global.basicInformation') }}
                </button>

                <button
                  @click="scrollTo('section_' + item.name)"
                  v-for="(item, index) in formItems"
                  v-bind:key="'section_button' + index"
                  class="modal-content--sidebar--button"
                >
                  {{ item.label }}
                </button>

                <button
                  v-if="permissionChecker(permissions.FULL_CUSTOMER_DATA, null) && showContactInfo"
                  @click="scrollTo('_contactInfo')"
                  class="modal-content--sidebar--button"
                >
                  {{ $t('global.contactInfo') }}
                </button>

                <button
                  v-if="permissionChecker(permissions.FULL_CUSTOMER_DATA, null) && showAddress"
                  @click="scrollTo('_address')"
                  class="modal-content--sidebar--button"
                >
                  {{ $t('global.address') }}
                </button>
              </div>
            </div>

            <div class="modal-content--main">
              <div class="modal-content--main--content">
                <div ref="_baseInfo" class="modal-content--main--container">
                  <h3 class="modal-content--main--headline">{{ $t('global.basicInformation') }}</h3>
                  <div class="form-main--grid">
                    <classic-input
                      ref="name"
                      :label="$t('global.deviceName')"
                      variable-name="name"
                      :errors="errors"
                      v-model="form.name"
                      :options="defaultFormItems.nameOptions"
                    ></classic-input>
                  </div>
                </div>

                <div v-if="formItems.length">
                  <div
                    v-for="(item, index) of formItems"
                    v-bind:key="'inputGroup' + index"
                    :ref="'section_' + item.name"
                    class="modal-content--main--container"
                  >
                    <h3 class="modal-content--main--headline">{{ item.label }}</h3>

                    <div class="form-main--grid">
                      <template v-for="(formItem, index) in item.items">
                        <component
                          v-if="isFieldEnabled(formItem.options)"
                          ref="inputs"
                          :is="componentFormItemType(formItem.type, formItem.unit)"
                          v-bind:key="'form_input' + index"
                          v-model="form.deviceData[formItem.name]"
                          :other-data="form.deviceData"
                          v-bind="formItem"
                          :errors="errors"
                          :options="formItem.options"
                          :form-items="formItems"
                        >
                        </component>
                      </template>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    formItems.length &&
                    permissionChecker(permissions.FULL_CUSTOMER_DATA, null) &&
                    showContactInfo
                  "
                  ref="_contactInfo"
                  class="modal-content--main--container"
                >
                  <h3 class="modal-content--main--headline">{{ $t('global.contactInfo') }}</h3>
                  <div class="form-main--grid">
                    <classic-input
                      ref="contactName"
                      :label="$t('global.contactName')"
                      name="contactName"
                      v-model="form.contactInfo.name"
                      :errors="errors"
                    ></classic-input>
                    <classic-input
                      ref="contactEmail"
                      :label="$t('global.contactEmail')"
                      type="email"
                      name="contactEmail"
                      v-model="form.contactInfo.email"
                      :errors="errors"
                    ></classic-input>
                    <classic-input
                      ref="contactPhone"
                      :label="$t('global.contactPhone')"
                      name="contactPhone"
                      v-model="form.contactInfo.phone"
                      :errors="errors"
                    ></classic-input>
                    <classic-input
                      ref="contactNote"
                      :label="$t('global.note')"
                      name="contactNote"
                      v-model="form.contactInfo.note"
                      :errors="errors"
                    ></classic-input>
                  </div>
                </div>

                <div
                  v-if="
                    formItems.length &&
                    permissionChecker(permissions.FULL_CUSTOMER_DATA, null) &&
                    showAddress
                  "
                  ref="_address"
                  class="modal-content--main--container"
                >
                  <h3 class="modal-content--main--headline">{{ $t('global.address') }}</h3>
                  <div class="form-main--grid">
                    <AddressEdit v-model="form.address" :errors="errors" />
                  </div>
                </div>
              </div>

              <footer class="modal-content--main--footer">
                <div class="modal-content--main--footer--wrapper">
                  <div class="modal-content--main--footer--wrapper--inner">
                    <button @click="close" class="modal-content--main--footer--button">
                      {{ $t('global.close') }}
                    </button>
                  </div>
                  <div class="modal-content--main--footer--wrapper--inner">
                    <button
                      v-if="
                        permissionChecker(
                          permissions.CONFIGURATION_CHANGES,
                          eligibilities.DEVICE_CONFIGURATION,
                        )
                      "
                      @click="() => save(false)"
                      class="modal-content--main--footer--button"
                    >
                      {{ $t('global.save') }}
                    </button>
                    <button
                      v-if="
                        permissionChecker(
                          [permissions.CONFIGURATION_CHANGES, permissions.CONFIGURATION_UPLOAD],
                          eligibilities.DEVICE_CONFIGURATION,
                        )
                      "
                      @click="() => save(true)"
                      class="modal-content--main--footer--button"
                    >
                      {{ $t('device.saveAndPropagate') }}
                    </button>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import closeOnEscape from '@/mixins/closeOnEscape'
import componentFormItemType from '@/mixins/componentFormItemType'
import ClassicInput from '@/components/Form/Input/ClassicInput'
import updateDevice from '@/mixins/device/updateDevice'
import SelectInput from '@/components/Form/Input/SelectInput'
import AddressEdit from '../Form/Input/AddressEdit'
import { prepareConditions } from '@/utils/conditionsValidator'
import { permissions } from '@/config/permissions'
import { eligibilities } from '@/config/eligibilities'
import { EventBus } from '@/helper/EventBus'

export default {
  name: 'DeviceEditModal',
  components: { AddressEdit, SelectInput, ClassicInput },
  mixins: [closeOnEscape, componentFormItemType, updateDevice],
  props: {
    show: Boolean,
    deviceId: String,
    permissionChecker: Function,
  },
  data: function () {
    return {
      isAddressValid: true,
      isManualAddressModeOpen: true,
    }
  },
  mounted() {
    EventBus.$on('paramUpdate', ({ paramName, value }) => {
      this.form.deviceData[paramName] = value
    })
  },
  methods: {
    async save(propagate) {
      this.errors = []
      if (this.permissionChecker(permissions.FULL_CUSTOMER_DATA, null) && this.showAddress) {
        if (!this.form.address?.city?.trim().length) {
          this.errors.push({
            name: 'city',
            error: 'is_empty',
          })
        }
        if (!this.form.address?.address?.trim().length) {
          this.errors.push({
            name: 'address',
            error: 'is_empty',
          })
        }
      }
      if (
        this.errors.length === 0 &&
        (await this.saveDevice(
          propagate,
          this.permissionChecker(permissions.FULL_CUSTOMER_DATA, null),
        ))
      ) {
        this.$emit('save')
        this.close()
      } else {
        this.$nextTick(() => {
          const inputWithError = document.querySelector(
            '.modal-content--main--content .error .form-input, .modal-content--main--content .error .form-select',
          )
          inputWithError.scrollIntoView()
          inputWithError.focus()
        })
      }
    },
    scrollTo(section) {
      if (this.$refs[section]) {
        if (Array.isArray(this.$refs[section])) {
          this.$refs[section][0].scrollIntoView()
        } else {
          this.$refs[section].scrollIntoView()
        }
      }
    },
    isFieldEnabled(options) {
      if (options?.enableIfConditions) {
        const condition = prepareConditions(options.enableIfConditions)
        return condition.evaluate(this.form.deviceData)
      }
      return true
    },
  },
  watch: {
    deviceId: function () {
      this.errors = []
      this.isAddressValid = true
      this.updateFormByDeviceId(
        this.deviceId,
        this.$i18n.locale,
        this.permissionChecker(permissions.FULL_CUSTOMER_DATA, null),
        true,
      )
    },
    '$i18n.locale'() {
      this.updateFormByDeviceId(
        this.deviceId,
        this.$i18n.locale,
        this.permissionChecker(permissions.FULL_CUSTOMER_DATA, null),
        false,
      )
    },
    'form.deviceData': {
      handler(newDeviceData) {
        // set hidden items to their default values
        const conditionalItems = this.formItems
          .reduce((acc, formItem) => (acc.push(...formItem.items), acc), [])
          .filter((item) => item.options?.enableIfConditions)

        conditionalItems.forEach((item) => {
          if (!this.isFieldEnabled(item.options)) {
            if (newDeviceData[item.name] !== this.defaultValues[item.name]) {
              this.form.deviceData[item.name] = this.defaultValues[item.name]
            }
          }
        })
      },
      deep: true,
    },
  },
  computed: {
    permissions() {
      return permissions
    },
    eligibilities() {
      return eligibilities
    },
  },
  created() {
    this.updateFormByDeviceId(
      this.deviceId,
      this.$i18n.locale,
      this.permissionChecker(permissions.FULL_CUSTOMER_DATA, null),
      true,
    )
  },
}
</script>

<style scoped lang="less">
@import 'editModal.less';
</style>
