`
<template>
  <div>
    <div v-if="$apollo.queries.operationCounters.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else-if="operationCounters.length" class="content-wrapper">
      <div v-for="(data, index) of operationCounters" :key="data.id" class="operation-counter-tile">
        <span class="name">{{ data.displayableEquipmentType }}</span>
        <h1>{{ data.equipmentId }}</h1>
        <dl class="mb-2">
          <dt>{{ $t('global.hourCounter') }}</dt>
          <dd>{{ data.hourCounter }}</dd>
          <dt>{{ $t('global.startCounter') }}</dt>
          <dd>{{ data.startCounter }}</dd>
          <dt>{{ $t('global.cycleStartDate') }}</dt>
          <dd>{{ formatDate(data.cycleStart) }}</dd>
        </dl>
        <div class="rich-text-wrapper">
          <div ref="note" class="rich-text-inner-wrapper">
            <span class="rich-text note" v-sanitized-html="data.note"></span>
            <a v-if="showShowMoreBtns[index]" @click="openExpandedNoteModal(data)"
              >...{{ $t('global.showMore') }}</a
            >
          </div>
        </div>
        <v-btn
          @click="openStartNewCycleModal(data)"
          color="primary"
          height="27"
          class="align-self-end mt-3"
          depressed
          >{{ $t('global.startNewCycle') }}</v-btn
        >
      </div>

      <v-dialog
        v-if="equipmentInModal"
        v-model="startNewCycleModalOpen"
        @click:outside="cancel()"
        @keydown.esc="cancel()"
        max-width="460"
        persistent
      >
        <div class="modal-content">
          <div class="d-flex" style="gap: 15px">
            <div>
              <v-text-field
                :value="equipmentInModal.equipmentId"
                :label="$t('global.equipmentId')"
                filled
                disabled
              ></v-text-field>
              <v-text-field
                :value="equipmentInModal.hourCounter"
                :label="$t('global.currentHourCounter')"
                filled
                disabled
              ></v-text-field>
              <v-text-field
                :value="equipmentInModal.startCounter"
                :label="$t('global.currentStartCounter')"
                filled
                disabled
              ></v-text-field>
              <v-text-field
                :value="formatDate(equipmentInModal.cycleStart)"
                :label="$t('global.cycleStartDate')"
                filled
                disabled
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                v-model="newCycleForm.name"
                :error-messages="getErrors('newCycleForm.name')"
                :label="$t('global.newEquipmentId')"
                @input="$v.newCycleForm.name.$touch()"
                @blur="$v.newCycleForm.name.$touch()"
                filled
              ></v-text-field>
              <v-text-field
                v-model="newCycleForm.hourCounter"
                :error-messages="getErrors('newCycleForm.hourCounter')"
                :label="$t('global.newHourCounter')"
                @input="$v.newCycleForm.hourCounter.$touch()"
                @blur="$v.newCycleForm.hourCounter.$touch()"
                filled
                type="number"
              ></v-text-field>
              <v-text-field
                v-model="newCycleForm.startCounter"
                :error-messages="getErrors('newCycleForm.startCounter')"
                :label="$t('global.newStartCounter')"
                @input="$v.newCycleForm.startCounter.$touch()"
                @blur="$v.newCycleForm.startCounter.$touch()"
                filled
                type="number"
              ></v-text-field>
              <v-menu
                ref="menu"
                v-model="datePickerOpen"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="newDateFormatted"
                    :error-messages="getErrors('newCycleForm.cycleStart')"
                    :label="$t('global.newCycleStart')"
                    readonly
                    filled
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="newCycleForm.cycleStart"
                  :active-picker.sync="activePicker"
                  :max="
                    new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                      .toISOString()
                      .substr(0, 10)
                  "
                  @change="datePickerOpen = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
          <RichTextEditor
            ref="richTextEditor"
            class="mt-2"
            :usersAvailableToMention="usersAvailableToMention"
            :placeholder="$t('global.operationCounterNotePlaceholder')"
          />
          <div class="d-flex justify-end">
            <v-btn @click="cancelEdit()" class="mr-2" depressed>{{ $t('global.cancel') }}</v-btn>
            <v-btn @click="createNewCycle()" color="primary" depressed>{{
              $t('global.save')
            }}</v-btn>
          </div>
        </div>
      </v-dialog>
      <ConfirmationDialog
        v-model="confirmCancelEditDialogOpen"
        :heading="$t('device.timeSchedule.editModal.cancelConfirmation.heading')"
        :text="$t('device.timeSchedule.editModal.cancelConfirmation.text')"
        :action="cancelEdit"
        :confirmText="$t('device.timeSchedule.editModal.cancelConfirmation.heading')"
        confirmBtnColor="error"
      />
      <v-dialog v-if="expandedModalData" v-model="noteExpandModalOpen" width="450">
        <div class="modal-content expanded-note-modal">
          <h1>{{ expandedModalData.equipmentId }}</h1>
          <dl class="mb-2">
            <dt>{{ $t('global.hourCounter') }}</dt>
            <dd>{{ expandedModalData.hourCounter }}</dd>
            <dt>{{ $t('global.startCounter') }}</dt>
            <dd>{{ expandedModalData.startCounter }}</dd>
            <dt>{{ $t('global.cycleStartDate') }}</dt>
            <dd>{{ formatDate(expandedModalData.cycleStart) }}</dd>
          </dl>
          <span class="rich-text note" v-sanitized-html="expandedModalData.note"></span>
        </div>
      </v-dialog>
    </div>
    <div v-else class="message-wrapper">
      <p class="mt-2">{{ $t('global.noOperationCounters') }}</p>
    </div>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import vuelidateErrorsExtractor from '@/mixins/vuelidateErrorsExtractor'
import { required, minValue, integer } from 'vuelidate/lib/validators'
import format from 'date-fns/format'
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor'
import throttle from 'lodash/throttle'
import { usersWithAccessToServiceHistory } from '@/graphql/query/usersWithAccessToServiceHistory'
import { operationCounters } from '@/graphql/query/operationCounters'
import { operationCounterCycleCreate } from '@/graphql/mutations/operationCounterCycleCreate'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import parseISO from 'date-fns/parseISO'

export default {
  name: 'OperationCounterTab',
  components: { RichTextEditor, ConfirmationDialog },
  mixins: [validationMixin, vuelidateErrorsExtractor],
  props: {
    device: Object,
  },
  data() {
    return {
      startNewCycleModalOpen: false,
      equipmentInModal: null,
      newCycleForm: {
        name: null,
        hourCounter: null,
        startCounter: null,
        cycleStart: null,
      },
      datePickerOpen: false,
      activePicker: null,
      showShowMoreBtns: [],
      confirmCancelEditDialogOpen: false,
      noteExpandModalOpen: false,
      expandedModalData: null,
    }
  },
  validations: {
    newCycleForm: {
      name: { required },
      hourCounter: { required, minValue: minValue(0), integer },
      startCounter: { required, minValue: minValue(0), integer },
      cycleStart: { required },
    },
  },
  created() {
    window.addEventListener('resize', this.resizeHandler)
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  apollo: {
    operationCounters: {
      query: operationCounters,
      variables() {
        return {
          deviceId: this.device.id,
          lang: this.$i18n.locale,
        }
      },
      update(data) {
        setTimeout(() => {
          this.getShowShowMoreBtns()
        })

        return data.device.operationCounters?.map((d) => ({
          ...d,
          hourCounter: Math.round((d.timeCounter / 3600) * 10) / 10,
        }))
      },
    },
    usersAvailableToMention: {
      query: usersWithAccessToServiceHistory,
      variables() {
        return {
          deviceId: this.device.id,
        }
      },
      update(data) {
        return data.device.usersWithAccessToServiceHistory
      },
    },
  },
  methods: {
    async createNewCycle() {
      try {
        await this.$apollo.mutate({
          mutation: operationCounterCycleCreate,
          variables: {
            data: {
              deviceId: this.device.id,
              equipmentId: this.newCycleForm.name,
              timeCounterEquipmentStartValue: this.newCycleForm.hourCounter * 3600,
              startCounterEquipmentStartValue: this.newCycleForm.startCounter,
              dataKey: this.equipmentInModal.dataKey,
              cycleStart: parseISO(this.newCycleForm.cycleStart),
              note: this.$refs.richTextEditor.editor.isEmpty
                ? null
                : this.$refs.richTextEditor.editor.getHTML(),
            },
          },
          refetchQueries: ['operationCounters'],
        })
        this.startNewCycleModalOpen = false
        this.cancelEdit()
        this.$toast.success(this.$t('global.operationCounterCycleCreateAction.success'))
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.operationCounterCycleCreateAction.failure'))
      }
    },
    formatDate(dateString) {
      return dateString ? format(new Date(dateString), 'd.M.Y') : ''
    },
    openStartNewCycleModal(equipment) {
      this.equipmentInModal = equipment
      this.startNewCycleModalOpen = true
    },
    cancel() {
      if (this.didSomeChanges) {
        this.confirmCancelEditDialogOpen = true
      } else {
        this.cancelEdit()
      }
    },
    cancelEdit() {
      this.confirmCancelEditDialogOpen = false
      this.startNewCycleModalOpen = false

      this.newCycleForm.name = null
      this.newCycleForm.hourCounter = null
      this.newCycleForm.startCounter = null
      this.newCycleForm.cycleStart = null
      this.$v.newCycleForm.$reset()
      this.$refs.richTextEditor.editor.commands.clearContent()
    },
    openExpandedNoteModal(data) {
      this.expandedModalData = data
      this.noteExpandModalOpen = true
    },
    getShowShowMoreBtns() {
      this.showShowMoreBtns = this.$refs.note?.map(
        (noteRef) => noteRef.scrollHeight > noteRef.clientHeight,
      )
    },
    resizeHandler: throttle(function () {
      this.getShowShowMoreBtns()
    }, 100),
  },
  computed: {
    newDateFormatted() {
      return this.formatDate(this.newCycleForm.cycleStart)
    },
    didSomeChanges() {
      return this.$v.newCycleForm.$anyDirty || !this.$refs.richTextEditor.editor.isEmpty
    },
  },
  watch: {
    datePickerOpen(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';
@import '~@/components/RichTextEditor/styles.less';

.content-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 5px;

  .operation-counter-tile {
    display: flex;
    flex-direction: column;
    background-color: fade(@color-brand-highlight, 50%);
    border-radius: 3px;
    padding: 12px 8px 10px 18px;

    .name {
      font-size: 12px;
      color: @color-brand-2;
      opacity: 0.5;
    }

    .rich-text-wrapper {
      flex-grow: 1;
      font-size: 12px;

      .rich-text-inner-wrapper {
        max-height: 36px;
        overflow: hidden;
        position: relative;

        a {
          text-transform: lowercase;
          position: absolute;
          bottom: 0;
          right: 0;
          background-color: tint(@color-brand-highlight, 50%);
          color: @color-primary;
          font-weight: 500;

          &:before {
            content: '';
            display: block;
            width: 20px;
            height: 18px;
            position: absolute;
            left: -20px;
            background: linear-gradient(to right, #e9f8ff00, #e9f8ff);
            pointer-events: none;
          }
        }
      }
    }
  }
}

.note {
  word-break: break-word;
}

.modal-content {
  background-color: white;
  padding: 15px;
}

h1 {
  font-size: 20px;
}

dl {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 12px;
  font-weight: 700;

  dt {
    &::after {
      content: ':';
    }
  }
}

.expanded-note-modal {
  dl {
    font-size: 14px;
  }
}

.message-wrapper {
  padding: 16px;
  background-color: @color-tile-background;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
