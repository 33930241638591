<template>
  <div>
    <v-btn
      :to="{
        name: 'AddBaseProductPage',
      }"
      style="text-decoration: none"
      color="primary"
      class="mr-5 mb-2"
      depressed
      >{{ $t('global.addBaseProduct') }}</v-btn
    >
    <DataTableCustom
      :headers="headers"
      :items="eligibilityTemplates"
      :search.sync="search"
      :loading="$apollo.loading"
      :no-data-text="$t('users.noMatchingRecordsFound')"
      :footer-props="{
        'items-per-page-text': $t('global.dataTable.itemsPerPageText'),
        'items-per-page-all-text': $t('global.dataTable.allItemsText'),
      }"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-text-field v-model="search" :label="$t('users.search')" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.name="{ item }">
        <v-btn text @click="redirectToEditPage(item.id)" class="text-capitalize">
          {{ item.name }}
        </v-btn>
      </template>
      <template v-slot:item.thingTypes="{ item }">
        {{ getThingTypeNames(item) }}
      </template>
    </DataTableCustom>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DataTableCustom from '@/components/DataTableCustom'
import { eligibilityTemplates } from '../../graphql/query/eligibilityTemplates'

export default {
  name: 'BaseProductsPage',
  components: {
    DataTableCustom,
  },
  data() {
    return {
      search: '',
      headers: [
        { text: this.$t('global.title'), value: 'name' },
        {
          text: this.$t('global.deviceType'),
          value: 'thingTypes',
          sortable: false,
        },
      ],
      eligibilityTemplates: [],
    }
  },
  apollo: {
    eligibilityTemplates: {
      query: eligibilityTemplates,
      variables() {
        return { companyId: this.currentCompanyId }
      },
      update(data) {
        return data.company.eligibilityTemplates
      },
    },
  },
  methods: {
    redirectToProductPage(productId) {
      this.$router.push({
        name: 'ProductDetailPage_new',
        params: { productId },
      })
    },
    redirectToEditPage(baseProductId) {
      this.$router.push({
        name: 'EditBaseProductPage',
        params: { baseProductId },
      })
    },
    redirectToNewProductPage(device) {
      this.$router.push({
        name: 'AddDeviceProductPage',
        params: {
          controllerId: device.id,
          deviceName: `${device.name}-${device.controller}`,
          thingType: device.thingType.name,
        },
      })
    },
    getThingTypeNames(item) {
      return item.thingTypes.map((tt) => tt.businessName || tt.name).join(', ')
    },
  },

  computed: {
    ...mapGetters('user', ['currentCompanyId']),
  },
}
</script>
