<template>
  <div class="user-accesses-tab-wrapper">
    <div class="d-flex align-center mb-4">
      <h2 class="mr-5">{{ $t('global.userAccessesTab.deviceAdmin') }}</h2>
      <v-btn
        v-if="isDeviceAdmin"
        color="primary"
        height="27"
        depressed
        @click="changeDeviceAdminModalOpen = true"
        >{{ $t('global.userAccessesTab.changeDeviceAdmin') }}</v-btn
      >
    </div>
    <div class="tiles-wrapper">
      <div v-if="!deviceAdminAccesses || !deviceAdminAccesses.length" class="message-wrapper">
        {{ $t('global.userAccessesTab.noDeviceAdmin') }}
      </div>
      <UserTile
        v-else
        v-for="access of deviceAdminAccesses"
        :key="access.id"
        :userAccess="access"
        :device="device"
        :deviceAdminView="isDeviceAdmin"
        :guestAccesses="guestAccesses"
      />
    </div>

    <div class="d-flex align-center mb-4 mt-6">
      <h2 class="mr-5">{{ $t('global.userAccessesTab.guests') }}</h2>
      <v-btn color="primary" height="27" depressed @click="inviteUserModalOpen = true">{{
        $t('global.userAccessesTab.inviteUser')
      }}</v-btn>
    </div>
    <div class="tiles-wrapper">
      <div v-if="!guestAccesses || !guestAccesses.length" class="message-wrapper">
        {{ $t('global.userAccessesTab.noGuests') }}
      </div>
      <UserTile
        v-else
        v-for="access of guestAccesses"
        :key="access.id"
        :userAccess="access"
        :device="device"
        :deviceAdminView="isDeviceAdmin"
      />
    </div>

    <template
      v-if="
        (permissionChecker(null, eligibilities.DEMO_MODE) && isDeviceAdmin) ||
        permissionChecker(permissions.USER_MANAGEMENT, null)
      "
    >
      <div class="d-flex align-center mb-4 mt-6">
        <h2 class="mr-5">{{ $t('global.userAccessesTab.demoTokens') }}</h2>
        <v-btn color="primary" height="27" depressed @click="addDemoTokenModalOpen = true">{{
          $t('global.add')
        }}</v-btn>
      </div>
      <div class="tiles-wrapper">
        <div v-if="!demoTokens || !demoTokens.length" class="message-wrapper">
          {{ $t('global.userAccessesTab.noTokens') }}
        </div>
        <DemoTokenTile
          v-else
          v-for="demoToken of demoTokens"
          :key="demoToken.id"
          :demoToken="demoToken"
          :device="device"
        />
      </div>
    </template>

    <div v-if="wifiCredentialsAvailable && isDeviceAdmin" class="d-flex align-center mb-4 mt-6">
      <h2 class="mr-5">{{ $t('global.userAccessesTab.wifiPassword') }}</h2>
      <v-btn color="primary" height="27" depressed @click="wifiPasswordModalOpen = true">{{
        $t('global.show')
      }}</v-btn>
    </div>

    <v-dialog v-model="changeDeviceAdminModalOpen" width="unset" max-width="500">
      <v-card>
        <div class="pa-6">
          <h2 class="mb-7">{{ $t('global.userAccessesTab.changeDeviceAdminModal.headline') }}</h2>
          <p>{{ $t('global.userAccessesTab.changeDeviceAdminModal.text') }}</p>
          <v-form @submit.prevent="submitChangeDeviceAdmin">
            <v-radio-group
              v-if="guestAccesses && guestAccesses.length"
              v-model="changeDeviceAdminInputType"
            >
              <v-radio
                :label="$t('global.userAccessesTab.changeDeviceAdminModal.selectExistingUser')"
                :value="'SELECT'"
              ></v-radio>
              <v-radio
                :label="$t('global.userAccessesTab.changeDeviceAdminModal.enterEmail')"
                :value="'TEXT'"
              ></v-radio>
            </v-radio-group>
            <v-text-field
              v-show="
                !guestAccesses || !guestAccesses.length || changeDeviceAdminInputType === 'TEXT'
              "
              v-model="invitationEmail"
              :error-messages="invitationEmailErrors"
              :label="$t('global.email')"
              required
              @input="$v.invitationEmail.$touch()"
              @blur="$v.invitationEmail.$touch()"
              filled
              type="email"
            ></v-text-field>
            <v-select
              v-show="
                guestAccesses && guestAccesses.length && changeDeviceAdminInputType === 'SELECT'
              "
              v-model="invitationEmail"
              :error-messages="invitationEmailErrors"
              :items="guestAccesses"
              :item-text="(access) => access.user.email"
              :item-value="(access) => access.user.email"
              @input="$v.invitationEmail.$touch()"
              @blur="$v.invitationEmail.$touch()"
              filled
              :label="$t('global.email')"
            ></v-select>
            <div class="mt-8 text-right">
              <v-btn @click="changeDeviceAdminModalOpen = false" depressed>{{
                $t('global.cancel')
              }}</v-btn>
              <v-btn type="submit" color="error" class="ml-3" depressed>{{
                $t('global.submit')
              }}</v-btn>
            </div>
          </v-form>
        </div>

        <ConfirmationDialog
          v-model="confirmChangeDeviceAdminModalOpen"
          :heading="$t('global.userAccessesTab.confirmChangeDeviceAdminModal.headline')"
          :text="
            $t('global.userAccessesTab.confirmChangeDeviceAdminModal.text', {
              deviceName: getDeviceName(device, currentCompanyId),
              invitationEmail,
            })
          "
          :confirmText="$t('global.submit')"
          confirmBtnColor="error"
          :action="changeDeviceAdmin"
        />
      </v-card>
    </v-dialog>

    <v-dialog v-model="inviteUserModalOpen" width="unset">
      <v-card>
        <div class="pa-6">
          <h2 class="mb-7">{{ $t('global.userAccessesTab.inviteUserModal.headline') }}</h2>
          <v-form @submit.prevent="inviteUser">
            <v-text-field
              v-model="invitationEmail"
              :error-messages="invitationEmailErrors"
              :label="$t('global.email')"
              required
              @input="$v.invitationEmail.$touch()"
              @blur="$v.invitationEmail.$touch()"
              filled
              style="width: 350px"
              type="email"
            ></v-text-field>
            <div class="mt-8 text-right">
              <v-btn @click="inviteUserModalOpen = false" depressed>{{
                $t('global.cancel')
              }}</v-btn>
              <v-btn type="submit" color="primary" class="ml-3" depressed>{{
                $t('global.submit')
              }}</v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDemoTokenModalOpen" width="unset">
      <v-card>
        <div class="pa-6">
          <h2 class="mb-7">{{ $t('global.userAccessesTab.addDemoTokenModal.headline') }}</h2>
          <DemoTokenForm ref="demoTokenForm" />
          <div class="mt-8 text-right">
            <v-btn @click="addDemoTokenModalOpen = false" depressed>{{
              $t('global.cancel')
            }}</v-btn>
            <v-btn @click="createDemoToken()" color="primary" class="ml-3" depressed>{{
              $t('global.userAccessesTab.addDemoTokenModal.submitBtnText')
            }}</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <WifiPasswordModal
      v-if="wifiCredentialsAvailable && isDeviceAdmin"
      v-model="wifiPasswordModalOpen"
      :deviceId="device.id"
    />
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { deviceInviteUser } from '@/graphql/mutations/deviceInviteUser'
import { demoTokenCreate } from '@/graphql/mutations/demoTokenCreate'
import { deviceChangeDeviceAdmin } from '@/graphql/mutations/deviceChangeDeviceAdmin'
import { deviceUserAccesses } from '@/graphql/query/device'
import { demoTokens } from '@/graphql/query/demoTokens'
import UserTile from '@/components/DeviceDetail/UserTile'
import DemoTokenTile from '@/components/DeviceDetail/DemoTokenTile'
import DemoTokenForm from '@/components/DeviceDetail/DemoTokenForm'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import WifiPasswordModal from '@/components/DeviceDetail/WifiPasswordModal'
import { mapGetters } from 'vuex'
import { permissions } from '@/config/permissions'
import { eligibilities } from '@/config/eligibilities'
import { getDeviceName } from '@/utils/getDeviceName'
import { userDeviceAccessRoles } from '@/config/userDeviceAccessRoles'

export default {
  name: 'UserAccessesTab',
  mixins: [validationMixin],
  components: { UserTile, DemoTokenTile, DemoTokenForm, WifiPasswordModal, ConfirmationDialog },
  props: {
    device: Object,
    permissionChecker: Function,
  },
  data() {
    return {
      changeDeviceAdminModalOpen: false,
      confirmChangeDeviceAdminModalOpen: false,
      inviteUserModalOpen: false,
      changeDeviceAdminInputType: 'SELECT',
      invitationEmail: '',
      addDemoTokenModalOpen: false,
      wifiPasswordModalOpen: false,
      wifiCredentialsAvailable: false,
    }
  },
  validations: {
    invitationEmail: { required, email },
  },
  apollo: {
    userAccesses: {
      query: deviceUserAccesses,
      variables() {
        return {
          deviceId: this.device.id,
        }
      },
      update(data) {
        this.wifiCredentialsAvailable = data.device.wifiCredentialsAvailable
        return data.device.userAccesses
      },
    },
    demoTokens: {
      query: demoTokens,
      variables() {
        return {
          deviceId: this.device.id,
        }
      },
      update(data) {
        return data.device.demoTokens
      },
      skip() {
        return (
          !(this.permissionChecker(null, eligibilities.DEMO_MODE) && this.isDeviceAdmin) &&
          !this.permissionChecker(permissions.USER_MANAGEMENT, null)
        )
      },
    },
  },
  methods: {
    async inviteUser() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: deviceInviteUser,
            variables: {
              deviceId: this.device.id,
              email: this.invitationEmail,
              lang: this.$i18n.locale,
            },
            refetchQueries: ['deviceUserAccesses'],
          })

          this.$toast.success(this.$t('global.userAccessesTab.inviteUserAction.success'))

          this.inviteUserModalOpen = false
        } catch (error) {
          console.error(error)
          const parsedError = error.message?.replace('GraphQL error:', '').trim()
          const errorMessages = {
            DEVICE_INVITE_USER_ALREADY_INVITED: this.$t(
              'global.userAccessesTab.inviteUserAction.alreadyInvited',
            ),
            DEVICE_INVITE_USER_ALREADY_HAS_ACCESS: this.$t(
              'global.userAccessesTab.inviteUserAction.alreadyHasAccess',
            ),
          }
          this.$toast.error(
            errorMessages[parsedError] ||
              this.$t('global.userAccessesTab.inviteUserAction.failure'),
          )

          if (errorMessages[parsedError]) {
            this.inviteUserModalOpen = false
          }
        }
      }
    },
    submitChangeDeviceAdmin() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.confirmChangeDeviceAdminModalOpen = true
      }
    },
    async changeDeviceAdmin() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: deviceChangeDeviceAdmin,
            variables: {
              deviceId: this.device.id,
              email: this.invitationEmail,
            },
            refetchQueries: ['GetDevice'],
          })

          this.$toast.success(this.$t('global.userAccessesTab.changeDeviceAdminAction.success'))

          this.confirmChangeDeviceAdminModalOpen = false
          this.changeDeviceAdminModalOpen = false
        } catch (error) {
          console.error(error)
          const parsedError = error.message?.replace('GraphQL error:', '').trim()
          const errorMessages = {
            DEVICE_CHANGE_DEVICE_ADMIN_ALREADY_DEVICE_ADMIN: this.$t(
              'global.userAccessesTab.changeDeviceAdminAction.alreadyDeviceAdmin',
            ),
          }
          this.$toast.error(
            errorMessages[parsedError] ||
              this.$t('global.userAccessesTab.changeDeviceAdminAction.failure'),
          )

          if (errorMessages[parsedError]) {
            this.confirmChangeDeviceAdminModalOpen = false
            this.changeDeviceAdminModalOpen = false
          }
        }
      }
    },
    async createDemoToken() {
      this.$refs.demoTokenForm.$v.$touch()
      if (!this.$refs.demoTokenForm.$v.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: demoTokenCreate,
            variables: {
              data: {
                deviceId: this.device.id,
                ...this.$refs.demoTokenForm.getValue(),
              },
            },
            refetchQueries: ['demoTokens'],
          })

          this.$toast.success(this.$t('global.userAccessesTab.createDemoTokenAction.success'))
          this.$refs.demoTokenForm.reset()
          this.addDemoTokenModalOpen = false
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.userAccessesTab.createDemoTokenAction.failure'))
        }
      }
    },
    getDeviceName,
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    invitationEmailErrors() {
      const errors = []
      if (!this.$v.invitationEmail.$dirty) {
        return errors
      }
      !this.$v.invitationEmail.required && errors.push(this.$t('global.formValidation.required'))
      !this.$v.invitationEmail.email && errors.push(this.$t('global.formValidation.notEmail'))
      return errors
    },
    deviceAdminAccesses() {
      // there should be at most one device admin access, but it is still possible to have more if it was changed directly in db
      return this.userAccesses?.filter(
        (access) => access.type === userDeviceAccessRoles.DEVICE_ADMIN,
      )
    },
    guestAccesses() {
      return this.userAccesses?.filter((access) => access.type === userDeviceAccessRoles.GUEST)
    },
    isDeviceAdmin() {
      return (
        !this.currentCompanyId &&
        this.device.userAccess?.type === userDeviceAccessRoles.DEVICE_ADMIN
      )
    },
    eligibilities() {
      return eligibilities
    },
    permissions() {
      return permissions
    },
  },
  watch: {
    changeDeviceAdminInputType() {
      this.invitationEmail = ''
    },
    changeDeviceAdminModalOpen() {
      this.$v.$reset()
      this.invitationEmail = ''
    },
    inviteUserModalOpen() {
      this.$v.$reset()
      this.invitationEmail = ''
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.user-accesses-tab-wrapper {
  h2 {
    font-size: 18px;
    font-weight: 500;
  }

  .message-wrapper {
    padding: 16px;
    background-color: @color-tile-background;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .tiles-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 5px;
  }
}
</style>
