<template>
  <v-main style="overflow: auto">
    <div v-if="$apollo.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <template v-else>
      <div v-if="user" class="page-wrapper">
        <div class="content-wrapper pa-6">
          <h2>{{ $t('users.userDetail') }}</h2>
          <p v-if="this.user && !this.user.enabled" class="not-enabled">
            {{ $t('users.notEnabled') }}
          </p>
          <v-form class="mt-6">
            <div class="form-wrapper mb-2">
              <v-text-field
                v-model="form.email"
                :error-messages="emailErrors"
                :label="$t('global.email')"
                required
                @input="$v.form.email.$touch()"
                @blur="$v.form.email.$touch()"
                filled
                disabled
              ></v-text-field>
              <v-text-field
                v-model="form.firstName"
                :error-messages="firstNameErrors"
                :label="$t('global.firstName')"
                required
                @input="$v.form.firstName.$touch()"
                @blur="$v.form.firstName.$touch()"
                :disabled="canNotEdit"
                filled
              ></v-text-field>
              <v-text-field
                v-model="form.lastName"
                :error-messages="lastNameErrors"
                :label="$t('global.lastName')"
                required
                @input="$v.form.lastName.$touch()"
                @blur="$v.form.lastName.$touch()"
                :disabled="canNotEdit"
                filled
              ></v-text-field>
              <v-text-field
                v-model="form.phoneNumber"
                :error-messages="phoneNumberErrors"
                :label="$t('global.phoneNumber')"
                @input="$v.form.phoneNumber.$touch()"
                @blur="$v.form.phoneNumber.$touch()"
                :disabled="canNotEdit"
                filled
              ></v-text-field>
            </div>
            <UserPermissionsInput
              v-model="form.companyPermissions"
              :isAdmin="user.companyPermissions[companyPermissions.COMPANY_ADMINISTRATION]"
              :isAdminInSomeCompany="user.isAdminInSomeCompany"
            ></UserPermissionsInput>
          </v-form>
          <div class="d-flex flex-wrap mt-4">
            <v-btn
              color="primary"
              class="mr-5 mb-2"
              @click="updateUserDataAndPermissions()"
              :block="showBlockButtons"
              :disabled="canNotEdit"
              >{{ $t('global.save') }}</v-btn
            >
            <v-btn
              v-if="showDeleteButton"
              @click="isConfirmDeleteDialogOpen = true"
              color="error"
              class="mr-5 mb-2"
              :block="showBlockButtons"
              >{{ $t('global.delete') }}</v-btn
            >
            <v-btn @click="cancel()" :block="showBlockButtons">{{ $t('global.cancel') }}</v-btn>
          </div>
        </div>
      </div>
      <div v-else class="pa-8">
        <v-alert prominent type="error">
          <v-row align="center">
            <v-col class="grow">
              {{ $t('users.userNotFound') }}
            </v-col>
            <v-col class="shrink">
              <v-btn @click="$router.back()">{{ $t('global.back') }}</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </div>

      <ConfirmationDialog
        v-if="showDeleteButton"
        v-model="isConfirmDeleteDialogOpen"
        :heading="$t('users.userDeleteConfirmation.heading')"
        :text="$t('users.userDeleteConfirmation.text')"
        :confirmText="$t('global.delete')"
        confirmBtnColor="error"
        :action="deleteUserFromCompany"
      />
    </template>
  </v-main>
</template>

<script>
import { companyUser } from '@/graphql/query/companyUser'
import UserPermissionsInput from '@/components/User/UserPermissionsInput'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { userInCompanyUpdate } from '@/graphql/mutations/userInCompanyUpdate'
import { userDeleteFromCompany } from '@/graphql/mutations/userDeleteFromCompany'
import { mapGetters } from 'vuex'
import { isPhoneNumber } from '@/utils/phoneNumberValidator'
import { companyPermissions } from '@/config/permissions'
import { assignDefaults } from '@/utils/assignDefaults'

export default {
  name: 'CompanyUserDetailPage',
  mixins: [validationMixin],
  components: { UserPermissionsInput, ConfirmationDialog },
  data: () => {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        companyPermissions: Object.fromEntries(
          Object.values(companyPermissions).map((permissionName) => [permissionName, undefined]),
        ),
      },
      isConfirmDeleteDialogOpen: false,
    }
  },
  validations: {
    form: {
      email: { required },
      firstName: { required },
      lastName: { required },
      phoneNumber: { isPhoneNumber },
      companyPermissions: Object.fromEntries(
        Object.values(companyPermissions).map((permissionName) => [permissionName, {}]),
      ),
    },
  },
  apollo: {
    user: {
      query: companyUser,
      variables() {
        return {
          userId: this.$route.params.id,
          companyId: this.currentCompanyId,
        }
      },
    },
  },
  watch: {
    user: function (newUser) {
      // assign newUser data to form data
      assignDefaults(this.form, newUser)
    },
    'form.companyPermissions'() {
      this.$v.form.companyPermissions.$touch()
    },
    '$v.$anyDirty'(isDirty) {
      this.$store.commit('setHasUnsavedChanges', isDirty)
    },
  },
  methods: {
    async updateUserDataAndPermissions() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: userInCompanyUpdate,
            variables: {
              userId: this.$route.params.id,
              companyId: this.currentCompanyId,
              userData: {
                firstName: this.form.firstName,
                lastName: this.form.lastName,
                phoneNumber: this.form.phoneNumber === '' ? null : this.form.phoneNumber,
              },
              permissions: this.form.companyPermissions,
            },
          })

          this.$toast.success(this.$t('global.dataUpdateSuccess'))

          this.$store.commit('setHasUnsavedChanges', false)
          this.$router.back()
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.dataUpdateFailure'))
        }
      }
    },
    async deleteUserFromCompany() {
      try {
        await this.$apollo.mutate({
          mutation: userDeleteFromCompany,
          variables: {
            userId: this.$route.params.id,
            companyId: this.currentCompanyId,
          },
          update: (store, { data }) => {
            if (data.userDeleteFromCompany.deletedUserId) {
              this.$apolloProvider.defaultClient.cache.evict(
                data.userDeleteFromCompany.deletedUserId,
              )
              this.$apolloProvider.defaultClient.cache.gc()
            }
          },
        })

        this.isConfirmDeleteDialogOpen = false
        this.$toast.success(this.$t('users.userDeleteSuccess'))

        this.$store.commit('setHasUnsavedChanges', false)
        this.$router.back()
      } catch (error) {
        this.$toast.error(this.$t('users.userDeleteFailure'))
      }
    },
    onBeforeUnload() {
      if (this.hasUnsavedChanges) {
        // modern browsers will ignore this message and will show default
        return this.$t('global.unsavedChanges')
      }
    },
    cancel() {
      this.$store.commit('setHasUnsavedChanges', false)
      this.$router.back()
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId', 'currentUserId', 'currentCompanyPermissions']),
    ...mapGetters(['hasUnsavedChanges']),
    canNotEdit() {
      return (
        this.user?.companyPermissions[companyPermissions.COMPANY_ADMINISTRATION] &&
        !this.currentCompanyPermissions[companyPermissions.COMPANY_ADMINISTRATION]
      )
    },
    emailErrors() {
      const errors = []
      if (!this.$v.form.email.$dirty) {
        return errors
      }
      !this.$v.form.email.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
    firstNameErrors() {
      const errors = []
      if (!this.$v.form.firstName.$dirty) {
        return errors
      }
      !this.$v.form.firstName.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
    lastNameErrors() {
      const errors = []
      if (!this.$v.form.lastName.$dirty) {
        return errors
      }
      !this.$v.form.lastName.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
    phoneNumberErrors() {
      const errors = []
      if (!this.$v.form.phoneNumber.$dirty) {
        return errors
      }
      !this.$v.form.phoneNumber.isPhoneNumber &&
        errors.push(this.$t('global.formValidation.notPhoneNumber'))
      return errors
    },
    showBlockButtons: function () {
      return this.$vuetify.breakpoint.name === 'xs'
    },
    showDeleteButton() {
      return (
        this.$route.params.id !== this.currentUserId &&
        (!this.user.companyPermissions[companyPermissions.COMPANY_ADMINISTRATION] ||
          (this.user.companyPermissions[companyPermissions.COMPANY_ADMINISTRATION] &&
            this.currentCompanyPermissions[companyPermissions.COMPANY_ADMINISTRATION]))
      )
    },
    companyPermissions() {
      return companyPermissions
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('global.unsavedChanges'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload
  },
  destroyed() {
    window.onbeforeunload = undefined
    this.$store.commit('setHasUnsavedChanges', false)
  },
}
</script>

<style scoped lang="less">
main {
  flex: 1 1 auto;
}

.page-wrapper {
  min-height: 100%;
  max-width: 768px;
  border-right: 1px solid #eeeeee;

  .content-wrapper {
    border-bottom: 1px solid #eeeeee;

    .form-wrapper {
      max-width: 330px;
    }
  }
}

.headline {
  word-break: break-word;
}

.not-enabled {
  color: darkorange;
}
</style>
