<template>
  <v-main style="overflow: auto">
    <div v-if="newProduct" class="pa-6">
      <v-row>
        <v-col>
          <a @click="$router.back()" class="back-to-dashboard"
            ><v-icon left class="back-arrow" color="primary" size="18">mdi-chevron-left</v-icon
            >{{
              this.fromProductSettings
                ? this.$t('global.backToProductsList')
                : this.$t('global.backToDashboard')
            }}</a
          >
          <h2>{{ this.$t('global.productSettings') }}</h2>
          <div class="top-row">
            <h3 class="mt-6 mb-6">{{ newProduct.name }}</h3>
            <div>
              <v-btn class="mr-2" @click="cancelChanges">{{ this.$t('global.cancel') }}</v-btn>
              <v-btn color="primary" @click="updateProduct">{{ this.$t('global.save') }}</v-btn>
            </div>
          </div>
          <div class="template-chip-container">
            <span class="product-label">{{ this.$t('global.baseProduct') }}:</span>
            <v-chip
              color="gray"
              text-color="black"
              label
              :class="{
                'disabled-chip': !(
                  currentCompanyId && currentCompanyPermissions[permissions.BASE_PRODUCT_CATALOGUE]
                ),
              }"
              @click="openConfirmationDialog"
            >
              {{ newProduct.template.name }}
              <v-icon
                v-if="
                  currentCompanyId && currentCompanyPermissions[permissions.BASE_PRODUCT_CATALOGUE]
                "
                small
                class="ml-2"
                >mdi-pencil</v-icon
              >
            </v-chip>
          </div>
          <div>
            <v-tabs v-model="currentTab" show-arrows class="mt-2">
              <v-tab @click="setFeatureFilter('ALL')">
                {{ this.$t('global.eligibilityFilter.all') }}
              </v-tab>
              <v-tab @click="setFeatureFilter('ONE_OFF')">
                {{ this.$t('global.eligibilityFilter.oneOff') }}
              </v-tab>
              <v-tab @click="setFeatureFilter('SUBSCRIPTION')">
                {{ this.$t('global.eligibilityFilter.subscription') }}
              </v-tab>
              <v-tab @click="setFeatureFilter('SERVICE_SEQUENCES')">
                {{ this.$t('global.eligibilityFilter.serviceSequences') }}
              </v-tab>
              <v-tab @click="setFeatureFilter('PREMIUM')">
                {{ this.$t('global.eligibilityFilter.premium') }}
              </v-tab>
            </v-tabs>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="hasEligibilities">
        <v-col>
          <div class="eligibility-form">
            <!-- Headers -->
            <div class="eligibility-header">
              <div class="name-header">{{ this.$t('global.feature') }}</div>
              <div class="availability-header">{{ this.$t('global.availability') }}</div>
            </div>
            <!-- Eligibility items -->

            <EligibilityDeviceControl
              v-for="eligibility in filteredEligibilities"
              :key="eligibility.name"
              :eligibility="eligibility"
              v-model="form.eligibilities[eligibility.name]"
              @change="unsavedChanges = true"
            />
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="showConfirmationDialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="text-h5">{{ $t('global.changeProductModal.title') }}</v-card-title>
          <v-card-text>{{ $t('global.changeProductModal.description') }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="error" @click="cancelTemplateChange">{{
              $t('global.cancel')
            }}</v-btn>
            <v-btn depressed color="primary" @click="confirmTemplateChange">{{
              $t('global.confirm')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-main>
</template>

<script>
import EligibilityDeviceControl from '@/components/ProductCatalogue/EligibilitiesInputs/EligibilityDeviceControl'
import { newProductUpdate } from '@/graphql/mutations/newProductUpdate'
import { required } from 'vuelidate/lib/validators'
import { getNewProduct } from '@/graphql/query/newProduct'
import { getDeviceThingType } from '@/graphql/query/device'
import { mapGetters } from 'vuex'
import { permissions } from '@/config/permissions'

export default {
  name: 'ProductDetailPage_new',
  components: {
    EligibilityDeviceControl,
  },
  props: {
    productId: String,
    fromProductSettings: Boolean,
  },
  data() {
    return {
      editMode: false,
      form: { eligibilities: {} },
      eligibilityForm: [],
      currentTab: 0,
      featureFilter: 'ALL',
      loadingProductData: true,
      showConfirmationDialog: false,
      unsavedChanges: false,
    }
  },
  validations() {
    return {
      form: {
        eligibilities: { required },
      },
    }
  },
  apollo: {
    newProduct: {
      query: getNewProduct,
      variables() {
        return { productId: this.productId, lang: this.$i18n.locale }
      },
      loadingKey: 'loadingProductData',
      result({ data }) {
        // Convert the eligibilities object into an array format
        this.eligibilityForm = Object.entries(data.newProduct.eligibilities).map(
          ([name, details]) => ({
            name,
            ...details,
          }),
        )
        // Filter out eligibilities with defaultAvailability as 'not_available'
        this.eligibilityForm = this.eligibilityForm.filter(
          (eligibility) => eligibility.defaultAvailability !== 'not_available',
        )
        // Initialize form.eligibilities with default values
        this.eligibilityForm.forEach((eligibility) => {
          // Ensure defaultValue is structured as expected by EligibilityDeviceControl
          this.form.eligibilities[eligibility.name] = {
            defaultValue: eligibility.defaultValue,
            defaultAvailability: eligibility.defaultAvailability,
          }
        })
        this.loadingProductData = false
      },
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId', 'currentCompanyPermissions']),
    permissions() {
      return permissions
    },
    filteredEligibilities() {
      if (!Array.isArray(this.eligibilityForm)) {
        console.error('eligibilityForm is not an array')
        return []
      }

      const filtered = this.eligibilityForm.filter((eligibility) => {
        switch (this.featureFilter) {
          case 'ONE_OFF':
            return eligibility.provisionType === 'ONE_OFF'
          case 'SUBSCRIPTION':
            return eligibility.provisionType === 'SUBSCRIPTION'
          case 'SERVICE_SEQUENCES':
            return eligibility.serviceSequence
          case 'PREMIUM':
            return eligibility.isPremium
          default:
            return true
        }
      })

      filtered.sort((a, b) => {
        if (a.defaultAvailability === 'optional' && b.defaultAvailability !== 'optional') {
          return -1
        } else if (a.defaultAvailability !== 'optional' && b.defaultAvailability === 'optional') {
          return 1
        } else {
          return 0
        }
      })

      return filtered
    },
    hasEligibilities() {
      return !this.loadingProductData && this.eligibilityForm.length > 0
    },
  },
  methods: {
    setFeatureFilter(filterType) {
      this.featureFilter = filterType
    },
    cancelChanges() {
      this.unsavedChanges = false
      this.$router.back()
    },
    async updateProduct() {
      this.unsavedChanges = false
      try {
        const input = {
          productId: this.productId,
          eligibilities: this.form.eligibilities,
        }

        const response = await this.$apollo.mutate({
          mutation: newProductUpdate,
          variables: { input },
        })

        if (response.data.newProductUpdate.success) {
          this.$toast.success(this.$t('global.newProductUpdate.success'))
          this.$router.back()
        } else {
          console.error('Failed to update the product')
          this.$toast.error(this.$t('global.newProductUpdate.failure'))
        }
      } catch (error) {
        console.error('Error updating product:', error)
        this.$toast.error(this.$t('global.newProductUpdate.failure'))
      }
    },
    openConfirmationDialog() {
      this.currentCompanyId && this.currentCompanyPermissions[permissions.BASE_PRODUCT_CATALOGUE]
        ? (this.showConfirmationDialog = true)
        : null
    },
    confirmTemplateChange() {
      this.showConfirmationDialog = false
      this.redirectToNewProductPage(this.newProduct)
    },
    cancelTemplateChange() {
      this.showConfirmationDialog = false
    },
    async redirectToNewProductPage(product) {
      try {
        const response = await this.$apollo.query({
          query: getDeviceThingType,
          variables: {
            deviceId: product.deviceId,
          },
        })

        if (response && response.data && response.data.device) {
          const { id, thingType } = response.data.device
          this.$router.push({
            name: 'AddDeviceProductPage',
            params: {
              controllerId: id,
              deviceName: product.name,
              thingType: thingType.name,
              fromChangeBaseProduct: true,
            },
          })
        } else {
          console.error('Device data fetch failed')
        }
      } catch (error) {
        console.error('Error fetching device data:', error)
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges) {
      const answer = window.confirm(this.$t('global.unsavedChanges'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

/deep/.v-slide-group {
  background-color: tint(@color-brand-highlight, 50%) !important;
  margin-bottom: 20px;

  .v-tab {
    color: @color-brand-2 !important;
    opacity: 0.5;

    &--active {
      opacity: 1;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .v-tabs-slider-wrapper {
    color: @color-brand-2 !important;
  }
}

/* Styling for the eligibility form section */
.eligibility-form {
  border-top: 1px solid #e0e0e0;
}

.top-row {
  display: flex;
  justify-content: space-between;
}

/* Styling for the headers above the radio buttons */
.eligibility-header {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  margin-bottom: 8px;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid #ececec;
}

.back-to-dashboard {
  cursor: pointer;
  color: @color-primary;
  text-decoration: none;
  margin-bottom: 1em;
  display: inline-block;
  display: flex;
  justify-items: center;
  font-size: 0.95rem;
}

.back-to-dashboard:hover {
  opacity: 0.8;
}

.back-arrow {
  max-width: 1rem;
  max-height: auto;
  color: @color-primary;
}

.disabled-chip {
  cursor: default !important;
  pointer-events: none;
}

.name-header {
  flex: 1;
}

.availability-header {
  margin-right: 7rem;
}

.no-header {
  width: 10%;
}

.template-chip-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.product-label {
  opacity: 0.5;
  margin-right: 1rem;
}
</style>
