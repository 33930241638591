import gql from 'graphql-tag'

export const serviceOrders = gql`
  query serviceOrders($deviceId: ID!, $lang: AcceptedLanguageEnum) {
    device(deviceId: $deviceId) {
      id
      serviceOrders {
        edges {
          node {
            id
            number
            text
            status
            createdAt
            author {
              id
              firstName
              lastName
              email
            }
            assignee {
              id
              firstName
              lastName
              email
            }
            thingName
            controller
            contactAddress
            contactPerson
            dataSnapshot(lang: $lang) {
              label
              type
              items {
                ... on Tab {
                  label
                  type
                  items {
                    ... on TabItem {
                      name
                      label
                      type
                      value
                      unit
                      options
                      formattedValue
                    }
                  }
                }
                ... on TabItem {
                  name
                  label
                  type
                  value
                  unit
                  options
                  formattedValue
                }
              }
            }
          }
        }
      }
    }
  }
`
