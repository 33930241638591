import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import LoginPage from './../views/LoginPage'
import DevicePage from './../views/DevicePage'
import LayoutPage from '@/views/LayoutPage'

import { ifAuthenticated } from '../middleware/authentication/ifAuthenticated'
import { registrationGuard } from '../middleware/registrationGuard'
import { deviceTypeGuard } from '../middleware/deviceTypeGuard'
import { authGuard } from '../middleware/authentication/authGuard'
import { permissions } from '@/config/permissions'
import { eligibilities } from '@/config/eligibilities'

import Login from '@/components/Login/Login'
import Register from '@/components/Login/Register'
import ChallengeNewPassword from '@/components/Login/ChallengeNewPassword'
import ForgotPassword from '@/components/Login/ForgotPassword'
import ResetPassword from '@/components/Login/ResetPassword'
import ConfirmUserAccountPage from '@/components/Login/ConfirmUserAccountPage'
import CompanyUsersListPage from '../views/Company/CompanyUsersListPage'
import CompanyNotificationsPage from '../views/Company/CompanyNotificationsPage'
import CompanyServiceCompaniesListPage from '../views/Company/CompanyServiceCompaniesListPage'
import CompanySettingsPage from '../views/Company/CompanySettingsPage'
import CompanyContactEmailsPage from '../views/Company/CompanyContactEmailsPage'
import CompanyContactEmailTemplatePage from '../views/Company/CompanyContactEmailTemplatePage'
import CompanyNotificationFlow from '../views/Company/CompanyNotificationFlow'
import CompanyNotificationDelayConfiguration from '../views/Company/CompanyNotificationDelayConfiguration'
import CompanyInvitePage from '@/views/CompanyInvitePage'
import CompanyUserDetailPage from '@/views/CompanyUserDetailPage'
import AddUserToCompanyPage from '@/views/AddUserToCompanyPage'
import AddCompanyToCompanyPage from '@/views/AddCompanyToCompanyPage'
import UserPermissionsPage from '@/views/UserPermissionsPage'
import ServiceCompanyPermissionsPage from '@/views/ServiceCompanyPermissionsPage'
import ServiceCompanyDetailPage from '@/views/ServiceCompanyDetailPage'
import NotFoundPage from '@/views/NotFoundPage'
import ErrorsPage from '@/views/ErrorsPage'
import DeviceListPage from '@/views/DeviceListPage'
import TemplatesPage from '@/views/TemplatesPage'
import AdminUsers from '@/views/Admin/AdminUsers'
import AdminDevices from '@/views/Admin/AdminDevices'
import BaseProductsPage from '@/views/ProductCatalogue/BaseProductsPage'
import NewProductCataloguePage from '@/views/ProductCatalogue/NewProductCataloguePage'
import DeviceListWithNewProduct from '@/views/ProductCatalogue/DeviceListWithNewProduct'
import AddBaseProductPage from '@/views/ProductCatalogue/AddBaseProductPage'
import EditBaseProductPage from '@/views/ProductCatalogue/EditBaseProductPage'
import AddDeviceProductPage from '@/views/ProductCatalogue/AddDeviceProductPage'
import AdminProductCatalogue from '@/views/Admin/AdminProductCatalogue'
import AdminCompanyEligibilities from '@/views/Admin/AdminCompanyEligibilities'
import AdminThingTypeEligibilities from '@/views/Admin/AdminThingTypeEligibilities'
import AdminThingTypes from '@/views/Admin/AdminThingTypes'
import AdminGeneralEligibilities from '@/views/Admin/AdminGeneralEligibilities'
import AdminCompanies from '@/views/Admin/AdminCompanies'
import DeviceListPageMapView from '@/components/DeviceListViews/DeviceListPageMapView'
import DeviceListPageListView from '@/components/DeviceListViews/DeviceListPageListView'
import AdminServiceHints from '@/views/Admin/AdminServiceHints'
import AdminDetectionPoints from '@/views/Admin/ServiceHints/AdminDetectionPoints'
import AdminEventCodes from '@/views/Admin/ServiceHints/AdminEventCodes'
import AdminEventCodeDetail from '@/views/Admin/ServiceHints/AdminEventCodeDetail'
import AdminCoreCauses from '@/views/Admin/ServiceHints/AdminCoreCauses'
import AdminCoreCauseDetail from '@/views/Admin/ServiceHints/AdminCoreCauseDetail'
import AdminCorrectiveActions from '@/views/Admin/ServiceHints/AdminCorrectiveActions'
import AdminCorrectiveActionDetail from '@/views/Admin/ServiceHints/AdminCorrectiveActionDetail'
import AdminDetectionPointDetail from '@/views/Admin/ServiceHints/AdminDetectionPointDetail'
import AdminServiceHintsOverview from '@/views/Admin/ServiceHints/AdminServiceHintsOverview'
import AdminCrowdin from '@/views/Admin/AdminCrowdin'
import NoDevicesPage from '@/views/NoDevicesPage'
import AdminDelayConfiguration from '@/views/Admin/NotificationDispatcher/AdminDelayConfiguration'
import ServiceSequenceWizard from '@/components/ServiceSequence/ServiceSequenceWizard'
import MyAccountPage from '@/views/MyAccountPage'
import LandingPageTemplate from '@/views/LandingPages/LandingPageTemplate'
import AcceptDeviceAccessRequestLandingPage from '@/views/LandingPages/AcceptDeviceAccessRequestLandingPage'
import ProductDetailPage_new from '@/views/ProductCatalogue/ProductDetailPage_new'

const routes = [
  { path: '/', redirect: '/app' },
  {
    path: '/login',
    component: LoginPage,
    children: [
      {
        path: '',
        name: 'LoginPage',
        component: Login,
        props: true,
      },
      {
        path: '/register',
        name: 'RegisterPage',
        component: Register,
        beforeEnter: registrationGuard,
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
      },
      {
        path: '/set-password',
        name: 'ChallengeNewPasswordPage',
        component: ChallengeNewPassword,
        props: true,
      },
      {
        path: '/confirm-user-account',
        name: 'ConfirmUserAccountPage',
        component: ConfirmUserAccountPage,
        props: true,
      },
    ],
  },
  {
    path: '/app',
    redirect: '/app/devices/pool/list',
    name: 'DashboardPage',
    component: LayoutPage,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: 'devices',
        name: 'NoDevicesPage',
        component: NoDevicesPage,
        beforeEnter: deviceTypeGuard,
      },
      {
        path: 'devices/:type',
        redirect: '/app/devices/:type/list',
        name: 'DeviceListPage',
        component: DeviceListPage,
        beforeEnter: deviceTypeGuard,
        children: [
          {
            path: 'list',
            name: 'DeviceListPageListView',
            component: DeviceListPageListView,
          },
          {
            path: 'map',
            name: 'DeviceListPageMapView',
            component: DeviceListPageMapView,
          },
        ],
      },
      {
        path: 'device/:deviceId',
        name: 'DevicePage',
        component: DevicePage,
      },
      {
        path: 'add-base-product',
        name: 'AddBaseProductPage',
        component: AddBaseProductPage,
      },
      {
        path: 'edit-base-product/:baseProductId',
        name: 'EditBaseProductPage',
        component: EditBaseProductPage,
        props: true,
      },
      {
        path: 'add-device-product/:controllerId/:deviceName/:thingType',
        name: 'AddDeviceProductPage',
        component: AddDeviceProductPage,
        props: true,
      },
      {
        path: 'product-detail/:productId',
        name: 'ProductDetailPage_new',
        component: ProductDetailPage_new,
        props: true,
      },
      {
        path: 'company',
        name: 'CompanyManagementPage',
        redirect: '/app/company/users',
        component: {
          // namespacing route, renders only router view
          render: (c) => c('router-view'),
        },
        children: [
          {
            path: 'users',
            name: 'CompanyUsersListPage',
            component: CompanyUsersListPage,
          },
          {
            path: 'users/add',
            name: 'AddUserToCompanyPage',
            beforeEnter: authGuard([permissions.COMPANY_USER_MANAGEMENT]),
            component: AddUserToCompanyPage,
          },
          {
            path: 'users/permissions',
            name: 'UserPermissionsPage',
            beforeEnter: authGuard([permissions.COMPANY_USER_MANAGEMENT]),
            component: UserPermissionsPage,
          },
          {
            path: 'service-companies',
            name: 'CompanyServiceCompaniesListPage',
            component: CompanyServiceCompaniesListPage,
          },
          {
            path: 'service-companies/add',
            name: 'AddCompanyToCompanyPage',
            beforeEnter: authGuard([permissions.COMPANY_USER_MANAGEMENT]),
            component: AddCompanyToCompanyPage,
          },
          {
            path: 'service-companies/permissions',
            name: 'ServiceCompanyPermissionsPage',
            beforeEnter: authGuard([permissions.COMPANY_USER_MANAGEMENT]),
            component: ServiceCompanyPermissionsPage,
          },
          {
            path: 'notifications',
            redirect: '/app/company/notifications/contact-emails',
            name: 'CompanyNotificationsPage',
            component: CompanyNotificationsPage,
            beforeEnter: authGuard([permissions.COMPANY_ADMINISTRATION]),
            children: [
              {
                path: 'contact-emails',
                name: 'CompanyContactEmailsPage',
                component: CompanyContactEmailsPage,
                beforeEnter: authGuard(
                  [],
                  [
                    eligibilities.ORDER_SERVICE_FROM_APP,
                    eligibilities.ORDER_SERVICE_FROM_APP_FROM_EVENT,
                    eligibilities.NOTIFICATION_DISPATCHER,
                  ],
                  true,
                ),
              },
              {
                path: 'contact-email-template',
                name: 'CompanyContactEmailTemplatePage',
                component: CompanyContactEmailTemplatePage,
                beforeEnter: authGuard(
                  [],
                  [
                    eligibilities.ORDER_SERVICE_FROM_APP,
                    eligibilities.ORDER_SERVICE_FROM_APP_FROM_EVENT,
                  ],
                  true,
                ),
              },
              {
                path: 'flow',
                name: 'CompanyNotificationFlow',
                component: CompanyNotificationFlow,
                beforeEnter: authGuard([], [eligibilities.NOTIFICATION_DISPATCHER]),
              },
              {
                path: 'delays',
                name: 'CompanyNotificationDelays',
                component: CompanyNotificationDelayConfiguration,
                beforeEnter: authGuard([], [eligibilities.NOTIFICATION_DISPATCHER]),
              },
            ],
          },
          {
            path: 'settings',
            name: 'CompanySettingsPage',
            component: CompanySettingsPage,
            beforeEnter: authGuard([permissions.COMPANY_ADMINISTRATION]),
          },
        ],
      },
      {
        path: 'company-invite',
        name: 'CompanyInvitePage',
        beforeEnter: authGuard([permissions.COMPANY_CREATE_INVITE]),
        component: CompanyInvitePage,
      },
      {
        path: 'user-detail/:id',
        name: 'CompanyUserDetailPage',
        beforeEnter: authGuard([permissions.COMPANY_USER_MANAGEMENT]),
        component: CompanyUserDetailPage,
      },
      {
        path: 'service-company-detail/:id',
        name: 'ServiceCompanyDetailPage',
        beforeEnter: authGuard([permissions.COMPANY_USER_MANAGEMENT]),
        component: ServiceCompanyDetailPage,
      },
      {
        path: 'templates',
        name: 'TemplatesPage',
        beforeEnter: authGuard([permissions.TEMPLATE_MANAGEMENT]),
        component: TemplatesPage,
      },
      {
        path: 'product-catalogue',
        beforeEnter: authGuard([permissions.BASE_PRODUCT_CATALOGUE]),
        component: {
          // namespacing route, renders only router view
          render: (c) => c('router-view'),
        },
        children: [
          {
            path: '',
            redirect: '/app/product-catalogue/base-products',
            name: 'NewProductCataloguePage',
            component: NewProductCataloguePage,
            children: [
              {
                path: 'base-products',
                name: 'BaseProductsPage',
                component: BaseProductsPage,
              },
              {
                path: 'new-products',
                name: 'DeviceListWithNewProduct',
                component: DeviceListWithNewProduct,
              },
            ],
          },
        ],
      },
      {
        path: 'admin',
        redirect: '/app/admin/users',
        beforeEnter: authGuard([permissions.SUPER_ADMIN]),
        name: 'AdminConsolePage',
        component: {
          // namespacing route, renders only router view
          render: (c) => c('router-view'),
        },
        children: [
          {
            path: 'users',
            name: 'AdminUsers',
            component: AdminUsers,
          },
          {
            path: 'devices',
            name: 'AdminDevices',
            component: AdminDevices,
          },
          {
            path: 'companies',
            name: 'AdminCompanies',
            component: AdminCompanies,
          },
          {
            path: 'thingTypes',
            name: 'AdminThingTypes',
            component: AdminThingTypes,
          },
          {
            path: 'product-catalogue',
            redirect: '/app/admin/product-catalogue/company-eligibilities',
            name: 'AdminProductCatalogue',
            component: AdminProductCatalogue,
            children: [
              {
                path: 'company-eligibilities',
                name: 'AdminCompanyEligibilities',
                component: AdminCompanyEligibilities,
              },
              {
                path: 'thing-type-eligibilities',
                name: 'AdminThingTypeEligibilities',
                component: AdminThingTypeEligibilities,
              },
              {
                path: 'general-eligibilities',
                name: 'AdminGeneralEligibilities',
                component: AdminGeneralEligibilities,
              },
            ],
          },
          {
            path: 'service-hints',
            name: 'AdminServiceHints',
            redirect: { name: 'AdminEventCodes' },
            component: AdminServiceHints,
            children: [
              {
                path: 'event-codes',
                name: 'AdminEventCodes',
                component: AdminEventCodes,
              },
              {
                path: 'event-codes/:id',
                name: 'AdminEventCodeDetail',
                component: AdminEventCodeDetail,
                props: true,
              },
              {
                path: 'detection-points',
                name: 'AdminDetectionPoints',
                component: AdminDetectionPoints,
              },
              {
                path: 'detection-points/:id',
                name: 'AdminDetectionPointDetail',
                component: AdminDetectionPointDetail,
                props: true,
              },
              {
                path: 'core-causes',
                name: 'AdminCoreCauses',
                component: AdminCoreCauses,
              },
              {
                path: 'core-causes/:id',
                name: 'AdminCoreCauseDetail',
                component: AdminCoreCauseDetail,
                props: true,
              },
              {
                path: 'corrective-actions',
                name: 'AdminCorrectiveActions',
                component: AdminCorrectiveActions,
              },
              {
                path: 'corrective-actions/:id',
                name: 'AdminCorrectiveActionDetail',
                component: AdminCorrectiveActionDetail,
                props: true,
              },
              {
                path: 'overview',
                name: 'AdminServiceHintsOverview',
                component: AdminServiceHintsOverview,
              },
            ],
          },
          {
            path: 'crowdin',
            name: 'AdminCrowdin',
            component: AdminCrowdin,
          },
          {
            path: 'notification',
            name: 'AdminNotificationDelayConfiguration',
            component: AdminDelayConfiguration,
          },
        ],
      },
      {
        path: 'account',
        name: 'MyAccountPage',
        component: MyAccountPage,
      },
      {
        path: '*',
        name: 'Errors',
        component: ErrorsPage,
      },
    ],
  },
  {
    path: '/service-sequence/:deviceId/:serviceSequenceName',
    name: 'ServiceSequenceWizard',
    component: ServiceSequenceWizard,
    props: (route) => ({
      deviceId: route.params.deviceId,
      serviceSequenceName: route.params.serviceSequenceName,
      soloPage: true,
    }),
  },
  {
    path: '/lp',
    component: LandingPageTemplate,
    children: [
      {
        path: 'accept-device-access-request',
        name: 'AcceptDeviceAccessRequestLandingPage',
        component: AcceptDeviceAccessRequestLandingPage,
      },
    ],
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFoundPage,
  },
]

export default new VueRouter({
  mode: 'history',
  routes,
})
