import gql from 'graphql-tag'

export const serviceOrderUpdate = gql`
  mutation serviceOrderUpdate(
    $serviceOrderId: ID!
    $text: String
    $status: ServiceOrderStatusEnum!
    $assigneeId: ID
  ) {
    serviceOrderUpdate(
      input: {
        text: $text
        serviceOrderId: $serviceOrderId
        status: $status
        assigneeId: $assigneeId
      }
    ) {
      success
    }
  }
`
