<template>
  <div>
    <figure
      ref="scrollContainer"
      class="scrollable pb-2"
      :style="tableHeight ? { height: tableHeight + 'px' } : null"
    >
      <table class="rotated-header-table">
        <thead>
          <tr>
            <th><div :class="{ 'shadow-right': showShadow }"></div></th>
            <th
              v-for="(permissionName, key, index) in settablePermissions"
              :key="permissionName"
              :style="{ zIndex: 100 - index }"
            >
              <div class="rotated-header-container" :ref="permissionName">
                <v-tooltip top max-width="300px" z-index="102">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="rotated-header-content"
                      :ref="permissionName"
                      @mouseenter="highlightColumn(permissionName)"
                      @mouseleave="unhighlightColumn(permissionName)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t(`users.permissions.${permissionName}`) }}
                    </div>
                  </template>
                  <span>{{ $t(`users.permissionsDescription.${permissionName}`) }}</span>
                </v-tooltip>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="permissionRow of permissionsEdit" :key="permissionRow.id" class="body-row">
            <template v-for="(value, key) in permissionRow">
              <td
                v-if="key !== 'id' && key !== 'userId' && key !== 'isAdminInSomeCompany'"
                class="text-center"
                :class="{ 'name-td': key === 'name' }"
                :key="key"
                :ref="key"
                @mouseenter="highlightColumn(key)"
                @mouseleave="unhighlightColumn(key)"
              >
                <v-simple-checkbox
                  v-if="key !== 'name'"
                  v-model="permissionRow[key]"
                  color="primary"
                  :ripple="false"
                  :disabled="
                    isPermissionDisabled(
                      key,
                      permissionRow.userId,
                      permissions.find((p) => p.id === permissionRow.id)[
                        companyPermissions.COMPANY_ADMINISTRATION
                      ],
                      permissionRow.isAdminInSomeCompany,
                    )
                  "
                ></v-simple-checkbox>
                <div v-else class="name-wrapper" :class="{ 'shadow-right': showShadow }">
                  <button
                    class="edit-btn"
                    @click="
                      $router.push({
                        name: linkToName,
                        params: { id: permissionRow.userId },
                      })
                    "
                  >
                    <div class="button-text">{{ value }}</div>
                    <v-icon right dense>mdi-pencil</v-icon>
                  </button>
                </div>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </figure>
    <div class="flex-shrink-0 mt-8">
      <v-btn color="primary" class="mr-5" @click="updatePermissions()">{{
        $t('global.save')
      }}</v-btn>
      <v-btn @click="cancel()">{{ $t('global.cancel') }}</v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { companyPermissions } from '@/config/permissions'
import { companyPermissionsUpdate } from '@/graphql/mutations/companyPermissionsUpdate'
import isEqual from 'lodash/isEqual'

export default {
  name: 'PermissionsTable',
  props: {
    settablePermissions: Object,
    permissions: {
      type: Array,
      default() {
        return []
      },
    },
    linkToName: String,
    tableHeight: Number,
  },
  data() {
    return {
      permissionsEdit: JSON.parse(JSON.stringify(this.permissions)),
      hasPermissionsChanged: false,
      showShadow: false,
    }
  },
  mounted() {
    this.$refs.scrollContainer.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    this.$refs.scrollContainer.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    async updatePermissions() {
      try {
        const permissionsToUpdate = this.permissionsEdit
          .filter((permission) => {
            // only admins can update other admins permissions
            const originalUserPermissions = this.permissions.find(
              (p) => p.userId === permission.userId,
            )
            if (originalUserPermissions?.[companyPermissions.COMPANY_ADMINISTRATION]) {
              return this.currentCompanyPermissions[companyPermissions.COMPANY_ADMINISTRATION]
            }
            return true
          })
          .map((permission) => {
            const result = {
              id: permission.id,
            }
            Object.values(companyPermissions).map(
              (permissionName) => (result[permissionName] = permission[permissionName]),
            )
            return result
          })

        await this.$apollo.mutate({
          mutation: companyPermissionsUpdate,
          variables: {
            companyId: this.currentCompanyId,
            permissionsList: permissionsToUpdate,
          },
        })

        this.$toast.success(this.$t('global.dataUpdateSuccess'))

        this.$store.commit('setHasUnsavedChanges', false)
        this.$router.back()
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.dataUpdateFailure'))
      }
    },
    isPermissionDisabled(permissionName, userId, isAdmin, isAdminInSomeCompany) {
      // only admins can change admin permission, and only admin can change any permission on admin
      const a =
        (permissionName === companyPermissions.COMPANY_ADMINISTRATION || isAdmin) &&
        !this.currentCompanyPermissions[companyPermissions.COMPANY_ADMINISTRATION]

      // can not change own company user management and company administration permissions
      const b =
        userId === this.currentUserId &&
        (permissionName === companyPermissions.COMPANY_USER_MANAGEMENT ||
          permissionName === companyPermissions.COMPANY_ADMINISTRATION)

      // user can be admin only in one company
      const c =
        permissionName === companyPermissions.COMPANY_ADMINISTRATION &&
        isAdminInSomeCompany &&
        !isAdmin
      return a || b || c
    },
    highlightColumn(permissionName) {
      if (Object.values(companyPermissions).includes(permissionName)) {
        this.$refs[permissionName].forEach((ref) => (ref.style.backgroundColor = '#D3F1FF'))
      }
    },
    unhighlightColumn(permissionName) {
      if (Object.values(companyPermissions).includes(permissionName)) {
        this.$refs[permissionName].forEach((ref) => (ref.style.backgroundColor = '#ffffff'))
      }
    },
    onBeforeUnload() {
      if (this.hasUnsavedChanges) {
        // modern browsers will ignore this message and will show default
        return this.$t('global.unsavedChanges')
      }
    },
    cancel() {
      this.$store.commit('setHasUnsavedChanges', false)
      this.$router.back()
    },
    onScroll(e) {
      this.showShadow = e.target.scrollLeft !== 0
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId', 'currentCompanyPermissions', 'currentUserId']),
    ...mapGetters(['hasUnsavedChanges']),
    companyPermissions() {
      return companyPermissions
    },
  },
  watch: {
    permissions() {
      this.permissionsEdit = JSON.parse(JSON.stringify(this.permissions))
    },
    permissionsEdit: {
      deep: true,
      handler() {
        this.hasPermissionsChanged = !isEqual(this.permissions, this.permissionsEdit)
      },
    },
    hasPermissionsChanged() {
      this.$store.commit('setHasUnsavedChanges', this.hasPermissionsChanged)
    },
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload
  },
  destroyed() {
    window.onbeforeunload = undefined
    this.$store.commit('setHasUnsavedChanges', false)
  },
}
</script>
<style lang="less" scoped>
@header-height: 180px;
@header-width: 50px;
@user-link-bg-color: #eeeeee;

.scrollable {
  overflow: auto;
}

.rotated-header-table {
  border-collapse: collapse;

  tbody {
    position: relative;
  }

  th {
    position: sticky;
    top: 0;
    max-width: @header-width;
    min-width: @header-width;
    height: @header-height;
    background-color: white;

    &:first-child {
      position: sticky;
      left: 0;
      min-width: 193px;
      max-width: 193px;
      z-index: 101;

      div {
        height: 100%;
      }
    }

    &:first-child::after {
      content: '';
      height: 100%;
      width: 100%;
      background-color: white;
      position: absolute;
      top: 0;
      left: -5px;
    }

    &:last-child::before {
      content: '';
      height: 100%;
      width: 100%;
      background-color: white;
      position: absolute;
      top: 0;
      right: -5px;
    }
  }

  tr {
    border-bottom: 1px solid #d6d6dd;
  }

  tr:last-child {
    border-bottom: none;
  }

  .rotated-header-container {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }

  .rotated-header-content {
    white-space: nowrap;
    width: fit-content;
    height: cos(45deg) * @header-width;
    line-height: cos(45deg) * @header-width;
    padding: 0 10px 0 15px;
    transform-origin: left top;
    transform: rotate(-45deg);
    font-weight: normal;
    font-size: 14px;
    cursor: default;
  }
}

.edit-btn {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 4px;

  .button-text {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    font-size: 14px;
    text-align: left;
    padding: 2px 0 0 5px;
  }

  i {
    visibility: hidden;
    opacity: 0.5;
  }

  &:hover {
    background-color: @user-link-bg-color;

    i {
      visibility: visible;
    }
  }

  &:focus {
    outline: none;
    background-color: @user-link-bg-color;

    i {
      visibility: visible;
    }
  }
}

.body-row {
  height: 43px;
}

.name-td {
  position: sticky;
  left: 0;
  z-index: 50;
}

.shadow-right {
  box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.5);
  clip-path: polygon(1% 0%, 120% 0%, 120% 100%, 1% 100%);
}

.name-wrapper {
  background-color: white;
  padding: 5px 0 4px 5px;
  border-bottom: 1px solid #d6d6dd;

  &:last-child {
    border-bottom: none;
  }
}
</style>
