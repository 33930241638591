import gql from 'graphql-tag'

export const companyUpdateEmailsForEventNotifications = gql`
  mutation companyUpdateEmailsForEventNotifications(
    $input: CompanyUpdateEmailsForEventNotificationsInput!
  ) {
    companyUpdateEmailsForEventNotifications(input: $input) {
      success
    }
  }
`
