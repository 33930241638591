<template>
  <div class="schedule-templates-wrapper mb-12">
    <div class="mb-5 d-flex align-center flex-wrap">
      <h3 class="mr-5">{{ $t('global.dailyTimeSchedules') }}</h3>
      <v-btn color="primary" height="27" depressed @click="startCreatingNewTemplate()">{{
        $t('global.add')
      }}</v-btn>
    </div>
    <div
      class="templates-wrapper"
      :style="{ gridTemplateColumns: `repeat(auto-fill, minmax(${maxTextWidth}px, 1fr))` }"
    >
      <DailyTimeScheduleTile
        v-for="(timeSchedule, index) of dailyTimeSchedules"
        :key="timeSchedule.id"
        :timeSchedule="timeSchedule"
        @openViewModal="openViewModal(timeSchedule.id)"
        @openEditModal="openEditModal(index)"
      />
    </div>

    <v-dialog
      v-model="showErrorModal"
      class="error-dialog custom-dialog-overlay"
      content-class="custom-dialog-overlay"
      width="500"
      z-index="500"
    >
      <v-card>
        <v-card-title class="headline">Error</v-card-title>
        <v-card-text>{{
          $t('global.errorInTimeScheduleConfigUpdate', {
            deviceList: deviceNames,
          })
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showErrorModal = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DailyTimeSchedulesModal
      v-if="dailyTimeSchedules"
      v-model="dailySchedulesModalOpen"
      :dailyTimeSchedules="dailyTimeSchedules"
      :weeklyTimeSchedules="weeklyTimeSchedules"
      :timeScheduleConfig="timeScheduleConfig"
      :editable="true"
      :createMethod="createDailyTimeSchedule"
      :updateMethod="updateDailyTimeSchedule"
      :deleteMethod="deleteDailyTimeSchedule"
      :preselectScheduleId="preselectedScheduleId"
      templateMode
    />
    <DailyTimeScheduleEditModal
      v-if="baseScheduleForEditModal"
      v-model="addTemplateModalOpen"
      :timeSchedule="baseScheduleForEditModal"
      :timeScheduleConfig="timeScheduleConfig"
      :saveMethod="saveMethodForEditModal"
      :deleteMethod="deleteDailyTimeSchedule"
      :disabledDeleteMessage="disabledDeleteMessageForEditModal"
      :namesTaken="namesTakenForEditModal"
      :updateMode="editModalIsInUpdateMode"
      :usedInWeeklySchedulesNames="usedInWeeklyForEditModal"
    />
  </div>
</template>
<script>
import { companyWeeklyAndDailyTimeSchedules } from '@/graphql/query/companyTimeSchedules'
import { mapGetters } from 'vuex'
import DailyTimeScheduleTile from '@/components/TimeSchedules/DailyTimeScheduleTile'
import DailyTimeScheduleEditModal from '@/components/TimeSchedules/DailyTimeScheduleEditModal'
import { getTextWidth } from '@/utils/getTextWidth'
import {
  dailyTimeScheduleCreate,
  dailyTimeScheduleUpdate,
  dailyTimeScheduleDelete,
} from '@/graphql/mutations/dailyTimeSchedule'
import DailyTimeSchedulesModal from '@/components/TimeSchedules/DailyTimeSchedulesModal'

export default {
  name: 'DailyTimeScheduleTemplates',
  components: {
    DailyTimeScheduleTile,
    DailyTimeScheduleEditModal,
    DailyTimeSchedulesModal,
  },
  props: {
    deviceType: String,
    timeScheduleConfig: Object,
  },
  data() {
    return {
      addTemplateModalOpen: false,
      dailySchedulesModalOpen: false,
      baseScheduleForEditModal: null,
      namesTakenForEditModal: null,
      saveMethodForEditModal: null,
      preselectedScheduleId: null,
      weeklyTimeSchedules: null,
      editModalIsInUpdateMode: false,
      usedInWeeklyForEditModal: null,
      disabledDeleteMessageForEditModal: null,
      showErrorModal: false,
      deviceNames: '',
    }
  },
  apollo: {
    dailyTimeSchedules: {
      query: companyWeeklyAndDailyTimeSchedules,
      variables() {
        return {
          companyId: this.currentCompanyId,
          deviceType: this.deviceType,
        }
      },
      update(data) {
        this.weeklyTimeSchedules = data.company.weeklyTimeSchedules
        return data.company.dailyTimeSchedules
      },
    },
  },
  methods: {
    async createDailyTimeSchedule(newSchedule, newName) {
      try {
        await this.$apollo.mutate({
          mutation: dailyTimeScheduleCreate,
          variables: {
            input: {
              companyId: this.currentCompanyId,
              name: newName,
              schedule: newSchedule,
              deviceType: this.deviceType,
            },
          },
          refetchQueries: ['companyWeeklyAndDailyTimeSchedules'],
        })
        this.$toast.success(this.$t('device.timeSchedule.createTimeScheduleSuccess'))
      } catch (error) {
        console.error(error)
        throw this.$t('device.timeSchedule.createTimeScheduleFailed')
      }
    },
    async updateDailyTimeSchedule(newSchedule, newName, dailyTimeScheduleId) {
      try {
        const response = await this.$apollo.mutate({
          mutation: dailyTimeScheduleUpdate,
          variables: {
            input: {
              dailyTimeScheduleId,
              name: newName,
              schedule: newSchedule,
            },
          },
          refetchQueries: ['companyWeeklyAndDailyTimeSchedules'],
        })
        if (response.data.dailyTimeScheduleUpdate.deviceNames) {
          this.deviceNames = response.data.dailyTimeScheduleUpdate.deviceNames
          this.showErrorModal = true
        } else {
          this.$toast.success(this.$t('device.timeSchedule.updateTimeScheduleSuccess'))
        }
      } catch (error) {
        console.error(error)
        throw this.$t('device.timeSchedule.updateTimeScheduleFailed')
      }
    },
    async deleteDailyTimeSchedule(dailyScheduleId) {
      try {
        await this.$apollo.mutate({
          mutation: dailyTimeScheduleDelete,
          variables: {
            dailyScheduleId,
          },
          refetchQueries: ['companyWeeklyAndDailyTimeSchedules'],
        })
        this.$toast.success(this.$t('device.timeSchedule.deleteTimeScheduleSuccess'))
      } catch (error) {
        console.error(error)
        throw this.$t('device.timeSchedule.deleteTimeScheduleFailed')
      }
    },
    startCreatingNewTemplate() {
      this.baseScheduleForEditModal = {
        name: '',
        schedule: Object.fromEntries(
          this.timeScheduleConfig.schedules.map((scheduleConfig) => [scheduleConfig.name, []]),
        ),
      }
      this.namesTakenForEditModal = this.dailySchedulesNames
      this.saveMethodForEditModal = this.createDailyTimeSchedule
      this.editModalIsInUpdateMode = false
      this.disabledDeleteMessageForEditModal = null
      this.addTemplateModalOpen = true
    },
    openViewModal(id) {
      this.preselectedScheduleId = id
      this.dailySchedulesModalOpen = true
    },
    openEditModal(index) {
      this.baseScheduleForEditModal = this.dailyTimeSchedules[index]
      this.namesTakenForEditModal = this.removeName(
        this.dailyTimeSchedules[index].name,
        this.dailySchedulesNames,
      )
      this.saveMethodForEditModal = this.updateDailyTimeSchedule
      this.editModalIsInUpdateMode = true
      this.usedInWeeklyForEditModal = this.weeklyTimeSchedules
        .filter((weeklySchedule) =>
          weeklySchedule.weeklySchedule.find(
            (entry) => entry.dailySchedule.id === this.dailyTimeSchedules[index].id,
          ),
        )
        .map((weeklySchedule) => weeklySchedule.name)
      this.disabledDeleteMessageForEditModal = this.dailyTimeSchedules[index].isUsed
        ? this.$t('global.disabledDeleteTooltipForDailyScheduleUsed')
        : null
      this.addTemplateModalOpen = true
    },
    removeName(name, namesTaken) {
      const index = namesTaken.indexOf(name)
      if (index === -1) {
        return namesTaken
      }
      const namesCopy = [...namesTaken]
      namesCopy.splice(index, 1)
      return namesCopy
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    maxTextWidth() {
      const computedMaxTextWidth = this.dailyTimeSchedules?.reduce((maxWidth, cur) => {
        return Math.max(maxWidth, getTextWidth(cur.name, 'bold 18px Roboto'))
      }, 200)

      return Math.min(computedMaxTextWidth ? computedMaxTextWidth + 35 : 200, 300) // +35 tile padding
    },
    dailySchedulesNames() {
      if (!this.dailyTimeSchedules) {
        return []
      }
      return this.dailyTimeSchedules.map((schedule) => schedule.name)
    },
  },
  watch: {
    addTemplateModalOpen() {
      if (!this.addTemplateModalOpen) {
        this.baseScheduleForEditModal = null
      }
    },
  },
}
</script>
<style lang="less" scoped>
.schedule-templates-wrapper {
  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  .templates-wrapper {
    display: grid;
    gap: 5px;
  }
}
</style>
