<template>
  <v-dialog :value="value" @input="$emit('input', $event)" width="unset">
    <v-card>
      <v-card-title class="headline"
        ><p
          class="mb-0"
          v-sanitized-html="
            replaceServiceSequenceVariables(config.header, reactive.serviceSequenceLiveData)
          "
        ></p
      ></v-card-title>
      <v-card-text>
        <p
          v-sanitized-html="
            replaceServiceSequenceVariables(config.text, reactive.serviceSequenceLiveData)
          "
        ></p>
        <v-alert v-if="reactive.isDeviceOffline" dense text type="error">{{
          $t('global.serviceSequenceWizard.deviceWentOffline')
        }}</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="$emit('input', false)">
          {{ $t('global.back') }}
        </v-btn>
        <v-btn
          depressed
          :color="config.buttonColor"
          @click="reactive.handleCommand(command)"
          :loading="reactive.loadingAction === command"
          :disabled="reactive.isDeviceOffline"
        >
          {{ replaceServiceSequenceVariables(config.buttonText, reactive.serviceSequenceLiveData) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { replaceServiceSequenceVariables } from '@/utils/replaceServiceSequenceVariables'

export default {
  name: 'ServiceSequenceConfirmationDialog',
  props: {
    value: Boolean,
    config: Object,
    command: String,
  },
  data() {
    return {
      openConfirmationModal: null,
    }
  },
  inject: ['reactive'],
  methods: {
    replaceServiceSequenceVariables,
  },
}
</script>
