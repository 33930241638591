<template>
  <v-col v-bind="config.props">
    <component
      v-for="(componentConfig, index) of config.children"
      :key="index"
      :is="selectComponent(componentConfig)"
      v-bind="getComponentProps(componentConfig)"
    ></component>
  </v-col>
</template>
<script>
import ServiceSequenceText from '@/components/ServiceSequence/components/ServiceSequenceText'
import ServiceSequenceList from '@/components/ServiceSequence/components/ServiceSequenceList'
import ServiceSequenceImage from '@/components/ServiceSequence/components/ServiceSequenceImage'
import ServiceSequenceButton from '@/components/ServiceSequence/components/ServiceSequenceButton'
import ServiceSequenceLayoutRow from '@/components/ServiceSequence/layouts/ServiceSequenceLayoutRow'
import ServiceSequenceTextInput from '@/components/ServiceSequence/components/ServiceSequenceTextInput'
import { merge } from 'lodash'

export default {
  name: 'ServiceSequenceLayoutColumn',
  components: {
    ServiceSequenceText,
    ServiceSequenceList,
    ServiceSequenceImage,
    ServiceSequenceButton,
    ServiceSequenceLayoutRow,
    ServiceSequenceTextInput,
  },
  props: {
    config: Object,
    componentConfigs: Array,
  },
  methods: {
    selectComponent(componentConfig) {
      const typeMap = {
        text: ServiceSequenceText,
        list: ServiceSequenceList,
        image: ServiceSequenceImage,
        button: ServiceSequenceButton,
        textInput: ServiceSequenceTextInput,
      }

      if (componentConfig.id) {
        const component = this.componentConfigs.find((c) => c.id === componentConfig.id)
        if (!component) {
          console.error(`Component with id ${componentConfig.id} not found.`)
          return
        }
        return typeMap[component.type]
      }
      if (componentConfig.type === 'row') {
        return ServiceSequenceLayoutRow
      }
      console.error(
        `Component type ${componentConfig.type} not expected. Column components can only contain rows or components.`,
      )
    },
    getComponentProps(componentConfig) {
      if (componentConfig.id) {
        const component = this.componentConfigs.find((c) => c.id === componentConfig.id)
        // for some reason lodash merge doesn't work here without the JSON.parse/JSON.stringify
        return merge(
          JSON.parse(JSON.stringify(component)),
          JSON.parse(JSON.stringify(componentConfig)),
        )
      }
      if (componentConfig.type === 'row') {
        return {
          config: componentConfig,
          componentConfigs: this.componentConfigs,
        }
      }
    },
  },
}
</script>
