import gql from 'graphql-tag'
import { CompanyRelatedPermissions } from '../fragments/companyRelatedPermissions'

export const companyPermissionsUpdate = gql`
  mutation companyPermissionsUpdate(
    $companyId: ID!
    $permissionsList: [CompanyPermissionUpdateInput!]!
  ) {
    companyPermissionsUpdate(input: { companyId: $companyId, permissions: $permissionsList }) {
      permissions {
        ...CompanyRelatedPermissions
      }
    }
  }
  ${CompanyRelatedPermissions}
`
