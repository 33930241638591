<template>
  <div
    class="suggestions-wrapper"
    :style="{ top: x + 'px', left: y + 'px' }"
    v-click-outside="clickOutsideHandler"
  >
    <ul>
      <li
        v-for="(item, index) in items"
        :key="index"
        @click="selectItem(index)"
        :class="{ 'is-selected': index === selectedIndex }"
      >
        <span>{{ getName(item) }}</span>
        <span v-if="hasName(item)" class="email">{{ item.email }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'MentionList',
  props: {
    items: Array,
    command: Function,
    exit: Function,
  },
  data() {
    return {
      selectedIndex: 0,
      x: 0,
      y: 0,
    }
  },
  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },
    upHandler() {
      this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length
    },
    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },
    enterHandler() {
      this.selectItem(this.selectedIndex)
    },
    clickOutsideHandler() {
      this.exit()
    },
    selectItem(index) {
      const item = this.items[index]

      if (item) {
        this.command({ id: item.id, label: this.getName(item) })
      }
    },
    getName(item) {
      return this.hasName(item) ? `${item.firstName} ${item.lastName}` : item.email
    },
    hasName(item) {
      return item.firstName && item.firstName.trim() !== ''
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.suggestions-wrapper {
  position: absolute;
  z-index: @z-rich-text-suggestions;

  ul {
    list-style-type: none;
    background-color: white;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 5px 0;
  }

  li {
    padding: 10px;
    cursor: pointer;

    &.is-selected {
      background-color: #dbdbdb !important;
    }

    &:hover {
      background-color: #f6f6f6;
    }

    .email {
      display: block;
      font-size: 12px;
      color: #777;
    }
  }
}
</style>
