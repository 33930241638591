<template>
  <div class="pa-6">
    <h1 class="mb-5">
      {{ $t('global.adminConsole') }}: {{ $t('global.adminConsoleMenu.devices') }}
    </h1>
    <v-data-table
      :headers="headers"
      :items="devicesData"
      :options.sync="options"
      :server-items-length="devices ? devices.totalCount : 0"
      :loading="$apollo.loading"
      :disable-sort="true"
      :no-data-text="$t('users.noMatchingRecordsFound')"
      :footer-props="{
        'items-per-page-text': $t('global.dataTable.itemsPerPageText'),
        'items-per-page-all-text': $t('global.dataTable.allItemsText'),
      }"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-text-field v-model="search" :label="$t('global.doFilter')" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.deleteCol="{ item }">
        <v-btn icon @click="openEditModal(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon color="error" @click="openDeleteDeviceConfirmationDialog(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-if="deviceToEdit" v-model="editModalOpen" width="350px">
      <v-card>
        <v-card-title class="headline">
          {{ $t('device.updateDevice') }}:<br />{{ deviceToEdit.name }}
        </v-card-title>
        <v-card-text>
          <v-form class="mt-2">
            <v-text-field
              v-model="deviceToEdit.controller"
              :error-messages="controllerErrors"
              :label="$t('device.controller')"
              required
              @input="$v.deviceToEdit.controller.$touch()"
              @blur="$v.deviceToEdit.controller.$touch()"
              counter="11"
              :class="invalidController ? 'invalid-controller' : 'valid-controller'"
              :append-outer-icon="invalidController ? 'mdi-alert' : 'mdi-check'"
              filled
            ></v-text-field>
            <v-select
              :items="companiesOptions"
              :error-messages="companyErrors"
              :label="$t('device.deviceListHeaders.company')"
              item-text="name"
              item-value="id"
              v-model="deviceToEdit.companyId"
              filled
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="editModalOpen = false">
            {{ $t('global.cancel') }}
          </v-btn>
          <v-btn color="primary" @click="updateDevice()">
            {{ $t('global.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationDialog
      v-if="deviceToDelete"
      v-model="confirmDeleteModalOpen"
      :heading="$t('device.deviceDeleteConfirmation.heading')"
      :text="$t('device.deviceDeleteConfirmation.text', { controller: deviceToDelete.controller })"
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
      :action="deleteDevice"
    />
  </div>
</template>
<script>
import { devices as devicesQuery } from '@/graphql/query/devices'
import { companiesOptions } from '@/graphql/query/companies'
import { doesControllerExists } from '@/graphql/query/doesControllerExists'
import { deviceDelete } from '@/graphql/mutations/deviceDelete'
import { deviceUpdateAdmin } from '@/graphql/mutations/deviceUpdateAdmin'
import debounce from 'lodash/debounce'
import { required, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import ConfirmationDialog from '@/components/ConfirmationDialog'

export default {
  name: 'AdminDevices',
  components: { ConfirmationDialog },
  mixins: [validationMixin],
  data() {
    return {
      devicesQueryInput: {
        search: null,
        first: 10,
        after: null,
        last: null,
        before: null,
      },
      options: {},
      search: null,
      headers: [
        { text: this.$t('device.deviceListHeaders.name'), value: 'name' },
        { text: this.$t('device.controller'), value: 'controller' },
        { text: this.$t('device.deviceListHeaders.company'), value: 'company.name' },
        { text: '', value: 'deleteCol', align: 'right' },
      ],
      deviceToDelete: null,
      deviceToEdit: null,
      confirmDeleteModalOpen: false,
      editModalOpen: false,
      invalidController: false,
    }
  },
  validations: {
    deviceToEdit: {
      controller: { required, maxLength: maxLength(11) },
      companyId: { required },
    },
  },
  apollo: {
    devices: {
      query: devicesQuery,
      variables() {
        return this.devicesQueryInput
      },
    },
    companiesOptions: {
      query: companiesOptions,
      update(data) {
        return data.companies.edges.map((companyEdge) => companyEdge.node)
      },
    },
  },
  methods: {
    async deleteDevice() {
      try {
        await this.$apollo.mutate({
          mutation: deviceDelete,
          variables: {
            deviceId: this.deviceToDelete.id,
          },
          refetchQueries: ['devices'],
        })
        this.$toast.success(this.$t('device.deviceDeleteSuccess'))
        this.confirmDeleteModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('device.deviceDeleteFailure'))
      }
    },
    async updateDevice() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: deviceUpdateAdmin,
            variables: {
              deviceId: this.deviceToEdit.id,
              companyId: this.deviceToEdit.companyId,
              controller: this.deviceToEdit.controller,
            },
            refetchQueries: ['devices'],
          })
          this.$toast.success(this.$t('device.deviceUpdateSuccess'))
          this.editModalOpen = false
        } catch (error) {
          console.error(error)
          const parsedError = error.message?.replace('GraphQL error:', '').trim()
          this.$toast.error(
            parsedError === 'DEVICE_UPDATE_ADMIN_CONTROLLER_TAKEN'
              ? this.$t('device.deviceUpdateControllerTaken')
              : this.$t('device.deviceUpdateFailure'),
          )
        }
      }
    },
    openDeleteDeviceConfirmationDialog(device) {
      this.deviceToDelete = device
      this.confirmDeleteModalOpen = true
    },
    openEditModal(device) {
      this.deviceToEdit = { ...device, companyId: device.company.id }
      this.editModalOpen = true
    },
    debounceSearch: debounce(function (newSearch) {
      this.devicesQueryInput = {
        search: newSearch !== '' ? newSearch : null,
        first: this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : null,
        after: null,
        last: null,
        before: null,
      }
    }, 250),
  },
  computed: {
    devicesData() {
      return this.devices ? this.devices.edges.map((edge) => edge.node) : []
    },
    controllerErrors() {
      const errors = []
      if (!this.$v.deviceToEdit.controller.$dirty) {
        return errors
      }
      !this.$v.deviceToEdit.controller.required &&
        errors.push(this.$t('global.formValidation.required'))
      !this.$v.deviceToEdit.controller.maxLength &&
        errors.push(this.$t('global.formValidation.tooLong'))
      return errors
    },
    companyErrors() {
      const errors = []
      if (!this.$v.deviceToEdit.companyId.$dirty) {
        return errors
      }
      !this.$v.deviceToEdit.companyId.required &&
        errors.push(this.$t('global.formValidation.required'))
      return errors
    },
  },
  watch: {
    options: {
      handler({ page, itemsPerPage }, { page: oldPage, itemsPerPage: oldItemsPerPage }) {
        if (!oldPage && !oldItemsPerPage) {
          return
        }
        if (oldItemsPerPage !== itemsPerPage) {
          // change page size
          this.devicesQueryInput = {
            ...this.devicesQueryInput,
            first: itemsPerPage !== -1 ? itemsPerPage : null,
            after: null,
            last: null,
            before: null,
          }
        } else if (page === oldPage + 1) {
          // next page
          this.devicesQueryInput = {
            ...this.devicesQueryInput,
            first: itemsPerPage,
            after: this.devices.pageInfo.endCursor,
            last: null,
            before: null,
          }
        } else if (page === oldPage - 1) {
          // previous page
          this.devicesQueryInput = {
            ...this.devicesQueryInput,
            first: null,
            after: null,
            last: itemsPerPage,
            before: this.devices.pageInfo.startCursor,
          }
        }
      },
      deep: true,
    },
    search() {
      this.debounceSearch(this.search)
    },
    'deviceToEdit.controller': debounce(async function (newValue) {
      try {
        const result = await this.$apollo.query({
          query: doesControllerExists,
          variables: {
            controller: newValue,
          },
        })

        this.invalidController = !result?.data?.doesControllerExists
      } catch (error) {
        console.error(error)
        this.invalidController = true
      }
    }, 250),
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

h1 {
  font-size: 24px;
}

// deep styles needed because icon is appended by append-outer-icon and cannot be accessed directly
.valid-controller /deep/.v-icon {
  color: @color-success !important;
}
.invalid-controller /deep/.v-icon {
  color: @color-error !important;
}
</style>
