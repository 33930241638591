import gql from 'graphql-tag'

export const coreCauseAssignCorrectiveAction = gql`
  mutation coreCauseAssignCorrectiveAction($coreCauseId: ID!, $correctiveActionId: ID!) {
    coreCauseAssignCorrectiveAction(
      input: { coreCauseId: $coreCauseId, correctiveActionId: $correctiveActionId }
    ) {
      success
    }
  }
`
