<template>
  <div class="schedule-overview-border">
    <div class="schedule-overview-wrapper">
      <MinimapTimeline class="minimap-timeline" />
      <div
        v-if="showCurrentTime"
        class="current-time-indicator"
        :style="{ left: `${leftOffset}%` }"
      ></div>
      <template v-for="config of timeScheduleConfig.schedules">
        <div class="horizontal-line" :key="`${config.name}-line`" />
        <span :key="`${config.name}-span`" class="plan-name">{{ config.label }}</span>
        <PlanMinimap
          :key="`${config.name}-plan`"
          :timeSchedule="normalizedTimeSchedule"
          :timeScheduleConfig="timeScheduleConfig"
          :timeScheduleName="config.name"
          :height="height"
          @planErrorConditionMet="$emit('dailyScheduleErrorConditionMet')"
          class="plan"
        />
      </template>
    </div>
  </div>
</template>
<script>
import PlanMinimap from '@/components/TimeSchedules/PlanMinimap'
import MinimapTimeline from '@/components/TimeSchedules/MinimapTimeline'
import startOfDay from 'date-fns/startOfDay'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import { normalizeAndSortTimeSchedule } from '@/utils/timeSchedule'

export default {
  name: 'DailyScheduleOverview',
  components: { PlanMinimap, MinimapTimeline },
  props: {
    timeSchedule: Object,
    timeScheduleConfig: Object,
    height: Number,
    showCurrentTime: Boolean,
  },
  data() {
    return {
      leftOffset: 0,
      updateInterval: null,
    }
  },
  created() {
    const now = new Date()
    this.leftOffset = (differenceInSeconds(now, startOfDay(now)) / 86400) * 100

    this.updateInterval = setInterval(() => {
      const now = new Date()
      this.leftOffset = (differenceInSeconds(now, startOfDay(now)) / 86400) * 100
    }, 60000)
  },
  beforeDestroy() {
    clearInterval(this.updateInterval)
  },
  computed: {
    normalizedTimeSchedule() {
      return normalizeAndSortTimeSchedule(this.timeSchedule.schedule)
    },
  },
}
</script>
<style lang="less" scoped>
.schedule-overview-border {
  border-radius: 4px;
}

.schedule-overview-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  position: relative;
  margin: 0px 18px;
}

.horizontal-line {
  grid-column: span 2;
  margin: 0px -18px;
  border-top: 1px solid #f2f2f2;
}

.current-time-indicator {
  position: absolute;
  bottom: 0;
  background: #231f20;
  width: 1px;
  height: calc(100% - 51px);
  z-index: 1;
  grid-column: 2;

  &::after {
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    background: #231f20;
    position: absolute;
    top: -4.5px;
    left: -4.5px;
    border-radius: 100%;
  }
}

.minimap-timeline {
  grid-column: 2;
  margin: 18px 0px;
}

.plan-name {
  font-weight: 400;
  font-size: 13px;
  align-self: stretch;
  display: flex;
  align-items: center;
  padding-right: 34px;
}

.plan {
  padding: 18px 0px;
}

@media (max-width: 992px) {
  .horizontal-line {
    grid-column: unset;
  }

  .current-time-indicator {
    grid-column: unset;
  }

  .schedule-overview-wrapper {
    grid-template-columns: 1fr;
    gap: 0px;
  }

  .minimap-timeline {
    grid-column: unset;
  }

  .plan-name {
    padding: 15px 0px 0px 0px;
  }

  .plan {
    border-top: none;
    padding: 3px 0px 18px 0px;
  }
}
</style>
