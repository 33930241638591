import PasswordValidator from 'password-validator'

const schema = new PasswordValidator()

schema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols()
  .has()
  .not()
  .spaces()

export default function (password) {
  return schema.validate(password)
}
