<template>
  <div>
    <div v-if="$apollo.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else>
      <div
        v-if="
          currentCompanyGeneralEligibilities[eligibilities.ORDER_SERVICE_FROM_APP] ||
          currentCompanyGeneralEligibilities[eligibilities.ORDER_SERVICE_FROM_APP_FROM_EVENT]
        "
        :class="{
          'mb-12': currentCompanyGeneralEligibilities[eligibilities.NOTIFICATION_DISPATCHER],
        }"
      >
        <h2>{{ $t('global.companyDetail.emailAddressesForContactEmails') }}</h2>
        <v-combobox
          v-model="contactEmailAddresses"
          :error-messages="contactEmailsErrors"
          :hide-no-data="!search"
          :items="companyUsersEmails"
          :search-input.sync="search"
          hide-selected
          :label="$t('global.companyDetail.targetAddresses')"
          :hint="$t('global.companyDetail.targetAddressesHint')"
          persistent-hint
          @input="$v.contactEmailAddresses.$touch()"
          @blur="$v.contactEmailAddresses.$touch()"
          multiple
          small-chips
          filled
          style="max-width: 330px"
        >
          <template v-slot:no-data>
            <v-list-item>
              <span class="subheading mr-2">{{ $t('global.companyDetail.createNewEmail') }}</span>
              <v-chip small>
                {{ search }}
              </v-chip>
            </v-list-item>
          </template>
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip v-bind="attrs" :input-value="selected" small>
              <span class="pr-2 mt-1">
                {{ item }}
              </span>
              <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>
          </template>
        </v-combobox>

        <div class="mt-5 d-flex flex-wrap">
          <v-btn
            @click="companyUpdateContactEmails()"
            :block="showBlockButtons"
            class="mr-2"
            :class="{ 'mb-1': showBlockButtons }"
            :disabled="!hasContactEmailFormChanged"
            color="primary"
            depressed
            >{{ $t('global.save') }}</v-btn
          >
          <v-btn
            v-if="hasContactEmailFormChanged"
            @click="resetContactEmailForm()"
            :block="showBlockButtons"
            depressed
            >{{ $t('global.cancel') }}</v-btn
          >
        </div>
      </div>

      <div v-if="currentCompanyGeneralEligibilities[eligibilities.NOTIFICATION_DISPATCHER]">
        <h2>{{ $t('global.companyDetail.emailAddressesForEventNotifications') }}</h2>
        <v-combobox
          v-model="emailsForEventNotifications"
          :error-messages="emailsForEventNotificationsErrors"
          :hide-no-data="!search"
          :items="companyUsersEmails"
          :search-input.sync="search"
          hide-selected
          :label="$t('global.companyDetail.targetAddresses')"
          :hint="$t('global.companyDetail.targetAddressesHint')"
          persistent-hint
          @input="$v.emailsForEventNotifications.$touch()"
          @blur="$v.emailsForEventNotifications.$touch()"
          multiple
          small-chips
          filled
          style="max-width: 330px"
        >
          <template v-slot:no-data>
            <v-list-item>
              <span class="subheading mr-2">{{ $t('global.companyDetail.createNewEmail') }}</span>
              <v-chip small>
                {{ search }}
              </v-chip>
            </v-list-item>
          </template>
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip v-bind="attrs" :input-value="selected" small>
              <span class="pr-2 mt-1">
                {{ item }}
              </span>
              <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>
          </template>
        </v-combobox>

        <div class="mt-5 d-flex flex-wrap">
          <v-btn
            @click="companyUpdateEmailsForEventNotifications()"
            :block="showBlockButtons"
            class="mr-2"
            :class="{ 'mb-1': showBlockButtons }"
            :disabled="!hasEmailForEventNotificationsFormChanged"
            color="primary"
            depressed
            >{{ $t('global.save') }}</v-btn
          >
          <v-btn
            v-if="hasEmailForEventNotificationsFormChanged"
            @click="resetEmailsForEventNotificationsForm()"
            :block="showBlockButtons"
            depressed
            >{{ $t('global.cancel') }}</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { companyContactEmails } from '@/graphql/query/companyForContact'
import { companyUpdateContactEmails } from '@/graphql/mutations/companyUpdateContactEmails'
import { companyUpdateEmailsForEventNotifications } from '@/graphql/mutations/companyUpdateEmailsForEventNotifications'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { email } from 'vuelidate/lib/validators'
import { eligibilities } from '@/config/eligibilities'

export default {
  name: 'CompanyContactEmailsPage',
  mixins: [validationMixin],
  data() {
    return {
      contactEmailAddresses: null,
      emailsForEventNotifications: null,
      companyUsersEmails: null,
      search: null,
    }
  },
  validations: {
    contactEmailAddresses: {
      $each: { email },
    },
    emailsForEventNotifications: {
      $each: { email },
    },
  },
  apollo: {
    company: {
      query: companyContactEmails,
      variables() {
        return {
          companyId: this.currentCompanyId,
          withContactEmailAddresses:
            this.currentCompanyGeneralEligibilities[eligibilities.ORDER_SERVICE_FROM_APP] ||
            this.currentCompanyGeneralEligibilities[
              eligibilities.ORDER_SERVICE_FROM_APP_FROM_EVENT
            ],
          withEmailsForEventNotifications: this.currentCompanyGeneralEligibilities[
            eligibilities.NOTIFICATION_DISPATCHER
          ],
        }
      },
    },
  },
  methods: {
    async companyUpdateContactEmails() {
      if (!this.$v.contactEmailAddresses.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: companyUpdateContactEmails,
            variables: {
              input: {
                companyId: this.currentCompanyId,
                contactEmailAddresses: this.contactEmailAddresses,
              },
            },
            refetchQueries: ['companyContactEmails'],
          })

          this.$toast.success(this.$t('global.updateCompanyContactEmails.success'))
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.updateCompanyContactEmails.failure'))
        }
      }
    },
    async companyUpdateEmailsForEventNotifications() {
      if (!this.$v.emailsForEventNotifications.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: companyUpdateEmailsForEventNotifications,
            variables: {
              input: {
                companyId: this.currentCompanyId,
                emailAddresses: this.emailsForEventNotifications,
              },
            },
            refetchQueries: ['companyContactEmails'],
          })

          this.$toast.success(this.$t('global.updateCompanyContactEmails.success'))
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.updateCompanyContactEmails.failure'))
        }
      }
    },
    resetContactEmailForm() {
      this.contactEmailAddresses = this.company.contactEmailAddresses
    },
    resetEmailsForEventNotificationsForm() {
      this.emailsForEventNotifications = this.company.emailsForEventNotifications
    },
    onBeforeUnload() {
      if (this.hasUnsavedChanges) {
        // modern browsers will ignore this message and will show default
        return this.$t('global.unsavedChanges')
      }
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId', 'currentCompanyGeneralEligibilities']),
    ...mapGetters(['hasUnsavedChanges']),
    hasContactEmailFormChanged() {
      if (!this.company) {
        return false
      }
      return (
        this.contactEmailAddresses?.toString() !== this.company.contactEmailAddresses?.toString()
      )
    },
    hasEmailForEventNotificationsFormChanged() {
      if (!this.company) {
        return false
      }
      return (
        this.emailsForEventNotifications?.toString() !==
        this.company.emailsForEventNotifications?.toString()
      )
    },
    contactEmailsErrors() {
      const errors = []
      if (!this.$v.contactEmailAddresses.$dirty) {
        return errors
      }
      this.$v.contactEmailAddresses.$invalid &&
        errors.push(this.$t('global.companyDetail.invalidEmailAddresses'))
      return errors
    },
    emailsForEventNotificationsErrors() {
      const errors = []
      if (!this.$v.emailsForEventNotifications.$dirty) {
        return errors
      }
      this.$v.emailsForEventNotifications.$invalid &&
        errors.push(this.$t('global.companyDetail.invalidEmailAddresses'))
      return errors
    },
    showBlockButtons: function () {
      return this.$vuetify.breakpoint.name === 'xs'
    },
    eligibilities() {
      return eligibilities
    },
  },
  watch: {
    company() {
      this.companyUsersEmails = this.company.users.map((user) => user.email)
      this.resetContactEmailForm()
      this.resetEmailsForEventNotificationsForm()
    },
    hasContactEmailFormChanged() {
      this.$store.commit(
        'setHasUnsavedChanges',
        this.hasContactEmailFormChanged || this.hasEmailForEventNotificationsFormChanged,
      )
    },
    hasEmailForEventNotificationsFormChanged() {
      this.$store.commit(
        'setHasUnsavedChanges',
        this.hasContactEmailFormChanged || this.hasEmailForEventNotificationsFormChanged,
      )
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('global.unsavedChanges'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload
  },
  destroyed() {
    window.onbeforeunload = undefined
    this.$store.commit('setHasUnsavedChanges', false)
  },
}
</script>
<style lang="less" scoped>
h2 {
  font-size: 18px;
  margin-bottom: 15px;
}
</style>
