<template>
  <v-main style="overflow: auto">
    <div class="page-wrapper">
      <div class="content-wrapper pa-6">
        <h2 class="mb-6">{{ $t('users.addNewCompany') }}</h2>
        <p>{{ $t('users.addNewCompanyDescription') }}</p>
        <v-form>
          <div class="form-wrapper">
            <v-text-field
              v-model="form.email"
              :error-messages="emailErrors"
              :label="$t('global.email')"
              required
              @input="$v.form.email.$touch()"
              @blur="$v.form.email.$touch()"
              filled
              type="email"
            ></v-text-field>

            <template v-if="!manualPermissionsOpen">
              <p class="mt-2">{{ $t('users.permissionsByCompany') }}:</p>
              <v-select
                :items="serviceCompanies"
                item-text="name"
                item-value="id"
                v-model="permissionsByCompanyId"
                filled
                hide-details
              ></v-select>
              <a @click="manualPermissionsOpen = true" class="brand-link mt-4 d-block">{{
                $t('users.setPermissionsManually')
              }}</a>
            </template>
          </div>
          <template v-if="manualPermissionsOpen">
            <p class="mt-2">{{ $t('users.permissionsTitle') }}:</p>
            <UserPermissionsInput v-model="form.companyPermissions"></UserPermissionsInput>
            <a @click="manualPermissionsOpen = false" class="brand-link mt-4 d-block">{{
              $t('users.setPermissionsByCompany')
            }}</a>
          </template>
          <div class="form-wrapper mt-8">
            <v-select
              :items="localeOptions"
              v-model="form.lang"
              :label="$t('global.invitationLanguage')"
              filled
              hide-details
            ></v-select>
          </div>
        </v-form>
        <div class="mt-7 d-flex flex-wrap">
          <v-btn
            :block="showBlockButtons"
            color="primary"
            class="mr-5 mb-2"
            @click="inviteNewCompany()"
            >{{ $t('users.sendInvitation') }}</v-btn
          >
          <v-btn :block="showBlockButtons" @click="cancel()">{{ $t('global.cancel') }}</v-btn>
        </div>
      </div>
    </div>
  </v-main>
</template>

<script>
import { serviceCompanies } from '@/graphql/query/serviceCompanies'
import { mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import UserPermissionsInput from '@/components/User/UserPermissionsInput'
import { companyInviteServiceCompany } from '@/graphql/mutations/companyInviteServiceCompany'
import { childCompaniesPermissions } from '@/config/permissions'
import { allowedLanguages } from '@/translations'

export default {
  name: 'AddCompanyToCompanyPage',
  mixins: [validationMixin],
  components: { UserPermissionsInput },
  data() {
    return {
      permissionsByCompanyId: null,
      manualPermissionsOpen: false,
      form: {
        email: '',
        companyPermissions: Object.fromEntries(
          Object.values(childCompaniesPermissions).map((permissionName) => [
            permissionName,
            undefined,
          ]),
        ),
        lang: this.$i18n.locale,
      },
    }
  },
  validations: {
    form: {
      email: { required, email },
      companyPermissions: Object.fromEntries(
        Object.values(childCompaniesPermissions).map((permissionName) => [permissionName, {}]),
      ),
    },
  },
  apollo: {
    serviceCompanies: {
      query: serviceCompanies,
      variables() {
        return {
          companyId: this.currentCompanyId,
        }
      },
      skip() {
        return !this.currentCompanyId
      },
      update(data) {
        this.permissionsByCompanyId = data.company.companiesWithAccess[0]?.id
        return data.company.companiesWithAccess
      },
    },
  },
  methods: {
    assignPermissionsByCompany(userId) {
      const usersPermissions = this.serviceCompanies.find((company) => company.id === userId)
        ?.permissions
      if (usersPermissions) {
        for (const key in this.form.companyPermissions) {
          this.form.companyPermissions[key] = usersPermissions[key]
        }
      }
    },
    async inviteNewCompany() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: companyInviteServiceCompany,
            variables: {
              companyId: this.currentCompanyId,
              email: this.form.email,
              permissions: this.form.companyPermissions,
              lang: this.form.lang,
            },
          })

          this.$toast.success(this.$t('users.invitationSuccess'))
          this.$store.commit('setHasUnsavedChanges', false)
          this.$router.back()
        } catch (error) {
          console.error(error)
          const parsedError = error.message?.replace('GraphQL error:', '').trim()
          const errorMessages = {
            COMPANY_INVITE_SERVICE_COMPANY_NOT_ADMIN_EMAIL: this.$t(
              'users.companyInviteNotAdminEmail',
            ),
            COMPANY_INVITE_SERVICE_COMPANY_ALREADY_INVITED: this.$t(
              'users.companyInvitationAlreadyInvited',
            ),
            COMPANY_INVITE_SERVICE_COMPANY_ALREADY_IN_COMPANY: this.$t(
              'users.companyInvitationAlreadyAdded',
            ),
            COMPANY_INVITE_SERVICE_COMPANY_INVITE_SELF_DENIED: this.$t(
              'users.companyInvitationCanNotInviteOwnCompany',
            ),
          }
          this.$toast.error(errorMessages[parsedError] || this.$t('users.invitationFailure'))

          if (
            [
              'COMPANY_INVITE_SERVICE_COMPANY_ALREADY_INVITED',
              'COMPANY_INVITE_SERVICE_COMPANY_ALREADY_IN_COMPANY',
            ].includes(parsedError)
          ) {
            this.$store.commit('setHasUnsavedChanges', false)
            this.$router.back()
          }
        }
      }
    },
    onBeforeUnload() {
      if (this.hasUnsavedChanges) {
        // modern browsers will ignore this message and will show default
        return this.$t('global.unsavedChanges')
      }
    },
    cancel() {
      this.$store.commit('setHasUnsavedChanges', false)
      this.$router.back()
    },
  },
  watch: {
    permissionsByCompanyId(userId) {
      this.assignPermissionsByCompany(userId)
    },
    manualPermissionsOpen(isOpen) {
      if (!isOpen) {
        this.assignPermissionsByCompany(this.permissionsByCompanyId)
      }
    },
    'form.companyPermissions'() {
      this.$v.form.companyPermissions.$touch()
    },
    '$v.$anyDirty'(isDirty) {
      this.$store.commit('setHasUnsavedChanges', isDirty)
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId', 'currentCompanyPermissions']),
    ...mapGetters(['hasUnsavedChanges']),
    localeOptions() {
      return allowedLanguages.map((locale) => ({
        text: this.$t(`global.lang.${locale}`),
        value: locale,
      }))
    },
    emailErrors() {
      const errors = []
      if (!this.$v.form.email.$dirty) {
        return errors
      }
      !this.$v.form.email.required && errors.push(this.$t('global.formValidation.required'))
      !this.$v.form.email.email && errors.push(this.$t('global.formValidation.notEmail'))
      return errors
    },
    showBlockButtons: function () {
      return this.$vuetify.breakpoint.name === 'xs'
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('global.unsavedChanges'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload
  },
  destroyed() {
    window.onbeforeunload = undefined
    this.$store.commit('setHasUnsavedChanges', false)
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

main {
  flex: 1 1 auto;
}

.page-wrapper {
  min-height: 100%;
  max-width: 768px;
  border-right: 1px solid #eeeeee;

  .content-wrapper {
    border-bottom: 1px solid #eeeeee;

    .form-wrapper {
      max-width: 330px;
    }

    .brand-link {
      color: @color-primary;
    }
  }
}
</style>
