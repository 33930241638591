import gql from 'graphql-tag'

export const companyInviteServiceCompany = gql`
  mutation companyInviteServiceCompany(
    $companyId: ID!
    $email: EmailAddress!
    $permissions: ChildCompanyPermissionsInput!
    $lang: String
  ) {
    companyInviteServiceCompany(
      input: { companyId: $companyId, email: $email, permissions: $permissions, lang: $lang }
    ) {
      success
    }
  }
`
