<template>
  <div class="login-page">
    <div class="login-page--wrapper">
      <div class="login-page--content">
        <div class="logo-wrapper">
          <ProductLogo />
        </div>
        <router-view></router-view>

        <div class="login-page--footer">
          <div class="login-page--footer--inner">
            <span>Powered by</span>
            <div class="d-flex align-center ml-3" style="width: 85px">
              <BrandLogo style="flex: 1" />
            </div>
          </div>
          <div class="login-page--footer--inner">
            <div class="language">
              <label for="_lang" class="language--text">{{ $t('global.language') }}:</label>
              <div class="language--select--wrapper">
                <select
                  :value="$store.state.locale"
                  @input="$store.commit('changeLocale', $event.target.value)"
                  name="_lang"
                  id="_lang"
                  class="language--select"
                >
                  <option value="cs">{{ $t('global.lang.cs') }}</option>
                  <option value="en">{{ $t('global.lang.en') }}</option>
                  <option value="de">{{ $t('global.lang.de') }}</option>
                  <option value="pl">{{ $t('global.lang.pl') }}</option>
                </select>
                <svg class="language--select--icon" width="9" height="5">
                  <use xlink:href="#icon-chevron-down"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="login-page--bg">
        <img class="login-page--bg--img" src="@/assets/images/login-img.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import ProductLogo from '@/assets/images/logo-ixsolve.svg'
import BrandLogo from '@/assets/images/logo-eledio.svg'

Auth.configure()

export default {
  name: 'LoginPage',
  components: { ProductLogo, BrandLogo },
}
</script>

<style lang="less">
.login-page {
  padding: 49px;
  display: flex;
  min-height: 100vh;
  background: #f6f8fc;
  &--wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1161px;
    margin: auto;
  }
  &--content {
    flex: 0 0 420px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    &--inner {
      margin: auto 0;
      padding: 40px 0;
    }
  }
  &--bg {
    flex: 0 1 auto;
    display: flex;
    align-items: center;

    &--img {
    }
  }
  &--headline {
    color: #000000;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 25px;
  }
  &--switch {
    border-bottom: solid 1px rgba(196, 196, 196, 0.35);
    display: flex;
    align-items: center;
    margin: 30px 0 32px;

    &--button {
      background: none;
      border: none;
      color: #000000;
      font-size: 1rem;
      font-weight: 500;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      outline: none;

      &:before {
        content: '';
        height: 3px;
        position: absolute;
        bottom: -2px;
        left: 15px;
        right: 15px;
        border-radius: 2.063rem;
        background: #1f97ff;
        opacity: 0;
        transition: all 300ms ease;
      }

      &:first-child {
        margin-left: -15px;
      }

      &.active {
        &:before {
          opacity: 1;
        }
      }
    }
  }
  &--form {
  }
  &--error {
    padding: 4px 0;
    color: #f44336;
    font-size: 0.875rem;
    font-weight: 400;
    min-height: 24px;
  }
  &--footer {
    color: rgba(30, 30, 31, 0.4);
    font-size: 0.875rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    margin: 20px -20px -10px;
    &--inner {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
    &--img {
      margin: 0 0 0 13px;
    }
  }
  @media (max-width: 1023px) {
    padding: 20px;
  }
  @media (max-width: 767px) {
    padding: 0;
    display: block;
    &--wrapper {
      flex-direction: column-reverse;
    }

    &--content {
      flex: 1 1 auto;
      margin: auto;
      padding: 40px 10px 20px;
      max-width: 330px;

      &--inner {
        padding: 0;
        margin: 0;
      }
    }

    &--bg {
      max-height: 328px;
      height: 40vh;
      overflow: hidden;
      &--img {
        width: 100%;
      }
    }
    &--logo {
      display: none;
    }
  }
}
.language {
  display: flex;
  align-items: center;
  &--text {
    opacity: 0.7;
    color: #0c0233;
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0 7px 0 0;
  }
  &--select {
    -webkit-appearance: none;
    color: #1f97ff;
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration: underline;
    padding: 5px 15px 5px 0;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
    &--wrapper {
      position: relative;
    }
    &--icon {
      color: #1f97ff;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
    }
  }
}

.logo-wrapper {
  width: 180px;
}
</style>
