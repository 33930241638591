import gql from 'graphql-tag'

export const companyWeeklyTimeSchedulesGetOrCreate = gql`
  mutation companyWeeklyTimeSchedulesGetOrCreate($companyId: ID!, $deviceType: DeviceTypeEnum!) {
    companyWeeklyTimeSchedulesGetOrCreate(
      input: { companyId: $companyId, deviceType: $deviceType }
    ) {
      weeklyTimeSchedules {
        id
        name
      }
    }
  }
`
