<template>
  <div>
    <button
      :style="{
        height: ((buttonEnd - buttonBegin) / 86400) * 2064 - 4 + 'px',
        top: (buttonBegin / 86400) * 2064 + 43 + 2 + 'px', // (interval.begin / numberOfSecondsInDay) * (48 * 43 = heightOfTimeline) + heightOfHeader + padding
      }"
      class="grid-add-btn"
      :class="{ visible: isResizing }"
      @mousedown="handleMouseDown($event)"
    >
      <v-icon>mdi-plus</v-icon>
    </button>
    <v-menu v-model="menu.isOpen" :position-x="menu.x" :position-y="menu.y" absolute offset-y>
      <v-list>
        <v-list-item
          v-for="state in states"
          :key="state.name"
          @click="createInterval(resizedBegin, resizedEnd, state.name)"
          link
        >
          <div class="square" :style="{ backgroundColor: state.color }"></div>
          <span>{{ state.label }}</span>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import mouseMoveScrollNearEdge from '@/mixins/calendar/mouseMoveScrollNearEdge'

export default {
  name: 'TimeScheduleCalendarAddButton',
  mixins: [mouseMoveScrollNearEdge],
  props: {
    timeSchedule: Array,
    granularity: Number,
    states: Array,
    begin: Number,
    end: Number,
    getWrapperRef: Function,
  },
  data() {
    return {
      gettingAnimationFrame: false,
      resizedBegin: null,
      resizedEnd: null,
      isResizing: false,
      menu: {
        isOpen: false,
        x: null,
        y: null,
      },
    }
  },
  methods: {
    createInterval(begin, end, state) {
      this.$emit('newInterval', { begin, end, state })
    },
    handleMouseDown(e) {
      e.preventDefault()
      // clear selection
      if (window.getSelection) {
        window.getSelection().removeAllRanges()
      } else if (document.selection) {
        document.selection.empty()
      }
      this.$emit('dragging', true)
      this.isResizing = true

      this.resizedBegin = this.begin
      this.resizedEnd = this.end
      const handleMouseMove = (moveEvent) => {
        if (!this.gettingAnimationFrame) {
          requestAnimationFrame(() => {
            this.gettingAnimationFrame = false
            const boundingRect = this.getWrapperRef().getBoundingClientRect()
            const realTop = moveEvent.clientY - boundingRect.y + this.getWrapperRef().scrollTop // real position of the mouse in px from the top of the scrollable div
            // mouse position corresponds to some time in seconds on our timeline
            // same equation as is used in template to calculate top from seconds but reversed
            // rounded to granularity steps
            const mousePositionInSeconds =
              Math.round((((realTop - 45) / 2064) * 86400) / this.granularity) * this.granularity
            // here we only normalize seconds to not overlap other intervals or go below 0 or above 86400
            if (mousePositionInSeconds < this.begin) {
              this.resizedBegin =
                mousePositionInSeconds < this.lowerBoundary
                  ? this.lowerBoundary
                  : mousePositionInSeconds
              this.resizedEnd = this.end
            } else if (mousePositionInSeconds > this.end) {
              this.resizedBegin = this.begin
              this.resizedEnd =
                mousePositionInSeconds > this.upperBoundary
                  ? this.upperBoundary
                  : mousePositionInSeconds
            } else {
              this.resizedBegin = this.begin
              this.resizedEnd = this.end
            }

            this.mouseMoveScrollNearEdge(moveEvent, boundingRect, this.getWrapperRef)
          })
        }
        this.gettingAnimationFrame = true
      }
      const handleMouseUp = (upEvent) => {
        this.menu.isOpen = true
        this.menu.x = upEvent.clientX
        this.menu.y = upEvent.clientY
        window.removeEventListener('mouseup', handleMouseUp)
        window.removeEventListener('mousemove', handleMouseMove)
        if (this.scrollInterval) {
          clearInterval(this.scrollInterval)
          this.scrollInterval = null
        }
      }
      window.addEventListener('mouseup', handleMouseUp)
      window.addEventListener('mousemove', handleMouseMove)
    },
  },
  computed: {
    lowerBoundary() {
      const previousInterval = this.timeSchedule.findLast((entry) => entry.end <= this.begin)
      return previousInterval?.end || 0
    },
    upperBoundary() {
      const nextInterval = this.timeSchedule.find((entry) => entry.begin >= this.end)
      return nextInterval?.begin || 86400
    },
    buttonBegin() {
      return this.resizedBegin ?? this.begin
    },
    buttonEnd() {
      return this.resizedEnd ?? this.end
    },
  },
  watch: {
    'menu.isOpen'() {
      if (!this.menu.isOpen) {
        this.$emit('dragging', false)
        this.isResizing = false
        this.resizedBegin = null
        this.resizedEnd = null
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/components/TimeSchedules/timeSchedule.less';
.grid-add-btn {
  position: absolute;
  left: 2px;
  width: calc(100% - 4px);
  background: #f1f1f1;
  border: 1.5px dashed #a7a7a7;
  border-radius: 4px;
  opacity: 0;

  &:hover,
  &.visible {
    opacity: 1;
  }
}

.square {
  margin-left: 5px;
  margin-right: 14px;
}
</style>
