import gql from 'graphql-tag'

export const eventDetectionPointsAdmin = gql`
  query eventDetectionPointsAdmin($lang: String) {
    eventDetectionPointsAdmin {
      id
      name
      description(lang: $lang)
    }
  }
`
