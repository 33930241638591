export default {
  data() {
    return {
      scrollBy: 0,
      scrollInterval: null,
    }
  },
  methods: {
    mouseMoveScrollNearEdge(moveEvent, boundingRect, getWrapper) {
      if (
        moveEvent.clientY > boundingRect.bottom - 50 ||
        moveEvent.clientY < boundingRect.top + 100
      ) {
        this.scrollBy =
          moveEvent.clientY > boundingRect.bottom - 50
            ? moveEvent.clientY - (boundingRect.bottom - 50)
            : moveEvent.clientY - (boundingRect.top + 100)
        if (!this.scrollInterval) {
          this.scrollInterval = setInterval(() => {
            getWrapper().scrollBy({ top: this.scrollBy, behavior: 'smooth' })
          }, 200)
        }
      } else if (this.scrollInterval) {
        clearInterval(this.scrollInterval)
        this.scrollInterval = null
      }
    },
  },
}
