import gql from 'graphql-tag'

export const users = gql`
  query users($search: NonEmptyString, $first: Int, $after: String, $last: Int, $before: String) {
    users(search: $search, first: $first, after: $after, last: $last, before: $before) {
      edges {
        node {
          id
          email
          phoneNumber
          firstName
          lastName
          enabled
          deactivated
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`
