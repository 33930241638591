<template>
  <div class="pa-6">
    <h1 class="mb-5">
      {{ $t('global.adminConsole') }}: {{ $t('global.adminConsoleMenu.thingTypes') }}
    </h1>
    <v-data-table
      :headers="headers"
      :items="thingTypes"
      :options.sync="options"
      :server-items-length="thingTypes ? thingTypes.totalCount : 0"
      :loading="$apollo.loading"
      :disable-sort="true"
      :no-data-text="$t('users.noMatchingRecordsFound')"
      :footer-props="{
        'items-per-page-text': $t('global.dataTable.itemsPerPageText'),
        'items-per-page-all-text': $t('global.dataTable.allItemsText'),
      }"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-text-field v-model="search" :label="$t('global.doFilter')" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.editCol="{ item }">
        <v-btn icon @click="openEditModal(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-if="thingTypeToEdit" v-model="editModalOpen" width="350px">
      <v-card>
        <v-card-title class="headline">
          {{ $t('global.updateThingType') }}:<br />{{ thingTypeToEdit.name }}
        </v-card-title>
        <v-card-text>
          <v-form class="mt-2">
            <v-select
              :items="thingTypeFamilies"
              :label="$t('global.thingTypeFamily')"
              item-text="name"
              item-value="id"
              v-model="thingTypeToEdit.thingTypeFamilyId"
              filled
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="editModalOpen = false">
            {{ $t('global.cancel') }}
          </v-btn>
          <v-btn color="primary" @click="updateThingType()">
            {{ $t('global.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { thingTypes as thingTypesQuery } from '@/graphql/query/thingTypes'
import { thingTypeFamilies as thingTypeFamiliesQuery } from '@/graphql/query/thingTypeFamilies'
import { thingTypeUpdateAdmin } from '@/graphql/mutations/thingTypeUpdateAdmin'

export default {
  name: 'AdminThingTypes',
  data() {
    return {
      options: {},
      search: null,
      headers: [
        { text: this.$t('global.name'), value: 'name' },
        { text: '', value: 'editCol', align: 'right' },
      ],
      thingTypeToEdit: null,
      confirmDeleteModalOpen: false,
      editModalOpen: false,
    }
  },
  apollo: {
    thingTypes: {
      query: thingTypesQuery,
    },
    thingTypeFamilies: {
      query: thingTypeFamiliesQuery,
      variables: {
        adminView: true,
      },
      result({ data }) {
        this.thingTypeFamilies = data.thingTypeFamilies
      },
    },
  },
  methods: {
    async updateThingType() {
      if (this.thingTypeToEdit) {
        try {
          await this.$apollo.mutate({
            mutation: thingTypeUpdateAdmin,
            variables: {
              name: this.thingTypeToEdit.name,
              thingTypeFamilyId: this.thingTypeToEdit.thingTypeFamilyId,
            },
            refetchQueries: ['thingTypes'],
          })
          this.$toast.success(this.$t('global.thingTypeRelationUpdateSuccess'))
          this.editModalOpen = false
        } catch (error) {
          console.error(error)
          const parsedError = error.message?.replace('GraphQL error:', '').trim()
          this.$toast.error(parsedError || this.$t('global.thingTypeRelationUpdateFail'))
        }
      }
    },
    openEditModal(thingType) {
      this.thingTypeToEdit = { ...thingType }
      this.editModalOpen = true
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

h1 {
  font-size: 24px;
}
</style>
