import gql from 'graphql-tag'

export const coreCauseReorderCorrectiveActions = gql`
  mutation coreCauseReorderCorrectiveActions(
    $coreCauseId: ID!
    $orderedCorrectiveActionIds: [ID!]!
  ) {
    coreCauseReorderCorrectiveActions(
      input: { coreCauseId: $coreCauseId, orderedCorrectiveActionIds: $orderedCorrectiveActionIds }
    ) {
      success
    }
  }
`
