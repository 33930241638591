<template>
  <div
    class="badge-wrapper px-3"
    :class="{ clickable }"
    :style="{
      color: errorsConfig[severity].color,
      backgroundColor: errorsConfig[severity].bgColor,
    }"
    @click="serviceHintsModalOpen = clickable"
  >
    <v-icon small :color="errorsConfig[severity].iconColor" class="mr-2">{{
      errorsConfig[severity].icon
    }}</v-icon>
    <span>{{ text }}</span>
    <ServiceHintsModal
      v-if="clickable"
      v-model="serviceHintsModalOpen"
      :deviceId="deviceId"
      :permissionChecker="permissionChecker"
    />
  </div>
</template>
<script>
import ServiceHintsModal from '@/components/ServiceHintsModal'
import { errorsConfig } from '@/config/errorsConfig'

export default {
  name: 'ReportBadge',
  components: { ServiceHintsModal },
  props: {
    text: String,
    clickable: Boolean,
    deviceId: String,
    severity: String,
    permissionChecker: Function,
  },
  data() {
    return {
      serviceHintsModalOpen: false,
    }
  },
  computed: {
    errorsConfig() {
      return errorsConfig
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.badge-wrapper {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 1px;
  white-space: wrap;
  justify-content: center;
}

.badge-content-wrapper {
  display: inline-block;
}

.clickable {
  cursor: pointer;
}
</style>
