export const errorsConfig = {
  CRITICAL: {
    color: 'white',
    bgColor: '#d20000',
    icon: 'mdi-alert',
    iconColor: 'white',
  },
  ERROR: {
    color: '#C83737',
    bgColor: '#ffeeee',
    icon: 'mdi-alert',
    iconColor: '#fc5252',
  },
  WARNING: {
    color: '#7C7227',
    bgColor: '#faf8ee',
    icon: 'mdi-wrench',
    iconColor: '#fc7610',
  },
  INFO: {
    color: '#0D7AA5',
    bgColor: '#eff9fe',
    icon: 'mdi-wrench',
    iconColor: '#5ec7f2',
  },
}
