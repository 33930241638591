<template>
  <div>
    <v-select
      :items="detectionPointOptions"
      :label="$t('global.detectionPoint')"
      v-model="detectionPoint"
      filled
      :style="{ maxWidth: '350px' }"
    ></v-select>
    <div id="graph"></div>
    <v-dialog v-model="editModalOpen">
      <div class="edit-modal-wrapper">
        <component
          v-if="editModalData"
          :is="chooseDetailPage(editModalData.type)"
          :id="editModalData.id"
          :inModal="true"
          @onDelete="editModalOpen = false"
        ></component>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { serviceHintsAdminOverview } from '@/graphql/query/serviceHintsAdminOverview'
import { errorsConfig } from '@/config/errorsConfig'
import AdminEventCodeDetail from '@/views/Admin/ServiceHints/AdminEventCodeDetail'
import AdminCoreCauseDetail from '@/views/Admin/ServiceHints/AdminCoreCauseDetail'
import AdminCorrectiveActionDetail from '@/views/Admin/ServiceHints/AdminCorrectiveActionDetail'
import AdminDetectionPointDetail from '@/views/Admin/ServiceHints/AdminDetectionPointDetail'

export default {
  name: 'AdminServiceHintsOverview',
  components: {
    AdminEventCodeDetail,
    AdminCoreCauseDetail,
    AdminCorrectiveActionDetail,
    AdminDetectionPointDetail,
  },
  data() {
    return {
      detectionPoint: null,
      editModalOpen: null,
      editModalData: null,
      duration: 500,
      svg: null,
      tree: null,
      root: null,
      depth: null,
      d3: null,
    }
  },
  apollo: {
    serviceHintsData: {
      query: serviceHintsAdminOverview,
      variables() {
        return {
          lang: this.$i18n.locale,
        }
      },
      update(data) {
        if (!data.eventDetectionPointsAdmin.find((dp) => dp.id === this.detectionPoint)) {
          this.detectionPoint = data.eventDetectionPointsAdmin[0].id
        }
        return data.eventDetectionPointsAdmin
      },
    },
  },
  computed: {
    detectionPointOptions() {
      return this.serviceHintsData?.map((dp) => ({
        value: dp.id,
        text: dp.description || `[${dp.name}]`,
      }))
    },
  },
  watch: {
    detectionPoint() {
      this.createGraph(this.serviceHintsData.find((dp) => dp.id === this.detectionPoint))
    },
    serviceHintsData() {
      if (!this.svg) {
        this.createGraph(this.serviceHintsData.find((dp) => dp.id === this.detectionPoint))
      } else {
        this.update(this.serviceHintsData.find((dp) => dp.id === this.detectionPoint))
      }
    },
    editModalOpen() {
      if (!this.editModalOpen) {
        this.editModalData = null
      }
    },
  },
  methods: {
    chooseDetailPage(type) {
      const detailPageMap = {
        EventCodeAdmin: AdminEventCodeDetail,
        EventCoreCauseAdmin: AdminCoreCauseDetail,
        EventCorrectiveActionAdmin: AdminCorrectiveActionDetail,
        EventDetectionPointAdmin: AdminDetectionPointDetail,
      }
      return detailPageMap[type]
    },
    labelClick(event, d) {
      this.editModalData = {
        id: d.data.id,
        type: d.data.__typename,
      }
      this.editModalOpen = true
    },
    click(event, d) {
      // Toggle children on click.
      if (d.children) {
        d._children = d.children
        d.children = null
      } else {
        d.children = d._children
        d._children = null
      }
      if (d.children?.length || d._children?.length) {
        this.update()
      }
    },
    collapse(d) {
      // Collapse the node and all it's children
      if (d.children) {
        d._children = d.children
        d._children.forEach(this.collapse)
        d.children = null
      }
    },
    mirrorOldCollapseState(newRoot, oldRoot) {
      let childrenKey = 'children'
      if (oldRoot._children) {
        childrenKey = '_children'
        newRoot._children = newRoot.children
        newRoot.children = null
      }
      newRoot[childrenKey]?.forEach((child) => {
        const oldChild = oldRoot[childrenKey].find((oldChild) => oldChild.id === child.data.id)
        if (oldChild) {
          this.mirrorOldCollapseState(child, oldChild)
        } else {
          this.collapse(child)
        }
      })
    },
    async createGraph(treeData) {
      if (!this.d3) {
        this.d3 = await import('d3')
      }

      // remove previous graph
      this.d3.select('#graph > svg').remove()

      // Set the dimensions and margins of the diagram
      const margin = { top: 0, right: 40, bottom: 10, left: 150 }

      const clientWidth = this.d3.select('#graph').node().offsetWidth
      const minDepth = 200
      this.depth = Math.max(minDepth, (clientWidth - margin.left - margin.right) / 3) // distance of the nodes horizontally

      const width = 3 * this.depth
      const height =
        Math.max(treeData.children.length * 75, this.d3.select('#graph').node().offsetHeight) -
        margin.top -
        margin.bottom

      // append the svg object to the body of the page
      // appends a 'group' element to 'svg'
      // moves the 'group' element to the top left margin
      this.svg = this.d3
        .select('#graph')
        .append('svg')
        .attr('width', width + margin.right + margin.left - 25) // 25 just to hide scrollbar
        .attr('height', height + margin.top + margin.bottom - 25) // 25 just to hide scrollbar
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      // declares a tree layout and assigns the size
      this.tree = this.d3.tree().size([height, width])

      // Assigns parent, children, height, depth
      this.root = this.d3.hierarchy(treeData, function (d) {
        return d.children
      })
      this.root.x0 = height / 2
      this.root.y0 = 0

      // Collapse after the second level
      this.root.children.forEach(this.collapse)

      this.update()
    },
    update(newData) {
      if (newData) {
        const newRoot = this.d3.hierarchy(newData, function (d) {
          return d.children
        })
        this.mirrorOldCollapseState(newRoot, this.root)
        this.root = newRoot
      }

      const padding = 5

      // Assigns the x and y position for the nodes
      const treeData = this.tree(this.root)

      // Compute the new tree layout.
      const nodes = treeData.descendants()
      const links = treeData.descendants().slice(1)

      // Normalize for fixed-depth.
      nodes.forEach((d) => {
        d.y = d.depth * this.depth
      })

      // ****************** Nodes section ***************************

      // Update the nodes...
      const node = this.svg.selectAll('g.node').data(nodes, (d) => {
        return d.id || (d.id = d.data.id)
      })

      // Enter any new modes at the parent's previous position.
      const nodeEnter = node
        .enter()
        .append('g')
        .attr('class', 'node')
        .attr('transform', (d) => {
          if (d.parent) {
            return 'translate(' + d.parent.y0 + ',' + d.parent.x0 + ')'
          }
          return 'translate(' + this.root.y0 + ',' + this.root.x0 + ')'
        })

      // Add Circle for the nodes
      nodeEnter
        .append('circle')
        .attr('class', 'node')
        .on('click', this.click)
        .attr('r', 1e-6)
        .style('fill', function (d) {
          if (!d._children) {
            return 'white'
          }
          if (d.data.__typename === 'EventCodeAdmin') {
            return d.data.disabled ? '#FCA6A6' : 'lightgreen'
          }
          return 'lightsteelblue'
        })
        .style('stroke', function (d) {
          if (d.data.__typename === 'EventCodeAdmin') {
            return d.data.disabled ? '#fc5252' : '#22b77c'
          }
          return 'steelblue'
        })

      // Add labels for the nodes
      nodeEnter
        .filter(function (d) {
          return d.data.__typename !== 'EventCodeAdmin'
        })
        .append('text')
        .attr('class', 'simpleLabel')
        .on('click', this.labelClick)
        .attr('dy', '.35em')
        .attr('x', -20)
        .attr('text-anchor', 'end')
        .attr('cursor', 'pointer')

      const eventCodeText = nodeEnter
        .filter(function (d) {
          return d.data.__typename === 'EventCodeAdmin'
        })
        .append('text')
        .attr('class', 'eventCodeText')
        .on('click', this.labelClick)
        .attr('dy', '.35em')
        .attr('y', '-0.5em')
        .attr('x', -20)
        .attr('text-anchor', 'end')
        .text(null)
        .attr('cursor', 'pointer')

      if (eventCodeText._groups[0].length) {
        eventCodeText
          .append('tspan')
          .attr('class', 'eventCodeDescription')
          .attr('x', eventCodeText.attr('x'))
          .attr('y', eventCodeText.attr('y'))
          .attr('dy', 0.25 + 'em')
          .attr('font-size', '14px')

        eventCodeText
          .append('tspan')
          .attr('class', 'eventCodeName')
          .attr('x', eventCodeText.attr('x'))
          .attr('y', eventCodeText.attr('y'))
          .attr('dy', 1.35 + 'em')
          .attr('font-size', '12px')
      }

      // create labels background
      nodeEnter
        .insert('rect', 'text')
        .attr('class', (d) => (d.data.__typename === 'EventCodeAdmin' ? 'eventCodeBg' : 'bg'))
        .attr('rx', 5)

      // UPDATE
      const nodeUpdate = nodeEnter.merge(node)

      // Transition to the proper position for the node
      nodeUpdate
        .transition()
        .duration(this.duration)
        .attr('transform', function (d) {
          return 'translate(' + d.y + ',' + d.x + ')'
        })

      // Update the node attributes and style
      nodeUpdate
        .select('circle.node')
        .attr('r', 10)
        .style('fill', function (d) {
          if (!d._children) {
            return 'white'
          }
          if (d.data.__typename === 'EventCodeAdmin') {
            return d.data.disabled ? '#FCA6A6' : 'lightgreen'
          }
          return 'lightsteelblue'
        })
        .style('stroke', function (d) {
          if (d.data.__typename === 'EventCodeAdmin') {
            return d.data.disabled ? '#fc5252' : '#22b77c'
          }
          return 'steelblue'
        })
        .attr('cursor', 'pointer')

      // update label texts and colors
      nodeUpdate
        .select('text.simpleLabel')
        .text(function (d) {
          return d.data.description || `[${d.data.name}]`
        })
        .call(this.wrap, this.depth - 50)

      nodeUpdate
        .select('tspan.eventCodeDescription')
        .style('fill', function (d) {
          return errorsConfig[d.data.severity].color
        })
        .text(function (d) {
          return d.data.description || `[${d.data.name}]`
        })

      nodeUpdate
        .select('tspan.eventCodeName')
        .style('fill', function (d) {
          return errorsConfig[d.data.severity].color
        })
        .text(function (d) {
          return d.data.name
        })

      nodeUpdate.select('text').call(getBB)

      nodeUpdate
        .select('rect.bg')
        .attr('x', function (d) {
          return -d.bbox.width - 20 - padding
        })
        .attr('y', -padding - 9)
        .attr('width', function (d) {
          return d.bbox.width + padding * 2
        })
        .attr('height', function (d) {
          return d.bbox.height + padding * 2
        })
        .style('fill', 'transparent')
        .style('stroke', 'black')
        .style('stroke-width', '1px')

      nodeUpdate
        .select('rect.eventCodeBg')
        .attr('x', function (d) {
          return -d.bbox.width - 20 - padding
        })
        .attr('y', -padding - 16)
        .attr('width', function (d) {
          return d.bbox.width + padding * 2
        })
        .attr('height', function (d) {
          return d.bbox.height + padding * 2
        })
        .style('fill', function (d) {
          return errorsConfig[d.data.severity].bgColor
        })

      // Remove any exiting nodes
      const nodeExit = node
        .exit()
        .transition()
        .duration(this.duration)
        .attr('transform', function (d) {
          return 'translate(' + d.parent.y + ',' + d.parent.x + ')'
        })
        .remove()

      // On exit reduce the node circles size to 0
      nodeExit.select('circle').attr('r', 1e-6)

      // On exit reduce the opacity of text labels
      nodeExit.select('text').style('fill-opacity', 1e-6)

      // ****************** links section ***************************

      // Update the links...
      const link = this.svg.selectAll('path.link').data(links, function (d) {
        return d.id
      })

      // Enter any new links at the parent's previous position.
      const linkEnter = link
        .enter()
        .insert('path', 'g')
        .attr('class', 'link')
        .attr('d', function (d) {
          const o = { x: d.parent.x0, y: d.parent.y0 }
          return diagonal(o, o)
        })

      // UPDATE
      const linkUpdate = linkEnter.merge(link)

      // Transition back to the parent element position
      linkUpdate
        .transition()
        .duration(this.duration)
        .attr('d', function (d) {
          return diagonal(d, d.parent)
        })

      // Remove any exiting links
      link
        .exit()
        .transition()
        .duration(this.duration)
        .attr('d', function (d) {
          const o = { x: d.parent.x, y: d.parent.y }
          return diagonal(o, o)
        })
        .remove()

      // Store the old positions for transition.
      nodes.forEach(function (d) {
        d.x0 = d.x
        d.y0 = d.y
      })

      // Creates a curved (diagonal) path from parent to the child nodes
      function diagonal(s, d) {
        const path = `M ${s.y} ${s.x}
            C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y} ${d.x}`

        return path
      }

      function getBB(selection) {
        selection.each(function (d) {
          d.bbox = this.getBBox()
        })
      }
    },
    wrap(text, width) {
      const that = this
      text.each(function () {
        var text = that.d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          // lineNumber = 0,
          lineHeight = 1.1, // ems
          x = text.attr('x'),
          y = text.attr('y'),
          dy = 0.25, //parseFloat(text.attr("dy")),
          tspan = text
            .text(null)
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', dy + 'em')
            .attr('font-size', '14px')

        // eslint-disable-next-line no-cond-assign
        while ((word = words.pop())) {
          line.push(word)
          tspan.text(line.join(' '))
          if (tspan.node().getComputedTextLength() > width) {
            line.pop()
            tspan.text(line.join(' '))
            line = [word]
            tspan = text
              .append('tspan')
              .attr('x', x)
              .attr('y', y)
              .attr('dy', lineHeight + dy + 'em')
              .attr('font-size', '14px')
              .text(word)
          }
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
#graph {
  overflow: auto;
  height: calc(100vh - 345px);
}

/deep/.node circle {
  stroke: steelblue;
  stroke-width: 3px;
}

/deep/.node text {
  font: 12px sans-serif;
}

/deep/.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}

.edit-modal-wrapper {
  background-color: white;
  padding: 15px;
  min-height: calc(100vh - 72px);
}
</style>
