<template>
  <div class="wrapper">
    <div class="actions-wrapper">
      <v-btn class="mr-1" icon @click="$emit('openViewMode', viewModeEnum.VIEW)"
        ><v-icon>mdi-eye-outline</v-icon></v-btn
      >
      <v-btn icon @click="$emit('openViewMode', viewModeEnum.EDIT)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </div>
    <p class="name">
      {{ $t('global.weeklyTimeSchedule') }}
    </p>
    <p class="value" ref="val" :title="timeSchedule.name">{{ timeSchedule.name }}</p>
  </div>
</template>

<script>
import { viewModeEnum } from '@/components/TimeSchedules/WeeklyTimeSchedulesModal.vue'

export default {
  name: 'WeeklyTimeScheduleTile',
  components: {},
  props: {
    timeSchedule: Object,
  },
  computed: {
    viewModeEnum() {
      return viewModeEnum
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.wrapper {
  position: relative;
  background-color: fade(@color-brand-highlight, 50%);
  border-radius: 3px;
  padding: 13px 15px 13px 18px;

  .actions-wrapper {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;

    i,
    span {
      color: @color-brand !important;
    }
  }

  p {
    margin-bottom: 0;
  }

  .name {
    font-size: 12px;
    color: @color-brand-2;
    opacity: 0.5;
    margin-bottom: 13px;
  }

  .value {
    font-size: 18px;
    font-weight: bold;
    color: @color-brand-2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
