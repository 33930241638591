import gql from 'graphql-tag'

export const operationCounters = gql`
  query operationCounters($deviceId: ID!, $lang: AcceptedLanguageEnum) {
    device(deviceId: $deviceId) {
      id
      operationCounters(lang: $lang) {
        equipmentId
        equipmentType
        displayableEquipmentType: equipmentType(lang: $lang)
        timeCounter
        startCounter
        cycleStart
        note
        dataKey
      }
    }
  }
`
