<template>
  <div class="schedule-templates-wrapper mb-12">
    <div class="mb-5 d-flex align-center flex-wrap">
      <h3 class="mr-5">{{ $t('global.weeklyTimeSchedules') }}</h3>
      <v-btn
        color="primary"
        height="27"
        depressed
        @click="openWeeklyScheduleModal(null, viewModeEnum.NEW)"
        >{{ $t('global.add') }}</v-btn
      >
    </div>
    <div
      v-if="companyWithSchedules"
      class="templates-wrapper"
      :style="{ gridTemplateColumns: `repeat(auto-fill, minmax(${maxTextWidth}px, 1fr))` }"
    >
      <WeeklyTimeScheduleTile
        v-for="timeSchedule of companyWithSchedules.weeklyTimeSchedules"
        :key="timeSchedule.id"
        :timeSchedule="timeSchedule"
        @openViewMode="openWeeklyScheduleModal(timeSchedule.id, $event)"
      />
    </div>
    <v-dialog
      v-model="showErrorModal"
      class="error-dialog custom-dialog-overlay"
      content-class="custom-dialog-overlay"
      width="500"
      z-index="500"
    >
      <v-card>
        <v-card-title class="headline">Error</v-card-title>
        <v-card-text>{{
          $t('global.errorInTimeScheduleConfigUpdate', {
            deviceList: deviceNames,
          })
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showErrorModal = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <WeeklyTimeSchedulesModal
      v-if="companyWithSchedules"
      v-model="weeklySchedulesModalOpen"
      :weeklyTimeSchedules="companyWithSchedules.weeklyTimeSchedules"
      :dailyTimeSchedules="companyWithSchedules.dailyTimeSchedules"
      :timeScheduleConfig="timeScheduleConfig"
      :createMethod="createWeeklyTimeSchedule"
      :updateMethod="updateWeeklyTimeSchedule"
      :deleteMethod="deleteWeeklyTimeSchedule"
      :dailyCreateMethod="createDailyTimeSchedule"
      :dailyUpdateMethod="updateDailyTimeSchedule"
      :editable="true"
      :canActivate="false"
      :preselectScheduleId="preselectWeeklyScheduleId"
      :openInViewMode="openWeeklyScheduleModalInViewMode"
      templateMode
    />
  </div>
</template>
<script>
import { companyWeeklyAndDailyTimeSchedules } from '@/graphql/query/companyTimeSchedules'
import { mapGetters } from 'vuex'
import WeeklyTimeScheduleTile from '@/components/TimeSchedules/WeeklyTimeScheduleTile'
import WeeklyTimeSchedulesModal from '@/components/TimeSchedules/WeeklyTimeSchedulesModal'
import { getTextWidth } from '@/utils/getTextWidth'
import {
  weeklyTimeScheduleCreate,
  weeklyTimeScheduleUpdate,
  weeklyTimeScheduleDelete,
} from '@/graphql/mutations/weeklyTimeSchedule'
import { viewModeEnum } from '@/components/TimeSchedules/WeeklyTimeSchedulesModal.vue'
import produce from 'immer'
import {
  dailyTimeScheduleCreate,
  dailyTimeScheduleUpdate,
} from '@/graphql/mutations/dailyTimeSchedule'

export default {
  name: 'WeeklyTimeScheduleTemplates',
  components: { WeeklyTimeScheduleTile, WeeklyTimeSchedulesModal },
  props: {
    deviceType: String,
    timeScheduleConfig: Object,
  },
  data() {
    return {
      weeklySchedulesModalOpen: false,
      preselectWeeklyScheduleId: null,
      openWeeklyScheduleModalInViewMode: null,
      showErrorModal: false,
      deviceNames: '',
    }
  },
  apollo: {
    companyWithSchedules: {
      query: companyWeeklyAndDailyTimeSchedules,
      variables() {
        return {
          companyId: this.currentCompanyId,
          deviceType: this.deviceType,
        }
      },
      update(data) {
        return data.company
      },
    },
  },
  methods: {
    async createWeeklyTimeSchedule(newSchedule, newName) {
      try {
        await this.$apollo.mutate({
          mutation: weeklyTimeScheduleCreate,
          variables: {
            input: {
              companyId: this.currentCompanyId,
              name: newName,
              schedule: newSchedule,
              deviceType: this.deviceType,
            },
          },
          // update cache
          update: (store, response) => {
            const query = {
              query: companyWeeklyAndDailyTimeSchedules,
              variables: {
                companyId: this.currentCompanyId,
                deviceType: this.deviceType,
              },
            }
            const data = store.readQuery(query)
            const newData = produce(data, (draft) => {
              draft.company.weeklyTimeSchedules.unshift(
                response.data.weeklyTimeScheduleCreate.weeklyTimeSchedule,
              )
            })
            store.writeQuery({
              ...query,
              data: newData,
            })
          },
        })
        this.$toast.success(this.$t('device.timeSchedule.createTimeScheduleSuccess'))
      } catch (error) {
        console.error(error)
        throw this.$t('device.timeSchedule.createTimeScheduleFailed')
      }
    },
    async updateWeeklyTimeSchedule(weeklyTimeScheduleId, newSchedule, newName) {
      try {
        await this.$apollo.mutate({
          mutation: weeklyTimeScheduleUpdate,
          variables: {
            input: {
              weeklyTimeScheduleId,
              name: newName,
              schedule: newSchedule,
            },
          },
          refetchQueries: ['companyWeeklyAndDailyTimeSchedules'],
        })
        this.$toast.success(this.$t('device.timeSchedule.updateTimeScheduleSuccess'))
      } catch (error) {
        console.error(error)
        throw this.$t('device.timeSchedule.updateTimeScheduleFailed')
      }
    },
    async deleteWeeklyTimeSchedule(weeklyScheduleId) {
      try {
        await this.$apollo.mutate({
          mutation: weeklyTimeScheduleDelete,
          variables: {
            weeklyScheduleId,
          },
          // update cache
          update: (store, response) => {
            const query = {
              query: companyWeeklyAndDailyTimeSchedules,
              variables: {
                companyId: this.currentCompanyId,
                deviceType: this.deviceType,
              },
            }
            const data = store.readQuery(query)
            const newData = produce(data, (draft) => {
              const indexToRemove = draft.company.weeklyTimeSchedules.findIndex(
                (schedule) => schedule.id === response.data.weeklyTimeScheduleDelete.deletedId,
              )
              draft.company.weeklyTimeSchedules.splice(indexToRemove, 1)
            })
            store.writeQuery({
              ...query,
              data: newData,
            })
          },
        })
        this.$toast.success(this.$t('device.timeSchedule.deleteTimeScheduleSuccess'))
      } catch (error) {
        console.error(error)
        throw this.$t('device.timeSchedule.deleteTimeScheduleFailed')
      }
    },
    async createDailyTimeSchedule(newSchedule, newName) {
      try {
        const response = await this.$apollo.mutate({
          mutation: dailyTimeScheduleCreate,
          variables: {
            input: {
              companyId: this.currentCompanyId,
              name: newName,
              schedule: newSchedule,
              deviceType: this.deviceType,
            },
          },
          // update cache
          update: (store, response) => {
            const query = {
              query: companyWeeklyAndDailyTimeSchedules,
              variables: {
                companyId: this.currentCompanyId,
                deviceType: this.deviceType,
              },
            }
            const data = store.readQuery(query)
            const newData = produce(data, (draft) => {
              draft.company.dailyTimeSchedules.unshift(
                response.data.dailyTimeScheduleCreate.dailyTimeSchedule,
              )
            })
            store.writeQuery({
              ...query,
              data: newData,
            })
          },
        })
        this.$toast.success(this.$t('device.timeSchedule.createTimeScheduleSuccess'))

        return response.data.dailyTimeScheduleCreate.dailyTimeSchedule.id
      } catch (error) {
        console.error(error)
        throw this.$t('device.timeSchedule.createTimeScheduleFailed')
      }
    },
    async updateDailyTimeSchedule(newSchedule, newName, dailyTimeScheduleId) {
      try {
        const response = await this.$apollo.mutate({
          mutation: dailyTimeScheduleUpdate,
          variables: {
            input: {
              dailyTimeScheduleId,
              name: newName,
              schedule: newSchedule,
            },
          },
          refetchQueries: ['companyWeeklyAndDailyTimeSchedules'],
        })
        if (response.data.dailyTimeScheduleUpdate.deviceNames) {
          this.deviceNames = response.data.dailyTimeScheduleUpdate.deviceNames
          this.showErrorModal = true
        } else {
          this.$toast.success(this.$t('device.timeSchedule.updateTimeScheduleSuccess'))
        }
      } catch (error) {
        console.error(error)
        throw this.$t('device.timeSchedule.updateTimeScheduleFailed')
      }
    },
    openWeeklyScheduleModal(id, viewMode) {
      this.preselectWeeklyScheduleId = id
      this.openWeeklyScheduleModalInViewMode = viewMode
      this.weeklySchedulesModalOpen = true
    },
    removeName(name, namesTaken) {
      const index = namesTaken.indexOf(name)
      if (index === -1) {
        return namesTaken
      }
      const namesCopy = [...namesTaken]
      namesCopy.splice(index, 1)
      return namesCopy
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    maxTextWidth() {
      const computedMaxTextWidth = this.companyWithSchedules?.weeklyTimeSchedules.reduce(
        (maxWidth, cur) => {
          return Math.max(maxWidth, getTextWidth(cur.name, 'bold 18px Roboto'))
        },
        200,
      )

      return Math.min(computedMaxTextWidth ? computedMaxTextWidth + 35 : 200, 300) // +35 tile padding
    },
    weeklySchedulesNames() {
      if (!this.companyWithSchedules) {
        return []
      }
      return this.companyWithSchedules.weeklyTimeSchedules.map((schedule) => schedule.name)
    },
    viewModeEnum() {
      return viewModeEnum
    },
  },
}
</script>
<style lang="less">
.v-dialog__content--active {
  z-index: 500 !important;
}

.v-overlay {
  z-index: 500 !important;
}
.schedule-templates-wrapper {
  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  .templates-wrapper {
    display: grid;
    gap: 5px;
  }
}
</style>
