import gql from 'graphql-tag'

export const AllPermissions = gql`
  fragment AllPermissions on Permission {
    id
    superAdmin
    companyCreateInvite
    companyAdministration
    companyUserManagement
    templateManagement
    otherCompaniesManagement
    configurationChanges
    configurationUpload
    deviceControl
    expertMode
    operationalHistory
    restrictedCustomerData
    fullCustomerData
    serviceSequences
    newProductManagement
    baseProductManagement
  }
`
