<template>
  <div class="d-flex flex-column">
    <span v-if="customName" class="custom-name">{{ customName }}</span>
    <div class="d-flex" :class="{ small: customName || small }">
      <div class="class-number">{{ notificationClasses[notificationClass].classNumber }}</div>
      <span v-sanitized-html="$t(`global.notificationClasses.${notificationClass}`)"></span>
    </div>
  </div>
</template>
<script>
import { notificationClasses } from '@/config/notificationClasses'

export default {
  name: 'NotificationClass',
  props: {
    notificationClass: String,
    customName: String,
    small: Boolean,
  },
  computed: {
    notificationClasses() {
      return notificationClasses
    },
  },
}
</script>
<style lang="less" scoped>
.set-size(@size) {
  .class-number {
    width: @size;
    height: @size;
    line-height: @size;
  }
  span {
    line-height: @size;
  }
}

.class-number {
  background-color: #a3d4ff;
  display: flex;
  align-content: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
}

.set-size(25px);

.small {
  .set-size(20px);
  span,
  .class-number {
    font-size: 12px;
  }
}

.custom-name {
  font-weight: 500;
  margin-bottom: 5px;
}
</style>
