export default {
  created() {
    let that = this
    document.addEventListener('keyup', function (evt) {
      {
        if (evt.keyCode === 27) {
          if (this.close) {
            that.close()
          } else {
            console.warn('Missing close function')
          }
        }
      }
    })
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
