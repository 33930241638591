<template>
  <v-dialog :value="value" @input="$emit('input', $event)" width="unset">
    <v-card>
      <v-card-title class="headline">{{ heading }}</v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input', false)" depressed>
          {{ cancelText || $t('global.cancel') }}
        </v-btn>
        <v-btn :color="confirmBtnColor || 'primary'" @click="action()" depressed>
          {{ confirmText || $t('global.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmationDialog',
  props: {
    value: Boolean,
    heading: String,
    text: String,
    cancelText: String,
    confirmText: String,
    confirmBtnColor: String,
    action: Function,
  },
}
</script>
