<template>
  <div>
    <header>
      <router-link class="d-flex align-center" :to="{ name: 'DashboardPage' }">
        <IXSolveLogo class="logo-full" width="134" height="30" />
        <IXLogo class="logo-icon" width="22" height="23" />
      </router-link>
      <v-select
        v-if="me && me.companies"
        :items="[...me.companies, { name: $t('global.myDevices'), id: null }]"
        v-model="currentCompanyId"
        item-text="name"
        item-value="id"
        solo
        dense
        flat
        hide-details
        class="company-select"
      ></v-select>
      <DeviceSearch />
      <div class="flex-grow-1"></div>
      <LocaleSelect id="locale-select" class="ml-2" />
      <div class="hamburger-menu-btn-wrapper">
        <v-btn icon @click="showMobileMenu = !showMobileMenu">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>
      <v-menu offset-y :zIndex="200">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="user-menu-activator ml-2">
            <div class="logged-in-text-wrapper">
              <span
                ><b>{{ currentlyLogged }}</b></span
              >
            </div>
            <div class="two-char-name">
              <template v-if="twoCharName">{{ twoCharName }}</template>
              <v-icon v-else color="white">mdi-account</v-icon>
            </div>
          </div>
        </template>
        <div class="user-menu-wrapper">
          <dl>
            <dt>{{ $t('global.email') }}</dt>
            <dd v-if="me">{{ me.email }}</dd>
            <template v-if="currentCompanyId">
              <dt>{{ $t('global.company') }}</dt>
              <dd>{{ currentCompanyName }}</dd>
            </template>
          </dl>
          <div class="user-menu-footer">
            <v-btn color="primary" :to="{ name: 'MyAccountPage' }" depressed>{{
              $t('global.myAccount')
            }}</v-btn>
            <v-btn color="error" text @click="logout"
              ><v-icon left> mdi-power </v-icon>{{ $t('global.menu.logout') }}</v-btn
            >
          </div>
        </div>
      </v-menu>
    </header>
    <div class="app-wrapper">
      <Menu :showMobileMenu.sync="showMobileMenu"></Menu>
      <router-view class="content"></router-view>
    </div>
    <CreateCompanyModal
      :show="createCompanyModalVisible"
      @close="createCompanyModalVisible = false"
    ></CreateCompanyModal>

    <v-dialog v-model="acceptCompanyToCompanyInvitationModalOpen" width="500" persistent>
      <v-card>
        <v-card-title class="headline">{{
          $t('global.acceptCompanyToCompanyInvitationModal.header')
        }}</v-card-title>
        <v-card-text>{{
          $t('global.acceptCompanyToCompanyInvitationModal.text', {
            childCompanyName,
            parentCompanyName,
          })
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="acceptCompanyToCompanyInvitationModalOpen = false">
            {{ $t('global.cancel') }}
          </v-btn>
          <v-btn color="primary" @click="acceptCompanyToCompanyInvitation()">
            {{ $t('global.accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="invalidInvitationModalOpen" width="500">
      <v-card>
        <v-card-title class="headline">{{
          $t('global.invalidInvitationModal.header')
        }}</v-card-title>
        <v-card-text>{{ $t('global.invalidInvitationModal.text') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="invalidInvitationModalOpen = false">
            {{ $t('global.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Menu from '@/components/Menu'
import DeviceSearch from '../components/UI/Layout/DeviceSearch'
import LocaleSelect from '@/components/LocaleSelect'
import CreateCompanyModal from '@/components/CreateCompanyModal/CreateCompanyModal'
import { saveItem } from '@/middleware/storage'
import store from '@/store'
import { me } from '@/graphql/query/me'
import { apolloClient } from '@/graphql/client'
import { Auth } from 'aws-amplify'
import IXSolveLogo from '@/assets/images/logo-ixsolve.svg'
import IXLogo from '@/assets/images/logo-ix.svg'
import { isTokenValid } from '@/graphql/query/isTokenValid'
import { companyToCompanyInvitation } from '@/graphql/query/companyToCompanyInvitation'
import { companyToCompanyInvitationAccept } from '@/graphql/mutations/companyToCompanyInvitationAccept'
import logout from '@/mixins/logout'

export default {
  name: 'LayoutPage',
  components: {
    DeviceSearch,
    Menu,
    CreateCompanyModal,
    IXSolveLogo,
    IXLogo,
    LocaleSelect,
  },
  mixins: [logout],
  data: function () {
    return {
      createCompanyModalVisible: false,
      invalidInvitationModalOpen: false,
      acceptCompanyToCompanyInvitationModalOpen: false,
      serviceCompanyInvitationAcceptationModalOpen: false,
      childCompanyName: null,
      parentCompanyName: null,
      showMobileMenu: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    // if user was already logged in and he clicked on invitation link that belongs to another account, he will be signed out
    if ((to.query.createCompanyToken || to.query.serviceCompanyToken) && to.query.email) {
      const user = store.getters['user/currentUser']
      if (to.query.email.toLowerCase() !== user?.email.toLowerCase()) {
        saveItem('redirect', to.fullPath)
        store.dispatch('user/logout')
        apolloClient.clearStore()
        apolloClient.resetStore()
        await Auth.signOut()
        next({ name: 'LoginPage' })
      }
    }
    next()
  },
  mounted: async function () {
    try {
      const tokens = ['createCompanyToken', 'serviceCompanyToken', 'deviceServiceCompanyToken']
      const tokenName = tokens.find((token) => this.$route.query[token])
      if (tokenName && this.$route.query.email) {
        const tokenValid = await this.isTokenValid(
          this.$route.query[tokenName],
          this.$route.query.email,
        )
        if (tokenValid) {
          if (this.$route.query.createCompanyToken) {
            this.createCompanyModalVisible = true
          } else if (this.$route.query.serviceCompanyToken) {
            const response = await this.$apollo.query({
              query: companyToCompanyInvitation,
              variables: {
                token: this.$route.query.serviceCompanyToken,
              },
            })
            this.childCompanyName = response.data.companyToCompanyInvitation?.childCompany.name
            this.parentCompanyName = response.data.companyToCompanyInvitation?.parentCompany.name
            this.acceptCompanyToCompanyInvitationModalOpen = true
          } else if (this.$route.query.deviceServiceCompanyToken) {
            this.serviceCompanyInvitationAcceptationModalOpen = true
          }
        } else {
          this.invalidInvitationModalOpen = true
        }
      }
    } catch (error) {
      console.error(error)
    }
  },
  apollo: {
    me: {
      query: me,
      fetchPolicy: 'cache-first',
    },
  },
  watch: {
    me: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          const companyId = this.$store.getters['user/currentCompanyId']
          if (companyId === undefined && newValue.companies.length) {
            this.$store.commit('user/setCurrentUserCompanyId', newValue.companies[0].id)
          }
          store.commit('user/setCurrentUser', { user: newValue })
        }
      },
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyName']),
    twoCharName: function () {
      if (this.me && this.me.firstName !== '') {
        return this.me.firstName.substr(0, 1) + this.me.lastName.substr(0, 1)
      } else {
        return null
      }
    },
    currentlyLogged: function () {
      if (this.me) {
        if (this.me.firstName) {
          return `${this.me.firstName} ${this.me.lastName}`
        } else {
          return this.me.email
        }
      }

      return ''
    },
    currentCompanyId: {
      get() {
        return this.$store.getters['user/currentCompanyId']
      },
      set(value) {
        this.$store.commit('user/setCurrentUserCompanyId', value)

        const availableDeviceTypes = this.$store.getters['user/availableDeviceTypes']

        if (!availableDeviceTypes?.length) {
          if (this.$router.currentRoute.name !== 'NoDevicesPage') {
            this.$router.push({ name: 'NoDevicesPage' })
          }
        } else {
          if (
            this.$router.currentRoute.name !== 'DeviceListPageListView' ||
            this.$router.currentRoute.params.type !== availableDeviceTypes[0]
          ) {
            this.$router.push({ name: 'DeviceListPage', params: { type: availableDeviceTypes[0] } })
          }
        }
      },
    },
  },
  methods: {
    async isTokenValid(token, email) {
      const response = await this.$apollo.query({
        query: isTokenValid,
        variables: {
          token,
          email,
        },
      })

      return response.data.isTokenValid
    },
    async acceptCompanyToCompanyInvitation() {
      try {
        await this.$apollo.mutate({
          mutation: companyToCompanyInvitationAccept,
          variables: {
            token: this.$route.query.serviceCompanyToken,
            email: this.$route.query.email,
          },
        })

        this.$toast.success(this.$t('global.acceptCompanyToCompanyInvitation.success'))
        this.acceptCompanyToCompanyInvitationModalOpen = false
      } catch (error) {
        this.$toast.error(this.$t('global.acceptCompanyToCompanyInvitation.failure'))
      }
    },
  },
}
</script>

<style scoped lang="less">
.app-wrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
}

header {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #eeeeee;
  padding: 10px 16px;
  min-height: 56px;
}

.hamburger-menu-btn-wrapper {
  display: none !important;
}

.content {
  position: relative;
  flex: 1 1 auto;
  animation: showRight 300ms ease;
  overflow: auto;
  min-height: 100%;
}

.company-select {
  max-width: 150px;
  margin-left: 30px !important;
  margin-right: 20px !important;

  /deep/.v-select__selection {
    font-size: 14px;
    font-weight: 700;
  }
}

.search-wrapper {
  flex-basis: 380px;
}

#locale-select {
  flex-basis: 0;
  flex-grow: 0;
}

.logged-in-text-wrapper {
  font-size: 14px;
  flex-grow: 1;
  margin-right: 10px;
  margin-left: 15px;
}

.logo-icon {
  display: none;
}

.two-char-name {
  color: white;
  background-color: #adadad;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-menu-activator {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: right;
  margin-left: auto;
  white-space: nowrap;
}

.user-menu-wrapper {
  background-color: white;

  dl {
    padding: 15px;

    dt {
      color: #adadad;
      font-weight: 500;
    }

    dd {
      font-weight: bold;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  .user-menu-footer {
    border-top: 1px solid #ddd;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
}

@media (max-width: 767px) {
  .company-select {
    margin-left: 20px !important;
    margin-right: 10px !important;
  }

  .hamburger-menu-btn-wrapper {
    display: block !important;
    flex-grow: 1;
    text-align: right;
    margin-right: 15px;
    margin-left: 20px;
  }

  .logo-icon {
    display: block;
  }
  .logo-full {
    display: none;
  }

  .logged-in-text-wrapper {
    display: none;
  }

  #locale-select {
    display: none;
  }
}

@media (max-width: 484px) {
  header {
    flex-wrap: wrap;
  }

  .search-wrapper {
    order: 5;
    flex-grow: 1;
    margin-bottom: 10px;
    margin-top: 3px;
  }

  .hamburger-menu-btn-wrapper {
    margin-right: 10px;
    margin-left: 0px;
  }
}
</style>
