import gql from 'graphql-tag'

export const translationsUpload = gql`
  mutation translationsUpload {
    translationsUpload(input: {}) {
      success
      updatedFiles
      failedFiles
    }
  }
`
