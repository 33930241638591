import gql from 'graphql-tag'

export const hasChargerApiKey = gql`
  query hasChargerApiKey {
    me {
      id
      hasChargerApiKey
    }
  }
`
