import gql from 'graphql-tag'

export const companyCustomNotificationClasses = gql`
  query companyCustomNotificationClasses($companyId: ID!) {
    company(companyId: $companyId) {
      id
      usesDefaultNotificationClassDelays
      customNotificationClasses {
        id
        customName
        delays(strict: false)
        baseNotificationClass {
          id
          notificationClass
          delays
        }
        isUsed
      }
    }
  }
`
