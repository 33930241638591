import gql from 'graphql-tag'

export const createCompanyInvitations = gql`
  query createCompanyInvitations($companyId: ID!) {
    company(companyId: $companyId) {
      id
      createCompanyInvitations {
        id
        email
        expired
        language
        createdCompany {
          id
          name
        }
      }
    }
  }
`
