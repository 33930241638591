import gql from 'graphql-tag'

export const chargingHistory = gql`
  query chargingHistory($deviceId: ID!, $first: Int, $after: String) {
    device(deviceId: $deviceId) {
      id
      chargingHistory(first: $first, after: $after) {
        edges {
          node {
            timestamp
            duration
            energy
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

export const chargingHistoryFromTo = gql`
  query chargingHistoryFromTo($deviceId: ID!, $from: DateTime, $to: DateTime) {
    device(deviceId: $deviceId) {
      id
      chargingHistoryFromTo(from: $from, to: $to) {
        timestamp
        duration
        energy
      }
    }
  }
`
