import gql from 'graphql-tag'

export const energyChartData = gql`
  query energyChartData(
    $deviceId: ID!
    $from: DateTime!
    $to: DateTime!
    $lang: AcceptedLanguageEnum
  ) {
    device(deviceId: $deviceId) {
      id
      energyChartData(from: $from, to: $to, lang: $lang) {
        equipmentId
        energyCounter
        timeCounter
      }
    }
  }
`
