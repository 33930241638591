import gql from 'graphql-tag'

export const coreCauseCreate = gql`
  mutation coreCauseCreate(
    $name: NonEmptyString!
    $descriptionTranslations: DescriptionTranslationsInput!
  ) {
    coreCauseCreate(input: { name: $name, descriptionTranslations: $descriptionTranslations }) {
      success
    }
  }
`
