<template>
  <SetPermissionsPage
    :permissions="permissions"
    :pageTitle="$t('users.serviceCompaniesPermissions')"
    :settablePermissions="childCompaniesPermissions"
    :detailPageName="'ServiceCompanyDetailPage'"
  />
</template>

<script>
import { serviceCompanies } from '@/graphql/query/serviceCompanies'
import { childCompaniesPermissions } from '@/config/permissions'
import { mapGetters } from 'vuex'
import SetPermissionsPage from '@/views/SetPermissionsPage'

export default {
  name: 'ServiceCompanyPermissionsPage',
  components: { SetPermissionsPage },
  data: () => {
    return {
      permissions: [],
    }
  },
  apollo: {
    serviceCompanies: {
      query: serviceCompanies,
      variables() {
        return {
          companyId: this.currentCompanyId,
        }
      },
      skip() {
        return !this.currentCompanyId
      },
      update(data) {
        this.permissions = data.company.companiesWithAccess.map((company) => ({
          id: company.permissions.id,
          name: company.name,
          userId: company.id,
          ...Object.fromEntries(
            Object.values(childCompaniesPermissions).map((permissionName) => [
              permissionName,
              company.permissions[permissionName],
            ]),
          ),
        }))
        return data.company.companiesWithAccess
      },
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    childCompaniesPermissions() {
      return childCompaniesPermissions
    },
  },
}
</script>
