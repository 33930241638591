import gql from 'graphql-tag'

export const translationsDownload = gql`
  mutation translationsDownload($onlyDatabase: Boolean!) {
    translationsDownload(input: { onlyDatabase: $onlyDatabase }) {
      success
      updatedFiles
      failedFiles
    }
  }
`
