import gql from 'graphql-tag'

const createDevice = gql`
  mutation($data: CreateDeviceInput!) {
    deviceCreate(input: $data) {
      status
      clientMutationId
      device {
        id
        name
        data
      }
      errors {
        name
        error
      }
    }
  }
`
const updateDevice = gql`
  mutation($data: UpdateDeviceInput!) {
    updateDevice(input: $data) {
      status
      clientMutationId
      device {
        id
        name
        data
      }
      errors {
        name
        error
      }
    }
  }
`

export { createDevice, updateDevice }
