import gql from 'graphql-tag'

export const companyWeeklyAndDailyTimeSchedules = gql`
  query companyWeeklyAndDailyTimeSchedules($companyId: ID!, $deviceType: DeviceTypeEnum!) {
    company(companyId: $companyId) {
      id
      weeklyTimeSchedules(deviceType: $deviceType) {
        id
        name
        isUsed
        isGlobal
        weeklySchedule {
          days
          dailySchedule {
            id
          }
        }
      }
      dailyTimeSchedules(deviceType: $deviceType) {
        id
        name
        schedule
        isGlobal
        isUsed
      }
    }
  }
`
