import gql from 'graphql-tag'

export const serviceHistory = gql`
  query serviceHistory(
    $deviceId: ID!
    $entryTypes: [ServiceHistoryEntryTypeEnum!]
    $first: Int
    $after: String
    $language: AcceptedLanguageEnum
    $lang: String
  ) {
    device(deviceId: $deviceId) {
      id
      serviceHistory(entryTypes: $entryTypes, first: $first, after: $after) {
        edges {
          node {
            ... on ServiceHistoryUserNote {
              id
              text
              createdAt
              author {
                id
                firstName
                lastName
                email
              }
            }
            ... on ServiceHistoryEvent {
              id
              createdAt
              action
              eventCode {
                severity
                description(lang: $lang)
              }
            }
            ... on OperationCounterCycle {
              id
              equipmentId
              equipmentType(lang: $language)
              timeCounter
              startCounter
              cycleStart
              createdAt
              note
              author {
                id
                firstName
                lastName
                email
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`
