<template>
  <div>
    <v-select
      :items="localeOptions"
      :value="$store.state.locale"
      @input="$store.commit('changeLocale', $event)"
      solo
      dense
      hide-details
      flat
    >
      <template v-slot:selection="{ item }">
        <v-icon>{{ `$${item.value}` }}</v-icon>
      </template>
      <template v-slot:item="{ active, item, attrs, on }">
        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
          <v-icon class="mr-2">{{ `$${item.value}` }}</v-icon
          >{{ item.text }}
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>
<script>
import { allowedLanguages } from '@/translations'

export default {
  name: 'LocaleSelect',
  computed: {
    localeOptions() {
      return allowedLanguages.map((locale) => ({
        text: this.$t(`global.lang.${locale}`),
        value: locale,
      }))
    },
  },
}
</script>
<style lang="less" scoped>
.v-select {
  /deep/.v-input__control > .v-input__slot {
    background: #eee !important;
  }
}
</style>
