import gql from 'graphql-tag'

export const usersWithAccessToServiceHistory = gql`
  query usersWithAccessToServiceHistory($deviceId: ID!) {
    device(deviceId: $deviceId) {
      id
      usersWithAccessToServiceHistory {
        id
        firstName
        lastName
        email
      }
    }
  }
`
