import gql from 'graphql-tag'

export const ImageData = gql`
  fragment ImageData on Image {
    id
    addedByUser {
      id
      firstName
      lastName
      email
    }
    name
    uploadedAt
    takenAt
    url
  }
`
