import gql from 'graphql-tag'

export const userDeviceAccessChangeType = gql`
  mutation userDeviceAccessChangeType(
    $deviceId: ID!
    $userId: ID!
    $newType: UserDeviceAccessTypeEnum!
  ) {
    userDeviceAccessChangeType(input: { deviceId: $deviceId, userId: $userId, newType: $newType }) {
      success
    }
  }
`
