import gql from 'graphql-tag'
import { CompanyUserWithPermissions } from '@/graphql/fragments/companyUserWithPermissions'

export const userInCompanyUpdate = gql`
  mutation userInCompanyUpdate(
    $userId: ID!
    $companyId: ID!
    $userData: UserDataUpdateInput!
    $permissions: CompanyPermissionsInput!
    $withPermissions: Boolean! = true
  ) {
    userInCompanyUpdate(
      input: {
        userId: $userId
        companyId: $companyId
        userData: $userData
        permissions: $permissions
      }
    ) {
      user {
        ...CompanyUserWithPermissions
      }
    }
  }
  ${CompanyUserWithPermissions}
`
