var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-6"},[_c('h1',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.$t('global.companyManagementMenu.users')))]),(_vm.currentCompanyPermissions[_vm.companyPermissions.COMPANY_USER_MANAGEMENT])?_c('div',{staticClass:"d-flex flex-wrap mb-4"},[_c('v-btn',{staticClass:"mr-5 mb-2",staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'AddUserToCompanyPage' },"color":"primary","depressed":"","block":_vm.showBlockButtons}},[_vm._v(_vm._s(_vm.$t('users.addUser')))]),_c('v-btn',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'UserPermissionsPage' },"color":"primary","depressed":"","block":_vm.showBlockButtons}},[_vm._v(_vm._s(_vm.$t('users.setPermissions')))])],1):_vm._e(),_c('DataTableCustom',{staticClass:"elevation-2 pt-2",attrs:{"headers":_vm.usersHeaders,"items":_vm.displayableCompanyUsers,"item-key":"email","item-class":_vm.getRowClass,"search":_vm.userSearch,"no-results-text":_vm.$t('users.noMatchingRecordsFound'),"footer-props":{
      showFirstLastPage: true,
      'items-per-page-text': _vm.$t('global.dataTable.itemsPerPageText'),
      'items-per-page-all-text': _vm.$t('global.dataTable.allItemsText'),
    }},on:{"update:search":function($event){_vm.userSearch=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":_vm.$t('users.search')},model:{value:(_vm.userSearch),callback:function ($$v) {_vm.userSearch=$$v},expression:"userSearch"}})]},proxy:true},(_vm.currentCompanyPermissions[_vm.companyPermissions.COMPANY_USER_MANAGEMENT])?{key:"item.edit",fn:function({ item }){return [(
          _vm.isEditModeAvailable(
            item.companyPermissions &&
              item.companyPermissions[_vm.companyPermissions.COMPANY_ADMINISTRATION],
          )
        )?_c('v-btn',{staticStyle:{"text-decoration":"none"},attrs:{"icon":"","to":{ name: 'CompanyUserDetailPage', params: { id: item.id } }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}:null,{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" "+_vm._s(_vm.$t('global.dataTable.of'))+" "+_vm._s(items.itemsLength)+" ")]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }