import Sortable from 'sortablejs'

export const sortableDataTable = {
  bind(el, binding, vnode) {
    const options = {
      animation: 150,
      handle: '.sort-handle',
      onUpdate: function (event) {
        vnode.child.$emit('sorted', event)
      },
    }
    Sortable.create(el.getElementsByTagName('tbody')[0], options)
  },
}
