<template>
  <v-dialog :value="value" @input="$emit('input', $event)" width="476" content-class="dialog">
    <v-btn @click="$emit('input', false)" class="close-btn" color="white" icon
      ><v-icon>mdi-close</v-icon></v-btn
    >
    <div class="content-wrapper">
      <h1>{{ $t('global.systemTimeScheduleEditValidationModal.heading') }}</h1>
      <v-alert text type="info">{{
        $t('global.systemTimeScheduleEditValidationModal.info')
      }}</v-alert>
      <v-btn @click="duplicateAndClose" class="mb-2" color="primary" height="48" block depressed>{{
        $t('global.systemTimeScheduleEditValidationModal.duplicateAction')
      }}</v-btn>
      <v-btn @click="$emit('input', false)" height="48" outlined block>{{
        $t('global.cancel')
      }}</v-btn>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: 'SystemTimeScheduleEditValidationModal',
  props: {
    value: Boolean,
    duplicateAction: Function,
  },
  methods: {
    duplicateAndClose() {
      this.duplicateAction()
      this.$emit('input', false)
    },
  },
}
</script>
<style lang="less" scoped>
/deep/.dialog {
  background: white;
  border-radius: 16px;
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.close-btn {
  position: absolute;
  top: -18px;
  right: -18px;
  background: black;
}

h1 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
