export class Condition {
  getOtherDataValue(otherData, key) {
    return otherData && otherData[key] ? otherData[key] : null
  }
}

export class SubConditionsHolder extends Condition {
  constructor() {
    super()
    this._conditions = []
  }

  addCondition(condition) {
    this._conditions.push(condition)
  }
  addConditions(conditions) {
    for (let condition of conditions) {
      this.addCondition(condition)
    }
  }
}

export class EqualCondition extends Condition {
  constructor(conditionSettings) {
    super()
    this.key = conditionSettings.key
    this.value = conditionSettings.value
  }

  evaluate(otherData) {
    const otherDataValue = this.getOtherDataValue(otherData, this.key)
    return otherDataValue === this.value
  }
}
export class NotEqualCondition extends EqualCondition {
  evaluate(otherData) {
    return !super.evaluate(otherData)
  }
}

export class ValueInCondition extends Condition {
  constructor(conditionSettings) {
    super()
    this.key = conditionSettings.key
    this.values = conditionSettings.values
  }

  evaluate(otherData) {
    const otherDataValue = this.getOtherDataValue(otherData, this.key)
    return this.values.includes(otherDataValue)
  }
}
export class ValueNotInCondition extends ValueInCondition {
  evaluate(otherData) {
    return !super.evaluate(otherData)
  }
}

export class OrConditionHolder extends SubConditionsHolder {
  evaluate(otherData) {
    for (let condition of this._conditions) {
      if (condition.evaluate(otherData)) {
        return true
      }
    }

    return false
  }
}
export class AndConditionHolder extends SubConditionsHolder {
  evaluate(otherData) {
    for (let condition of this._conditions) {
      if (!condition.evaluate(otherData)) {
        return false
      }
    }

    return true
  }
}

function extractSubConditions(subConditions) {
  let conditions = []

  for (let condition of subConditions) {
    if (!Array.isArray(condition)) {
      conditions.push(parseSubConditions(condition))
    }
  }

  return conditions
}

export function parseSubConditions(conditionsRaw) {
  const condition = conditionsRaw.hasOwnProperty('c') ? conditionsRaw.c : 'and'

  let orCond = null,
    andCond = null
  switch (condition.toLowerCase()) {
    case 'equal':
      return new EqualCondition(conditionsRaw)
    case 'notequal':
      return new NotEqualCondition(conditionsRaw)
    case 'valuein':
      return new ValueInCondition(conditionsRaw)
    case 'valuenotin':
      return new ValueNotInCondition(conditionsRaw)

    case 'or':
      orCond = new OrConditionHolder()
      if (conditionsRaw.hasOwnProperty('conditions')) {
        orCond.addConditions(extractSubConditions(conditionsRaw.conditions))
      }
      return orCond

    case 'and':
    default:
      andCond = new AndConditionHolder()
      if (conditionsRaw.hasOwnProperty('conditions')) {
        andCond.addConditions(extractSubConditions(conditionsRaw.conditions))
      }
      return andCond
  }
}

export function prepareConditions(conditionsRaw) {
  const rootCondition = new OrConditionHolder()

  for (let conditionSettings of conditionsRaw) {
    if (Array.isArray(conditionSettings)) {
      const andCond = new AndConditionHolder()
      andCond.addConditions(extractSubConditions(conditionSettings))
      rootCondition.addCondition(andCond)
    } else {
      rootCondition.addCondition(parseSubConditions(conditionSettings))
    }
  }

  return rootCondition
}
