import gql from 'graphql-tag'

export const customNotificationClassCreate = gql`
  mutation customNotificationClassCreate(
    $companyId: ID!
    $baseNotificationClass: NotificationClassEnum!
    $delays: [Int!]!
    $customName: NonEmptyString!
  ) {
    customNotificationClassCreate(
      input: {
        companyId: $companyId
        baseNotificationClass: $baseNotificationClass
        delays: $delays
        customName: $customName
      }
    ) {
      success
    }
  }
`
