<template>
  <div class="history-tab-wrapper">
    <div v-if="$apollo.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else-if="!deviceSystemLogs || !deviceSystemLogs.length" class="message-wrapper">
      {{ $t('device.noRecords') }}
    </div>
    <Value
      v-else
      v-for="(log, index) of deviceSystemLogs"
      :key="index"
      :label="getLabel(log)"
      :value="log.message"
      class="mb-1"
    />
  </div>
</template>
<script>
import { deviceSystemLogs } from '@/graphql/query/device'
import format from 'date-fns/format'
import Value from '@/components/UI/Value'

export default {
  name: 'HistoryTab',
  components: { Value },
  props: {
    device: Object,
  },
  apollo: {
    deviceSystemLogs: {
      query: deviceSystemLogs,
      variables() {
        return {
          deviceId: this.device.id,
        }
      },
      update(data) {
        return data.device.systemLogs
      },
    },
  },
  methods: {
    getLabel(log) {
      const occuredPart = log.count === 1 ? '' : `${log.count}x `
      return `${occuredPart}${format(new Date(log.created), 'dd/MM/y h:mm a')}`
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.history-tab-wrapper {
  max-width: 1000px;
}
</style>
