<template>
  <div class="pa-6">
    <h1 class="mb-5">{{ $t('global.companyManagementMenu.serviceCompanies') }}</h1>
    <div
      v-if="currentCompanyPermissions[companyPermissions.COMPANY_USER_MANAGEMENT]"
      class="d-flex flex-wrap mb-4"
    >
      <v-btn
        :to="{ name: 'AddCompanyToCompanyPage' }"
        style="text-decoration: none"
        color="primary"
        class="mr-5 mb-2"
        depressed
        :block="showBlockButtons"
        >{{ $t('users.addCompany') }}</v-btn
      >
      <v-btn
        v-if="serviceCompanies && serviceCompanies.length"
        :to="{ name: 'ServiceCompanyPermissionsPage' }"
        style="text-decoration: none"
        color="primary"
        depressed
        :block="showBlockButtons"
        >{{ $t('users.setPermissions') }}</v-btn
      >
    </div>
    <DataTableCustom
      :headers="companiesHeaders"
      :items="serviceCompanies"
      item-key="email"
      :item-class="getRowClass"
      class="elevation-2 pt-2"
      :search.sync="companySearch"
      :no-results-text="$t('users.noMatchingRecordsFound')"
      :no-data-text="$t('users.noServiceCompanies')"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-text': $t('global.dataTable.itemsPerPageText'),
        'items-per-page-all-text': $t('global.dataTable.allItemsText'),
      }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="companySearch"
          :label="$t('users.search')"
          class="mx-4"
        ></v-text-field>
      </template>
      <template
        v-if="currentCompanyPermissions[companyPermissions.COMPANY_USER_MANAGEMENT]"
        v-slot:item.edit="{ item }"
      >
        <v-btn
          icon
          v-if="
            isEditModeAvailable(
              item.companyPermissions &&
                item.companyPermissions[companyPermissions.COMPANY_ADMINISTRATION],
            )
          "
          :to="{ name: 'ServiceCompanyDetailPage', params: { id: item.id } }"
          style="text-decoration: none"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} {{ $t('global.dataTable.of') }}
        {{ items.itemsLength }}
      </template>
    </DataTableCustom>
  </div>
</template>
<script>
import { serviceCompanies } from '@/graphql/query/serviceCompanies'
import { mapGetters } from 'vuex'
import { companyPermissions } from '@/config/permissions'

export default {
  name: 'CompanyServiceCompaniesListPage',
  data: () => {
    return {
      companySearch: '',
    }
  },
  apollo: {
    serviceCompanies: {
      query: serviceCompanies,
      variables() {
        return {
          companyId: this.currentCompanyId,
          withPermissions: this.currentCompanyPermissions[
            companyPermissions.COMPANY_USER_MANAGEMENT
          ],
        }
      },
      skip() {
        return !this.currentCompanyId
      },
      update(data) {
        return data.company.companiesWithAccess
      },
    },
  },
  methods: {
    isEditModeAvailable(isUserAdmin) {
      return (
        (this.currentCompanyPermissions[companyPermissions.COMPANY_USER_MANAGEMENT] &&
          !isUserAdmin) ||
        this.currentCompanyPermissions[companyPermissions.COMPANY_ADMINISTRATION]
      )
    },
    getRowClass(item) {
      return item.enabled ? '' : 'disabled-row'
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId', 'currentCompanyPermissions']),
    companiesHeaders() {
      return [
        { text: this.$t('global.companyName'), value: 'name' },
        { text: this.$t('global.email'), value: 'contactEmail' },
        ...(this.currentCompanyPermissions[companyPermissions.COMPANY_USER_MANAGEMENT]
          ? [{ text: '', value: 'edit', align: 'right' }]
          : []),
      ]
    },
    companyPermissions() {
      return companyPermissions
    },
    showBlockButtons: function () {
      return this.$vuetify.breakpoint.name === 'xs'
    },
  },
}
</script>

<style scoped lang="less">
h1 {
  font-size: 24px;
}

/deep/.disabled-row {
  color: darkorange;
}
</style>
