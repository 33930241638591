<template>
  <div class="eligibility-row">
    <div class="name-column">
      <div class="d-flex flex-wrap">
        <h4 class="mr-2">{{ eligibility.label }}</h4>
        <PaymentTypeBadge :paymentType="eligibility.provisionType" class="mr-2" />
        <ServiceSequenceBadge v-if="eligibility.serviceSequence" class="mr-2" />
        <PremiumBadge v-if="eligibility.isPremium" />
      </div>
      <div>
        <span>{{ eligibility.description }}</span>
      </div>
    </div>
    <div class="options-column">
      <v-tooltip v-if="eligibility.defaultAvailability === 'available'" bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <CheckboxInput
              :name="eligibility.name"
              :value="checkboxValue"
              @input="updateValue"
              :onlyInput="true"
              :isPremium="eligibility.isPremium"
              :disabled="eligibility.defaultAvailability === 'available'"
            />
          </div>
        </template>
        <span>{{ this.$t('global.eligibilityControlTooltip') }}</span>
      </v-tooltip>
      <CheckboxInput
        v-else
        :name="eligibility.name"
        :value="checkboxValue"
        @input="updateValue"
        :onlyInput="true"
        :isPremium="eligibility.isPremium"
      />
    </div>
  </div>
</template>

<script>
import CheckboxInput from '@/components/Form/Input/CheckboxInput'
import PaymentTypeBadge from '@/components/UI/PaymentTypeBadge'
import ServiceSequenceBadge from '@/components/UI/ServiceSequenceBadge'
import PremiumBadge from '@/components/UI/PremiumBadge.vue'

export default {
  name: 'EligibilityDeviceControl',
  components: {
    CheckboxInput,
    PaymentTypeBadge,
    ServiceSequenceBadge,
    PremiumBadge,
  },
  props: {
    eligibility: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checkboxValue: this.value.defaultValue,
    }
  },
  methods: {
    updateValue(newValue) {
      this.checkboxValue = newValue
      this.$emit('input', { ...this.value, defaultValue: newValue })
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

.eligibility-row {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #f3f6f9;
  border-radius: 8px;
  margin-bottom: 8px;
}

.name-column {
  flex: 1;
}

.options-column {
  display: flex;
  justify-content: flex-end;
  margin-right: 7rem;
}
</style>
