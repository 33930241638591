var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{staticClass:"mb-4",attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.createEventCodeModalOpen = true}}},[_vm._v(_vm._s(_vm.$t('global.adminEventCodes.addEventCode')))]),_c('DataTableCustom',{staticClass:"elevation-1 pt-2 mb-12",attrs:{"headers":_vm.headers,"items":_vm.eventCodes,"loading":_vm.$apollo.loading,"search":_vm.search,"item-class":() => 'row-wrapper',"footer-props":{
      'items-per-page-text': _vm.$t('global.dataTable.itemsPerPageText'),
      'items-per-page-all-text': _vm.$t('global.dataTable.allItemsText'),
    },"items-per-page":-1,"multi-sort":""},on:{"update:search":function($event){_vm.search=$event},"click:row":(item) => {
        _vm.$router.push({
          name: 'AdminEventCodeDetail',
          params: { id: item.id },
        })
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":_vm.$t('users.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.severity",fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[_c('ReportBadge',{attrs:{"text":item.severity,"severity":item.severity,"clickable":false}})],1)]}},{key:"item.disabled",fn:function({ item }){return [_c('span',{staticClass:"status-badge",class:item.disabled ? 'offline-badge' : 'online-badge'},[_vm._v(_vm._s(item.disabled ? _vm.$t('global.adminEventCode.disabled') : _vm.$t('global.adminEventCode.active')))])]}},{key:"item.defaultNotificationClass",fn:function({ item }){return [(item.defaultNotificationClass != null)?_c('NotificationClass',{attrs:{"notificationClass":item.defaultNotificationClass.notificationClass}}):_vm._e()]}}])}),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.createEventCodeModalOpen),callback:function ($$v) {_vm.createEventCodeModalOpen=$$v},expression:"createEventCodeModalOpen"}},[_c('v-card',[_c('div',{staticClass:"pa-6"},[_c('h2',{ref:"createModalHeader",staticClass:"mb-6"},[_vm._v(" "+_vm._s(_vm.$t('global.adminEventCodes.addEventCode'))+" ")]),_c('EventCodeForm',{ref:"eventCodeForm"}),_c('div',{staticClass:"mt-8 text-right"},[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.createEventCodeModalOpen = false}}},[_vm._v(_vm._s(_vm.$t('global.close')))]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.createEventCode()}}},[_vm._v(_vm._s(_vm.$t('global.submit')))])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }