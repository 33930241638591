<template>
  <transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper" @click.self="close">
        <div class="modal-container">
          <!-- Komponenta -->
          <div class="modal-content">
            <div class="modal-content--sidebar">
              <h2 class="modal-content--sidebar--headline">{{ $t('global.settings') }}</h2>

              <div class="modal-content--sidebar--nav" v-if="formItems.length">
                <button @click="scrollTo('_baseInfo')" class="modal-content--sidebar--button">
                  {{ $t('global.basicInformation') }}
                </button>
                <button
                  @click="scrollTo('section_' + item.name)"
                  v-for="(item, index) in formItems"
                  v-bind:key="'section_button' + index"
                  class="modal-content--sidebar--button"
                >
                  {{ item.label }}
                </button>
                <button
                  v-if="showTimeSchedule"
                  @click="scrollTo('_timeSchedule')"
                  class="modal-content--sidebar--button"
                >
                  {{ $t('global.schedule') }}
                </button>
                <button
                  v-if="showContactInfo"
                  @click="scrollTo('_contactInfo')"
                  class="modal-content--sidebar--button"
                >
                  {{ $t('global.contactInfo') }}
                </button>
                <button
                  v-if="showAddress"
                  @click="scrollTo('_address')"
                  class="modal-content--sidebar--button"
                >
                  {{ $t('global.address') }}
                </button>
              </div>
            </div>

            <div class="modal-content--main">
              <div>
                <v-btn icon class="float-right mt-md-3 mr-md-3" @click="close()"
                  ><v-icon>mdi-window-close</v-icon></v-btn
                >
              </div>
              <div class="modal-content--main--content">
                <div ref="_baseInfo" class="modal-content--main--container">
                  <h3 class="modal-content--main--headline">{{ $t('global.basicInformation') }}</h3>
                  <div class="form-main--grid">
                    <select-input
                      v-if="presetOptions.length > 1"
                      :label="$t('global.template')"
                      variable-name="defaultValuesId"
                      v-model="defaultValuesId"
                      :choices="presetOptions"
                    ></select-input>

                    <hr />

                    <classic-input
                      ref="controller"
                      :label="$t('device.controllerId')"
                      variable-name="controllerId"
                      :errors="errors"
                      name="controller"
                      v-model="form.controllerId"
                      :options="defaultFormItems.controllerOptions"
                      @change="createFormByController($event, true)"
                      required
                    ></classic-input>

                    <classic-input
                      v-if="formItems.length"
                      ref="name"
                      :label="$t('global.deviceName')"
                      variable-name="name"
                      :errors="errors"
                      v-model="form.name"
                      :options="defaultFormItems.nameOptions"
                      required
                    ></classic-input>
                  </div>
                </div>

                <div v-if="formItems.length">
                  <div
                    v-for="(item, index) of formItems"
                    v-bind:key="'inputGroup' + index"
                    :ref="'section_' + item.name"
                    class="modal-content--main--container"
                  >
                    <h3 class="modal-content--main--headline">{{ item.label }}</h3>

                    <div class="form-main--grid">
                      <template v-for="(formItem, index) in item.items">
                        <component
                          v-if="isFieldEnabled(formItem.options)"
                          ref="inputs"
                          :is="componentFormItemType(formItem.type, formItem.unit)"
                          v-bind:key="'form_input' + index"
                          v-model="form.deviceData[formItem.name]"
                          :other-data="form.deviceData"
                          v-bind="formItem"
                          :errors="errors"
                          :options="formItem.options"
                        >
                        </component>
                      </template>
                    </div>
                  </div>
                </div>

                <div
                  v-if="formItems.length && showTimeSchedule"
                  ref="_timeSchedule"
                  class="modal-content--main--container"
                >
                  <h3 class="modal-content--main--headline">{{ $t('global.schedule') }}</h3>
                  <div class="form-main--grid">
                    <select-input
                      name="defaultTimeSchedule"
                      :label="$t('global.defaultTimeSchedule')"
                      variable-name="defaultTimeScheduleId"
                      v-model="form.defaultTimeScheduleId"
                      :choices="defaultFormItems.weeklyScheduleOptions"
                      :errors="errors"
                      required
                    ></select-input>
                  </div>
                </div>

                <div
                  v-if="formItems.length && showContactInfo"
                  ref="_contactInfo"
                  class="modal-content--main--container"
                >
                  <h3 class="modal-content--main--headline">{{ $t('global.contactInfo') }}</h3>
                  <div class="form-main--grid">
                    <classic-input
                      ref="contactName"
                      :label="$t('global.contactName')"
                      name="contactName"
                      v-model="form.contactInfo.name"
                      :errors="errors"
                      required
                    ></classic-input>
                    <classic-input
                      ref="contactEmail"
                      :label="$t('global.contactEmail')"
                      type="email"
                      name="contactEmail"
                      v-model="form.contactInfo.email"
                      :errors="errors"
                    ></classic-input>
                    <classic-input
                      ref="contactPhone"
                      :label="$t('global.contactPhone')"
                      name="contactPhone"
                      v-model="form.contactInfo.phone"
                      :errors="errors"
                    ></classic-input>
                    <classic-input
                      ref="contactNote"
                      :label="$t('global.note')"
                      name="contactNote"
                      v-model="form.contactInfo.note"
                      :errors="errors"
                    ></classic-input>
                  </div>
                </div>

                <div
                  v-if="formItems.length && showAddress"
                  ref="_address"
                  class="modal-content--main--container"
                >
                  <h3 class="modal-content--main--headline">{{ $t('global.address') }}</h3>
                  <div class="form-main--grid">
                    <AddressEdit v-model="form.address" :errors="errors" />
                  </div>
                </div>
              </div>

              <footer class="modal-content--main--footer-create-pool">
                <v-btn
                  @click="isConfirmResetDialogOpen = true"
                  :block="showBlockButtons"
                  small
                  color="error"
                >
                  {{ $t('global.reset') }}
                </v-btn>
                <v-btn
                  :block="showBlockButtons"
                  small
                  v-if="this.formItems.length"
                  @click="savePresetModalOpen = true"
                  >{{ $t('device.saveAsDeviceTemplate') }}</v-btn
                >
                <v-btn :block="showBlockButtons" small @click="() => save(true)">{{
                  $t('device.saveAndPropagate')
                }}</v-btn>
                <v-btn
                  :block="showBlockButtons"
                  small
                  color="primary"
                  @click="() => save(false)"
                  class="modal-content--main--footer--button"
                >
                  {{ $t('global.save') }}
                </v-btn>
              </footer>

              <v-dialog v-model="savePresetModalOpen" width="unset">
                <v-card>
                  <div class="pa-6">
                    <h2>{{ $t('device.saveAsDeviceTemplate') }}</h2>
                    <p>{{ $t('device.saveAsDeviceTemplateDescription') }}</p>
                    <v-form @submit.prevent="savePreset">
                      <v-text-field
                        v-model="templateName"
                        :error-messages="templateNameErrors"
                        :label="$t('global.name')"
                        required
                        @input="$v.templateName.$touch()"
                        @blur="$v.templateName.$touch()"
                        filled
                        style="width: 350px"
                      ></v-text-field>
                      <div class="mt-8 text-right">
                        <v-btn @click="savePresetModalOpen = false" depressed>{{
                          $t('global.cancel')
                        }}</v-btn>
                        <v-btn type="submit" color="primary" class="ml-3" depressed>{{
                          $t('global.save')
                        }}</v-btn>
                      </div>
                    </v-form>
                  </div>
                </v-card>
              </v-dialog>
              <ConfirmationDialog
                v-model="isConfirmResetDialogOpen"
                :heading="$t('global.newDeviceResetConfirmationModal.heading')"
                :text="$t('global.newDeviceResetConfirmationModal.text')"
                :confirmText="$t('global.reset')"
                confirmBtnColor="error"
                :action="resetAction"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue'
import closeOnEscape from '@/mixins/closeOnEscape'
import SelectInput from '@/components/Form/Input/SelectInput'
import AddressEdit from '../Form/Input/AddressEdit'
import ClassicInput from '@/components/Form/Input/ClassicInput'
import ConfirmationDialog from '@/components/ConfirmationDialog'

import { EventBus } from '@/helper/EventBus'
import componentFormItemType from '@/mixins/componentFormItemType'
import createDevice from '@/mixins/device/createDevice'
import { prepareConditions } from '@/utils/conditionsValidator'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { presetCreate } from '../../graphql/mutations/presetCreate'

export default {
  name: 'DeviceNewModal',
  components: { ClassicInput, SelectInput, AddressEdit, ConfirmationDialog },
  mixins: [closeOnEscape, componentFormItemType, createDevice, validationMixin],
  props: {
    show: Boolean,
  },
  data: function () {
    return {
      defaultValuesId: null,
      skipQuery: true,
      isAddressValid: false,
      isConfirmResetDialogOpen: false,
      isManualAddressModeOpen: false,
      savePresetModalOpen: false,
      templateName: null,
    }
  },
  validations: {
    templateName: { required },
  },
  methods: {
    getTemplates() {
      this.skipQuery = false
    },
    async save(propagate) {
      this.errors = []
      if (this.showAddress && !this.form.address?.city?.trim().length) {
        this.errors.push({
          name: 'city',
          error: 'is_empty',
        })
      }
      if (this.showAddress && !this.form.address?.address?.trim().length) {
        this.errors.push({
          name: 'address',
          error: 'is_empty',
        })
      }
      if (this.showTimeSchedule && this.form.defaultTimeScheduleId == null) {
        this.errors.push({
          name: 'defaultTimeSchedule',
          error: 'is_empty',
        })
      }
      if (this.errors.length === 0 && (await this.saveDevice(propagate))) {
        this.close()
        EventBus.$emit('REFRESH_USER_DEVICES')
      } else {
        this.$nextTick(() => {
          const inputWithError = document.querySelector(
            '.modal-content--main--content .error .form-input, .modal-content--main--content .error .form-select',
          )
          inputWithError.scrollIntoView()
          inputWithError.focus()
        })
      }
    },
    scrollTo(section) {
      if (this.$refs[section]) {
        if (Array.isArray(this.$refs[section])) {
          this.$refs[section][0].scrollIntoView()
        } else {
          this.$refs[section].scrollIntoView()
        }
      }
    },
    isFieldEnabled(options) {
      if (options?.enableIfConditions) {
        const condition = prepareConditions(options.enableIfConditions)
        return condition.evaluate(this.form.deviceData)
      }
      return true
    },
    async savePreset() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: presetCreate,
            variables: {
              data: {
                name: this.templateName,
                controller: this.form.controllerId,
                data: this.form.deviceData,
                companyId: this.$store.getters['user/currentCompanyId'],
              },
            },
          })

          this.$toast.success(this.$t('device.saveDeviceTemplateSuccess'))
          this.savePresetModalOpen = false
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('device.saveDeviceTemplateError'))
        }
      }
    },
    resetAction() {
      this.reset()
      this.isConfirmResetDialogOpen = false
    },
  },
  computed: {
    presetOptions() {
      return [
        { value: null, label: '' },
        ...this.presets.map((preset) => ({ value: preset.id, label: preset.name })),
      ]
    },
    showBlockButtons: function () {
      return this.$vuetify.breakpoint.name === 'xs'
    },
    templateNameErrors() {
      const errors = []
      if (!this.$v.templateName.$dirty) {
        return errors
      }
      !this.$v.templateName.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
  },
  watch: {
    defaultValuesId() {
      if (this.defaultValuesId) {
        const preset = this.presets.find((preset) => preset.id === this.defaultValuesId)
        Vue.set(this.form, 'deviceData', { ...this.form.deviceData, ...preset.defaultValues })
      }
    },
    '$i18n.locale'() {
      this.createFormByController(this.form.controllerId, false)
    },
    'form.controllerId'(newValue) {
      if (newValue !== newValue.toUpperCase()) {
        this.form.controllerId = newValue.toUpperCase()
      }
      if (newValue.length === 3 || newValue.length === 7) {
        this.form.controllerId += '-'
      }
      if (newValue.length === 11) {
        this.$nextTick(() => {
          this.$refs.controller.$refs.input.blur()
        })
      }
    },
    'form.deviceData': {
      handler(newDeviceData) {
        // set hidden items to their default values
        const conditionalItems = this.formItems
          .reduce((acc, formItem) => (acc.push(...formItem.items), acc), [])
          .filter((item) => item.options?.enableIfConditions)

        conditionalItems.forEach((item) => {
          if (!this.isFieldEnabled(item.options)) {
            if (newDeviceData[item.name] !== this.defaultValues[item.name]) {
              this.form.deviceData[item.name] = this.defaultValues[item.name]
            }
          }
        })
      },
      deep: true,
    },
  },
}
</script>

<style scoped lang="less">
@import 'editModal.less';
</style>
