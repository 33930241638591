import gql from 'graphql-tag'

export const coreCauseUpdate = gql`
  mutation coreCauseUpdate(
    $coreCauseId: ID!
    $name: NonEmptyString!
    $descriptionTranslations: DescriptionTranslationsInput!
  ) {
    coreCauseUpdate(
      input: {
        coreCauseId: $coreCauseId
        name: $name
        descriptionTranslations: $descriptionTranslations
      }
    ) {
      success
    }
  }
`
