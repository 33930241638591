import gql from 'graphql-tag'

export const devices = gql`
  query devices($search: NonEmptyString, $first: Int, $after: String, $last: Int, $before: String) {
    devices(search: $search, first: $first, after: $after, last: $last, before: $before) {
      edges {
        node {
          id
          controller
          name
          company {
            id
            name
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`
