import gql from 'graphql-tag'

export const controllerInfo = gql`
  query controllerInfo($controller: NonEmptyString!) {
    controllerInfo(controller: $controller) {
      deviceTypeInfo {
        name
        hasAddress
        hasContactInfo
        hasTimeSchedule
      }
    }
  }
`
