import gql from 'graphql-tag'

export const companyEventCodeUseDefaultNotificationClass = gql`
  mutation companyEventCodeUseDefaultNotificationClass($companyId: ID!, $useDefault: Boolean!) {
    companyEventCodeUseDefaultNotificationClass(
      input: { companyId: $companyId, useDefault: $useDefault }
    ) {
      success
    }
  }
`
