import gql from 'graphql-tag'

export const eligibilitiesForm = gql`
  query eligibilitiesForm($companyId: ID!, $thingTypes: [NonEmptyString!]!, $lang: String) {
    eligibilitiesForm(companyId: $companyId, thingTypes: $thingTypes) {
      name
      type
      provisionType
      serviceSequence
      defaultValue
      isPremium
      defaultAvailability
      label(lang: $lang)
      description(lang: $lang)
    }
  }
`
