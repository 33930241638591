<template>
  <v-main>
    <v-container class="text-center">
      <h1>{{ $t('global.pageNotFound') }}</h1>
      <v-btn @click="$router.back()" color="primary">{{ $t('global.back') }}</v-btn>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'NotFoundPage',
}
</script>

<style scoped lang="less"></style>
