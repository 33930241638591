var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',[_c('v-text-field',{staticStyle:{"width":"330px"},attrs:{"error-messages":_vm.nameErrors,"label":_vm.$t('global.name'),"filled":""},on:{"input":function($event){return _vm.$v.form.name.$touch()},"blur":function($event){return _vm.$v.form.name.$touch()}},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('v-radio-group',{staticClass:"token-expiration-radio",attrs:{"label":_vm.$t('global.demoTokenForm.tokenExpiration')},model:{value:(_vm.form.doesTokenExpire),callback:function ($$v) {_vm.$set(_vm.form, "doesTokenExpire", $$v)},expression:"form.doesTokenExpire"}},[_c('v-radio',{attrs:{"value":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-datetime-picker',{ref:"dateTimePicker",attrs:{"label":_vm.$t('global.demoTokenForm.expirationDate'),"clearText":_vm.$t('global.clear'),"okText":_vm.$t('global.save'),"textFieldProps":{
            filled: true,
            dense: true,
            errorMessages: _vm.expiresAtErrors,
            class: 'date-time-text-field',
          },"datePickerProps":{
            locale: _vm.$i18n.locale,
            'first-day-of-week': 1,
            class: 'date-time-picker-wrapper',
          },"timePickerProps":{ format: '24hr', class: 'date-time-picker-wrapper' },"dateFormat":"d.M.yyyy"},model:{value:(_vm.form.expiresAt),callback:function ($$v) {_vm.$set(_vm.form, "expiresAt", $$v)},expression:"form.expiresAt"}},[_c('template',{slot:"dateIcon"},[_c('v-icon',[_vm._v("mdi-calendar")])],1),_c('template',{slot:"timeIcon"},[_c('v-icon',[_vm._v("mdi-clock-outline")])],1)],2)]},proxy:true}])}),_c('v-radio',{attrs:{"value":false,"label":_vm.$t('global.demoTokenForm.neverExpires')}})],1),_c('v-radio-group',{staticClass:"access-expiration-radio",attrs:{"label":_vm.$t('global.demoTokenForm.accessExpiration')},model:{value:(_vm.form.doesAccessExpire),callback:function ($$v) {_vm.$set(_vm.form, "doesAccessExpire", $$v)},expression:"form.doesAccessExpire"}},[_c('v-radio',{attrs:{"value":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('DurationInput',{attrs:{"errorMessages":_vm.accessExpirationMinutesErrors},on:{"input":function($event){return _vm.$v.form.accessExpirationMinutes.$touch()},"hasError":function($event){_vm.durationHasError = $event}},model:{value:(_vm.form.accessExpirationMinutes),callback:function ($$v) {_vm.$set(_vm.form, "accessExpirationMinutes", $$v)},expression:"form.accessExpirationMinutes"}})]},proxy:true}])}),_c('v-radio',{attrs:{"value":false,"label":_vm.$t('global.demoTokenForm.neverExpires')}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }