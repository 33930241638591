import gql from 'graphql-tag'

export const deviceSendCommand = gql`
  mutation deviceSendCommand(
    $deviceId: ID!
    $serviceSequenceName: NonEmptyString!
    $setValue: NonEmptyString!
    $setInputValue: String
  ) {
    deviceSendCommand(
      input: {
        deviceId: $deviceId
        serviceSequenceName: $serviceSequenceName
        setValue: $setValue
        setInputValue: $setInputValue
      }
    ) {
      success
    }
  }
`
