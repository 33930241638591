import Vue from 'vue'
import { prepareForm } from '@/graphql/query/form'
import { controllerInfo } from '@/graphql/query/controllerInfo'
import { companyWeeklyTimeSchedulesGetOrCreate } from '@/graphql/mutations/companyWeeklyTimeSchedulesGetOrCreate'
import { createDevice } from '@/graphql/mutations/device'

const defaultData = {
  defaultValuesId: null,
  lang: '',
  name: '',
  controllerId: '',
  deviceData: {},
  defaultTimeScheduleId: null,
  contactInfo: {
    name: '',
    email: '',
    phone: '',
    note: '',
  },
  address: {
    address: null,
    postalCode: null,
    code: 'cz',
    lat: null,
    lng: null,
    placeId: null,
    city: null,
  },
}

export default {
  data() {
    return {
      errors: [],
      defaultValues: {},
      formItems: [],
      defaultFormItems: {
        nameOptions: {},
        controllerOptions: {},
        weeklyScheduleOptions: {},
      },
      presets: [],
      form: { ...JSON.parse(JSON.stringify(defaultData)) },
      showAddress: false,
      showContactInfo: false,
      showTimeSchedule: false,
    }
  },
  methods: {
    clear() {
      Vue.set(this, 'errors', [])
      Vue.set(this, 'defaultValues', [])
      Vue.set(this, 'formItems', [])
      Vue.set(this, 'presets', [])
      Vue.set(this.defaultFormItems, 'nameOptions', {})
      Vue.set(this.defaultFormItems, 'controllerOptions', {})
      this.defaultValuesId = null
    },
    reset() {
      this.clear()
      this.form = JSON.parse(JSON.stringify(defaultData))
      this.createFormByController(this.form.controllerId, true)
    },
    async createFormByController(controller, assignDefaults) {
      try {
        if (controller && controller !== '') {
          const emptyControllerErrIndex = this.errors.findIndex(
            (err) => err.error === 'is_empty' && err.name === 'controller',
          )
          if (emptyControllerErrIndex !== -1) {
            this.errors.splice(emptyControllerErrIndex, 1)
          }

          const prepareFormQuery = this.$apollo.query({
            query: prepareForm,
            variables: {
              controller,
              lang: this.$i18n.locale,
              companyId: this.$store.getters['user/currentCompanyId'],
            },
          })

          const controllerInfoQuery = this.$apollo.query({
            query: controllerInfo,
            variables: {
              controller,
            },
          })

          const [prepareFormResponse, controllerInfoResponse] = await Promise.all([
            prepareFormQuery,
            controllerInfoQuery,
          ])

          this.showAddress = controllerInfoResponse.data.controllerInfo.deviceTypeInfo.hasAddress
          this.showContactInfo =
            controllerInfoResponse.data.controllerInfo.deviceTypeInfo.hasContactInfo
          this.showTimeSchedule =
            controllerInfoResponse.data.controllerInfo.deviceTypeInfo.hasTimeSchedule

          if (this.serialNumberErrorIndex > -1) {
            this.errors.splice(this.serialNumberErrorIndex, 1)
          }

          if (prepareFormResponse.data.prepareCreateForm) {
            this.form.lang = this.$i18n.locale

            Vue.set(this, 'defaultValues', prepareFormResponse.data.prepareCreateForm.defaultValues)
            Vue.set(this, 'formItems', prepareFormResponse.data.prepareCreateForm.items)

            if (assignDefaults) {
              Vue.set(this.form, 'deviceData', JSON.parse(JSON.stringify(this.defaultValues)))
            }

            this.presets = prepareFormResponse.data.prepareCreateForm.presets
          }

          if (this.showTimeSchedule) {
            const weeklySchedulesResult = await this.$apollo.mutate({
              mutation: companyWeeklyTimeSchedulesGetOrCreate,
              variables: {
                companyId: this.$store.getters['user/currentCompanyId'],
                deviceType: prepareFormResponse.data.prepareCreateForm.deviceType,
              },
            })

            if (
              weeklySchedulesResult?.data?.companyWeeklyTimeSchedulesGetOrCreate.weeklyTimeSchedules
            ) {
              const weeklyScheduleOptions = weeklySchedulesResult.data.companyWeeklyTimeSchedulesGetOrCreate.weeklyTimeSchedules.map(
                (template) => ({
                  id: template.id,
                  value: template.id,
                  label: template.name,
                }),
              )
              Vue.set(this.defaultFormItems, 'weeklyScheduleOptions', weeklyScheduleOptions)
              Vue.set(this.form, 'defaultTimeScheduleId', weeklyScheduleOptions[0].id) // hot fix - there was always preselected first option but field was actually empty - probably some problem with SelectInput component
            }
          }
        } else {
          this.clear()
          this.errors.push({
            name: 'controller',
            error: 'is_empty',
          })
        }
      } catch (error) {
        const parsedError = error.message?.replace('GraphQL error:', '').trim()
        if (parsedError === 'PREPARE_CREATE_FORM_THING_DOES_NOT_EXIST') {
          this.clear()
          this.errors.push({
            name: 'controller',
            error: 'invalid_serial',
          })
        } else {
          console.error(error)
        }
      }
    },
    async saveDevice(propagate) {
      Vue.set(this, 'errors', [])

      const clientMutationId = 'deviceCreate'
      const saveResult = await this.$apollo.mutate({
        mutation: createDevice,
        variables: {
          data: {
            lang: this.form.lang,

            controller: this.form.controllerId,
            name: this.form.name,

            data: JSON.stringify(this.form.deviceData),
            propagate,

            ...(this.showContactInfo && {
              contactInfo: {
                name: this.form.contactInfo.name,
                email: this.form.contactInfo.email,
                phone: this.form.contactInfo.phone,
                note: this.form.contactInfo.note,
              },
            }),
            ...(this.showAddress && {
              address: {
                ...this.form.address,
                lat: this.form.address?.lat ? parseFloat(this.form.address?.lat) : null,
                lng: this.form.address?.lng ? parseFloat(this.form.address?.lng) : null,
              },
            }),

            companyId: this.$store.getters['user/currentCompanyId'],

            defaultTimeScheduleId: this.form.defaultTimeScheduleId,
          },
          clientMutationId,
        },
      })

      if (saveResult.data.deviceCreate.status) {
        this.reset()

        return true
      } else {
        Vue.set(this, 'errors', saveResult.data.deviceCreate.errors)
      }

      return false
    },
  },
  computed: {
    serialNumberErrorIndex() {
      return this.errors.findIndex((err) => err.error === 'invalid_serial')
    },
  },
  watch: {
    formItems(newValue) {
      this.$emit('form-changed', !newValue.length)
    },
  },
}
