import gql from 'graphql-tag'

export const deviceUpdateAdmin = gql`
  mutation deviceUpdateAdmin($deviceId: ID!, $companyId: ID!, $controller: NonEmptyString!) {
    deviceUpdateAdmin(
      input: { deviceId: $deviceId, companyId: $companyId, controller: $controller }
    ) {
      success
    }
  }
`
