import gql from 'graphql-tag'

export const demoTokens = gql`
  query demoTokens($deviceId: ID!) {
    device(deviceId: $deviceId) {
      id
      demoTokens {
        id
        name
        token
        expiresAt
        accessExpirationMinutes
      }
    }
  }
`
