import gql from 'graphql-tag'

export const companyForContact = gql`
  query companyForContact($companyId: ID!) {
    company(companyId: $companyId) {
      id
      name
      defaultLanguage
      contactEmailSubject
      contactEmailBody
    }
  }
`

export const companyContactEmails = gql`
  query companyContactEmails(
    $companyId: ID!
    $withContactEmailAddresses: Boolean!
    $withEmailsForEventNotifications: Boolean!
  ) {
    company(companyId: $companyId) {
      id
      contactEmailAddresses @include(if: $withContactEmailAddresses)
      emailsForEventNotifications @include(if: $withEmailsForEventNotifications)
      users {
        id
        email
      }
    }
  }
`
