import formatDuration from 'date-fns/formatDuration'
import { dateLocales } from '@/config/dateFnsLocales'

export function formatMinutes(minutesDelay) {
  if (minutesDelay === 0) {
    return formatDuration({ minutes: 0 }, { locale: dateLocales[this.$i18n.locale], zero: true })
  }
  const days = Math.floor(minutesDelay / 1440)
  const hours = Math.floor((minutesDelay - days * 1440) / 60)
  const minutes = minutesDelay % 60
  return formatDuration({ days, hours, minutes }, { locale: dateLocales[this.$i18n.locale] })
}
