/**
 * Checks if device has error of some severity.
 * @param {object} device
 * @param {string} severity
 * @returns {boolean}
 */
export function hasErrorOfSeverity(device, severity) {
  return (
    device.eventDetectionPoints &&
    device.eventDetectionPoints.findIndex(
      (eop) => eop.eventCodes.findIndex((ec) => ec.severity === severity) !== -1,
    ) !== -1
  )
}
