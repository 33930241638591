import gql from 'graphql-tag'

export const companyEventCodeUpdateNotificationClass = gql`
  mutation companyEventCodeUpdateNotificationClass(
    $companyId: ID!
    $eventCodeId: ID!
    $notificationClassId: ID!
  ) {
    companyEventCodeUpdateNotificationClass(
      input: {
        companyId: $companyId
        eventCodeId: $eventCodeId
        notificationClassId: $notificationClassId
      }
    ) {
      success
    }
  }
`
