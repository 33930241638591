<template>
  <div class="payment-type-badge">
    {{ $t(`global.serviceSequence`) }}
  </div>
</template>
<script>
export default {
  name: 'ServiceSequenceBadge',
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables';

.payment-type-badge {
  padding: 0 8px;
  height: 20px;
  line-height: 20px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  color: white;
  background-color: @color-brand-2;
}
</style>
