/**
 * Function will recursively set properties of initialObj to the value found in defaultObj. Extra properties in defaultObj will be ignored. Function mutates initialObj.
 * @param {*} initialObj
 * @param {*} defaultObj
 */
export const assignDefaults = (initialObj, defaultObj) => {
  for (const key in initialObj) {
    if (initialObj[key] instanceof Object) {
      assignDefaults(initialObj[key], defaultObj[key])
    } else {
      initialObj[key] = defaultObj[key]
    }
  }
}
