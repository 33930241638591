<template>
  <div
    class="demo-token-tile-wrapper"
    :class="{
      expired: isExpired,
    }"
  >
    <div class="actions-wrapper">
      <v-btn v-if="!isExpired" icon @click="qrModalOpen = true">
        <v-icon>$qr</v-icon>
      </v-btn>
      <v-btn class="mr-1" icon @click="updateDemoTokenModalOpen = true">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </div>
    <p class="name mb-2">
      {{ displayText }}
    </p>
    <p class="value">{{ demoToken.name }}</p>

    <v-dialog v-model="updateDemoTokenModalOpen" width="unset">
      <v-card>
        <div class="pa-6">
          <div class="mb-7 d-flex align-center">
            <h2>{{ $t('global.updateDemoTokenModal.headline') }}</h2>
            <v-btn
              @click=";(updateDemoTokenModalOpen = false), (qrModalOpen = true)"
              icon
              class="ml-2"
            >
              <v-icon>$qr</v-icon>
            </v-btn>
          </div>
          <DemoTokenForm ref="demoTokenForm" />
          <div class="mt-8 d-flex justify-space-between">
            <v-btn @click="confirmDeleteModalOpen = true" color="error" depressed>{{
              $t('global.delete')
            }}</v-btn>
            <div>
              <v-btn @click="updateDemoTokenModalOpen = false" depressed>{{
                $t('global.cancel')
              }}</v-btn>
              <v-btn @click="updateDemoToken()" color="primary" class="ml-3" depressed>{{
                $t('global.save')
              }}</v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <ConfirmationDialog
      v-model="confirmDeleteModalOpen"
      :heading="$t('global.demoTokenDeleteConfirmation.headline')"
      :text="$t('global.demoTokenDeleteConfirmation.text', { tokenName: demoToken.name })"
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
      :action="deleteDemoToken"
    />

    <v-dialog v-model="qrModalOpen" max-width="400">
      <v-card>
        <v-card-title class="qr-modal-headline flex-column align-start">
          <h1>{{ demoToken.name }}</h1>
          <p>{{ validUntil }}</p>
        </v-card-title>
        <v-card-text class="pb-5 px-15">
          <vue-qrcode :value="demoToken.token" :width="400" :margin="4" />
        </v-card-text>
        <v-card-actions class="flex-wrap">
          <v-btn color="primary" @click="downloadPdf" depressed :block="showBlockButtons"
            ><v-icon class="mr-2">mdi-download</v-icon>Download pdf</v-btn
          >
          <div class="flex-grow-1 text-right" :class="{ 'mt-1': showBlockButtons }">
            <v-btn
              @click=";(updateDemoTokenModalOpen = true), (qrModalOpen = false)"
              depressed
              class="mr-2"
              :class="{ 'mb-1': showBlockButtons }"
              :block="showBlockButtons"
            >
              {{ $t('global.edit') }}
            </v-btn>
            <v-btn color="primary" @click="qrModalOpen = false" depressed :block="showBlockButtons">
              {{ $t('global.close') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DemoTokenForm from '@/components/DeviceDetail/DemoTokenForm'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import VueQrcode from 'vue-qrcode'
import { demoTokenUpdate } from '@/graphql/mutations/demoTokenUpdate'
import { demoTokenDelete } from '@/graphql/mutations/demoTokenDelete'
import format from 'date-fns/format'

export default {
  name: 'DemoTokenTile',
  components: { DemoTokenForm, VueQrcode, ConfirmationDialog },
  props: {
    demoToken: Object,
    device: Object,
  },
  data() {
    return {
      updateDemoTokenModalOpen: false,
      confirmDeleteModalOpen: false,
      qrModalOpen: false,
    }
  },
  methods: {
    async updateDemoToken() {
      this.$refs.demoTokenForm.$v.$touch()
      if (!this.$refs.demoTokenForm.$v.$invalid) {
        try {
          await this.$apollo.mutate({
            mutation: demoTokenUpdate,
            variables: {
              data: {
                demoTokenId: this.demoToken.id,
                ...this.$refs.demoTokenForm.getValue(),
              },
            },
            refetchQueries: ['demoTokens'],
          })

          this.$toast.success(this.$t('global.updateDemoTokenModal.updateDemoTokenAction.success'))
          this.updateDemoTokenModalOpen = false
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.updateDemoTokenModal.updateDemoTokenAction.failure'))
        }
      }
    },
    async deleteDemoToken() {
      try {
        await this.$apollo.mutate({
          mutation: demoTokenDelete,
          variables: {
            demoTokenId: this.demoToken.id,
          },
          refetchQueries: ['demoTokens'],
        })

        this.$toast.success(this.$t('global.updateDemoTokenModal.deleteDemoTokenAction.success'))
        this.confirmDeleteModalOpen = false
        this.updateDemoTokenModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.updateDemoTokenModal.deleteDemoTokenAction.failure'))
      }
    },
    async downloadPdf() {
      const pdfMake = (await import('pdfmake/build/pdfmake')).default
      const pdfFonts = (await import('pdfmake/build/vfs_fonts')).default
      pdfMake.vfs = pdfFonts.pdfMake.vfs

      const pdfDocGenerator = pdfMake.createPdf({
        content: [
          {
            text: `${this.$t('global.demoQrPrintTitle')} - ${this.demoToken.name}`,
            style: {
              fontSize: 18,
              bold: true,
            },
          },
          {
            text: this.validUntil,
            margin: [0, 0, 0, 20],
          },
          {
            qr: this.demoToken.token,
            style: {
              alignment: 'center',
            },
          },
        ],
      })
      pdfDocGenerator.getBlob((blob) => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `Demo QR code - ${this.demoToken.name}` // file name
        link.click()
        URL.revokeObjectURL(link.href)
      })
    },
  },
  computed: {
    isExpired() {
      return this.demoToken.expiresAt && new Date(this.demoToken.expiresAt) < new Date()
    },
    displayText() {
      if (this.demoToken.expiresAt == null) {
        return this.$t('global.demoTokenTile.token')
      }
      return `${this.$t('global.demoTokenTile.token')} ${
        this.isExpired
          ? `(${this.$t('global.demoTokenTile.expired')})`
          : `(${this.$t('global.demoTokenTile.tokenValidUntil')} ${format(
              new Date(this.demoToken.expiresAt),
              'd.M.Y, H:mm',
            )})`
      }`
    },
    validUntil() {
      return this.demoToken.expiresAt == null
        ? this.$t('global.demoTokenTile.neverExpires')
        : `${this.$t('global.demoTokenTile.tokenValidUntil')} ${format(
            new Date(this.demoToken.expiresAt),
            'd.M.Y, H:mm',
          )}`
    },
    showBlockButtons() {
      return this.$vuetify.breakpoint.name === 'xs'
    },
  },
  watch: {
    updateDemoTokenModalOpen() {
      if (this.updateDemoTokenModalOpen) {
        this.$nextTick(() => {
          this.$refs.demoTokenForm.setValue({
            name: this.demoToken.name,
            expiresAt: this.demoToken.expiresAt ? new Date(this.demoToken.expiresAt) : null,
            accessExpirationMinutes: this.demoToken.accessExpirationMinutes,
          })
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.demo-token-tile-wrapper {
  position: relative;
  background-color: fade(@color-brand-highlight, 50%);
  border-radius: 3px;
  padding: 14px 16px 16px 16px;

  p {
    margin-bottom: 0;
  }

  .actions-wrapper {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .name {
    font-size: 12px;
    color: @color-brand-2;
    opacity: 0.5;
  }

  .value {
    font-size: 14px;
    font-weight: bold;
    color: @color-brand-2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.expired {
    background-color: #ffeeee;
  }
}

.qr-modal-headline {
  line-height: unset;
  h1 {
    font-size: 18px;
    font-weight: 500;
  }

  p {
    font-size: 10px;
    opacity: 0.5;
  }
}
</style>
