import gql from 'graphql-tag'

export const deviceAccessRequestAccept = gql`
  mutation deviceAccessRequestAccept($token: NonEmptyString!, $lang: String) {
    deviceAccessRequestAccept(input: { token: $token, lang: $lang }) {
      newUserDeviceAccess {
        id
        customDeviceName
        user {
          id
          email
        }
      }
    }
  }
`
