<template>
  <v-dialog :value="isOpen" @input="$emit('modalToggle', $event)" width="unset" max-height>
    <div class="wrapper">
      <h1 class="mb-5">{{ $t('global.wifiCredentials') }}</h1>
      <div v-if="$apollo.queries.deviceWifiCredentials.loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <dl v-else-if="deviceWifiCredentials">
        <div class="d-flex">
          <dt>{{ $t('global.ssid') }}</dt>
          <dd>{{ deviceWifiCredentials.ssid }}</dd>
        </div>
        <div class="d-flex mb-5">
          <dt>{{ $t('login.password') }}</dt>
          <dd>{{ deviceWifiCredentials.password }}</dd>
        </div>
        <dt>{{ $t('global.wifiQrCode') }}</dt>
        <dd><vue-qrcode :value="qrData" :width="400" :margin="4" /></dd>
      </dl>
      <v-alert v-else outlined type="error">
        {{ $t('global.wifiCredentialsNotFound') }}
      </v-alert>
    </div>
  </v-dialog>
</template>
<script>
import { deviceWifiCredentials } from '@/graphql/query/deviceWifiCredentials'
import VueQrcode from 'vue-qrcode'

export default {
  name: 'WifiPasswordModal',
  components: { VueQrcode },
  model: {
    prop: 'isOpen',
    event: 'modalToggle',
  },
  props: {
    isOpen: Boolean,
    deviceId: String,
  },
  data() {
    return {}
  },
  apollo: {
    deviceWifiCredentials: {
      query: deviceWifiCredentials,
      variables() {
        return {
          deviceId: this.deviceId,
        }
      },
      skip() {
        return !this.isOpen
      },
      update(data) {
        return data.device.wifiCredentials
      },
    },
  },
  computed: {
    qrData() {
      if (!this.deviceWifiCredentials) {
        return null
      }
      return `WIFI:S:${this.deviceWifiCredentials.ssid};T:WPA;P:${this.deviceWifiCredentials.password};;`
    },
  },
}
</script>
<style lang="less" scoped>
.wrapper {
  background-color: white;
  padding: 15px;

  h1 {
    font-size: 24px;
    font-weight: 500;
  }

  dl {
    font-size: 16px;

    dt {
      font-weight: 700;
      margin-right: 5px;
      flex-shrink: 0;
      display: inline-block;

      &::after {
        content: ':';
      }
    }
  }
}
</style>
