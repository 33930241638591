import gql from 'graphql-tag'

export const serviceSequenceConfig = gql`
  query serviceSequenceConfig(
    $deviceId: ID!
    $serviceSequenceName: NonEmptyString!
    $lang: AcceptedLanguageEnum
  ) {
    device(deviceId: $deviceId) {
      id
      liveDeviceData {
        serviceSequenceConfig: getServiceSequenceConfig(
          serviceSequenceName: $serviceSequenceName
          lang: $lang
        ) {
          name
          modalWidth
          modalHeight
          mobileLayoutBreakpoint
          hasLiveData
          requiredEligibility
          components
          statesConfig
          globalAbort
        }
      }
    }
  }
`
