const state = {
  currentUser: null,
  currentUserCompanyId: undefined,
  loginError: null,
  devices: null,
}

const getters = {
  currentCompanyId: (state) => state.currentUserCompanyId,
  currentUser: (state) => state.currentUser,
  loginError: (state) => state.loginError,
  currentCompanyPermissions: (state) =>
    state.currentUser?.companies.find((company) => company.id === state.currentUserCompanyId)
      ?.userPermissions || {},
  currentCompanyName: (state) =>
    state.currentUser?.companies.find((company) => company.id === state.currentUserCompanyId)
      ?.name || null,
  currentUserId: (state) => state.currentUser.id,
  usersDevices: (state) => state.devices,
  availableDeviceTypes: (state) =>
    state.currentUserCompanyId === null
      ? state.currentUser?.deviceTypes.map((dt) => dt.toLowerCase())
      : state.currentUser?.companies
          ?.find((c) => c.id === state.currentUserCompanyId)
          ?.deviceTypesAvailableToUser.map((dt) => dt.toLowerCase()),
  currentCompanyGeneralEligibilities: (state) =>
    state.currentUserCompanyId === null
      ? undefined
      : state.currentUser?.companies?.find((c) => c.id === state.currentUserCompanyId)
          ?.generalEligibilities,
  currentUserCompanies: (state) => state.currentUser?.companies,
}

const actions = {
  logout({ commit }) {
    commit('removeCurrentUserCompanyId')
    commit('removeCurrentUser')
  },
}

const mutations = {
  setCurrentUserCompanyId(state, companyId) {
    state.currentUserCompanyId = companyId
  },
  removeCurrentUserCompanyId(state) {
    state.currentUserCompanyId = undefined
  },
  setCurrentUser(state, payload) {
    state.currentUser = payload.user
  },
  updateCurrentUserData(state, newUserData) {
    state.currentUser = {
      ...state.currentUser,
      ...newUserData,
    }
  },
  removeCurrentUser(state) {
    state.currentUser = null
  },
  setLoginError(state, loginError) {
    state.loginError = loginError
  },
  addCompany(state, newCompany) {
    state.currentUser = {
      ...state.currentUser,
      companies: [...state.currentUser.companies, newCompany],
    }
  },
  updateCurrentPermissions(state, newPermissions) {
    const currentPermissionIndex = state.currentUser?.companies.findIndex(
      (company) => company.id === state.currentUserCompanyId,
    )
    if (currentPermissionIndex != null && currentPermissionIndex !== -1) {
      state.currentUser.companies[currentPermissionIndex].userPermissions = {
        ...state.currentUser.companies[currentPermissionIndex].userPermissions,
        ...newPermissions,
      }
    }
  },
  setUsersDevices(state, newDevices) {
    state.devices = newDevices
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
