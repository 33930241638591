<template>
  <v-dialog :value="isOpen" @input="$emit('modalToggle', $event)" width="unset">
    <v-card>
      <div class="pa-6">
        <h2 class="mb-6">{{ $t('global.eventCodeDetail.updateEventCodeModal.heading') }}</h2>
        <EventCodeForm ref="eventCodeForm" />
        <div class="mt-8 text-right">
          <v-btn @click="$emit('modalToggle', false)" depressed>{{ $t('global.close') }}</v-btn>
          <v-btn @click="updateEventCode()" color="primary" class="ml-3" depressed>{{
            $t('global.submit')
          }}</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import EventCodeForm from '@/components/Admin/ServiceHints/EventCodeForm'
import { eventCodeUpdate } from '@/graphql/mutations/eventCodeUpdate'
import { allowedLanguages } from '@/translations'

export default {
  name: 'UpdateEventCodeModal',
  components: { EventCodeForm },
  model: {
    prop: 'isOpen',
    event: 'modalToggle',
  },
  props: {
    isOpen: Boolean,
    eventCode: Object,
    refetchQueries: Array,
  },
  methods: {
    async updateEventCode() {
      this.$refs.eventCodeForm.$v.$touch()
      if (!this.$refs.eventCodeForm.$v.$invalid) {
        try {
          const nullInsteadOfEmptyString = (string) => (string?.trim() === '' ? null : string)
          const formData = this.$refs.eventCodeForm.form

          await this.$apollo.mutate({
            mutation: eventCodeUpdate,
            variables: {
              data: {
                eventCodeId: this.eventCode.id,
                ...formData,
                detectionMechanism: nullInsteadOfEmptyString(formData.detectionMechanism),
                terminationPoint: nullInsteadOfEmptyString(formData.terminationPoint),
                terminationMechanism: nullInsteadOfEmptyString(formData.terminationMechanism),
                actionUponDetection: nullInsteadOfEmptyString(formData.actionUponDetection),
                descriptionTranslations: Object.fromEntries(
                  allowedLanguages.map((lang) => [
                    lang,
                    nullInsteadOfEmptyString(formData.descriptionTranslations[lang]),
                  ]),
                ),
              },
            },
            refetchQueries: this.refetchQueries,
          })

          this.$toast.success(this.$t('global.eventCodeDetail.updateEventCodeAction.success'))
          this.$emit('modalToggle', false)
        } catch (error) {
          console.error(error)
          const parsedError = error.message?.replace('GraphQL error:', '').trim()
          this.$toast.error(
            parsedError === 'EVENT_CODE_UPDATE_NAME_TAKEN'
              ? this.$t('global.nameTakenError')
              : this.$t('global.eventCodeDetail.updateEventCodeAction.failure'),
          )
        }
      }
    },
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.$nextTick(() => {
          this.$refs.eventCodeForm.form = {
            name: this.eventCode.name,
            eventDetectionPointId: this.eventCode.detectionPoint?.id,
            severity: this.eventCode.severity,
            disabled: this.eventCode.disabled,
            defaultNotificationClass: this.eventCode.defaultNotificationClass.notificationClass,
            detectionMechanism: this.eventCode.detectionMechanism,
            terminationPoint: this.eventCode.terminationPoint,
            terminationMechanism: this.eventCode.terminationMechanism,
            actionUponDetection: this.eventCode.actionUponDetection,
            descriptionTranslations: Object.fromEntries(
              allowedLanguages.map((lang) => [lang, this.eventCode[`${lang}Description`]]),
            ),
          }
        })
      }
    },
  },
}
</script>
