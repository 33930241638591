import gql from 'graphql-tag'

export const deviceSetActiveWeeklyTimeSchedule = gql`
  mutation deviceSetActiveWeeklyTimeSchedule($deviceId: ID!, $weeklyTimeScheduleId: ID!) {
    deviceSetActiveWeeklyTimeSchedule(
      input: { deviceId: $deviceId, weeklyTimeScheduleId: $weeklyTimeScheduleId }
    ) {
      success
    }
  }
`
