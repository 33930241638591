<template>
  <div>
    <v-btn color="primary" class="mb-4" @click="addEligibilityModalOpen = true">
      {{ $t('global.addEligibility') }}
    </v-btn>
    <v-simple-table class="elevation-2" dense>
      <template v-slot:default>
        <thead>
          <th class="px-4 text-left">{{ $t('global.generalEligibility') }}</th>
          <th class="px-4 text-right">{{ $t('global.defaultValue') }}</th>
          <th class="px-4 text-right">{{ $t('global.defaultAvailability') }}</th>
          <th class="px-4 text-right">{{ $t('global.premium') }}</th>
          <th class="px-4 text-right">{{ $t('global.provisionType') }}</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="item in generalEligibilities" :key="item.name">
            <td class="py-2">
              <div class="d-flex flex-wrap" style="gap: 5px">
                <p class="mb-1 mr-2">{{ item.label }}</p>
                <PaymentTypeBadge :paymentType="item.provisionType" />
              </div>
              <p class="description">{{ item.description }}</p>
            </td>
            <td align="right">
              <component
                :is="chooseInputType(item.type)"
                :value="eligibilitiesInput[item.name].defaultValue"
                @input="valueChange(item.name, $event, 'defaultValue')"
                @hasErrors="handleError(`${item.name}.defaultValue`, $event)"
              ></component>
            </td>
            <td align="right">
              <v-select
                :items="availabilityOptions"
                v-model="eligibilitiesInput[item.name].defaultAvailability"
                item-text="text"
                item-value="value"
                @change="valueChange(item.name, $event, 'defaultAvailability')"
              ></v-select>
            </td>
            <td align="right">
              <component
                :is="chooseInputType(item.type)"
                :value="eligibilitiesInput[item.name].isPremiumDefault"
                @input="valueChange(item.name, $event, 'isPremiumDefault')"
                @hasErrors="handleError(`${item.name}.isPremiumDefault`, $event)"
              ></component>
            </td>
            <td align="right">
              <v-select
                :items="provisionTypes"
                v-model="eligibilitiesInput[item.name].provisionTypeDefault"
                item-text="text"
                item-value="value"
                @change="valueChange(item.name, $event, 'provisionTypeDefault')"
              ></v-select>
            </td>
            <td align="right" class="delete-col">
              <v-btn icon color="error" @click="openDeleteEligibilityConfirmationDialog(item.name)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="flex-shrink-0 mt-8">
      <v-btn
        color="primary"
        class="mr-5"
        @click="updateGeneralEligibilities()"
        :disabled="!hasUnsavedChanges"
        >{{ $t('global.save') }}</v-btn
      >
      <v-btn @click="reset()" :disabled="!hasUnsavedChanges">{{ $t('global.reset') }}</v-btn>
    </div>

    <v-dialog v-model="addEligibilityModalOpen" width="unset">
      <v-card>
        <div class="pa-6">
          <h2 class="mb-7">{{ $t('global.addEligibility') }}</h2>
          <v-form @submit.prevent="addEligibility">
            <v-text-field
              v-model="newEligibilityName"
              :error-messages="newEligibilityNameErrors"
              :label="$t('global.name')"
              required
              @input="$v.newEligibilityName.$touch()"
              @blur="$v.newEligibilityName.$touch()"
              filled
            ></v-text-field>
            <v-select
              v-model="newEligibilityType"
              :error-messages="newEligibilityTypeErrors"
              :items="eligibilityTypes"
              :label="$t('global.type')"
              filled
              style="width: 350px"
            ></v-select>
            <template v-if="newEligibilityType">
              <p class="mb-2">{{ $t('global.defaultValue') }}</p>
              <component
                :is="chooseInputType(newEligibilityType)"
                v-model="newDefaultValue"
                @hasErrors="defaultValueHasError = $event"
                class="mb-4"
              ></component>
              <p class="mb-2">{{ $t('global.defaultAvailability') }}</p>
              <v-select
                :items="availabilityOptions"
                v-model="newDefaultAvailability"
                item-text="text"
                item-value="value"
              ></v-select>
              <p class="mb-2">{{ $t('global.premium') }}</p>
              <component
                :is="chooseInputType(newEligibilityType)"
                v-model="newIsPremiumDefaultValue"
                @hasErrors="defaultValueHasError = $event"
              ></component>
              <p class="mb-2">{{ $t('global.provisionType') }}</p>
              <v-select
                :items="provisionTypes"
                v-model="newProvisionTypeDefaultValue"
                item-text="text"
                item-value="value"
                :label="$t('global.provisionType')"
                filled
              ></v-select>
            </template>
            <div class="mt-8 text-right">
              <v-btn @click="addEligibilityModalOpen = false" depressed>
                {{ $t('global.cancel') }}
              </v-btn>
              <v-btn type="submit" color="primary" class="ml-3" depressed>
                {{ $t('global.confirm') }}
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>

    <ConfirmationDialog
      v-if="eligibilityToDelete"
      v-model="confirmDeleteModalOpen"
      :heading="$t('global.eligibilityDeleteConfirmation.heading')"
      :text="$t('global.eligibilityDeleteConfirmation.text', { eligibility: eligibilityToDelete })"
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
      :action="deleteEligibility"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { generalEligibilities } from '@/graphql/query/generalEligibilities'
import { eligibilityTypeEnumValues } from '@/graphql/query/eligibilityTypeEnumValues'
import { generalEligibilitiesUpdateDefaultValues } from '@/graphql/mutations/generalEligibilitiesUpdateDefaultValues'
import { generalEligibilityCreate } from '@/graphql/mutations/generalEligibilityCreate'
import { eligibilityDelete } from '@/graphql/mutations/eligibilityDelete'
import isEqual from 'lodash/isEqual'
import BooleanEligibilityInput from '@/components/ProductCatalogue/EligibilitiesInputs/BooleanEligibilityInput'
import IntegerEligibilityInput from '@/components/ProductCatalogue/EligibilitiesInputs/IntegerEligibilityInput'
import HistoryEligibilityInput from '@/components/ProductCatalogue/EligibilitiesInputs/HistoryEligibilityInput'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import PaymentTypeBadge from '@/components/UI/PaymentTypeBadge'

export default {
  name: 'AdminGeneralEligibilities',
  mixins: [validationMixin],
  components: {
    BooleanEligibilityInput,
    IntegerEligibilityInput,
    HistoryEligibilityInput,
    ConfirmationDialog,
    PaymentTypeBadge,
  },
  data() {
    return {
      eligibilitiesInput: {},
      originalEligibilities: {},
      addEligibilityModalOpen: false,
      newEligibilityName: '',
      newEligibilityType: null,
      newDefaultValue: null,
      newDefaultAvailability: null,
      defaultValueHasError: false,
      errors: new Map(),
      confirmDeleteModalOpen: false,
      eligibilityToDelete: null,
      newIsPremiumDefaultValue: false,
      newProvisionTypeDefaultValue: null,
      provisionTypes: [
        { text: 'Subscription', value: 'SUBSCRIPTION' },
        { text: 'One-Off', value: 'ONE_OFF' },
      ],
      availabilityOptions: [
        { text: 'Available', value: 'available' },
        { text: 'Not Available', value: 'not_available' },
        { text: 'Optional', value: 'optional' },
      ],
    }
  },
  validations: {
    newEligibilityName: { required },
    newEligibilityType: { required },
  },
  apollo: {
    generalEligibilities: {
      query: generalEligibilities,
      variables() {
        return {
          lang: this.$i18n.locale,
        }
      },
      update(data) {
        this.eligibilitiesInput = data.eligibilitiesConfig.general.reduce((acc, cur) => {
          acc[cur.name] = {
            defaultValue: cur.defaultValue,
            defaultAvailability: cur.defaultAvailability,
            isPremiumDefault: cur.isPremium,
            provisionTypeDefault: cur.provisionType,
          }
          return acc
        }, {})
        this.originalEligibilities = JSON.parse(JSON.stringify(this.eligibilitiesInput))

        return data.eligibilitiesConfig.general
      },
    },
    eligibilityTypes: {
      query: eligibilityTypeEnumValues,
      update(data) {
        return data.__type.enumValues.map((value) => value.name)
      },
    },
  },
  methods: {
    async updateGeneralEligibilities() {
      if (!this.errors.size) {
        try {
          await this.$apollo.mutate({
            mutation: generalEligibilitiesUpdateDefaultValues,
            variables: {
              updateData: Object.entries(this.eligibilitiesInput).map(([name, defaultValues]) => ({
                name,
                defaultDefaultValueValue: ['INTEGER', 'HISTORY'].includes(
                  this.generalEligibilities.find((e) => e.name === name).type,
                )
                  ? Number(defaultValues.defaultValue)
                  : defaultValues.defaultValue,
                defaultDefaultAvailabilityValue: defaultValues.defaultAvailability,
                defaultIsPremiumValue: defaultValues.isPremiumDefault,
                defaultProvisionTypeValue: defaultValues.provisionTypeDefault,
              })),
            },
            refetchQueries: ['generalEligibilities'],
          })
          this.$toast.success(this.$t('global.generalEligibilitiesUpdateDefaultValues.success'))
          this.$store.commit('setHasUnsavedChanges', false)
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.generalEligibilitiesUpdateDefaultValues.failure'))
        }
      }
    },
    async addEligibility() {
      this.$v.$touch()
      if (!this.$v.$invalid && !this.defaultValueHasError) {
        try {
          await this.$apollo.mutate({
            mutation: generalEligibilityCreate,
            variables: {
              name: this.newEligibilityName,
              type: this.newEligibilityType,
              defaultDefaultValueValue: ['INTEGER', 'HISTORY'].includes(this.newEligibilityType)
                ? Number(this.newDefaultValue)
                : this.newDefaultValue,
              defaultDefaultAvailabilityValue: this.newDefaultAvailability,
              defaultIsPremiumValue: this.newIsPremiumDefaultValue,
              defaultProvisionTypeValue: this.newProvisionTypeDefaultValue,
            },
            refetchQueries: ['generalEligibilities'],
          })
          this.$toast.success(this.$t('global.deviceEligibilityCreate.success'))
          this.addEligibilityModalOpen = false
          this.newEligibilityName = ''
          this.newEligibilityType = null
          this.newDefaultValue = null
          this.newDefaultAvailability = null
          this.newIsPremiumDefaultValue = null
          this.newProvisionTypeDefaultValue = null
          this.$v.$reset()
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.deviceEligibilityCreate.failure'))
        }
      }
    },
    async deleteEligibility() {
      try {
        await this.$apollo.mutate({
          mutation: eligibilityDelete,
          variables: {
            name: this.eligibilityToDelete,
          },
          refetchQueries: ['generalEligibilities'],
        })
        this.$toast.success(this.$t('global.eligibilityDelete.success'))
        this.confirmDeleteModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.eligibilityDelete.failure'))
      }
    },
    valueChange(eligibilityName, newValue, valueName) {
      this.eligibilitiesInput[eligibilityName][valueName] = newValue
    },
    handleError(eligibilityName, hasError) {
      if (hasError && !this.errors.has(eligibilityName)) {
        this.errors.set(eligibilityName, true)
      } else if (!hasError && this.errors.has(eligibilityName)) {
        this.errors.delete(eligibilityName)
      }
    },
    openDeleteEligibilityConfirmationDialog(eligibility) {
      this.eligibilityToDelete = eligibility
      this.confirmDeleteModalOpen = true
    },
    chooseInputType(type) {
      const inputTypeMap = {
        INTEGER: IntegerEligibilityInput,
        BOOLEAN: BooleanEligibilityInput,
        HISTORY: HistoryEligibilityInput,
      }
      return inputTypeMap[type]
    },
    onBeforeUnload() {
      if (this.hasUnsavedChanges) {
        // modern browsers will ignore this message and will show default
        return this.$t('global.unsavedChanges')
      }
    },
    reset() {
      this.eligibilitiesInput = JSON.parse(JSON.stringify(this.originalEligibilities))
    },
  },
  computed: {
    ...mapGetters(['hasUnsavedChanges']),
    newEligibilityNameErrors() {
      const errors = []
      if (!this.$v.newEligibilityName.$dirty) {
        return errors
      }
      !this.$v.newEligibilityName.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
    newEligibilityTypeErrors() {
      const errors = []
      if (!this.$v.newEligibilityType.$dirty) {
        return errors
      }
      !this.$v.newEligibilityType.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
  },
  watch: {
    eligibilitiesInput: {
      deep: true,
      handler() {
        this.$store.commit(
          'setHasUnsavedChanges',
          !isEqual(this.eligibilitiesInput, this.originalEligibilities),
        )
      },
    },
    newEligibilityType() {
      const baseValueForType = {
        BOOLEAN: false,
        INTEGER: 0,
        HISTORY: 0,
      }

      this.newDefaultValue = baseValueForType[this.newEligibilityType]
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('global.unsavedChanges'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload
  },
  destroyed() {
    window.onbeforeunload = undefined
    this.$store.commit('setHasUnsavedChanges', false)
  },
}
</script>

<style lang="less" scoped>
.description {
  font-size: 12px;
  opacity: 0.75;
  margin-bottom: 0;
}

.delete-col {
  width: 75px;
}

th {
  font-size: 0.75rem;
  height: 48px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
