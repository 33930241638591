<template>
  <div class="form-main--row">
    <div class="form-main--column">
      <label class="form-main--label" :class="{ required }" :for="variableName">{{
        translatedLabel
      }}</label>
    </div>
    <div class="form-main--column">
      <!-- Komponenta -->
      <div class="form-select--wrapper" :class="{ error: inputErrors.length }">
        <!--                form-select--color-->
        <select ref="select" @input="updateValue($event.target.value)" :value="value" :id="variableName"
          class="form-select">
          <option v-for="choice in avaiableChoices" v-bind:key="choice.id" :value="choice.value"
            :selected="choice.value == value">
            {{ translatedChoiceLabel(choice.label) }}
          </option>
        </select>
        <svg class="form-select--icon" width="11" height="7">
          <use xlink:href="#icon-chevron-down"></use>
        </svg>
      </div>
      <p v-for="error in inputErrors" :key="`input_${name}_error_${error.error}`">
        {{ $t('device.validations.' + error.error) }}
      </p>
    </div>
  </div>
</template>

<script>
import BasicInput from '@/components/Form/Input/BasicInput'
import { replaceDeviceParametersLabels } from '@/utils/replaceDeviceParametersLabels';

export default {
  extends: BasicInput,
  name: 'SelectInput',
  props: {
    choices: {
      type: Array,
      required: false,
    },
  },
  methods: {
    translatedChoiceLabel: function (l) {
      return replaceDeviceParametersLabels(l, this.otherData, this.formItems);
    },
  },
  computed: {
    avaiableChoices: function () {
      return this.choices ? this.choices : this.options.choices
    },
  },
}
</script>
