export default {
  usersList: 'Benutzerliste',
  companyList: 'Liste der Serviceunternehmen',
  userDetail: 'Benutzerdetails',
  serviceCompanyDetail: 'Details zum Serviceunternehmen',
  addNewUser: 'Neuen Benutzer hinzufügen',
  addNewCompany: 'Neues Serviceunternehmen hinzufügen',
  userPermissions: 'Benutzerberechtigungen',
  serviceCompaniesPermissions: 'Berechtigungen für Serviceunternehmen',
  addUser: 'Benutzer/In hinzufügen',
  addCompany: 'Unternehmen hinzufügen',
  setPermissions: 'Berechtigungen festlegen',
  search: 'Suchen',
  permissions: {
    companyAdministration: 'Unternehmensadministration',
    companyUserManagement: 'Benutzerverwaltung',
    templateManagement: 'Vorlagen-Verwaltung',
    productCatalogue: 'Kunden und Produkte',
    otherCompaniesManagement: 'Verwalten anderer Unternehmen',
    configurationUpload: 'Neue Konfiguration hochlade',
    configurationChanges: 'Konfiguration bearbeiten',
    expertMode: 'Experten-Modus',
    deviceControl: 'Gerätesteuerung',
    operationalHistory: 'Betriebshistorie',
    fullCustomerData: 'Komplette Kundendaten',
    restrictedCustomerData: 'Eingeschränkte Kundendaten',
    userManagement: 'Verwaltung des Benutzerzugangs',
    serviceSequences: 'Servicesequenzen',
  },
  permissionsDescription: {
    companyAdministration: 'Unternehmenskonto-Verwaltung.',
    companyUserManagement:
      'Zugriff auf die Liste der Benutzer, erstellen und löschen Sie Benutzerkonten, setzen Sie Berechtigungen für andere Benutzer (außer Administratorkonten).',
    templateManagement:
      'Zugriff auf Unternehmensvorlagen, Erstellen, Aktualisieren und Löschen von Vorlagen.',
    productCatalogue: 'Zugang zum Produktkatalog.',
    otherCompaniesManagement: 'Anzeigen und Verwalten der Dinge von Partnerfirmen.',
    configurationUpload: 'Konfiguration auf das Gerät hochladen',
    configurationChanges: 'Geräte erstellen und bearbeiten, ohne sie auf das Gerät hochzuladen',
    expertMode: 'Zugriff auf den Expert-Modus.',
    deviceControl:
      'Das Gerät im Steuerungspanel steuern, Betriebsmodi ändern, außer im Expertenmodus',
    operationalHistory:
      'Zugriff auf die Gerätehistorie und technische Diagramme, Erstellen von Serviceaufzeichnungen und Notizen',
    fullCustomerData: 'Vollständige Kontaktinformationen anzeigen',
    restrictedCustomerData: 'Nur Kontaktstadt und Land anzeigen',
    userManagement:
      'Zugriff auf die Liste der Gerätebenutzer, Erstellen und Löschen von Gerätezugriffsrechten, Festlegen von Berechtigungen für andere Benutzer, Zugriff auf die Benachrichtigungseinstellungen',
    serviceSequences: 'Starten und Fortschritt der Service-Sequenz sehen.',
  },
  userDeleteSuccess: 'Der Benutzer wurde erfolgreich aus dem Unternehmen entfernt',
  userDeleteFailure: 'Fehler beim Entfernen des Benutzers aus der Firma.',
  serviceCompanyDeleteSuccess: 'Serviceunternehmen erfolgreich aus der Firma entfernt.',
  serviceCompanyDeleteFailure: 'Fehler beim Entfernen des Serviceunternehmens aus der Firma.',
  userSuccessfullyAdded: 'Der neue Benutzer wurde erfolgreich zum Unternehmen hinzugefügt.',
  userInviteUserAlreadyInvited: 'Benutzer wurde bereits eingeladen.',
  userInviteUserAlreadyAdded: 'Der Benutzer ist bereits im Unternehmen.',
  invitationSuccess: 'Einladung erfolgreich gesendet.',
  invitationFailure: 'Fehler beimVersenden der Einladung.',
  companyInviteNotAdminEmail: 'Die eingegebene E-Mail gehört keinem Firmenadministrator.',
  companyInvitationAlreadyInvited: 'Das Unternehmen wurde bereits eingeladen.',
  companyInvitationAlreadyAdded:
    'Das Unternehmen befindet sich bereits unter dem angegebenen Unternehmen.',
  companyInvitationCanNotInviteOwnCompany: 'Ein Unternehmen kann sich nicht selbst einladen.',
  userDeleteConfirmation: {
    heading: 'Benutzer aus dem Unternehmen löschen',
    text: 'Sind Sie sicher, dass Sie diesen Benutzer aus dem Unternehmen entfernen möchten?',
  },
  serviceCompanyDeleteConfirmation: {
    heading: 'Serviceunternehmen löschen',
    text: 'Sind Sie sicher, dass Sie dieses Serviceunternehmen aus Ihrem Unternehmen löschen möchten?',
  },
  userNotFound: 'Benutzer nicht gefunden.',
  addNewUserDescription:
    'Geben Sie die E-Mail des neuen Benutzers ein. Eine Einladung mit einem Aktivierungslink wird an die E-Mail-Adresse gesendet.',
  addNewCompanyDescription:
    'Geben Sie die E-Mail-Adresse des Administrators des Unternehmens ein, das Sie hinzufügen möchten. Eine Einladung mit einem Aktivierungslink wird an diese E-Mail-Adresse gesendet.',
  permissionsByUser: 'Berechtigungen eines bestehenden Benutzers benutzen',
  permissionsByCompany: 'Berechtigungen der des überwachenden Unternehmens benutzen',
  permissionsTitle: 'Berechtigungen',
  setPermissionsManually: 'Berechtigungen manuell festlegen',
  setPermissionsByUser: 'Berechtigungen eines vorhandenen Benutzers benutzen',
  setPermissionsByCompany: 'Berechtigungen eines bestehenden Unternehmens benutzen',
  sendInvitation: 'Einladung versenden',
  notEnabled: 'Der Benutzer hat die Einladung nicht angenommen.',
  notEnabledCompany: 'Das Unternehmen hat die Einladung noch nicht angenommen.',
  noMatchingRecordsFound: 'Keine passenden Datensätze gefunden.',
  noServiceCompanies: ' Keine Serviceunternehmen.',
  userAdminDeleteConfirmation: {
    heading: 'Benutzer löschen',
    text: 'Sind Sie sicher, diesen Benutzer ({email}) aus der Firma zu entfernen)?',
  },
  userAdminDeleteSuccess: 'Benutzer erfolgreich entfernt.',
  userAdminDeleteFailure: 'Fehler beim Entfernen des Benutzers.',
}
