<template>
  <v-app>
    <div id="app">
      <router-view class="app"></router-view>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'app',
  created() {
    this.$i18n.locale = this.$store.state.locale
  },
  watch: {
    '$store.state.locale'() {
      this.$i18n.locale = this.$store.state.locale
    },
  },
}
</script>

<style scoped lang="less">
.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
