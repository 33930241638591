var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.isOpen,"max-width":"1000","max-height":""},on:{"input":function($event){return _vm.$emit('modalToggle', $event)}}},[_c('div',{staticClass:"wrapper"},[_c('h1',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t('global.troubleshooting')))]),_vm._l((_vm.eventDetectionPoints),function(eventDetectionPoint,index){return _c('div',{key:index,staticClass:"detection-point"},[_c('h2',{staticClass:"mb-3"},[_vm._v(_vm._s(eventDetectionPoint.name))]),_c('div',_vm._l((_vm.getSortedEventCodes(eventDetectionPoint.eventCodes)),function(eventCode){return _c('div',{key:`${eventCode.description}|${eventCode.severity}`,staticClass:"d-flex align-center mb-3"},[_c('span',{staticClass:"datetime mr-4"},[_vm._v(_vm._s(_vm.formatDate(eventCode.timestamp)))]),_c('ReportBadge',{attrs:{"text":eventCode.description,"severity":eventCode.severity,"clickable":false,"deviceId":null}})],1)}),0),(
          _vm.permissionChecker([], _vm.eligibilities.EVENT_CORE_CAUSES) &&
          eventDetectionPoint.coreCauses.length
        )?_c('div',{staticClass:"mb-4 mt-4"},[_c('h3',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('global.possibleCauses')))]),_c('ol',_vm._l((eventDetectionPoint.coreCauses),function(coreCause,index){return _c('li',{key:index,staticClass:"mb-1"},[_vm._v(" "+_vm._s(coreCause)+" ")])}),0)]):_vm._e(),(
          _vm.permissionChecker([], _vm.eligibilities.EVENT_CORRECTIVE_ACTIONS) &&
          eventDetectionPoint.correctiveActions.length
        )?_c('div',{staticClass:"mt-4"},[_c('h3',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('global.correctiveMeassures')))]),_c('ol',_vm._l((eventDetectionPoint.correctiveActions),function(correctiveAction,index){return _c('li',{key:index,staticClass:"mb-1"},[_vm._v(" "+_vm._s(correctiveAction)+" ")])}),0)]):_vm._e()])}),_c('div',{staticClass:"mt-5 text-right"},[_c('v-btn',{attrs:{"height":"27","depressed":""},on:{"click":function($event){return _vm.$emit('modalToggle', false)}}},[_vm._v(_vm._s(_vm.$t('global.close')))])],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }