import gql from 'graphql-tag'

export const getNewProduct = gql`
  query newProduct($productId: ID!, $lang: NonEmptyString) {
    newProduct(productId: $productId, lang: $lang) {
      name
      id
      eligibilities
      deviceId
      template {
        id
        name
      }
      companyId
      deviceId
    }
  }
`
