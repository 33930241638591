import { render, staticRenderFns } from "./ProductDetailPage_new.vue?vue&type=template&id=bd72850c&scoped=true"
import script from "./ProductDetailPage_new.vue?vue&type=script&lang=js"
export * from "./ProductDetailPage_new.vue?vue&type=script&lang=js"
import style0 from "./ProductDetailPage_new.vue?vue&type=style&index=0&id=bd72850c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd72850c",
  null
  
)

export default component.exports