import gql from 'graphql-tag'

export const serviceSequenceState = gql`
  query serviceSequenceState($serviceSequenceName: NonEmptyString!, $deviceId: ID!) {
    device(deviceId: $deviceId) {
      id
      connectionStatus
      liveDeviceData {
        serviceSequenceState: getServiceSequenceState(serviceSequenceName: $serviceSequenceName)
        serviceSequenceLiveData: getServiceSequenceLiveData(
          serviceSequenceName: $serviceSequenceName
        )
      }
    }
  }
`
