import gql from 'graphql-tag'

export const dailyTimeScheduleCreate = gql`
  mutation dailyTimeScheduleCreate($input: DailyTimeScheduleCreateInput!) {
    dailyTimeScheduleCreate(input: $input) {
      dailyTimeSchedule {
        id
        name
        schedule
        isGlobal
        isUsed
      }
    }
  }
`

export const dailyTimeScheduleUpdate = gql`
  mutation dailyTimeScheduleUpdate($input: DailyTimeScheduleUpdateInput!) {
    dailyTimeScheduleUpdate(input: $input) {
      success
      errorMessage
      deviceNames
    }
  }
`

export const dailyTimeScheduleDelete = gql`
  mutation dailyTimeScheduleDelete($dailyScheduleId: ID!) {
    dailyTimeScheduleDelete(input: { dailyScheduleId: $dailyScheduleId }) {
      success
    }
  }
`
