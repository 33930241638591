<template>
  <div
    class="menu-wrapper"
    :class="{ mobile: showMobileMenu }"
    v-click-outside="handleClickOutside"
  >
    <button
      v-if="currentCompanyPermissions[permissions.CONFIGURATION_CHANGES]"
      @click="newDeviceModal = true"
      color="primary"
      class="add-device-btn"
    >
      <span>{{ $t('device.addDevice') }}</span>
      <span v-if="formInProgress" class="inProgress">({{ $t('device.addDeviceInProgress') }})</span>
    </button>
    <div class="flex-grow-1">
      <template v-for="deviceType of availableDeviceTypesForMenu">
        <div
          :key="deviceType.name"
          @click="$emit('update:showMobileMenu', false)"
          class="menu-link-wrapper"
        >
          <router-link
            :to="{ name: 'DeviceListPage', params: { type: deviceType.name } }"
            class="menu-link"
          >
            <v-icon>{{ deviceType.icon }}</v-icon>
            <span>{{ $t(deviceType.label) }}</span>
          </router-link>
        </div>
      </template>
      <div @click="$emit('update:showMobileMenu', false)" class="menu-link-wrapper">
        <router-link
          v-if="currentCompanyId"
          :to="{ name: 'CompanyManagementPage' }"
          class="menu-link"
        >
          <v-icon>$building</v-icon>
          <span>{{ $t('global.menu.companyManagement') }}</span>
        </router-link>
        <div
          v-if="Boolean($route.matched.find((route) => route.name === 'CompanyManagementPage'))"
          class="secondary-menu"
        >
          <router-link :to="{ name: 'CompanyUsersListPage' }" class="secondary-menu-link">
            {{ $t('global.companyManagementMenu.users') }}
          </router-link>
          <router-link
            :to="{ name: 'CompanyServiceCompaniesListPage' }"
            class="secondary-menu-link"
          >
            {{ $t('global.companyManagementMenu.serviceCompanies') }}
          </router-link>
          <router-link
            v-if="
              currentCompanyPermissions[permissions.COMPANY_ADMINISTRATION] &&
              (currentCompanyGeneralEligibilities[eligibilities.NOTIFICATION_DISPATCHER] ||
                currentCompanyGeneralEligibilities[eligibilities.ORDER_SERVICE_FROM_APP] ||
                currentCompanyGeneralEligibilities[eligibilities.ORDER_SERVICE_FROM_APP_FROM_EVENT])
            "
            :to="{ name: 'CompanyNotificationsPage' }"
            class="secondary-menu-link"
          >
            {{ $t('global.companyManagementMenu.notifications') }}
          </router-link>
          <router-link
            v-if="currentCompanyPermissions[permissions.COMPANY_ADMINISTRATION]"
            :to="{ name: 'CompanySettingsPage' }"
            class="secondary-menu-link"
          >
            {{ $t('global.companyManagementMenu.settings') }}
          </router-link>
        </div>
      </div>
      <div @click="$emit('update:showMobileMenu', false)" class="menu-link-wrapper">
        <router-link
          v-if="
            currentCompanyId &&
            currentCompanyPermissions[permissions.TEMPLATE_MANAGEMENT] &&
            availableDeviceTypes.length
          "
          :to="{ name: 'TemplatesPage' }"
          class="menu-link"
        >
          <v-icon>$paper</v-icon>
          <span>{{ $t('global.menu.templates') }}</span>
        </router-link>
      </div>
      <div @click="$emit('update:showMobileMenu', false)" class="menu-link-wrapper">
        <router-link
          v-if="currentCompanyId && currentCompanyPermissions[permissions.BASE_PRODUCT_CATALOGUE]"
          :to="{
            name: 'NewProductCataloguePage',
          }"
          class="menu-link"
        >
          <v-icon>mdi-book-account-outline</v-icon>
          <span>{{ $t('global.menu.productCatalogue') }}</span>
        </router-link>
      </div>
      <div
        v-if="currentCompanyId && currentCompanyPermissions[permissions.SUPER_ADMIN]"
        @click="$emit('update:showMobileMenu', false)"
        class="menu-link-wrapper"
      >
        <router-link :to="{ name: 'AdminConsolePage' }" class="menu-link">
          <v-icon>mdi-account-cog</v-icon>
          <span>{{ $t('global.menu.admin') }}</span>
        </router-link>
        <div
          v-if="Boolean($route.matched.find((route) => route.name === 'AdminConsolePage'))"
          class="secondary-menu"
        >
          <router-link :to="{ name: 'AdminUsers' }" class="secondary-menu-link">
            {{ $t('global.adminConsoleMenu.users') }}
          </router-link>
          <router-link :to="{ name: 'AdminDevices' }" class="secondary-menu-link">
            {{ $t('global.adminConsoleMenu.devices') }}
          </router-link>
          <router-link :to="{ name: 'AdminThingTypes' }" class="secondary-menu-link">
            {{ $t('global.adminConsoleMenu.thingTypes') }}
          </router-link>
          <router-link :to="{ name: 'AdminProductCatalogue' }" class="secondary-menu-link">
            {{ $t('global.adminConsoleMenu.productCatalogue') }}
          </router-link>
          <router-link :to="{ name: 'AdminCompanies' }" class="secondary-menu-link">
            {{ $t('global.adminConsoleMenu.companies') }}
          </router-link>
          <router-link :to="{ name: 'AdminServiceHints' }" class="secondary-menu-link">
            {{ $t('global.adminConsoleMenu.serviceHints') }}
          </router-link>
          <router-link
            :to="{ name: 'AdminNotificationDelayConfiguration' }"
            class="secondary-menu-link"
          >
            {{ $t('global.adminConsoleMenu.notificationDispatcher') }}
          </router-link>
          <router-link :to="{ name: 'AdminCrowdin' }" class="secondary-menu-link">
            {{ $t('global.crowdin.crowdin') }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="menu-bottom">
      <div @click="$emit('update:showMobileMenu', false)" class="menu-link-wrapper">
        <router-link
          v-if="currentCompanyPermissions[permissions.COMPANY_CREATE_INVITE]"
          :to="{ name: 'CompanyInvitePage' }"
          class="menu-link"
        >
          <v-icon>mdi-domain-plus</v-icon>
          <span>{{ $t('global.menu.inviteCompany') }}</span>
        </router-link>
      </div>
      <LocaleSelect id="locale-select" />
    </div>
    <img src="@/assets/images/co-funded.png" alt="Eledio" class="co-funded-img" />
    <DeviceNewModal
      @form-changed="formInProgress = !$event"
      :show="newDeviceModal"
      @close="newDeviceModal = false"
    ></DeviceNewModal>
  </div>
</template>

<script>
import DeviceNewModal from '@/components/DeviceDetail/DeviceNewModal'
import LocaleSelect from '@/components/LocaleSelect'
import { mapGetters } from 'vuex'
import { permissions } from '@/config/permissions'
import { eligibilities } from '@/config/eligibilities'
import { deviceTypes } from '@/config/deviceTypes'
import { allowedLanguages } from '@/translations'

export default {
  name: 'Menu',
  components: { DeviceNewModal, LocaleSelect },
  props: {
    showMobileMenu: Boolean,
  },
  data: function () {
    return {
      newDeviceModal: false,
      formInProgress: false,
      shouldCloseMenuOnclickOutside: false, // we need this because we can not use showMobileMenu for the condition to click outside handler
    }
  },
  methods: {
    handleClickOutside() {
      if (this.shouldCloseMenuOnclickOutside) {
        this.$emit('update:showMobileMenu', false)
      }
    },
  },
  computed: {
    ...mapGetters('user', [
      'currentCompanyPermissions',
      'currentCompanyId',
      'availableDeviceTypes',
      'currentCompanyGeneralEligibilities',
    ]),
    permissions() {
      return permissions
    },
    eligibilities() {
      return eligibilities
    },
    localeOptions() {
      return allowedLanguages.map((locale) => ({
        text: this.$t(`global.lang.${locale}`),
        value: locale,
      }))
    },
    availableDeviceTypesForMenu() {
      return this.availableDeviceTypes?.reduce((acc, cur) => {
        if (deviceTypes[cur]) {
          acc.push({
            name: cur,
            ...deviceTypes[cur],
          })
        }
        return acc
      }, [])
    },
  },
  watch: {
    showMobileMenu() {
      if (this.showMobileMenu) {
        setTimeout(() => {
          this.shouldCloseMenuOnclickOutside = true
        }, 10) // little delay to not immediately close the menu
      } else {
        this.shouldCloseMenuOnclickOutside = false
      }
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

.menu-wrapper {
  display: flex;
  flex-direction: column;
  flex: 0 0 169px;
  border-right: solid 1px #eeeeee;
  padding: 16px;
  overflow: auto;

  #locale-select {
    display: none;
  }

  @media (max-width: 767px) {
    display: none;

    &.mobile {
      display: flex;
      position: absolute;
      background-color: white;
      top: 56px;
      right: 0;
      z-index: @z-mobile-menu-layer;
      box-shadow: 0 0.063rem 0.188rem rgba(0, 0, 0, 0.14);
      overflow: auto;
      width: 100%;

      .co-funded-img {
        display: none;
      }
      .menu-bottom {
        margin-top: 20px;
      }

      #locale-select {
        display: block;
      }
    }
  }

  @media (max-width: 484px) {
    &.mobile {
      top: 97px;
    }
  }

  .add-device-btn {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    font-size: 0.875rem;
    font-weight: 700;
    box-shadow: 0 0.375rem 0.5rem rgba(2, 36, 67, 0.16);
    border-radius: 0.188rem;
    background: @color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    border: solid 1px @color-primary;
    padding: 10px;
    margin-bottom: 30px;

    .inProgress {
      font-size: smaller;
      font-weight: 300;
    }
  }

  .menu-link-wrapper {
    margin-bottom: 15px;
  }

  .menu-link {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    padding: 9px;
    border-radius: 0.188rem;
    transition: all 300ms ease;

    &:hover {
      text-decoration: none;
      color: @color-primary;
    }
    &.router-link-active {
      color: @color-primary;
      background: rgba(31, 151, 255, 0.12);
    }
    .v-icon {
      color: inherit;
      margin-right: 7px;
    }
  }

  .secondary-menu {
    display: flex;
    flex-direction: column;
    padding: 9px 9px 0px 9px;

    a {
      font-size: 13px;
      padding: 2px 0px;

      &:hover {
        text-decoration: none;
        color: @color-primary;
      }

      &.router-link-active {
        color: @color-primary;
        font-weight: 500;
      }
    }
  }
}
</style>
