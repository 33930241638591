import gql from 'graphql-tag'

export const companyDevicesWithProduct = gql`
  query companyDevices($companyId: ID!) {
    company(companyId: $companyId) {
      devices {
        edges {
          node {
            id
            name
            controller
            thingType {
              name
            }
            newProduct {
              id
              companyId
              name
              template {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`
