import gql from 'graphql-tag'
import { AllPermissions } from '../fragments/allPermissions'

export const me = gql`
  query me {
    me {
      id
      firstName
      lastName
      email
      phoneNumber
      deviceTypes
      companies {
        id
        name
        userPermissions {
          ...AllPermissions
        }
        deviceTypesAvailableToUser
        generalEligibilities
      }
    }
  }
  ${AllPermissions}
`
