import gql from 'graphql-tag'

export const companyThingTypes = gql`
  query companyThingTypes($companyId: ID!) {
    company(companyId: $companyId) {
      id
      thingTypes {
        name
        businessName
        thingTypeFamilyId
      }
    }
  }
`
