import gql from 'graphql-tag'

export const deviceSetEventNotifications = gql`
  mutation deviceSetEventNotifications(
    $deviceId: ID!
    $pushNotifications: Boolean
    $phoneNumbers: [PhoneNumber!]
    $emailAddresses: [EmailAddress!]
  ) {
    deviceSetEventNotifications(
      input: {
        deviceId: $deviceId
        pushNotifications: $pushNotifications
        phoneNumbers: $phoneNumbers
        emailAddresses: $emailAddresses
      }
    ) {
      success
    }
  }
`
