<template>
  <div class="pt-15 px-10 d-flex justify-center">
    <p>{{ $t('global.noDevicesText') }}</p>
  </div>
</template>
<script>
export default {
  name: 'NoDevicesPage',
}
</script>
<style lang="less" scoped>
p {
  font-size: 18px;
  font-weight: 500;
}
</style>
