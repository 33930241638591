<template>
  <div
    class="wrapper"
    :class="{
      unavailable: isUnavailable,
      'forbidden-by-user': control.forbiddenByUser && !isUnavailable,
      dosing: isDosing,
    }"
  >
    <!-- Disable forbidden by user for now -->
    <!-- <v-dialog v-if="!isUnavailable && canEdit" v-model="isEditDialogOpen" max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon class="edit-btn" v-bind="attrs" v-on="on">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline">{{ control.label }}</v-card-title>
        <v-card-text>
          <v-checkbox
            class="mt-3"
            v-model="currentForbiddenByUserValue"
            :label="$t('device.forbidden')"
            hide-details
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="isEditDialogOpen = false">
            {{ $t('global.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="setForbiddenByUser(), (isEditDialogOpen = false)"
          >
            {{ $t('global.set') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <div class="title-wrapper mt-2">
      <v-icon v-if="control.icon" size="30">{{ `$${control.icon}` }}</v-icon>
      <p>{{ control.label }}</p>
    </div>
    <div v-if="isUnavailable" class="value-wrapper">
      <span>{{ $t('device.unavailable') }}</span>
    </div>
    <div v-else-if="control.forbiddenByUser" class="value-wrapper">
      <span>{{ $t('device.forbiddenByUser') }}</span>
    </div>
    <div v-else class="button-wrapper value-wrapper mr-2">
      <span>{{ isDosing ? $t('device.dosing') : $t('device.notDosing') }}</span>
      <v-btn
        v-if="!isDosing"
        @click="sendClickAction()"
        :disabled="!canEdit"
        color="primary"
        dense
        depressed
        height="27"
      >
        <v-icon v-if="control.buttonIcon" left color="white" class="ml-1">{{
          `$${control.buttonIcon}`
        }}</v-icon>
        {{ control.buttonText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  deviceControl,
  deviceControlUpdateForbiddenByUser,
} from '@/graphql/mutations/deviceControl'

export default {
  name: 'ToggleControl',
  props: {
    control: Object,
    deviceId: String,
    isOffline: Boolean,
    canEdit: Boolean,
  },
  data() {
    return {
      currentForbiddenByUserValue: this.control.forbiddenByUser,
      isEditDialogOpen: false,
      isSetting: false,
      isDosing: false, // todo get somewhere from shadow
    }
  },
  computed: {
    isUnavailable() {
      return this.isOffline || this.control.forbiddenByTechnology
    },
  },
  methods: {
    async sendClickAction() {
      try {
        await this.$apollo.mutate({
          mutation: deviceControl,
          variables: {
            data: {
              deviceId: this.deviceId,
              name: this.control.name,
            },
          },
        })

        // temporary hardcoded isDosing state - will be determined by shadow
        this.isDosing = true
        setTimeout(() => {
          this.isDosing = false
        }, 5000)

        this.$emit('controlChanged')
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('device.controlUpdateFail'))
      }
    },
    async setForbiddenByUser() {
      if (this.currentForbiddenByUserValue !== this.control.forbiddenByUser) {
        this.isSetting = true
        try {
          await this.$apollo.mutate({
            mutation: deviceControlUpdateForbiddenByUser,
            variables: {
              deviceId: this.deviceId,
              controlName: this.control.name,
              forbiddenByUser: this.currentForbiddenByUserValue,
            },
          })

          this.$emit('controlChanged')
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('device.controlUpdateFail'))
          this.currentForbiddenByUserValue = this.control.forbiddenByUser
        }
        this.isSetting = false
      }
    },
  },
  watch: {
    isEditDialogOpen() {
      if (!this.isEditDialogOpen && !this.isSetting) {
        this.currentForbiddenByUserValue = this.control.forbiddenByUser
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.wrapper {
  position: relative;
  background-color: fade(@color-brand-highlight, 50%);
  border-radius: 3px;
  padding: 5px 5px 13px 18px;

  .title-wrapper {
    display: flex;
    align-items: center;

    .v-icon {
      margin-right: 16px;
    }

    p {
      font-size: 12px;
      opacity: 0.5;
      color: @color-brand-2;
      margin-bottom: 0;
    }
  }

  .edit-btn {
    float: right;

    i {
      color: @color-brand !important;
    }
  }

  &.unavailable {
    background-color: fade(#ebebeb, 50%);

    .v-icon {
      color: gray;
    }

    p,
    span {
      color: gray;
    }
  }

  &.forbidden-by-user {
    background-color: fade(@color-danger, 20%);

    .v-icon {
      color: @color-danger;
    }

    p,
    span {
      color: @color-danger;
    }
  }

  .value-wrapper {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    font-weight: bold;

    span {
      font-size: 14px;
    }
  }

  .button-wrapper {
    .v-btn {
      padding: 0 6px;
    }

    span {
      font-weight: bold;
      color: @color-brand;
    }
  }

  &.dosing {
    background-color: @color-brand-highlight;

    .button-wrapper span {
      color: @color-brand-2;
    }
  }
}
</style>
