<template>
  <v-simple-checkbox
    :value="value"
    @input="$emit('input', $event)"
    color="primary"
    :ripple="false"
    v-bind="options"
  ></v-simple-checkbox>
</template>
<script>
export default {
  name: 'BooleanEligibilityInput',
  props: {
    value: Boolean,
    options: Object,
  },
}
</script>
