import gql from 'graphql-tag'
import { ImageData } from '../fragments/imageData'

export const deviceImagesCheckUpload = gql`
  mutation deviceImagesCheckUpload($imageIds: [ID!]!) {
    deviceImagesCheckUpload(input: { imageIds: $imageIds }) {
      images {
        ...ImageData
      }
    }
  }
  ${ImageData}
`
