import gql from 'graphql-tag'

export const eventCorrectiveActionsAdmin = gql`
  query eventCorrectiveActionsAdmin($lang: String) {
    eventCorrectiveActionsAdmin {
      id
      name
      description(lang: $lang)
    }
  }
`
