import Vue from 'vue'
import { prepareFormForDevice } from '@/graphql/query/form'
import { deviceTypeInfo } from '@/graphql/query/deviceTypeInfo'
import { updateDevice } from '@/graphql/mutations/device'
import { mapGetters } from 'vuex'
import produce from 'immer'

export default {
  data() {
    return {
      errors: [],
      defaultValues: {},
      formItems: [],
      defaultFormItems: {
        nameOptions: {},
      },
      form: {
        name: '',
        lang: '',
        deviceData: {},
        contactInfo: {
          name: '',
          email: '',
          phone: '',
          note: '',
        },
        address: {
          address: null,
          postalCode: null,
          code: null,
          lat: null,
          lng: null,
          placeId: null,
          city: null,
        },
      },
      showAddress: false,
      showContactInfo: false,
    }
  },
  methods: {
    async updateFormByDeviceId(deviceId, lang, includeContactInfoAndAddress, assignDefaults) {
      const prepareFormQuery = this.$apollo.query({
        query: prepareFormForDevice,
        variables: {
          deviceId,
          lang,
          includeContactInfoAndAddress,
        },
        fetchPolicy: 'network-only',
      })

      const deviceTypeInfoQuery = this.$apollo.query({
        query: deviceTypeInfo,
        variables: {
          deviceId,
        },
      })

      const [prepareFormResponse, deviceTypeInfoResponse] = await Promise.all([
        prepareFormQuery,
        deviceTypeInfoQuery,
      ])

      this.showAddress = deviceTypeInfoResponse.data.device.typeInfo.hasAddress
      this.showContactInfo = deviceTypeInfoResponse.data.device.typeInfo.hasContactInfo

      if (prepareFormResponse.data.prepareUpdateForm) {
        this.form.lang = lang
        Vue.set(this, 'defaultValues', prepareFormResponse.data.prepareUpdateForm.defaultValues)

        Vue.set(this, 'formItems', prepareFormResponse.data.prepareUpdateForm.items)

        this.form.name = prepareFormResponse.data.device.name

        if (assignDefaults) {
          Vue.set(this.form, 'deviceData', {
            ...JSON.parse(JSON.stringify(this.defaultValues)),
            ...prepareFormResponse.data.device.liveDeviceData.parameters,
          })
        }

        if (prepareFormResponse.data.device.contactInfo) {
          this.form.contactInfo.name = prepareFormResponse.data.device.contactInfo.name
          this.form.contactInfo.email = prepareFormResponse.data.device.contactInfo.email
          this.form.contactInfo.phone = prepareFormResponse.data.device.contactInfo.phone
          this.form.contactInfo.note = prepareFormResponse.data.device.contactInfo.note
        }

        if (prepareFormResponse.data.device.address) {
          Vue.set(this.form, 'address', {
            address: prepareFormResponse.data.device.address.address,
            postalCode:
              prepareFormResponse.data.device.address.postalCode === ''
                ? null
                : prepareFormResponse.data.device.address.postalCode,
            code: prepareFormResponse.data.device.address.code,
            lat: prepareFormResponse.data.device.address.lat,
            lng: prepareFormResponse.data.device.address.lng,
            placeId: prepareFormResponse.data.device.address.placeId,
            city: prepareFormResponse.data.device.address.city,
          })
        }
      }
    },
    removeAllErrorValues() {
      for (let ref in ['name', 'contactName', 'contactEmail', 'contactPhone', 'contactNote']) {
        if (this.$refs[ref]) {
          this.$refs[ref].setError(null)
        }
      }
      for (let input of this.$refs.inputs) {
        input.setError(null)
      }
    },
    async saveDevice(propagate, updateDeviceAndAddress) {
      // this.removeAllErrorValues();
      Vue.set(this, 'errors', [])

      const clientMutationId = 'deviceCreate'
      const saveResult = await this.$apollo.mutate({
        mutation: updateDevice,
        variables: {
          data: {
            deviceId: this.deviceId,
            lang: this.form.lang,

            name: this.form.name,

            data: JSON.stringify(this.form.deviceData),
            propagate,

            contactInfo:
              updateDeviceAndAddress && this.showContactInfo ? this.form.contactInfo : null,

            address:
              updateDeviceAndAddress && this.showAddress
                ? {
                    ...this.form.address,
                    lat: this.form.address?.lat ? parseFloat(this.form.address?.lat) : null,
                    lng: this.form.address?.lng ? parseFloat(this.form.address?.lng) : null,
                  }
                : null,
          },
          clientMutationId,
        },
      })

      // update name in device panel - TODO find some better solution states of devices in panel also won't update
      this.$store.commit(
        'user/setUsersDevices',
        produce(this.usersDevices, (draft) => {
          const updatedDeviceIndex = draft.findIndex((device) => device.id === this.deviceId)
          draft[updatedDeviceIndex] = {
            ...draft[updatedDeviceIndex],
            name: this.form.name,
          }
        }),
      )

      if (saveResult.data.updateDevice.status) {
        return true
      } else {
        Vue.set(this, 'errors', saveResult.data.updateDevice.errors)
      }

      return false
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyPermissions', 'usersDevices']),
  },
}
