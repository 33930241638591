import gql from 'graphql-tag'

export const deviceImagesCreate = gql`
  mutation deviceImagesCreate($deviceId: ID!, $images: [ImageInput]!) {
    deviceImagesCreate(input: { deviceId: $deviceId, images: $images }) {
      images {
        id
        uploadUrl
      }
    }
  }
`
