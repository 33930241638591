import gql from 'graphql-tag'

export const serviceOrderCreate = gql`
  mutation serviceOrderCreate(
    $text: String
    $deviceId: ID!
    $status: ServiceOrderStatusEnum!
    $assigneeId: ID
  ) {
    serviceOrderCreate(
      input: { text: $text, deviceId: $deviceId, status: $status, assigneeId: $assigneeId }
    ) {
      success
    }
  }
`
