import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import { rootState } from './modules/root'

import user from './modules/user'

Vue.use(Vuex)

const vueLocalStorage = new VuexPersist({
  reducer: (state) => ({
    locale: state.locale,
    user: {
      loginError: state.user.loginError,
      currentUserCompanyId: state.user.currentUserCompanyId,
    },
  }),
})

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    user,
  },
  ...rootState,
  strict: debug,
  plugins: [vueLocalStorage.plugin],
})
