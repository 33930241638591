import gql from 'graphql-tag'

export const companyAddServiceCompany = gql`
  mutation companyAddServiceCompany($childCompanyId: ID!, $parentCompanyId: ID!) {
    companyAddServiceCompany(
      input: { childCompanyId: $childCompanyId, parentCompanyId: $parentCompanyId }
    ) {
      success
    }
  }
`
