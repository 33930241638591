<template>
  <v-form>
    <v-text-field
      v-model="form.name"
      :error-messages="nameErrors"
      :label="$t('global.name')"
      @input="$v.form.name.$touch()"
      @blur="$v.form.name.$touch()"
      filled
      style="width: 330px"
    ></v-text-field>
    <v-radio-group
      v-model="form.doesTokenExpire"
      :label="$t('global.demoTokenForm.tokenExpiration')"
      class="token-expiration-radio"
    >
      <v-radio :value="true">
        <template v-slot:label>
          <v-datetime-picker
            ref="dateTimePicker"
            :label="$t('global.demoTokenForm.expirationDate')"
            v-model="form.expiresAt"
            :clearText="$t('global.clear')"
            :okText="$t('global.save')"
            :textFieldProps="{
              filled: true,
              dense: true,
              errorMessages: expiresAtErrors,
              class: 'date-time-text-field',
            }"
            :datePickerProps="{
              locale: $i18n.locale,
              'first-day-of-week': 1,
              class: 'date-time-picker-wrapper',
            }"
            :timePickerProps="{ format: '24hr', class: 'date-time-picker-wrapper' }"
            dateFormat="d.M.yyyy"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker>
        </template>
      </v-radio>
      <v-radio :value="false" :label="$t('global.demoTokenForm.neverExpires')"> </v-radio>
    </v-radio-group>
    <v-radio-group
      v-model="form.doesAccessExpire"
      :label="$t('global.demoTokenForm.accessExpiration')"
      class="access-expiration-radio"
    >
      <v-radio :value="true">
        <template v-slot:label>
          <DurationInput
            v-model="form.accessExpirationMinutes"
            @input="$v.form.accessExpirationMinutes.$touch()"
            @hasError="durationHasError = $event"
            :errorMessages="accessExpirationMinutesErrors"
          />
        </template>
      </v-radio>
      <v-radio :value="false" :label="$t('global.demoTokenForm.neverExpires')"> </v-radio>
    </v-radio-group>
  </v-form>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, minValue } from 'vuelidate/lib/validators'
import DurationInput from '@/components/Form/Input/DurationInput'

export default {
  name: 'DemoTokenForm',
  mixins: [validationMixin],
  components: { DurationInput },
  data() {
    return {
      form: {
        name: null,
        expiresAt: null,
        accessExpirationMinutes: null,
        doesTokenExpire: true,
        doesAccessExpire: true,
      },
      durationHasError: false,
      eventTarget: null,
    }
  },
  validations() {
    return {
      form: {
        name: { required },
        ...(this.form.doesTokenExpire && {
          expiresAt: { required },
        }),
        ...(this.form.doesAccessExpire && {
          accessExpirationMinutes: {
            required,
            minValue: minValue(1),
            internalError: () => !this.durationHasError,
          },
        }),
      },
    }
  },
  mounted() {
    // fix: when clicking label of text-field for date-time-picker, picker was not opening
    this.eventTarget = document.querySelector('.date-time-text-field .v-text-field__slot')
    this.eventTarget.addEventListener('click', this.clickTextFieldListener)
  },
  beforeDestroy() {
    this.eventTarget.removeEventListener('click', this.clickTextFieldListener)
  },
  methods: {
    reset() {
      // called from parent
      this.form = {
        name: null,
        expiresAt: null,
        accessExpirationMinutes: null,
        doesTokenExpire: true,
        doesAccessExpire: true,
      }
      this.$refs.dateTimePicker.clearHandler() // date time picker would remain populated without this https://github.com/darrenfang/vuetify-datetime-picker/issues/98
      this.$v.$reset()
    },
    setValue(value) {
      // called from parent
      this.form = {
        name: value.name,
        expiresAt: value.expiresAt,
        accessExpirationMinutes: value.accessExpirationMinutes,
        doesTokenExpire: Boolean(value.expiresAt),
        doesAccessExpire: Boolean(value.accessExpirationMinutes),
      }

      if (value.expiresAt == null) {
        this.$refs.dateTimePicker.clearHandler()
      }
    },
    getValue() {
      // called from parent
      return {
        name: this.form.name,
        expiresAt: this.form.doesTokenExpire ? this.form.expiresAt : null,
        accessExpirationMinutes: this.form.doesAccessExpire
          ? this.form.accessExpirationMinutes
          : null,
      }
    },
    clickTextFieldListener() {
      document.querySelector('.date-time-text-field input').click()
    },
  },
  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.form.name.$dirty) {
        return errors
      }
      !this.$v.form.name.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
    expiresAtErrors() {
      const errors = []
      if (!this.form.doesTokenExpire || !this.$v.form.expiresAt.$dirty) {
        return errors
      }
      !this.$v.form.expiresAt.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
    accessExpirationMinutesErrors() {
      const errors = []
      if (!this.form.doesAccessExpire || !this.$v.form.accessExpirationMinutes.$dirty) {
        return errors
      }
      !this.$v.form.accessExpirationMinutes.required &&
        errors.push(this.$t('global.formValidation.required'))
      !this.$v.form.accessExpirationMinutes.minValue &&
        errors.push(this.$t('global.formValidation.min', { min: 1 }))
      return errors
    },
  },
  watch: {
    'form.expiresAt'() {
      if (this.form.expiresAt) {
        this.form.doesTokenExpire = true
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables';

.token-expiration-radio {
  /deep/.v-radio:first-of-type .v-input--selection-controls__input {
    margin-bottom: 22px;
  }
}

.access-expiration-radio {
  /deep/.v-radio:first-of-type .v-input--selection-controls__input {
    margin-bottom: 10px;
  }
}
</style>
<style lang="less">
.date-time-picker-wrapper .v-picker__title {
  border-radius: 0 !important;
}
</style>
