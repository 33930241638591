<template>
  <div class="wrapper">
    <div v-if="$apollo.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else-if="!images.length" class="no-images">
      <p>{{ $t('device.gallery.noPhotodocumentation') }}</p>
      <div
        v-if="permissionChecker(permissions.CONFIGURATION_CHANGES, eligibilities.GALLERY_EDIT)"
        class="d-flex"
      >
        <v-btn
          color="primary"
          @click="$refs.fileInput.click()"
          :loading="isUploadingImages"
          :disabled="isUploadingImages"
          >{{ $t('device.gallery.addPhotos') }}</v-btn
        >
        <input
          ref="fileInput"
          type="file"
          accept=".jpg,.jpeg,.png,.heif,.heic"
          multiple
          @change="uploadImages"
          hidden
        />
        <template v-if="isMobileDevice">
          <v-btn
            color="primary"
            class="ml-3"
            @click="$refs.fileInputMobile.click()"
            :loading="isUploadingImages"
            :disabled="isUploadingImages"
            >{{ $t('device.gallery.takePhoto') }}</v-btn
          >
          <input
            ref="fileInputMobile"
            type="file"
            accept=".jpg,.jpeg,.png,.heif,.heic"
            multiple
            capture="camera"
            @change="uploadImages"
            hidden
          />
        </template>
      </div>
    </div>
    <div v-else>
      <div
        v-if="permissionChecker(permissions.CONFIGURATION_CHANGES, eligibilities.GALLERY_EDIT)"
        class="d-flex mb-7"
      >
        <v-btn
          color="primary"
          @click="$refs.fileInput.click()"
          :loading="isUploadingImages"
          :disabled="isUploadingImages"
          >{{ $t('device.gallery.addPhotos') }}</v-btn
        >
        <input
          ref="fileInput"
          type="file"
          accept=".jpg,.jpeg,.png,.heif,.heic"
          multiple
          @change="uploadImages"
          hidden
        />
        <template v-if="isMobileDevice">
          <v-btn
            color="primary"
            class="ml-3"
            @click="$refs.fileInputMobile.click()"
            :loading="isUploadingImages"
            :disabled="isUploadingImages"
            >{{ $t('device.gallery.takePhoto') }}</v-btn
          >
          <input
            ref="fileInputMobile"
            type="file"
            accept=".jpg,.jpeg,.png,.heif,.heic"
            multiple
            capture="camera"
            @change="uploadImages"
            hidden
          />
        </template>
      </div>
      <div v-for="{ dateTaken, images } of groupedImages" :key="dateTaken" class="gallery-row">
        <p class="date mb-3">{{ dateTaken }}</p>
        <div class="thumbnails">
          <v-img
            v-for="image of images"
            :key="image.id"
            :src="image.url"
            aspect-ratio="1"
            @click="openPreview(image.id)"
            style="cursor: pointer"
          ></v-img>
        </div>
      </div>
    </div>
    <ImagePreviewModal
      v-if="isPreviewOpen"
      v-model="isPreviewOpen"
      :images="sortedImages"
      :openIndex="previewIndex"
      :deviceId="device.id"
      :canEdit="permissionChecker(permissions.CONFIGURATION_CHANGES, eligibilities.GALLERY_EDIT)"
    />
  </div>
</template>
<script>
import { deviceImagesCreate } from '@/graphql/mutations/deviceImagesCreate'
import { deviceImagesCheckUpload } from '@/graphql/mutations/deviceImagesCheckUpload'
import { deviceImages } from '@/graphql/query/device'
import { mapGetters } from 'vuex'
import { permissions } from '@/config/permissions'
import { eligibilities } from '@/config/eligibilities'
import format from 'date-fns/format'
import { dateLocales } from '@/config/dateFnsLocales'
import ImagePreviewModal from '@/components/DeviceDetail/ImagePreviewModal'
import produce from 'immer'
import exifr from 'exifr'
import Compressor from 'compressorjs'
import axios from 'axios'
import Worker from 'worker-loader!./../../../utils/heifToJpeg.worker.js'

export default {
  name: 'GalleryTab',
  components: { ImagePreviewModal },
  props: {
    device: Object,
    permissionChecker: Function,
  },
  data() {
    return {
      isPreviewOpen: false,
      previewIndex: null,
      workers: [],
    }
  },
  apollo: {
    images: {
      query: deviceImages,
      variables() {
        return {
          deviceId: this.device.id,
        }
      },
      update(data) {
        return data.device.images
      },
    },
  },
  methods: {
    async compressImage(file) {
      const extension = file.name.split('.').pop().toLowerCase()
      // heif heic
      if (extension === 'heif' || extension === 'heic') {
        const worker = new Worker()
        this.workers.push(worker)
        const buffer = Buffer.from(await file.arrayBuffer())
        return new Promise((resolve, reject) => {
          worker.onmessage = (e) => {
            if (e.data.error) {
              reject(e.data.error)
            } else {
              resolve(e.data)
            }
            worker.terminate()
          }
          worker.onerror = (err) => {
            reject(err)
          }
          worker.postMessage(buffer)
        })
      }
      // jpg png
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.6,
          success(result) {
            resolve(result)
          },
          error(err) {
            reject(err)
          },
        })
      })
    },
    async parseAndCreateImages(files) {
      const options = {
        exif: { pick: ['DateTimeOriginal'] },
        gps: { pick: ['GPSLatitude', 'GPSLongitude'] },
      }
      const imageData = []
      const parsedData = await Promise.all(files.map((file) => exifr.parse(file, options)))
      files.forEach((file, index) => {
        imageData.push({
          fileName: file.name,
          dateTaken: parsedData[index]?.DateTimeOriginal,
          lat: parsedData[index]?.latitude,
          lng: parsedData[index]?.longitude,
        })
      })

      const result = await this.$apollo.mutate({
        mutation: deviceImagesCreate,
        variables: {
          deviceId: this.device.id,
          images: imageData,
        },
      })

      return result.data.deviceImagesCreate.images
    },
    async uploadToS3(url, file) {
      try {
        await axios.put(url, file)
      } catch (error) {
        console.error(error)
      }
    },
    async uploadImages(event) {
      this.$store.commit('setIsUploadingImages', true)
      const filesArray = [...event.target.files]
      if (
        filesArray.some((file) => {
          const extension = file.name.split('.').pop().toLowerCase()
          return !['jpg', 'jpeg', 'png', 'heif', 'heic'].includes(extension)
        })
      ) {
        this.$toast.error(this.$t('device.gallery.imageUploadUnsupportedFormat'))
      } else {
        try {
          const [imagesCreateResult, ...compressedImages] = await Promise.all([
            this.parseAndCreateImages(filesArray),
            ...filesArray.map((file) => this.compressImage(file)),
          ])

          await Promise.all(
            imagesCreateResult.map((image, index) =>
              this.uploadToS3(image.uploadUrl, compressedImages[index]),
            ),
          )

          await this.$apollo.mutate({
            mutation: deviceImagesCheckUpload,
            variables: {
              imageIds: imagesCreateResult.map((image) => image.id),
            },
            update: (store, response) => {
              const query = {
                query: deviceImages,
                variables: {
                  deviceId: this.device.id,
                },
              }
              const data = store.readQuery(query)
              const newData = produce(data, (draft) => {
                draft.device.images.push(...response.data.deviceImagesCheckUpload.images)
              })
              store.writeQuery({
                ...query,
                data: newData,
              })

              const failedCount =
                filesArray.length - response.data.deviceImagesCheckUpload.images?.length
              if (failedCount === 0) {
                this.$toast.success(this.$t('device.gallery.imageUploadSuccess'))
              } else {
                this.$toast.error(
                  this.$t('device.gallery.imageUploadPartiallyFailed', {
                    failedCount,
                    totalCount: filesArray.length,
                  }),
                )
              }
            },
          })
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('device.gallery.imageUploadFailed'))
        }
      }
      this.$store.commit('setIsUploadingImages', false)
      this.$refs.fileInput.value = null
    },
    openPreview(imageId) {
      this.previewIndex = this.sortedImages.findIndex((image) => image.id === imageId)
      this.isPreviewOpen = true
    },
    onBeforeUnload() {
      if (this.isUploadingImages) {
        // modern browsers will ignore this message and will show default
        return this.$t('global.unsavedChanges')
      }
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    ...mapGetters(['isUploadingImages']),
    permissions() {
      return permissions
    },
    eligibilities() {
      return eligibilities
    },
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    },
    groupedImages() {
      return this.sortedImages
        ? this.sortedImages.reduce((acc, image) => {
            const photoTaken = format(new Date(image.takenAt || image.uploadedAt), 'd. LLLL y', {
              locale: dateLocales[this.$i18n.locale],
            })
            const targetGroup = acc.find((group) => group.dateTaken === photoTaken)
            if (targetGroup) {
              targetGroup.images.push(image)
            } else {
              acc.push({
                dateTaken: photoTaken,
                images: [image],
              })
            }
            return acc
          }, [])
        : null
    },
    sortedImages() {
      return this.images
        ? JSON.parse(JSON.stringify(this.images)).sort(
            (a, b) => new Date(b.takenAt || b.uploadedAt) - new Date(a.takenAt || a.uploadedAt),
          )
        : null
    },
  },
  watch: {
    $route() {
      this.workers.forEach((worker) => {
        worker.terminate()
      })
      this.$store.commit('setIsUploadingImages', false)
    },
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload
  },
  destroyed() {
    this.workers.forEach((worker) => {
      worker.terminate()
    })
    window.onbeforeunload = undefined
    this.$store.commit('setIsUploadingImages', false)
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.wrapper {
  .no-images {
    background-color: @color-tile-background;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 275px;
    width: 100%;

    p {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .upload-btns-wrapper {
    display: flex;
  }

  .gallery-row {
    background-color: @color-tile-background;
    padding: 20px;
    margin-bottom: 5px;

    .date {
      font-size: 12px;
      opacity: 0.5;
    }

    .thumbnails {
      display: grid;
      grid-template-columns: repeat(auto-fill, 140px);
      gap: 20px;
    }
  }
}
</style>
