import produce from 'immer'

export function normalizeAndSortTimeSchedule(timeSchedule) {
  return produce(timeSchedule, (draft) => {
    for (const name in draft) {
      // Assigning IDs to each interval
      draft[name] = draft[name].map((interval, index) => ({
        ...interval,
        id: index,
      }))
      // Sorting based on the 'begin' property
      draft[name].sort((a, b) => a.begin - b.begin)
    }
  })
}

/** Function will merge consecutive intervals with equal state in time schedule. Expects sorted time schedule on input. */
export function mergeConsecutiveInTimeSchedule(timeSchedule) {
  return produce(timeSchedule, (draft) => {
    for (const name in draft) {
      draft[name] = []
      let intervalId = 0

      for (let i = 0, endIndex = 0; i < timeSchedule[name].length; i = ++endIndex) {
        while (
          endIndex < timeSchedule[name].length - 1 &&
          !timeSchedule[name][endIndex].wip &&
          !timeSchedule[name][endIndex + 1].wip &&
          timeSchedule[name][endIndex].end === timeSchedule[name][endIndex + 1].begin &&
          timeSchedule[name][endIndex].state === timeSchedule[name][endIndex + 1].state &&
          // Make sure we're not merging intervals that span over midnight
          !(
            timeSchedule[name][endIndex].end === 86400 &&
            timeSchedule[name][endIndex + 1].begin === 0
          )
        ) {
          endIndex++
        }

        draft[name].push({
          begin: timeSchedule[name][i].begin,
          end: timeSchedule[name][endIndex].end,
          state: timeSchedule[name][i].state,
          wip: timeSchedule[name][i].wip,
          id: intervalId++,
        })
      }
    }
  })
}

/** Function will check for overmidnightEntries and return flag which could be useful for updating data. */
export function checkAndFlagOverMidnightEntries(schedule) {
  let overMidnightFlag = false

  const updatedSchedule = produce(schedule, (draft) => {
    for (const name in draft) {
      const midnightEntryIndex = draft[name].findIndex((entry) => entry.begin > entry.end)
      if (midnightEntryIndex > -1) {
        overMidnightFlag = true
        draft[name].splice(
          midnightEntryIndex,
          1,
          {
            begin: 0,
            end: draft[name][midnightEntryIndex].end,
            state: draft[name][midnightEntryIndex].state,
          },
          {
            begin: draft[name][midnightEntryIndex].begin,
            end: 86400,
            state: draft[name][midnightEntryIndex].state,
          },
        )
      }
    }
  })

  return { overMidnightFlag, updatedSchedule }
}

export function cleanTimeScheduleFromIds(timeSchedule) {
  return produce(timeSchedule, (draft) => {
    for (const name in draft) {
      draft[name] = draft[name].map((interval) => ({
        begin: interval.begin,
        end: interval.end,
        state: interval.state,
      }))
    }
  })
}

export function formatSecondsToTime(seconds) {
  if (seconds == null) {
    return null
  }
  if (seconds === 86400) {
    return '00:00'
  }
  const hours = Math.floor(seconds / 3600).toString()
  const minutes = ((seconds % 3600) / 60).toString()
  return `${hours.length === 1 ? '0' + hours : hours}:${
    minutes.length === 1 ? '0' + minutes : minutes
  }`
}
