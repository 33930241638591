export default {
  pageTitle: 'Welcome to iXsolve',
  login: 'Login',
  register: 'Register',
  logIn: 'Log in',
  password: 'Password',
  passwordAgain: 'Repeat password',
  forgotPassword: 'Forgot password?',
  registerNewAccount: 'Register new account',
  signIn: 'Create account',
  haveAccount: 'I have an account',
  error: { wrongUsernameOrPassword: 'Incorrect email or password.' },
  backToLogin: 'Back to login',
  resetPassword: 'Reset password',
  forgotPasswordTitle: 'Reset Password',
  confirmAccountTitle: 'Please confirm your account.',
  confirmationCode: 'Confirmation code',
  sendConfirmation: 'Confirm account',
  requestNewCode: 'Request new code',
  confirmAccountInEmail: 'Account successfully created, please check your email and confirm.',
  changePassword: 'Change password',
  resetPasswordTitle: 'Reset Password',
  resetPasswordText:
    'A verification code has been sent to your email. Please copy the verification code into the box below and set a new password.',
  resetPasswordCode: 'Verification code from email',
  resetPasswordSuccess: 'Password updated successfully.',
  resetPasswordInvalidCode: 'Invalid verification code.',
}
