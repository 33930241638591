import gql from 'graphql-tag'

export const companiesWithEligibilities = gql`
  query companiesWithEligibilities {
    companies {
      edges {
        node {
          id
          name
          eligibilities {
            name
          }
        }
      }
    }
  }
`
