<template>
  <div :class="{ 'form-main--row': !onlyInput }">
    <div v-if="!onlyInput" class="form-main--column">
      <label class="form-main--label" :for="name">{{ translatedLabel }}</label>
    </div>
    <div class="form-main--column">
      <div class="form-switch--wrapper">
        <input
          ref="checkbox"
          class="form-switch--input"
          type="checkbox"
          @click="handleCheckboxClick"
          v-model="isChecked"
          :id="name"
          :disabled="disabled"
          hidden
        />
        <label
          :class="{ 'form-switch--disabled': disabled }"
          class="form-switch"
          :for="name"
        ></label>
      </div>
      <p v-for="error in inputErrors" :key="`input_${name}_error_${error.error}`">
        {{ $t('device.validations.' + error.error) }}
      </p>
    </div>

    <v-dialog v-model="showPremiumFeatureDialog" persistent max-width="30rem">
      <v-card>
        <v-card-title class="text-h5">{{ $t('global.premiumFeatureModalTitle') }}</v-card-title>
        <v-card-text>{{ $t('global.premiumFeatureModalDesc') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="confirmPremiumFeature">{{
            $t('global.continue')
          }}</v-btn>
          <v-btn color="red" text @click="cancelPremiumFeature">{{ $t('global.cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BasicInput from '@/components/Form/Input/BasicInput'

export default {
  name: 'CheckboxInput',
  extends: BasicInput,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    onlyInput: {
      type: Boolean,
      default: false,
    },
    isPremium: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPremiumFeatureDialog: false,
      isChecked: this.value,
      initialValue: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.isChecked = newValue
      if (this.initialValue === undefined) {
        // Initialize initialValue if it hasn't been set
        this.initialValue = newValue
      }
    },
  },
  methods: {
    handleCheckboxClick() {
      if (this.isPremium && !this.initialValue && !this.isChecked) {
        this.showPremiumFeatureDialog = true
      } else {
        this.$emit('input', !this.isChecked)
      }
    },
    confirmPremiumFeature() {
      this.isChecked = true
      this.$emit('input', this.isChecked)
      this.showPremiumFeatureDialog = false
    },
    cancelPremiumFeature() {
      this.isChecked = this.initialValue
      this.$emit('input', this.isChecked)
      this.showPremiumFeatureDialog = false
    },
  },
}
</script>

<style scoped>
.form-switch--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
