<template>
  <div>
    <div v-if="editor" class="editor-wrapper mb-3">
      <div class="header">
        <v-btn
          @click="editor.chain().focus().toggleBold().run()"
          :color="editor.isActive('bold') ? editorActiveColor : editorInactiveColor"
          depressed
          small
        >
          <v-icon>mdi-format-bold</v-icon>
        </v-btn>
        <v-btn
          @click="editor.chain().focus().toggleItalic().run()"
          :color="editor.isActive('italic') ? editorActiveColor : editorInactiveColor"
          depressed
          small
        >
          <v-icon>mdi-format-italic</v-icon>
        </v-btn>
        <v-btn
          @click="editor.chain().focus().toggleStrike().run()"
          :color="editor.isActive('strike') ? editorActiveColor : editorInactiveColor"
          depressed
          small
        >
          <v-icon>mdi-format-strikethrough</v-icon>
        </v-btn>
        <v-btn
          @click="editor.chain().focus().insertContent('@').run()"
          :color="editorInactiveColor"
          depressed
          small
        >
          <v-icon>mdi-at</v-icon>
        </v-btn>
      </div>
      <editor-content :editor="editor" />
    </div>
  </div>
</template>
<script>
import { Editor, EditorContent, VueRenderer } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Mention from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
import MentionList from '@/components/RichTextEditor/MentionList.vue'

export default {
  name: 'RichTextEditor',
  components: {
    EditorContent,
  },
  props: {
    usersAvailableToMention: Array,
    placeholder: String,
  },
  data() {
    return {
      editor: null,
      formatToggles: [],
      editorActiveColor: '#d8d8d8',
      editorInactiveColor: '#f8f8f8',
    }
  },
  mounted() {
    const extensions = [
      StarterKit,
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        renderLabel({ node }) {
          return `@${node.attrs.label}`
        },
        renderHTML({ node, HTMLAttributes }) {
          return [
            'span',
            { ...HTMLAttributes, 'data-id': node.attrs.id, 'data-type': 'mention' },
            `@${node.attrs.label}`,
          ]
        },
        suggestion: {
          allowedPrefixes: null,
          items: ({ query }) => {
            return (this.usersAvailableToMention || [])
              .filter((item) => {
                const lowerCaseQuery = query.toLowerCase()
                return (
                  item.firstName.toLowerCase().startsWith(lowerCaseQuery) ||
                  item.lastName.toLowerCase().startsWith(lowerCaseQuery) ||
                  item.email.toLowerCase().startsWith(lowerCaseQuery)
                )
              })
              .slice(0, 5)
          },
          render: () => {
            const that = this
            let component
            return {
              onStart(props) {
                component = new VueRenderer(MentionList, {
                  parent: that,
                  propsData: {
                    ...props,
                    exit: this.onExit,
                  },
                })
                if (!props.clientRect) {
                  return
                }
                const rect = props.clientRect()
                component.ref.x = rect.bottom
                component.ref.y = rect.left
                document.body.appendChild(component.element)
              },
              onUpdate(props) {
                component.updateProps(props)
              },
              onKeyDown(props) {
                if (props.event.key === 'Escape') {
                  this.onExit()
                  return true
                }
                return component.ref?.onKeyDown(props)
              },
              onExit() {
                try {
                  document.body.removeChild(component.element)
                } catch {
                  // ignore if it is already removed
                }
                component.destroy()
              },
            }
          },
        },
      }),
    ]
    if (this.placeholder) {
      extensions.push(
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
      )
    }
    this.editor = new Editor({
      content: '',
      extensions,
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>
<style lang="less" scoped>
@import '~@/components/RichTextEditor/styles.less';

.editor-wrapper {
  border: 1px solid #e2e2e2;
  border-radius: 5px;

  .header {
    display: flex;
    gap: 5px;
    background-color: #f8f8f8;
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    /deep/.v-btn {
      padding: 0;
      min-width: 28px;
    }
  }
}

/deep/.ProseMirror {
  padding: 10px;
  &:focus-visible {
    outline: none;
  }

  p {
    margin-bottom: 0;
  }

  .mention {
    .mention-mixin();
  }

  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }
}
</style>
