<template>
  <v-dialog
    :value="isOpen"
    @input="$emit('modalToggle', $event)"
    @click:outside="tryClose()"
    @keydown.esc="tryClose()"
    :max-width="1500"
    :content-class="
      mobileView ? 'weekly-time-schedules-modal-mobile' : 'weekly-time-schedules-modal'
    "
    :fullscreen="mobileView"
    persistent
  >
    <v-btn v-if="mobileView && mobileViewDetail" @click="tryGoBackToMenu()" class="back-btn" icon
      ><v-icon>mdi-arrow-left</v-icon></v-btn
    >
    <v-btn @click="tryClose" :color="mobileView ? 'default' : 'white'" class="close-btn" icon
      ><v-icon>mdi-close</v-icon></v-btn
    >
    <v-snackbar
      :value="Boolean(error)"
      color="info"
      height="39"
      :timeout="10000"
      :style="{
        top: mobileView ? undefined : '-70px',
      }"
      :max-width="mobileView ? 'calc(100% - 100px)' : undefined"
      :min-width="mobileView ? 'initial' : undefined"
      top
      absolute
      >{{ error }}</v-snackbar
    >

    <div class="modal-content-wrapper">
      <div v-show="!mobileView || !mobileViewDetail" class="sidebar">
        <h1>{{ $t('global.weeklyTimeSchedules') }}</h1>
        <div class="schedule-list">
          <button
            v-if="viewMode === viewModeEnum.NEW || viewMode === viewModeEnum.NEW_WITH_ACTIVATE"
            class="schedule-selector-btn active d-flex flex-row justify-content-between"
          >
            <div>
              <h2>{{ $t('global.newSchedule') }}</h2>
              <p>{{ $t('global.mySchedule') }}</p>
            </div>
            <v-btn
              :title="$t('global.delete')"
              icon
              height="24"
              width="24"
              @click="deleteClickHandler"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </button>
          <button
            v-for="(weeklyTimeSchedule, index) of weeklyTimeSchedules"
            :key="weeklyTimeSchedule.id"
            @click="trySwitchSchedule(index)"
            class="schedule-selector-btn"
            :class="{
              active: !mobileView && index === selectedWeeklyScheduleIndex,
              'without-action-btns': !editable,
            }"
          >
            <div class="schedule-selector-btn-texts">
              <h2>{{ weeklyTimeSchedule.name }}</h2>
              <p>
                {{
                  weeklyTimeSchedule.isGlobal
                    ? $t('global.predefinedSchedule')
                    : $t('global.mySchedule')
                }}
              </p>
            </div>
            <div class="active-icon" v-if="weeklyTimeSchedule.id === activeTimeScheduleId">
              <v-icon size="12" color="white">mdi-check</v-icon>
            </div>
            <div
              v-if="editable || canActivate"
              class="action-buttons"
              :class="{ 'always-visible': mobileView }"
            >
              <v-btn
                v-if="editable"
                :title="$t('global.duplicateSchedule')"
                icon
                height="24"
                width="24"
                @click.stop="tryStartCreatingNewSchedule(index)"
                ><v-icon small>mdi-content-duplicate</v-icon></v-btn
              >
              <v-btn
                v-if="editable && weeklyTimeSchedule.isGlobal"
                :title="$t('global.edit')"
                icon
                height="24"
                width="24"
                @click.stop="systemTimeScheduleEditValidationModalOpen = true"
                ><v-icon small>mdi-pencil</v-icon></v-btn
              >
              <v-btn
                v-if="
                  editable &&
                  ((!weeklyTimeSchedule.isGlobal &&
                    weeklyTimeSchedule.id !== activeTimeScheduleId) ||
                    (templateMode && !weeklyTimeSchedule.isUsed))
                "
                :title="$t('global.delete')"
                icon
                height="24"
                width="24"
                @click.stop="openDeleteConfirmationModal(index)"
                ><v-icon small>mdi-delete</v-icon></v-btn
              >
              <v-btn
                v-if="editable && (!weeklyTimeSchedule.isGlobal || templateMode)"
                :title="$t('global.edit')"
                icon
                height="24"
                width="24"
                @click.stop="trySwitchToEdit(index)"
                ><v-icon small>mdi-pencil</v-icon></v-btn
              >
              <v-btn
                v-if="canActivate && weeklyTimeSchedule.id !== activeTimeScheduleId"
                :title="$t('global.activate')"
                icon
                height="24"
                width="24"
                @click.stop="openActivateConfirmation(index)"
                ><v-icon small>mdi-play</v-icon></v-btn
              >
            </div>
          </button>
        </div>
        <div v-if="editable" class="create-btn-wrapper">
          <v-btn outlined depressed @click="tryStartCreatingNewSchedule()">{{
            $t('global.newWeeklySchedule')
          }}</v-btn>
        </div>
      </div>
      <div v-show="!mobileView || mobileViewDetail" class="weekly-schedule-detail-wrapper">
        <div
          class="d-flex flex-wrap align-center"
          :class="[
            viewMode === viewModeEnum.VIEW ? 'mb-4' : 'mb-1',
            mobileView ? 'mt-12' : 'mt-8',
            mobileView ? 'mx-2' : 'mx-8',
          ]"
        >
          <template v-if="viewMode === viewModeEnum.VIEW">
            <h1 class="mr-2">
              {{ weeklyTimeSchedules[selectedWeeklyScheduleIndex].name }}
            </h1>
            <!-- <v-btn
              v-if="
                editable &&
                viewMode === viewModeEnum.VIEW &&
                (!weeklyTimeSchedules[selectedWeeklyScheduleIndex].isGlobal || templateMode)
              "
              @click="viewMode = viewModeEnum.EDIT"
              class="mr-2"
              depressed
              icon
              ><v-icon>mdi-pencil</v-icon></v-btn
            > -->
          </template>
          <v-text-field
            v-else
            v-model="actualTimeScheduleName"
            :error-messages="visibleTimeScheduleNameErrors"
            :label="$t('global.timeScheduleName')"
            required
            @input="$v.actualTimeScheduleName.$touch()"
            @blur="$v.actualTimeScheduleName.$touch()"
            filled
            class="flex-grow-1 mr-5"
          ></v-text-field>
          <span
            v-if="viewMode !== viewModeEnum.NEW && viewMode !== viewModeEnum.NEW_WITH_ACTIVATE"
            class="schedule-label mr-2"
            :class="{ 'mb-6': viewMode !== viewModeEnum.VIEW }"
            >{{
              weeklyTimeSchedules[selectedWeeklyScheduleIndex].isGlobal
                ? $t('global.predefinedSchedule')
                : $t('global.mySchedule')
            }}</span
          >
          <span v-else class="schedule-label mr-2 mb-6">{{ $t('global.mySchedule') }}</span>
          <AddDailyScheduleButton
            v-if="viewMode !== viewModeEnum.VIEW"
            @select="addDailySchedule"
            @addNew="createNewDailySchedule"
            :actualTimeSchedule="actualTimeSchedule"
            :dailyTimeSchedules="dailyTimeSchedules"
            :btnProps="{ disabled: !canAddMoreDailySchedules, class: 'mb-6' }"
          />
        </div>

        <!-- we do not hide following section even when there are no schedules because we need to initialize sortable -->
        <div
          class="daily-schedules"
          :class="{
            'flex-grow-1': actualTimeSchedule.length,
            'mx-8': !mobileView,
            'mx-2': mobileView,
          }"
          ref="sortableDailySchedules"
        >
          <section
            v-for="entry of actualTimeSchedule"
            :key="entry.dailyTimeScheduleId"
            class="daily-entry-wrapper"
            :class="{ 'has-error': !entry.days.length }"
          >
            <div class="entry-header">
              <div class="d-flex align-center">
                <v-icon v-if="viewMode !== viewModeEnum.VIEW" class="sort-handle"
                  >mdi-drag-vertical</v-icon
                >
                <h1 class="mr-4">{{ dailyScheduleMap.get(entry.dailyTimeScheduleId).name }}</h1>
                <span class="schedule-label mr-2">{{
                  dailyScheduleMap.get(entry.dailyTimeScheduleId).isGlobal
                    ? $t('global.predefinedSchedule')
                    : $t('global.mySchedule')
                }}</span>
              </div>
              <div class="d-flex align-center">
                <v-menu
                  offset-y
                  :disabled="viewMode === viewModeEnum.VIEW"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
                    <div
                      v-bind="menuAttrs"
                      v-on="menuOn"
                      :class="{ 'repeat-with-error': !entry.days.length }"
                      :style="{ cursor: viewMode !== viewModeEnum.VIEW ? 'pointer' : 'auto' }"
                    >
                      <span class="repeat-span">{{ $t('global.repeat') }}: </span>
                      <span class="repeated-days-list">{{
                        entry.days.length === 7
                          ? $t('global.daily')
                          : entry.days.map((day) => $t(`global.daysOfWeekShort.${day}`)).join(', ')
                      }}</span>
                      <v-icon v-if="viewMode !== viewModeEnum.VIEW">mdi-menu-down</v-icon>
                    </div>
                  </template>
                  <v-list>
                    <v-list-item @click="selectAllDays(entry.dailyTimeScheduleId)">
                      {{ $t('global.selectAll') }}
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                    <v-list-item
                      v-for="day of daysOfWeekItems"
                      :key="day.value"
                      class="day-of-week-list-item"
                      @click="computeNextValue(entry.dailyTimeScheduleId, day.value)"
                    >
                      <v-checkbox
                        :input-value="entry.days.includes(day.value)"
                        :label="day.text"
                        color="primary"
                        :ripple="false"
                        hide-details
                        dense
                      ></v-checkbox>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-tooltip v-if="viewMode !== viewModeEnum.VIEW" top>
                  <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <v-btn
                      v-bind="tooltipAttrs"
                      v-on="tooltipOn"
                      @click="duplicateDailySchedule(entry.dailyTimeScheduleId)"
                      icon
                      class="ml-2 mr-n2"
                    >
                      <v-icon>mdi-content-duplicate</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('global.duplicateScheduleTooltip') }}</span>
                </v-tooltip>
                <v-tooltip v-if="viewMode !== viewModeEnum.VIEW" top>
                  <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <v-btn
                      v-bind="tooltipAttrs"
                      v-on="tooltipOn"
                      @click="removeDailySchedule(entry.dailyTimeScheduleId)"
                      icon
                      class="ml-2 mr-n2"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('global.delete') }}</span>
                </v-tooltip>
                <v-tooltip v-if="viewMode !== viewModeEnum.VIEW" top>
                  <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <v-btn
                      v-bind="tooltipAttrs"
                      v-on="tooltipOn"
                      @click="
                        dailyScheduleMap.get(entry.dailyTimeScheduleId).isGlobal && !templateMode
                          ? ((selectedDailyScheduleIndex = entry.dailyTimeScheduleId),
                            (systemDailyTimeScheduleEditValidationModalOpen = true))
                          : editDailySchedule(entry.dailyTimeScheduleId)
                      "
                      icon
                      class="ml-2 mr-n2"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('global.edit') }}</span>
                </v-tooltip>
              </div>
            </div>
            <DailyScheduleOverview
              :timeSchedule="dailyScheduleMap.get(entry.dailyTimeScheduleId)"
              :timeScheduleConfig="timeScheduleConfig"
              @dailyScheduleErrorConditionMet="showErrorModal"
              showCurrentTime
            />
            <v-dialog persistent @click:outside.stop max-width="300px" v-model="isErrorModalOpen">
              <div class="error-modal-content">
                <p>{{ $t('global.errorInTimeScheduleConfiguration') }}</p>
                <v-btn color="primary" @click="closeErrorModalAndRefresh">{{
                  $t('global.close')
                }}</v-btn>
              </div>
            </v-dialog>
          </section>
          <div
            v-if="actualTimeSchedule.length && viewMode !== viewModeEnum.VIEW"
            class="text-center my-5"
          >
            <AddDailyScheduleButton
              @select="addDailySchedule"
              @addNew="createNewDailySchedule"
              :actualTimeSchedule="actualTimeSchedule"
              :dailyTimeSchedules="dailyTimeSchedules"
            />
          </div>
        </div>
        <div
          v-if="!actualTimeSchedule.length"
          class="d-flex flex-column align-center flex-grow-1 justify-center"
        >
          <v-icon x-large class="mb-5">mdi-close-box-outline</v-icon>
          <p class="text-center">
            <b>{{ $t('global.nothingToSeeHereYet') }}</b
            ><br />{{ $t('global.addFirstDailyScheduleMessage') }}
          </p>
          <AddDailyScheduleButton
            @select="addDailySchedule"
            @addNew="createNewDailySchedule"
            :actualTimeSchedule="actualTimeSchedule"
            :dailyTimeSchedules="dailyTimeSchedules"
            :btnProps="{ color: 'primary', class: 'mb-10' }"
          />
        </div>

        <footer
          :class="[
            mobileView ? 'pa-2' : 'pa-8',
            templateMode ||
            viewMode === viewModeEnum.NEW ||
            viewMode === viewModeEnum.NEW_WITH_ACTIVATE ||
            !weeklyTimeSchedules[selectedWeeklyScheduleIndex].isGlobal
              ? 'justify-space-between'
              : 'justify-end',
          ]"
          v-if="editable || canActivate"
        >
          <v-tooltip
            v-if="
              templateMode ||
              viewMode === viewModeEnum.NEW ||
              viewMode === viewModeEnum.NEW_WITH_ACTIVATE ||
              !weeklyTimeSchedules[selectedWeeklyScheduleIndex].isGlobal
            "
            top
            :disabled="!disabledDeleteMessage"
          >
            <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
              <div v-bind="tooltipAttrs" v-on="tooltipOn">
                <v-btn
                  :disabled="Boolean(disabledDeleteMessage)"
                  color="error"
                  text
                  @click="deleteClickHandler"
                  ><v-icon left>mdi-delete</v-icon>{{ $t('global.delete') }}</v-btn
                >
              </div>
            </template>
            <span>{{ disabledDeleteMessage }}</span>
          </v-tooltip>
          <div class="actions">
            <v-tooltip
              v-if="
                editable &&
                viewMode === viewModeEnum.VIEW &&
                weeklyTimeSchedules[selectedWeeklyScheduleIndex].isGlobal
              "
              top
            >
              <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                <v-btn
                  v-bind="tooltipAttrs"
                  v-on="tooltipOn"
                  @click="systemTimeScheduleEditValidationModalOpen = true"
                  depressed
                  ><v-icon small class="mr-1">mdi-pencil</v-icon>{{ $t('global.edit') }}</v-btn
                >
              </template>
              <span>{{ $t('global.duplicateScheduleTooltip') }}</span>
            </v-tooltip>
            <v-btn
              v-if="
                editable &&
                viewMode === viewModeEnum.VIEW &&
                !weeklyTimeSchedules[selectedWeeklyScheduleIndex].isGlobal
              "
              v-bind="tooltipAttrs"
              v-on="tooltipOn"
              @click="trySwitchToEdit(selectedWeeklyScheduleIndex)"
              depressed
              ><v-icon small class="mr-1">mdi-pencil</v-icon>{{ $t('global.edit') }}</v-btn
            >
            <v-btn
              v-if="
                editable &&
                viewMode === viewModeEnum.VIEW &&
                !weeklyTimeSchedules[selectedWeeklyScheduleIndex].isGlobal
              "
              v-bind="tooltipAttrs"
              v-on="tooltipOn"
              @click="tryStartCreatingNewSchedule(selectedWeeklyScheduleIndex)"
              depressed
              ><v-icon small class="mr-1">mdi-content-duplicate</v-icon
              >{{ $t('global.duplicateSchedule') }}</v-btn
            >
            <v-btn
              v-if="
                canActivate &&
                viewMode === viewModeEnum.VIEW &&
                weeklyTimeSchedules[selectedWeeklyScheduleIndex].id !== activeTimeScheduleId
              "
              @click="openActivateConfirmation(selectedWeeklyScheduleIndex)"
              color="primary"
              depressed
              >{{ $t('global.activate') }}</v-btn
            >
            <v-btn
              v-if="editable && viewMode === viewModeEnum.EDIT"
              @click="trySwitchSchedule(selectedWeeklyScheduleIndex)"
              :text="hasUnsavedChanges"
              :color="hasUnsavedChanges ? 'error' : undefined"
              depressed
              >{{ hasUnsavedChanges ? $t('global.discardChanges') : $t('global.back') }}</v-btn
            >
            <v-tooltip :disabled="!disabledSaveMessage" top>
              <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                <div v-bind="tooltipAttrs" v-on="tooltipOn">
                  <v-btn
                    v-if="editable && canActivate && viewMode === viewModeEnum.NEW_WITH_ACTIVATE"
                    @click="handleSaveAndActivate()"
                    :disabled="Boolean(disabledSaveMessage)"
                    color="primary"
                    depressed
                    >{{ $t('global.saveAndActivate') }}</v-btn
                  >
                  <v-btn
                    v-else-if="editable && viewMode !== viewModeEnum.VIEW"
                    @click="handleSave()"
                    :disabled="Boolean(disabledSaveMessage)"
                    color="primary"
                    depressed
                    >{{ $t('global.save') }}</v-btn
                  >
                </div>
              </template>
              <span>{{ disabledSaveMessage }}</span>
            </v-tooltip>
          </div>
        </footer>
      </div>
    </div>

    <SystemTimeScheduleEditValidationModal
      v-model="systemTimeScheduleEditValidationModalOpen"
      :duplicateAction="() => tryStartCreatingNewSchedule(selectedWeeklyScheduleIndex)"
    />

    <SystemTimeScheduleEditValidationModal
      v-model="systemDailyTimeScheduleEditValidationModalOpen"
      :duplicateAction="() => editDailySchedule(selectedDailyScheduleIndex)"
    />

    <DailyTimeScheduleEditModal
      v-if="baseScheduleForDailyEditModal"
      v-model="isEditDailyDialogOpen"
      :timeSchedule="baseScheduleForDailyEditModal"
      :timeScheduleConfig="timeScheduleConfig"
      :saveMethod="dailyEditModalSaveMethod"
      :namesTaken="namesTakenForDailyEditModal"
      :updateMode="dailyEditModalIsInUpdateMode"
      :usedInWeeklySchedulesNames="usedInWeeklyForDailyEditModal"
    />
    <ConfirmationDialog
      v-model="discardChangesConfirmationModalOpen"
      :heading="$t('device.timeSchedule.editModal.cancelConfirmation.heading')"
      :text="
        $t('device.timeSchedule.editModal.cancelConfirmation.text', {
          scheduleName:
            selectedWeeklyScheduleIndex != null
              ? weeklyTimeSchedules[selectedWeeklyScheduleIndex].name
              : $t('global.new'),
        })
      "
      :cancelText="$t('global.continueEditing')"
      :confirmText="$t('global.discardChanges')"
      confirmBtnColor="error"
      :action="discardChangesAction"
    />
    <ConfirmationDialog
      v-model="deleteNewConfirmationModalOpen"
      :heading="$t('global.newWeeklyTimeScheduleDeleteConfirmationHeading')"
      :text="$t('global.newWeeklyTimeScheduleDeleteConfirmationText')"
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
      :action="
        () => {
          switchSchedule(0, viewModeEnum.VIEW)
          deleteNewConfirmationModalOpen = false
          setError($t('global.deleteWeeklyScheduleInfo'))
        }
      "
    />
    <ConfirmationDialog
      v-if="deleteConfirmationModalOpen"
      v-model="deleteConfirmationModalOpen"
      :heading="$t('global.weeklyTimeScheduleDeleteConfirmationHeading')"
      :text="
        $t('global.weeklyTimeScheduleDeleteConfirmationText', {
          scheduleName: weeklyTimeSchedules[indexToDelete].name,
        })
      "
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
      :action="deleteWeeklyTimeSchedule"
    />
    <ConfirmationDialog
      v-if="isConfirmActivateModalOpen"
      v-model="isConfirmActivateModalOpen"
      :heading="$t('device.timeSchedule.confirmActivateModal.heading')"
      :text="
        $t('device.timeSchedule.confirmActivateModal.text', {
          scheduleName: weeklyTimeSchedules[indexToActivate].name,
        })
      "
      :confirmText="$t('global.activate')"
      :action="() => activateSchedule(indexToActivate, normalActivateCleanUp)"
    />
  </v-dialog>
</template>

<script>
import DailyScheduleOverview from '@/components/TimeSchedules/DailyScheduleOverview'
import AddDailyScheduleButton from '@/components/TimeSchedules/AddDailyScheduleButton'
import SystemTimeScheduleEditValidationModal from '@/components/TimeSchedules/SystemTimeScheduleEditValidationModal'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { daysOfWeek } from '@/config/daysOfWeek'
import unsavedChangesUnloadGuard from '@/mixins/unsavedChangesUnloadGuard'
import isEqual from 'lodash/isEqual'
import Sortable from 'sortablejs'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { isUnique } from '@/utils/isUniqueValidator'
import { deviceSetActiveWeeklyTimeSchedule } from '@/graphql/mutations/deviceSetActiveWeeklyTimeSchedule'
import DailyTimeScheduleEditModal from '@/components/TimeSchedules/DailyTimeScheduleEditModal'
import produce from 'immer'

export const viewModeEnum = Object.freeze({
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  NEW: 'NEW',
  NEW_WITH_ACTIVATE: 'NEW_WITH_ACTIVATE',
})

export default {
  name: 'WeeklyTimeSchedulesModal',
  mixins: [validationMixin, unsavedChangesUnloadGuard],
  components: {
    DailyScheduleOverview,
    ConfirmationDialog,
    AddDailyScheduleButton,
    DailyTimeScheduleEditModal,
    SystemTimeScheduleEditValidationModal,
  },
  model: {
    prop: 'isOpen',
    event: 'modalToggle',
  },
  props: {
    isOpen: Boolean,
    device: Object,
    weeklyTimeSchedules: Array,
    dailyTimeSchedules: Array,
    timeScheduleConfig: Object,
    createMethod: Function,
    updateMethod: Function,
    deleteMethod: Function,
    dailyCreateMethod: Function,
    dailyUpdateMethod: Function,
    editable: Boolean,
    canActivate: Boolean,
    openInViewMode: String,
    preselectScheduleId: String,
    activeTimeScheduleId: String,
    isActivatingTimeScheduleId: String,
    templateMode: Boolean,
  },
  data() {
    return {
      selectedWeeklyScheduleIndex: 0,
      actualTimeSchedule: this.weeklyTimeSchedules[0].weeklySchedule.map((entry) => ({
        dailyTimeScheduleId: entry.dailySchedule.id,
        days: entry.days,
      })),
      actualTimeScheduleName: this.weeklyTimeSchedules[0].name,
      discardChangesConfirmationModalOpen: false,
      viewMode: this.openInMode || viewModeEnum.VIEW,
      error: null,
      manualError: false,
      discardChangesAction: null,
      deleteConfirmationModalOpen: false,
      deleteNewConfirmationModalOpen: false,
      isConfirmActivateModalOpen: false,
      indexToDelete: null,
      indexToActivate: null,
      mobileViewDetail: false,
      isReopeningAfterCloseOfDailyEditModal: false,
      isErrorModalOpen: false,
      isEditDailyDialogOpen: false,
      baseScheduleForDailyEditModal: null,
      dailyEditModalSaveMethod: null,
      namesTakenForDailyEditModal: null,
      dailyEditModalIsInUpdateMode: false,
      usedInWeeklyForDailyEditModal: null,
      systemTimeScheduleEditValidationModalOpen: false,
      systemDailyTimeScheduleEditValidationModalOpen: false,
      tooltipOn: null,
      tooltipAttrs: null,
      menuOn: null,
      menuAttrs: null,
    }
  },
  validations() {
    return {
      actualTimeScheduleName: {
        required,
        maxLength: maxLength(35),
        mustBeUnique: isUnique(
          this.viewMode === viewModeEnum.NEW || this.viewMode === viewModeEnum.NEW_WITH_ACTIVATE
            ? this.weeklySchedulesNames
            : this.removeName(
                this.weeklyTimeSchedules[this.selectedWeeklyScheduleIndex].name,
                this.weeklySchedulesNames,
              ),
        ),
      },
    }
  },
  methods: {
    showErrorModal() {
      this.isErrorModalOpen = true
    },
    closeErrorModalAndRefresh() {
      this.$emit('modalToggle', false)
      this.$emit('isErrorInConfig')
    },
    setError(message) {
      this.manualError = true
      this.error = message
      setTimeout(() => {
        this.manualError = false
      }, 3000)
    },
    async activateSchedule(indexToActivate, successAction) {
      try {
        await this.$apollo.mutate({
          mutation: deviceSetActiveWeeklyTimeSchedule,
          variables: {
            deviceId: this.device.id,
            weeklyTimeScheduleId: this.weeklyTimeSchedules[indexToActivate].id,
          },
        })
        this.$emit(
          'update:isActivatingTimeScheduleId',
          this.weeklyTimeSchedules[indexToActivate].id,
        )
        successAction()
      } catch (error) {
        console.error(error)
        this.setError(this.$t('device.timeSchedule.setActiveTimeScheduleFailed'))
      }
    },
    async duplicateDailySchedule(dailyTimeScheduleId) {
      try {
        // Find the schedule to duplicate
        const dailySchedule = this.dailyTimeSchedules.find(
          (schedule) => schedule.id === dailyTimeScheduleId,
        )
        const newSchedule = produce(dailySchedule, (draft) => {
          draft.name = `${draft.name} ${this.$t('global.duplicateSchedulePostfix')}`
        })
        await this.createDailyAndAdd(newSchedule.schedule, newSchedule.name)

        this.setError(this.$t('global.duplicateDailyScheduleInfo'))
      } catch (error) {
        this.setError(this.$t('global.scheduleDuplicateFailed'))
      }
    },
    async createWeeklyTimeSchedule(successAction) {
      try {
        await this.createMethod(this.actualTimeSchedule, this.actualTimeScheduleName)
        if (successAction) {
          successAction()
        } else {
          this.switchSchedule(0, viewModeEnum.VIEW)
        }
      } catch (error) {
        this.setError(error)
      }
    },
    async updateWeeklyTimeSchedule(successAction) {
      try {
        await this.updateMethod(
          this.weeklyTimeSchedules[this.selectedWeeklyScheduleIndex].id,
          this.actualTimeSchedule,
          this.actualTimeScheduleName,
        )
        if (successAction) {
          successAction()
        } else {
          this.viewMode = viewModeEnum.VIEW
        }
      } catch (error) {
        this.setError(error)
      }
    },
    async deleteWeeklyTimeSchedule() {
      this.setError(this.$t('global.deleteWeeklyScheduleInfo'))
      try {
        await this.deleteMethod(this.weeklyTimeSchedules[this.indexToDelete].id)
        if (this.indexToDelete === this.selectedWeeklyScheduleIndex) {
          this.switchSchedule(0, viewModeEnum.VIEW)
        } else if (this.indexToDelete < this.selectedWeeklyScheduleIndex) {
          this.selectedWeeklyScheduleIndex--
        }

        this.deleteConfirmationModalOpen = false
        this.indexToDelete = null
      } catch (error) {
        this.setError(error)
      }
    },
    handleSave(successAction) {
      if (this.viewMode === viewModeEnum.EDIT) {
        this.updateWeeklyTimeSchedule(successAction)
      } else {
        this.createWeeklyTimeSchedule(successAction)
      }
    },
    handleSaveAndActivate() {
      this.createWeeklyTimeSchedule(() => {
        this.$emit('modalToggle', false)
        this.activateSchedule(0, () => {
          this.saveAndActivate = false
        })
      })
    },
    normalActivateCleanUp() {
      // called after successful activation, when activation is triggered from modal with confirmation
      this.isConfirmActivateModalOpen = false
      this.indexToActivate = null

      if (this.viewMode === viewModeEnum.VIEW) {
        this.$emit('modalToggle', false)
      }
    },
    openActivateConfirmation(index) {
      this.indexToActivate = index
      this.isConfirmActivateModalOpen = true
    },
    openDeleteConfirmationModal(index) {
      this.indexToDelete = index
      this.deleteConfirmationModalOpen = true
    },
    trySwitchToEdit(index) {
      if (this.hasUnsavedChanges) {
        if (this.disabledSaveMessage) {
          this.discardChangesAction = () => this.switchSchedule(index, viewModeEnum.EDIT)
          this.discardChangesConfirmationModalOpen = true
        } else {
          this.handleSave(() => this.switchSchedule(index, viewModeEnum.EDIT))
        }
      } else {
        this.switchSchedule(index, viewModeEnum.EDIT)
        this.mobileViewDetail = true
      }
    },
    trySwitchSchedule(index) {
      if (this.hasUnsavedChanges) {
        if (this.disabledSaveMessage) {
          this.discardChangesAction = () => this.switchSchedule(index, viewModeEnum.VIEW)
          this.discardChangesConfirmationModalOpen = true
        } else {
          this.handleSave(() => this.switchSchedule(index, viewModeEnum.VIEW))
        }
      } else {
        this.switchSchedule(index, viewModeEnum.VIEW)
        this.mobileViewDetail = true
      }
    },
    tryGoBackToMenu() {
      if (this.hasUnsavedChanges) {
        if (this.disabledSaveMessage) {
          this.discardChangesAction = () => {
            this.switchSchedule(0, viewModeEnum.VIEW)
            this.mobileViewDetail = false
          }
          this.discardChangesConfirmationModalOpen = true
        } else {
          this.handleSave(() => {
            this.switchSchedule(0, viewModeEnum.VIEW)
            this.mobileViewDetail = false
          })
        }
      } else {
        this.switchSchedule(0, viewModeEnum.VIEW)
        this.mobileViewDetail = false
      }
    },
    switchSchedule(index, viewMode) {
      this.selectedWeeklyScheduleIndex = index
      this.actualTimeSchedule = this.weeklyTimeSchedules[index].weeklySchedule.map((entry) => ({
        dailyTimeScheduleId: entry.dailySchedule.id,
        days: entry.days,
      }))
      this.actualTimeScheduleName = this.weeklyTimeSchedules[index].name
      this.discardChangesAction = null
      this.discardChangesConfirmationModalOpen = false
      this.viewMode = viewMode
    },
    tryClose() {
      if (this.hasUnsavedChanges) {
        if (this.disabledSaveMessage) {
          this.discardChangesAction = () => this.$emit('modalToggle', false)
          this.discardChangesConfirmationModalOpen = true
        } else {
          this.handleSave(() => this.$emit('modalToggle', false))
        }
      } else {
        this.$emit('modalToggle', false)
      }
    },
    addDailySchedule(dailyTimeScheduleId) {
      const usedDays = this.actualTimeSchedule.map((schedule) => schedule.days).flat()
      const unusedDaysOfWeek = [...daysOfWeek].filter((day) => !usedDays.includes(day))
      this.actualTimeSchedule = [
        ...this.actualTimeSchedule,
        {
          dailyTimeScheduleId,
          days: unusedDaysOfWeek,
        },
      ]
    },
    removeDailySchedule(dailyTimeScheduleId) {
      this.setError(this.$t('global.deleteDailyScheduleInfo'))
      const indexToRemove = this.actualTimeSchedule.findIndex(
        (entry) => entry.dailyTimeScheduleId === dailyTimeScheduleId,
      )
      this.actualTimeSchedule = [
        ...this.actualTimeSchedule.slice(0, indexToRemove),
        ...this.actualTimeSchedule.slice(indexToRemove + 1),
      ]
    },
    editDailySchedule(dailyTimeScheduleId) {
      const dailyTimeSchedule = this.dailyTimeSchedules.find(
        (schedule) => schedule.id === dailyTimeScheduleId,
      )
      this.baseScheduleForDailyEditModal = produce(dailyTimeSchedule, (draft) => {
        if (dailyTimeSchedule.isGlobal && !this.templateMode) {
          draft.name = `${draft.name} ${this.$t('global.duplicateSchedulePostfix')}`
        }
      })
      this.dailyEditModalIsInUpdateMode = this.templateMode || !dailyTimeSchedule.isGlobal
      this.dailyEditModalSaveMethod =
        dailyTimeSchedule.isGlobal && !this.templateMode
          ? this.createDailyAndSwap
          : this.dailyUpdateMethod
      this.namesTakenForDailyEditModal = this.dailyTimeSchedules
        .filter(
          (schedule) =>
            (!schedule.isGlobal || this.templateMode) && schedule.id !== dailyTimeScheduleId,
        )
        .map((schedule) => schedule.name)
      this.usedInWeeklyForDailyEditModal = this.weeklyTimeSchedules
        .filter((weeklySchedule) =>
          weeklySchedule.weeklySchedule.find(
            (entry) => entry.dailySchedule.id === dailyTimeScheduleId,
          ),
        )
        .map((weeklySchedule) => weeklySchedule.name)

      this.isReopeningAfterCloseOfDailyEditModal = true
      this.isEditDailyDialogOpen = true
    },
    createNewDailySchedule() {
      this.baseScheduleForDailyEditModal = {
        name: '',
        schedule: Object.fromEntries(
          this.timeScheduleConfig.schedules.map((scheduleConfig) => [scheduleConfig.name, []]),
        ),
      }
      this.dailyEditModalIsInUpdateMode = false
      this.dailyEditModalSaveMethod = this.createDailyAndAdd
      this.namesTakenForDailyEditModal = this.dailyTimeSchedules
        .filter((schedule) => !schedule.isGlobal || this.templateMode)
        .map((schedule) => schedule.name)

      this.isReopeningAfterCloseOfDailyEditModal = true
      this.isEditDailyDialogOpen = true
    },
    async createDailyAndSwap(newSchedule, newName) {
      try {
        const createdId = await this.dailyCreateMethod(newSchedule, newName)
        this.actualTimeSchedule = produce(this.actualTimeSchedule, (draft) => {
          const entryToChange = draft.find(
            (entry) => entry.dailyTimeScheduleId === this.baseScheduleForDailyEditModal.id,
          )
          entryToChange.dailyTimeScheduleId = createdId
        })
      } catch (error) {
        throw error
      }
    },
    async createDailyAndAdd(newSchedule, newName) {
      try {
        const createdId = await this.dailyCreateMethod(newSchedule, newName)
        this.addDailySchedule(createdId)
      } catch (error) {
        throw error
      }
    },
    computeNextValue(changedScheduleId, day) {
      // will delete newly set day from other schedule
      const newValue = !this.actualTimeSchedule
        .find((entry) => entry.dailyTimeScheduleId === changedScheduleId)
        .days.includes(day)
      this.actualTimeSchedule = this.actualTimeSchedule.map((entry) => {
        const newEntry = { dailyTimeScheduleId: entry.dailyTimeScheduleId }
        if (entry.dailyTimeScheduleId === changedScheduleId) {
          if (newValue) {
            newEntry.days = [...entry.days, day]
            newEntry.days.sort((a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b))
          } else {
            newEntry.days = entry.days.filter((weekDay) => weekDay !== day)
          }
        } else {
          if (newValue) {
            newEntry.days = entry.days.filter((weekDay) => weekDay !== day)
          } else {
            newEntry.days = [...entry.days]
          }
        }
        return newEntry
      })
    },
    selectAllDays(changedScheduleId) {
      this.actualTimeSchedule = this.actualTimeSchedule.map((entry) => {
        const newEntry = { dailyTimeScheduleId: entry.dailyTimeScheduleId }
        if (entry.dailyTimeScheduleId === changedScheduleId) {
          newEntry.days = [...daysOfWeek]
        } else {
          newEntry.days = []
        }
        return newEntry
      })
    },
    saveOrder(event) {
      // move element in array
      this.actualTimeSchedule.splice(
        event.newIndex,
        0,
        this.actualTimeSchedule.splice(event.oldIndex, 1)[0],
      )
    },
    tryStartCreatingNewSchedule(duplicateIndex) {
      if (this.hasUnsavedChanges) {
        if (this.disabledSaveMessage) {
          this.discardChangesAction = () => {
            this.startCreatingNewSchedule(duplicateIndex)
            this.discardChangesConfirmationModalOpen = false
          }
          this.discardChangesConfirmationModalOpen = true
        } else {
          this.handleSave(() => this.startCreatingNewSchedule(duplicateIndex))
        }
      } else {
        this.startCreatingNewSchedule(duplicateIndex)
        this.mobileViewDetail = true
      }
    },
    startCreatingNewSchedule(duplicateIndex) {
      this.setError(this.$t('global.duplicateWeeklyScheduleInfo'))
      this.selectedWeeklyScheduleIndex = null
      this.viewMode = viewModeEnum.NEW
      if (duplicateIndex != null) {
        this.actualTimeScheduleName = `${this.weeklyTimeSchedules[duplicateIndex].name} ${this.$t(
          'global.duplicateSchedulePostfix',
        )}`
        this.actualTimeSchedule = this.weeklyTimeSchedules[duplicateIndex].weeklySchedule.map(
          (entry) => ({
            dailyTimeScheduleId: entry.dailySchedule.id,
            days: entry.days,
          }),
        )
      } else {
        this.actualTimeScheduleName = ''
        this.$v.actualTimeScheduleName.$reset()
        this.actualTimeSchedule = []
      }
    },
    removeName(name, namesTaken) {
      const index = namesTaken.indexOf(name)
      if (index === -1) {
        return namesTaken
      }
      const namesCopy = [...namesTaken]
      namesCopy.splice(index, 1)
      return namesCopy
    },
    deleteClickHandler() {
      if (this.viewMode === viewModeEnum.NEW || this.viewMode === viewModeEnum.NEW_WITH_ACTIVATE) {
        this.deleteNewConfirmationModalOpen = true
      } else {
        this.indexToDelete = this.selectedWeeklyScheduleIndex
        this.deleteConfirmationModalOpen = true
      }
    },
  },
  computed: {
    mobileView() {
      return this.$vuetify.breakpoint.smAndDown
    },
    disabledDeleteMessage() {
      if (this.selectedWeeklyScheduleIndex == null) {
        return null
      }
      if (this.templateMode && this.weeklyTimeSchedules[this.selectedWeeklyScheduleIndex].isUsed) {
        return this.$t('global.disabledDeleteTooltipForWeeklyUsed')
      }
      if (
        this.weeklyTimeSchedules[this.selectedWeeklyScheduleIndex].id === this.activeTimeScheduleId
      ) {
        return this.$t('global.disabledDeleteTooltipForWeeklyActive')
      }
      return null
    },
    dailyScheduleMap() {
      const map = new Map()
      this.dailyTimeSchedules.forEach((schedule) => {
        map.set(schedule.id, schedule)
      })
      return map
    },
    daysOfWeekItems() {
      return daysOfWeek.map((day) => ({ value: day, text: this.$t(`global.daysOfWeek.${day}`) }))
    },
    canAddMoreDailySchedules() {
      return this.actualTimeSchedule.length < 7
    },
    weeklySchedulesNames() {
      if (!this.weeklyTimeSchedules) {
        return []
      }
      return this.weeklyTimeSchedules
        .filter((schedule) => !schedule.isGlobal || this.templateMode)
        .map((schedule) => schedule.name)
    },
    timeScheduleNameErrors() {
      const errors = []
      !this.$v.actualTimeScheduleName.required &&
        errors.push(this.$t('global.formValidation.required'))
      !this.$v.actualTimeScheduleName.maxLength &&
        errors.push(this.$t('global.formValidation.tooLong'))
      !this.$v.actualTimeScheduleName.mustBeUnique && errors.push(this.$t('global.nameTakenError'))
      return errors
    },
    visibleTimeScheduleNameErrors() {
      if (!this.$v.actualTimeScheduleName.$dirty) {
        return []
      } else {
        return this.timeScheduleNameErrors
      }
    },
    disabledSaveMessage() {
      if (!this.actualTimeSchedule.length) {
        return this.$t('global.weeklyScheduleEmptyError')
      }
      if (this.actualTimeSchedule.find((entry) => !entry.days.length)) {
        return this.$t('global.dailyScheduleNotUsedError')
      }
      const usedDays = this.actualTimeSchedule.flatMap((entry) => entry.days)
      if (usedDays.length !== 7) {
        const unusedDays = daysOfWeek.filter((day) => !usedDays.includes(day))
        return `${this.$t('global.everyDayHasToBeUsedError')} ${unusedDays
          .map((day) => this.$t(`global.daysOfWeek.${day}`))
          .join(', ')}`
      }
      if (this.actualTimeSchedule.reduce((acc, entry) => acc + entry.days.length, 0) !== 7) {
        return this.$t('global.everyDayHasToBeUsedError')
      }
      if (this.timeScheduleNameErrors.length) {
        return this.$t('global.scheduleInvalidNameError')
      }
      if (!this.hasUnsavedChanges) {
        return this.$t('global.noChangeError')
      }
      return null
    },
    viewModeEnum() {
      return viewModeEnum
    },
  },
  watch: {
    actualTimeSchedule() {
      if (this.manualError) {
        return
      }
      if (!this.actualTimeSchedule.length) {
        this.error = this.$t('global.weeklyScheduleEmptyError')
      } else if (this.actualTimeSchedule.find((entry) => !entry.days.length)) {
        this.error = this.$t('global.dailyScheduleNotUsedError')
      } else {
        const usedDays = this.actualTimeSchedule.flatMap((entry) => entry.days)
        if (usedDays.length !== 7) {
          const unusedDays = daysOfWeek.filter((day) => !usedDays.includes(day))
          this.error = `${this.$t('global.everyDayHasToBeUsedError')} ${unusedDays
            .map((day) => this.$t(`global.daysOfWeek.${day}`))
            .join(', ')}`
        } else {
          this.error = null
        }
      }

      if (
        !this.hasUnsavedChanges &&
        !isEqual(
          this.actualTimeSchedule,
          this.viewMode !== viewModeEnum.NEW && this.viewMode !== viewModeEnum.NEW_WITH_ACTIVATE
            ? this.weeklyTimeSchedules[this.selectedWeeklyScheduleIndex].weeklySchedule.map(
                (entry) => ({
                  dailyTimeScheduleId: entry.dailySchedule.id,
                  days: entry.days,
                }),
              )
            : [],
        )
      ) {
        this.$store.commit('setHasUnsavedChanges', true)
      }
    },
    actualTimeScheduleName() {
      if (
        !this.hasUnsavedChanges &&
        this.actualTimeScheduleName !==
          (this.viewMode !== viewModeEnum.NEW && this.viewMode !== viewModeEnum.NEW_WITH_ACTIVATE
            ? this.weeklyTimeSchedules[this.selectedWeeklyScheduleIndex].name
            : '')
      ) {
        this.$store.commit('setHasUnsavedChanges', true)
      }
    },
    viewMode() {
      if (this.viewMode === viewModeEnum.VIEW) {
        this.$store.commit('setHasUnsavedChanges', false)
      }
    },
    isOpen: {
      immediate: true,
      handler() {
        this.$store.commit('setHasUnsavedChanges', false)
        if (this.isOpen) {
          if (!this.isReopeningAfterCloseOfDailyEditModal) {
            const preselectedIndex = this.weeklyTimeSchedules.findIndex(
              (schedule) => schedule.id === this.preselectScheduleId,
            )
            if (
              this.openInViewMode === viewModeEnum.NEW ||
              this.openInViewMode === viewModeEnum.NEW_WITH_ACTIVATE
            ) {
              this.startCreatingNewSchedule(preselectedIndex === -1 ? null : preselectedIndex)
              this.viewMode = this.openInViewMode
              this.mobileViewDetail = true
            } else {
              this.mobileViewDetail = false
              this.selectedWeeklyScheduleIndex = preselectedIndex === -1 ? 0 : preselectedIndex
              this.actualTimeSchedule = this.weeklyTimeSchedules[
                this.selectedWeeklyScheduleIndex
              ].weeklySchedule.map((entry) => ({
                dailyTimeScheduleId: entry.dailySchedule.id,
                days: entry.days,
              }))
              this.actualTimeScheduleName =
                this.weeklyTimeSchedules[this.selectedWeeklyScheduleIndex].name
              this.viewMode = this.openInViewMode || viewModeEnum.VIEW
            }

            // initialize sortable
            this.$nextTick(() => {
              Sortable.create(this.$refs.sortableDailySchedules, {
                animation: 150,
                handle: '.sort-handle',
                onUpdate: this.saveOrder,
              })
            })
          }
          this.isReopeningAfterCloseOfDailyEditModal = false
        }
      },
    },
    isEditDailyDialogOpen() {
      if (!this.isEditDailyDialogOpen) {
        // when edit daily modal is closed it sets hasUnsavedChanges to false on destroy, then we need to set it to true manually
        this.$nextTick(() => {
          this.$store.commit('setHasUnsavedChanges', true)
        })
        this.$emit('modalToggle', true)
      } else {
        this.$emit('modalToggle', false)
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables';
@import '~@/components/TimeSchedules/timeSchedule.less';

/deep/.weekly-time-schedules-modal {
  height: 825px;
  overflow: visible;
  border-radius: 16px;
  position: relative;
}

/deep/.weekly-time-schedules-modal-mobile {
  position: relative;

  .sidebar {
    flex-grow: 1;
  }

  .close-btn {
    top: 3px;
    right: 3px;
  }

  .back-btn {
    position: absolute;
    top: 3px;
    left: 3px;
  }

  .sidebar .schedule-list .action-buttons.always-visible {
    display: flex;
  }
}

.error-modal-content {
  background-color: white;
  padding: 20px;
  text-align: center;
}

.error-modal-content p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
}

.error-modal-content .v-btn {
  color: white;
}

.modal-content-wrapper {
  display: flex;
  height: 100%;
}

.sidebar {
  background: #eee;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  width: 350px;

  h1 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 24px;
  }

  .schedule-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: auto;
    flex-grow: 1;

    .schedule-selector-btn {
      padding: 17px 15px 17px 21px;
      border-radius: 8px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .schedule-selector-btn-texts {
        overflow: hidden;
      }

      h2 {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        opacity: 0.4;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .active-icon {
        width: 18px;
        height: 18px;
        border-radius: 100%;
        background: rgba(76, 175, 80, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.without-action-btns {
        padding: 17px 21px;
      }

      &.active {
        background: white;
      }

      &:hover:not(.active) {
        background: #e4e4e4;
      }

      .action-buttons {
        display: none;
      }

      &:hover {
        .active-icon {
          display: none;
        }

        .action-buttons {
          display: flex;
        }
      }
    }
  }

  .create-btn-wrapper {
    margin-top: 32px;
    text-align: center;
  }
}

.weekly-schedule-detail-wrapper {
  background: white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }

  .daily-schedules {
    overflow: auto;
  }

  .daily-entry-wrapper {
    border: 1px solid #e3e2e4;
    border-radius: 8px;
    margin-bottom: 18px;

    &.has-error {
      border-color: @color-error;
    }

    .sort-handle {
      cursor: move;
      margin-left: -9px;
      margin-right: 20px;
    }

    .entry-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 0px 18px;
      margin-top: 17px;
    }

    h1 {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }

    .repeat-span {
      font-size: 14px;
    }

    .repeated-days-list {
      font-size: 14px;
      font-weight: 500;
    }

    .repeat-with-error {
      color: @color-error;

      /deep/i {
        color: @color-error;
      }
    }
  }

  footer {
    display: flex;
    border-top: 1px solid #e8e8e8;

    .actions {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}

.day-of-week-list-item {
  /deep/.v-input--checkbox {
    margin-top: 0;
    padding-top: 0;
  }
}

.close-btn {
  position: absolute;
  top: -39px;
  right: -8px;
}
</style>
