import gql from 'graphql-tag'

export const companyWithDevices = gql`
  query companyWithDevices($companyId: ID!) {
    company(companyId: $companyId) {
      id
      usesNewProductCatalogue
      devices {
        edges {
          node {
            id
            name
            controller
            thingType {
              name
            }
          }
        }
      }
    }
  }
`
