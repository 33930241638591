import Vue from 'vue'
import App from './App.vue'

import VueI18n from 'vue-i18n'
import translations from './translations'

import store from './store'
import router from './router'

import './assets/app.less'

import apolloProvider from './graphql/client'
import VueApollo from 'vue-apollo'

import vuetify from './plugins/vuetify'
import { Auth } from 'aws-amplify'
import { awsConfig } from './auth/awsConfig'
import { enableES5 } from 'immer'
import * as directives from './directives'
import DatetimePicker from 'vuetify-datetime-picker'
import DataTableCustom from '@/components/DataTableCustom'

enableES5()

Auth.configure(awsConfig)

Vue.config.productionTip = false

Vue.use(VueApollo)
Vue.use(VueI18n)
Vue.use(DatetimePicker)

for (const directive in directives) {
  Vue.directive(directive, directives[directive])
}

Vue.component('DataTableCustom', DataTableCustom)

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: translations,
})

new Vue({
  render: (h) => h(App),
  router,
  i18n,
  store,
  apolloProvider,
  vuetify,
}).$mount('#app')
