<template>
  <div class="login-page--content--inner">
    <h1 class="login-page--headline">{{ $t('login.confirmAccountTitle') }}</h1>

    <div class="login-page--form">
      <!--            <div class="input&#45;&#45;wrapper">-->
      <!--                <input class="input" id="code" type="text" v-model="confirmUserForm.confirmationCode">-->
      <!--                <label class="input&#45;&#45;label" for="code">{{ $t('login.confirmationCode') }}</label>-->
      <!--            </div>-->

      <div>{{ $t('login.confirmAccountInEmail') }}</div>

      <!--            <div class="login-page&#45;&#45;error">-->
      <!--                <div v-if="error">{{ $t(error) }}</div>-->
      <!--            </div>-->

      <!--            <div class="btn&#45;&#45;wrapper">-->
      <!--                <button class="btn btn&#45;&#45;big" type="submit" @click="confirmUser">{{ $t('login.sendConfirmation') }}</button>-->
      <!--                <button class="btn&#45;&#45;link" type="submit" @click="requestNewCode">{{ $t('login.requestNewCode') }}</button>-->
      <!--            </div>-->

      <div class="btn&#45;&#45;wrapper">
        <router-link :to="{ name: 'LoginPage' }" class="btn btn--big">{{
          $t('login.backToLogin')
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmUserAccountPage',
  props: {
    cognitoUser: Object,
  },
  data: function () {
    return {
      error: null,
      confirmUserForm: {
        confirmationCode: '',
      },
    }
  },
  created() {
    if (this.cognitoUser === null) {
      this.$router.push({ name: 'LoginPage' })
    }
  },
  methods: {
    requestNewCode() {
      this.cognitoUser.requestNewCode((err, result) => {
        console.log(err, result)
      })
    },
    confirmUser() {
      this.cognitoUser.confirmRegistration(
        this.confirmUserForm.confirmationCode,
        true,
        (err, result) => {
          if (!err) {
            console.log(err, result)
            this.$router.push({ name: 'LoginPage' })
          } else {
            this.error = err.message
          }
        },
      )
    },
  },
}
</script>
