import gql from 'graphql-tag'

export const ControlFields = gql`
  fragment ControlFields on Control {
    type
    name
    label
    icon
    value
    desiredValue
    options
    showDesired
    settable
    buttonText
    buttonIcon
    setEligibility
    statusLabel
    statusIcon
  }
`
