<template>
  <div v-bind="wrapperProps">
    <img v-bind="imageProps" :src="src" />
  </div>
</template>
<script>
export default {
  name: 'ServiceSequenceImage',
  props: {
    src: String,
    imageProps: Object,
    wrapperProps: Object,
  },
}
</script>
