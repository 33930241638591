<template>
  <div>
    <div v-if="$apollo.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <template v-else-if="companiesAllowedEligibilitiesData">
      <figure class="scrollable pb-2 pl-1">
        <table class="rotated-header-table">
          <thead>
            <tr>
              <th></th>
              <th
                v-for="(company, index) of companies"
                :key="company.id"
                :style="{ zIndex: 100 - index }"
              >
                <div class="rotated-header-container" :ref="company.id">
                  <div
                    class="rotated-header-content"
                    :ref="company.id"
                    @mouseenter="highlightColumn(company.id)"
                    @mouseleave="unhighlightColumn(company.id)"
                  >
                    {{ company.name }}
                  </div>
                </div>
              </th>
              <th :style="{ zIndex: 100 - companies.length }"></th>
            </tr>
          </thead>
          <tbody class="elevation-3">
            <tr v-for="eligibility of generalEligibilities" :key="eligibility.name">
              <td>
                <div class="mx-2 my-1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{ eligibility.label }}</span>
                    </template>
                    <span>{{ eligibility.description }}</span>
                  </v-tooltip>
                </div>
              </td>
              <template v-for="company of companies">
                <td
                  class="text-center"
                  :key="company.id"
                  :ref="company.id"
                  @mouseenter="highlightColumn(company.id)"
                  @mouseleave="unhighlightColumn(company.id)"
                >
                  <v-simple-checkbox
                    v-model="companiesAllowedEligibilitiesData[company.id][eligibility.name]"
                    color="primary"
                    :ripple="false"
                  ></v-simple-checkbox>
                </td>
              </template>
              <td class="text-center">
                <v-btn
                  icon
                  color="error"
                  @click="openDeleteEligibilityConfirmationDialog(eligibility.name)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </figure>
      <div class="flex-shrink-0 mt-8">
        <v-btn
          color="primary"
          class="mr-5"
          @click="updateCompaniesAllowedEligibilities()"
          :disabled="!hasUnsavedChanges"
          >{{ $t('global.save') }}</v-btn
        >
        <v-btn @click="reset()" :disabled="!hasUnsavedChanges">{{ $t('global.reset') }}</v-btn>
      </div>
    </template>
    <ConfirmationDialog
      v-if="eligibilityToDelete"
      v-model="confirmDeleteModalOpen"
      :heading="$t('global.eligibilityDeleteConfirmation.heading')"
      :text="$t('global.eligibilityDeleteConfirmation.text', { eligibility: eligibilityToDelete })"
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
      :action="deleteEligibility"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { companiesWithEligibilities } from '@/graphql/query/companiesWithEligibilities'
import { generalEligibilitiesOnlyNames } from '@/graphql/query/generalEligibilities'
import { companyAllowedEligibilitiesUpdate } from '@/graphql/mutations/companyAllowedEligibilitiesUpdate'
import { eligibilityDelete } from '@/graphql/mutations/eligibilityDelete'
import isEqual from 'lodash/isEqual'
import ConfirmationDialog from '@/components/ConfirmationDialog'

export default {
  name: 'AdminCompanyEligibilities',
  components: { ConfirmationDialog },
  data() {
    return {
      companiesAllowedEligibilitiesDataOriginal: null,
      companiesAllowedEligibilitiesData: null,
      eligibilityToDelete: null,
      confirmDeleteModalOpen: false,
    }
  },
  apollo: {
    companies: {
      query: companiesWithEligibilities,
      update(data) {
        return data.companies.edges.map((companyEdge) => companyEdge.node)
      },
    },
    generalEligibilities: {
      query: generalEligibilitiesOnlyNames,
      variables() {
        return {
          lang: this.$i18n.locale,
        }
      },
      update(data) {
        return data.eligibilitiesConfig.general
      },
    },
  },
  methods: {
    async updateCompaniesAllowedEligibilities() {
      try {
        await this.$apollo.mutate({
          mutation: companyAllowedEligibilitiesUpdate,
          variables: {
            updateData: Object.entries(this.companiesAllowedEligibilitiesData).map(
              ([companyId, eligibilities]) => ({
                companyId,
                eligibilities,
              }),
            ),
          },
          refetchQueries: ['companiesWithEligibilities'],
        })
        this.$toast.success(this.$t('global.companyAllowedEligibilitiesUpdate.success'))
        this.$store.commit('setHasUnsavedChanges', false)
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.companyAllowedEligibilitiesUpdate.failure'))
      }
    },
    async deleteEligibility() {
      try {
        await this.$apollo.mutate({
          mutation: eligibilityDelete,
          variables: {
            name: this.eligibilityToDelete,
          },
          refetchQueries: ['companiesWithEligibilities', 'generalEligibilitiesOnlyNames'],
        })
        this.$toast.success(this.$t('global.eligibilityDelete.success'))
        this.confirmDeleteModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.eligibilityDelete.failure'))
      }
    },
    openDeleteEligibilityConfirmationDialog(eligibility) {
      this.eligibilityToDelete = eligibility
      this.confirmDeleteModalOpen = true
    },
    highlightColumn(companyId) {
      this.$refs[companyId].forEach((ref) => (ref.style.backgroundColor = '#D3F1FF'))
    },
    unhighlightColumn(companyId) {
      this.$refs[companyId].forEach((ref) => (ref.style.backgroundColor = '#ffffff'))
    },
    onBeforeUnload() {
      if (this.hasUnsavedChanges) {
        // modern browsers will ignore this message and will show default
        return this.$t('global.unsavedChanges')
      }
    },
    reset() {
      this.prepareFormData()
    },
    prepareFormData() {
      if (!this.companies || !this.generalEligibilities) {
        this.companiesAllowedEligibilitiesData = null
      } else {
        this.companiesAllowedEligibilitiesDataOriginal = this.companies.reduce((acc, cur) => {
          acc[cur.id] = Object.fromEntries(
            this.generalEligibilities.map((eligibility) => [
              eligibility.name,
              cur.eligibilities.findIndex((el) => el.name === eligibility.name) !== -1,
            ]),
          )
          return acc
        }, {})
        this.companiesAllowedEligibilitiesData = JSON.parse(
          JSON.stringify(this.companiesAllowedEligibilitiesDataOriginal),
        )
      }
    },
  },
  computed: {
    ...mapGetters(['hasUnsavedChanges']),
  },
  watch: {
    companies() {
      this.prepareFormData()
    },
    generalEligibilities() {
      this.prepareFormData()
    },
    companiesAllowedEligibilitiesData: {
      deep: true,
      handler() {
        this.$store.commit(
          'setHasUnsavedChanges',
          !isEqual(
            this.companiesAllowedEligibilitiesData,
            this.companiesAllowedEligibilitiesDataOriginal,
          ),
        )
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('global.unsavedChanges'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload
  },
  destroyed() {
    window.onbeforeunload = undefined
    this.$store.commit('setHasUnsavedChanges', false)
  },
}
</script>
<style lang="less" scoped>
@header-height: 150px;
@header-width: 50px;
@user-link-bg-color: #eeeeee;

.scrollable {
  overflow: auto;
  max-height: 550px;
}

.rotated-header-table {
  border-collapse: collapse;

  th {
    position: sticky;
    top: 0;
    max-width: @header-width;
    min-width: @header-width;
    height: @header-height;
    background-color: white;

    &:first-child::after {
      content: '';
      height: 100%;
      width: 100%;
      background-color: white;
      position: absolute;
      top: 0;
      left: -5px;
    }

    &:last-child::before {
      content: '';
      height: 100%;
      width: 100%;
      background-color: white;
      position: absolute;
      top: 0;
      right: -5px;
    }
  }

  tr {
    border-bottom: 1px solid #d6d6dd;
  }

  tr:last-child {
    border-bottom: none;
  }

  .rotated-header-container {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }

  .rotated-header-content {
    white-space: nowrap;
    width: fit-content;
    height: cos(45deg) * @header-width;
    line-height: cos(45deg) * @header-width;
    padding: 0 10px 0 15px;
    transform-origin: left top;
    transform: rotate(-45deg);
    font-weight: normal;
    font-size: 14px;
    cursor: default;
  }
}
</style>
